import React, { useState } from "react";
import TooltipAndInfo from "../TooltipAndInfo";
import Cookies from "universal-cookie";
import { Link } from "react-router-dom";
  
const RedirectToFaaButton = ({ session, isFamilyAccess, opi }) => {
  const { REACT_APP_HOST } = process.env;
  const [faaRedirectToolTip, setfaaRedirectToltip] = useState(false);
  const showFaaRedirectTooltip = () => {
    setfaaRedirectToltip(!faaRedirectToolTip);
  };
  const adminRedirectionPath = `${REACT_APP_HOST}/admin/${opi}/arrangements`;
  const clArrangerRedirectionPath = `${REACT_APP_HOST}/admin/${opi}/arrangements?onePortal=true`;
  const familyArrangerRedirectPath = `${REACT_APP_HOST}/app/${opi}/arrangements`;

  const getRedirectPath = () => {
    const cookies = new Cookies();
    const role = cookies.get('role');
    switch (role) {
      case 'arranger':
        return familyArrangerRedirectPath;
      case 'admin':
        return adminRedirectionPath;
      case 'cl-arranger':
        return clArrangerRedirectionPath;
      default:
        return familyArrangerRedirectPath;
      
    }
  }

  const handleClick = (event) => {
    if (event.metaKey || event.ctrlKey) {
      // Allow the user to open the link in a new tab if they use ctrl+click or command+click
      return;
    }
    event.preventDefault();
    // Navigate programmatically to the route
    window.open(getRedirectPath(), '_blank');
  };

  return (
    <div className="goBackToFaaButton">
      <Link
        to={getRedirectPath()}
        onClick={(e)=>handleClick(e)}
      >
        Redirect To FAA Portal
      </Link>
      <TooltipAndInfo
        id="faaRedirectToolTip"
        showTooltipFn={showFaaRedirectTooltip}
        showToolTip={faaRedirectToolTip}
        size={17}
        tooltipText="Login to FAA to personalize your loved one's biography, death certificate, prayer card, program card,obituary. Choose between preferred or legal name, add donation links, and include social media profiles."
        color="#000000"
      />
    </div>
  );
};

export default RedirectToFaaButton;
