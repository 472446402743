import React, { useState, useEffect, useCallback, useMemo } from "react";
import useAsync from "react-use-async-hook";
import { Redirect} from "react-router-dom";
import _ from "lodash";

import Disclaimer from "./disclaimer";
import PrivacyPolicy from "./privacyPolicy";
import addNotification from "../utils/notification/notification";
import * as Dashboard from "../../api/dashboard";

const AcceptPolicies = ({
    policyStatus,
    getDecedent,
    decedentId,
    isPublic,
    handleDecline,
    setLoader
}) => {
    const [acceptDisclaimer, setDisclaimer] = useState(false);
    const [acceptPrivacy, setPrivacyPolicy] = useState(false);
    const [isDecline, setDecline] = useState(false);

    /**
     * Get Decedent Detail
     */
    const {
        data: policyResponse,
        loading: acceptPolicyLoader,
        execute: executeAcceptPolicy
    } = useAsync({
        autoExecute: false,
        task: Dashboard.acceptPolicy,
        dataLoader: useCallback(res => {
            return _.get(res, "data");
        }, []),
        onError: useCallback(error => {
            addNotification({
                type: "danger",
                title: "Error",
                message: _.get(
                    error,
                    "response.data.message",
                    "Oops! Something went wrong"
                )
            });
        }, [])
    });

    useEffect(() => {
        if (policyResponse) getDecedent(decedentId);
    }, [policyResponse]);

    const showLoader = useMemo(() => acceptPolicyLoader, [acceptPolicyLoader]);
    setLoader(showLoader);

    useEffect(() => {
        if (!policyStatus) {
            setDisclaimer(true);
        }
    }, [policyStatus]);

    const handleAcceptDisclaimer = () => {
        setPrivacyPolicy(true);
        setDisclaimer(false);
    };

    const handleAcceptPrivacyPolicy = () => {
        setPrivacyPolicy(false);
        if (!isPublic) {
            executeAcceptPolicy(decedentId);
        } else {
            sessionStorage.setItem("haveAcceptPolicy", true);
        }
    };

    const handleDeclinePolicies = () => {
        if (isPublic) {
            handleDecline();
        } else setDecline(true);
    };

    return (
        <>
            <Disclaimer
                isOpen={acceptDisclaimer}
                handleAccept={handleAcceptDisclaimer}
                onClose={handleDeclinePolicies}
            />
            <PrivacyPolicy
                isOpen={acceptPrivacy}
                handleAccept={handleAcceptPrivacyPolicy}
                onClose={handleDeclinePolicies}
            />
            {isDecline && <Redirect to="/app" />}
        </>
    );
};

export default AcceptPolicies;
