import React, { useState } from 'react';
import SpinnerImage from '../../../assets/gifs/spinning-loading.gif';

const OfficePreview = ({ width = '100%', height = '600px', src }) => {
  const [isLoaded, setIsLoaded] = useState(false);

  return (
    <>
      {!isLoaded && (
        <div className="displayLoader">
          <img src={SpinnerImage} alt="spinnerGif" />
        </div>
      )}
      <iframe
        title="Office Preview"
        src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(
          src
        )}`}
        onLoad={() => {
          setIsLoaded(true);
        }}
        width={width}
        height={height}
        frameborder="0"
      ></iframe>
    </>
  );
};

export default OfficePreview;
