import { apiFetch } from "../configs/apiConfig";

export const setProfile = data => {
    return apiFetch({
        method: "put",
        url: "/user/profile",
        data,
    });
};

export const setPassword = data => {
    return apiFetch({
        method: "put",
        url: "/user/password",
        data,
    });
};

export const updatePassword = data => {
    return apiFetch({
        method: "post",
        url: "/user/password",
        data,
    });
};

export const updateUserDetail = data => {
    return apiFetch({
        method: "put",
        url: "/user/profile/save",
        data,
    });
};

export const transferEmailValidate = (value) => {
    return apiFetch({
        method: "post",
        url: "/auth/verify-user-email",
        data: { email: value },
    });
};

export const transferAccount = (data) => {
    return apiFetch({
        method: "post",
        url: "/auth/transfer-account",
        data,
    });
};
