import React, { useCallback, useMemo, useEffect, useState } from "react";
import _ from "lodash";
import { useParams } from "react-router-dom";
import useAsync from "react-use-async-hook";

import * as ServiceAPI from "../../../api/funeralService";
import { useSetLoading } from "../../utils/hooks/loader";
import * as Dashboard from "../../../api/dashboard";
import addNotification from "../../utils/notification/notification";

import AppLayout from "../../appLayout";
import BreadCrumb from "../../utils/breadCrumb/breadCrumb";
import AvailableServicesImage from "../../../assets/images/availableServices.jpg";
import AvailableServiceImg from "../../../assets/images/Availableservice.jpg";
import ServiceDetail from "./serviceDetail";

const ServicesDetails = () => {
    const { onePortalId } = useParams();
    const [loader, setLoader] = useState(false);

    /**
     * Get Decedent Detail
     */
    const {
        data: decedent,
        loading: getDecedentLoader,
        execute: executeGetDecedent
    } = useAsync({
        autoExecute: false,
        task: Dashboard.getArrangementSummary,
        dataLoader: useCallback(res => {
            return _.get(res, "data");
        }, [])
    });

    useEffect(() => {
        if (onePortalId) {
            executeGetDecedent(onePortalId);
        }
    }, [onePortalId]);

    /**
     * Get Service Details
     */
    const {
        data: services,
        loading: getServiceLoader,
        execute: executeGetServices
    } = useAsync({
        autoExecute: false,
        task: ServiceAPI.getServiceData,
        dataLoader: useCallback(res => {
            return _.get(res, "data");
        }, [])
    });

    useEffect(() => {
        executeGetServices(onePortalId);
    }, [onePortalId]);

    /**
     * Send service interest
     */
    const {
        loading: sendInterestLoader,
        execute: executeSendInterest
    } = useAsync({
        autoExecute: false,
        task: ServiceAPI.showServiceInterest,
        dataLoader: useCallback(res => {
            addNotification({
                type: "success",
                title: "Success",
                message: _.get(
                    res,
                    "data.message",
                    "Interest Sent Successfully!"
                )
            });
        }, []),
        onError: useCallback(error => {
            addNotification({
                type: "danger",
                title: "Error",
                message: _.get(
                    error,
                    "response.data.message",
                    "Oops! Something went wrong"
                )
            });
        }, [])
    });

    const showLoader = useMemo(
        () => getServiceLoader || getDecedentLoader || sendInterestLoader || loader,
        [getServiceLoader, getDecedentLoader, sendInterestLoader, loader]
    );
    useSetLoading(showLoader);

    return (
        <AppLayout
            decedent={_.get(decedent, "decedent") || null}
            haveAcceptPolicy={_.get(decedent, "haveAcceptPolicy", false)}
            getDecedent={executeGetDecedent}
            setLoader={setLoader}
        >
            <div className="container">
                <BreadCrumb
                    links={[
                        {
                            url: `/app`,
                            description: "Home",
                            heading: "Dashboard"
                        },
                        {
                            url: `/app/${onePortalId}/arrangements`,
                            description: "Arrangements Summary",
                            heading: "Arrangements Summary"
                        },
                        {
                            url: "/app/servicesDetails",
                            description: "Available Services",
                            heading: "Available Services",
                            isSpan: true
                        }
                    ]}
                    showBack={true}
                />
                <div className="navigationCenter-body-wrapper">
                    <div className="whattoexpect-wrapper servicesdetails-wrapper">
                        <div className="services-available-wrapper cardbox-wrapper">
                            <div className='cardbox-heading'>
                                <h4 className='cardbox-heading-name service-heading'>
                                    Understanding Your Choices
                                </h4>
                            </div>
                            <div className='cardbox-body cardbox-body-text'>
                                <img
                                    src={AvailableServiceImg}
                                    alt='available Service'
                                    className='service-sub-image'
                                />
                                <p className='service-text'>
                                    The death of someone we love impacts both
                                    family and the community. From the beginning
                                    of recorded time, people have responded to
                                    death through ritual and ceremony. At a time
                                    of acute loss, there is tremendous value to
                                    be gained in allowing friends and family to
                                    gather together to recognize their loss and
                                    to support one another. This is a natural
                                    human emotion - when we hear of someone’s
                                    death, we almost instinctively think, "when
                                    is the service?" - because we want to be
                                    with those who are hurting, so that we may
                                    offer comfort and support.
                                </p>
                                <h4 className='service-sub-heading'>
                                    Service Types and Options
                                </h4>
                                <p className='service-text'>
                                    We have years of experience in helping
                                    families with deeply rooted funeral
                                    traditions as well as families with no
                                    traditions at all - in fact, we often help
                                    families to develop "new traditions" of
                                    their own. Whether you prefer the comfort of
                                    long established rituals, or a gathering
                                    that is unique and personalized as the life
                                    that it celebrates, embracing the support of
                                    family and friends is an important part of
                                    the healing process.
                                </p>
                            </div>
                        </div>
                        {services &&
                            services.length > 0 &&
                            services.map(service => (
                                <div
                                    className="services-available-wrapper"
                                    key={service.id}
                                >
                                    <ServiceDetail
                                        details={service}
                                        decedentId={decedent?.decedent?.id}
                                        onePortalId={onePortalId}
                                        executeSendInterest={
                                            executeSendInterest
                                        }
                                        executeGetServices={executeGetServices}
                                    />
                                </div>
                            ))}
                    </div>
                </div>
            </div>
        </AppLayout>
    );
};

export default ServicesDetails;
