import React, { Component } from "react";
import _ from "lodash";
import BreadCrumb from "../utils/breadCrumb/breadCrumb";

export default class ScheduledServices extends Component {
    render() {
        const decedentId = _.get(this.props.match.params, "decedentId");

        return (
            <div className="container">
                <BreadCrumb
                    links={[
                        {
                            url: `/app`,
                            description: "Home",
                            heading: "Dashboard"
                        },
                        {
                            url: `/app/${decedentId}/arrangements`,
                            description: "Arrangements Summary",
                            heading: "Arrangements Summary"
                        },
                        {
                            url: "/app/scheduledServices",
                            description: "Funeral Services Scheduled",
                            heading: "Funeral Services Scheduled",
                            isSpan: true
                        }
                    ]}
                    showBack={true}
                />
                <div className="navigationCenter-body-wrapper">
                    <div className="scheduledservices-wrapper">
                        <div className="cardbox-wrapper">
                            <div className="cardbox-heading">
                                <h4 className="cardbox-heading-name text-capitalize">
                                    Visitation Services
                                </h4>
                            </div>
                            <div className="cardbox-body cardbox-body-text">
                                <div className="dashboard-card-decedentinfo">
                                    <div className="dashboard-card-keyvalue">
                                        <span className="dashboard-card-key">
                                            Location:
                                        </span>
                                        <span className="dashboard-card-value">
                                            Cypress Lawn Funeral Home
                                        </span>
                                    </div>
                                    <div className="dashboard-card-keyvalue">
                                        <span className="dashboard-card-key">
                                            Date:
                                        </span>
                                        <span className="dashboard-card-value">
                                            07-07-18
                                        </span>
                                    </div>
                                    <div className="dashboard-card-keyvalue">
                                        <span className="dashboard-card-key">
                                            Time:
                                        </span>
                                        <span className="dashboard-card-value">
                                            12:00
                                        </span>
                                    </div>
                                    <div className="dashboard-card-keyvalue">
                                        <span className="dashboard-card-key">
                                            Arranger:
                                        </span>
                                        <span className="dashboard-card-value">
                                            Helen Gonzales
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
