import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import _ from "lodash";
import moment from "moment";
import { updateBannerImage } from "../../../api/dashboard";
import addNotification from "../notification/notification";
import TooltipAndInfo from "../../TooltipAndInfo";
import AvatarEditor from "react-avatar-editor";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import useAsync from "react-use-async-hook";
import { useSetLoading } from "../hooks/loader";
import Closebutton from "../../../assets/images/cancel-button.png";
import cameraIcon from "../../../assets/images/camera.svg";
import defaultBannerImage from '../../../assets/images/CLFAA-banner.JPG'
import SpinnerImage from '../../../assets/gifs/spinning-loading.gif'
import OptionsMenuContainer from "../optionsDropDown";

const getPersonName = person => {
    let name = [];
    if (_.get(person, "firstName")) {
        name.push(_.get(person, "firstName"));
    }
    if (_.get(person, "middleName")) {
        name.push(_.get(person, "middleName"));
    }
    if (_.get(person, "lastName")) {
        name.push(_.get(person, "lastName"));
    }
    return name.join(" ") || "";
};

const BannerContent = ({ decedent, opi = "" }) => {
    const [showBannerTooltip, setShowBannerTooltip] = useState(false);
    const [isModal, setIsModal] = useState(false);
    // const [sizeAccepted,setSizeAccepted] = useState(true);
    const [showAlertMsg,setShowAlertMessage] = useState(false)
    const [isEdit,setIdEdit] = useState(false);
    const [originaalBannerImage,setOriginalBannerImage] = useState(_.get(decedent,'originalBannerUrl'));
    const [originalBannerUrl,setOriginalBannerUrl] = useState(_.get(decedent,"originalBannerUrl"))
    const [imageHeigth,setImageHeight] = useState(0);
    const [uploadNewImage,setUploadNewImage] = useState(false);
    const [position, setPosition] = useState({ x: 0.5, y: 0.5 });
    const [avatarImage, setAvatarImage] = useState(
        _.get(decedent, "bannerUrl")
    );

    useEffect(() => {
        setOriginalBannerUrl(_.get(decedent, "originalBannerUrl"))
        setBannerUrl(_.get(decedent, "bannerUrl") || "")
    }, [decedent])

    const [mimeType, setMimeType] = useState("image/jpeg");
    const [fileName, setFileName] = useState("fileName.jpg");
    const [zoom, setZoom] = useState(1);
    const avatarRef = useRef(null);
    const [bannerUrl, setBannerUrl] = useState(_.get(decedent, "bannerUrl") || "");

    const [deviceWidth, setDeviceWidth] = useState();

    useEffect(() => {
        const handleResize = () => {
            setDeviceWidth(window.innerWidth);
        };
        window.addEventListener("resize", handleResize);
        setDeviceWidth(window.innerWidth);
    }, []);

    const showHideToolTip = () => {
        setShowBannerTooltip(!showBannerTooltip);
    };

    let personName =
        (_.get(decedent, "prefix") ? _.get(decedent, "prefix") + ". " : "") +
        getPersonName(decedent);

    const opiId = _.get(decedent, "opi") || opi || "";
    const isEmptyDecedent =
        opi && (!decedent || (decedent && _.isEmpty(decedent)));

    const {data:urlsData, loading: mediaLoading, execute: executeMedia } = useAsync({
        autoExecute: false,
        initialData: useMemo(() => null, []),
        task: useCallback(async (opiId, formData) => {
            const response = await updateBannerImage(opiId, formData);
            return response;
        }, []),
        dataLoader: useCallback(({ data }) => {
            addNotification({
                type: "success",
                title: "Success",
                message: data.message,
            });
           return data;
        }, []),
        onError: useCallback(error => {
            addNotification({
                type: "danger",
                title: "Error",
                message: _.get(
                    error,
                    "response.data.message",
                    "Oops! Something went wrong"
                ),
            });
        }, []),
    });

    useEffect(() => {
        if (urlsData) {
            setBannerUrl(_.get(urlsData, 'bannerUrl'));
            if (uploadNewImage) {
                setOriginalBannerUrl(_.get(urlsData, 'originalBannerUrl'))
            }
        }
    }, [urlsData])

    useEffect(()=>{
        const img = new Image();
        img.src = originalBannerUrl;
        img.addEventListener('load', function() {
        setImageHeight(this.naturalHeight);
        });
    },[isEdit])
    
    const showLoader = useMemo(() => mediaLoading, [mediaLoading]);
    useSetLoading(showLoader);

    const modalClose = () => {
        setIdEdit(false)
        setIsModal(false);
        setZoom(1)
    }

    const handlePositionChange = (newPosition) => {
        if (newPosition.x < 0) {
          newPosition.x = 0;
        } else if (newPosition.x > 1) {
          newPosition.x = 1;
        }
        if (newPosition.y < 0) {
            newPosition.y = 0;
          } else if (newPosition.y > 1) {
            newPosition.y = 1;
          }
        setPosition(newPosition);
      };


    const avatarSave = async () => {
        if (avatarRef.current) {
            const canvasScaled = avatarRef.current.getImageScaledToCanvas();
            const croppedImg = canvasScaled.toDataURL();
            const blob = await (await fetch(croppedImg)).blob();
            let originalFileName;
            let originalExtension;
            const fileNameArray = originalBannerUrl?originalBannerUrl.split('-'):'';
            const originalNameArray = fileNameArray ? fileNameArray.slice(1).join(""): '';
            const removeExtension = originalNameArray ? originalNameArray.split('.') : '';
            const extension = removeExtension ? removeExtension.pop() : 'jpeg'
            const finalName = removeExtension ? removeExtension.join("").trim() : '';
            const min = 1;
            const max = 100;
            const randomNum = Math.floor(Math.random() * (max - min + 1)) + min;
            if(uploadNewImage) {
                const formatFileName = fileName? fileName.split('.') : '';
                 originalExtension = formatFileName ? formatFileName.pop() : 'jpeg'
                 originalFileName = formatFileName ? formatFileName.join("").trim() : '';
            }
           const newFileName = uploadNewImage ? `${originalFileName}_${randomNum}.${originalExtension}` 
            : `${finalName ? finalName : 'BannerImage'}_${randomNum}.${extension}`;
            const croppedImage = new File([blob], newFileName, {
                type: `image/${originalExtension ? originalExtension : (extension ? extension : 'jpeg')}`,
                lastModified: new Date(),
            });
            var formData = new FormData();
            formData.append("file", croppedImage);
            if(uploadNewImage) {
                const min = 2;
                const max = 200;
                const randomNum = Math.floor(Math.random() * (max - min + 1)) + min;
                const newFileName = `${originalFileName ? originalFileName : 'BannerImage'}_${randomNum}.${originalExtension}`
                const newFile = new File([originaalBannerImage], newFileName, { type: originaalBannerImage.type });
                formData.append("files", newFile);
            }
            executeMedia(opiId,formData);
            setIsModal(false); 
            setIdEdit(false)
        }
    };

    let clsName = "landing-banner-decedentdetails";
    const loadingClass = isEmptyDecedent
        ? " banner-unloaded"
        : " banner-loaded";
    clsName = clsName + loadingClass;
    return (
        <div  className="mainBannerImageContainer">
        <div className='container'>
            <div className="landing-banner-wrapper">
                {!isModal && (
                       <>
                        <div>
                            {
                                !isEmptyDecedent ? (
                                    <img src={bannerUrl ? bannerUrl : defaultBannerImage} alt='bannerImage' 
                                className={`${bannerUrl ? 'bannerImage' : 'deafultBannerImage' }`}/>
                                )
                                : (
                                    <div className="displayLoader">
                                        <img src={SpinnerImage} alt='spinnerGif'/>
                                    </div>
                                )
                            }
                            
                        </div>
                        {opiId && (
                        <>
                            <button className='banner-info static-text toolTipButton'>
                                <TooltipAndInfo
                                    id='banner-tooltip-icon'
                                    color='#000000'
                                    size="25"
                                    clsName=""
                                    marginValue="0"
                                    showTooltipFn={showHideToolTip}
                                    showToolTip={showBannerTooltip}
                                    tooltipText='Recommended size to upload the banner image is 1500 x 350 Pixels'
                                />
                            </button>
                            <OptionsMenuContainer
                                originalBannerUrl={originalBannerUrl}
                                decedent={decedent}
                                setIdEdit={setIdEdit}
                                setIsModal={setIsModal}
                                setUploadNewImage={setUploadNewImage}
                                setFileName={setFileName}
                                setMimeType={setMimeType}
                                setOriginalBannerImage={setOriginalBannerImage}
                                setAvatarImage={setAvatarImage}
                                setImageHeight={setImageHeight}
                            >
                            </OptionsMenuContainer>
                        </>
                        )}
                     </>
                )}
                    {decedent && (
                        <div className="landing-banner-decedentdetails" style={{display: isModal ? 'none' : 'block'}}>
                        <div className='landing-banner-decedent d-block d-md-inline-block'>
                            <div className='dashboard-card-decedentinfo'>
                                <h5 className='dashboard-card-name'>
                                    {personName}
                                </h5>
                                {_.get(decedent, "dateOfBirth") ? (
                                    <div className='dashboard-card-keyvalue'>
                                        <span className='dashboard-card-key'>
                                            Date of Birth:
                                        </span>
                                        <span className='dashboard-card-value'>
                                            {_.get(decedent, "dateOfBirth")
                                                ? moment(
                                                      _.get(
                                                          decedent,
                                                          "dateOfBirth"
                                                      )
                                                  ).format("MM/DD/YYYY")
                                                : ""}
                                        </span>
                                    </div>
                                ) : (
                                    ""
                                )}
                                {_.get(decedent, "dateOfDeath") ? (
                                    <div className='dashboard-card-keyvalue'>
                                        <span className='dashboard-card-key'>
                                            Date of Death:
                                        </span>
                                        <span className='dashboard-card-value'>
                                            {_.get(decedent, "dateOfDeath")
                                                ? moment(
                                                      _.get(
                                                          decedent,
                                                          "dateOfDeath"
                                                      )
                                                  ).format("MM/DD/YYYY")
                                                : ""}
                                        </span>
                                    </div>
                                ) : (
                                    ""
                                )}
                                {/* <div className="dashboard-card-keyvalue">
                                        <span className="dashboard-card-key">Arrangement Scheduled:</span>
                                        <span className="dashboard-card-value">09-07-19</span>
                                    </div> */}
                            </div>
                        </div>
                        </div>
                    )}
            </div>
                {isModal && (
                            <div className="avatar-container shadow bg-white rounded">
                                <div>
                                    <AvatarEditor
                                        position={position}
                                        ref={avatarRef}
                                        image={isEdit ? originalBannerUrl : avatarImage}
                                        crossOrigin="anonymous"
                                        height={350}
                                        width={deviceWidth <= 1500 ? 1480 : deviceWidth-20}
                                        scale={zoom}
                                        rotate={0}
                                        border={ imageHeigth < 500 ? [5,5] : [10,100]}
                                        onPositionChange={handlePositionChange}
                                        color={[255, 255, 255, 0.6]}
                                        className='banner-avatar-editor'
                                    />
                                </div>
                                <div className="cancelSaveOptionsContainer">
                                    <div className='slidecontainer d-flex justify-content-between align-items-center'>
                                        <span className='symbol-size' onClick={() => setZoom(prev => prev <= 0.1 ? prev : prev - 0.1)}>-</span>
                                        <input
                                            type='range'
                                            min={0.1}
                                            max={2}
                                            step={0.01}
                                            value={zoom}
                                            className='slider'
                                            onChange={e =>
                                                setZoom(parseFloat(e.target.value))
                                            }
                                        />
                                        <span className='symbol-size' onClick={() => setZoom(prev => prev >= 2 ? prev : prev + 0.1)}>+</span>
                                    </div>
                                    <div className='avatar-buttons-wrapper d-flex justify-content-center mt-2 mb-2'>
                                        <span>
                                            <button
                                                className='btn-theme  mr-3'
                                                onClick={modalClose}
                                            >
                                                CANCEL
                                            </button>
                                        </span>
                                        <span>
                                            <button
                                                className='btn-theme'
                                                onClick={avatarSave}
                                            >
                                                SAVE
                                            </button>
                                        </span>
                                    </div>
                                </div>
                            </div>
                )}
            
        </div>
        </div>
    );
};

export default React.memo(BannerContent);
