import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import FamilyWebsiteDashboard from "../../components/features/familyWebsite/dashboard";

const mapStateToProps = ({ authentication }) => {
    return {
        session: authentication.session,
        sessionExpired:authentication.sessionExpired
    };
};

const mapDispatchToProps = (dispatch) => ({
    get refreshSession() {
        return async () => {
            dispatch({
                type: `REFRESH_SESSION`,
            });
        };
    },
    get loggedIn() {
        return async (role, isNewUser) => {
            dispatch({
                type: `LOGIN`,
                payload: {
                    isLoggedIn: true,
                    role,
                    isNewUser,
                },
            });
        };
    },
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(FamilyWebsiteDashboard));
