import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useParams, withRouter } from "react-router-dom";
import _ from "lodash";
import { HelmetProvider, Helmet } from "react-helmet-async";
import useAsync from "react-use-async-hook";

import * as AuthAPI from "../../api/auth";

import BannerContent from "../utils/bannerContent/bannerContent";
import NavigationTop from "../navigation/navbar/navbar";
import NavigationBottom from "../navigation/footer/footer";
import BtnBack from "../utils/btnBack/BtnBack";
import AcceptPolicies from "../acceptPolicies";
import ScrollToTop from "../utils/hooks/scrollToTop";
import { getPageTitle } from "../utils/functions";
import ReactGA from "react-ga4";
ReactGA.initialize([
  {
    trackingId: process.env.REACT_APP_Google_Analytics_Id,
    gaOptions: { name: "faaAnalyticsTracker", send_page_view: false },
    testMode:
      process.env.REACT_APP_HOST !== "https://cypresslawn.com/obituaries" ? true : false,
  },
]);

const AppLayout = props => {
    const location = useLocation();
    const { onePortalId } = useParams();
    const { showAcceptPolicy, isPublic, decedent, setLoader, updateLoggedinData } = props;
    const [pageTitle, setPageTitle] = useState("");
    //useSetData(props);

     /**
     * Get User Details
     */
     const {
        data: userDetail,
        loading: getUserLoader,
        execute: executeGetUser,
    } = useAsync({
        autoExecute: false,
        task: AuthAPI.isUserLoggedIn,
        dataLoader: useCallback(res => {
            return _.get(res, "data.data");
        }, []),
    });
  
    useEffect(() => {
      if (userDetail)
      {
        if (props?.updateLoggedinData) {
          updateLoggedinData(userDetail)
        }
      }
   
    }, [userDetail])

  useEffect(() => {
    executeGetUser();
    const pageurl = window.location.pathname;
    if(pageurl) setPageTitle(getPageTitle(pageurl));
  }, []);

  useEffect(() => {
    if (pageTitle) {
      ReactGA.send({
        hitType: "pageview",
        page: window.location.pathname + window.location.search,
        title: pageTitle,
      });
    }
  }, [pageTitle]);

    const opi =
        _.get(props, "match.params.opi") ||
        _.get(props, "match.params.onePortalId") ||
        "";

    return (
      <HelmetProvider>
        <Helmet>
          <title>{pageTitle}</title>
        </Helmet>
        <div className='main-content-wrapper'>
          <ScrollToTop />
          <div className='navigationTop-content-wrapper'>
            <div className='container'>
              <NavigationTop
                decedentId={onePortalId}
                userInfo={userDetail}
                location={location}
              />
            </div>
          </div>
          <div className='navigationCenter-content-wrapper'>
            <div className='container d-block d-md-none'>
              <BtnBack />
            </div>
            <BannerContent decedent={props.decedent} opi={opi} />
            {/* <div className=""> */}
            {props.children}
            {/* </div> */}
          </div>
          {!isPublic && userDetail && props.decedent ? (
            <AcceptPolicies
              policyStatus={props.haveAcceptPolicy}
              getDecedent={props.getDecedent}
              decedentId={onePortalId}
              setLoader={setLoader}
            />
          ) : showAcceptPolicy && isPublic ? (
            <AcceptPolicies
              policyStatus={!showAcceptPolicy}
              isPublic={isPublic}
              handleDecline={props.handleDecline}
              setLoader={setLoader}
            />
          ) : (
            ""
          )}
          <div className='navigationBottom-content-wrapper'>
            <div className='container'>
              <NavigationBottom />
            </div>
          </div>
        </div>
      </HelmetProvider>
    );
};

export default withRouter(AppLayout);
