import React from "react";
import LifeOfSection from "./lifOfSection";
const MemoriesListing = ({
    opi,
    decedent,
    obituary,
    isLoggedIn,
    getObituary,
}) => {
    
    const name = obituary?.displayName

    return (
        <>
            {obituary && (
                <LifeOfSection
                    isLoggedIn={isLoggedIn}
                    opi={opi}
                    data={obituary}
                    getObituary={getObituary}
                    name={name}
                ></LifeOfSection>
            )}
        </>
    );
};

MemoriesListing.propTypes = {};

export default MemoriesListing;
