import React, { useState } from "react";
import { Link } from "react-router-dom";
import _ from "lodash";
import moment from "moment";
import TooltipAndInfo from "../../TooltipAndInfo";

const HistorySection = ({historyDetails}) => {
    const [showHistoryToolTip, setHistoryToolTip] = useState(false);

    const showHideToolTip = () => {
        setHistoryToolTip(!showHistoryToolTip);
    };

    const getPersonName = person => {
        let name = [];
        if (_.get(person, "firstName")) {
            name.push(_.get(person, "firstName"));
        }
        if (_.get(person, "middleName")) {
            name.push(_.get(person, "middleName"));
        }
        if (_.get(person, "lastName")) {
            name.push(_.get(person, "lastName"));
        }
        return name.join(" ") || "";
    };

    return (
        historyDetails && historyDetails.length>0 && (
            <div className='home-wrapper'>
                <div className='dashboard-header-family'>
                    <div className='dashboard-header'>
                        <h1 className='home-heading'>Family History</h1>
                        <TooltipAndInfo
                            id='family-history-tooltip-icon'
                            color='#000000'
                            showTooltipFn={showHideToolTip}
                            showToolTip={showHistoryToolTip}
                            tooltipText='This section shows any additional family members you may have access to add,
                            update or post memories for. This information may be public or kept private'
                            toolTipClsName='large-tooltip-section'
                        />
                    </div>
                </div>
                <div className='dashboard-wrapper'>
                    {historyDetails &&
                        historyDetails.map(person => {
                            let { decedent } = person;
                            let personName =
                                (_.get(decedent, "prefix")
                                    ? _.get(decedent, "prefix") + ". "
                                    : "") + getPersonName(decedent);

                            return (
                                <div className='dashboard-card' key={decedent.id}>
                                    <Link to={`/app/${decedent.onePortalId}/arrangements`}>
                                        <div className='dashboard-card-content card-content'>
                                            <div className='dashboard-card-decedentinfo card-bottom'>
                                                <h5 className='dashboard-card-name card-heading'>
                                                    {personName}
                                                </h5>
                                                <div className='dashboard-card-keyvalue card-keyvalue'>
                                                    <span className='dashboard-card-key'>
                                                        Date of Birth
                                                    </span>
                                                    <span className='dashboard-card-value'>
                                                        {_.get(
                                                            decedent,
                                                            "dateOfBirth"
                                                        )
                                                            ? moment(
                                                                  _.get(
                                                                      decedent,
                                                                      "dateOfBirth"
                                                                  )
                                                              ).format("MM-DD-YYYY")
                                                            : "-"}
                                                    </span>
                                                </div>
                                                <div className='dashboard-card-keyvalue card-keyvalue'>
                                                    <span className='dashboard-card-key'>
                                                        Date of Death
                                                    </span>
                                                    <span className='dashboard-card-value'>
                                                        {_.get(
                                                            decedent,
                                                            "dateOfDeath"
                                                        )
                                                            ? moment(
                                                                  _.get(
                                                                      decedent,
                                                                      "dateOfDeath"
                                                                  )
                                                              ).format("MM-DD-YYYY")
                                                            : "-"}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className='dashboard-card-viewinfo'>
                                                <button className='arrangements-button'>
                                                    VIEW ARRANGEMENTS
                                                </button>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            );
                        })}
                </div>
            </div>
        )
    );
};

export default HistorySection