import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import OfficePreview from "../../../../common/officePreview/officePreview";
import AdminSectionPdfPreview from "./adminSectionDisplayPdf";

const PreviewDocumentModal = ({
  isOpenModal,
  closeModal,
  currentFile,
  fileExtesnion,
}) => {
  return (
    <Modal
      isOpen={isOpenModal}
      toggle={closeModal}
      className="documentViewerModal"
    >
      <ModalHeader>
        <i className="modal-close fas fa-times" onClick={() => closeModal()} />
      </ModalHeader>
      <ModalBody>
        {fileExtesnion === "docx" ? (
          <OfficePreview src={currentFile} />
        ) : (
          <AdminSectionPdfPreview src={currentFile}></AdminSectionPdfPreview>
        )}
      </ModalBody>
    </Modal>
  );
};

export default PreviewDocumentModal;
