import React, { useEffect, useCallback, useMemo, useState } from 'react';
import axios from 'axios';
import _ from 'lodash';
import useAsync from 'react-use-async-hook';

import { useSetLoading } from '../../../../utils/hooks/loader';
import { ReactComponent as DefaultImage } from '../../../../../assets/images/play.svg';

const fetchFromYoutube = (url) => {
    return axios({
        url: `https://www.youtube.com/oembed?url=${url}&format=json`,
        method: 'GET',
    });
};

const fetchFromVimeo = (url) => {
    return axios({
        url: `https://vimeo.com/api/oembed.json?url=${url}`,
        method: 'GET',
    });
};

const VideoThumbnail = ({
    link,
    handleClose,
    setFieldError,
    setVideoError,
}) => {
    const [thumbnailUrl, setThumbnailUrl] = useState(null);
    const [videoTitle, setVideoTitle] = useState('');

    const fetchVideoData = (link) => {
        let url = link;
        url = new URL(url);

        if (url.host === 'www.youtube.com') {
            executeGetVideoMeta(link, 'youtube');
            setVideoError('');
        } else if (url.host === 'vimeo.com') {
            executeGetVideoMeta(link, 'vimeo');
            setVideoError('');
        } else {
        }
    };

    useEffect(() => {
        if (link) {
            fetchVideoData(link);
        }
    }, [link]);

    const {
        data: videoData,
        loading: getVideoMetaLoading,
        execute: executeGetVideoMeta,
    } = useAsync({
        autoExecute: false,
        task: useCallback((url, host) => {
            if (host === 'youtube') {
                return fetchFromYoutube(url);
            } else {
                return fetchFromVimeo(url);
            }
        }, []),
        dataLoader: useCallback((response) => {
            return _.get(response, 'data');
        }, []),
        onError: useCallback((error) => {
            if (_.get(error, 'response.status') === 400) {
                setFieldError(`URL doesn't exists`);
            }
        }, []),
    });

    useEffect(() => {
        if (videoData) {
            setFieldError(null);
            setThumbnailUrl(_.get(videoData, 'thumbnail_url'));
            setVideoTitle(_.get(videoData, 'title', link));
        }
    }, [videoData]);

    const showLoading = useMemo(() => getVideoMetaLoading, [
        getVideoMetaLoading,
    ]);
    useSetLoading(showLoading);

    return (
        (thumbnailUrl || videoTitle) && (
            <div className="thumbnail-wrapper d-flex">
                {thumbnailUrl ? (
                    <div className="thumbnail-image">
                        <img src={thumbnailUrl} alt="video" />
                    </div>
                ) : (
                    <div className="thumbnail-image">
                        <DefaultImage />
                    </div>
                )}
                <div className="video-content d-flex">
                    {videoTitle && <div className="content">{videoTitle}</div>}
                    <div className="close d-flex">
                        <i
                            className="modal-close fas fa-times"
                            onClick={handleClose}
                        />
                    </div>
                </div>
            </div>
        )
    );
};

export default VideoThumbnail;
