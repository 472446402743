import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import useAsync from "react-use-async-hook";
import _ from "lodash";

import { useSetLoading } from "../../utils/hooks/loader";
import * as Dashboard from "../../../api/dashboard";
import AppLayout from "../../appLayout";
import BreadCrumb from "../../utils/breadCrumb/breadCrumb";
import WhatToExpectBanner from "../../../assets/images/whatToExpect.jpg";
import WhatToExpectBanner2 from "../../../assets/images/whatToExpect_2.jpg";

const WhatToExpect = () => {
    const { onePortalId } = useParams();
    const [loader, setLoader] = useState(false);

    /**
     * Get Decedent Detail
     */
    const {
        data: decedent,
        loading: getDecedentLoader,
        execute: executeGetDecedent
    } = useAsync({
        autoExecute: false,
        task: Dashboard.getArrangementSummary,
        dataLoader: useCallback(res => {
            return _.get(res, "data");
        }, [])
    });

    useEffect(() => {
        if (onePortalId) {
            executeGetDecedent(onePortalId);
        }
    }, [onePortalId]);

    const showLoader = useMemo(() => getDecedentLoader || loader, [getDecedentLoader, loader]);
    useSetLoading(showLoader);

    return (
        <AppLayout
            decedent={_.get(decedent, "decedent") || null}
            haveAcceptPolicy={_.get(decedent, "haveAcceptPolicy", false)}
            getDecedent={executeGetDecedent}
            setLoader={setLoader}
        >
            <div className="container">
                <BreadCrumb
                    links={[
                        {
                            url: `/app`,
                            description: "Home",
                            heading: "Dashboard"
                        },
                        {
                            url: `/app/${onePortalId}/arrangements`,
                            description: "Arrangements Summary",
                            heading: "Arrangements Summary"
                        },
                        {
                            url: "/app/whatToExpect",
                            description: "What To Expect",
                            heading: "What To Expect",
                            isSpan: true
                        }
                    ]}
                    showBack={true}
                />
                <div className="navigationCenter-body-wrapper">
                    <div className="whattoexpect-wrapper">
                        <div className="whattoexpect-banner">
                            <img
                                src={WhatToExpectBanner}
                                alt="What To Expect"
                                className="whattoexpect-banner-image"
                            />
                        </div>
                        <div className="whattoexpect-welcome">
                            <h4 className="whattoexpect-welcome-header">
                                Welcome. This website has been prepared to help
                                you...
                            </h4>
                            <ul className="whattoexpect-welcome-list">
                                <li>
                                    Prepare for the arrangement conference and
                                    understand a little more about what we can
                                    do to help you and your family.
                                </li>
                                <li>
                                    Consider different ways in which you might
                                    celebrate the life of your loved one.
                                </li>
                                <li>
                                    Provide information to friends and family
                                    about details of memorial services.
                                </li>
                            </ul>
                            <p className="whattoexpect-welcome-note">
                                This site is private and secure. Any information
                                you enter here will be available during your
                                arrangement appointment to review and approve.
                            </p>
                        </div>

                        <div className="whattoexpect-welcome">
                            <h4 className="whattoexpect-welcome-header">
                                What to Expect
                            </h4>
                            <p className="whattoexpect-welcome-note">
                                When you meet with us to discuss the
                                arrangements, we will do several things,
                                including:
                            </p>
                            <ul className="whattoexpect-welcome-list">
                                <li>
                                    Spend some time sharing memories of your
                                    loved one and exploring your ideas,
                                    traditions, and wishes for the arrangements.
                                </li>
                                <li>
                                    As decisions are made, we will coordinate
                                    necessary plans with everyone involved, such
                                    as clergy or celebrants, musicians,
                                    cemetery/crematory, florists, newspapers
                                    (for obituaries), etc.
                                </li>
                                <li>
                                    We will review information for the death
                                    certificate and help you prepare obituaries
                                    or other tribute items.
                                </li>
                            </ul>
                            <p className="whattoexpect-welcome-note">
                                An important part of our mission is to help you
                                and your family as you move through the loss
                                process toward healing. We will work closely
                                with you make sure that you are able to choose
                                arrangements that will meet the emotional,
                                relational, and spiritual needs we all have when
                                we lose someone we love.
                            </p>
                        </div>
                        <div className="whattoexpect-banner">
                            <img
                                src={WhatToExpectBanner2}
                                alt="What To Expect"
                                className="whattoexpect-banner-image"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </AppLayout>
    );
};

export default WhatToExpect;
