import { combineReducers } from "redux";
import authentication from "./auth";
import loader from "./common";

const rootReducer = combineReducers({
    authentication,
    loader
});

export default rootReducer;
