import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import TooltipAndInfo from '../../TooltipAndInfo';
import homeIcon from '../../../assets/images/homeIcon.svg';
import Cookies from 'universal-cookie';
class BreadCrumbs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showArrangmentSummaryTooltip: false,
    };
  }

  showHideToolTip = () => {
    this.setState({
      showArrangmentSummaryTooltip: !this.state.showArrangmentSummaryTooltip,
    });
  };

  render() {
    const { links, customStyling } = this.props;
    let heading = '';
    let description = '';
    const cookies = new Cookies();
    const Oprole =
      sessionStorage.getItem('role') ||
      localStorage.getItem('role') ||
      cookies.get('role');

    const linkArray = links
      ? links.map((ele, idx) => {
          heading = ele.heading;
          description = ele.description;

          return !ele.isSpan ? (
            <React.Fragment key={idx}>
              {ele.description !== 'Home' && (
                <i className="fas fa-chevron-right next-icon" />
              )}
              <div
                className={`${Oprole === 'cl-arranger' && 'disable-cursor'}`}
              >
                <Link
                  to={{
                    pathname: ele.url,
                    state: ele.state,
                    search: ele.search,
                  }}
                  key={`bread-crumb-${idx}`}
                  style={{ fontSize: '20px' }}
                  className={
                    Oprole === 'cl-arranger' && ele.description === 'Home'
                      ? 'disable-link'
                      : ''
                  }
                >
                  {ele.description}
                </Link>
              </div>
            </React.Fragment>
          ) : (
            <div
              key={`bread-crumb-${idx}`}
              className="d-flex align-items-center"
            >
              {ele.description !== 'Home' && (
                <i className="fas fa-chevron-right next-icon" />
              )}
              <span
                className="active-breadcrumb theme-primary"
                style={{ fontSize: '20px' }}
              >
                {ele.description}
              </span>
            </div>
          );
        })
      : [];

    return (
      <div
        className="navigationCenter-header-wrapper"
        style={{
          padding: customStyling ? '20px 42px' : '',
          margin: customStyling ? '20px 60px 0 60px' : '',
          boxShadow: customStyling ? '0px 4px 12px rgb(0 0 0 / 10%)' : '',
          borderRadius: 6,
        }}
      >
        <div className="d-none d-md-flex align-items-center justify-content-between">
          <div className="title-wrapper d-flex align-items-center">
            <div className={`${Oprole === 'cl-arranger' && 'disable-cursor'}`}>
              <Link
                to={Oprole === 'arranger' ? '/app' : '/admin/memories'}
                className={Oprole === 'cl-arranger' && 'disable-link'}
              >
                {/* <i className='fas fa-home home-icon' /> */}
                <img
                  src={homeIcon}
                  alt="home"
                  style={{ marginRight: '15px' }}
                />
              </Link>
            </div>
            {linkArray}
          </div>
          {heading === 'Arrangements Summary' && (
            <TooltipAndInfo
              id="arrangements-tooltip-icon"
              showTooltipFn={this.showHideToolTip}
              showToolTip={this.state.showArrangmentSummaryTooltip}
              tooltipText="Please follow the steps to begin the arrangements for your loved one."
              color="#000000"
            />
          )}
          {heading === 'Obituary' && (
            <TooltipAndInfo
              id="obituary-tooltip-icon"
              showTooltipFn={this.showHideToolTip}
              showToolTip={this.state.showArrangmentSummaryTooltip}
              tooltipText="Enter Obituary text and upload your loved ones image and favorite voice message here and send them to your arranger."
            />
          )}
          {heading === 'Biography and Death Certificate Information' && (
            <TooltipAndInfo
              id="biography-tooltip-icon"
              showTooltipFn={this.showHideToolTip}
              showToolTip={this.state.showArrangmentSummaryTooltip}
              tooltipText="After entering your information, be sure to click the SAVE button in each section. The SUBMIT TO ARRANGER button will send the information to the ARRANGER you are working with."
              toolTipClsName="large-tooltip-section"
            />
          )}
          {description === 'Prayer Card Creation' && (
            <TooltipAndInfo
              id="prayercard-tooltip-icon"
              showTooltipFn={this.showHideToolTip}
              showToolTip={this.state.showArrangmentSummaryTooltip}
              tooltipText="Create and edit the prayer card for your loved ones service. When you have completed your selections, Click the “SUBMIT TO ARRANGER” to send the desired card and layout to be printed."
              toolTipClsName="large-tooltip-section"
            />
          )}
          {description === 'Service Program Creation' && (
            <TooltipAndInfo
              id="programcard-tooltip-icon"
              showTooltipFn={this.showHideToolTip}
              showToolTip={this.state.showArrangmentSummaryTooltip}
              tooltipText="Create and edit the program for your loved ones service. When you have completed your selections, Click the SUBMIT TO ARRANGER to send the desired card and layout to be printed."
              toolTipClsName="large-tooltip-section"
            />
          )}
        </div>
        <div className="navigationCenter-header-name d-flex d-md-none">
          <h4 className="text-capitalize">{heading}</h4>
        </div>
      </div>
    );
  }
}
export default withRouter(BreadCrumbs);
