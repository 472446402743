import React from "react";
import Select from "react-select";
import {
  funeralLocation,
  prayerCardFuneralLocations,
} from "../../utils/variables";

const SelectFooter = ({
  handleSelect,
  setLocationCity,
}) => {
  const changeLocationName = (city) => {
    let cityName = prayerCardFuneralLocations[city];
    setLocationCity(cityName);
  };
  return (
    <div className="select-wrapper" style={{ paddingTop: "20px" }}>
      <div className="choose-reason inputfield d-flex align-items-center justify-content-between">
        <div
          className="inputcard-withlabel d-flex align-items-start justify-content-center flex-column"
          style={{ minWidth: "250px" }}
        >
          <Select
            className="select-input"
            placeholder="Select Location"
            styles={{
              menu: (base) => ({
                ...base,
                position: "absolute",
              }),
              container: (base) => ({
                ...base,
                width: 200,
              }),
            }}
            name="color"
            options={funeralLocation}
            // eslint-disable-next-line react/jsx-no-duplicate-props
            onChange={(val) => {
              handleSelect(val, "footer");
              changeLocationName(val.value);
         
            }}
      
          />
        </div>
      </div>
    </div>
  );
};

export default SelectFooter;
