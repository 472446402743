import React, { useCallback, useMemo, useState } from "react";
import { Formik } from "formik";
import _ from "lodash";
import * as yup from "yup";
import { Form, Tooltip } from "reactstrap";
import useAsync from "react-use-async-hook";

import * as DecedentAPI from "../../../../api/decedent";
import { useSetLoading } from "../../../utils/hooks/loader";
import addNotification from "../../../utils/notification/notification";
import { formatOptions } from "../../../utils/functions";
import {
    certificateFreezeText,
    firstTimeSaveText,
} from "../constants/constants";

import Btnradio from "../../../utils/btnRadio/customBtnRadio";
import SelectComponent from "../../../utils/SelectComponent/SelectComponent";

const VeteranDetails = ({
    decedent,
    isDisable,
    serviceBranchData,
    decedentId,
    refreshDecedent,
    showCertificateSavePopup,
    enableSubmitButton,
}) => {
    /**
     * Save Veteran Details
     */
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const isFieldAlreadySaved = _.get(decedent, "isFirstSaved");
    const [isFirstSaved, setIsFirstSaved] = useState(isFieldAlreadySaved);
    if (
        typeof isFirstSaved === "undefined" &&
        isFirstSaved !== isFieldAlreadySaved
    ) {
        setIsFirstSaved(isFieldAlreadySaved);
    }
    const showToolTip = disabled => {
        if (isDisable) {
            setTooltipOpen(disabled);
        }
    };
    const { loading: updateLoader, execute: executeUpdate } = useAsync({
        autoExecute: false,
        task: useCallback(
            async (
                values,
                decedentId,
                formikBag,
                isFirstSaved,
                showCertificateSavePopup
            ) => {
                let payload = {
                    isVeteran: values.isVeteran,
                    serviceBranch: values.serviceBranch
                        ? values.serviceBranch.value
                        : "",
                    serviceEra: values.serviceEra,
                    isUnknown: values.isUnknown,
                };
                return {
                    res: await DecedentAPI.saveVeteranDetails(
                        decedentId,
                        payload
                    ),
                    formikBag,
                    isFirstSaved,
                    showCertificateSavePopup,
                };
            },
            []
        ),
        dataLoader: useCallback(
            ({ res, formikBag, isFirstSaved, showCertificateSavePopup }) => {
                formikBag.setSubmitting(false);
                refreshDecedent();
                enableSubmitButton();
                if (isFirstSaved) {
                    addNotification({
                        type: "success",
                        title: "Success",
                        message: firstTimeSaveText,
                    });
                } else {
                    showCertificateSavePopup();
                    setIsFirstSaved(true);
                }

                addNotification({
                    type: "success",
                    title: "Success",
                    message: "Veteran Details Updated!",
                });
            },
            []
        ),
        onError: useCallback(error => {
            addNotification({
                type: "danger",
                title: "Error",
                message: _.get(
                    error,
                    "response.data.message",
                    "Oops! Something went wrong"
                ),
            });
        }, []),
    });

    const showLoader = useMemo(() => updateLoader, [updateLoader]);
    useSetLoading(showLoader);

    return (
        <div className='veterandetails-wrapper'>
            <div className='cardbox-wrapper'>
                <div className='cardbox-heading'>
                    <h4 className='cardbox-heading-name text-capitalize text-black'>
                        <span className='item-numbering'>5</span>
                        Veteran Details
                    </h4>
                </div>
                <Formik
                    initialValues={{
                        isVeteran: decedent ? true : false,
                        veteran: decedent
                            ? _.get(decedent, "isUnknown")
                                ? "Unknown"
                                : "Yes"
                            : "No",
                        serviceBranch: _.get(decedent, "serviceBranch")
                            ? {
                                  label: decedent.serviceBranch,
                                  value: decedent.serviceBranch,
                                  ..._.find(serviceBranchData, {
                                      name: decedent.serviceBranch,
                                  }),
                              }
                            : "",
                        serviceEra: _.get(decedent, "serviceEra", ""),
                        serviceBranchData: serviceBranchData,
                        isUnknown: Boolean(_.get(decedent, "isUnknown")),
                    }}
                    enableReinitialize={true}
                    validationSchema={yup.object().shape({
                        serviceBranch: yup
                            .string()
                            .test(
                                "service-branch-required",
                                "Service Branch is required",
                                function (val) {
                                    if (
                                        this.parent.isVeteran &&
                                        !this.parent.isUnknown &&
                                        !val
                                    ) {
                                        return false;
                                    }
                                    return true;
                                }
                            ),
                        serviceEra: yup
                            .string()
                            .nullable()
                            .test(
                                "service-era-required",
                                "Service Era is required",
                                function (val) {
                                    if (
                                        this.parent.isVeteran &&
                                        !this.parent.isUnknown &&
                                        !val
                                    ) {
                                        return false;
                                    }
                                    return true;
                                }
                            ),
                    })}
                    onSubmit={(values, formikBag) => {
                        executeUpdate(
                            values,
                            decedentId,
                            formikBag,
                            isFirstSaved,
                            showCertificateSavePopup
                        );
                    }}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleSubmit,
                        handleBlur,
                        setSubmitting,
                        isSubmitting,
                        setFieldValue,
                    }) => {
                        const handleChange = e => {
                            const { name, value } = e.target;
                            setFieldValue(name, value);
                            setSubmitting(false);
                        };
                        return (
                            <Form id='veteranForm' onSubmit={handleSubmit}>
                                <div className='inputcard-wrapper cardbox-body'>
                                    <div className='inputcard-body custom-inputcard-body'>
                                        <div className='inputcard-group d-flex align-items-center justify-content-start'>
                                            <div className='choose-reason w-100 inputfield d-flex align-items-start align-items-sm-center justify-content-start flex-column flex-sm-row'>
                                                <span>Is a Veteran? </span>
                                                <div
                                                    className={`${
                                                        isDisable &&
                                                        "disable-radio"
                                                    } custom-radio ml-5 d-flex align-items-center justify-content-between`}
                                                >
                                                    <div className='d-flex align-items-center justify-content-start mr-3'>
                                                        <span>Yes</span>
                                                        <Btnradio
                                                            value='Yes'
                                                            checked={
                                                                values.veteran ===
                                                                "Yes"
                                                            }
                                                            radioButtonType='veteran'
                                                            handleChange={e => {
                                                                setFieldValue(
                                                                    "isVeteran",
                                                                    true
                                                                );
                                                                setFieldValue(
                                                                    "isUnknown",
                                                                    false
                                                                );
                                                                setFieldValue(
                                                                    "veteran",
                                                                    "Yes"
                                                                );
                                                                setSubmitting(
                                                                    false
                                                                );
                                                                setFieldValue(
                                                                    "serviceBranch",
                                                                    _.get(
                                                                        decedent,
                                                                        "serviceBranch"
                                                                    )
                                                                        ? {
                                                                              label: decedent.serviceBranch,
                                                                              value: decedent.serviceBranch,
                                                                              ..._.find(
                                                                                  serviceBranchData,
                                                                                  {
                                                                                      name: decedent.serviceBranch,
                                                                                  }
                                                                              ),
                                                                          }
                                                                        : ""
                                                                );
                                                                setFieldValue(
                                                                    "serviceEra",
                                                                    _.get(
                                                                        decedent,
                                                                        "serviceEra"
                                                                    )
                                                                );
                                                            }}
                                                            disabled={isDisable}
                                                        />
                                                    </div>
                                                    <div className='d-flex align-items-center justify-content-start mr-3'>
                                                        <span>No</span>
                                                        <Btnradio
                                                            value='No'
                                                            checked={
                                                                values.veteran ===
                                                                "No"
                                                            }
                                                            radioButtonType='veteran'
                                                            handleChange={e => {
                                                                setSubmitting(
                                                                    false
                                                                );
                                                                setFieldValue(
                                                                    "veteran",
                                                                    "No"
                                                                );
                                                                setFieldValue(
                                                                    "isVeteran",
                                                                    false
                                                                );
                                                                setFieldValue(
                                                                    "isUnknown",
                                                                    false
                                                                );
                                                                setFieldValue(
                                                                    "serviceBranch",
                                                                    ""
                                                                );
                                                                setFieldValue(
                                                                    "serviceEra",
                                                                    ""
                                                                );
                                                            }}
                                                            disabled={isDisable}
                                                        />
                                                    </div>
                                                    <div className='d-flex align-items-center justify-content-start'>
                                                        <span>Unknown</span>
                                                        <Btnradio
                                                            value='Unknown'
                                                            checked={
                                                                values.veteran ===
                                                                "Unknown"
                                                            }
                                                            radioButtonType='veteran'
                                                            handleChange={e => {
                                                                setSubmitting(
                                                                    false
                                                                );
                                                                setFieldValue(
                                                                    "veteran",
                                                                    "Unknown"
                                                                );
                                                                setFieldValue(
                                                                    "isVeteran",
                                                                    true
                                                                );
                                                                setFieldValue(
                                                                    "isUnknown",
                                                                    true
                                                                );
                                                                setFieldValue(
                                                                    "serviceBranch",
                                                                    ""
                                                                );
                                                                setFieldValue(
                                                                    "serviceEra",
                                                                    ""
                                                                );
                                                            }}
                                                            disabled={isDisable}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='inputcard-group d-flex align-items-center justify-content-start'>
                                            <div className='choose-reason inputfield d-flex align-items-center justify-content-between'>
                                                <div className='inputcard-withlabel d-flex align-items-start justify-content-center flex-column '>
                                                    <label className='inputcard-label'>
                                                        Service Branch
                                                    </label>
                                                    <SelectComponent
                                                        placeholder='Select Service Branch'
                                                        options={formatOptions(
                                                            values.serviceBranchData
                                                        )}
                                                        defaultValue={
                                                            values.serviceBranch
                                                        }
                                                        selectedOption={
                                                            values.serviceBranch
                                                        }
                                                        name='serviceBranch'
                                                        handleChange={e => {
                                                            setSubmitting(
                                                                false
                                                            );
                                                            setFieldValue(
                                                                "serviceBranch",
                                                                e
                                                            );
                                                        }}
                                                        disabled={
                                                            (values.isUnknown &&
                                                                values.isVeteran) ||
                                                            (!values.isUnknown &&
                                                                !values.isVeteran) ||
                                                            isDisable
                                                        }
                                                    />
                                                    {errors.serviceBranch &&
                                                    touched.serviceBranch ? (
                                                        <span className='error-input-msg certificate-error-msg'>
                                                            {
                                                                errors.serviceBranch
                                                            }
                                                        </span>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className='choose-reason inputfield d-flex align-items-center justify-content-between'>
                                                <div className='inputcard-withlabel d-flex align-items-start justify-content-center flex-column '>
                                                    <label className='inputcard-label'>
                                                        Service Era
                                                    </label>
                                                    <input
                                                        id='serviceEra'
                                                        className='custominputfield'
                                                        type='text'
                                                        placeholder='Enter Service Era'
                                                        value={
                                                            values.serviceEra
                                                        }
                                                        name='serviceEra'
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        disabled={
                                                            (values.isUnknown &&
                                                                values.isVeteran) ||
                                                            (!values.isUnknown &&
                                                                !values.isVeteran) ||
                                                            isDisable
                                                        }
                                                    />
                                                    {errors.serviceEra &&
                                                    touched.serviceEra ? (
                                                        <span className='error-input-msg certificate-error-msg'>
                                                            {errors.serviceEra}
                                                        </span>
                                                    ) : null}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='inputcard-footer'>
                                        <div
                                            id='veteranForm-save'
                                            onPointerEnter={() =>
                                                showToolTip(true)
                                            }
                                            onPointerLeave={() =>
                                                showToolTip(false)
                                            }
                                        >
                                            <button
                                                type='submit'
                                                disabled={
                                                    isDisable || isSubmitting
                                                }
                                                className='btn-theme btn-search-wrapper text-uppercase'
                                            >
                                                SAVE
                                            </button>
                                        </div>
                                        <Tooltip
                                            placement='top-end'
                                            isOpen={tooltipOpen}
                                            target='veteranForm-save'
                                            className='tooltip-template'
                                        >
                                            {certificateFreezeText}
                                        </Tooltip>
                                    </div>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            </div>
        </div>
    );
};

export default VeteranDetails;
