import { apiFetch } from "../configs/apiConfig";
import { API_URL } from "../constants/appConstants";
import axios from "axios";
import { getAccessToken } from "../utils";

export const getArrangementSummary = onePortalId => {
    return apiFetch({
        method: "get",
        url: `/decedent/${onePortalId}`,
    });
};
export const getDecedentDetails = opi => {
    return apiFetch({
        method: "get",
        url: `/decedent/opi/${opi}`,
    });
};

export const acceptPolicy = decedentId => {
    return apiFetch({
        method: "get",
        url: `/decedent/${decedentId}/accept`,
    });
};

export const getDecedentList = () => {
    return apiFetch({
        method: "get",
        url: "/decedent",
    });
};

export const getObituary = onePortalId => {
    return apiFetch({
        method: "get",
        url: `/obituary/${onePortalId}`,
    });
};

export const updateEditor = data => {
    return apiFetch({
        url: `editor`,
        method: "POST",
        data,
    });
};

export const updateBannerImage = (opiId, formData) => {
    return axios({
        url: `${API_URL}/obituary/${opiId}/life-of/banner-image/upload`,
        method: "POST",
        data: formData,
        headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${getAccessToken()}`,
        },
    });
};
