import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";

import PreviewTemplateCarousel from "./previewTemplateCarousel";

const PreviewCardModal = ({ isOpen, toggle, pages, template, pageURL }) => {
    return (
        <Modal
            isOpen={isOpen}
            toggle={toggle}
            className='modal-template-slide modal-preview-card'
        >
            <ModalHeader toggle={toggle}></ModalHeader>
            <ModalBody>
                <PreviewTemplateCarousel
                    pages={pages}
                    template={template}
                    pageURL={pageURL}
                />
            </ModalBody>
        </Modal>
    );
};

export default PreviewCardModal;
