import React from "react";

const Card = props => {
    return (
        <div className="prayer-card">
            <div className="prayer-card-template-img-container">
                <img src={props.src} alt="Prayer Card | Template" />
                <button
                    onClick={() => props.select(true)}
                    className="link-customise btn-theme theme-green"
                    disabled={props.isLocked}
                >
                    Customize
                </button>
            </div>
            <h3 className="prayer-card-template-type">{props.templateName}</h3>
        </div>
    );
};

export default Card;
