import { apiFetch } from "../configs/apiConfig";
import { API_URL } from "../constants/appConstants";
import _ from "lodash";

export const getMemoriesList = (opi, filters) => {
    return apiFetch({
        url: `${API_URL}/memories/${opi}/list`,
        method: "GET",
        params: {
            memoryType: filters,
        },
    });
};

export const decedentAPI = opi => {
    if (opi) {
        return apiFetch({
            url: `${API_URL}/memories/${opi}`,
            method: "GET",
        });
    }

    return;
};

export const uploadAPI = (data,isAllowAllMedia=false) => {
    if (data) {
        return apiFetch({
            url: `${API_URL}/memories/upload?isAllowAllMedia=${isAllowAllMedia}`,
            method: "POST",
            data,
        });
    }

    return;
};

export const uploaAllMediadAPI = (data,opi) => {
    if (data) {
        return apiFetch({
            url: `${API_URL}/memories/${opi}/allMediaupload`,
            method: "POST",
            data,
        });
    }

    return;
};

export const shareMemoryAPI = (opi, data) => {
    return apiFetch({
        url: `${API_URL}/memories/${opi}`,
        method: "POST",
        data,
    });
};

export const shareMediaAPI = (opi, data) => {
    return apiFetch({
        url: `${API_URL}/memories/${opi}/addOnlyMedia`,
        method: "POST",
        data,
    });
};

export const privacyModeAPI = opi => {
    return `${API_URL}/decedent/privacy/${opi}`;
};

export const getApproveMemories = (opi, memoryType) => {
    return apiFetch({
        url: `${API_URL}/memories/${opi}/approve-memory-list`,
        method: "GET",
        params: {
            status: memoryType,
        },
    });
};
export const hideMemoryAPI = (opi, hideMemory) => {
    let key = "",
        value = "";

    if (hideMemory && _.isObject(hideMemory) && !_.isEmpty(hideMemory)) {
        key = Number(Object.keys(hideMemory)[0]);
        value = Object.values(hideMemory)[0];
    } else {
        return;
    }

    let data = {
        isPrivate: value,
    };
    return apiFetch({
        url: `${API_URL}/memories/${opi}/hide/${key}`,
        method: "PUT",
        data,
    });
};
export const aceeptOrrejectMemory = (opi, memoryId, status, rejectedReason) => {
    let data;
    if (status === "Rejected") {
        data = {
            status: status,
            rejectedReason: rejectedReason,
        };
    } else {
        data = {
            status: status,
        };
    }
    return apiFetch({
        url: `${API_URL}/memories/${opi}/${memoryId}/status`,
        method: "PUT",
        data,
    });
};

export const titleEditApi = (title, memoryId, opi) => {
    const reqBody = {
        title: title,
    };
    return apiFetch({
        url: `${API_URL}/memories/${opi}/${memoryId}/title`,
        method: "PUT",
        data: reqBody,
    });
};

export const descriptionEditApi = (description, memoryId, opi) => {
    const reqBody = {
        memory: description,
    };
    return apiFetch({
        url: `${API_URL}/memories/${opi}/${memoryId}/description`,
        method: "PUT",
        data: reqBody,
    });
};

export const deleteImgAPI = (memoryId, fileId, opi) => {
    return apiFetch({
        url: `${API_URL}/memories/${opi}/${memoryId}/file/${fileId}`,
        method: "Delete",
    });
};

export const addImgAPI = (memoryId, opi, data) => {
    return apiFetch({
        url: `${API_URL}/memories/${opi}/${memoryId}/addImage`,
        method: "POST",
        data,
    });
};

export const rotateImgAPI = (memoryId, opi, data) => {
    return apiFetch({
        url: `${API_URL}/memories/${opi}/${memoryId}/rotateImage`,
        method: "POST",
        data,
    });
};

export const getDraft = (opId) =>{
    return apiFetch({
        url : `${API_URL}/memorialGpt/api/drafts`,
        method : "GET",
        params: {
            decedentOpId: opId,
        },
    })
}

export const createDraft = (data)=>{
    return apiFetch({
        url: `${API_URL}/memorialGpt/api/save-draft`,
        method : "POST",
        data
    })
}
