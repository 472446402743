import React, { useEffect, useRef, useState } from "react";
import _ from "lodash";
import {
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from 'reactstrap'
import Dropzone from "react-dropzone";
import { getValidFileUrl } from "../../utils/global";
import elliseCricle from '../../assets/images/ellipse-circle.svg'
import addNotification from "./notification/notification";
import cameraIcon from "../../assets/images/camera.svg";
import { saveAs } from "file-saver";


const OptionsMenuContainer = ({
    originalBannerUrl,
    decedent,
    setIdEdit,
    setIsModal,
    setUploadNewImage,
    setFileName,
    setMimeType,
    setOriginalBannerImage,
    setAvatarImage,
    setImageHeight,

}) => {
    const [dropdownOpen, setDropDownOpen] = useState(false)
    const dropdownRef = useRef(null);
    const dropzoneRef = useRef();
    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    });
    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setDropDownOpen(false)
        }
    };

    const downloadImage = async() => {
        const srcUrl = originalBannerUrl;
        const fileNameArray = srcUrl?.split('-');
        const originalNameArray = fileNameArray ? fileNameArray.slice(1).join("") : '';
        const removeExtension = originalNameArray ? originalNameArray.split('.') : '';
        const extension = removeExtension ? removeExtension?.pop() : 'jpeg';
        const finalName = removeExtension ? removeExtension.join("").trim() : '';
        let response = await fetch(srcUrl);
        let blob = await response?.blob();
        const blobUrl = URL.createObjectURL(blob);
        saveAs(blobUrl, `${finalName ? finalName : 'BannerImage'}.${extension}`)
    }

    const editBannerImage = () => {
        // setSizeAccepted(true)
        setIdEdit(true)
        setIsModal(true)
    }
    const onDrop = async (acceptedFiles,rejectedFiles) => {
        const MAX_FILE_SIZE = 20 * 1024 * 1024;
        const acceptedFormats = ['image/jpeg','image/png','image/gif'];
        if(rejectedFiles && rejectedFiles.length > 0) {
            const rejectedFile = rejectedFiles[0].file;
            if(rejectedFile.size > MAX_FILE_SIZE){
                addNotification({
                    type: "danger",
                    title: "Error",
                    message: `File too large, Max file size is 20 MB.`,
                });
            }
            else if(!acceptedFormats.includes(rejectedFile.type)) {
                addNotification({
                    type: "danger",
                    title: "Error",
                    message: `Invalid File Format. Please check the file type and upload again.`,
                });
            }
            setDropDownOpen(false)
            return
        }
        if (acceptedFiles && acceptedFiles[0]) {
            let img = new Image()
            img.src = window.URL.createObjectURL(acceptedFiles[0])
            img.onload =  async () => {
                setImageHeight(img.height);
            }
            setUploadNewImage(true)
            var file = acceptedFiles[0];
            setFileName(file.name);
            setMimeType(file.type);
            let fileUrl = await getValidFileUrl(file);
            setIsModal(true);
            setOriginalBannerImage(file)
            setAvatarImage(fileUrl);
        }
    };

    const handleButtonClick = () => {
        dropzoneRef.current.open();
      };

    return (
        <div>
            <span className="ellipse-container optionsMenuContainer" ref={dropdownRef}>
                <UncontrolledDropdown isOpen={dropdownOpen} >
                    <DropdownToggle className="ToggleButton-banner" onClick={() => {
                            setDropDownOpen(!dropdownOpen)
                        }}>
                        <img src={cameraIcon}
                            className='CameraIcon'
                            alt='MenuIcon'
                        />                       
                    </DropdownToggle>
                    <DropdownMenu right >
                        <DropdownItem 
                            className="menuItem"
                            onClick={handleButtonClick}
                        >
                            <Dropzone
                                ref={dropzoneRef}
                                onDrop={onDrop}
                                accept='image/jpeg,
                                        image/png,
                                        image/gif '
                                minSize={0}
                                maxSize={10485760}
                                noClick={true}
                            >
                                {({ getRootProps, getInputProps }) => (
                                    <div
                                        {...getRootProps()}
                                        className='update-banner'
                                    >
                                        <input {...getInputProps()} />
                                        Upload Banner
                                    </div>
                                )}
                            </Dropzone>
                        </DropdownItem>
                        <DropdownItem onClick={() => {
                            editBannerImage();
                        }}
                            disabled={originalBannerUrl ? false : true}
                            className="menuItem">
                            Edit Banner
                        </DropdownItem>
                        <DropdownItem onClick={() => {
                            downloadImage();
                            setDropDownOpen(false)
                        }}
                            disabled={originalBannerUrl ? false : true}
                            className="menuItem"
                        >
                            Download Image
                        </DropdownItem>
                    </DropdownMenu>
                </UncontrolledDropdown>
            </span>
        </div>
    )
}

export default OptionsMenuContainer;
