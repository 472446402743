import React, {
    useState,
    useEffect,
    useCallback,
    useMemo,
    useRef,
} from "react";
import { useParams, useLocation, Redirect } from "react-router-dom";
import _ from "lodash";
import useAsync from "react-use-async-hook";
import { Form } from "reactstrap";
import { useDropzone } from "react-dropzone";
import { Formik } from "formik";

import AppLayout from "../../../appLayout";
import SelectComponent from "../../../utils/SelectComponent/SelectComponent";
import DatepickerComponent from "../../../utils/datePicker/datePicker";
import {
    decedentAPI,
    uploadAPI,
    shareMemoryAPI,
} from "../../../../api/memories";
import addNotification from "../../../utils/notification/notification";
import VideoThumbnail from "./videoThumbnail";
import InputTag from "../../../utils/InputTag";
import { formatOptions } from "../../../utils/functions";
import store from "../../../../store/store";
import { useSetLoading } from "../../../utils/hooks/loader";
import { getPolicyAcceptance } from "../../../../utils";
import { useSetData } from "../../../utils/hooks/data";
import CommonAddress from "../../../utils/commonAddress/commonAddress";
import { relations, memoryTypes } from "../../../utils/variables";
import { fileSizeInMB, fullName } from "../../../../utils/global";
import { initialValuesObj, validationSchemaObj, requestObject } from "./utils";
import AlertMessage from "../../../utils/AlertMessage/AlertMessage";
import MaskedPhoneNumber from "../../../utils/MaskedPhoneNumber";
import { ReactComponent as Photo } from "../../../../assets/images/photo.svg";
import { ReactComponent as Audio } from "../../../../assets/images/audio.svg";
import useGAEventTracker from "../../../utils/hooks/GAEventsTracker";
import { VALID_FILE_TYPES } from "./constants/helper";

const ShareMemory = () => {
    const [showVideoPreview, setShowVideoPreview] = useState(false);
    const [alertMessage, setAlertMessage] = useState(null);
    const [fileDrag, setFiles] = useState([]);
    const [imageName, setImageName] = useState([]);
    const [mediaToUpload, setMedia] = useState([]);
    const [videoLinkError, setVideoLinkError] = useState("");
    const [loader, setLoader] = useState(false);
    const { onePortalId } = useParams();

    const [redirectToMemoryHome, setMemoryHomeRedirect] = useState(null);
    const [acceptPolicy, setAcceptPolicies] = useState(false);
    const [progress, setProgress] = useState(0);
    const [showProgressBar, setShowProgressBar] = useState(false);
    const MAX_FILE_SIZE = 500 * 1024 * 1024;
    /**
     * Handle accept policies pop-up
     */
    const handlePolicies = () => {
        let haveAcceptPolicy = getPolicyAcceptance();
        if (!haveAcceptPolicy) {
            setAcceptPolicies(true);
        }
    };

    useEffect(() => {
        handlePolicies();
    }, []);

    /**
     * Get Decedent using API
     */
    const {
        data: decedentData,
        loading: decedentAPILoading,
        execute: executeDecedentAPI,
    } = useAsync({
        autoExecute: false,
        initialData: useMemo(() => null, []),
        task: decedentAPI,
        dataLoader: useCallback(response => {
            if (response) {
                return _.get(response, "data.decedent", {});
            }
        }, []),
    });

    useEffect(() => {
        if (onePortalId) executeDecedentAPI(onePortalId);
    }, []);

    useSetData();

    const states = store.getState();
    const { data } = states.loader;

    const { getRootProps, getInputProps } = useDropzone({
        accept: "audio/mpeg, audio/wav, audio/wave, video/mp4, video/*, audio/ogg, application/ogg, image/jpeg, image/png, image/gif,  video/mpeg, video/ogg, video/quicktime, video/mp4, video/x-flv,  video/x-matroska,  video/x-ms-asf, video/x-msvideo, .flv, */flv, .ogv, */ogv",
        multiple: true,
        onDrop: async acceptedFiles => {
            const filteredFiles = acceptedFiles.filter(file => {
                const fileType = file.type;
                let matches = null;
                matches = VALID_FILE_TYPES.find(type => {
                    return type.match(fileType) != null;
                });

                return !!matches;
            });
            if (!filteredFiles || filteredFiles.length == 0) {
                addNotification({
                    type: "danger",
                    title: "Error",
                    message: `Invalid File Format. Please check the file type and upload again.`,
                });
                return;
            }

            const validFiles = filteredFiles.filter(file => {
                const fileSize = file.size;
                const { name = "" } = file;
                return (
                    name &&
                    Number(fileSize) > 0 &&
                    Number(fileSize) <= MAX_FILE_SIZE
                );
            });
            if (!validFiles || validFiles.length == 0) {
                addNotification({
                    type: "danger",
                    title: "Error",
                    message: `File too large, Max file size is ${MAX_FILE_SIZE}MB.`,
                });
                return;
            }
            const fileName = validFiles.map(file => file.path);
            const formData = new FormData();
            if (fileName && fileName.length) {
                setShowProgressBar(true);
            }
            await _.map(validFiles, file => {
                formData.append("file", file);
            });
            trackUploadResponse(null);
            performUploadAPI(formData, validFiles);
            setImageName(prev => prev.concat(fileName));
            const fileArray = validFiles.map(file => {
                let { type } = file;
                type = type.split("/")[0];
                return {
                    src: URL.createObjectURL(file),
                    type,
                };
            });
            setFiles(prev => prev.concat(fileArray));
            Array.from(validFiles).map(file => URL.revokeObjectURL(file));
        },
    });

    const renderPhotos = source => {
        return source.map((file, i) => {
            const videoTitle = imageName[i];
            const length = 5;
            let trimVideoTitle =
                videoTitle.length > length
                    ? videoTitle.substring(0, 6) + "..." + videoTitle.slice(-4)
                    : videoTitle;

            return (
                <div className='upload-content-wrapper' key={i}>
                    <div style={thumbInner}>
                        <i
                            className='modal-close fas fa-times'
                            onClick={e => {
                                e.stopPropagation();
                                removeFile(e, file.src, i);
                                setProgress(0);
                                setLoadingInterval(clearInterval(interval));
                                setShowProgressBar(false);
                            }}
                        />
                        {file.type === "image" ? (
                            <img
                                style={thumb}
                                src={file.src}
                                key={file}
                                alt='thumbnail'
                            />
                        ) : file.type === "video" ? (
                            <video style={thumb} src={file.src} key={file} />
                        ) : (
                            <div style={audioThumb}>
                                <Audio style={thumbSvg} />
                            </div>
                        )}
                    </div>
                    <span>{trimVideoTitle}</span>
                </div>
            );
        });
    };

    const removeFile = (e, file, index) => {
        fileDrag.splice(index, 1);
        imageName.splice(index, 1);
        setImageName(imageName);
        setFiles(fileDrag);
        index = mediaToUpload.findIndex(e => e.file === file);
        mediaToUpload.splice(index, 1);
        setMedia(mediaToUpload);
        dropZoneRef.current.blur();
    };

    /**
     * Upload media API
     */
    let {
        data: uploadData,
        loading: uploadAPILoading,
        execute: performUploadAPI,
    } = useAsync({
        autoExecute: false,
        initialData: useMemo(() => null, []),
        task: useCallback(async (requestObj, files) => {
            if (requestObj && files && files.length) {
                return {
                    response: await uploadAPI(requestObj),
                    files,
                };
            }
        }, []),
        dataLoader: useCallback(({ response, files }) => {
            if (response && files && files.length) {
                return {
                    URL: _.get(response, "data.URL"),
                    files,
                };
            }
        }, []),
        onError: useCallback(error => {
            addNotification({
                type: "danger",
                title: "Error",
                message: _.get(
                    error,
                    "response.data.message",
                    "Oops! Something went wrong"
                ),
            });
        }, []),
    });

    useEffect(() => {
        if (uploadData) {
            trackUploadResponse(uploadData);
            const { URL, files } = uploadData;
            const addedFiles = [
                ...mediaToUpload,
                ..._.map(files, (file, i) => ({
                    file,
                    fileType: _.capitalize(file.type.split("/")[0]),
                    fileUrl: URL[i],
                })),
            ];
            setMedia(addedFiles);
        }
    }, [uploadData]);

    /**
     * Share memory API
     */
    const {
        data: shareMemoryData,
        loading: shareMemoryAPILoading,
        execute: performShareMemoryAPI,
    } = useAsync({
        autoExecute: false,
        initialData: useMemo(() => null, []),
        task: useCallback(
            requestObj => shareMemoryAPI(onePortalId, requestObj),
            []
        ),
        dataLoader: useCallback(response => _.get(response, "data"), []),
        onError: useCallback(error => {
            addNotification({
                type: "danger",
                title: "Error",
                message: _.get(
                    error,
                    "response.data.message",
                    "Oops! Something went wrong"
                ),
            });
        }, []),
    });

    useEffect(() => {
        if (!_.isEmpty(shareMemoryData)) {
            setAlertMessage({
                success: true,
                message: "Successfully shared a memory",
            });
        }
    }, [shareMemoryData]);

    const [interval, setLoadingInterval] = useState(null);
    const [uploadResponse, trackUploadResponse] = useState(null);

    useEffect(() => {
        if (uploadAPILoading) {
            if (!uploadResponse) {
                if (!interval && showProgressBar) {
                    setLoadingInterval(
                        setInterval(() => {
                            setProgress(oldValue => {
                                if (oldValue !== 90) {
                                    return oldValue + 10;
                                } else {
                                    return oldValue;
                                }
                            });
                        }, 1000)
                    );
                }
            } else {
                setLoadingInterval(clearInterval(interval));
                setProgress(100);
                addNotification({
                    type: "success",
                    title: "Success",
                    message: "Media Uploaded Successfully",
                });
            }
        } else {
            setLoadingInterval(clearInterval(interval));
            setProgress(0);
        }
    }, [
        JSON.stringify(uploadAPILoading),
        JSON.stringify(uploadResponse),
        showProgressBar,
    ]);

    const showLoading = useMemo(
        () => shareMemoryAPILoading || loader,
        [shareMemoryAPILoading, loader]
    );
    useSetLoading(showLoading);

    const name = fullName(decedentData);

    let location = useLocation();
    const handleCancelShareMemory = () => {
        let { pathname } = location;
        let path = pathname.split("/");
        path = _.remove(path, p => p !== "share");
        path = _.join(path, "/");
        setMemoryHomeRedirect(path);
    };

    const thumbsContainer = {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        cursor: "pointer",
    };

    const thumb = {
        width: 103,
        height: 103,
        borderRadius: 8,
    };
    const audioThumb = {
        width: 103,
        height: 103,
        borderRadius: 8,
        border: "3px solid grey",
        background: "lightgrey",
    };
    const thumbSvg = {
        height: "100%",
        width: "100%",
        padding: "0.4rem",
    };
    const thumbInner = {
        display: "flex",
        minWidth: 0,
        overflow: "hidden",
    };

    useEffect(
        () => () => {
            fileDrag.forEach(file => URL.revokeObjectURL(file.preview));
        },
        [fileDrag]
    );

    const dropZoneRef = useRef(null);

    const GAEventsTracker = useGAEventTracker("Memory Event");

    return redirectToMemoryHome ? (
        <Redirect to={redirectToMemoryHome} />
    ) : (
        <AppLayout
            decedent={decedentData || null}
            isPublic={true}
            showAcceptPolicy={acceptPolicy}
            handleDecline={handleCancelShareMemory}
            setLoader={setLoader}
        >
            {onePortalId && (
                <div className='share-a-memory-wrapper'>
                    <div className='cardbox-wrapper'>
                        <div className='cardbox-heading'>
                            <h4 className='cardbox-heading-name'>
                                Share Your Memories About {name}
                            </h4>
                        </div>

                        <Formik
                            enableReinitialize
                            initialValues={initialValuesObj}
                            validationSchema={validationSchemaObj()}
                            onSubmit={values => {
                                const obj = requestObject(
                                    values,
                                    mediaToUpload
                                );
                                if (videoLinkError) {
                                    addNotification({
                                        type: "danger",
                                        title: "Error",
                                        message: "Resolve all validation error",
                                    });
                                } else {
                                    performShareMemoryAPI(obj);
                                }
                                GAEventsTracker("share");
                            }}
                        >
                            {formik => {
                                const {
                                    values,
                                    handleChange,
                                    setFieldValue,
                                    setSubmitting,
                                    handleSubmit,
                                    errors,
                                    touched,
                                } = formik;

                                return (
                                    <Form
                                        id='primaryForm'
                                        onSubmit={handleSubmit}
                                    >
                                        <div className='inputcard-wrapper cardbox-body'>
                                            <div className='inputcard-body custom-inputcard-body'>
                                                <div className='inputcard-group d-flex align-items-center justify-content-start'>
                                                    <div className='choose-reason inputfield d-flex align-items-center justify-content-between'>
                                                        <div className='inputcard-withlabel d-flex align-items-start justify-content-center flex-column'>
                                                            <label className='inputcard-label'>
                                                                Type of Memory
                                                                <small className='ml-2 input-mandatory'>
                                                                    *
                                                                </small>
                                                            </label>
                                                            <SelectComponent
                                                                placeholder='Select a Type'
                                                                options={formatOptions(
                                                                    memoryTypes
                                                                )}
                                                                defaultValue={
                                                                    values.memoryType
                                                                }
                                                                selectedOption={
                                                                    values.memoryType
                                                                }
                                                                name='memoryType'
                                                                handleChange={e => {
                                                                    setFieldValue(
                                                                        "memoryType",
                                                                        e
                                                                    );
                                                                }}
                                                            />

                                                            {errors.memoryType &&
                                                                touched.memoryType && (
                                                                    <span className='error-input-msg'>
                                                                        {
                                                                            errors.memoryType
                                                                        }
                                                                    </span>
                                                                )}
                                                        </div>
                                                    </div>

                                                    <div className='choose-reason inputfield d-flex align-items-center justify-content-between'>
                                                        <div className='inputcard-withlabel d-flex align-items-start justify-content-center flex-column'>
                                                            <label className='inputcard-label'>
                                                                Date of Memory
                                                                <small className='ml-2 input-mandatory'>
                                                                    *
                                                                </small>
                                                            </label>
                                                            <div className='daterange-container w-100'>
                                                                <DatepickerComponent
                                                                    name='dateOfMemory'
                                                                    placeholderText='Choose a Date'
                                                                    selectedDate={
                                                                        values.dateOfMemory
                                                                    }
                                                                    onChange={e => {
                                                                        setFieldValue(
                                                                            "dateOfMemory",
                                                                            e
                                                                        );
                                                                        setSubmitting(
                                                                            false
                                                                        );
                                                                    }}
                                                                />
                                                            </div>

                                                            {errors.dateOfMemory &&
                                                                touched.dateOfMemory && (
                                                                    <span className='error-input-msg'>
                                                                        {
                                                                            errors.dateOfMemory
                                                                        }
                                                                    </span>
                                                                )}
                                                        </div>
                                                    </div>

                                                    <div className='choose-reason inputfield d-flex align-items-center justify-content-between'>
                                                        <div className='inputcard-withlabel d-flex align-items-start justify-content-center flex-column'>
                                                            <label className='inputcard-label'>
                                                                Relationship
                                                            </label>
                                                            <SelectComponent
                                                                placeholder='Choose a Relationship'
                                                                options={formatOptions(
                                                                    relations
                                                                )}
                                                                defaultValue={
                                                                    values.relationship
                                                                }
                                                                selectedOption={
                                                                    values.relationship
                                                                }
                                                                name='relationship'
                                                                handleChange={e => {
                                                                    setFieldValue(
                                                                        "relationship",
                                                                        e
                                                                    );
                                                                }}
                                                            />

                                                            {errors.relationship &&
                                                                touched.relationship && (
                                                                    <span className='error-input-msg'>
                                                                        {
                                                                            errors.relationship
                                                                        }
                                                                    </span>
                                                                )}
                                                        </div>
                                                    </div>

                                                    <div className='choose-reason inputfield d-flex align-items-center justify-content-between'>
                                                        <div className='inputcard-withlabel d-flex align-items-start justify-content-center flex-column'>
                                                            <label className='inputcard-label'>
                                                                Memory title
                                                                <small className='ml-2 input-mandatory'>
                                                                    *
                                                                </small>
                                                            </label>
                                                            <input
                                                                className='custominputfield'
                                                                type='text'
                                                                placeholder='Enter the title of memory'
                                                                name='title'
                                                                onChange={
                                                                    handleChange
                                                                }
                                                            />

                                                            {errors.title &&
                                                                touched.title && (
                                                                    <span className='error-input-msg'>
                                                                        {
                                                                            errors.title
                                                                        }
                                                                    </span>
                                                                )}
                                                        </div>
                                                    </div>

                                                    <div className='choose-reason inputfield d-flex align-items-center justify-content-between'>
                                                        <div className='inputcard-withlabel d-flex align-items-start justify-content-center flex-column your-memory-wrapper'>
                                                            <label className='inputcard-label'>
                                                                Your Memory of{" "}
                                                                {name}
                                                                <small className='ml-2 input-mandatory'>
                                                                    *
                                                                </small>
                                                            </label>
                                                            <textarea
                                                                className='memory-input custominputfield'
                                                                placeholder={`Enter the your memory of ${name}`}
                                                                type='text'
                                                                name='memory'
                                                                onChange={
                                                                    handleChange
                                                                }
                                                                row={4}
                                                            />

                                                            {errors.memory &&
                                                                touched.memory && (
                                                                    <span className='error-input-msg'>
                                                                        {
                                                                            errors.memory
                                                                        }
                                                                    </span>
                                                                )}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div id='preview-img'></div>
                                                <section className='upload-wrapper'>
                                                    <div
                                                        {...getRootProps({
                                                            className:
                                                                "dropzone",
                                                        })}
                                                        ref={dropZoneRef}
                                                    >
                                                        <input
                                                            {...getInputProps()}
                                                            onClick={e => {
                                                                if (progress) {
                                                                    e.preventDefault();
                                                                } else {
                                                                    getInputProps().onClick(
                                                                        e
                                                                    );
                                                                }
                                                            }}
                                                        />
                                                        {fileDrag.length ? (
                                                            ""
                                                        ) : (
                                                            <p>
                                                                <Photo /> Drop
                                                                your photo,
                                                                audio, video or
                                                                click to upload
                                                            </p>
                                                        )}
                                                        <aside
                                                            style={
                                                                thumbsContainer
                                                            }
                                                        >
                                                            {renderPhotos(
                                                                fileDrag
                                                            )}
                                                        </aside>
                                                    </div>
                                                </section>
                                                <section align='center'>
                                                    {progress > 0 &&
                                                        progress < 100 && (
                                                            <div>
                                                                <progress
                                                                    value={
                                                                        progress
                                                                    }
                                                                    max='100'
                                                                />
                                                                <span>
                                                                    {`Uploading ${progress}%`}
                                                                </span>
                                                            </div>
                                                        )}
                                                </section>
                                                <section align='center'>
                                                    <label className='label-input'>
                                                        Already have video on
                                                        YouTube or Vimeo? You
                                                        can include them in your
                                                        memory here.
                                                    </label>
                                                    {showVideoPreview && (
                                                        <div className='inputcard-group d-flex align-items-center justify-content-start'>
                                                            <div className='full-width choose-reason inputfield d-flex align-items-center justify-content-between'>
                                                                <div className='inputtag-field inputcard-withlabel d-flex align-items-start justify-content-center flex-column'>
                                                                    <InputTag
                                                                        tags={
                                                                            values.videoLink ||
                                                                            []
                                                                        }
                                                                        handleError={e =>
                                                                            setVideoLinkError(
                                                                                e
                                                                            )
                                                                        }
                                                                        error={
                                                                            videoLinkError
                                                                        }
                                                                        regex={
                                                                            /https:\/\/(?:www.)?(?:(vimeo).com\/(.*)|(youtube).com\/watch\?v=(.*?))/
                                                                        }
                                                                        handleChange={value => {
                                                                            if (
                                                                                !value.length
                                                                            ) {
                                                                                setVideoLinkError(
                                                                                    ""
                                                                                );
                                                                            }
                                                                            setFieldValue(
                                                                                "videoLink",
                                                                                value
                                                                            );
                                                                        }}
                                                                    />

                                                                    {videoLinkError && (
                                                                        <span className='error-msg'>
                                                                            {
                                                                                videoLinkError
                                                                            }
                                                                        </span>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                    {showVideoPreview &&
                                                    values.videoLink.length ? (
                                                        <VideoPreview
                                                            videoData={
                                                                values.videoLink
                                                            }
                                                            setFieldValue={
                                                                setFieldValue
                                                            }
                                                            setVideoLinkError={
                                                                setVideoLinkError
                                                            }
                                                        />
                                                    ) : (
                                                        ""
                                                    )}
                                                    <div
                                                        className='inputcard-group'
                                                        align='center'
                                                    >
                                                        <div
                                                            className='choose-reason inputfield'
                                                            align='center'
                                                        >
                                                            <button
                                                                type='button'
                                                                className='btn-theme text-uppercase'
                                                                onClick={() => {
                                                                    if (
                                                                        !showVideoPreview
                                                                    )
                                                                        setFieldValue(
                                                                            "videoLink",
                                                                            ""
                                                                        );

                                                                    setShowVideoPreview(
                                                                        !showVideoPreview
                                                                    );
                                                                }}
                                                            >
                                                                Add Video
                                                            </button>
                                                        </div>
                                                    </div>
                                                </section>

                                                <div className='inputcard-group d-flex align-items-center justify-content-start personal-details-wrapper'>
                                                    <div className='choose-reason inputfield d-flex align-items-center justify-content-between'>
                                                        <div className='inputcard-withlabel d-flex align-items-start justify-content-center flex-column'>
                                                            <label className='inputcard-label'>
                                                                First Name
                                                                <small className='ml-2 input-mandatory'>
                                                                    *
                                                                </small>
                                                            </label>
                                                            <input
                                                                className='custominputfield'
                                                                type='text'
                                                                placeholder='Enter the First Name'
                                                                name='firstName'
                                                                onChange={
                                                                    handleChange
                                                                }
                                                            />

                                                            {errors.firstName &&
                                                                touched.firstName && (
                                                                    <span className='error-input-msg'>
                                                                        {
                                                                            errors.firstName
                                                                        }
                                                                    </span>
                                                                )}
                                                        </div>
                                                    </div>

                                                    <div className='choose-reason inputfield d-flex align-items-center justify-content-between'>
                                                        <div className='inputcard-withlabel d-flex align-items-start justify-content-center flex-column'>
                                                            <label className='inputcard-label'>
                                                                Last Name
                                                                <small className='ml-2 input-mandatory'>
                                                                    *
                                                                </small>
                                                            </label>
                                                            <input
                                                                className='custominputfield'
                                                                type='text'
                                                                placeholder='Enter the last Name'
                                                                name='lastName'
                                                                onChange={
                                                                    handleChange
                                                                }
                                                            />

                                                            {errors.lastName &&
                                                                touched.lastName && (
                                                                    <span className='error-input-msg'>
                                                                        {
                                                                            errors.lastName
                                                                        }
                                                                    </span>
                                                                )}
                                                        </div>
                                                    </div>

                                                    <div className='choose-reason inputfield d-flex align-items-center justify-content-between'>
                                                        <div className='inputcard-withlabel d-flex align-items-start justify-content-center flex-column'>
                                                            <label className='inputcard-label'>
                                                                Email
                                                                <small className='ml-2 input-mandatory'>
                                                                    *
                                                                </small>
                                                            </label>
                                                            <input
                                                                className='custominputfield'
                                                                type='text'
                                                                placeholder='Enter the Email Address'
                                                                name='email'
                                                                onChange={
                                                                    handleChange
                                                                }
                                                            />

                                                            {errors.email &&
                                                                touched.email && (
                                                                    <span className='error-input-msg'>
                                                                        {
                                                                            errors.email
                                                                        }
                                                                    </span>
                                                                )}
                                                        </div>
                                                    </div>

                                                    <div className='choose-reason inputfield d-flex align-items-center justify-content-between'>
                                                        <div className='inputcard-withlabel d-flex align-items-start justify-content-center flex-column'>
                                                            <label className='inputcard-label'>
                                                                Phone
                                                            </label>
                                                            {/* <input
                                                                className="custominputfield"
                                                                type="text"
                                                                placeholder="Enter the Phone Number"
                                                                name="phoneNumber"
                                                                onChange={
                                                                    handleChange
                                                                }
                                                            /> */}
                                                            <MaskedPhoneNumber
                                                                className='custominputfield'
                                                                type='text'
                                                                name='phoneNumber'
                                                                formInput
                                                                placeholder='Enter the Phone Number'
                                                                value={
                                                                    values.phoneNumber
                                                                }
                                                                onChange={value => {
                                                                    setFieldValue(
                                                                        "phoneNumber",
                                                                        value
                                                                    );
                                                                }}
                                                            />

                                                            {errors.phoneNumber &&
                                                                touched.phoneNumber && (
                                                                    <span className='error-input-msg'>
                                                                        {
                                                                            errors.phoneNumber
                                                                        }
                                                                    </span>
                                                                )}
                                                        </div>
                                                    </div>

                                                    <CommonAddress
                                                        isDisabled={false}
                                                        setFieldValue={
                                                            setFieldValue
                                                        }
                                                        values={values}
                                                        data={data || {}}
                                                    />
                                                </div>
                                            </div>

                                            <div className='inputcard-footer'>
                                                <button
                                                    type='button'
                                                    onClick={
                                                        handleCancelShareMemory
                                                    }
                                                    className='btn-theme btn-cancel-wrapper text-uppercase'
                                                >
                                                    Cancel
                                                </button>

                                                <button
                                                    type='submit'
                                                    disabled={progress !== 0}
                                                    className='btn-theme text-uppercase'
                                                >
                                                    Share
                                                </button>
                                            </div>

                                            <div className='note-wrapper'>
                                                *All submissions will be
                                                reviewed by management prior to
                                                posting.
                                            </div>
                                        </div>
                                    </Form>
                                );
                            }}
                        </Formik>
                    </div>
                </div>
            )}

            {alertMessage && (
                <AlertMessage
                    toggleAlertModal={() => {
                        setAlertMessage(null);
                        handleCancelShareMemory();
                    }}
                    toggleAlertState
                    successMsg={alertMessage.message}
                    showCancelButton={false}
                    messageState={alertMessage.success}
                />
            )}
        </AppLayout>
    );
};

const VideoPreview = ({ videoData, setFieldValue, setVideoLinkError }) => {
    const [errorObj, setError] = useState([]);

    useEffect(() => {
        let flag = false;
        _.map(errorObj, obj => {
            if (_.get(obj, "error")) {
                flag = true;
                setVideoLinkError(_.get(obj, "error"));
            }
        });
        if (!flag) {
            setVideoLinkError("");
        }
    }, [JSON.stringify(errorObj)]);

    useEffect(() => {
        let obj = _.map(videoData, (v, i) => ({
            link: v,
            error: _.get(errorObj[i], "error", ""),
        }));
        setError(obj);
    }, [videoData]);

    return videoData.map((videoUrl, key) => {
        return (
            <div className='inputcard-group' align='center'>
                <VideoThumbnail
                    link={videoUrl}
                    handleClose={() => {
                        const filterredVideos = _.filter(
                            videoData,
                            ele => ele !== videoUrl
                        );
                        setFieldValue("videoLink", filterredVideos);
                    }}
                    key={key}
                    setVideoError={setVideoLinkError}
                    setFieldError={msg => {
                        let err = errorObj || [];
                        err[key] = {
                            link: videoUrl,
                            error: msg,
                        };
                        setError(err);
                    }}
                />
            </div>
        );
    });
};

export default ShareMemory;
