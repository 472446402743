import React, { useEffect, useState, useRef } from "react";
import { Modal, ModalBody } from "reactstrap";

const RejectionModal = ({ setIsRejected, handleRejection }) => {
    const [reason, setReason] = useState("");
    const [error, setError] = useState("");
    const firstUpdate = useRef(true);

    useEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
            return;
        }
        if (reason) {
            setError("");
        } else {
            setError("Reason is required*");
        }
    }, [reason]);

    const handleChange = (event) => {
        setReason(event.target.value);
    };

    return (
        <Modal
            isOpen
            backdrop="static"
            className="rejection-modal"
            //style={{ width: "530px" }}
        >
            <ModalBody>
                <i
                    className="fas fa-times close-button"
                    onClick={() => setIsRejected(false)}
                />
                <div className="rejection-body">
                    <h1 className="rejection-header">Reason for Rejection</h1>
                    <div className="description-container">
                        <h1 className="description-text">
                            Description<span className="required">*</span>
                        </h1>
                        <textarea
                            className="description-input"
                            placeholder="Type the reason for Rejection"
                            onChange={handleChange}
                            value={reason}
                        />
                        <span className="input-error-txt">{error}</span>
                    </div>
                    <div className="footer-buttons-tab">
                        <button
                            className="modal-footer-button cancel-button"
                            onClick={() => setIsRejected(false)}
                        >
                            Cancel
                        </button>
                        <button
                            className="modal-footer-button reject-button"
                            onClick={() =>
                                !reason
                                    ? setError("Reason is required*")
                                    : handleRejection(reason)
                            }
                        >
                            Reject
                        </button>
                    </div>
                </div>
            </ModalBody>
        </Modal>
    );
};

export default RejectionModal;
