import React, { useCallback, useMemo, useState } from "react";
import _ from "lodash";
import useAsync from "react-use-async-hook";

import * as DecedentAPI from "../../../../api/decedent";
import { useSetLoading } from "../../../utils/hooks/loader";
import addNotification from "../../../utils/notification/notification";
import MotherDetails from "./motherDetails";
import FatherDetails from "./fatherDetails";
import { firstTimeSaveText, MOTHER, FATHER } from "../constants/constants";

const ParentsDetails = ({
    states,
    countries,
    decedent,
    isDisable,
    decedentId,
    refreshDecedent,
    showCertificateSavePopup,
    enableSubmitButton,
}) => {
    /**
     * Save Parents Details
     */
    const isMotherFieldAlreadySaved = _.get(decedent, "mother.isFirstSaved");
    const [isMotherDataFirstSaved, setIsMotherDataFirstSaved] = useState(
        isMotherFieldAlreadySaved
    );

    const isFatherFieldAlreadySaved = _.get(decedent, "father.isFirstSaved");
    const [isFatherDataFirstSaved, setIsFatherDataFirstSaved] = useState(
        isFatherFieldAlreadySaved
    );

    if (
        typeof isMotherDataFirstSaved === "undefined" &&
        isMotherDataFirstSaved !== isMotherFieldAlreadySaved
    ) {
        setIsMotherDataFirstSaved(isMotherFieldAlreadySaved);
    }

    if (
        typeof isFatherDataFirstSaved === "undefined" &&
        isFatherDataFirstSaved !== isFatherFieldAlreadySaved
    ) {
        setIsFatherDataFirstSaved(isFatherFieldAlreadySaved);
    }
    const { loading: updateLoader, execute: executeUpdate } = useAsync({
        autoExecute: false,
        task: useCallback(
            async (
                values,
                decedentId,
                formikBag,
                isFatherDataFirstSaved,
                isMotherDataFirstSaved,
                showCertificateSavePopup
            ) => {
                let payload = {
                    birthCountry: values.birthCountry
                        ? values.birthCountry.value
                        : "",
                    birthState: values.birthState
                        ? values.birthState.value
                        : "",
                    firstName: values.firstName,
                    lastName: values.lastName,
                    maidenName: values.maidenName,
                    middleName: values.middleName,
                    relation: values.relation,
                };
                return {
                    res: await DecedentAPI.saveParentDetails(
                        decedentId,
                        payload
                    ),
                    relation: values.relation,
                    formikBag,
                    isFatherDataFirstSaved,
                    isMotherDataFirstSaved,
                    showCertificateSavePopup,
                };
            },
            []
        ),
        dataLoader: useCallback(
            ({
                res,
                relation,
                formikBag,
                isFatherDataFirstSaved,
                isMotherDataFirstSaved,
                showCertificateSavePopup,
            }) => {
                formikBag.setSubmitting(false);
                refreshDecedent();
                enableSubmitButton();
                if (relation.toLowerCase() === FATHER) {
                    if (isFatherDataFirstSaved) {
                        addNotification({
                            type: "success",
                            title: "Success",
                            message: firstTimeSaveText,
                        });
                    } else {
                        showCertificateSavePopup();
                        setIsFatherDataFirstSaved(true);
                    }
                } else if (relation.toLowerCase() === MOTHER) {
                    if (isMotherDataFirstSaved) {
                        addNotification({
                            type: "success",
                            title: "Success",
                            message: firstTimeSaveText,
                        });
                    } else {
                        showCertificateSavePopup();
                        setIsMotherDataFirstSaved(true);
                    }
                }
                addNotification({
                    type: "success",
                    title: "Success",
                    message: `${relation} Details Updated!`,
                });
            },
            []
        ),
        onError: useCallback(error => {
            addNotification({
                type: "danger",
                title: "Error",
                message: _.get(
                    error,
                    "response.data.message",
                    "Oops! Something went wrong"
                ),
            });
        }, []),
    });

    const showLoader = useMemo(() => updateLoader, [updateLoader]);
    useSetLoading(showLoader);

    const handleParentDetailSubmit = async (values, formikBag) => {
        executeUpdate(
            values,
            decedentId,
            formikBag,
            isFatherDataFirstSaved,
            isMotherDataFirstSaved,
            showCertificateSavePopup
        );
    };

    return (
        <div className='parentsdetails-wrapper'>
            <div className='cardbox-wrapper'>
                <div className='cardbox-heading'>
                    <h4 className='cardbox-heading-name text-capitalize text-black'>
                        <span className='item-numbering'>6</span>
                        Parents Details
                    </h4>
                </div>
                <div className='inputcard-wrapper cardbox-body'>
                    <div className='subdetails-wrapper'>
                        <h5 className='text-capitalize'>Mother Details</h5>
                        <MotherDetails
                            states={states}
                            countries={countries}
                            decedent={_.get(decedent, "mother")}
                            handleSubmitDetail={handleParentDetailSubmit}
                            isDisable={isDisable}
                        />
                    </div>
                    <div className='subdetails-wrapper'>
                        <h5 className='text-capitalize'>Father Details</h5>
                        <FatherDetails
                            states={states}
                            countries={countries}
                            decedent={_.get(decedent, "father")}
                            handleSubmitDetail={handleParentDetailSubmit}
                            isDisable={isDisable}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ParentsDetails;
