import _ from 'lodash';
import { apiFetch } from '../configs/apiConfig';
import { API_URL } from '../constants/appConstants';

export const getMemoriesData = (params, status) => {
    return apiFetch({
        url: `${API_URL}/admin/memories/${status}`,
        method: "GET",
        params
    })
}

export const getDecedentsList = (params) => {
    params = _.pickBy(params, _.identity)
    return apiFetch({
        url: `${API_URL}/admin/decedents`,
        method: "GET",
        params
    })
}

export const getMemory = (memoryId) => {
    return apiFetch({
        url: `${API_URL}/admin/memory/${memoryId}`,
        method: 'GET',
    });
};

export const acceptOrRejectMemory = (status, memoryId, reason) => {
    let data = {
        status: status,
    };
    if (reason) {
        data = {
            status: status,
            rejectedReason: reason,
        };
    }
    return apiFetch({
        url: `${API_URL}/admin/memory/${memoryId}/status`,
        method: 'PUT',
        data,
    });
};

export const editMemory = (data, memoryId, isTitle = false) => {
    let reqBody = {};
    if (isTitle) reqBody.title = data
    else reqBody.memory = data
    return apiFetch({
        url: `${API_URL}/admin/memory/${memoryId}`,
        method: 'PUT',
        data: reqBody,
    });
};

export const deleteImageAPI = (memoryId, fileId) => {
    return apiFetch({
        url: `${API_URL}/admin/memory/${memoryId}/file/${fileId}`,
        method: 'DELETE',
    });
};

export const addImageAPI = (memoryId, data) => {
    return apiFetch({
        url: `${API_URL}/admin/memory/${memoryId}/addImage`,
        method: 'POST',
        data,
    });
};

export const rotateImageAPI = (memoryId, data) => {
    return apiFetch({
        url: `${API_URL}/admin/memory/${memoryId}/rotateImage`,
        method: 'POST',
        data,
    });
};

export const usersListAPI = (params) => {
    params = _.pickBy(params, _.identity)
    return apiFetch( {
        url: `${API_URL}/admin/loginList`,
        method: "GET",
        params
    })
};

export const adminUsersListAPI = (params) => {
    return apiFetch({
        url: `${API_URL}/admin/adminReport`,
        method: 'GET',
        params,
    });
};

export const editLocationCode = (opi,data) => {
    return apiFetch({
        url :`${API_URL}/decedent/${opi}/location`,
        method:'PUT',
        data
    })
}
