import React, { Component } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

const AlertMessageForMemory = (props) => {
    const messageState = props.messageState;
    return (
        <Modal
            isOpen={props.toggleAlertState}
            toggle={props.toggleAlertModal}
            className='AlertMessage-wrapper'
            backdrop='static'
        >
            <form className='trust-form'>
                <ModalHeader>
                    <i
                        className='modal-close fas fa-times'
                        onClick={props.toggleAlertModal}
                    />
                    <span
                        className={
                            "modal-memory-headername" +
                            (messageState ? " theme-primary" : " theme-error")
                        }
                    >
                        <i
                            className={
                                "mr-2 fas" +
                                (messageState
                                    ? " fa-check-circle theme-primary"
                                    : " fa-exclamation-circle theme-error")
                            }
                        />
                        {props.title || "Message"}
                    </span>
                </ModalHeader>
                <ModalBody>
                    {props.successMsg ? props.successMsg : " "}
                </ModalBody>
                <ModalFooter>
                    {props.showCancelButton ? (
                        <button
                            onClick={props.toggleAlertModal}
                            type='button'
                            className='btn-theme btn-cancel'
                        >
                            Cancel
                        </button>
                    ) : (
                        ""
                    )}
                    <button
                        onClick={() =>
                            !props.deleteState
                                ? props.toggleAlertModal()
                                : props.toggleOk()
                        }
                        type='button'
                        className='btn-theme'
                    >
                        {props.okButtonText || "OK"}
                    </button>
                </ModalFooter>
            </form>
        </Modal>
    );
};
export default AlertMessageForMemory
