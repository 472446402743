import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from "react";
import { useDropzone } from "react-dropzone";
import { VALID_FILE_TYPES } from "../../../components/features/familyWebsite/shareMemory/constants/helper";
import _ from "lodash";
import useAsync from "react-use-async-hook";
import { uploadAPI } from "../../../api/memories";
import addNotification from "../../utils/notification/notification";
import { ReactComponent as Photo } from "../../../assets/images/drop-icon.svg";
import { ReactComponent as Audio } from "../../../assets/images/audio.svg";
import CloudUploadSvg from "../../../assets/images/fileThumbnail.svg";
import VideoThumbnail from "../familyWebsite/shareMemory/videoThumbnail";
import InputTag from "../../utils/InputTag";

const UploadMedia = ({
  values,
  setFieldValue,
  mediaToUpload,
  setMedia,
  progress,
  setProgress,
  videoLinkError,
  setVideoLinkError,
  acceptAllMedia = false,
}) => {
  const dropZoneRef = useRef(null);
  const MAX_FILE_SIZE = 500 * 1024 * 1024;
  const [showProgressBar, setShowProgressBar] = useState(false);
  const [interval, setLoadingInterval] = useState(null);
  const [uploadResponse, trackUploadResponse] = useState(null);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [imageName, setImageName] = useState([]);
  const [isError, setError] = useState(false);
  const [fileDrag, setFiles] = useState([]);
  const [showVideoPreview, setShowVideoPreview] = useState(false);

  useEffect(
    () => () => {
      fileDrag.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [fileDrag]
  );

  const thumbsContainer = {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    cursor: "pointer",
    marginBottom: "80px",
  };

  const thumb = {
    width: 103,
    height: 103,
    objectFit: "cover",
    borderRadius: 8,
  };
  const audioThumb = {
    width: 103,
    height: 103,
    borderRadius: 8,
    border: "3px solid grey",
    background: "lightgrey",
  };
  const thumbSvg = {
    height: "100%",
    width: "100%",
    padding: "0.4rem",
  };
  const thumbDocument = {
    height: "103px",
    width: "103px",
    padding: "0.4rem",
    objectFit: "contain",
  };
  const thumbInner = {
    display: "flex",
    minWidth: 0,
    overflow: "hidden",
  };

  const removeFile = (e, file, index, flag) => {
    const newFileDrag = [...fileDrag]
    newFileDrag?.splice(index,1)
    imageName.splice(index, 1);
    setImageName(imageName);
    setFiles(newFileDrag);
    const newMedia = [...mediaToUpload]
    if (flag) {
      newMedia?.splice(index,1)
    }
    setMedia(newMedia);
    dropZoneRef.current.blur();
  };

  const renderPhotos = (source) => {
    source.push();
    return source.map((file, i) => {
      const videoTitle = imageName[i];
      const length = 5;
      let trimVideoTitle =
        videoTitle.length > length
          ? videoTitle.substring(0, 6) + "..." + videoTitle.slice(-4)
          : videoTitle;

      return (
        <div className="upload-content-wrapper" key={i}>
          <div style={thumbInner}>
            {file.type === "image" && (
              <img style={thumb} src={file.src} key={file} alt="thumbnail" />
            )}
            {file.type === "video" && (
              <video style={thumb} src={file.src} key={file} />
            )}{" "}
            {file.type === "audio" && (
              <div style={audioThumb}>
                <Audio style={thumbSvg} />
              </div>
            )}
            {!["image", "audio", "video"].includes(file?.type) && (
              <div>
                <img
                  src={CloudUploadSvg}
                  alt="documentUpload"
                  style={thumbDocument}
                ></img>
              </div>
            )}
            <button
              className="removeFile"
              type="button"
              disabled={mediaToUpload[i]?.fileUrl ? false : true}
              onClick={(e) => {
                e.stopPropagation();
                removeFile(e, file.src, i, true);
                setProgress(0);
                setLoadingInterval(clearInterval(interval));
                setShowProgressBar(false);
              }}
            >
              <i className="modal-close fas fa-times" />
            </button>
          </div>
          <span>{trimVideoTitle}</span>
        </div>
      );
    });
  };

  /**
   * Upload media API
   */
  let {
    data: uploadData,
    loading: uploadAPILoading,
    execute: performUploadAPI,
  } = useAsync({
    autoExecute: false,
    initialData: useMemo(() => null, []),
    task: useCallback(async (requestObj, files) => {
      if (requestObj && files && files.length) {
        if (acceptAllMedia) {
          return {
            response: await uploadAPI(requestObj, true),
            files,
          };
        } else {
          return {
            response: await uploadAPI(requestObj),
            files,
          };
        }
      }
    }, []),
    dataLoader: useCallback(({ response, files }) => {
      if (response && files && files.length) {
        return {
          URL: _.get(response, "data.URL"),
          files,
        };
      }
    }, []),
    onError: useCallback((error) => {
      setError(true);
      addNotification({
        type: "danger",
        title: "Error",
        message: _.get(
          error,
          "response.data.message",
          "Oops! Something went wrong"
        ),
      });
    }, []),
  });

  useEffect(() => {
    const flag = false;
    if (isError === true) {
      setTimeout(async () => {
        const lastIndex = mediaToUpload?.length;
        await Promise.all(
          uploadedFiles?.map((file, index) => {
            removeFile("", file, lastIndex, flag);
          })
        );
        setError(false);
      }, 1000 * 3);
    }
  }, [isError]);

  useEffect(() => {
    if (uploadData) {
      trackUploadResponse(uploadData);
      const { URL, files } = uploadData;
      const addedFiles = [
        ...mediaToUpload,
        ..._.map(files, (file, i) => ({
          file,
          fileType: _.capitalize(file.type.split("/")[0]),
          originalFileUrl: URL[i],
          fileUrl: URL[i],
        })),
      ];
      setMedia(addedFiles);
    }
  }, [uploadData]);

  const { getRootProps, getInputProps } = useDropzone({
    accept: `audio/mpeg, audio/wav, audio/wave, video/mp4, video/*, audio/ogg, application/ogg, image/jpeg, image/png, image/gif,  video/mpeg, video/ogg, video/quicktime, video/mp4, video/x-flv,  video/x-matroska,  video/x-ms-asf, video/x-msvideo, .flv, */flv, .ogv, */ogv,application/pdf`,
    multiple: true,
    onDrop: async (acceptedFiles) => {
      let filteredFiles = acceptedFiles;
      if (!acceptAllMedia) {
        filteredFiles = acceptedFiles.filter((file) => {
          const fileType = file.type;
          let matches = null;
          matches = VALID_FILE_TYPES.find((type) => {
            return type.match(fileType) != null;
          });

          return !!matches;
        });
      }

      if (!filteredFiles || filteredFiles.length == 0) {
        addNotification({
          type: "danger",
          title: "Error",
          message: `Invalid File Format. Please check the file type and upload again.`,
        });
        return;
      }

      const validFiles = filteredFiles.filter((file) => {
        const fileSize = file.size;
        const { name = "" } = file;
        return (
          name && Number(fileSize) > 0 && Number(fileSize) <= MAX_FILE_SIZE
        );
      });
      if (!validFiles || validFiles.length == 0) {
        addNotification({
          type: "danger",
          title: "Error",
          message: `File too large, Max file size is ${MAX_FILE_SIZE}MB.`,
        });
        return;
      }
      const fileName = validFiles.map((file) => file.path);
      const formData = new FormData();
      if (fileName && fileName.length) {
        setShowProgressBar(true);
      }
      await _.map(validFiles, (file) => {
        formData.append("file", file);
      });
      trackUploadResponse(null);
      setUploadedFiles(validFiles);
      performUploadAPI(formData, validFiles);
      setImageName((prev) => prev.concat(fileName));
      const fileArray = validFiles.map((file) => {
        let { type } = file;
        type = type.split("/")[0];
        return {
          src: URL.createObjectURL(file),
          type,
        };
      });
      setFiles((prev) => prev.concat(fileArray));
      Array.from(validFiles).map((file) => URL.revokeObjectURL(file));
    },
  });

  useEffect(() => {
    if (uploadAPILoading) {
      if (!uploadResponse) {
        if (!interval && showProgressBar) {
          setLoadingInterval(
            setInterval(() => {
              setProgress((oldValue) => {
                if (oldValue !== 90) {
                  return oldValue + 10;
                } else {
                  return oldValue;
                }
              });
            }, 1000)
          );
        }
      } else {
        setLoadingInterval(clearInterval(interval));
        setProgress(100);
        addNotification({
          type: "success",
          title: "Success",
          message: "Media Uploaded Successfully",
        });
      }
    } else {
      setLoadingInterval(clearInterval(interval));
      setProgress(0);
    }
  }, [
    JSON.stringify(uploadAPILoading),
    JSON.stringify(uploadResponse),
    showProgressBar,
  ]);

  return (
    <div>
      <div className="memory-gallery">
        <section className="upload-wrapper">
          <div
            {...getRootProps({
              className: "dropzone",
            })}
            ref={dropZoneRef}
          >
            <input
              {...getInputProps()}
              onClick={(e) => {
                if (progress) {
                  e.preventDefault();
                } else {
                  getInputProps().onClick(e);
                }
              }}
            />
            {fileDrag.length ? (
              ""
            ) : (
              <p>
                <Photo /> Drag and Drop a photo / Video / Pdf / Docx, or{" "}
                <span className="upload-picture">Upload your file</span>
              </p>
            )}
            <aside style={thumbsContainer}>
              {renderPhotos(fileDrag)}
              {fileDrag.length ? (
                <div className="add-image">
                  <span>+</span>
                </div>
              ) : (
                ""
              )}
            </aside>
          </div>
        </section>
        <section align="center">
          {progress > 0 && progress < 100 && (
            <div>
              <progress value={progress} max="100" />
              <span>{`Uploading ${progress}%`}</span>
            </div>
          )}
        </section>
      </div>
      <div className="memory-gallery">
        <section align="center" className="video-upload">
          <label className="label-input">
            Already have video on YouTube or Vimeo or Tribute? You can include
            them in your memory here.
          </label>
          <div className="inputcard-group d-flex align-items-center justify-content-start">
            <div className="full-width choose-reason inputfield">
              <div className="inputtag-field inputcard-withlabel d-flex align-items-center justify-content-center flex-row">
                <InputTag
                  tags={values?.videoLink || []}
                  handleError={(e) => setVideoLinkError(e)}
                  error={videoLinkError}
                  regex={
                    /https:\/\/(?:www.)?(vimeo\.com\/(.*)|(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$|tributeslides\.com\/videos\/embedded_video\/(.*)|tributeslides\.com\/tributes\/show\/(.*))/
                  }
                  // regex={
                  //     /https:\/\/(?:www.)?(vimeo\.com\/(.*)|youtu(be\.com|\.be)\/watch\?v=(.*?)|tributeslides\.com\/(.*))/
                  // }
                  // regex={
                  //     /https:\/\/(?:www.)?(?:(vimeo).com\/(.*)|(youtube).com\/watch\?v=(.*?))/
                  // }
                  handleChange={(value) => {
                    if (!value.length) {
                      setVideoLinkError("");
                    }
                    setFieldValue("videoLink", value);
                    setShowVideoPreview(true);
                  }}
                />
                <button
                  type="button"
                  className="add-video-button"
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                >
                  Add Video
                </button>

                {videoLinkError && (
                  <span className="error-msg">{videoLinkError}</span>
                )}
              </div>
            </div>
          </div>
          {showVideoPreview && values?.videoLink?.length ? (
            <VideoPreview
              videoData={values?.videoLink}
              setFieldValue={setFieldValue}
              setVideoLinkError={setVideoLinkError}
            />
          ) : (
            ""
          )}
          <div className="inputcard-group" align="center">
            <div className="choose-reason inputfield" align="center"></div>
          </div>
        </section>
      </div>
    </div>
  );
};

const VideoPreview = ({ videoData, setFieldValue, setVideoLinkError }) => {
  const [errorObj, setError] = useState([]);

  useEffect(() => {
    let flag = false;
    _.map(errorObj, (obj) => {
      if (_.get(obj, "error")) {
        flag = true;
        setVideoLinkError(_.get(obj, "error"));
      }
    });
    if (!flag) {
      setVideoLinkError("");
    }
  }, [JSON.stringify(errorObj)]);

  useEffect(() => {
    let obj = _.map(videoData, (v, i) => ({
      link: v,
      error: _.get(errorObj[i], "error", ""),
    }));
    setError(obj);
  }, [videoData]);

  return videoData.map((videoUrl, key) => {
    return (
      <div className="inputcard-group" align="center">
        <VideoThumbnail
          link={videoUrl}
          handleClose={() => {
            const filterredVideos = _.filter(
              videoData,
              (ele) => ele !== videoUrl
            );
            setFieldValue("videoLink", filterredVideos);
          }}
          key={key}
          setVideoError={setVideoLinkError}
          setFieldError={(msg) => {
            let err = errorObj || [];
            err[key] = {
              link: videoUrl,
              error: msg,
            };
            setError(err);
          }}
        />
      </div>
    );
  });
};
export default UploadMedia;
