import { apiFetch } from "../configs/apiConfig";
import { API_URL } from "../constants/appConstants";

export const updateDonationDetails = (formData,opi) => {
    return apiFetch ({
        method: 'POST',
        url: `${API_URL}/decedent/${opi}/donationUrl`,
        data: formData,
    })
}

export const deleteDonationDetails = (opi) => {
    return apiFetch ({
        method: 'DELETE',
        url : `${API_URL}/decedent/${opi}/deleteDonationDetails`,
    })
}

export const updateSocialMediaDetails = (formData,opi) => {
    return apiFetch ({
        method: "POST",
        url: `${API_URL}/decedent/${opi}/addSocialMedia`,
        data: formData,
    })
}
export const addSubscriber = (formData,opi,timeZone) => {
    return apiFetch ({
        method: "POST",
        url: `${API_URL}/decedent/${opi}/addSubscriber?timeZone=${timeZone}`,
        data: formData,
    })
}

export const checkToGenerateCaptcha = (opi,timeZone) => {
    return apiFetch ({
        method: "GET",
        url: `${API_URL}/decedent/${opi}/checkCountForCaptcha?timeZone=${timeZone}`,
    })
}

export const getSubscribersCount = (opi) => {
    return apiFetch ({
        method: "GET",
        url: `${API_URL}/decedent/${opi}/getCountOfSubscribers`,
    })
}

export const saveMessage = (formData,opi,timeZone) => {
    return apiFetch({
        method: "POST",
        url: `${API_URL}/decedent/${opi}/saveMessage?timezone=${timeZone}`,
        data: formData,
    })
}

export const getCount = (opi,timeZone) => {
    return apiFetch({
        method: "GET",
        url: `${API_URL}/decedent/${opi}/getMessageCount?timezone=${timeZone}`,
    })
}

export const getListOfMessages = (opi) => {
    return apiFetch({
        method: "GET",
        url: `${API_URL}/decedent/${opi}/getListOfMessages`,
    })
}

export const deleteMessage = (id,opi) => {
    return apiFetch({
        method: "POST",
        url: `${API_URL}/decedent/${opi}/deleteMessage`,
        data: id,
    })
}

export const hideContactFamily = (opi,data) => {
    return apiFetch({
        method: "POST",
        url: `${API_URL}/decedent/${opi}/hideContactFamily`,
        data: data,
    })
}