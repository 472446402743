import { string, object } from "yup";
import _ from "lodash";
import moment from "moment";
import { emailRegex, phoneRegex } from "../../../../constants/regex";

export const initialValuesObj = {
    memoryType: null,
    dateOfMemory: null,
    relationship: null,
    title: "",
    memory: "",
    videoLink: [],
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    line1: "",
    line2: "",
    city: "",
    state: "",
    zipcode: "",
};

export const validationSchemaObj = () => {
    const obj = {
        memoryType: string().nullable().required("Type of memory is required"),
        dateOfMemory: string()
            .nullable()
            .required("Date of memory is required"),
        title: string()
            .trim()
            .max(254, "Memory title should be less than 255 characters")
            .required("Memory title is required"),
        email: string()
            .required("Email is required")
            .matches(emailRegex, "Invalid Email Address"),
        firstName: string()
            .trim()
            .max(50, "First Name should be less than 50 characters")
            .required("First Name is required"),
        lastName: string()
            .trim()
            .max(50, "Last Name should be less than 50 characters")
            .required("Last Name is required"),
        memory: string()
            .trim()
            .max(4999, "Memory should be less than 5000 characters")
            .required("Memory is required"),
        videoLink: string("Enter valid data"),
    };

    return object(obj);
};

export const requestObject = (values, files) => {
    let filesToSave = [];
    filesToSave = files.map(({ file, ...rest }) => rest);
    if (values.videoLink) {
        let videos = values.videoLink;
        videos.forEach(video => {
            filesToSave = _.concat(filesToSave, [
                {
                    fileUrl: video,
                    fileType: "Video",
                },
            ]);
        });
    }
    return {
        memoryType: _.get(values, "memoryType.value"),
        dateOfMemory: moment(values.dateOfMemory).format("YYYY-MM-DD"),
        title: values.title,
        memory: values.memory,
        relationship: _.get(values, "relationship.value"),
        memoryAddingPerson: {
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            phoneNumber: values.phoneNumber,
            address: {
                line1: values.line1,
                line2: values.line2,
                country: values?.country?.value,
                state: values?.state?.value,
                county: values?.county?.value,
                city: values?.city?.value,
                zipcode: values?.zipcode?.value,
            },
        },
        files: filesToSave,
    };
};
