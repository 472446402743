import { Button, Col, Container, Row } from 'reactstrap';
import { toMb } from '../../../utils/helper';

const DropzoneFileCard = ({ file, uploadProgress, onFileRemove }) => {
  const uploadPercent = file
    ? `${((uploadProgress / file?.size) * 100).toFixed()}%`
    : 0;

  return (
    <Container className="dropzone-file-card">
      <Row>
        <Col xs={1}>
          <i className="fas fa-file-word"></i>
        </Col>
        <Col xs={9}>
          <div className="text-truncate" title={file?.name}>
            {file?.name}
          </div>
          <div className="dropzone-file-card-details">
            {uploadPercent}
            <span style={{ paddingLeft: '10px' }}>
              {`${toMb(uploadProgress)} / ${toMb(file?.size)} MB`}
            </span>
          </div>
        </Col>
        <Col xs={1}>
          {uploadPercent === '100%' && (
            <Button className="btn-icon" onClick={onFileRemove}>
              <i className="fas fa-times"></i>
            </Button>
          )}
        </Col>
      </Row>
      {uploadProgress && uploadPercent !== '100%' && (
        <div class="progress" style={{ height: '2px' }}>
          <div
            class="progress-bar"
            role="progressbar"
            style={{
              width: uploadPercent,
            }}
            aria-valuemin="0"
            aria-valuemax="100"
          ></div>
        </div>
      )}
    </Container>
  );
};

export default DropzoneFileCard;
