import React from "react";
// import TemplateImage from '../../../assets/images/Mary.jpg';

const EditTemplateImage = ({
    source,
    index,
    role,
    isCustom,
    isNav = false,
    url
}) => {
    return (
        <div
            className={`template-card template-card-image ${index === 1 ? "template-content-card" : ""} 
                ${isCustom && index === 0 ? "template-custom-image" : ""} ${
                isCustom ? "custom-card-img" : ""
            } cardbox-wrapper`}
        >
            <div
                id={isNav ? `template-nav-${index}` : `template-div-${index}`}
                className={`${!isCustom ? "card-nav" : index === 1 ? "custom-content-nav": "custom-card-nav"} ${index === 1 ? "template-content-image" : ""} ${
                    role === "admin" && !isCustom ? "custom-prayer-body" : ""
                } prayer-body cardbox-body cardbox-body-text`}
            >
                {/* FE ToDo: Onclick functionality to be added */}
                <img src={index === 1 ? (url ? url : "") : source} alt='Prayer Card Template' className={`${index === 0 ? "prayer-card-image" : "prayer-card-content"}`} />
            </div>
        </div>
    );
};

export default EditTemplateImage;
