import React, {
    useEffect,
    useCallback,
    useState,
    useRef,
    useMemo,
} from "react";
import _ from "lodash";
import useAsync from "react-use-async-hook";
import * as ObituaryAPI from "../../../../api/obituary";
import { Link } from "react-router-dom";
import { Collapse } from "reactstrap";
import TinyMceEditor from "../../../../components/common/tinyMceEditor/TinyMceEditor";
import addNotification from "../../../utils/notification/notification";
import { useSetLoading } from "../../../utils/hooks/loader";
import TooltipAndInfo from "../../../TooltipAndInfo";
import { copyListener } from "../../../utils/functions";
import { getDecedent } from "../../../../api/decedent";
import moment from "moment";
import { createDraft, getDraft } from "../../../../api/memories";
import { getPlainText } from "../../../utils/functions";

const MAX_LENGTH_LIFE_OF_DESCRIPTION = 50000;

const LifeOfSection = ({
    data,
    opi,
    isLoggedIn,
    getObituary,
    obituaryIsLocked,
    name,
    loggedinUserData
}) => {
    const [obituaryRes,setObituaryRes] = useState(_.get(data,'obituary'))
    const [obituaryData, setObituaryData] = useState(_.get(data, "obituary"));
    const fileRef = useRef(null);
    const audioRef = useRef(null);
    const inputRef = useRef(null);
    const [focus, setFocus] = useState(false);
    const [editorError, setEditorError] = useState(false);
    const [editorErrorMessage, setEditorErrorMessage] = useState("");
    const [isEditMode, setEditMode] = useState(false);
    const currentYear = new Date().getFullYear()

    useEffect(()=>{
        if(data) {
            setObituaryRes(_.get(data,'obituary'))
        }
    },[data])

    const { loading: updateLoader, execute: executeEditor } = useAsync({
        autoExecute: false,
        task: useCallback(async (values, section) => {
            const payload = {
                obituary: values,
            };
            return {
                res: await ObituaryAPI.updateObituary(opi, payload, "", false),
            };
        }, []),
        dataLoader: useCallback(({ res }) => {
            addNotification({
                type: "success",
                title: "Success",
                message: "Data updated successfully",
            });
        }, []),
        onError: useCallback(error => {
            let msg = _.get(
                error,
                "response.data.message",
                "Oops! Something went wrong"
            );

            if (getPlainText(obituaryData)?.length > MAX_LENGTH_LIFE_OF_DESCRIPTION) {
                msg = "Error. Text Limit Exceeded.";
            }
            addNotification({
                type: "danger",
                title: "Error",
                message: msg,
            });
        }, []),
    });

    const { data: mediaData, loading: updateMedia, execute: executeMedia } = useAsync({
        autoExecute: false,
        task: useCallback(async (formData, type) => {
            return {
                res: await ObituaryAPI.uploadMedia(opi, formData, type),
            };
        }, []),
        dataLoader: useCallback(({ res }) => {
            addNotification({
                type: "success",
                title: "Success",
                message: "Data updated successfully",
            });
            return res;
        }, []),
        onError: useCallback(error => {
            addNotification({
                type: "danger",
                title: "Error",
                message: _.get(
                    error,
                    "response.data.message",
                    "Oops! Something went wrong"
                ),
            });
        }, []),
    });

    useEffect(()=>{
        if(mediaData) {
            getObituary(opi)
        }
    },[mediaData])

    const showLoader = useMemo(() => updateMedia || updateLoader, [updateMedia, updateLoader]);
    useSetLoading(showLoader);

    useEffect(() => {
        if (inputRef.current && focus) {
            inputRef.current.focus();
        }
    }, [inputRef.current, focus]);

    const postEditorData = async (val) => {
        setEditorError(false);
        const obituaryText = val;
        if (!obituaryText || getPlainText(obituaryText)?.length === 0) {
          addNotification({
                type: "danger",
                title: "Error",
                message: "Obituary is required",
          });
          return false
        } else if (getPlainText(obituaryText)?.length > MAX_LENGTH_LIFE_OF_DESCRIPTION) {
            addNotification({
                type: "danger",
                title: "Error",
                message: "Error. Text Limit Exceeded.",
            });
            return false;
        } else if (_.get(data, "obituary") !== obituaryText) {
          await executeEditor(obituaryText, "obituary");
          getObituary(opi);
          setEditorError(false);
          setFocus(false);
          setEditMode(false)
        }
        else
        {
            return false
        }
      };

    const changeEditorState = (newValue) => {
        const value = newValue
        setFocus(true);
        if (!value) {
          setEditorError(true);
           setEditorErrorMessage("Obituary is required");
           setObituaryData(value);
        } else if (getPlainText(value)?.length <= MAX_LENGTH_LIFE_OF_DESCRIPTION) {
          setEditorError(false);
          setObituaryData(value);
        } else {
          setObituaryData(value);
          setEditorError(true);
          setEditorErrorMessage("You have exceeded max limit for obituary");
        }
      };
    
    const uploadClick = type => {
        type === "image" ? fileRef.current.click() : audioRef.current.click();
    };

    const handleAudio = async e => {
        const file = e.target.files[0];
        var formData = new FormData();
        formData.append("file", file);
        await executeMedia(formData, "audio");
    };

    let description = _.get(data, "obituary");
    // description = _.replace(description, "<p>", "");
    // description = _.replace(description, "</p>", "");

    const shortDescription = description;

    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);
    const [isTopSection, setIsTopSection] = useState(true);
    const obituaryContent = useRef(null);
    const [showReadMoreButton, setShowReadMoreButton] = useState(false);

    useEffect(() => {
      if (obituaryContent?.current) {
        if (obituaryContent.current.clientHeight > 275) {
          setShowReadMoreButton(true);
        } else {
          setShowReadMoreButton(false);
        }
      }
    }, [obituaryRes]);

    const activateEditor = () => {
        setIsTopSection(false);
        setFocus(true);
        setIsOpen(true);
    };

    const [showAudioTooltip, setShowAudioTooltip] = useState(false);

    const showHideToolTip = () => {
        setShowAudioTooltip(!showAudioTooltip);
    };

    useEffect(() => {
        if (isEditMode) {
            activateEditor();
        }
    }, [isEditMode]);

    let topSection = () => {
        let childElem = (
            <input
                className='profile-description-input'
                placeholder={`Tell us about ${name}`}
                disabled={!isEditMode}
            />
        );
        if (shortDescription) {
            childElem = (
                <div
                    ref={obituaryContent}
                    style={{
                        textAlign: "left",
                        padding: "0",
                        margin: "0 0 15px",
                        fontSize: "18pt",
                        fontFamily:'Georgia',
                        lineHeight: "27px",
                    }}
                    dangerouslySetInnerHTML={{
                        __html: isOpen ? description : shortDescription,
                    }}
                />
            );
        } else if (isLoggedIn && !obituaryIsLocked) {
            childElem = (
                <input
                    className='profile-description-input'
                    placeholder={`Tell us about ${name}`}
                    onClick={activateEditor}
                    disabled={!isEditMode}
                    style={{ background: "none!important" }}
                />
            );
        }

        let clsName = isOpen
            ? "profile-description-wrapper"
            : "profile-description-wrapper maxHeight275";

        return (
            <div>
                <div className={clsName}>{childElem}</div>
                {_.get(data, "obituary") && (
                    <div className="copyRightStaticTextWrapper">
                    <a
                        href="https://cypresslawn.com/cypress-lawn-cemetery-association/"
                        target="_blank"
                        rel="noreferrer"
                        className="copyRightText"
                    >
                        {`© ${currentYear} Cypress Lawn Cemetery Association, All Rights Reserved.`}
                    </a>
                    </div>
                )}
            </div>
        );
    };
    const [isDataPosted, setIsDataPosted] = useState(false)
    const [dataFromIframe, setDataFromIframe] = useState("")

    let editorSection = (        
            <div
                className={`container-root ${
                    focus && isLoggedIn
                        ? editorError
                            ? "errorFocus"
                            : "hasFocus"
                        : "noFocus"
                }`}
            >
                {isLoggedIn && !obituaryIsLocked ? (
                    <TinyMceEditor
                    onFocus={() => {
                      setFocus(true);
                    }}
                    changeEditorState={changeEditorState}
                    descriptionData={isDataPosted ? dataFromIframe : shortDescription}
                    placeholder={`Obituary of ${name}...`}
                    sizeLimit={MAX_LENGTH_LIFE_OF_DESCRIPTION}
                    height={350}
                    width={"auto"}
                    ></TinyMceEditor>
                ) : (
                    <span
                        style={{ textAlign: "left", whiteSpace: "pre-wrap", fontFamily:'Georgia', fontSize:'18pt'}}
                        dangerouslySetInnerHTML={{
                            __html: _.get(data, "description"),
                        }}
                    />
                )}
            </div>
    );
    
    useEffect(() => {
      document.addEventListener("copy", copyListener);
      return () => {
        document.removeEventListener("copy", copyListener);
      };
    }, []);


    const GPT_URL = process.env.REACT_APP_GPT_URL

    const IFRAME_ORIGIN = process.env.REACT_APP_IFRAME_ORIGIN
    const [iframeData, setIFrameData] = useState(null);
    const [ draftIframeData, setDraftIframeData] = useState(null)
    const [openGptWriter, setOpenGptWriter] = useState(false)
    const [showDrafts, setShowDrafts] = useState(false)
    const [loadDraftData, setLoadDraftData]= useState(false)

    const handleIframeOpen = ()=>{   // for opening the iframe with pre populate data from FAA 
        setLoadDraftData(false)
        setOpenGptWriter(true)
    }

    const handleDraftsOpen = ()=>{   // for opening the iframe with saved draft data
        setLoadDraftData(true)
        setOpenGptWriter(true)
    }

    const handlePostObituary = (data)=>{   /// for creating the draft in db
        setIsDataPosted(true)
        setDataFromIframe(data)
        setOpenGptWriter(false)
        setEditMode(true)
    }

    const iRef = useRef(null)
    const handleMessage = (event) => {
      if (event?.origin === IFRAME_ORIGIN) {
          if(event.data === "close"){
              setOpenGptWriter(false)
          }
          if(event.data.action === "createDraft"){
            const modifiedData = {
                ...event.data.dataToParent,
                decedentOpId: opi
            };
            createDraftOnServer(modifiedData)
          }
          if(event.data.action === "post-data"){
            event.data.formattedTextareaValue !== undefined && handlePostObituary(event.data.formattedTextareaValue);
          }
      }
    };
     
      useEffect(() => {
        window.addEventListener("message", handleMessage);
        return () => {
          window.removeEventListener("message", handleMessage);
        };
      }, []);


      useEffect(()=>{
        const fetchDataForIframe = async () => {
            try {
                const response = await getDecedent(opi);
                setIFrameData(response?.data?.decedent);
            } catch (error) {
                addNotification({
                    type : "danger",
                    title : "Error",
                    message : error?.message || "Error at getting decedent Data"
                })
            }
        };
        fetchDataForIframe()
      },[])

      const fetchDrafts = async()=>{
        try{
            const response = await getDraft(opi)
            if(response.data?.drafts){
                setShowDrafts(true)
                setDraftIframeData(response.data.drafts[0])
            }else{
                setShowDrafts(false)
            }
        }
        catch(error){
            addNotification({
                type : "danger",
                title : "Error",
                message : error?.message || "Failed to fetch draft"
            })
        }
        }

        useEffect(()=>{
            if(!showDrafts){
                fetchDrafts()
            }
        },[showDrafts])

      // Function to create or update the data
      const createDraftOnServer = async(data)=>{
        try{
            const response = await createDraft(data)
            if(response.data.success === true){
                setShowDrafts(true)
                fetchDrafts()
                addNotification({
                    type : "success",
                    title : "Success",
                    message : "Draft saved successfully"
                })
                setOpenGptWriter(false)
            }
            if(response.data.success === false){
                addNotification({
                    type : "danger",
                    title : "Error",
                    message : response?.data?.error || "Error at draft saving, try again"
                })
            }
        }catch(error){
            addNotification({
                type : "danger",
                title : "Error",
                message : error?.message || "Error at draft saving, try again"
            })
        }
      }

        const handleLoad = () => {
            const dataValues = {
                userEmail: loggedinUserData?.email || "",
                firstName: iframeData?.primaryInfo?.firstName || "",
                ...(iframeData?.primaryInfo?.middleName !==null && {middleName: iframeData?.primaryInfo?.middleName,}),
                ...(iframeData?.primaryInfo?.lastName !== null && {lastName: iframeData?.primaryInfo?.lastName,}),
                ...(iframeData?.primaryInfo?.aka !==null && iframeData?.primaryInfo?.aka !== "" &&{nickName: iframeData?.primaryInfo?.aka}),
                ...(iframeData?.primaryInfo?.dateOfBirth != null && {
                    dateOfBirth: moment(iframeData?.primaryInfo?.dateOfBirth).format("YYYY-MM-DD")
                }),
                ...(iframeData?.deathInfo?.dateOfDeath != null && {
                    dateOfDeath: moment(iframeData?.deathInfo?.dateOfDeath).format("YYYY-MM-DD")
                }),
                birthplace: `${iframeData?.primaryInfo?.locationOfBirth?.birthState !==null ? 
                    `${iframeData?.primaryInfo?.locationOfBirth?.birthState },` : ""}`+ `${iframeData?.primaryInfo?.locationOfBirth?.birthCountry !== null ? 
                    `${iframeData?.primaryInfo?.locationOfBirth?.birthCountry} ` : ""}` || "",
                placeLived: `${iframeData?.residentInfo?.address?.city ? `${iframeData?.residentInfo?.address?.city}, ` : ""}` +
                `${iframeData?.residentInfo?.address?.state ? `${iframeData?.residentInfo?.address?.state}, ` : ""}` +
                `${iframeData?.residentInfo?.address?.country ? `${iframeData?.residentInfo?.address?.country} ` : ""}` || "",
                familyMembers: `${iframeData?.parentsInfo?.father?.firstName ? `Parents - Father: ${iframeData?.parentsInfo?.father?.firstName} ${iframeData?.parentsInfo?.father?.middleName || ''} ${iframeData?.parentsInfo?.father?.lastName || ''}` : ''}${iframeData?.parentsInfo?.mother?.firstName ?`, Mother: ${iframeData?.parentsInfo?.mother?.firstName} ${iframeData?.parentsInfo?.mother?.middleName || ''} ${iframeData?.parentsInfo?.mother?.lastName || ''}` : ''}`,
                gender: iframeData?.primaryInfo?.gender,
                ...(iframeData?.educationInfo?.qualification !==null && {educationalQualification: iframeData?.educationInfo?.qualification}),
                termsandcondition : true,
                deathCause: "",
                personalityTraits: "",
                dateOfCeremony: null,
                hobbies: "",
                significantLifeMoments: "",
                writingTone: "",
                achievements: "",
                memories: "",
                length: "",
                timeOfCeremony: "",
                locationForCeremony: "",
                officiatingPersonForCeremony: "",
                contactPersonForCeremony: "",
                memorialDonationsForCeremony: "",
                receptionDetailForCeremony: "",
                memorableQuote: false,
                generatedText:"",
                applicationKey: "ObituaryWriterFAA", 
            };
                setTimeout(() => {
                iRef.current.contentWindow.postMessage({ ...dataValues }, "*");
                }, [100]);
            };

        const handleDraftLoad = ()=>{
            const dataValues = {
                firstName: draftIframeData?.firstName || "",
                ...(iframeData?.primaryInfo?.middleName !==null && {middleName: draftIframeData?.middleName}),
                ...(draftIframeData?.lastName !== null && { lastName: draftIframeData?.lastName }),
                ...(draftIframeData?.nickName !== null && draftIframeData?.nickName !== "" && { nickName: draftIframeData?.nickName }),
                gender: draftIframeData?.gender || "",
                ...(draftIframeData?.dateOfBirth !== null && 
                    { dateOfBirth: moment(draftIframeData?.dateOfBirth).format("YYYY-MM-DD") }),
                ...(draftIframeData?.dateOfDeath !== null && 
                    { dateOfDeath: moment(draftIframeData?.dateOfDeath).format("YYYY-MM-DD") }),
                ...(draftIframeData?.birthplace !== null && { birthplace: draftIframeData?.birthplace }),
                ...(draftIframeData?.placeLived !== null && { placeLived: draftIframeData?.placeLived }),
                ...(draftIframeData?.educationalQualification !== null && 
                    { educationalQualification: draftIframeData?.educationalQualification }),
                ...(draftIframeData?.deathCause !== null && { deathCause: draftIframeData?.deathCause }),
                ...(draftIframeData?.familyMembers !== null && { familyMembers: draftIframeData?.familyMembers }),
                ...(draftIframeData?.significantLifeMoments !== null && 
                    { significantLifeMoments: draftIframeData?.significantLifeMoments }),
                ...(draftIframeData?.achievements !== null && { achievements: draftIframeData?.achievements }),
                ...(draftIframeData?.memories !== null && { memories: draftIframeData?.memories }),
                hobbies: draftIframeData?.hobbies || "",
                personalityTraits: draftIframeData?.personalityTraits || "",
                ...(draftIframeData?.dateOfCeremony !== null && 
                    { dateOfCeremony: moment(draftIframeData?.dateOfCeremony).format("YYYY-MM-DD") }),
                ...(draftIframeData?.timeOfCeremony !==null && { timeOfCeremony: draftIframeData?.timeOfCeremony}),
                ...(draftIframeData?.locationForCeremony !== null && 
                    { locationForCeremony: draftIframeData?.locationForCeremony }),
                ...(draftIframeData?.officiatingPersonForCeremony !== null &&
                    { officiatingPersonForCeremony: draftIframeData?.officiatingPersonForCeremony }),
                ...(draftIframeData?.contactPersonForCeremony !== null && 
                    { contactPersonForCeremony: draftIframeData?.contactPersonForCeremony }),
                ...(draftIframeData?.memorialDonationsForCeremony !== null && 
                    { memorialDonationsForCeremony: draftIframeData?.memorialDonationsForCeremony }),
                ...(draftIframeData?.receptionDetailForCeremony !== null && 
                    { receptionDetailForCeremony: draftIframeData?.receptionDetailForCeremony }),
                writingTone: draftIframeData?.writingTone || "",
                length: draftIframeData?.length || "",
                ...(draftIframeData?.memorableQuote !== null  && {memorableQuote: draftIframeData?.memorableQuote}),
                generatedText: draftIframeData?.generatedText,
                termsandcondition : true,
                applicationKey: "ObituaryWriterFAA"
            };
            setTimeout(() => {
                iRef.current.contentWindow.postMessage({ ...dataValues }, "*");
                }, [100]);
        }

    return (
        <>
            {name && (
                <>
                <div className="obituary-writer-container">
                   <div style={{flex: "1", paddingLeft:"20px"}}> Try our Obituary Writer AI tool </div>
                    <div style={{ display: "flex", gap: "20px", margin: "8px 0px" }}>
                        {showDrafts &&<button className="btn draft-btn" onClick={handleDraftsOpen} disabled={obituaryIsLocked} >Draft</button> }
                        <button className="btn writer-btn" onClick={handleIframeOpen} disabled={obituaryIsLocked}> Obituary Writer </button>
                    </div>
                </div>

                {openGptWriter && 
                <>
                <div className="overlay"></div>
                    <iframe 
                    title="Obituary Writer"
                    src={GPT_URL}
                    className="iframe-container"
                    allowFullScreen
                    ref={iRef}
                    onLoad={loadDraftData ? handleDraftLoad : handleLoad}
                    />
                    </>
                }

                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <h2>The Obituary of {name} </h2>
                    <div
                        style={{
                            display: "flex",
                            marginBottom: "30px",
                        }}
                    >
                        {isEditMode && (
                            <>
                                <Link
                                    onClick={(e) => {
                                        setEditMode(false);
                                        setIsTopSection(true);
                                        setEditorErrorMessage('')
                                        setEditorError(false)
                                        e.preventDefault();    
                                    }}
                                    className='link-customise btn-theme cancel-button'
                                >
                                    CANCEL
                                </Link>
                                <Link
                                    onClick={e => {
                                        postEditorData(obituaryData);
                                        setIsTopSection(true)
                                        e.preventDefault();
                                    }}
                                    disabled={editorError}
                                    style={{pointerEvents: editorError ? 'none' : 'unset'}}
                                    className='link-customise btn-theme custom-btn'
                                >
                                    SUBMIT TO ARRANGER
                                </Link>
                            </>
                        )}
                        {!isEditMode && (
                            <>
                                <Link
                                    onClick={(e) => {
                                        setEditMode(true);
                                        e.preventDefault();
                                    }}
                                    className='link-customise btn-theme custom-btn'
                                    style={{
                                        marginRight: "20px",
                                        pointerEvents: obituaryIsLocked
                                            ? "none"
                                            : "unset",
                                    }}
                                    disabled={obituaryIsLocked}
                                >
                                    ADD/EDIT
                                </Link>
                                <Link
                                    className='link-customise btn-theme theme-green custom-btn'
                                    disabled
                                >
                                    SUBMIT TO ARRANGER
                                </Link>
                            </>
                        )}
                    </div>
                </div>
                </>
            )}
            <div>
                <div
                    className='story-wrapper'
                    style={{ padding: isEditMode ? "0px" : "30px" }}
                >
                    {isTopSection && !isEditMode ? topSection() : ""}
                    <Collapse isOpen={isOpen}>
                        <div>
                            {isEditMode && isLoggedIn ? editorSection : ""}
                            {editorError ? (
                                <div
                                    style={{ marginTop: 10 }}
                                    className='errorMessage'
                                >
                                    {editorErrorMessage}
                                </div>
                            ) : (
                                ""
                            )}
                        </div>
                    </Collapse>
                    {showReadMoreButton && !isEditMode && (
                        <div style={{ marginTop: 35 }}>
                            <hr />
                            <div className='description'>
                                <Link
                                    color='btn-theme theme-green'
                                    onClick={(e) => {
                                        toggle()
                                        e.preventDefault(); 
                                    }}
                                    className='read-less-more'
                                >
                                    {isOpen ? "Read Less" : "Read More"}
                                </Link>
                            </div>
                        </div>
                    )}
                </div>

                {!(
                    !isLoggedIn &&
                    obituaryIsLocked &&
                    !_.get(data, "audioFileUrl")
                ) && (
                    <div className='audio-wrapper d-flex justify-content-between'>
                        <div className='audio-file'>
                            <audio controls key={_.get(data, 'audioFileUrl')}>
                                <source
                                    src={_.get(data, "audioFileUrl")}
                                ></source>
                            </audio>
                        </div>
                        {isLoggedIn && !obituaryIsLocked && !isEditMode ? (
                            <>
                                <Link
                                    onClick={e => { uploadClick("audio"); e.preventDefault();  }}
                                    className='btn-edit btn-theme'
                                    disabled={obituaryIsLocked}
                                >
                                    ADD AUDIO MESSAGE
                                </Link>

                                <TooltipAndInfo
                                    id='audio-tooltip-icon'
                                    showTooltipFn={showHideToolTip}
                                    showToolTip={showAudioTooltip}
                                    tooltipText='File must be in "WAV" format.'
                                />
                            </>
                        ) : (
                            ""
                        )}
                    </div>
                )}
                <input
                    id='template-image'
                    type='file'
                    ref={audioRef}
                    accept='.mp3, .wav'
                    onChange={handleAudio}
                    hidden
                />
            </div>
        </>
    );
};

export default LifeOfSection;