import React, { useEffect, useCallback, useState, useMemo } from "react";
import _ from "lodash";
import useAsync from "react-use-async-hook";
import { getUpcomingEvents, hideEventsAPI, hideEventsEndTimeApi } from "../../../../api/obituary";
import "draft-js/dist/Draft.css";
import { useSetLoading } from "../../../utils/hooks/loader";
import TooltipAndInfo from "../../../TooltipAndInfo";
import { apiErrorHandler } from "../../../../utils/handlers";
import moment from "moment";
import {
    getLocationByCode,
    programCardFuneralLocations,
} from "../../../utils/variables";
import { FormControlLabel, Switch } from "@material-ui/core";
import addNotification from "../../../utils/notification/notification";
import  RedirectToTributeStore  from '../redirectToTributeStore';
import CopyImage from '../../../../assets/images/copyIcon.jpeg'
import SingleTickImage from '../../../../assets/images/singleTick.svg';
let momentTz = require("moment-timezone");

let currentLat, currentLong;
function showPosition(position) {
    currentLat = position.coords.latitude;
    currentLong = position.coords.longitude;
}

const ToggleSwitch = ({ hideFun, isHided, eventId, handleChange, label }) => {
  return (
    <>
      <FormControlLabel
        control={
          <Switch
            checked={hideFun[eventId] || isHided}
            name={eventId?.toString()}
            value={hideFun && hideFun[eventId] ? hideFun[eventId] : isHided}
            onChange={handleChange}
            inputProps={{
              "aria-label": "secondary checkbox",
            }}
          />
        }
        label={label}
        labelPlacement="start"
        style={{
          margin: 0,
        }}
      />
    </>
  );
};

const HideEvent = ({ eventId, isPrivate, opi, handleHideEvent }) => {
    const [hideEvent, setHideEvent] = useState({});

    /* Hide Events API */

    const {
        data: hideEventData,
        loading: hideEventLoader,
        execute: executeHideEvent,
    } = useAsync({
        autoExecute: false,
        task: useCallback(hideEvent => {
            return hideEventsAPI(opi,hideEvent);
        }, []),
        dataLoader: useCallback((res) => {
            return _.get(res, "data");
        }, []),
        onError: useCallback((error) => {
            if (
                document.getElementsByClassName("notification-danger").length ==
                0
            ) {
                apiErrorHandler(error);
            }
        }, []),
    });

    useEffect(() => {
        if (hideEventData) {
            handleHideEvent();
        }
    }, [hideEventData]);

    const handleChange = e => {
        let obj = {
            [e.target.name]: e.target.checked,
        };
        setHideEvent(obj);
        executeHideEvent(obj);
        e.target.checked &&
            addNotification({
                type: "success",
                title: "Success",
                message: "Event Hidden Successfully",
            });
    };

    return (
        <>
            <ToggleSwitch
                eventId={eventId}
                handleChange={handleChange}
                label={'Hide Event'}
                hideFun={hideEvent}
                isHided={isPrivate}
            />
        </>
    );
};

const HideEndTime = ({ eventId, isEndTimeHided, opi, handleHideEndTime }) => {
    const [hideEndTime, setHideEventEndTime] = useState({});

    /* Hide Event EndTime API */

    const {
        data: hideEventEndTimeData,
        loading: hideEndTimeLoader,
        execute: executeHideEventEndTime,
    } = useAsync({
        autoExecute: false,
        task: useCallback((data) => {
            return hideEventsEndTimeApi(opi, eventId, data);
        }, []),
        dataLoader: useCallback((res) => {
            return _.get(res, "data");
        }, []),
        onError: useCallback((error) => {
            if (
                document.getElementsByClassName("notification-danger").length ==
                0
            ) {
                apiErrorHandler(error);
            }
        }, []),
    });

    useEffect(() => {
        if (hideEventEndTimeData) {
            handleHideEndTime()
        }
    }, [hideEventEndTimeData]);

    const handleChange = (e) => {
        let obj = {
            [e.target.name]: e.target.checked,
        };
        setHideEventEndTime(obj);
        executeHideEventEndTime({ isEndTimeHided: e.target.checked });
        e.target.checked &&
            addNotification({
                type: "success",
                title: "Success",
                message: "Ending Time Hided Successfully",
            });
    };

    const showLoader = useMemo(() => hideEndTimeLoader,  [hideEndTimeLoader]);
    useSetLoading(showLoader);

    return (
        <>
            <ToggleSwitch
                hideFun={hideEndTime}
                eventId={eventId}
                label={'Hide End Time'}
                handleChange={handleChange}
                isHided={isEndTimeHided}
            />
        </>
    );
};

const UpcomingEvents = ({ decedent, opi, obituary, isLoggedIn, isFamilyAccess }) => {
    
    let {
        data: events,
        loading: eventsLoader,
        execute: fetchUpcomingEvents,
    } = useAsync({
        autoExecute: false,
        task: getUpcomingEvents,
        dataLoader: useCallback(res => {
            return _.get(res, "data.events");
        }, []),
        onError: useCallback(error => {
            if (
                document.getElementsByClassName("notification-danger").length ==
                0
            ) {
                apiErrorHandler(error);
            }
        }, []),
    });

    useEffect(() => {
        if (opi) {
            fetchUpcomingEvents(opi, momentTz.tz.guess());
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(showPosition);
            }
        }
    }, []);

    let upcomingEvents = [];
    let pastEvents = [];
    if (events && events.upcomingEvents) {
        upcomingEvents = events.upcomingEvents;
    }
    if (events && events.pastEvents) {
        pastEvents = events.pastEvents;
    }
    const totalEvents = upcomingEvents.length + pastEvents.length || 0;

    const getDirectionsMap = async address => {
        const destination = address?.split(" ")?.join("+");
        const query = `https://www.google.com/maps/dir/?api=1&amp&origin=${currentLat},${currentLong}&destination=${destination}&travelmode=DRIVING`;
        window.open(query, "_blank");
    };

    const showLoader = useMemo(() => eventsLoader,  [eventsLoader]);
    useSetLoading(showLoader);

    const [showObituaryTooltip, setShowObituaryTooltip] = useState(false);
    const [showObituaryMobileTooltip, setMobileShowObituaryTooltip] = useState(false);
    const [copyIcon, setCopyIcon] = useState({action:'copy',src: CopyImage,id: null,item:'url'})
    const mobileShowHideToolTip = () => {
        setMobileShowObituaryTooltip(!showObituaryMobileTooltip)
     };
    const showHideToolTip = () => {
        setShowObituaryTooltip(!showObituaryTooltip);
    };

    const getAddress = locationCode => {
        if (!locationCode) {
            return null;
        }

        const currentLocation = getLocationByCode(locationCode);
        if (currentLocation) {
            const currentAddress =
                programCardFuneralLocations[currentLocation].address;
            return { location: currentLocation, address: currentAddress };
        }

        return null;
    };

    const [deviceWidth, setDeviceWidth] = useState();
   
    useEffect(() => {
        const handleResize = () => {
            setDeviceWidth(window.innerWidth);
        };
        window.addEventListener("resize", handleResize);
        setDeviceWidth(window.innerWidth);
    }, []);

    const formatAddress = (address) => {
        let addStr = '';
        if (_.get(address, 'city')) addStr = _.get(address, 'city')
        if (_.get(address, 'state')) addStr = addStr !== '' ? `${addStr}, ${_.get(address, 'state')}` : _.get(address, 'state');
        if(_.get(address, 'zipcode')) addStr = addStr !== '' ? `${addStr} ${_.get(address, 'zipcode')}` : _.get(address, 'zipcode')
        return addStr;
    }

    const handleCopy = (text,id,item='url') => {
      navigator.clipboard
        .writeText(text)
        .then(() => {
          setCopyIcon({action:'copied', src: SingleTickImage, id, item});
          setTimeout(() => {
            setCopyIcon({action:'copy', src: CopyImage, id, item});
          }, 2000);
        })
        .catch((err) => {
          addNotification({
            type: "danger",
            title: "Error",
            message: err ? err : "Something went wrong",
          });
        });
    };

    const checkDisplayValue = (isHided) => {
        return  !isHided|| (isLoggedIn && isFamilyAccess);
    }

    const formatUrl = (url) => {
        if (!/^https?:\/\//i.test(url)) {
            return `https://${url}`;
        }
        return url;
    };

    return (
        <>
            <div>
               <div className='obituary-header-family desktop-header'>
                    <h2>Upcoming / Past Events </h2>
                    <div className="d-flex">
                        {/* {totalEvents > 0 && (isLoggedIn && isFamilyAccess) && (
                            <div className="tribute-store-desktop-wrapper">
                                <RedirectToTributeStore opi={opi}/>
                            </div>
                        )} */}
                        <TooltipAndInfo
                        id="obituary-tooltip-icon-events-desktop"
                        clsName="toopltip-space-top"
                        showTooltipFn={showHideToolTip}
                        showToolTip={showObituaryTooltip}
                        tooltipText='List of all the events scheduled for your loved one'
                       />
                    </div>
                </div>
                {
                    deviceWidth <= 567 &&
                    <div className='mobile-events-header'>
                         <div className="d-flex justify-content-between">
                            <h2>Upcoming / Past Events </h2>    
                            <TooltipAndInfo
                            id="obituary-tooltip-icon-events-mobile"
                            clsName="toopltip-space-top"
                            showTooltipFn={mobileShowHideToolTip}
                            showToolTip={showObituaryMobileTooltip}
                            tooltipText="List of all the events scheduled for your loved one"
                            />
                        </div>
                        {/* {totalEvents > 0 && (
                               <RedirectToTributeStore opi={opi}/>
                        )} */}
                    </div>
                }
            </div>
            <div>
                <div className='story-wrapper'>
                    {upcomingEvents && upcomingEvents.length > 0 && (
                        <div className='event-description-wrapper'>
                            {upcomingEvents &&
                                upcomingEvents.map(event => {
                                    const {
                                        id,
                                        eventName,
                                        locationName,
                                        locationAddress,
                                        locationAddressObj,
                                        serviceDate,
                                        beginningTime,
                                        endingTime,
                                        locationCode,
                                        chapelName,
                                        url,
                                        passCode,
                                        hidePassCodeInFaa,
                                        isEndTimeHided,
                                        eventType,
                                    } = event;
                                    let locationData =
                                        getAddress(locationCode);
                                    if (eventName === 'Cemetery Graveside Service') {
                                        locationData = getAddress('CFS');
                                    }
                                    let locName = locationName;
                                    let locAddress = locationAddress;
                                    if (!locAddress && locationData) {
                                        const { location = "", address = "" } =
                                            locationData;
                                        locName = location;
                                        locAddress = address;
                                    }
                                    return (
                                        <div
                                            className='upcoming-event'
                                            key={event.id}
                                        >
                                            {isLoggedIn && isFamilyAccess &&(
                                                <div className='d-flex justify-content-end'>
                                                    <HideEvent
                                                        eventId={event.id}
                                                        isPrivate={event.isPrivate}
                                                        opi={opi}
                                                        handleHideEvent={() =>
                                                            fetchUpcomingEvents(
                                                                opi,
                                                                momentTz.tz.guess()
                                                            )
                                                        }
                                                    />
                                                </div>
                                            )}
                                            {locName && <div className='event-location'>
                                                {locName}
                                            </div>}
                                            { chapelName && <div className='event-chapel'>
                                                {chapelName}
                                            </div> }
                                            { eventName && <div className='event-service'>
                                                {eventName}
                                            </div>}
                                            {locationAddressObj && !_.isEmpty(locationAddressObj) && eventName !== 'Viewlogies Service' &&
                                                <div
                                                    className='event-address'
                                                    onClick={() => {
                                                        getDirectionsMap(
                                                            locAddress
                                                        );
                                                    }}
                                               >
                                                {_.get(locationAddressObj, 'line1') && <p>{_.get(locationAddressObj, 'line1')}</p>}
                                                <p>{formatAddress(locationAddressObj)}</p>
                                                {_.get(locationAddressObj, 'country') && <p>{_.get(locationAddressObj, 'country')}</p>}
                                            </div>}
                                            <div className='event-date'>
                                                {moment(serviceDate).format(
                                                    "MMMM DD, YYYY"
                                                )}
                                            </div>
                                            {beginningTime && (
                                                <div className='event-time d-flex'>
                                                    <span className="startTime">
                                                        {moment(
                                                            beginningTime
                                                        ).format("h:mm A")}{" "}
                                                    </span>
                                                    <>
                                                        {endingTime && (
                                                            <>
                                                                {
                                                                    checkDisplayValue(isEndTimeHided) && 
                                                                    <span className="endTime">
                                                                        {" "}
                                                                        to{" "}
                                                                        {moment(
                                                                            endingTime
                                                                        ).format(
                                                                            "h:mm A"
                                                                        )} 
                                                                    </span>
                                                                }
                                                                {isLoggedIn && isFamilyAccess  && eventType === 'Dynamic' && (
                                                                <div className='d-flex hideEndTime'>
                                                                    <HideEndTime
                                                                        eventId={id}
                                                                        isEndTimeHided={isEndTimeHided}
                                                                        opi={opi}
                                                                        handleHideEndTime={() =>
                                                                            fetchUpcomingEvents(
                                                                                opi,
                                                                                momentTz.tz.guess()
                                                                            )
                                                                        }
                                                                    />
                                                                </div>
                                                              )}
                                                            </>
                                                        )}
                                                    </>
                                                </div>
                                            )}
                                            {url && url !== '' && eventName === 'Viewlogies Service' && (
                                                <>
                                                    <div className="event-broadCastUrl d-flex">
                                                        <div className="d-flex ">
                                                            <span className="labelName liveStreamHeading">
                                                                Live Stream Link:
                                                            </span>
                                                            <div className="d-flex textContainer">
                                                            <a
                                                                href={formatUrl(url)}
                                                                target="_blank"
                                                                rel="noreferrer"
                                                            >
                                                                Click Here
                                                            </a>
                                                            <img
                                                                src={id === _.get(copyIcon, 'id') && _.get(copyIcon, 'item') === 'url' ? _.get(copyIcon, 'src') : CopyImage}
                                                                alt="CopyIcon"
                                                                onClick={() => {
                                                                    handleCopy(url, id, 'url');
                                                                }}
                                                                className={`copyIcon ${_.get(copyIcon, 'action') === 'copied' && _.get(copyIcon, 'item') === 'url' ? 'tickMark' : ''}`}
                                                            />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {checkDisplayValue(hidePassCodeInFaa) && passCode  ?
                                                    <div className="event-passCode d-flex">
                                                        <span className="labelName">
                                                            Pass Code:
                                                        </span>
                                                        {" "}
                                                        <div className="d-flex textContainer">
                                                            <span>{ passCode }</span>
                                                                <img src={id === _.get(copyIcon, 'id') && _.get(copyIcon, 'item') === 'passCode' ? _.get(copyIcon, 'src') : CopyImage} alt="CopyIcon" onClick={() => { handleCopy(passCode, id, 'passCode'); }}
                                                                    className={`copyIcon ${_.get(copyIcon, 'action') === 'copied' && _.get(copyIcon, 'item') === 'passCode' ? 'tickMark' : ''}`} />
                                                        </div>
                                                    </div>
                                                    :
                                                        <div className='event-passCode passCodeDivWithNote'>
                                                             <span className="labelName">
                                                                Pass Code:
                                                             </span>
                                                            <span className="noteMsg">Please contact Family member</span>
                                                    </div>
                                                    }
                                                </>
                                            )}
                                            <div className="tribute-store-button-wrapper">
                                                <RedirectToTributeStore opi={opi}/>
                                                <p className="note">Note: All flower orders must be submitted by 10 a.m. the day before the service.</p>
                                            </div>
                                        </div>
                                    );
                                })}
                        </div>
                    )}

                    {upcomingEvents &&
                        upcomingEvents.length > 0 &&
                        pastEvents &&
                        pastEvents.length > 0 && (
                            <hr style={{ marginBottom: 40 }} />
                        )}
                    {pastEvents && pastEvents.length > 0 && (
                        <div className='event-description-wrapper'>
                            {pastEvents &&
                                pastEvents.map(event => {
                                    const {
                                        id,
                                        eventName,
                                        locationName,
                                        locationAddress,
                                        locationAddressObj,
                                        serviceDate,
                                        beginningTime,
                                        endingTime,
                                        locationCode,
                                        chapelName,
                                        url,
                                        passCode,
                                        hidePassCodeInFaa,
                                        isEndTimeHided,
                                        eventType,
                                    } = event;

                                    let locationData =
                                        getAddress(locationCode);
                                    if (eventName === 'Cemetery Graveside Service') {
                                        locationData = getAddress('CFS');
                                    }
                                    let locName = locationName;
                                    let locAddress = locationAddress;
                                    if (!locAddress && locationData) {
                                        const { location = "", address = "" } =
                                            locationData;
                                        locName = location;
                                        locAddress = address;
                                    }
                                    return (
                                        <div
                                            className='upcoming-event'
                                            key={event.id}
                                        >
                                            {isLoggedIn && isFamilyAccess &&(
                                                <div className='d-flex justify-content-end'>
                                                    <HideEvent
                                                        eventId={event.id}
                                                        isPrivate={event.isPrivate}
                                                        opi={opi}
                                                        handleHideEvent={() =>
                                                            fetchUpcomingEvents(
                                                                opi,
                                                                momentTz.tz.guess()
                                                            )
                                                        }
                                                    />
                                                </div>
                                            )}
                                            {locName && <div className='event-location'>
                                                {locName}
                                            </div>}
                                            {chapelName && <div className='event-chapel'>
                                                {chapelName}
                                            </div>}
                                            {eventName && <div className='event-service'>
                                                {eventName}
                                            </div>}
                                            {locationAddressObj && !_.isEmpty(locationAddressObj) && eventName !== 'Viewlogies Service' &&
                                                <div
                                                    className='event-address'
                                                    onClick={() => {
                                                        getDirectionsMap(
                                                            locAddress
                                                        );
                                                    }}
                                                >
                                                {_.get(locationAddressObj, 'line1') && <p>{_.get(locationAddressObj, 'line1')}</p>}
                                                <p>{formatAddress(locationAddressObj)}</p>
                                                {_.get(locationAddressObj, 'country') && <p>{_.get(locationAddressObj, 'country')}</p>}
                                            </div>}
                                            <div className='event-date'>
                                                {moment(serviceDate).format(
                                                    "MMMM DD, YYYY"
                                                )}
                                            </div>
                                            {beginningTime && (
                                                <div className='event-time d-flex'>
                                                    <span className="startTime">
                                                        {moment(
                                                            beginningTime
                                                        ).format("h:mm A")}{" "}
                                                    </span>
                                                    <>
                                                        {endingTime && (
                                                            <>
                                                                {
                                                                    checkDisplayValue(isEndTimeHided) && 
                                                                    <span className="endTime">
                                                                        {" "}
                                                                        to{" "}
                                                                        {moment(
                                                                            endingTime
                                                                        ).format(
                                                                            "h:mm A"
                                                                        )} 
                                                                    </span>
                                                                }
                                                                {isLoggedIn && isFamilyAccess && eventType === 'Dynamic' && (
                                                                <div className='d-flex hideEndTime'>
                                                                    <HideEndTime
                                                                        eventId={id}
                                                                        isEndTimeHided={isEndTimeHided}
                                                                        opi={opi}
                                                                        handleHideEndTime={() =>
                                                                            fetchUpcomingEvents(
                                                                                opi,
                                                                                momentTz.tz.guess()
                                                                            )
                                                                        }
                                                                    />
                                                                </div>
                                                              )}
                                                            </>
                                                        )}
                                                    </>
                                                </div>
                                            )}
                                            {url && url !== '' && eventName === 'Viewlogies Service' && (
                                                <>
                                                    <div className="event-broadCastUrl d-flex">
                                                        <div className="d-flex ">
                                                            <span className="labelName liveStreamHeading">
                                                                Live Stream Link:
                                                            </span>
                                                            <div className="d-flex textContainer">
                                                            <a
                                                                href={formatUrl(url)}
                                                                target="_blank"
                                                                rel="noreferrer"
                                                            >
                                                                Click Here
                                                            </a>
                                                            <img
                                                                src={id === _.get(copyIcon, 'id') && _.get(copyIcon, 'item') === 'url' ? _.get(copyIcon, 'src') : CopyImage}
                                                                alt="CopyIcon"
                                                                onClick={() => {
                                                                handleCopy(url, id, 'url');
                                                                }}
                                                                className={`copyIcon ${_.get(copyIcon, 'action') === 'copied' && _.get(copyIcon, 'item') === 'url' ? 'tickMark' : ''}`}
                                                            />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {checkDisplayValue(hidePassCodeInFaa) && passCode ?
                                                    <div className="event-passCode d-flex">
                                                        <span className="labelName">
                                                            Pass Code:
                                                        </span>
                                                        {" "}
                                                        <div className="d-flex textContainer">
                                                            <span>{ passCode }</span>
                                                                <img src={id === _.get(copyIcon, 'id') && _.get(copyIcon, 'item') === 'passCode' ? _.get(copyIcon, 'src') : CopyImage} alt="CopyIcon" onClick={() => { handleCopy(passCode, id, 'passCode'); }}
                                                                className={`copyIcon ${_.get(copyIcon, 'action') === 'copied' && _.get(copyIcon, 'item') === 'passCode' ? 'tickMark' : ''}`}/>
                                                        </div>
                                                    </div>
                                                    :
                                                        <div className='event-passCode passCodeDivWithNote'>
                                                             <span className="labelName">
                                                                Pass Code:
                                                             </span>
                                                            <span className="noteMsg">Please contact Family member</span>
                                                    </div>
                                                    }
                                                </>
                                            )}
                                            <div className="tribute-store-button-wrapper d-flex justify-content-end">
                                                <button
                                                    className={`tribute-store-redirection-button disableButton tooltipButton`}
                                                    disabled={true}
                                                    >
                                                    Send Flowers
                                                </button>
                                                <button
                                                    className={`tribute-store-redirection-button disableButton tooltipButton`}
                                                    disabled={true}
                                                    >
                                                    Plant A Tree
                                                </button>
                                            </div>
                                        </div>
                                    );
                                })}
                        </div>
                    )}
                    {!events ||
                        (totalEvents === 0 && (
                            <div className='no-events'>No upcoming Events</div>
                        ))}
                </div>
            </div>
        </>
    );
};

export default UpcomingEvents;
