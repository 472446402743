import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from "react";
import { Formik } from "formik";
import useAsync from "react-use-async-hook";
import { useSetLoading } from "../utils/hooks/loader";
import { Form } from "reactstrap";
import TooltipAndInfo from "../TooltipAndInfo";
import * as yup from "yup";
import addNotification from "../utils/notification/notification";
import * as CustomizeFamilyWebsite from "../../api/customizeFamilyWebsite";
import _ from "lodash";
import Reaptcha from "reaptcha";
var momentTz = require("moment-timezone");

const SubscribeDecedent = ({ decedentData, opi }) => {
  const timeZone = momentTz.tz.guess();
  const [isDisable, setIsDisabled] = useState(true);
  const [formData, setFormData] = useState(null);
  const [isEmailDataSaved, setIsEmailDataSaved] = useState(true);
  const [errorObject, setErrorObject] = useState(null);
  const [subscribeDecedentToolTip, setSubscribeDecedentToltip] =
    useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const captchaRef = useRef(null);
  const [captchaToken, setCaptchaToken] = useState(null);
  const [useCaptcha, setUseCaptcha] = useState(false);
  const formRef = useRef()

  const siteKey =  process.env?.REACT_APP_SITE_KEY ||  "6LfRxvYpAAAAAOwb6Se3R1iapOHsHn1Bonx7ytmb";

  const showSubscribeDecedentTooltip = () => {
    setSubscribeDecedentToltip(!subscribeDecedentToolTip);
  };

  useEffect(() => {
    if (useCaptcha) {
      document
        .getElementById("recaptcha")
        .addEventListener("touchstart", { passive: true });
    }
  }, []);

  const verify = async () => {
    captchaRef.current.getResponse().then( async (res) => {
      setIsVerified(true);
      setErrorObject({ 'captcha' : ''})
    });
    setUseCaptcha(false);
    captchaRef?.current?.reset();
  };

  const {
    data: subscriberData,
    loading: subscriberLoader,
    execute: executeAddSubscriber,
  } = useAsync({
    autoExecute: false,
    initialData: useMemo(() => null, []),
    task: useCallback((values, opi) => {
      return CustomizeFamilyWebsite.addSubscriber(values, opi, timeZone);
    }, []),
    dataLoader: useCallback((res) => {
      addNotification({
        type: "success",
        title: "Success",
        message: "Subscribed Successfully",
      });
      setIsDisabled(true);
      setIsVerified(false)
      setIsEmailDataSaved(true);
      return _.get(res, "data", []);
    }, []),
    onError: useCallback((error) => {
      addNotification({
        type: "danger",
        title: "Error",
        message: _.get(
          error,
          "response.data.message",
          "Oops! Something went wrong"
        ),
      });
      setIsDisabled(true);
      setIsEmailDataSaved(false);
    }, []),
  });

  const {
    data: messagesCount,
    loading: getCountLoader,
    execute: executeGetCountOfMesagesSend,
  } = useAsync({
    autoExecute: false,
    initialData: useMemo(() => null, []),
    task: useCallback((opi, timeZone) => {
      return CustomizeFamilyWebsite.checkToGenerateCaptcha(opi, timeZone);
    }, []),
    dataLoader: useCallback((res) => {
      return _.get(res, "data", []);
    }, []),
    onError: useCallback((error) => {
      addNotification({
        type: "danger",
        title: "Error",
        message: _.get(
          error,
          "response.data.message",
          "Oops! Something went wrong"
        ),
      });
    }, []),
  });

  // useEffect(() => {
  //   if (messagesCount) {
  //     if (_.get(messagesCount, "count") > 3) {
  //       addNotification({
  //         type: "danger",
  //         title: "Error",
  //         message: "Enter Captcha To Send Message",
  //       });
  //       setUseCaptcha(true);
  //     } else {
  //       executeAddSubscriber(formData, opi, timeZone);
  //     }
  //   }
  // }, [messagesCount]);

  const handleSubmitForm = async (values) => {
    setFormData({ ...values });
    if (isVerified) {
      await executeAddSubscriber(formData, opi, timeZone)
      formRef?.current?.resetForm();
      setIsDisabled(true);
    }

  };

  const showLoader = useMemo(
    () => subscriberLoader || getCountLoader,
    [subscriberLoader, getCountLoader]
  );

  useSetLoading(showLoader);

  return (
    <div>
      <div className="headingSection">
        <p className="stayUpdated">Stay Updated</p>
        <TooltipAndInfo
          id="socialMediaLinkTooltip"
          showTooltipFn={showSubscribeDecedentTooltip}
          showToolTip={subscribeDecedentToolTip}
          size={17}
          tooltipText="Receive email notifications about your loved one from Cypress Lawn"
          color="#000000"
        />
      </div>
      <p className="reminderHeading">Recieve reminders about loved one's</p>
      <Formik
        innerRef={formRef}
        enableReinitialize
        initialValues={{
          email: "",
        }}
        validateOnBlur={false}
        validateOnChange={true}
        validationSchema={yup.object().shape({
          email: yup.string().email("Invalid Email").required("Email Required"),
        })}
        onSubmit={(values) => {
          handleSubmitForm(values);
        }}
      >
        {({
          values,
          errors,
          handleSubmit,
          setFieldValue,
          touched,
          setTouched,
          setSubmitting,
        }) => {
          const handleChange = (e) => {
            setIsDisabled(false);
            const { name, value } = e.target;
            if(!isVerified) setUseCaptcha(true)
            setFieldValue(name, value);
            setFormData({ email: value });
            setTouched({ [name]: true });
            setIsEmailDataSaved(false);
            setSubmitting(false);
          };
          return (
            <Form id="subscribeDecedentForm" onSubmit={handleSubmit}>
              <div className="inputcard-wrapper">
                <div className="inputcard-body custom-inputcard-body">
                  <div className="inputcard-group subscribeDecedentFormGroup">
                    <div className="choose-reason inputfield d-flex align-items-center justify-content-between">
                      <div className="inputcard-withlabel d-flex align-items-start justify-content-center flex-column">
                        <input
                          className="custominputfield"
                          placeholder="Please Add Email"
                          name="email"
                          value={values.email}
                          onChange={handleChange}
                          id="email"
                        />

                        {errors.email && touched.email && (
                          <span className="error-memory-input-msg">
                            {errors.email}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {useCaptcha && (
                <>
                  <Reaptcha
                    id="recaptcha"
                    sitekey={siteKey}
                    ref={captchaRef}
                    onVerify={verify}
                    onExpire={() => {
                      captchaRef.current.reset();
                    }}
                  />
                  <div className="error-captcha-div">
                    <span className="error-captcha-msg">
                      {errorObject?.["captcha"]}
                    </span>
                  </div>
                </>
              )}
              <div
                className={`dashboard-card-button-value subscribeButton ${
                  isDisable ? "disbledSubscribeButton" : ""
                }`}
                style={{ marginTop: errors?.email ? "24px" : "10px" }}
              >
                <button
                  type="submit"
                  className={`send-button save-button ${
                    isDisable ? "disableSave" : ""
                  }`}
                  onClick={()=>{
                    if(!isVerified) {
                      setErrorObject({'captcha' : 'verify captcha'})
                    }
                  }}
                  disabled={isDisable}
                >
                  Subscribe
                </button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default SubscribeDecedent;
