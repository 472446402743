import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState
} from "react";
import _ from "lodash";
import useAsync from "react-use-async-hook";

import { useSetLoading } from "../../utils/hooks/loader";
import * as PrayerCardAPI from "../../../api/prayerCard";
import Card from "./card";
import { getValidFileUrl } from "../../../utils/global";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import AvatarEditor from "react-avatar-editor";
import TooltipAndInfo from "../../TooltipAndInfo";
import Closebutton from "../../../assets/images/cancel-button.png";

const TemplateList = ({ isLocked, addCustomPrayerCard, saveTemplate }) => {
    const [isModal, setIsModal] = useState(false);
    const [customTooltipOpen, setCustomTooltipOpen] = useState(false);
    const [customModalTooltipOpen, setCustomModalTooltipOpen] = useState(false);
    const [avatarImage, setAvatarImage] = useState();
    const [zoom, setZoom] = useState(1);
    const [mimeType, setMimeType] = useState("image/jpeg");
    const [fileName, setFileName] = useState("fileName.jpg");
    const avatarRef = useRef(null);
    const fileRef = useRef(null);
    /**
     * Get Template List
     */
    const {
        data: templates,
        loading: getTemplateLoader,
        execute: executeGetTemplate
    } = useAsync({
        autoExecute: false,
        task: PrayerCardAPI.getPrayerCardTemplates,
        dataLoader: useCallback(res => {
            return _.get(res, "data.data");
        }, [])
    });

    useEffect(() => {
        executeGetTemplate();
    }, []);

    const uploadClick = () => {
        if (!isLocked) fileRef.current.click();
    };

    const showCustomToolTip = () => {
        setCustomTooltipOpen(!customTooltipOpen);
    };

    const showCustomModalToolTip = () => {
        setCustomModalTooltipOpen(!customModalTooltipOpen);
    };

    const fileChange = async evt => {
        const file = evt.target.files[0];
        setFileName(file.name);
        setMimeType(file.type);
        evt.target.value = null;
        let fileUrl = await getValidFileUrl(file);
        setAvatarImage(fileUrl);
        setIsModal(true);
    };

    const modalClose = () => {
        setIsModal(false);
        setZoom(1)
    }

    const avatarSave = async () => {
        if (avatarRef.current) {
            const canvasScaled = avatarRef.current.getImageScaledToCanvas();
            const croppedImg = canvasScaled.toDataURL();
            const blob = await (await fetch(croppedImg)).blob();
            const file = new File([blob], fileName, {
                type: mimeType,
                lastModified: new Date(),
            });
            var formData = new FormData();
            formData.append("file", file);
            addCustomPrayerCard(formData);
            setIsModal(false);
        }
    };

    const showLoader = useMemo(() => getTemplateLoader, [getTemplateLoader]);
    useSetLoading(showLoader);

    const handleSelectTemplate = (template) => {
        const newTemplate = {
            ...template,
            isNew: true,
            templateId: template.templateId || template.id,
        };
        delete newTemplate.id;
        saveTemplate(newTemplate);
    }

    return (
        <div className="prayer-card-body-wrapper">
            <div className="prayer-cards-list">
                {templates &&
                    templates.map(template => (
                        <Card
                            src={template.imageURL}
                            templateName={template.name}
                            select={() => {
                                if (!isLocked) {
                                    handleSelectTemplate(template);
                                }
                            }}
                            isLocked={isLocked}
                        />
                    ))}
                <div className="prayer-card prayer-card-upload-img">
                    <div className="prayer-card-template-img-container">
                        <button
                            className="link-customise btn-theme theme-orange orange-transparent orange-only-text"
                            onClick={uploadClick}
                            disabled={isLocked}
                        >
                            Upload your Image
                        </button>
                    </div>
                    <div className="d-flex justify-content-between align-items-center prayer-card-template-type">
                        <h3 className="custom-template-text">Custom</h3>
                        <TooltipAndInfo
                            id="custom-tooltip-icon"
                            showTooltipFn={showCustomToolTip}
                            showToolTip={customTooltipOpen}
                            tooltipText="Recommended size to upload the custom image is 187 X 322 Pixels"
                            color="#000000"
                        />
                    </div>
                </div>
                <input
                    type="file"
                    accept=".png,.jpg,.jpeg"
                    ref={fileRef}
                    className="DN"
                    onChange={fileChange}
                />
                <div>
                    <Modal
                        isOpen={isModal}
                        onClose={() => setIsModal(false)}
                        className='obituary-modal-wrapper mobile-view-wrapper prayer-custom-modal profile-section-wrapper'
                        style={{ width: "55%" }}
                    >
                        <ModalHeader className='prayer-custom-header'>
                            <img
                                src={Closebutton}
                                className='custom-close-button'
                                onClick={modalClose}
                                alt='close-button'
                            />
                        </ModalHeader>
                        <ModalBody align='center'>
                            <div className='story-wrapper prayer-custom-wrapper' align='center'>
                                <div className='custom-info-button'>
                                    <TooltipAndInfo
                                        id="custom-modal-tooltip-icon"
                                        showTooltipFn={showCustomModalToolTip}
                                        showToolTip={customModalTooltipOpen}
                                        tooltipText="Preferred Image size is 187 X 322 Pixels."
                                        tooltipExtraText="Resize the image to fit the card."
                                        color="#000000"
                                        size="25"
                                    />
                                </div>
                                <AvatarEditor
                                    ref={avatarRef}
                                    image={avatarImage}
                                    width={187}
                                    height={322}
                                    border={1}
                                    color={[0, 0, 0, 0.45]} // RGBA
                                    scale={zoom}
                                    rotate={0}
                                />
                            </div>
                            <div className='slidecontainer d-flex align-items-center justify-content-between'>
                                    <span className='symbol-size' onClick={() => setZoom((prev) => prev <= 0.1 ? prev : prev - 0.1)}>-</span>
                                    <input
                                        type='range'
                                        min={0.1}
                                        max={2}
                                        step={0.1}
                                        value={zoom}
                                        className='slider'
                                        onChange={(e) => {
                                            setZoom(parseFloat(e.target.value));
                                        }}
                                    ></input>
                                    <span className='symbol-size' onClick={() => setZoom((prev) => prev >= 2 ? prev : prev + 0.1)}>+</span>
                                </div>
                                <div className='avatar-buttons-wrapper'>
                                    <span
                                        style={{
                                            display: "block",
                                            float: "left",
                                        }}
                                    >
                                        <button
                                            className='btn-theme'
                                            onClick={modalClose}
                                        >
                                            CANCEL
                                        </button>
                                    </span>
                                    <span
                                        style={{
                                            display: "block",
                                            float: "right",
                                        }}
                                    >
                                        <button
                                            className='btn-theme'
                                            onClick={avatarSave}
                                        >
                                            SAVE
                                        </button>
                                    </span>
                                </div>
                        </ModalBody>
                    </Modal>
                </div>
            </div>
        </div>
    );
};

export default TemplateList;
