import _ from "lodash";
import { apiFetch } from "../configs/apiConfig";

export const getObituary = onePortalId => {
    return apiFetch({
        method: "GET",
        url: `/obituary/${onePortalId}`,
    });
};

export const updateObituary = (onePortalId, data, section, bool) => {
    return apiFetch({
        method: "POST",
        url:
            section === "title" || bool
                ? `/obituary/${onePortalId}/life-of`
                : `/obituary/${onePortalId}`,
        data,
    });
};

export const uploadMedia = (onePortalId, data, mediaType) => {
    return apiFetch({
        method: "POST",
        url: `/obituary/${onePortalId}/upload/${mediaType}`,
        data,
    });
};

export const getUpcomingEvents = (onePortalId, timeZone) => {
    return apiFetch({
        method: "GET",
        url: `/events/${onePortalId}?timezone=${timeZone}`,
    });
};

export const hideEventsAPI = (opi,hideEvent) => {
    let key = "",
        value = "";

    if (hideEvent && _.isObject(hideEvent) && !_.isEmpty(hideEvent)) {
        key = Number(Object.keys(hideEvent)[0]);
        value = Object.values(hideEvent)[0];
    } else {
        return;
    }

    let data = {
        isPrivate: value,
    };

    return apiFetch({
        method: "PUT",
        url: `/events/${opi}/hide/${key}`,
        data,
    });
};
export const hideEventsEndTimeApi = (opi, eventId, data) => {
    return apiFetch({
        method: "PUT",
        url: `/events/${opi}/hideEndTime/${eventId}`,
        data,
    });
};

export const getDisplayName = (opi, payload) => {
    let data = {
        displayName: payload
    }
    return apiFetch({
        method: 'POST',
        url: `/obituary/displayName/${opi}`,
        data,
    });
};
