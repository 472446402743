export const prefixes = [
    { value: "Mr", label: "Mr" },
    { value: "Ms", label: "Ms" },
    { value: "Mrs", label: "Mrs" },
    { value: "Esq", label: "Esq" },
    { value: "Phd", label: "Phd" },
    { value: "Dr", label: "Dr" },
    { value: "Master", label: "Master" },
    { value: "Miss", label: "Miss" },
    { value: "Professor", label: "Professor" },
    { value: "Rev", label: "Rev" },
    { value: "Fr", label: "Fr" },
    { value: "Rabbi", label: "Rabbi" },
];

export const genders = [
    { value: 1, label: "Female" },
    { value: 2, label: "Male" },
    { value: 3, label: "Unspecified" },
];

export const relations = [
    { id: 1, name: "Aunt" },
    { id: 2, name: "Brother" },
    { id: 3, name: "Brother-In-Law" },
    { id: 4, name: "Companion" },
    { id: 5, name: "Cousin" },
    { id: 6, name: "Daughter" },
    { id: 7, name: "Daughter-In-Law" },
    { id: 8, name: "Father" },
    { id: 9, name: "Father-In-Law" },
    { id: 10, name: "Fiance" },
    { id: 11, name: "Fiancee" },
    { id: 12, name: "Friend" },
    { id: 13, name: "Granddaughter" },
    { id: 14, name: "Grandfather" },
    { id: 15, name: "Grandmother" },
    { id: 16, name: "Grandson" },
    { id: 17, name: "Great Granddaughter" },
    { id: 18, name: "Great Grandfather" },
    { id: 19, name: "Great Grandmother" },
    { id: 20, name: "Great Grandson" },
    { id: 21, name: "Mother" },
    { id: 22, name: "Mother-In-Law" },
    { id: 23, name: "Nephew" },
    { id: 24, name: "Niece" },
    { id: 43, name: "Other" },
    { id: 25, name: "Partner" },
    { id: 45, name: "Self" },
    { id: 27, name: "Sister" },
    { id: 28, name: "Sister-In-Law" },
    { id: 29, name: "Son" },
    { id: 30, name: "Son-In-Law" },
    { id: 31, name: "Spouse" },
    { id: 32, name: "Step-Brother" },
    { id: 33, name: "Step-Daughter" },
    { id: 34, name: "Step-Father" },
    { id: 35, name: "Step-Mother" },
    { id: 36, name: "Step-Sister" },
    { id: 37, name: "Step-Son" },
    { id: 38, name: "Uncle" },

    { id: 39, name: "Doctor" },
    { id: 26, name: "DPOAHC" },
    { id: 44, name: "Conservator" },
    { id: 40, name: "Nurse" },
    { id: 41, name: "Coroner" },
    { id: 42, name: "SRDP" },
];
export const memoryTypes = [
    { value: 1, label: "Family" },
    { value: 2, label: "Relationship" },
    { value: 3, label: "Education" },
    { value: 4, label: "Career" },
    { value: 5, label: "Military" },
    { value: 6, label: "Important Dates" },
    { value: 7, label: "Hobby" },
    { value: 8, label: "Remembrances" },
];

export const funeralLocation = [
    {
        value: "Cypress Lawn Cremation Society",
        label: "Cypress Lawn Cremation Society",
        city: "Colma",
    },
    { value: "Cypress Lawn Funeral Home", label: "Cypress Lawn Funeral Home" },
    {
        value: "All County Cremation Service",
        label: "All County Cremation Service",
        city: "Colma",
    },
    {
        value: "Crosby-N. Gray & Co. Funeral Home",
        label: "Crosby-N. Gray & Co. Funeral Home",
        city: "Burlingame",
    },
    {
        value: "Miller-Dutra Coastside Chapel & Funeral Home",
        label: "Miller-Dutra Coastside Chapel & Funeral Home",
        city: "Half Moon Bay",
    },
    {
        value: "Sneider & Sullivan & O'Connell's Funeral Home",
        label: "Sneider & Sullivan & O'Connell's Funeral Home",
        city: "San Mateo",
    },
];

export const programFuneralLocations = [
    {
        value: "Cypress Lawn Cremation Society",
        label: "Cypress Lawn Cremation Society",
    },
    { value: "Cypress Lawn Funeral Home", label: "Cypress Lawn Funeral Home" },
    {
        value: "All County Cremation Service",
        label: "All County Cremation Service",
    },
    {
        value: "Crosby-N. Gray & Co. Funeral Home",
        label: "Crosby-N. Gray & Co. Funeral Home",
    },
    {
        value: "Miller Dutra Coast-side Funeral Home",
        label: "Miller Dutra Coast-side Funeral Home",
    },
    {
        value: "Sneider & Sullivan & O’Connell’s Funeral Home",
        label: "Sneider & Sullivan & O’Connell’s Funeral Home",
    },
]

export const prayerCardFuneralLocations = {
    "Cypress Lawn Cremation Society": "Colma",
    "Cypress Lawn Funeral Home": "Colma",
    "All County Cremation Service": "Colma",
    "Crosby-N. Gray & Co. Funeral Home": "Burlingame",
    "Miller-Dutra Coastside Chapel & Funeral Home": "Half Moon Bay",
    "Sneider & Sullivan & O'Connell's Funeral Home": "San Mateo",
};

export const programCardFuneralLocations = {
    "Cypress Lawn Cremation Society": {
        address: "1370 El Camino Real, Colma, CA 94014.",
        city: "Colma",
        phone: "(650) 550-8868",
        imageFilename: "CFS",
        label: "Cypress Lawn Cremation Society",
        value: "Cypress Lawn Cremation Society",
    },
    "Cypress Lawn Funeral Home": {
        address: "1370 El Camino Real, Colma, CA 94014.",
        city: "Colma",
        phone: "(650) 755-0580",
        imageFilename: "CFS",
        label: "Cypress Lawn Funeral Home",
        value: "Cypress Lawn Funeral Home",
    },
    "All County Cremation Service": {
        address: "1201 El Camino Real, Colma, CA 94014.",
        city: "Colma",
        phone: "(650) 343-3914",
        imageFilename: "ACC",
        label: "All County Cremation Service",
        value: "All County Cremation Service",
    },
    "Crosby-N. Gray & Co. Funeral Home": {
        address: "2 Park Road, Burlingame, CA 94010.",
        city: "Burlingame",
        phone: "(650) 342-6617",
        imageFilename: "CNG",
        label: "Crosby-N. Gray & Co. Funeral Home",
        value: "Crosby-N. Gray & Co. Funeral Home",
    },
    "Miller Dutra Coast-side Funeral Home": {
        address: "645 Kelly Ave, Half Moon Bay, CA 94019.",
        city: "Half Moon Bay",
        phone: "(650) 726-4474",
        imageFilename: "MDC",
        label: "Miller Dutra Coast-side Funeral Home",
        value: "Miller Dutra Coast-side Funeral Home",
    },
    "Sneider & Sullivan & O’Connell’s Funeral Home": {
        address: "977 S El Camino Real, San Mateo, CA 94402.",
        city: "San Mateo",
        phone: "(650) 343-1804",
        imageFilename: "SSO",
        label: "Sneider & Sullivan & O’Connell’s Funeral Home",
        value: "Sneider & Sullivan & O’Connell’s Funeral Home",
    },
};

export const programCardFuneralLocationTypes = {
    "Cypress Lawn Cremation Society": [
        {
            value: "1370 El Camino Real, Colma, CA 94014,",
            type: "heading4",
        },
        {
            value: "(650) 550-8868.",
            type: "heading4",
        },
        {
            type: "locationLogo",
            value: "CCS",
            imageFilename: "CFS",
        },
    ],
    "Cypress Lawn Funeral Home": [
        {
            value: "1370 El Camino Real, Colma, CA 94014,",
            type: "heading4",
        },
        {
            value: "(650) 755-0580.",
            type: "heading4",
        },
        {
            type: "locationLogo",
            value: "CFS",
            imageFilename: "CFS",
        },
    ],
    "All County Cremation Service": [
        {
            value: "1201 El Camino Real, Colma, CA 94014,",
            type: "heading4",
        },
        {
            value: "(650) 343-3914.",
            type: "heading4",
        },
        {
            type: "locationLogo",
            value: "ACC",
            imageFilename: "ACC",
        },
    ],
    "Crosby-N. Gray & Co. Funeral Home": [
        {
            value: "2 Park Road, Burlingame, CA 94010,",
            type: "heading4",
        },
        {
            value: "(650) 342-6617.",
            type: "heading4",
        },
        {
            type: "locationLogo",
            value: "CNG",
            imageFilename: "CNG",
        },
    ],
    "Miller Dutra Coast-side Funeral Home": [
        {
            value: "645 Kelly Ave, Half Moon Bay, CA 94019,",
            type: "heading4",
        },
        {
            value: "(650) 726-4474.",
            type: "heading4",
        },
        {
            type: "locationLogo",
            value: "MDC",
            imageFilename: "MDC",
        },
    ],
    "Sneider & Sullivan & O’Connell’s Funeral Home": [
        {
            value: "977 S El Camino Real, San Mateo, CA 94402,",
            type: "heading4",
        },
        {
            value: "(650) 343-1804.",
            type: "heading4",
        },
        {
            type: "locationLogo",
            value: "SSO",
            imageFilename: "SSO",
        },
    ],
};

export const getLocationByCode = value => {
    switch (value) {
        case "CFS":
            return "Cypress Lawn Funeral Home";
        case "ACC":
            return "All County Cremation Service";
        case "CNG":
            return "Crosby-N. Gray & Co. Funeral Home";
        case "MDC":
            return "Miller Dutra Coast-side Funeral Home";
        case "SSO":
            return "Sneider & Sullivan & O’Connell’s Funeral Home";
        case "CCS":
            return "Cypress Lawn Cremation Society";
        default:
            return "Cypress Lawn Funeral Home";
    }
};

export const getLocationByKey = value => {
    switch (value) {
        case "CFS":
            return "Cypress Lawn";
        case "CNG":
            return "Crosby-N. Gray & Co. Funeral Home";
        case "MDC":
            return "Miller-Dutra Coastside Chapel & Funeral Home";
        case "SSO":
            return "Sneider & Sullivan & O’Connell’s Funeral Home";
        default:
            return "Cypress Lawn";
    }
}

export const getLocationUrls = value => {
    switch(value) {
        case "CFS" :
            return "https://www.cypresslawn.com/cypress-lawn-funeral-home/";
        case "CNG" :
            return "https://www.cypresslawn.com/crosby-n-gray-co-funeral-home-and-cremation-service/";
        case "SSO" :
            return "https://www.cypresslawn.com/sneider-sullivan-oconnells-funeral-home-and-cremation-service/";
        case "MDC" :
            return "https://www.cypresslawn.com/miller-dutra-coastside-chapel/";
        default :
            return "https://www.cypresslawn.com/cypress-lawn-funeral-home/";
    }
}

export const getFullPageTitles = [
  { value: "arrangements", label: "Arrangements" },
  { value: "certificateDetails", label: "Certificate Details" },
  { value: "servicesDetails", label: "Services Details" },
  { value: "prayerCreation", label: "Prayer Creation" },
  { value: "programCreation", label: "Program Creation" },
  { value: "whatToExpect", label: "What To Expect" },
  { value: "whatToBring", label: "What To Bring" },
  { value: "obituary", label: "Obituary" },
  { value: "customizeFamilyWebsite", label: "Customize FamilyWebsite" },
  { value: "memories", label: "Memories" },
  { value: "decedents", label: "Decedents" },
  { value: "loginReport", label: "Login Report" },
  { value: "userReport", label: "User Report" },
  { value: "userProfile", label: "Profile Page" },
];

export const socialMediaTypes = [
  {
    value: "CustomUrl",
    label: "CustomUrl",
  },
  {
    value: "Facebook",
    label: "Facebook",
  },
  {
    value: "Instagram",
    label: "Instagram",
  },
  {
    value: "Twitter",
    label: "Twitter",
  },
  {
    value: "Tiktok",
    label: "Tiktok",
  },
  {
    value: "Blog",
    label: "Blog",
  },
  {
    value: "Youtube",
    label: "Youtube",
  },
  {
    value: "Linkedin",
    label: "Linkedin",
  },
];