import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import MemoryDashboard from "../../components/features/memoriesOld/dashboard";

const mapStateToProps = ({ authentication }) => {
    return {
        session: authentication.session,
    };
};

export default connect(mapStateToProps, null)(withRouter(MemoryDashboard));
