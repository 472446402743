import { store } from "react-notifications-component";

const notification = {
  insert: "top",
  container: "top-right",
  animationIn: ["animated", "fadeIn"],
  animationOut: ["animated", "fadeOut"],
  dismiss: {
    duration: 5000,
    pauseOnHover: true
  }
};

function addNotification (notifyData) {
  store.addNotification({
    ...notification,
    title: notifyData.title,
    message: notifyData.message,
    type: notifyData.type
  });
}

export default addNotification