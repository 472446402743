import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { formatOptions } from '../functions';
import SearchSelectComponent from '../SearchSelelctComponent/SearchSelelctComponent';

const CommonAddress = (props) => {
    const { className, showHeader, setFieldValue, isDisabled, values } = props;
    const { country, state, county, city, zipcode } = values;

    const {
        countries = [],
        counties = [],
        states = [],
        cities = [],
        zipcodes = [],
    } = props.data;

    const [countryOption, setCountries] = useState([]);
    const [countyOption, setCounties] = useState([]);
    const [stateOption, setStates] = useState([]);
    const [cityOption, setCities] = useState([]);
    const [zipcodeOption, setZipcodes] = useState([]);

    const filterOptions = () => {
        setCountries(formatOptions(countries));
        setCounties(formatCounty(counties));
        setStates(formatOptions(filter(states, 'state')));
        setCities(formatOptions(filter(cities, 'city'), 'name', 'code'));
        setZipcodes(formatZipcode(zipcodes));
    };

    useEffect(() => {
        if (props.data) {
            filterOptions();
        }
    }, [props.data]);

    const filter = (list, key) => {
        let filteredList = list;

        let filteredState = [];
        if (values['country']) {
            filteredState = _.filter(states, {
                country: {
                    name: values.country.name,
                },
            });
        }
        if (key === 'state') {
            if (values['country']) {
                filteredList = filteredState;
            }
        }
        if (key === 'county') {
            if (values['country']) {
                let list = [];
                _.map(filteredList, (l) => {
                    if (
                        _.find(filteredState, { name: _.get(l, 'state.name') })
                    ) {
                        list.push(l);
                    }
                });
                filteredList = list;
            }
            if (values['state']) {
                filteredList = _.filter(filteredList, {
                    state: {
                        name: values.state.name,
                    },
                });
            }
        }
        if (key === 'city') {
            if (values['country']) {
                let list = [];
                _.map(filteredList, (l) => {
                    if (
                        _.find(filteredState, { name: _.get(l, 'state.name') })
                    ) {
                        list.push(l);
                    }
                });
                filteredList = list;
            }
            if (values['state'] || values['county']) {
                filteredList = _.filter(filteredList, {
                    state: {
                        name: values.state.name,
                    },
                });
                if (values['county']) {
                    let zipcodeList = _.filter(zipcodeOption, {
                        county: {
                            description: values.county.description,
                        },
                        state: {
                            name: values.state.name,
                        },
                    });
                    let list = [];
                    _.map(filteredList, (l) => {
                        if (
                            _.find(zipcodeList, {
                                city: {
                                    name: _.get(l, 'name'),
                                },
                            })
                        ) {
                            list.push(l);
                        }
                    });
                    filteredList = list;
                }
            }
        }
        if (key === 'zipcode') {
            if (values['country']) {
                let list = [];
                _.map(filteredList, (l) => {
                    if (
                        _.find(filteredState, { name: _.get(l, 'state.name') })
                    ) {
                        list.push(l);
                    }
                });
                filteredList = list;
            }
            if (values['state'] || values['county'] || values['city']) {
                let queryObj = {};
                if (_.get(values, 'state.name')) {
                    queryObj = {
                        ...queryObj,
                        state: {
                            name: _.get(values, 'state.name'),
                        },
                    };
                }
                if (_.get(values, 'county.description')) {
                    queryObj = {
                        ...queryObj,
                        county: {
                            description: _.get(values, 'county.description'),
                        },
                    };
                }
                if (_.get(values, 'city.name')) {
                    queryObj = {
                        ...queryObj,
                        city: {
                            name: _.get(values, 'city.name'),
                        },
                    };
                }
                filteredList = _.filter(filteredList, queryObj);
            }
        }
        return filteredList;
    };

    useEffect(() => {
        filterOptions();
    }, [country]);

    useEffect(() => {
        filterOptions();
    }, [state]);

    useEffect(() => {
        filterOptions();
    }, [county]);

    useEffect(() => {
        filterOptions();
    }, [city]);

    useEffect(() => {
        filterOptions();
    }, [zipcode]);

    const handleSearch = (searchString, options) => {
        if (searchString) {
            return _.filter(options, (opt) =>
                _.includes(
                    _.toLower(_.get(opt, 'label')),
                    _.toLower(searchString)
                )
            );
        }
        return options;
    };

    const formatZipcode = (zipcodes) => {
        zipcodes = _.map(zipcodes, (option) => {
            return {
                label: `${option.code} - ${_.get(
                    option,
                    'city.name',
                    _.get(option, 'cityName')
                )}`,
                value: option.code,
                ...option,
            };
        });
        return zipcodes;
    };

    const formatCounty = (counties) => {
        counties = _.map(counties, (option) => {
            return {
                label: `${option.description} - ${_.get(option, 'state.name')}`,
                value: option.description,
                ...option,
            };
        });
        return counties;
    };

    return (
        <div
            className={
                'common-address-wrapper' + (className ? ' ' + className : '')
            }
        >
            <div className="inputcard-wrapper commom-address-section">
                {showHeader && (
                    <div className="inputcard-header d-flex align-items-center justify-content-start">
                        <span>Address Details</span>
                    </div>
                )}
                <div className="inputcard-body custom-inputcard-body">
                    <div className="inputcard-group d-flex align-items-start justify-content-start flex-wrap">
                        <div className="choose-reason-address choose-reason inputfield d-flex align-items-center justify-content-between">
                            <div className="inputcard-withlabel d-flex align-items-start justify-content-center flex-column">
                                <label className="inputcard-label">
                                    Address Line 1
                                </label>
                                <input
                                    className="custominputfield"
                                    placeholder="Enter Address Line 1"
                                    maxLength={50}
                                    name="line1"
                                    defaultValue={values.line1}
                                    onChange={(e) => {
                                        setFieldValue('line1', e.target.value);
                                    }}
                                    disabled={isDisabled}
                                />
                            </div>
                        </div>
                        <div className="choose-reason-address choose-reason inputfield d-flex align-items-center justify-content-between">
                            <div className="inputcard-withlabel d-flex align-items-start justify-content-center flex-column">
                                <label className="inputcard-label">
                                    Address Line 2
                                </label>
                                <input
                                    className="custominputfield"
                                    placeholder="Enter Address Line 2"
                                    maxLength={50}
                                    name="line2"
                                    defaultValue={values.line2}
                                    onChange={(e) => {
                                        setFieldValue('line2', e.target.value);
                                    }}
                                    disabled={isDisabled}
                                />
                            </div>
                        </div>
                        <div className="country-dropdown choose-reason-address choose-reason inputfield d-flex align-items-center justify-content-between">
                            <div className="inputcard-withlabel d-flex align-items-start justify-content-center flex-column">
                                <label className="inputcard-label">
                                    Country
                                </label>
                                <SearchSelectComponent
                                    isClearable
                                    className="display-clear-indicator"
                                    options={countryOption}
                                    handleInputChange={(e) => {
                                        setCountries(
                                            handleSearch(
                                                e,
                                                formatOptions(countries)
                                            )
                                        );
                                    }}
                                    selectedOption={country}
                                    name="country"
                                    handleChange={(e) => {
                                        setFieldValue('country', e);
                                        setFieldValue('state', '');
                                        setFieldValue('county', '');
                                        setFieldValue('city', '');
                                        setFieldValue('zipcode', '');
                                    }}
                                    placeholder="Search for Country"
                                    disabled={isDisabled}
                                />
                            </div>
                        </div>
                        <div className="choose-reason-address choose-reason inputfield d-flex align-items-center justify-content-between">
                            <div className="inputcard-withlabel d-flex align-items-start justify-content-center flex-column">
                                <label className="inputcard-label">State</label>
                                <SearchSelectComponent
                                    isClearable={true}
                                    options={stateOption}
                                    className="display-clear-indicator"
                                    handleInputChange={(e) => {
                                        setStates(
                                            handleSearch(
                                                e,
                                                formatOptions(
                                                    filter(states, 'state')
                                                )
                                            )
                                        );
                                    }}
                                    selectedOption={state}
                                    name="state"
                                    handleChange={(e) => {
                                        setFieldValue('state', e);
                                        if (e) {
                                            setFieldValue(
                                                'country',
                                                _.find(
                                                    formatOptions(countries),
                                                    {
                                                        value: e.country.name,
                                                    }
                                                )
                                            );
                                        } else {
                                        }
                                        setFieldValue('county', '');
                                        setFieldValue('city', '');
                                        setFieldValue('zipcode', '');
                                    }}
                                    placeholder="Search for State"
                                    disabled={!values.country || isDisabled}
                                />
                            </div>
                        </div>
                        <div className="choose-reason-address choose-reason inputfield d-flex align-items-center justify-content-between">
                            <div className="inputcard-withlabel d-flex align-items-start justify-content-center flex-column">
                                <label className="inputcard-label">
                                    County
                                </label>
                                <SearchSelectComponent
                                    isClearable
                                    className="display-clear-indicator"
                                    options={countyOption}
                                    handleInputChange={(e) => {
                                        setCounties(
                                            handleSearch(
                                                e,
                                                formatCounty(counties)
                                            )
                                        );
                                    }}
                                    selectedOption={county}
                                    name="county"
                                    handleChange={(e) => {
                                        setFieldValue('county', e);
                                        if (e) {
                                            const selectedState = _.find(
                                                formatOptions(states),
                                                {
                                                    value: e.state.name,
                                                }
                                            );
                                            setFieldValue(
                                                'state',
                                                selectedState
                                            );
                                            setFieldValue(
                                                'country',
                                                _.find(
                                                    formatOptions(countries),
                                                    {
                                                        value:
                                                            selectedState
                                                                .country.name,
                                                    }
                                                )
                                            );
                                        }
                                        setFieldValue('city', '');
                                        setFieldValue('zipcode', '');
                                    }}
                                    placeholder="Search for County"
                                    disabled={!values.country || isDisabled}
                                />
                            </div>
                        </div>
                        <div className="choose-reason-address choose-reason inputfield d-flex align-items-center justify-content-between">
                            <div className="inputcard-withlabel d-flex align-items-start justify-content-center flex-column">
                                <label className="inputcard-label">City</label>
                                <SearchSelectComponent
                                    isClearable
                                    options={cityOption}
                                    className="display-clear-indicator"
                                    selectedOption={city}
                                    handleInputChange={(e) => {
                                        setCities(
                                            handleSearch(
                                                e,
                                                formatOptions(
                                                    filter(cities, 'city'),
                                                    'name',
                                                    'code'
                                                )
                                            )
                                        );
                                    }}
                                    name="city"
                                    handleChange={(e) => {
                                        setFieldValue('city', e);
                                        if (e) {
                                            const selectedState = _.find(
                                                formatOptions(states),
                                                {
                                                    value: e.state.name,
                                                }
                                            );
                                            setFieldValue(
                                                'state',
                                                selectedState
                                            );
                                            setFieldValue(
                                                'country',
                                                _.find(
                                                    formatOptions(countries),
                                                    {
                                                        value:
                                                            selectedState
                                                                .country.name,
                                                    }
                                                )
                                            );
                                        } else {
                                        }
                                        setFieldValue('zipcode', '');
                                    }}
                                    placeholder="Search for City"
                                    disabled={!values.country || isDisabled}
                                />
                            </div>
                        </div>
                        <div className="choose-reason-address choose-reason inputfield d-flex align-items-center justify-content-between">
                            <div className="inputcard-withlabel d-flex align-items-start justify-content-center flex-column">
                                <label className="inputcard-label">ZIP</label>
                                <SearchSelectComponent
                                    isClearable
                                    options={zipcodeOption}
                                    className="display-clear-indicator"
                                    handleInputChange={(e) => {
                                        setZipcodes(
                                            handleSearch(
                                                e,
                                                formatZipcode(zipcodes)
                                            )
                                        );
                                    }}
                                    selectedOption={zipcode}
                                    name="zipcode"
                                    handleChange={(e) => {
                                        setFieldValue('zipcode', e);
                                        if (e) {
                                            let country = _.find(
                                                formatOptions(countries),
                                                {
                                                    value: e.country.name,
                                                }
                                            );
                                            let state, county, city;
                                            if (e.state) {
                                                state = _.find(
                                                    formatOptions(states),
                                                    {
                                                        value: e.state.name,
                                                    }
                                                );
                                            } else if (e.stateName) (
                                                state = {
                                                    label: e.stateName,
                                                    value: e.stateName,
                                                    name: e.stateName
                                                }
                                            )
                                            if (e.county) {
                                                county = _.find(
                                                    formatOptions(
                                                        counties,
                                                        'description',
                                                        'description'
                                                    ),
                                                    {
                                                        value:
                                                            e.county
                                                                .description,
                                                        state: {
                                                            name: e.state.name,
                                                        },
                                                    }
                                                );
                                            } else if (e.countyName) (
                                                county = {
                                                    label: e.countyName,
                                                    value: e.countyName,
                                                    description: e.countyName,
                                                    state: e.stateName ? {
                                                        name: e.stateName
                                                    } : null
                                                }
                                            )

                                            if (e.city) {
                                                let citySearchObj = {
                                                    value: e.city.name,
                                                };
                                                if (e.state) {
                                                    citySearchObj = {
                                                        ...citySearchObj,
                                                        state: {
                                                            name: e.state.name,
                                                        },
                                                    };
                                                }
                                                city = _.find(
                                                    formatOptions(
                                                        cities,
                                                        'name',
                                                        'code'
                                                    ),
                                                    citySearchObj
                                                );
                                            } else if (e.cityName) {
                                                city = {
                                                    name: e.cityName,
                                                    label: e.cityName,
                                                    value: e.cityName,
                                                    state: e.stateName ? {
                                                        name: e.stateName
                                                    } : null
                                                }
                                            }
                                            
                                            
                                            setFieldValue('country', country);
                                            setFieldValue('state', state);
                                            setFieldValue('county', county);
                                            setFieldValue('city', city);
                                        } else {
                                            setFieldValue('state', '');
                                            setFieldValue('county', '');
                                            setFieldValue('city', '');
                                        }
                                    }}
                                    placeholder="Search for ZIP"
                                    disabled={!values.country || isDisabled}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CommonAddress;
