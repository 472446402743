import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

export default class DatepickerComponent extends React.Component {

    render() {
        return (
            <DatePicker
                id={this.props.id}
                name={this.props.name}
                className="datepicker-field"
                placeholderText={this.props.placeholderText}
                dateFormat={
                    this.props.showTimeSelect
                        ? 'MM/dd/yyyy h:mm aa'
                        : 'MM/dd/yyyy'
                }
                maxDate={Date.now()}
                timeCaption="Time"
                timeFormat="h:mm"
                timeIntervals={15}
                fixedHeight
                showMonthDropdown
                showYearDropdown
                showTimeInput={this.props.showTimeSelect}
                selected={this.props.selectedDate}
                onChange={this.props.onChange}
                disabled={this.props.disabled}
            />
        );
    }
}
