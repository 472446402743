import React from "react";

import AlertMessage from "../utils/AlertMessage/AlertMessage";
import { doLogout, handleAuthLogout } from "../../utils";
import Cookies from "universal-cookie";

const SessionExpiryPopup = ({ open, msalInstance }) => {
    const isAzureMFAEnabled = process.env.REACT_APP_IS_AZURE_MFA_ENABLED;
    const cookies = new Cookies()
    return (
        <AlertMessage
            toggleAlertModal={() => {
                if (open) {
                  const role = cookies.get("role");
                  const logoutRedirectUrl =
                    process.env.REACT_APP_HOST !== "https://temp.clcafamily.com"
                      ? `/obituaries`
                      : `/`;
                  // Without clicking on sign out, if forceful logout occurs,
                  // and if it is a cl-arranger login with MFA enabled, then we need to remove account details as well.
                  if (
                    isAzureMFAEnabled &&
                    msalInstance?.getAllAccounts()?.length >=1
                  )
                    handleAuthLogout(
                      msalInstance,
                      msalInstance?.getAllAccounts(),
                      logoutRedirectUrl,
                      true
                    );
                  doLogout();
                }    
            }}
            toggleAlertState={open}
            successMsg={"Your session has been expired. Login again to continue"}
            showCancelButton={false}
            title={"Session Expiry"}
        />
    );
};

export default SessionExpiryPopup;
