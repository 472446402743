import React from 'react';

const generateGridArray = () => {
  return new Array(8).fill(1);
};

export default function CustomImageGrid({ url }) {
  return (
    <div className="custom-image-grid">
        {generateGridArray().map(() => (
          <div className="custom-item-container">
            <img src={url} alt="Img" />
          </div>
        ))}
    </div>
  );
}