import { Formik } from "formik";
import { Form } from "reactstrap";
import _ from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import * as yup from "yup";
import SelectComponent from "../../../utils/SelectComponent/SelectComponent";
import TooltipAndInfo from "../../../TooltipAndInfo";
import * as ProfileAPI from "../../../../api/profile";
import useAsync from "react-use-async-hook";
import addNotification from "../../../utils/notification/notification";
import { useSetLoading } from "../../../utils/hooks/loader";

const TransferLovedOnes = ({ decedents, executeGetDecedent }) => {
    const [transferTooltipOpen, setTransferTooltipOpen] = useState(false);
    const [emailExists, setEmailExists] = useState(false);
    const [emailInfo, setEmailInfo] = useState();
    const [selectedDecedent, setSelectedDecedent] = useState({});
    const [isTransfer, setIsTranfer] = useState(false);
    const [isAgree, setIsAgree] = useState(false);
    const [errorMsg, setErrorMsg] = useState(false);

    const emailRegex =
        /^[a-zA-Z0-9_]([\.-]?[a-zA-Z0-9_])*@[a-zA-Z0-9_]([\.-]?[a-zA-Z0-9_])*(\.[a-zA-Z_]{2,})+$/;

    decedents = decedents && [
        ...decedents.activeCases,
        ...decedents.closedCases,
    ];

    const showTransferToolTip = () => {
        setTransferTooltipOpen(!transferTooltipOpen);
    };

    const getPersonName = (person) => {
        let name = [];
        if (_.get(person, "firstName")) {
            name.push(_.get(person, "firstName"));
        }
        if (_.get(person, "middleName")) {
            name.push(_.get(person, "middleName"));
        }
        if (_.get(person, "lastName")) {
            name.push(_.get(person, "lastName"));
        }
        return name.join(" ") || "";
    };

    const {
        data: transferInfo,
        loading: getTransferLoader,
        execute: executeTransferAccount,
    } = useAsync({
        autoExecute: false,
        task: useCallback(async (values, selectedDecedent, formikBag) => {
            let payload = {
                firstName: values.firstName,
                lastName: values.lastName,
                decedentId: selectedDecedent.id,
                email: values.email,
            };
            return {
                res: await ProfileAPI.transferAccount(payload),
            };
        }, []),
        dataLoader: useCallback(res => {
            addNotification({
                type: "success",
                title: "Success",
                message: `Account Transfered Successfully`,
            });
            return res;
        }, []),
        onError: useCallback(error => {
            addNotification({
                type: "danger",
                title: "Error",
                message: _.get(
                    error,
                    "response.data.message",
                    "Oops! Something went wrong"
                ),
            });
        }, []),
    });

    useEffect(() => {
        if (transferInfo) {
            executeGetDecedent()
        }
    }, [transferInfo, executeGetDecedent]);

    useEffect(() => {
        if (emailInfo) {
            if (emailInfo.isNewUser) {
                setEmailExists(false);
            } else {
                setEmailExists(true);
            }
        }
    }, [emailInfo]);

    // const showLoader = useMemo(() => getTransferLoader, [getTransferLoader]);
    // useSetLoading(showLoader);

    const decedentList =
        decedents &&
        decedents.length &&
        decedents.map((person) => {
            let { decedent } = person;
            let personName =
                (_.get(decedent, "prefix")
                    ? _.get(decedent, "prefix") + ". "
                    : "") + getPersonName(decedent);
            return {
                value: personName,
                label: personName,
                id: decedent.id,
            };
        });

    return (
        <Formik
            initialValues={{
                firstName: "",
                lastName: "",
                email: "",
                lovedOnes: "",
            }}
            enableReinitialize={true}
            validationSchema={yup.object().shape({
                lovedOnes: yup.string().required("Choose your Loved Ones"),
                email: yup
                    .string()
                    .trim()
                    .required("E-mail Id is required")
                    .matches(emailRegex, "Invalid Email"),
                firstName: yup.string().required("Firstname is required"),
                lastName: yup.string().required("Lastname is required"),
            })}
            onSubmit={(values, {resetForm},formikBag) => {
                executeTransferAccount(values, selectedDecedent, formikBag);
                setIsAgree(!isAgree)
                setIsTranfer(!isTransfer)
                resetForm();
                console.log(values);
            }}
        >
            {({
                values,
                touched,
                errors,
                handleSubmit,
                isSubmitting,
                resetForm,
                handleBlur,
                setFieldValue,
                setSubmitting,
            }) => {
                const handleChange = async (e, data) => {
                    const { name, value } = e.target;
                    setFieldValue(name, value);
                    if (name === "email") {
                        if (emailRegex.test(value)) {
                            const res = await ProfileAPI.transferEmailValidate(
                                value
                            );
                            const info = res.data?.payload;
                            info && setEmailInfo(info);
                            info &&
                                info.firstName &&
                                setFieldValue("firstName", info.firstName);
                            info &&
                                info.lastName &&
                                setFieldValue("lastName", info.lastName);
                            setErrorMsg(false);
                        } else if (value === "") {
                            setErrorMsg(false);
                            setEmailExists(false);
                            if(emailExists) {
                            setFieldValue("firstName", "");
                            setFieldValue("lastName","");
                            }
                        } else {
                            setErrorMsg("Invalid Email");
                        }
                    }
                    setSubmitting(false);
                };

                return (
                    <Form
                        className='transferlovedones-form mt-4'
                        onSubmit={handleSubmit}
                    >
                        <div className='cardbox-wrapper'>
                            <div className='cardbox-heading'>
                                <h4 className='cardbox-heading-name text-capitalize text-black'>
                                    Transfer Loved Ones
                                </h4>
                                <TooltipAndInfo
                                    id='biography-tooltip-icon'
                                    showTooltipFn={showTransferToolTip}
                                    showToolTip={transferTooltipOpen}
                                    tooltipText='Once submitted, the ownership of your loved one will be transferred to the new email. Please contact your arranger in case of any assistance.'
                                    color='#000000'
                                />
                            </div>
                            <div className='inputcard-wrapper cardbox-body'>
                                <div className='inputcard-body custom-inputcard-body'>
                                    <div className='inputcard-group d-flex align-items-center justify-content-start'>
                                        <div className='choose-reason inputfield d-flex align-items-center justify-content-between'>
                                            <div className='inputcard-withlabel d-flex align-items-start justify-content-center flex-column'>
                                                <label className='inputcard-label'>
                                                    Choose Loved Ones
                                                    <small className='ml-2 input-mandatory'>
                                                        *
                                                    </small>
                                                </label>
                                                <SelectComponent
                                                    placeholder='Select'
                                                    selectedOption={_.get(
                                                        values,
                                                        "lovedOnes"
                                                    )}
                                                    defaultValue={_.get(
                                                        values,
                                                        "lovedOnes"
                                                    )}
                                                    options={decedentList}
                                                    handleChange={(e) => {
                                                        setFieldValue(
                                                            "lovedOnes",
                                                            e
                                                        );
                                                        setSubmitting(false);
                                                        setSelectedDecedent(e);
                                                    }}
                                                    onBlur={handleBlur}
                                                    name='lovedOnes'
                                                />
                                                {errors.lovedOnes &&
                                                touched.lovedOnes ? (
                                                    <span className='error-input-msg mt-md-3'>
                                                        {errors.lovedOnes}
                                                    </span>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className='choose-reason inputfield d-flex align-items-center justify-content-between'>
                                            <div className='inputcard-withlabel d-flex align-items-start justify-content-center flex-column'>
                                                <label className='inputcard-label'>
                                                    First Name
                                                    <small className='ml-2 input-mandatory'>
                                                        *
                                                    </small>
                                                </label>
                                                <input
                                                    id='firstName'
                                                    name='firstName'
                                                    className='custominputfield'
                                                    type='text'
                                                    //placeholder='Enter First Name'
                                                    onChange={(e) =>
                                                        handleChange(e)
                                                    }
                                                    value={
                                                        values.firstName || ""
                                                    }
                                                    onBlur={handleBlur}
                                                    disabled={emailExists}
                                                />
                                                {errors.firstName &&
                                                touched.firstName ? (
                                                    <span className='error-input-msg mt-md-3'>
                                                        {errors.firstName}
                                                    </span>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className='choose-reason inputfield d-flex align-items-center justify-content-between'>
                                            <div className='inputcard-withlabel d-flex align-items-start justify-content-center flex-column'>
                                                <label className='inputcard-label'>
                                                    Last Name
                                                    <small className='ml-2 input-mandatory'>
                                                        *
                                                    </small>
                                                </label>
                                                <input
                                                    id='lastName'
                                                    name='lastName'
                                                    className='custominputfield'
                                                    type='text'
                                                    //placeholder='Enter First Name'
                                                    onChange={(e) =>
                                                        handleChange(e)
                                                    }
                                                    value={
                                                        values.lastName || ""
                                                    }
                                                    onBlur={handleBlur}
                                                    disabled={emailExists}
                                                />
                                                {errors.lastName &&
                                                touched.lastName ? (
                                                    <span className='error-input-msg mt-md-3'>
                                                        {errors.lastName}
                                                    </span>
                                                ) : null}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='inputcard-group d-flex align-items-center justify-content-start'>
                                        <div className='choose-reason inputfield d-flex align-items-center justify-content-between'>
                                            <div className='inputcard-withlabel d-flex align-items-start justify-content-center flex-column'>
                                                <label className='inputcard-label'>
                                                    New Email Id
                                                    <small className='ml-2 input-mandatory'>
                                                        *
                                                    </small>
                                                </label>
                                                <input
                                                    id='email'
                                                    className='custominputfield'
                                                    type='email'
                                                    placeholder='Enter mail Id'
                                                    onChange={handleChange}
                                                    value={values.email}
                                                    onBlur={handleBlur}
                                                    name='email'
                                                />
                                                {errors.email &&
                                                touched.email ? (
                                                    <span className='error-input-msg mt-md-3'>
                                                        {errors.email}
                                                    </span>
                                                ) : null}
                                                {errorMsg && (
                                                    <p className='error-input-msg mt-md-3'>
                                                        {errorMsg}
                                                    </p>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className='inputcard-footer'
                                    style={{
                                        justifyContent: "space-between",
                                        marginTop: 20,
                                    }}
                                >
                                    <div className='d-flex flex-column'>
                                        <div className='mb-3'>
                                            <input
                                                type='checkbox'
                                                id='transfer'
                                                checked={isAgree}
                                                onChange={() =>
                                                    setIsAgree(!isAgree)
                                                }
                                                className='mr-2'
                                                style={{ cursor: "pointer" }}
                                            />
                                            <span>
                                                I agree that I authorize the
                                                transfer of this account to the
                                                following family member{" "}
                                                <strong>
                                                    {emailInfo &&
                                                    emailInfo.firstName
                                                        ? emailInfo.firstName
                                                        : values.firstName}{" "}
                                                    {emailInfo &&
                                                    emailInfo.lastName
                                                        ? emailInfo.lastName
                                                        : values.lastName}{" "}
                                                </strong>
                                                {values.email && (
                                                    <span>
                                                        {(values.firstName ||
                                                            values.lastName) &&
                                                            "and"}{" "}
                                                        <strong>
                                                            {values.email}
                                                        </strong>
                                                    </span>
                                                )}
                                            </span>
                                        </div>
                                        <div className='mb-3'>
                                            <input
                                                type='checkbox'
                                                id='transfer'
                                                checked={isTransfer}
                                                onChange={() =>
                                                    setIsTranfer(!isTransfer)
                                                }
                                                className='mr-2'
                                                style={{ cursor: "pointer" }}
                                            />
                                            <span>
                                                I agree that once I have
                                                initiated the transfer, I will
                                                no longer have administrative
                                                access to the above-mentioned
                                                account.
                                            </span>
                                        </div>
                                    </div>

                                    <button
                                        type='submit'
                                        disabled={
                                            isSubmitting ||
                                            !isTransfer ||
                                            !isAgree
                                        }
                                        className='transfer-button text-uppercase'
                                    >
                                        TRANSFER OWNERSHIP
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default TransferLovedOnes;
