import React, { useCallback, useEffect, useMemo, useState } from "react";
import _ from "lodash";
import useAsync from "react-use-async-hook";

import * as AuthAPI from "../../../api/auth";
import * as ProfileAPI from "../../../api/profile";
import * as DecedentAPI from "../../../api/dashboard";
import { useSetLoading } from "../../utils/hooks/loader";
import addNotification from "../../utils/notification/notification";

import BreadCrumb from "../../utils/breadCrumb/breadCrumb";
import PersonalDetails from "./personalDetail";
import ChangePassword from "./changePassword";
import AppLayout from "../../appLayout";
import TransferLovedOnes from "./transferLovedOnes";

const UserProfile = () => {
    const [loader, setLoader] = useState(false);
    const [isArranger, setIsArranger] = useState(true);

    /**
     * Get User Details
     */
    const {
        data: userInfo,
        loading: getUserLoader,
        execute: executeGetUser,
    } = useAsync({
        autoExecute: false,
        task: AuthAPI.isUserLoggedIn,
        dataLoader: useCallback(res => {
            return _.get(res, "data.data");
        }, []),
    });

    useEffect(() => {
        executeGetUser();
    }, []);

    useEffect(() => {
        if (userInfo?.role === "arranger") {
            setIsArranger(true);
            executeGetDecedent()
        } else {
            setIsArranger(false);
        }
    }, [userInfo]);

    const {
        data: decedents,
        loading: getDecedentLoader,
        execute: executeGetDecedent,
    } = useAsync({
        autoExecute: false,
        task: DecedentAPI.getDecedentList,
        dataLoader: useCallback((res) => {
            return _.get(res, "data.decedent");
        }, []),
    });

    /**
     * Get User Password
     */
    const { loading: updatePasswordLoader, execute: executeUpdatePassword } =
        useAsync({
            autoExecute: false,
            task: useCallback(async (payload, formikBag) => {
                return {
                    res: await ProfileAPI.updatePassword(payload),
                    formikBag,
                };
            }, []),
            dataLoader: useCallback(({ res, formikBag }) => {
                formikBag.setSubmitting(false);
                formikBag.resetForm();
                addNotification({
                    type: "success",
                    title: "Success",
                    message: _.get(res, "data.message"),
                });
            }, []),
            onError: useCallback(error => {
                addNotification({
                    type: "danger",
                    title: "Error",
                    message: _.get(
                        error,
                        "response.data.message",
                        "Oops! Something went wrong"
                    ),
                });
            }, []),
        });

    const { loading: userSaveLoader, execute: executeSaveUserDetail } =
        useAsync({
            autoExecute: false,
            task: useCallback(async (payload, formikBag) => {
                return {
                    res: await ProfileAPI.updateUserDetail(payload),
                    formikBag,
                };
            }, []),
            dataLoader: useCallback(({ res, formikBag }) => {
                formikBag.setSubmitting(false);
                //  formikBag.resetForm();
                addNotification({
                    type: "success",
                    title: "Success",
                    message: _.get(res, "data.message"),
                });
                executeGetUser();
            }, []),
            onError: useCallback(error => {
                addNotification({
                    type: "danger",
                    title: "Error",
                    message: _.get(
                        error,
                        "response.data.message",
                        "Oops! Something went wrong"
                    ),
                });
            }, []),
        });

    const showLoader = useMemo(
        () =>
            getUserLoader ||
            updatePasswordLoader ||
            loader ||
            userSaveLoader ||
            getDecedentLoader,
        [
            getUserLoader,
            updatePasswordLoader,
            loader,
            userSaveLoader,
            getDecedentLoader,
        ]
    );
    useSetLoading(showLoader);

    return (
        <AppLayout setLoader={setLoader}>
            <div className='container'>
                {isArranger ? (
                    <BreadCrumb
                        links={[
                            {
                                url: `/app`,
                                description: "Home",
                                heading: "Dashboard",
                            },
                            {
                                url: "/app/userProfile",
                                description: "Profile",
                                heading: "Profile",
                                isSpan: true,
                            },
                        ]}
                        showBack={true}
                    />
                ) : (
                    <BreadCrumb
                        links={[
                            {
                                url: `/admin/memories`,
                                description: "Home",
                                heading: "Dashboard",
                            },
                            {
                                url: "/admin/userProfile",
                                description: "Profile",
                                heading: "Profile",
                                isSpan: true,
                            },
                        ]}
                        showBack={true}
                    />
                )}
                <div className='navigationCenter-body-wrapper'>
                    <div className='userprofile-wrapper'>
                        <PersonalDetails
                            userInfo={userInfo}
                            executeSaveUserDetail={executeSaveUserDetail}
                        />
                        <ChangePassword
                            executeUpdatePassword={executeUpdatePassword}
                        />
                        {userInfo?.role === "arranger" && (
                            <TransferLovedOnes
                                decedents={decedents}
                                executeGetDecedent={executeGetDecedent}
                            />
                        )}
                    </div>
                </div>
            </div>
        </AppLayout>
    );
};

export default UserProfile;
