import { Formik } from "formik";
import _ from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Form } from "reactstrap";
import { apiErrorHandler } from "../../../../utils/handlers";
import TooltipAndInfo from "../../../TooltipAndInfo";
import SelectComponent from "../../../utils/SelectComponent/SelectComponent";
import DisplayNameFilterOptions from "./selectField-checkbox";
import useAsync from "react-use-async-hook";
import { getDisplayName } from "../../../../api/obituary";
import addNotification from "../../../utils/notification/notification";
import { useSetLoading } from "../../../utils/hooks/loader";

const SelectingDisplayName = ({
  decedentData,
  obituary,
  opi,
  executeObituary,
}) => {
  const [selectedName, setSelectedName] = useState({});
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [selectedNameList, setSelectedNameList] = useState();
  const [displayName, setDisplayName] = useState("");
  const [dropdownValue, setDropdownValue] = useState({});
  const [displayNameToolTip, setDisplayNameToolTip] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);

  const displayObject = obituary?.displayNameObject;

  const showDisplayNameToolTip = () => {
    setDisplayNameToolTip(!displayNameToolTip);
  };

  const options = [
    {
      value: "Legal",
      label: "Legal Name",
    },
  ];
  obituary?.preferredFirstName &&
    obituary?.preferredLastName &&
    options.push({
      value: "Preferred",
      label: "Preferred Name",
    });

  const preferredNameList = [
    {
      id: "prefix",
      label: "Prefix",
      value: obituary?.prefix,
    },
    {
      id: "preferredFirstName",
      label: "First Name",
      value: obituary?.preferredFirstName,
    },
    {
      id: "preferredMiddleName",
      label: "Middle Name",
      value: obituary?.preferredMiddleName,
    },
    {
      id: "preferredLastName",
      label: "Last Name",
      value: obituary?.preferredLastName,
    },
    {
      id: "suffix",
      label: "Suffix",
      value: obituary?.suffix,
    },
  ];

  const legalNameList = [
    {
      id: "prefix",
      label: "Prefix",
      value: obituary?.prefix,
    },
    {
      id: "firstName",
      label: "First Name",
      value: obituary?.firstName,
    },
    {
      id: "middleName",
      label: "Middle Name",
      value: obituary?.middleName,
    },
    {
      id: "lastName",
      label: "Last Name",
      value: obituary?.lastName,
    },
    {
      id: "suffix",
      label: "Suffix",
      value: obituary?.suffix,
    },
  ];

  useEffect(() => {
    if (displayObject) {
      const objKeys = Object.keys(displayObject)[0];
      setDisplayName(objKeys);
      if (objKeys === "Legal") {
        setDropdownValue({ value: "Legal", label: "Legal Name" });
      } else if (objKeys === "Preferred") {
        setDropdownValue({ value: "Preferred", label: "Preferred Name" });
      }
    }
  }, [displayObject]);

  useEffect(() => {
    if (displayObject) {
      const objKeys = Object.keys(displayObject)[0];
      const objValues = Object.values(displayObject)[0];
      if (objKeys === "Legal" && displayName === "Legal") {
        setSelectedNameList(legalNameList);
        setSelectedFilters(objValues);
        setSelectedName(getDefaultName(objValues));
      } else if (objKeys === "Preferred" && displayName === "Preferred") {
        setSelectedNameList(preferredNameList);
        setSelectedFilters(objValues);
        setSelectedName(getDefaultName(objValues));
      } else if (displayName === "Preferred") {
        setSelectedNameList(preferredNameList);
        setSelectedFilters(["preferredFirstName", "preferredLastName"]);
        setSelectedName({
          preferredFirstName: obituary?.preferredFirstName,
          preferredLastName: obituary?.preferredLastName,
        });
      } else if (displayName === "Legal") {
        setSelectedNameList(legalNameList);
        setSelectedFilters(["firstName", "lastName"]);
        setSelectedName({
          firstName: obituary?.firstName,
          lastName: obituary?.lastName,
        });
      }
    }
  }, [displayName, displayObject]);

  const {
    data: displayNameResponse,
    loading: displayNameLoading,
    execute: executeDisplayName,
  } = useAsync({
    autoExecute: false,
    initialData: useMemo(() => null, []),
    task: useCallback((opi, displayName, selectedFilters) => {
      let payload = {
        [displayName]: selectedFilters,
      };
      return getDisplayName(opi, payload);
    }, []),
    dataLoader: useCallback((res) => {
      addNotification({
        type: "success",
        title: "Success",
        message: "Decedent Name Updated Successfully",
      });
      return _.get(res, "data", []);
    }, []),
    onError: useCallback((error) => {
      if (document.getElementsByClassName("notification-danger").length == 0) {
        apiErrorHandler(error);
      }
    }, []),
  });

  useEffect(() => {
    if (displayNameResponse) {
      executeObituary();
    }
  }, [displayNameResponse]);

  const showLoading = useMemo(() => displayNameLoading, [displayNameLoading]);
  useSetLoading(showLoading);

  const getDefaultName = (values) => {
    let result = {};

    if (_.includes(values, "prefix")) {
      result = { ...result, prefix: obituary?.prefix };
    }
    if (_.includes(values, "firstName")) {
      result = { ...result, firstName: obituary?.firstName };
    }
    if (_.includes(values, "middleName")) {
      result = { ...result, middleName: obituary?.middleName };
    }
    if (_.includes(values, "lastName")) {
      result = { ...result, lastName: obituary?.lastName };
    }
    if (_.includes(values, "preferredFirstName")) {
      result = {
        ...result,
        preferredFirstName: obituary?.preferredFirstName,
      };
    }
    if (_.includes(values, "preferredMiddleName")) {
      result = {
        ...result,
        preferredMiddleName: obituary?.preferredMiddleName,
      };
    }
    if (_.includes(values, "preferredLastName")) {
      result = { ...result, preferredLastName: obituary?.preferredLastName };
    }
    if (_.includes(values, "suffix")) {
      result = { ...result, suffix: obituary?.suffix };
    }
    return result;
  };

  const displayNameHandler = (selectedName) => {
    const nameObj = [
      selectedName?.firstName,
      selectedName?.preferredFirstName,
      selectedName?.middleName,
      selectedName?.preferredMiddleName,
      selectedName?.lastName,
      selectedName?.preferredLastName,
      selectedName?.suffix,
    ];
    return nameObj.filter((ele) => ele).join(" ");
  };

  const handleFilterChange = (id, label, value) => {
    if (selectedFilters.includes(id)) {
      const updatedFilters = _.filter(
        selectedFilters,
        (filter) => filter !== id
      );
      delete selectedName[id];
      setSelectedName(selectedName);
      setSelectedFilters(updatedFilters);
    } else {
      const updatedFilters = selectedFilters.concat(id);
      setSelectedFilters(updatedFilters);
      setSelectedName({ ...selectedName, [id]: value });
    }
  };

  return (
    <div className="display-name-container inputcard-wrapper">
      <div className="display-name-heading">
        <div className="display-name">
          <div className="customIndex">1</div>
          <div>Display Name</div>
        </div>
        <TooltipAndInfo
          id="display-name-tooltip-icon"
          showTooltipFn={showDisplayNameToolTip}
          showToolTip={displayNameToolTip}
          size={25}
          tooltipText="Choose either the Legal name or Preferred name to be displayed on the Family Website"
          color="#000000"
        />
      </div>
      <div className="chooseName">Choose between Legal & Preferred name</div>
      <div className="display-dropdown-container inputcard-body custom-inputcard-body">
        <Formik
          initialValues={{ displayName: dropdownValue || "" }}
          enableReinitialize={true}
          onSubmit={async (values, formikBag) => {
            executeDisplayName(opi, displayName, selectedFilters);
            setIsDisabled(true);
          }}
        >
          {({ values, handleSubmit, isSubmitting, setFieldValue }) => {
            return (
              <Form onSubmit={handleSubmit} className="inputcard-group p-0">
                <div className="choose-reason w-100 p-0">
                  <div className="inputcard-withlabel">
                    <SelectComponent
                      placeholder="Select display name"
                      options={options}
                      selectedOption={values.displayName || dropdownValue}
                      name="displayName"
                      handleChange={(e) => {
                        setFieldValue("displayName", e);
                        setDisplayName(e.value);
                        setIsDisabled(false);
                      }}
                    />
                  </div>
                  {displayName && (
                    <>
                      <DisplayNameFilterOptions
                        filters={selectedNameList}
                        handleFilterChange={handleFilterChange}
                        selectedFilters={selectedFilters}
                        setIsDisabled={setIsDisabled}
                      />
                      {displayName && (
                        <>
                          <div className="selected-name">
                            <p className="previewText">
                              {
                                "Preview\u00a0\u00a0\u00a0\u00a0:\u00a0\u00a0\u00a0\u00a0"
                              }
                            </p>
                            <p>
                              {(_.get(selectedName, "prefix")
                                ? _.get(selectedName, "prefix") + ". "
                                : "") + displayNameHandler(selectedName)}
                            </p>
                          </div>
                          <hr></hr>
                        </>
                      )}
                      <div className="d-flex justify-content-end align-items-end">
                        <button
                          type="submit"
                          className="save-button"
                          disabled={isDisabled}
                        >
                          Save
                        </button>
                      </div>
                    </>
                  )}
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default React.memo(SelectingDisplayName);
