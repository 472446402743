import React, { useEffect, useState } from "react";
import { useLocation, useParams, withRouter } from "react-router-dom";
import { useSetLoading } from "../../utils/hooks/loader";
import VideoThumbnail from "../familyWebsite/memoriesListing/memories-files/videoThumbnail";

const ImageFullView = () => {
    const [fileLoader, setFileLoader] = useState(true);
    const location = useLocation();
    console.log(location);
    const { fileId } = useParams();
    const fileDetails = JSON.parse(localStorage.getItem(fileId));
    let file = fileDetails[0];
    let key = fileDetails[1];
    let memoryId = fileDetails[2];

    useEffect(() => {
        if (file.src) {
            setFileLoader(false);
        } else {
            setFileLoader(true);
        }
    }, [fileLoader, file]);

    useSetLoading(fileLoader);

    const renderVideo = (video, key, memoryId) => {
        return <VideoThumbnail video={video} flag={key} memoryId={memoryId}/>;
    };

    return (
        <div className='d-flex justify-content-center align-items-center'>
            {file && file.fileType === "Image" && (
                <img src={file.src} alt={"fileUrl"} />
            )}
            {file &&
                file.fileType === "Video" &&
                <div className='mobile-video-div'>
                    {renderVideo(file, key, memoryId)}
                </div>
            } 
            {file.fileType === "Audio" && (
                <audio controls className='audio-file'>
                    <source src={file.src} />
                </audio>
            )}
        </div>
    );
};

export default withRouter(ImageFullView);
