import React, { useState, useEffect, useCallback, useMemo } from "react";
import useAsync from "react-use-async-hook";
import _ from "lodash";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { getFileName, handleFileDownLoad } from "../../../../utils/functions";
import addNotification from "../../../../utils/notification/notification";
import { deleteImgAPI } from "../../../../../api/memories";
import { useSetLoading } from "../../../../utils/hooks/loader";
import AlertMessageForMemory from "../../../../utils/AlertMessage/AlertMessageForMemory";
import PreviewDocumentModal from "./previewDocumentModal";
import PdfPreview from "./displayPdfFiles";

const ListDocuments = ({
  otherMedia,
  session,
  opi,
  refreshList,
  isFamilyAccess,
  memoryId,
  callFrom,
  isAdminView = false,
}) => {
  const [dropdownOpen, setDropDownOpen] = useState(-1);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [currentFile, setCurrentFile] = useState();
  const [fileExtesnion, setFileExtension] = useState();
  const [isDeleteFile, setIsDeleteFile] = useState(false);

  /*Delete Media API */
  const {
    data: deleteMediaInfo,
    execute: executeDeleteMedia,
    loading: deleteMediaLoader,
  } = useAsync({
    autoExecute: false,
    task: deleteImgAPI,
    dataLoader: useCallback((res) => {
      addNotification({
        type: "success",
        title: "Success",
        message: "Media Deleted Successfully",
      });
      return res;
    }, []),
    onError: useCallback((error) => {
      addNotification({
        type: "danger",
        title: "Error",
        message: _.get(
          error,
          "response.data.message",
          "Oops! Something went wrong"
        ),
      });
    }, []),
  });

  useEffect(() => {
    if (_.get(deleteMediaInfo, "data.success") === true) {
      refreshList(opi);
    }
  }, [deleteMediaInfo]);

  const closeModal = () => {
    setIsOpenModal(false);
  };

  const viewDocument = (src, index) => {
    setCurrentFile(src);
    const extension = src?.split(".")?.pop();
    setFileExtension(extension);
    setIsOpenModal(true);
  };

  const toggle = (e) => {
    if (e?.target?.id) {
      setDropDownOpen(e.target.id);
    } else {
      setDropDownOpen(-1);
    }
  };

  const handleDelete = async (file) => {
    await executeDeleteMedia(
      callFrom === "gallery" || callFrom === "mobileObituary"
        ? file?.memoryId
        : memoryId,
      file?.id,
      opi
    );
    setIsDeleteFile(false);
  };

  const showLoader = useMemo(() => deleteMediaLoader, [deleteMediaLoader]);
  useSetLoading(showLoader);

  return (
    <div
      className={`pdfViewer ${
        callFrom === "obituary" || callFrom === "mobileObituary"
          ? "obituaryDocuments"
          : "galleryDocuments"
      }`}
    >
      {otherMedia?.map((file, index) => {
        const { finalName, extension } = getFileName(_.get(file, "src"));
        return (
          <div className="fileContainer justify-content-between" key={index}>
            <div className="d-flex justify-content-between fileNameContainer">
              <p
                onClick={() => {
                  if (file?.src?.split(".")?.pop() !== "pdf")
                    viewDocument(file?.src, index);
                }}
                className={`fileName ${file?.src?.split(".")?.pop() === 'docx' ? 'docxFile' : ''}`}
              >{`${decodeURIComponent(`${finalName}.${extension}`)} `}</p>
              {!isAdminView && (
                <Dropdown
                  isOpen={index == dropdownOpen}
                  direction="left"
                  toggle={toggle}
                >
                  <DropdownToggle id={index} className="toggleButton">
                    <i id={index} className="fas fa-ellipsis-v" />
                  </DropdownToggle>
                  <DropdownMenu>
                    {file?.src?.split(".").pop() !== "pdf" && (
                      <DropdownItem
                        onClick={() => {
                          viewDocument(file?.src, index);
                        }}
                      >
                        View
                      </DropdownItem>
                    )}
                    {
                      <DropdownItem
                        onClick={() => {
                          handleFileDownLoad(file?.src);
                        }}
                      >
                        Download
                      </DropdownItem>
                    }
                    {session?.isLoggedIn && isFamilyAccess && (
                      <>
                        <DropdownItem
                          onClick={() => {
                            setCurrentFile(file);
                            setIsDeleteFile(true);
                          }}
                        >
                          Delete
                        </DropdownItem>
                      </>
                    )}
                  </DropdownMenu>
                </Dropdown>
              )}
            </div>
            {extension === "pdf" && (
              <PdfPreview
                fileExtesnion={fileExtesnion}
                src={_.get(file, "src")}
              />
            )}
          </div>
        );
      })}
      {isDeleteFile && (
        <AlertMessageForMemory
          toggleAlertModal={() => {
            setIsDeleteFile(false);
          }}
          toggleOk={() => {
            handleDelete(currentFile);
          }}
          toggleAlertState
          title="Are you sure you want to delete this Document"
          successMsg="Once deleted it cannot be revoked"
          showCancelButton={true}
          okButtonText="Delete"
          deleteState={true}
        />
      )}
      <PreviewDocumentModal
        isOpenModal={isOpenModal}
        closeModal={closeModal}
        currentFile={currentFile}
        fileExtesnion={fileExtesnion}
      />
    </div>
  );
};

export default ListDocuments;
