import React, { useCallback, useMemo, useEffect, useState } from 'react';
import _ from 'lodash';
import useAsync from 'react-use-async-hook';
import { useParams, useLocation } from 'react-router-dom';
import { Button } from 'reactstrap';
import Pagination from 'react-js-pagination';

import { useSetLoading } from '../../utils/hooks/loader';
import addNotification from '../../utils/notification/notification';
import * as Dashboard from '../../../api/dashboard';
import AppLayout from '../../appLayout';
import BreadCrumb from '../../utils/breadCrumb/breadCrumb';

import checkMark from '../../../assets/images/download-transparent-check-mark-gif-11562855141yrviuuu1dd-removebg-preview.png';
import ServiceCard from '../../common/serviceCard/serviceCard';
import {
  createNewProgramCard,
  getNewProgramCard,
  getNewProgramCardTemplates,
  uploadNewProgramCard,
  submitNewProgramCard,
} from '../../../api/newProgram';
import NewProgramCard from './newProgram/newProgramCard';
import AlertMessage from '../../utils/AlertMessage/AlertMessage';

export default function ProgramCreation({ role }) {
  const { onePortalId } = useParams();
  const [isArranger, setIsArranger] = useState(true);
  const [loader, setLoader] = useState(false);
  const [templatesCurrentPage, setTemplatesCurrentPage] = useState(1);
  const [decedentProgramCardUrl, setDecedentProgramCardUrl] = useState(null);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [showTemplateChangeAlert, setShowTemplateChangeAlert] = useState(false);

  const { pathname, search } = useLocation();
  const searchParameter = search ? search.slice(search.indexOf("?")) : pathname && pathname.includes("?") ? pathname.slice(pathname.indexOf("?")) : ''

  useEffect(() => {
    if (role === 'admin') setIsArranger(false);
    else if (role === 'arranger') setIsArranger(true);
  }, [role]);

  /**
   * Get Decedent Detail
   */
  // Fetching with the help of OPI as of now.
  const {
    data: decedent,
    loading: getDecedentLoader,
    execute: executeGetDecedent,
  } = useAsync({
    autoExecute: false,
    task: Dashboard.getDecedentDetails,
    dataLoader: useCallback((res) => {
      return _.get(res, 'data');
    }, []),
  });

  /**
   * Get Template List
   */
  const {
    data: templates = [],
    loading: templateListLoader,
    execute: executeGetTemplateList,
  } = useAsync({
    autoExecute: false,
    task: getNewProgramCardTemplates,
    dataLoader: useCallback((res) => {
      return _.get(res, 'data.data');
    }, []),
    initialData: useMemo(() => [], []),
  });

  /**
   * Get Selected Program Card
   */
  const { loading: getProgramCardLoader, execute: executeGetProgramCard } =
    useAsync({
      autoExecute: false,
      task: useCallback((onePortalId) => {
        return getNewProgramCard(onePortalId);
      }, []),
      dataLoader: useCallback((res) => {
        setDecedentProgramCardUrl(_.get(res, 'data.data.fileUrl'), null);
        setSelectedTemplate(_.get(res, 'data.data'));
        return _.get(res, 'data.data');
      }, []),
      onError: useCallback((error) => {
        addNotification({
          type: 'danger',
          title: 'Error',
          message: _.get(
            error,
            'response.data.message',
            'Oops! Something went wrong'
          ),
        });
      }, []),
    });

  const { execute: executeProgramCardUpload } = useAsync({
    autoExecute: false,
    task: uploadNewProgramCard,
    dataLoader: useCallback((res) => {
      setDecedentProgramCardUrl(_.get(res, 'data.fileUrl'));
      addNotification({
        type: "success",
        title: "Success",
        message: "ProgramCard Uploaded Successfully",
      });
      return _.get(res, 'data');
    }, []),
    initialData: useMemo(() => [], []),
  });

  useEffect(() => {
    executeGetTemplateList(templatesCurrentPage);
  }, [templatesCurrentPage, executeGetTemplateList]);

  /**
   * Save Template
   */
  const { loading: setTemplateLoader, execute: executeCreateNewProgramCard } =
    useAsync({
      autoExecute: false,
      task: useCallback((onePortalId, data) => {
        return createNewProgramCard(onePortalId, data);
      }, []),
      dataLoader: useCallback((res) => {
        setDecedentProgramCardUrl(_.get(res, 'data.fileUrl', null));
        return _.get(res, 'data', null);
      }, []),
      onError: useCallback((error) => {
        addNotification({
          type: 'danger',
          title: 'Error',
          message: _.get(
            error,
            'response.data.message',
            'Oops! Something went wrong'
          ),
        });
      }, []),
    });

  /**
   * Submit Program Card to OnePortal(Family Arranger)
   */
  const { loading: submitTemplateLoader, execute: executeSubmitProgramCard } =
    useAsync({
      autoExecute: false,
      task: useCallback((onePortalId) => {
        return submitNewProgramCard(onePortalId);
      }, []),
      dataLoader: useCallback((res) => {
        addNotification({
          type: "success",
          title: "Success",
          message: "ProgramCard Submited Successfully",
        });
        return _.get(res, 'data', null);
      }, []),
      onError: useCallback((error) => {
        addNotification({
          type: 'danger',
          title: 'Error',
          message: _.get(
            error,
            'response.data.message',
            'Oops! Something went wrong'
          ),
        });
      }, []),
    });
  const showLoader = useMemo(
    () =>
      templateListLoader ||
      getDecedentLoader ||
      loader ||
      setTemplateLoader ||
      getProgramCardLoader ||
      submitTemplateLoader,
    [
      templateListLoader,
      getDecedentLoader,
      loader,
      setTemplateLoader,
      getProgramCardLoader,
      submitTemplateLoader,
    ]
  );

  useSetLoading(showLoader);

  useEffect(() => {
    if (onePortalId) {
      executeGetDecedent(onePortalId);
      executeGetProgramCard(onePortalId);
    }
  }, [onePortalId, executeGetDecedent, executeGetProgramCard]);

  const handleTemplateSelect = (item) => {
    setSelectedTemplate(item);
    executeCreateNewProgramCard(onePortalId, { templateId: item.id });
  };

  const handleProgramCardUpload = (formData) => {
    executeProgramCardUpload(onePortalId, formData, ({ loaded, total }) => {
      setUploadProgress(loaded);
    });
  };

  return (
    <>
      <AppLayout
        decedent={_.get(decedent, 'decedent') || null}
        haveAcceptPolicy={
          isArranger ? _.get(decedent, 'haveAcceptPolicy', false) : true
        }
        getDecedent={executeGetDecedent}
        setLoader={setLoader}
      >
        <div className="container container-program-card">
          <AlertMessage
            toggleAlertModal={() => {
              setShowTemplateChangeAlert(false);
            }}
            toggleOk={() => {
              setDecedentProgramCardUrl(null);
              setShowTemplateChangeAlert(false);
            }}
            toggleAlertState={showTemplateChangeAlert}
            deleteState
            successMsg={
              'Are you sure you want to continue? Selecting a template will replace the current program card.'
            }
            showCancelButton
            title={'Confirm Change'}
          />
          <BreadCrumb
            links={[
              {
                url: `/${isArranger ? 'app' : 'admin/decedents'}`,
                description: 'Home',
                heading: 'Dashboard',
              },
              {
                url: `/${
                  isArranger ? 'app' : 'admin'
                }/${onePortalId}/arrangements`,
                description: 'Arrangements Summary',
                heading: 'Arrangements Summary',
                search: searchParameter,
              },
              {
                description: 'Service Program Creation',
                heading: 'Service Program Creation',
                isSpan: true,
              },
            ]}
            showBack={true}
          />
          <div className="prayer-card-body-wrapper program-card-body-wrapper">
            <div className="prayer-card-creation-steps">
              <li>
                <span style={{ fontWeight: '600' }}>STEP 1. </span> Choose a
                program card from one of our available selections
                {decedentProgramCardUrl && (
                  <Button
                    className="action-btn-link"
                    onClick={() => {
                      setShowTemplateChangeAlert(true);
                    }}
                  >
                    Change Program Card
                  </Button>
                )}{' '}
                {decedentProgramCardUrl && (
                  <img src={checkMark} alt="Check" style={{ height: 20 }} />
                )}
              </li>
              {decedentProgramCardUrl && (
                <li>
                  <span style={{ fontWeight: '600' }}>STEP 2. </span> Please
                  download the Program Card, edit it in Microsoft Word and
                  upload the updated card
                  {_.get(decedent, 'programStatus') && (
                    <img src={checkMark} alt="Check" style={{ height: 20 }} />
                  )}
                </li>
              )}
            </div>
            {decedentProgramCardUrl ? (
              <NewProgramCard
                item={selectedTemplate}
                fileUrl={decedentProgramCardUrl}
                onUpload={handleProgramCardUpload}
                uploadProgress={uploadProgress}
                executeSubmitProgramCard={() => {
                  executeSubmitProgramCard(onePortalId);
                }}
              />
            ) : (
              <div className="program-templates-wrapper">
                {templates?.results?.map((template) => {
                  return (
                    <ServiceCard
                      key={template.id}
                      item={template}
                      onSelect={handleTemplateSelect}
                    />
                  );
                })}

                <div className="pagination-wrapper">
                  <Pagination
                    className="pagination"
                    activePage={templatesCurrentPage}
                    activeLinkClass={'active'}
                    itemsCountPerPage={5}
                    totalItemsCount={templates?.total || 1}
                    pageRangeDisplayed={2}
                    onChange={(pageNumber) => {
                      setTemplatesCurrentPage(pageNumber);
                    }}
                    linkClass="page-link"
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </AppLayout>
    </>
  );
}
