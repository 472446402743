import React from "react";
import { Input, Label, Row, Col } from "reactstrap";
import addNotification from "../../../utils/notification/notification";

const DisplayNameFilterOptions = ({
  filters,
  handleFilterChange,
  selectedFilters,
  setIsDisabled,
}) => {
  return (
    <Row className="display-filter-options">
      {filters?.map((filter) => {
        return (
          <Col
            key={filter.id}
            className="checkbox-inline d-flex justify-content-start"
            style={{
              pointerEvents:
                (filter.label === "First Name" ||
                  filter.label === "Last Name") &&
                "none",
              cursor: "pointer",
            }}
          >
            <Label className="checkbox-heading">
              <Row className="filterLabel">
                <Col>{filter.label}</Col>
              </Row>
              <Row>
                <Col>
                  <div>
                  <Input
                    type="checkbox"
                    name="filterValues"
                    onChange={() => {
                      if (filter.value || selectedFilters.includes(filter.id)) {
                        handleFilterChange(
                          filter.id,
                          filter.label,
                          filter.value
                        );
                        setIsDisabled(false);
                      } else {
                        addNotification({
                          type: "danger",
                          title: "Error",
                          message: `${filter.label} is empty, Please Update in Biography and Death Certificate Information Section`,
                        });
                      }
                    }}
                    checked={selectedFilters.includes(filter.id)}
                    style={{
                      cursor: "pointer",
                      marginTop:'0px',
                    }}
                    className={
                      filter.label === "First Name" ||
                      filter.label === "Last Name"
                        ? "color-checked-change checkBox"
                        : "color-change checkBox"
                    }
                  />
                  <span className="filterValue">{filter.value}</span>
                  </div>
                </Col>
          
              </Row>
            </Label>
          </Col>
        );
      })}
    </Row>
  );
};

export default DisplayNameFilterOptions;
