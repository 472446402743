import React from 'react';

const Loader = ({ showLoader, LoaderClassname }) => {
    return (
        showLoader ?
        <div
            className={
                'loader-section' +
                (LoaderClassname
                    ? ' ' + LoaderClassname
                    : '')
            }
        >
            <div className="loader">
                <div />
                <div />
                <div />
            </div>
        </div>
        : "" 
    );
}

export default Loader;
