export const getPhoneNumberCountryCode = (PhoneNumber) => {
    let phoneNumber;
    let countryCode = '';
    if (PhoneNumber.trim().length > 10) {
        const arrayOfNumbers = PhoneNumber.split('-');
        phoneNumber = arrayOfNumbers[1];
        countryCode = arrayOfNumbers[0] + '-';
    }
    else if (PhoneNumber.trim().length < 10) {
        countryCode = PhoneNumber;
    }
    else {
        phoneNumber = PhoneNumber;
    }
    return { phoneNumber, countryCode };
}

export const getSecondaryPhoneCountryCode = (PhoneNumber) => {
    let secondaryPhoneNumber;
    let secondaryCountryCode = '';
    if (PhoneNumber.trim().length > 10) {
        const arrayOfNumbers = PhoneNumber.split('-');
        secondaryPhoneNumber = arrayOfNumbers[1];
        secondaryCountryCode = arrayOfNumbers[0] + '-';
    }
    else if (PhoneNumber.trim().length < 10) {
        secondaryCountryCode = PhoneNumber;
    }
    else {
        secondaryPhoneNumber = PhoneNumber;
    }
    return { secondaryPhoneNumber, secondaryCountryCode };
}
