import React, { useCallback, useEffect, useMemo, useState } from "react";
import _ from "lodash";
import { useLocation, useParams } from "react-router-dom";
import useAsync from "react-use-async-hook";
import { Tooltip } from "reactstrap";

import AppLayout from "../../appLayout";
import BreadCrumb from "../../utils/breadCrumb/breadCrumb";
import * as DecedentAPI from "../../../api/decedent";
import * as Dashboard from "../../../api/dashboard";
import { useSetLoading } from "../../utils/hooks/loader";
import addNotification from "../../utils/notification/notification";
import PrimaryDetails from "./primaryDetails";
import ResidentialDetails from "./residentialDetails";
import EthnicityDetails from "./ethnicityDetails";
import EducationOccupationDetails from "./educationOccupationDetails";
import VeteranDetails from "./veteranDetails";
import ParentsDetails from "./parentsDetails";
import NotifierDetails from "./notifierDetails";
import NOKDetails from "./nokDetails";
import CertificateDetailsPopup from "../../utils/Template/CertificateDetailsPopup";

import { useSetData } from "../../utils/hooks/data";
import store from "../../../store/store";
import useGAEventTracker from "../../utils/hooks/GAEventsTracker";
import { certificateFreezeText } from "./constants/constants";
import Cookies from "universal-cookie";

const CertificateDetails = ({ role }) => {
    const { onePortalId } = useParams();
    const states = store.getState();
    const { data } = states.loader;
    const [loader, setLoader] = useState(false);
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const [certificateSavePopup, setCertificateSavePopup] = useState(false);
    const [isArranger, setIsArranger] = useState(true);

    const [isSubmitButtonEnabled, toggleSubmitButtonEnabled] = useState(false);
    const enableSubmitButton = () => toggleSubmitButtonEnabled(true);

    const hideCertificateSavePopup = () => {
        setCertificateSavePopup(false);
    };

    const showCertificateSavePopup = () => {
        setCertificateSavePopup(true);
    };
    useSetData();

    useEffect(() => {
        if (role === "admin") setIsArranger(false);
        else if (role === "arranger") setIsArranger(true);
    }, [role]);

    const cookies = new Cookies();
    // const Oprole =
    //     sessionStorage.getItem("role") || cookies.get("role") || localStorage.getItem("role");

    const { pathname, search } = useLocation();
    const searchParameter = search ? search.slice(search.indexOf("?")) : pathname && pathname.includes("?") ? pathname.slice(pathname.indexOf("?")) : ''


    /**
     * Get Decedent Detail
     */
    const {
        data: decedent,
        loading: getDecedentLoader,
        execute: executeGetDecedent,
    } = useAsync({
        autoExecute: false,
        task: DecedentAPI.getDecedent,
        dataLoader: useCallback(res => {
            return _.get(res, "data.decedent");
        }, []),
    });

    useEffect(() => {
        if (onePortalId) {
            executeGetDecedent(onePortalId);
            executeGetStatus(onePortalId);
        }
    }, [onePortalId]);

    /**
     * Get Decedent Detail
     */
    const {
        data: decedentData,
        loading: getStatusLoader,
        execute: executeGetStatus,
    } = useAsync({
        autoExecute: false,
        task: Dashboard.getArrangementSummary,
        dataLoader: useCallback(res => {
            return _.get(res, "data");
        }, []),
    });

    /**
     * Submit Decedent Detail to One-Portal
     */
    const { loading: submitLoader, execute: executeSubmit } = useAsync({
        autoExecute: false,
        task: DecedentAPI.submitToOPI,
        dataLoader: useCallback(res => {
            toggleSubmitButtonEnabled(false);
            addNotification({
                type: "success",
                title: "Success",
                message: "Details Submitted to your Arranger Successfully!",
            });
        }, []),
        onError: useCallback(error => {
            addNotification({
                type: "danger",
                title: "Error",
                message: _.get(
                    error,
                    "response.data.message",
                    "Oops! Something went wrong"
                ),
            });
        }, []),
    });

    const showToolTip = disabled => {
        if (_.get(decedent, "isFreeze")) {
            setTooltipOpen(disabled);
        }
    };

    const showLoader = useMemo(
        () => getDecedentLoader || submitLoader || getStatusLoader || loader,
        [getDecedentLoader, submitLoader, getStatusLoader, loader]
    );
    useSetLoading(showLoader);

    const GAEventsTracker = useGAEventTracker("Certificates Event");
    const decedentId = decedentData && decedentData.decedent.id

    return (
        <AppLayout
            decedent={_.get(decedentData, "decedent") || null}
            haveAcceptPolicy={
                isArranger ? _.get(decedentData, "haveAcceptPolicy", false) : true
            }
            getDecedent={executeGetDecedent}
            setLoader={setLoader}
        >
            <div className='container'>
                {isArranger ? (
                    <BreadCrumb
                        links={[
                            {
                                url: `/app`,
                                description: "Home",
                                heading: "Dashboard",
                            },
                            {
                                url: `/app/${onePortalId}/arrangements`,
                                description: "Arrangements Summary",
                                heading: "Arrangements Summary",
                            },
                            {
                                url: "/app/certificateDetails",
                                description:
                                    "Biography and Death Certificate Information",
                                heading:
                                    "Biography and Death Certificate Information",
                                isSpan: true,
                            },
                        ]}
                        showBack={true}
                    />
                ) : (
                    <BreadCrumb
                        links={[
                            {
                                url: `/admin/decedents`,
                                description: "Home",
                                heading: "Dashboard",
                            },
                            {
                                url: `/admin/${onePortalId}/arrangements`,
                                description: "Arrangements Summary",
                                heading: "Arrangements Summary",
                                search: searchParameter
                            },
                            {
                                url: `/admin/${onePortalId}/certificateDetails`,
                                description:
                                    "Biography and Death Certificate Information",
                                heading:
                                    "Biography and Death Certificate Information",
                                isSpan: true,
                            },
                        ]}
                        showBack={true}
                    />
                )}

                <div
                    className='certificates-wrapper'
                    style={{ margin: "60px 0 30px 0" }}
                >
                    <div className='certificates-form-wrapper'>
                        <div className='w-100 certificates-submit-wrapper d-flex align-items-center'>
                            <div>
                                {_.get(decedent, "isFreeze") ? (
                                    <h5 className='error-input-msg'>
                                        This section is now locked. Please
                                        contact your arranger for changes.
                                    </h5>
                                ) : (
                                    <h5
                                        className='error-input-msg'
                                        style={{ color: "black" }}
                                    >
                                        Be sure to SUBMIT{" "}
                                        <span style={{ color: "grey" }}>
                                            {" "}
                                            [SUBMIT TO ARRANGER]
                                        </span>{" "}
                                        the information after SAVING{" "}
                                        <span style={{ color: "grey" }}>
                                            [SAVE]
                                        </span>{" "}
                                        your entry.
                                    </h5>
                                )}
                            </div>
                            <div
                                id='submit-button'
                                onPointerEnter={() => showToolTip(true)}
                                onPointerLeave={() => showToolTip(false)}
                            >
                                <Tooltip
                                    placement='top-end'
                                    isOpen={tooltipOpen}
                                    target='submit-button'
                                    className='tooltip-template'
                                >
                                    {certificateFreezeText}
                                </Tooltip>
                                <button
                                    onClick={() => {
                                        executeSubmit(decedentId);
                                        GAEventsTracker("submit");
                                    }}
                                    disabled={
                                        _.get(decedent, "isFreeze") ||
                                        !isSubmitButtonEnabled
                                    }
                                    className='btn-theme text-uppercase ml-auto mr'
                                >
                                    SUBMIT TO ARRANGER
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='navigationCenter-body-wrapper'>
                    <div className='certificates-wrapper'>
                        <div className='certificates-form-wrapper'>
                            <PrimaryDetails
                                decedent={_.get(decedent, "primaryInfo")}
                                maritalStatus={_.get(data, "maritalStatus")}
                                states={_.get(data, "states")}
                                countries={_.get(data, "countries")}
                                decedentId={decedentId}
                                refreshDecedent={() =>
                                    executeGetDecedent(onePortalId)
                                }
                                isDisable={_.get(decedent, "isFreeze")}
                                showCertificateSavePopup={
                                    showCertificateSavePopup
                                }
                                enableSubmitButton={enableSubmitButton}
                            />
                            <ResidentialDetails
                                decedent={_.get(decedent, "residentInfo")}
                                locationData={data}
                                decedentId={decedentId}
                                isDisable={_.get(decedent, "isFreeze")}
                                refreshDecedent={() =>
                                    executeGetDecedent(onePortalId)
                                }
                                showCertificateSavePopup={
                                    showCertificateSavePopup
                                }
                                enableSubmitButton={enableSubmitButton}
                            />
                            <EthnicityDetails
                                ethnicityHeader={_.get(
                                    data,
                                    "ethnicityHeader",
                                    []
                                )}
                                ethnicity={_.get(data, "ethnicity")}
                                race={_.get(data, "race")}
                                decedent={_.get(decedent, "ethnicityInfo")}
                                decedentId={decedentId}
                                isDisable={_.get(decedent, "isFreeze")}
                                refreshDecedent={() =>
                                    executeGetDecedent(onePortalId)
                                }
                                showCertificateSavePopup={
                                    showCertificateSavePopup
                                }
                                enableSubmitButton={enableSubmitButton}
                            />
                            <EducationOccupationDetails
                                qualifications={_.get(data, "qualification")}
                                decedent={_.get(decedent, "educationInfo")}
                                isDisable={_.get(decedent, "isFreeze")}
                                refreshDecedent={() =>
                                    executeGetDecedent(onePortalId)
                                }
                                decedentId={decedentId}
                                showCertificateSavePopup={
                                    showCertificateSavePopup
                                }
                                enableSubmitButton={enableSubmitButton}
                            />
                            <VeteranDetails
                                serviceBranchData={_.get(data, "serviceBranch")}
                                decedent={_.get(decedent, "veteranInfo")}
                                isDisable={_.get(decedent, "isFreeze")}
                                refreshDecedent={() =>
                                    executeGetDecedent(onePortalId)
                                }
                                decedentId={decedentId}
                                showCertificateSavePopup={
                                    showCertificateSavePopup
                                }
                                enableSubmitButton={enableSubmitButton}
                            />
                            <ParentsDetails
                                states={_.get(data, "states")}
                                countries={_.get(data, "countries")}
                                decedent={_.get(decedent, "parentsInfo")}
                                isDisable={_.get(decedent, "isFreeze")}
                                refreshDecedent={() =>
                                    executeGetDecedent(onePortalId)
                                }
                                decedentId={decedentId}
                                showCertificateSavePopup={
                                    showCertificateSavePopup
                                }
                                enableSubmitButton={enableSubmitButton}
                            />
                            <NotifierDetails
                                decedent={_.get(decedent, "notifierInfo")}
                                locationData={{
                                    states: _.get(data, "states"),
                                    countries: _.get(data, "countries"),
                                    counties: _.get(data, "counties"),
                                    cities: _.get(data, "cities"),
                                    zipcodes: _.get(data, "zipcodes"),
                                }}
                                isDisable={_.get(decedent, "isFreeze")}
                                refreshDecedent={() =>
                                    executeGetDecedent(onePortalId)
                                }
                                decedentId={decedentId}
                                showCertificateSavePopup={
                                    showCertificateSavePopup
                                }
                                enableSubmitButton={enableSubmitButton}
                            />
                            <NOKDetails
                                decedent={_.get(decedent, "nokInfo", {})}
                                showCertificateSavePopup={
                                    showCertificateSavePopup
                                }
                                locationData={{
                                    states: _.get(data, "states"),
                                    countries: _.get(data, "countries"),
                                    counties: _.get(data, "counties"),
                                    cities: _.get(data, "cities"),
                                    zipcodes: _.get(data, "zipcodes"),
                                }}
                                isDisable={_.get(decedent, "isFreeze")}
                                refreshDecedent={() =>
                                    executeGetDecedent(onePortalId)
                                }
                                decedentId={decedentId}
                                enableSubmitButton={enableSubmitButton}
                            />
                            <div className='w-100 certificates-submit-wrapper d-flex align-items-center'>
                                <div>
                                    {_.get(decedent, "isFreeze") ? (
                                        <h5 className='error-input-msg'>
                                            This section is now locked. Please
                                            contact your arranger for changes.
                                        </h5>
                                    ) : (
                                        <h5
                                            className='error-input-msg'
                                            style={{ color: "black" }}
                                        >
                                            Be sure to SUBMIT{" "}
                                            <span style={{ color: "grey" }}>
                                                [SUBMIT TO ARRANGER]
                                            </span>{" "}
                                            the information after SAVING{" "}
                                            <span style={{ color: "grey" }}>
                                                [SAVE]
                                            </span>{" "}
                                            your entry.
                                        </h5>
                                    )}
                                </div>
                                <div
                                    id='submit-button'
                                    onPointerEnter={() => showToolTip(true)}
                                    onPointerLeave={() => showToolTip(false)}
                                >
                                    <Tooltip
                                        placement='top-end'
                                        isOpen={tooltipOpen}
                                        target='submit-button'
                                        className='tooltip-template'
                                    >
                                        {certificateFreezeText}
                                    </Tooltip>
                                    <button
                                        style={{ marginLeft: 25 }}
                                        onClick={() => {
                                            executeSubmit(decedentId);
                                            GAEventsTracker("submit");
                                        }}
                                        disabled={
                                            _.get(decedent, "isFreeze") ||
                                            !isSubmitButtonEnabled
                                        }
                                        className='btn-theme  text-uppercase ml-auto mr'
                                    >
                                        SUBMIT TO ARRANGER
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <CertificateDetailsPopup
                isOpen={certificateSavePopup}
                onClose={hideCertificateSavePopup}
            />
        </AppLayout>
    );
};

export default CertificateDetails;
