import React, {
    useEffect,
    useCallback,
    useState,
    useRef,
    useMemo,
} from "react";
import _ from "lodash";
import useAsync from "react-use-async-hook";
import * as ObituaryAPI from "../../../../api/obituary";
import { Link } from "react-router-dom";
import "draft-js/dist/Draft.css";
import addNotification from "../../../utils/notification/notification";
import { getValidFileUrl, getValidFileUrlForImg } from "../../../../utils/global";
import { useSetLoading } from "../../../utils/hooks/loader";
import defaultImage from "../../../../assets/images/238_DefaultDeceasedPortrait.jpg";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import AvatarEditor from "react-avatar-editor";
import Closebutton from "../../../../assets/images/cancel-button.png";
const MAX_LENGTH_LIFE_OF_DESCRIPTION = 10000;

const ProfilePictureSection = ({
    data,
    opi,
    isLoggedIn,
    getObituary,
    obituaryIsLocked,
}) => {
    const fileRef = useRef(null);
    const audioRef = useRef(null);
    const inputRef = useRef(null);
    const avatarRef = useRef(null);
    const [focus, setFocus] = useState(false);

    const { loading: updateMedia, execute: executeMedia } = useAsync({
        autoExecute: false,
        task: useCallback(async (formData, type) => {
            return {
                res: await ObituaryAPI.uploadMedia(opi, formData, type),
            };
        }, []),
        dataLoader: useCallback(({ res }) => {
            addNotification({
                type: "success",
                title: "Success",
                message: "Profile photo uploaded successfully",
            });
        }, []),
        onError: useCallback(error => {
            addNotification({
                type: "danger",
                title: "Error",
                message: _.get(
                    error,
                    "response.data.message",
                    "Oops! Something went wrong"
                ),
            });
        }, []),
    });

    const showLoader = useMemo(() => updateMedia, [updateMedia]);
    useSetLoading(showLoader);

    const initial = () => {
        setFocus(true);
    };

    useEffect(() => {
        if (data) initial();
    }, [data]);

    useEffect(() => {
        if (inputRef.current && focus) {
            inputRef.current.focus();
        }
    }, [inputRef.current, focus]);

    const uploadClick = type => {
        type === "image" ? fileRef.current.click() : audioRef.current.click();
    };

    const [isModel, setIsModel] = useState(false);
    const [avatarImage, setAvatarImage] = useState(_.get(data, "pictureUrl"));
    const [zoom, setZoom] = useState(1);
    const [fileName, setFileName] = useState("fileName.jpg");
    const [mimeType, setMimeType] = useState("image/jpeg");

    const modelClose = () => {
        setIsModel(false);
        setZoom(1)
    }

    const avatarSave = async () => {
        if (avatarRef.current) {
            // const canvas = avatarRef.getImage()
            const canvasScaled = avatarRef.current.getImageScaledToCanvas();
            const croppedImg = canvasScaled.toDataURL();
            const blob = await (await fetch(croppedImg)).blob();
            const file = new File([blob], fileName, {
                type: mimeType,
                lastModified: new Date(),
            });
            var formData = new FormData();
            formData.append("file", file);
            await executeMedia(formData, "image");
            getObituary(opi);
            setIsModel(false);
        }
    };

    const handleImagePreview = async e => {
        const file = e.target.files[0];
        setFileName(file.name);
        setMimeType(file.type);
        e.target.value = null;
        let fileURL = await getValidFileUrlForImg(file);
        setAvatarImage(fileURL);
        setIsModel(true);
    };

    let topSection = (
        <div>
            <div className='mask'>
                <div
                    className={`${
                        !isLoggedIn && obituaryIsLocked ? "disable-onhover" : ""
                    } profile-picture-wrapper`}
                >
                    <img
                        src={_.get(data, "pictureUrl") || defaultImage}
                        alt='ProfilePic'
                        className='profile-pic-wrapper'
                    />
                    {isLoggedIn && !obituaryIsLocked && (
                        <div style={{ margin: "20px 0px 0px 0px"}}>
                            <Link
                                onClick={e => { uploadClick("image"); e.preventDefault();}}
                                className='link-customise btn-theme theme-green-outlined'
                            >
                                UPLOAD PROFILE PHOTO
                            </Link>
                        </div>
                    )}
                    <input
                        id='template-image'
                        type='file'
                        ref={fileRef}
                        accept='.png,.jpg,.jpeg'
                        onChange={handleImagePreview}
                        hidden
                    />
                </div>
            </div>
        </div>
    );

    return (
        <div>
            <div className='story-wrapper mt-2'>
                {topSection}
                <Modal
                    isOpen={isModel}
                    onClose={() => setIsModel(false)}
                    className='obituary-modal-wrapper mobile-view-wrapper profile-section-wrapper'
                    style={{ width: "55%" }}
                >
                    <ModalHeader>
                        <img
                            src={Closebutton}
                            className='custom-close-button'
                            onClick={modelClose}
                            alt='close-button'
                        />
                    </ModalHeader>
                    <ModalBody align='center'>
                        <div className='story-wrapper' align='center'>
                            <AvatarEditor
                                ref={avatarRef}
                                image={avatarImage}
                                width={220}
                                height={320}
                                border={1}
                                color={[0, 0, 0, 0.45]} // RGBA
                                scale={zoom}
                                rotate={0}
                            />
                            <div className='slidecontainer d-flex justify-content-between align-items-center'>
                                <span className='symbol-size' onClick={() => setZoom(prev => prev <= 0.1 ? prev : prev - 0.1)}>-</span>
                                <input
                                    type='range'
                                    min={0.1}
                                    max={2}
                                    step={0.1}
                                    value={zoom}
                                    className='slider'
                                    onChange={e => {
                                        setZoom(parseFloat(e.target.value));
                                    }}
                                ></input>
                                <span className='symbol-size' onClick={() => setZoom(prev => prev >= 2 ? prev : prev + 0.1)}>+</span>
                            </div>
                            <div className='avatar-buttons-wrapper'>
                                <span
                                    style={{ display: "block", float: "left" }}
                                >
                                    <button
                                        className='btn-theme'
                                        onClick={modelClose}
                                    >
                                        CANCEL
                                    </button>
                                </span>
                                <span
                                    style={{ display: "block", float: "right" }}
                                >
                                    <button
                                        className='btn-theme'
                                        onClick={avatarSave}
                                    >
                                        SAVE
                                    </button>
                                </span>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>
            </div>
        </div>
    );
};

export default ProfilePictureSection;
