import React, { useState, useEffect } from "react";
import {
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
} from "reactstrap";
import _ from "lodash";
import ImageGrid from "./imageGrid";
import { templateName } from "../../utils/Template/inputConstants";

const PreviewTemplateCarousel = ({ pages, template, pageURL = [] }) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [animating, setAnimating] = useState(false);
    const [items, setItem] = useState([]);

    const next = () => {
        if (animating) return;
        const nextIndex =
            activeIndex === items.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
    };

    const previous = () => {
        if (animating) return;
        const nextIndex =
            activeIndex === 0 ? items.length - 1 : activeIndex - 1;
        setActiveIndex(nextIndex);
    };

    const goToIndex = newIndex => {
        if (animating) return;
        setActiveIndex(newIndex);
    };

    useEffect(() => {
        if (pages) {
            let items = renderItems(pages);
            setItem(items);
        }
    }, [pages]);

    const renderItems = pages => {
        return _.map(pages, (page, idx) => {
            let pageData = null;
            if (idx === 0) {
                pageData = (
                    <div id='print'>
                        {_.get(template, "isCustom") ? (
                            <div className='template-img-container'>
                                <div className='extra' />
                                <div className='image'>
                                    <img src={pageURL[0]} alt='Img' />
                                </div>
                            </div>
                        ) : (
                            <div className='template-img-container'>
                                <div className='extra' />
                                <div className='image'>
                                    <img src={pageURL[0]} alt='Img' />
                                </div>
                            </div>
                        )}
                    </div>
                );
            } else {
                pageData = (
                    <div id='print'>
                        <ImageGrid type='back' url={pageURL[1]} />
                    </div>
                );
            }

            return { src: pageData };
        });
    };

    const slides = items.map((item, i) => {
        return (
            <CarouselItem
                onExiting={() => setAnimating(true)}
                onExited={() => setAnimating(false)}
                key={item.src}
            >
                <div className='template-card cardbox-wrapper card-whatToExpect'>
                    <div className='cardbox-heading'>
                        <h4 className='cardbox-heading-name'>
                            {templateName[i]}
                        </h4>
                    </div>
                    <div id={`jsx-template-${i}`}>{item.src}</div>
                </div>
            </CarouselItem>
        );
    });

    return (
        <Carousel
            activeIndex={activeIndex}
            next={next}
            previous={previous}
            interval={false}
        >
            <CarouselIndicators
                items={items}
                activeIndex={activeIndex}
                onClickHandler={goToIndex}
            />
            {slides}
            <CarouselControl
                direction='prev'
                directionText='Previous'
                onClickHandler={previous}
            />
            <CarouselControl
                direction='next'
                directionText='Next'
                onClickHandler={next}
            />
        </Carousel>
    );
};

export default PreviewTemplateCarousel;
