import React, { useState } from "react";
import oldOne from "../../../assets/images/word 2007-2010-one.png";
import oldTwo from "../../../assets/images/word 2007-2010-two.png";
import newerVersionOne from "../../../assets/images/word-2011-one.png";
import newerVersionTwo from "../../../assets/images/word-2011-two.png";
import { Modal, ModalBody, ModalHeader } from "reactstrap";

const InstructionsModal = () => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const onClose = () => {
    setIsOpenModal(false);
  };
  return (
    <div>
      <button
        className="instructionsTitle"
        onClick={() => {
          console.log("clicks on Instruction Link");
          setIsOpenModal(true);
        }}
      >
        Instructions To Edit Template
      </button>
      <Modal isOpen={isOpenModal} toggle={onClose} className="instructionModal">
        <ModalHeader>
          {" "}
          <i className="modal-close fas fa-times" onClick={() => onClose()} />
        </ModalHeader>
        <ModalBody>
          <div className="modal-content">
            <span className="osHeading">Windows</span>
            <span className="versionHeading">Replace a Picture:</span>
            <span className="versionHeading wordHeading">Microsoft Word 2011 or later</span>
            <ul style={{ listStyle: "none" }} className="mainUl">
              <li>
                {"1.\u00a0\u00a0\u00a0\u00a0"}
                Right click on the the Frame <b>(Insert Photo Here)</b> where
                you want to insert the picture
              </li>
              <li>
                {"2.\u00a0\u00a0\u00a0\u00a0"}Click on{" "}
                <span className="pictureFormat">
                  <i>Change Picture</i>
                </span>
              </li>
              <div className="instructionImageContainer">
                <img
                  src={newerVersionOne}
                  alt="image1"
                  className="instructionImage"
                  style={{ height: "300px" }}
                ></img>
              </div>
              <li>
                {"3.\u00a0\u00a0\u00a0\u00a0"}From the drop down menu, please
                select the option to change image Eg: Click <b>"This Device"</b>{" "}
                to upload the picture from your computer
              </li>

              <li>
                {"4.\u00a0\u00a0\u00a0\u00a0"}Browse & select the picture you
                wanted to Insert
              </li>
              <li>
                {"5.\u00a0\u00a0\u00a0\u00a0"}Click on{" "}
                <b className="pictureFormat">Insert</b> and its done
              </li>
            </ul>
            <span className="replaceHeading">Or</span>
            <ul style={{ listStyle: "none" }} className="mainUl">
              <li>
                {"1.\u00a0\u00a0\u00a0\u00a0"}Click the{" "}
                <span className="pictureFormat">
                  <u>Picture Format</u>
                </span>{" "}
                tab on the top right of the screen
              </li>
              <li>
                {"2.\u00a0\u00a0\u00a0\u00a0"}Click on{" "}
                <span className="change-picture">Change Picture</span> and
                follow the steps as defined above (Points 3 - 5)
              </li>
              <div className="instructionImageContainer">
                <img
                  src={newerVersionTwo}
                  alt="image2"
                  className="instructionImage"
                  style={{ height: "200px" }}
                ></img>
              </div>
            </ul>
            <span className="versionHeading">Microsoft Word 2007 - 2010</span>
            <ul
              style={{ listStyle: "none", paddingLeft: "40px" }}
              className="mainUl"
            >
              <li>
                {"1.\u00a0\u00a0\u00a0\u00a0"} Select the picture you want to
                replace.
              </li>
              <li>
                {"2.\u00a0\u00a0\u00a0\u00a0"}Under Picture tools on the menu
                bar
                <ul
                  style={{
                    listStyleType: "square",
                    padding: "5px 0px 5px 40px",
                  }}
                >
                  <li>{"\u00a0\u00a0\u00a0\u00a0"}Click on the Format Tab</li>
                  <li>{"\u00a0\u00a0\u00a0\u00a0"}Select Change Picture</li>
                </ul>
              </li>
              <li>
                <div className="instructionImageContainer">
                  <img
                    src={oldOne}
                    alt="image3"
                    className="instructionImage"
                    style={{ height: "110px" }}
                  ></img>
                </div>
              </li>
              <li>
                {"3.\u00a0\u00a0\u00a0\u00a0"}Locate a new picture and open or
                double click it
              </li>
            </ul>
            <span className="osHeading">Mac OS</span>
            <span className="replaceHeading wordHeading">
              Replace a Picture:
            </span>
            <ul style={{ listStyle: "none" }} className="mainUl">
              <li>
                {"1.\u00a0\u00a0\u00a0\u00a0"}
                {"Click the Frame where you want to Insert the Picture"}
              </li>
              <li>
                {"2.\u00a0\u00a0\u00a0\u00a0"}Select the{" "}
                <span className="pictureFormat">Picture Format</span>
                {"\u00a0\u00a0\u00a0\u00a0"}tab
              </li>
              <li>{"3.\u00a0\u00a0\u00a0\u00a0"}Click on Change Picture</li>
              <li>
                <div className="instructionImageContainer">
                  <img
                    src={oldTwo}
                    alt="image4"
                    className="instructionImage"
                    style={{ height: "110px" }}
                  ></img>
                </div>
              </li>
              <li>
                {"4.\u00a0\u00a0\u00a0\u00a0"}From the menu that appears. Select
                the option where you want to get the replacement picture from
              </li>
              <li>
                {"5.\u00a0\u00a0\u00a0\u00a0"}Navigate to the picture you would
                like to insert
              </li>
              <li>
                {"6.\u00a0\u00a0\u00a0\u00a0"}Click <b>Insert</b>
              </li>
              <li></li>
            </ul>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default InstructionsModal;
