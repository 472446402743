import React from "react";
import Select from "react-select";
import Instagram from "../../../../assets/images/instagramSelect.svg";
import Facebook from "../../../../assets/images/facebook.svg";
import Linkedin from "../../../../assets/images/LinkedIn.svg";
import Twitter from "../../../../assets/images/twitterNewIcon.webp";
import Youtube from "../../../../assets/images/youtube.svg";
import Tiktok from "../../../../assets/images/tiktok.svg";
import Blog from "../../../../assets/images/blog.svg";
import CustomUrl from "../../../../assets/images/internet.svg";

const SelectMedia = (props) => {
  const socialMediaIcons = {
    Instagram,
    Facebook,
    Linkedin,
    Twitter,
    Youtube,
    Tiktok,
    CustomUrl,
    Blog,
  };

  return (
    <div className="select-wrapper">
      <Select
        placeholder={props.placeholder}
        value={props.selectedOption}
        defaultValue={props.selectedOption}
        onChange={(e) => {
          if (props.handleChange) {
            props.name
              ? props.handleChange(e, props.name)
              : props.handleChange(e);
          }
        }}
        onBlur={props.onBlur}
        isDisabled={props.disabled}
        options={props.options}
        getOptionLabel={(e) => (
          <div className="labelCotainer">
            <img
              className="socialMediaIcon"
              src={socialMediaIcons[e.value]}
              alt="icon"
            />
            <p className="labelName">{e.label}</p>
          </div>
        )}
        isSearchable={false}
        isClearable={props.isClearable || false}
        classNamePrefix={props.className ? props.className : "select-dropdown"}
        id={props.id}
        
      />
    </div>
  );
};
export default SelectMedia;
