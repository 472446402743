import React, { useState, useCallback, useEffect } from "react";
import {
    Switch,
    Route,
    Redirect,
    useRouteMatch,
    useLocation,
} from "react-router-dom";
import _ from "lodash";
import useAsync from "react-use-async-hook";
import ReactNotification from "react-notifications-component";

import * as AuthAPI from "../../api/auth";

import Dashboard from "./dashboard";
import Arrangements from "./arrangements";
import WhatToExpect from "./whatToExpect";
import WhatToBring from "./whatToBring";
import CertificateDetails from "./certificateDetails";
import ServicesDetails from "./servicesDetails";
import UserProfile from "./userProfile";
import ScheduledServices from "../scheduledServices/scheduledServices";
// import PrayerCreation from "./prayerCreation";
import PrayerCreation from "./prayerCard";
import ProgramCreation from "./programCreation";
import Memories from "./memories/index";
import MemoriesOld from "./memoriesOld/index";
import FamilyWebsite from "./familyWebsite/index";
import CustomizeFamilyWebsite from "./customizeFamilyWebsite";

const Cases = () => {
    const match = useRouteMatch();
    const [isNewUser, setNewUser] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);
    const location = useLocation();
    /**
     * Get User Details
     */
    const { data: userDetail, execute: executeGetUser } = useAsync({
        autoExecute: false,
        task: AuthAPI.isUserLoggedIn,
        dataLoader: useCallback(res => {
            return _.get(res, "data.data");
        }, []),
    });

    useEffect(() => {
        executeGetUser();
    }, []);

    useEffect(() => {
        if (userDetail) {
            if (_.get(userDetail, "role") === "admin") {
                setIsAdmin(true);
            } else if (_.get(userDetail, "isNewUser")) {
                setNewUser(true);
            }
        }
    }, [userDetail]);

    // eslint-disable-next-line no-restricted-globals
    return isAdmin && !location.pathname.includes("/admin/memories-list") ? (
        <Redirect to='/admin' />
    ) : isNewUser ? (
        <Redirect to='/profile' />
    ) : (
        <div>
            <ReactNotification />
            <Switch>
                <Route path={`${match.url}`} exact component={Dashboard} />
                <Route
                    path={`${match.url}/:onePortalId/arrangements`}
                    render={() => <Arrangements role='arranger' />}
                />
                <Route
                    path={`${match.url}/:onePortalId/whatToExpect`}
                    component={WhatToExpect}
                />
                <Route
                    path={`${match.url}/:onePortalId/whatToBring`}
                    component={WhatToBring}
                />
                <Route
                    path={`${match.url}/:onePortalId/certificateDetails`}
                    render={() => <CertificateDetails role='arranger' />}
                />
                <Route
                    path={`${match.url}/:onePortalId/servicesDetails`}
                    component={ServicesDetails}
                />
                <Route
                    path={`${match.url}/userProfile`}
                    component={UserProfile}
                />
                <Route
                    path={`${match.url}/:onePortalId/scheduledServices`}
                    component={ScheduledServices}
                />
                <Route
                    path={`${match.url}/:onePortalId/prayerCreation`}
                    render={() => <PrayerCreation role='arranger' />}
                />
                <Route
                    path={`${match.url}/:onePortalId/programCreation`}
                    render={() => <ProgramCreation role='arranger' />}
                />
                <Route path={`${match.url}/memories`} render={() => <Memories />} />
                <Route path={`${match.url}/:onePortalId/customizeFamilyWebsite`} render={() => <CustomizeFamilyWebsite role='arranger'/> } />
                <Route
                    path={`${match.url}/memories-old`}
                    component={MemoriesOld}
                />
                <Route
                    path={`${match.url}/share-memories`}
                    component={FamilyWebsite}
                />
                <Route
                    path={`${match.url}/name`}
                    component={FamilyWebsite}
                />
            </Switch>
        </div>
    );
};

export default Cases;
