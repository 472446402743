import React, { useCallback, useState, useEffect, useMemo } from "react";
import useAsync from "react-use-async-hook";
import { Row, Col, Form } from "reactstrap";
import { Formik } from "formik";
import SelectMedia from "./selectMedia";
import { socialMediaTypes } from "../../../utils/variables";
import * as yup from "yup";
import _ from "lodash";
import TooltipAndInfo from "../../../TooltipAndInfo";
import DeleteIcon from "../../../../assets/images/deleteGrey.svg";
import { useSetLoading } from "../../../utils/hooks/loader";
import { Prompt, useHistory } from "react-router-dom";
import ConfirmationDialog from "../../../utils/confirmationDailouge";
import addNotification from "../../../utils/notification/notification";
import Instagram from "../../../../assets/images/instagramSelect.svg";
import Facebook from "../../../../assets/images/facebook.svg";
import Linkedin from "../../../../assets/images/LinkedIn.svg";
import Twitter from "../../../../assets/images/twitterNewIcon.webp";
import Youtube from "../../../../assets/images/youtube.svg";
import Tiktok from "../../../../assets/images/tiktok.svg";
import Blog from "../../../../assets/images/blog.svg";
import CustomUrl from "../../../../assets/images/internet.svg";
import * as CustomizeFamilyWebsite from "../../../../api/customizeFamilyWebsite";
var timeout;

const AddSocialMediaLinks = ({ decedent, onePortalId }) => {
  const socialMediaIcons = {
    Instagram,
    Facebook,
    Linkedin,
    Twitter,
    Youtube,
    Tiktok,
    CustomUrl,
    Blog,
  };

  const history = useHistory();
  const [socialMediaLinkTooltip, setSocialMediaLinkTooltip] = useState(false);
  const [isDisable, setIsDisabled] = useState(true);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [finalData, setFinalData] = useState(null);
  const [isNavigate, setIsNavigate] = useState(false);
  const [isSocialMediaLinkError, setSocialMediaLinkError] = useState(false);
  const [isSocialMediaDataSaved, setIsSocialMediaDataSaved] = useState(true);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [selectedProfiles, setSelectedProfiles] = useState(null);
  const [targetLocation, setTargetLocation] = useState(null);
  const [errorObject, setErrorObject] = useState({});
  const [touchedFields, setTouchedFields] = useState({
    Instagram: false,
    Facebook: false,
    Youtube: false,
    Linkedin: false,
    Twitter: false,
    Tiktok: false,
    Blog: false,
    CustomUrl: false,
  });
  const [availableSocialMedia, setAvailableSocialMedia] =
    useState(socialMediaTypes);
  const [socialMediaLinksData, setSocialMediaLinksData] = useState(null);
  const showSocialMediaTooltip = () => {
    setSocialMediaLinkTooltip(!socialMediaLinkTooltip);
  };

  useEffect(() => {
    if (_.get(decedent, "socialMediaLinks")) {
      const resObject = _.get(decedent.socialMediaLinks, "socialMediaLinks");
      setSocialMediaLinksData(resObject);
      const arrayOfObjects = _.map(resObject, (value, key) => ({
        value: value,
        label: key,
      }));
      setSelectedProfiles(resObject);
      setSelectedOptions([...arrayOfObjects]);
      let actualSocialMediaTypes;
      actualSocialMediaTypes = availableSocialMedia;
      arrayOfObjects.map((account, i) => {
        const objToAdd = { value: account["label"], label: account["label"] };
        _.remove(actualSocialMediaTypes, (obj) => _.isEqual(obj, objToAdd));
      });
      setAvailableSocialMedia(actualSocialMediaTypes);
    } else {
      const listOfSocialMediaTypes = [...socialMediaTypes];
      setAvailableSocialMedia(listOfSocialMediaTypes);
    }
  }, [decedent]);

  const {
    data: socialMediaRes,
    loading: socialMediaLoader,
    execute: executeAddSocialMedia,
  } = useAsync({
    autoExecute: false,
    initialData: useMemo(() => null, []),
    task: useCallback((values, opi) => {
      return CustomizeFamilyWebsite.updateSocialMediaDetails(values, opi);
    }, []),
    dataLoader: useCallback((res) => {
      addNotification({
        type: "success",
        title: "Success",
        message: "SocialMediaLinks Updated Successfully",
      });
      return _.get(res, "data", []);
    }, []),
    onError: useCallback((error) => {
      addNotification({
        type: "danger",
        title: "Error",
        message: _.get(
          error,
          "response.data.message",
          "Oops! Something went wrong"
        ),
      });
      setIsSocialMediaDataSaved(false);
    }, []),
  });

  const callAPI = async () => {
    delete socialMediaLinksData?.socialMediaType;
    const filteredObject = _.pickBy(
      socialMediaLinksData,
      (value) => value !== undefined && value !== null && value !== ""
    );
    setFinalData({ socialMediaLinks: filteredObject });
    let checkFinalData = { socialMediaLinks: filteredObject };
    await executeAddSocialMedia(checkFinalData, onePortalId);
  };

  useEffect(() => {
    if (socialMediaRes?.message) {
      setIsSocialMediaDataSaved(true);
      setIsDisabled(true);
    }
  }, [socialMediaRes]);

  useEffect(() => {
    if (!_.isEmpty(errorObject) && selectedOptions?.length > 0) {
      Object.keys(errorObject).map((keyName) => {
        if (selectedOptions.some((option) => option.label === keyName)) {
          setSocialMediaLinkError(true);
        } else {
          setSocialMediaLinkError(false);
        }
      });
    } else {
      setSocialMediaLinkError(false);
    }
  }, [errorObject]);

  const handleBeforeUnload = async (event) => {
    if (!isSocialMediaLinkError) {
      callAPI();
    }
  };

  function warning() {
    timeout = setTimeout(function () {
      setTimeout(() => {
        handleBeforeUnload();
      }, [1000]);
    }, 1000);
    return "";
  }

  function noTimeout() {
    clearTimeout(timeout);
  }

  if (!isSocialMediaDataSaved) {
    window.onbeforeunload = warning;
    window.unload = noTimeout;
  } else {
    window.onbeforeunload = null;
  }

  const handleNavigation = (location) => {
    if (!isSocialMediaDataSaved && !showConfirmationDialog) {
      setIsNavigate(true);
      setTargetLocation(location);
      setShowConfirmationDialog(true);
      return false;
    }
    return true;
  };

  const handleSaveChanges = async () => {
    await callAPI();
    setShowConfirmationDialog(false);
    if (isNavigate) {
      history.push(targetLocation.pathname);
      setIsNavigate(false);
    }
  };

  const handleDiscardlNavigation = () => {
    setIsSocialMediaDataSaved(true);
    setShowConfirmationDialog(false);
    if (isNavigate && !isSocialMediaDataSaved) {
      setIsNavigate(false);
      history.push(targetLocation.pathname);
    }
  };

  const handleCancelNavigagtion = () => {
    setIsSocialMediaDataSaved(false);
    setShowConfirmationDialog(false);
    setIsNavigate(false);
  };

  const handleDelete = async (i, currentValue, setFieldValue, account) => {
    const updatedOptions = [...selectedOptions];
    updatedOptions.splice(i, 1);
    setFieldValue(currentValue, "");
    const availableOptions = [...availableSocialMedia];
    const objectToPush = { value: account["label"], label: account["label"] };
    availableOptions.push(objectToPush);
    setAvailableSocialMedia(availableOptions);
    if (updatedOptions?.length === 0) {
      setFieldValue("socialMediaType", "");
    } else {
      const lastEle = _.last(updatedOptions);
      const lastEleLabel = lastEle["label"];
      setFieldValue("socialMediaType", {
        value: [lastEleLabel],
        label: [lastEleLabel],
      });
    }
    setSelectedOptions(updatedOptions);
    delete socialMediaLinksData?.[currentValue];
    setSocialMediaLinksData(socialMediaLinksData);
    setIsDisabled(false);
    setIsSocialMediaDataSaved(false);
  };

  const showLoading = useMemo(() => socialMediaLoader, [socialMediaLoader]);
  useSetLoading(showLoading);

  return (
    <div className="addSocialMediaWrapper">
      <Prompt when={!isSocialMediaDataSaved} message={handleNavigation} />
      {showConfirmationDialog && !isSocialMediaDataSaved && (
        <ConfirmationDialog
          isOpen={showConfirmationDialog}
          onSave={() => {
            handleSaveChanges();
          }}
          onDisCard={handleDiscardlNavigation}
          onCancel={handleCancelNavigagtion}
          textToDisplay={"You have unsaved Social Media Links Changes"}
          isError={isSocialMediaLinkError}
        />
      )}
      <div className="display-name-heading">
        <div className="display-name">
          <div className="customIndex">3</div>
          <div>Add Social Media Links To Your Website</div>
        </div>
        <TooltipAndInfo
          id="socialMediaLinkTooltip"
          showTooltipFn={showSocialMediaTooltip}
          showToolTip={socialMediaLinkTooltip}
          size={25}
          tooltipText="Add Social Media Links of decedent to be displayed on the Family Website"
          color="#000000"
        />
      </div>
      <Formik
        enableReinitialize
        validateOnBlur={false}
        validateOnChange={true}
        initialValues={{
          socialMediaType: "",
          Instagram: "" || _.get(selectedProfiles, "Instagram"),
          Facebook: "" || _.get(selectedProfiles, "Facebook"),
          Linkedin: "" || _.get(selectedProfiles, "Linkedin"),
          Twitter: "" || _.get(selectedProfiles, "Twitter"),
          Youtube: "" || _.get(selectedProfiles, "Youtube"),
          Tiktok: "" || _.get(selectedProfiles, "Tiktok"),
          CustomUrl: "" || _.get(selectedProfiles, "CustomUrl"),
          Blog: "" || _.get(selectedProfiles, "Blog"),
        }}
        validationSchema={yup.object().shape({
          Instagram: yup
            .string()
            .matches(
              /^(https?:\/\/)?(www\.)?instagram\.com\/[a-zA-Z0-9_\-]*/,
              "Invalid Instagram URL"
            )
            .max(1024, "URL should be less than 1025 characters"),

          Facebook: yup
            .string()
            .matches(
              /^(https?:\/\/)?(www\.)?facebook\.com\/[a-zA-Z0-9_\-]*/,
              "Invalid Facebook URL"
            )
            .max(1024, "URL should be less than 1025 characters"),

          Linkedin: yup
            .string()
            .matches(
              /^(https?:\/\/)?(www\.)?linkedin\.com\/[a-zA-Z0-9_\-]*/,
              "Invalid LinkedIn URL"
            )
            .max(1024, "URL should be less than 1025 characters"),

          Twitter: yup
            .string()
            .matches(
              /^(https?:\/\/)?(www\.)?twitter\.com\/[a-zA-Z0-9_\-]*|x\.com\/[a-zA-Z0-9_\-]*/,
              "Invalid Twitter URL"
            )
            .max(1024, "URL should be less than 1025 characters"),

          Youtube: yup
            .string()
            .matches(
              /^(https?:\/\/)?(www\.)?youtube\.com\/[a-zA-Z0-9_\-]*|youtu\.be\/[a-zA-Z0-9_\-]*/,
              "Invalid YouTube URL"
            )
            .max(1024, "URL should be less than 1025 characters"),

          Tiktok: yup
            .string()
            .matches(
              /^(https?:\/\/)?(www\.)?tiktok\.com\/[a-zA-Z0-9_\-]*/,
              "Invalid TikTok URL"
            )
            .max(1024, "URL should be less than 1025 characters"),

          Blog: yup
            .string()
            .url("Invalid Blog Url")
            .max(1024, "Url should be less then 1025 characters"),

          CustomUrl: yup
            .string()
            .url("Invalid Custom Url")
            .max(1024, "Url should be less then 1025 characters"),
        })}
        onSubmit={(values) => {
          callAPI();
        }}
      >
        {({
          values,
          errors,
          touched,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          setSubmitting,
        }) => {
          const handleChange = (e) => {
            const { name, value } = e.target;
            setFieldValue(name, value);
            setSocialMediaLinksData({ ...socialMediaLinksData, [name]: value });
            setTouchedFields((prevTouchedFields) => ({
              ...prevTouchedFields,
              [name]: true,
            }));
            setIsDisabled(false);
            setIsSocialMediaDataSaved(false);
            setSubmitting(false);
          };
          if (errors) {
            setErrorObject(errors);
          }
          return (
            <Form id="addSocialMediaLinks" onSubmit={handleSubmit}>
              <div className="inputcard-wrapper">
                <div className="inputcard-body custom-inputcard-body">
                  <div className="inputcard-group selectSocialMedia">
                    <Row>
                      <Col>
                        <div className="choose-reason inputfield d-flex align-items-center justify-content-between">
                          <div className="inputcard-withlabel d-flex align-items-start justify-content-center flex-column">
                            <label className="inputcard-label">
                              Choose Account
                            </label>
                            <SelectMedia
                              placeholder="Select account"
                              options={availableSocialMedia}
                              defaultValue={values.socialMediaType}
                              selectedOption={values.socialMediaType}
                              name="socialMediaType"
                              handleChange={(e) => {
                                setFieldValue("socialMediaType", e);
                                setAvailableSocialMedia((prevOptions) =>
                                  prevOptions.filter(
                                    (option) => option["label"] !== e["label"]
                                  )
                                );
                                setSelectedOptions([...selectedOptions, e]);
                              }}
                            />

                            {errors.socialMediaType &&
                              (touched.socialMediaType ||
                                touchedFields?.socialMediaType) && (
                                <span className="error-input-msg">
                                  {errors.socialMediaType}
                                </span>
                              )}
                          </div>
                        </div>
                      </Col>
                      <Col className="selectedFieldsContainer">
                        {selectedOptions?.length >= 1 && (
                          <>
                            {selectedOptions.map((account, i) => {
                              const currentValue = account.label;
                              return (
                                <div className="choose-reason inputfield d-flex align-items-center justify-content-between">
                                  <div className="inputcard-withlabel d-flex align-items-start justify-content-center flex-column">
                                    <label className="inputcard-label">
                                      {account?.label}
                                    </label>
                                    <div className="mediaLinkField">
                                      <div className="inputImageWrapper">
                                        <input
                                          className={`custominputfield`}
                                          placeholder={`https://www.${account?.label}.com/`}
                                          name={account.label}
                                          value={values[currentValue]}
                                          formInput
                                          onChange={handleChange}
                                        />
                                        <img
                                          src={socialMediaIcons[currentValue]}
                                          alt="socialMediaIcon"
                                          className={`socialMediaIcon ${currentValue}`}
                                        />
                                      </div>
                                      <div className="deleteContainer">
                                        <img
                                          src={DeleteIcon}
                                          alt="DeleteIcon"
                                          onClick={() => {
                                            handleDelete(
                                              i,
                                              currentValue,
                                              setFieldValue,
                                              account
                                            );
                                          }}
                                        />
                                      </div>
                                    </div>
                                    {errors[currentValue] &&
                                      (touched[currentValue] ||
                                        touchedFields[currentValue]) && (
                                        <span className="error-memory-input-msg">
                                          {errors[currentValue]}
                                        </span>
                                      )}
                                  </div>
                                </div>
                              );
                            })}
                          </>
                        )}
                      </Col>
                    </Row>
                    <hr className="lineTag"></hr>
                    <div className="d-flex justify-content-end align-items-end">
                      <button
                        type="submit"
                        className="save-button"
                        disabled={isDisable}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default AddSocialMediaLinks;
