import React, { useState, useEffect } from "react";
import _ from "lodash";
import parse from "html-react-parser";

import EditTemplateImage from "./editTemplateImage";
import { simplifyTextFields } from "../../utils/Template/templateUtils";
import Template from "./template";
import { prayerCardFuneralLocations } from "../../utils/variables";
import { getString, checkIsHTML, getCustomSize } from "./templateString";

const PreviewPage = ({ template, role, isCustom, page, index, url}) => {
    const [inputs, setInputs] = useState([]);

    useEffect(() => {
        if (_.get(page, "textFields")) {
            let inputs = simplifyTextFields(page.textFields);
            inputs = _.orderBy(inputs, ["priority"], ["asc"]);
            setInputs(inputs);
        }
    }, [page]);

    return _.get(page, "type") === "image" || url ? (
        <EditTemplateImage
            index={index}
            role={role}
            isCustom={isCustom}
            isNav={true}
            source={template.imageURL}
            url={url}
        />
    ) : (
        <div className='template-screen-edit'>
            {inputs &&
                inputs.map((inputField, i) => {
                    let value = _.get(inputField, "text");
                    let style = _.get(inputField, "name")
                        ? Template.prayer[_.get(inputField, "name")]
                        : {};
                    if (
                        _.get(inputField, "type") &&
                        _.get(inputField, "subType") === "title"
                    ) {
                        value = _.get(page, "poem.title");
                        style =
                            Template.prayer[_.get(inputField, "name")][
                                _.get(inputField, "subType")
                            ];
                    }
                    if (
                        _.get(inputField, "type") &&
                        _.get(inputField, "subType") === "content"
                    ) {
                        value = _.get(page, "poem.content");
                        style =
                            Template.prayer[_.get(inputField, "name")][
                                _.get(inputField, "subType")
                            ];
                    }

                    let htmlContent = value;
                    if (!checkIsHTML(value)) {
                        let { type, subType } = inputField;
                        subType = type === "poem" ? subType : type;
                        style = {
                            ...style,
                            size: getCustomSize(style, value, subType),
                        };
                        htmlContent = getString(style, value || "", type);

                        if (type === "footer") {
                            const locationName = _.get(inputField, "text");
                            const cityName =
                                prayerCardFuneralLocations[locationName];
                            htmlContent =
                                htmlContent +
                                getString(style, cityName || "", type);
                        }
                    }

                    return <div className=''>{parse(htmlContent)}</div>;
                })}
        </div>
    );
};

export default PreviewPage;
