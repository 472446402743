import React, { useCallback, useMemo, useState } from "react";
import { Formik } from "formik";
import _ from "lodash";
import { Form, Tooltip } from "reactstrap";
import useAsync from "react-use-async-hook";

import * as DecedentAPI from "../../../../api/decedent";
import { useSetLoading } from "../../../utils/hooks/loader";
import addNotification from "../../../utils/notification/notification";
import { formatOptions } from "../../../utils/functions";

import CustomCheck from "../../../utils/btnCheckBox/btnCheckBox";
import SelectComponent from "../../../utils/SelectComponent/SelectComponent";
import {
    certificateFreezeText,
    firstTimeSaveText,
} from "../constants/constants";

const EthnicityDetails = ({
    decedent,
    ethnicityHeader,
    ethnicity,
    race,
    isDisable,
    refreshDecedent,
    decedentId,
    showCertificateSavePopup,
    enableSubmitButton,
}) => {
    /**
     * Save Ethnicity Details
     */
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const isFieldAlreadySaved = _.get(decedent, "isFirstSaved");
    const [isFirstSaved, setIsFirstSaved] = useState(isFieldAlreadySaved);
    if (
        typeof isFirstSaved === "undefined" &&
        isFirstSaved !== isFieldAlreadySaved
    ) {
        setIsFirstSaved(isFieldAlreadySaved);
    }
    const showToolTip = disabled => {
        if (isDisable) {
            setTooltipOpen(disabled);
        }
    };
    const { loading: updateLoader, execute: executeUpdate } = useAsync({
        autoExecute: false,
        task: useCallback(
            async (
                values,
                decedentId,
                formikBag,
                isFirstSaved,
                showCertificateSavePopup
            ) => {
                let payload = {
                    isHispanic: values.isHispanic,
                    hispanic: values.hispanic
                        ? {
                              id: values.hispanic.id,
                              edrsCode: values.hispanic.edrsCode,
                              isHispanic: values.hispanic.isHispanic,
                              name: values.hispanic.name,
                          }
                        : "",
                    raceOne: values.raceOne
                        ? {
                              id: values.raceOne.id,
                              edrsCode: values.raceOne.edrsCode,
                              headerId: values.raceOne.headerId,
                              name: values.raceOne.name,
                          }
                        : "",
                    raceTwo: values.raceTwo
                        ? {
                              id: values.raceTwo.id,
                              edrsCode: values.raceTwo.edrsCode,
                              headerId: values.raceTwo.headerId,
                              name: values.raceTwo.name,
                          }
                        : "",
                    raceThree: values.raceThree
                        ? {
                              id: values.raceThree.id,
                              edrsCode: values.raceThree.edrsCode,
                              headerId: values.raceThree.headerId,
                              name: values.raceThree.name,
                          }
                        : "",
                    ethnicityOne: values.ethnicityOne
                        ? {
                              id: values.ethnicityOne.id,
                              edrsCode: values.ethnicityOne.edrsCode,
                              isHispanic: values.ethnicityOne.isHispanic,
                              name: values.ethnicityOne.name,
                          }
                        : "",
                    ethnicityTwo: values.ethnicityTwo
                        ? {
                              id: values.ethnicityTwo.id,
                              edrsCode: values.ethnicityTwo.edrsCode,
                              isHispanic: values.ethnicityTwo.isHispanic,
                              name: values.ethnicityTwo.name,
                          }
                        : "",
                    ethnicityThree: values.ethnicityThree
                        ? {
                              id: values.ethnicityThree.id,
                              edrsCode: values.ethnicityThree.edrsCode,
                              isHispanic: values.ethnicityThree.isHispanic,
                              name: values.ethnicityThree.name,
                          }
                        : "",
                };
                return {
                    res: await DecedentAPI.saveEthnicityDetails(
                        decedentId,
                        payload
                    ),
                    formikBag,
                    isFirstSaved,
                    showCertificateSavePopup,
                };
            },
            []
        ),
        dataLoader: useCallback(
            ({ res, formikBag, isFirstSaved, showCertificateSavePopup }) => {
                formikBag.setSubmitting(false);
                refreshDecedent();
                enableSubmitButton();
                if (isFirstSaved) {
                    addNotification({
                        type: "success",
                        title: "Success",
                        message: firstTimeSaveText,
                    });
                } else {
                    showCertificateSavePopup();
                    setIsFirstSaved(true);
                }
                addNotification({
                    type: "success",
                    title: "Success",
                    message: "Ethnicity Details Updated!",
                });
            },
            []
        ),
        onError: useCallback(error => {
            addNotification({
                type: "danger",
                title: "Error",
                message: _.get(
                    error,
                    "response.data.message",
                    "Oops! Something went wrong"
                ),
            });
        }, []),
    });

    const showLoader = useMemo(() => updateLoader, [updateLoader]);
    useSetLoading(showLoader);

    return (
        <div className='ethnicitydetails-wrapper'>
            <div className='cardbox-wrapper'>
                <div className='cardbox-heading'>
                    <h4 className='cardbox-heading-name text-capitalize text-black'>
                        <span className='item-numbering'>3</span>
                        Ethnicity Details
                    </h4>
                </div>
                <Formik
                    initialValues={{
                        ethnicityOneData: ethnicity,
                        ethnicityTwoData: ethnicity,
                        ethnicityThreeData: ethnicity,
                        ethnicityHeaderData: ethnicityHeader,
                        race: race,
                        raceData: _.map(ethnicityHeader, item => {
                            return {
                                label: item.name,
                                options: formatOptions(
                                    _.filter(race, {
                                        headerId: item.id.toString(),
                                    })
                                ),
                            };
                        }),
                        hispanicData: _.filter(ethnicity, { isHispanic: true }),
                        isHispanic: _.get(decedent, "isHispanic", false),
                        hispanic: _.get(decedent, "hispanic")
                            ? {
                                  label: _.get(decedent, "hispanic.name"),
                                  value: _.get(decedent, "hispanic.name"),
                                  ..._.find(ethnicity, {
                                      name: _.get(decedent, "hispanic.name"),
                                  }),
                              }
                            : "",
                        raceOne: _.get(decedent, "raceOne")
                            ? {
                                  label: _.get(decedent, "raceOne.name"),
                                  value: _.get(decedent, "raceOne.name"),
                                  ..._.find(race, {
                                      name: _.get(decedent, "raceOne.name"),
                                  }),
                              }
                            : "",
                        ethnicityOne: _.get(decedent, "ethnicityOne")
                            ? {
                                  label: _.get(decedent, "ethnicityOne.name"),
                                  value: _.get(decedent, "ethnicityOne.name"),
                                  ..._.find(ethnicity, {
                                      name: _.get(
                                          decedent,
                                          "ethnicityOne.name"
                                      ),
                                  }),
                              }
                            : "",
                        raceTwo: _.get(decedent, "raceTwo")
                            ? {
                                  label: _.get(decedent, "raceTwo.name"),
                                  value: _.get(decedent, "raceTwo.name"),
                                  ..._.find(race, {
                                      name: _.get(decedent, "raceTwo.name"),
                                  }),
                              }
                            : "",
                        ethnicityTwo: _.get(decedent, "ethnicityTwo")
                            ? {
                                  label: _.get(decedent, "ethnicityTwo.name"),
                                  value: _.get(decedent, "ethnicityTwo.name"),
                                  ..._.find(ethnicity, {
                                      name: _.get(
                                          decedent,
                                          "ethnicityTwo.name"
                                      ),
                                  }),
                              }
                            : "",
                        raceThree: _.get(decedent, "raceThree")
                            ? {
                                  label: _.get(decedent, "raceThree.name"),
                                  value: _.get(decedent, "raceThree.name"),
                                  ..._.find(race, {
                                      name: _.get(decedent, "raceThree.name"),
                                  }),
                              }
                            : "",
                        ethnicityThree: _.get(decedent, "ethnicityThree")
                            ? {
                                  label: _.get(decedent, "ethnicityThree.name"),
                                  value: _.get(decedent, "ethnicityThree.name"),
                                  ..._.find(ethnicity, {
                                      name: _.get(
                                          decedent,
                                          "ethnicityThree.name"
                                      ),
                                  }),
                              }
                            : "",
                    }}
                    enableReinitialize={true}
                    onSubmit={async (values, formikBag) => {
                        executeUpdate(
                            values,
                            decedentId,
                            formikBag,
                            isFirstSaved,
                            showCertificateSavePopup
                        );
                    }}
                >
                    {({
                        values,
                        handleSubmit,
                        setSubmitting,
                        isSubmitting,
                        setFieldValue,
                    }) => {
                        return (
                            <Form id='ethnicityForm' onSubmit={handleSubmit}>
                                <div className='inputcard-wrapper cardbox-body'>
                                    <div className='inputcard-body custom-inputcard-body'>
                                        <div className='inputcard-group d-flex align-items-center justify-content-start'>
                                            <div className='choose-reason inputfield d-flex align-items-center justify-content-start'>
                                                <CustomCheck
                                                    value={values.isHispanic}
                                                    handleChange={e => {
                                                        setSubmitting(false);
                                                        setFieldValue(
                                                            "isHispanic",
                                                            e
                                                        );
                                                        if (values.raceOne) {
                                                            setFieldValue(
                                                                "ethnicityOneData",
                                                                _.filter(
                                                                    ethnicity,
                                                                    {
                                                                        edrsCode:
                                                                            values
                                                                                .raceOne
                                                                                .edrsCode,
                                                                        isHispanic:
                                                                            e,
                                                                    }
                                                                )
                                                            );
                                                        }
                                                        if (values.raceTwo) {
                                                            setFieldValue(
                                                                "ethnicityTwoData",
                                                                _.filter(
                                                                    ethnicity,
                                                                    {
                                                                        edrsCode:
                                                                            values
                                                                                .raceTwo
                                                                                .edrsCode,
                                                                        isHispanic:
                                                                            e,
                                                                    }
                                                                )
                                                            );
                                                        }
                                                        if (values.raceThree) {
                                                            setFieldValue(
                                                                "ethnicityThreeData",
                                                                _.filter(
                                                                    ethnicity,
                                                                    {
                                                                        edrsCode:
                                                                            values
                                                                                .raceThree
                                                                                .edrsCode,
                                                                        isHispanic:
                                                                            e,
                                                                    }
                                                                )
                                                            );
                                                        }
                                                        if (!e) {
                                                            setFieldValue(
                                                                "hispanic",
                                                                ""
                                                            );
                                                        }
                                                    }}
                                                    disabled={isDisable}
                                                />
                                                <span>Hispanic</span>
                                            </div>
                                            <div className='choose-reason inputfield d-flex align-items-center justify-content-between'>
                                                <div className='inputcard-withlabel d-flex align-items-start justify-content-center flex-column'>
                                                    <label className='inputcard-label'>
                                                        Ethnicity
                                                    </label>
                                                    <SelectComponent
                                                        placeholder='Select'
                                                        options={
                                                            values.hispanicData
                                                                ? formatOptions(
                                                                      values.hispanicData
                                                                  )
                                                                : ""
                                                        }
                                                        defaultValue={
                                                            values.hispanic
                                                        }
                                                        selectedOption={
                                                            values.hispanic
                                                        }
                                                        disabled={
                                                            !values.isHispanic ||
                                                            isDisable
                                                        }
                                                        name='hispanic'
                                                        handleChange={e => {
                                                            setSubmitting(
                                                                false
                                                            );
                                                            setFieldValue(
                                                                "hispanic",
                                                                e
                                                            );
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='inputcard-group d-flex align-items-center justify-content-start'>
                                            <div className='choose-reason inputfield d-flex align-items-center justify-content-between'>
                                                <div className='inputcard-withlabel d-flex align-items-start justify-content-center flex-column'>
                                                    <label className='inputcard-label'>
                                                        Other Race
                                                    </label>
                                                    <SelectComponent
                                                        placeholder='Select'
                                                        options={formatOptions(
                                                            values.raceData
                                                        )}
                                                        defaultValue={
                                                            values.raceOne
                                                        }
                                                        selectedOption={
                                                            values.raceOne
                                                        }
                                                        name='raceOne'
                                                        handleChange={e => {
                                                            setSubmitting(
                                                                false
                                                            );
                                                            setFieldValue(
                                                                "raceOne",
                                                                e
                                                            );
                                                            setFieldValue(
                                                                "ethnicityOneData",
                                                                _.filter(
                                                                    ethnicity,
                                                                    {
                                                                        edrsCode:
                                                                            e.edrsCode,
                                                                        isHispanic:
                                                                            values.isHispanic,
                                                                    }
                                                                )
                                                            );
                                                        }}
                                                        disabled={isDisable}
                                                    />
                                                </div>
                                            </div>
                                            <div className='choose-reason inputfield d-flex align-items-center justify-content-between'>
                                                <div className='inputcard-withlabel d-flex align-items-start justify-content-center flex-column'>
                                                    <label className='inputcard-label'>
                                                        Ethnicity
                                                    </label>
                                                    <SelectComponent
                                                        placeholder='Select'
                                                        options={formatOptions(
                                                            values.ethnicityOneData
                                                        )}
                                                        defaultValue={
                                                            values.ethnicityOne
                                                        }
                                                        selectedOption={
                                                            values.ethnicityOne
                                                        }
                                                        disabled={
                                                            isDisable ||
                                                            !values.raceOne
                                                        }
                                                        name='ethnicityOne'
                                                        handleChange={e => {
                                                            setSubmitting(
                                                                false
                                                            );
                                                            setSubmitting(
                                                                false
                                                            );
                                                            setFieldValue(
                                                                "ethnicityOne",
                                                                e
                                                            );
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='inputcard-group d-flex align-items-center justify-content-start'>
                                            <div className='choose-reason inputfield d-flex align-items-center justify-content-between'>
                                                <div className='inputcard-withlabel d-flex align-items-start justify-content-center flex-column'>
                                                    <label className='inputcard-label d-flex d-md-none'>
                                                        Other Race
                                                    </label>
                                                    <SelectComponent
                                                        placeholder='Select'
                                                        options={formatOptions(
                                                            values.raceData
                                                        )}
                                                        defaultValue={
                                                            values.raceTwo
                                                        }
                                                        selectedOption={
                                                            values.raceTwo
                                                        }
                                                        name='raceTwo'
                                                        handleChange={e => {
                                                            setSubmitting(
                                                                false
                                                            );
                                                            setFieldValue(
                                                                "raceTwo",
                                                                e
                                                            );
                                                            setFieldValue(
                                                                "ethnicityTwoData",
                                                                _.filter(
                                                                    ethnicity,
                                                                    {
                                                                        edrsCode:
                                                                            e.edrsCode,
                                                                        isHispanic:
                                                                            values.isHispanic,
                                                                    }
                                                                )
                                                            );
                                                        }}
                                                        disabled={isDisable}
                                                    />
                                                </div>
                                            </div>
                                            <div className='choose-reason inputfield d-flex align-items-center justify-content-between'>
                                                <div className='inputcard-withlabel d-flex align-items-start justify-content-center flex-column'>
                                                    <label className='inputcard-label d-flex d-md-none'>
                                                        Ethnicity
                                                    </label>
                                                    <SelectComponent
                                                        placeholder='Select'
                                                        options={formatOptions(
                                                            values.ethnicityTwoData
                                                        )}
                                                        defaultValue={
                                                            values.ethnicityTwo
                                                        }
                                                        selectedOption={
                                                            values.ethnicityTwo
                                                        }
                                                        disabled={
                                                            isDisable ||
                                                            !values.raceTwo
                                                        }
                                                        name='ethnicityTwo'
                                                        handleChange={e => {
                                                            setSubmitting(
                                                                false
                                                            );
                                                            setFieldValue(
                                                                "ethnicityTwo",
                                                                e
                                                            );
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='inputcard-group d-flex align-items-center justify-content-start'>
                                            <div className='choose-reason inputfield d-flex align-items-center justify-content-between'>
                                                <div className='inputcard-withlabel d-flex align-items-start justify-content-center flex-column'>
                                                    <label className='inputcard-label d-flex d-md-none'>
                                                        Other Race
                                                    </label>
                                                    <SelectComponent
                                                        placeholder='Select'
                                                        options={formatOptions(
                                                            values.raceData
                                                        )}
                                                        defaultValue={
                                                            values.raceThree
                                                        }
                                                        selectedOption={
                                                            values.raceThree
                                                        }
                                                        name='raceThree'
                                                        handleChange={e => {
                                                            setSubmitting(
                                                                false
                                                            );
                                                            setFieldValue(
                                                                "raceThree",
                                                                e
                                                            );
                                                            setFieldValue(
                                                                "ethnicityThreeData",
                                                                _.filter(
                                                                    ethnicity,
                                                                    {
                                                                        edrsCode:
                                                                            e.edrsCode,
                                                                        isHispanic:
                                                                            values.isHispanic,
                                                                    }
                                                                )
                                                            );
                                                        }}
                                                        disabled={isDisable}
                                                    />
                                                </div>
                                            </div>
                                            <div className='choose-reason inputfield d-flex align-items-center justify-content-between'>
                                                <div className='inputcard-withlabel d-flex align-items-start justify-content-center flex-column'>
                                                    <label className='inputcard-label d-flex d-md-none'>
                                                        Ethnicity
                                                    </label>
                                                    <SelectComponent
                                                        placeholder='Select'
                                                        options={formatOptions(
                                                            values.ethnicityThreeData
                                                        )}
                                                        defaultValue={
                                                            values.ethnicityThree
                                                        }
                                                        selectedOption={
                                                            values.ethnicityThree
                                                        }
                                                        disabled={
                                                            isDisable ||
                                                            !values.raceThree
                                                        }
                                                        name='ethnicityThree'
                                                        handleChange={e => {
                                                            setSubmitting(
                                                                false
                                                            );
                                                            setFieldValue(
                                                                "ethnicityThree",
                                                                e
                                                            );
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='inputcard-footer'>
                                        <div
                                            id='ethnicityForm-save'
                                            onPointerEnter={() =>
                                                showToolTip(true)
                                            }
                                            onPointerLeave={() =>
                                                showToolTip(false)
                                            }
                                        >
                                            <button
                                                type='submit'
                                                disabled={
                                                    isDisable || isSubmitting
                                                }
                                                className='btn-theme btn-search-wrapper text-uppercase'
                                            >
                                                SAVE
                                            </button>
                                        </div>
                                        <Tooltip
                                            placement='top-end'
                                            isOpen={tooltipOpen}
                                            target='ethnicityForm-save'
                                            className='tooltip-template'
                                        >
                                            {certificateFreezeText}
                                        </Tooltip>
                                    </div>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            </div>
        </div>
    );
};

export default EthnicityDetails;
