import React from 'react'
import _ from 'lodash'
import { Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import PreviewPage from './previewPage';

const Preview = ({ pages, activeTab, toggle, mode, ...props }) => {
    return (
        <Nav tabs className={`menu-navigation ${mode}-mode`}>
            {pages.map((page, index) => (
                <NavItem>
                    <NavLink
                        className={classnames({
                            active: activeTab === index + 1
                        })}
                        onClick={() => {
                            toggle(index + 1);
                        }}
                    >
                        <PreviewPage 
                            page={page}
                            index={index}
                            {...props}
                        />
                    </NavLink>
                </NavItem> 
            ))}
        </Nav>
    )
}

export default Preview
