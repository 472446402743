import { apiFetch } from "../configs/apiConfig";
import axios from "axios";
import { API_URL } from "../constants/appConstants";

export const familyWebsiteAccess = (opi) => {
    return apiFetch({
        url: `${API_URL}/auth/verify-familyWebsite-access/${opi}`,
        method: "GET"
    });
};

export const isUserLoggedIn = () => {
    return apiFetch({
        url: `${API_URL}/auth/me`,
        method: "GET"
    });
};

export const doSignIn = data => {
    return apiFetch({
        method: "post",
        url: `/auth/${data.role}/login`,
        data: data.payload
    });
};

export const resetPassword = data => {
    return apiFetch({
        method: "post",
        url: "/auth/forgot-password",
        data
    });
};

export const doLinkVerification = data => {
    return axios({
        url: `${API_URL}/auth/verify`,
        method: "POST",
        headers: {
            Authorization: `Bearer ${data.token}`
        }
    });
};

export const createPassword = data => {
    return axios({
        url: `${API_URL}/auth/reset-password`,
        method: "POST",
        data: {
            password: data.password
        },
        headers: {
            Authorization: `Bearer ${data.token}`
        }
    });
};
