import React, { useCallback, useMemo, useRef, useState, useEffect } from "react";
import _ from "lodash";
import addNotification from "../notification/notification";
import { updateBannerImage } from "../../../api/dashboard";
import cameraIcon from "../../../assets/images/camera.svg";
import TooltipAndInfo from "../../TooltipAndInfo";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import AvatarEditor from "react-avatar-editor";
import useAsync from "react-use-async-hook";
import { useSetLoading } from "../hooks/loader";
import Closebutton from "../../../assets/images/cancel-button.png";
import defaultBannerImage from '../../../assets/images/CLFAA-banner.JPG'
import SpinnerImage from '../../../assets/gifs/spinning-loading.gif'
import OptionsMenuContainer from "../optionsDropDown";


const BannerContentStatic = ({
    decedent,
    opi = "",
    session,
    userDetail,
    isPagePrivate,
    isLoading,
    userNotFound,
    isFamilyAccess
}) => {
    const [showBannerTooltip, setShowBannerTooltip] = useState(false);
    const [isModal, setIsModal] = useState(false);
    const [originaalBannerImage,setOriginalBannerImage] = useState(_.get(decedent,'originalBannerUrl'));
    const [originalBannerUrl,setOriginalBannerUrl] = useState(_.get(decedent,"originalBannerUrl"));
    const [imageHeigth,setImageHeight] = useState(0);
    const [position, setPosition] = useState({ x: 0.5, y: 0.5 });
    // const [sizeAccepted,setSizeAccepted] = useState(true);
    const [isEdit,setIdEdit] = useState(false);

    const [uploadNewImage,setUploadNewImage] = useState(false);
    const [avatarImage, setAvatarImage] = useState(
        _.get(decedent, "bannerUrl")
    );
    const [mimeType, setMimeType] = useState("image/jpeg");
    const [fileName, setFileName] = useState("fileName.jpg");
    const [zoom, setZoom] = useState(1);
    const avatarRef = useRef(null);

    const [deviceWidth, setDeviceWidth] = useState();

    useEffect(() => {
        const handleResize = () => {
            setDeviceWidth(window.innerWidth);
        };
        window.addEventListener("resize", handleResize);
        setDeviceWidth(window.innerWidth);
    }, []);

    const [bannerUrl, setBannerUrl] = useState(_.get(decedent, "bannerUrl") || "");
    useEffect(()=>{
        setOriginalBannerUrl(_.get(decedent,'originalBannerUrl'))
        setBannerUrl(_.get(decedent, "bannerUrl") || "")
    },[decedent])

    const showHideToolTip = () => {
        setShowBannerTooltip(!showBannerTooltip);
    };

    const opiId = _.get(decedent, "opi") || opi || "";
    const isEmptyDecedent =
        opi && (!decedent || (decedent && _.isEmpty(decedent)));

    const {data: urlsData,loading : mediaLoading, execute : executeMedia} = useAsync({
        autoExecute : false,
        initialData: useMemo(() => null, []),
        task : useCallback(async formData => {
            const response = await updateBannerImage(opiId, formData);
            return response;   
        },[]),
        dataLoader : useCallback(({data}) => {
            addNotification({
                type: "success",
                title: "Success",
                message: data.message,
            });
            return data;
        }, []),
        onError: useCallback(error => {
            addNotification({
                type: "danger",
                title: "Error",
                message: _.get(
                    error,
                    "response.data.message",
                    "Oops! Something went wrong"
                ),
            });
        }, []),  
    })

    useEffect(() => {
        if (urlsData) {
            setBannerUrl(_.get(urlsData, 'bannerUrl'));
            if (uploadNewImage) {
                setOriginalBannerUrl(_.get(urlsData, 'originalBannerUrl'))
            }
        }
    }, [urlsData])

    useEffect(()=>{
        const img = new Image();
        img.src = originalBannerUrl;
        img.addEventListener('load', function() {
        setImageHeight(this.naturalHeight);
        });
    },[isEdit])

    const showLoader = useMemo(() => mediaLoading, [mediaLoading]);
    useSetLoading(showLoader);

    const modalClose = () => {
        setIdEdit(false)
        setIsModal(false);
        setZoom(1)
    }

    const handlePositionChange = (newPosition) => {
        if (newPosition.x < 0) {
          newPosition.x = 0;
        } else if (newPosition.x > 1) {
          newPosition.x = 1;
        }
        if (newPosition.y < 0) {
            newPosition.y = 0;
          } else if (newPosition.y > 1) {
            newPosition.y = 1;
          }
        setPosition(newPosition);
      };

    const avatarSave = async () => {
        if (avatarRef.current) {
            const canvasScaled = avatarRef.current.getImageScaledToCanvas();
            const croppedImg = canvasScaled.toDataURL();
            const blob = await (await fetch(croppedImg)).blob();
            let originalFileName;
            let originalExtension;
            const fileNameArray = originalBannerUrl?originalBannerUrl.split('-'):'';
            const originalNameArray = fileNameArray ? fileNameArray.slice(1).join(""): '';
            const removeExtension = originalNameArray ? originalNameArray.split('.') : '';
            const extension = removeExtension ? removeExtension.pop() : 'jpeg'
            const finalName = removeExtension ? removeExtension.join("").trim() : '';
            const min = 1;
            const max = 100;
            const randomNum = Math.floor(Math.random() * (max - min + 1)) + min;
            if(uploadNewImage) {
                const formatFileName = fileName? fileName.split('.') : '';
                originalExtension = formatFileName ? formatFileName.pop() : 'jpeg'
                 originalFileName = formatFileName ? formatFileName.join("").trim() : '';
            }
           const newFileName = uploadNewImage ? `${originalFileName}_${randomNum}.${originalExtension}` 
            : `${finalName ? finalName : 'BannerImage'}_${randomNum}.${extension}`;
            const croppedImage = new File([blob], newFileName, {
                type: `image/${originalExtension ? originalExtension : (extension ? extension : 'jpeg')}`,
                lastModified: new Date(),
            });
            var formData = new FormData();
            formData.append("file", croppedImage);
            if(uploadNewImage) {
                const min = 2;
                const max = 200;
                const randomNum = Math.floor(Math.random() * (max - min + 1)) + min;
                const newFileName = `${originalFileName ? originalFileName : 'BannerImage'}_${randomNum}.${originalExtension}`
                const newFile = new File([originaalBannerImage], newFileName, { type: originaalBannerImage.type });
                formData.append("files", newFile);
            }
            setIdEdit(false)
            executeMedia(formData);
            setIsModal(false); 
        }
    };

    // let clsName = "landing-banner-decedentdetails";
    let clsName = '';
    if (!isLoading) {
        const loadingClass = isEmptyDecedent && !isPagePrivate && !userNotFound
            ? "banner-unloaded"
            : "banner-loaded";
        clsName = loadingClass;
    }

    return (
            <div className="mainBannerImageContainer">
                <div className={`container ${clsName}`}>
                    {!isModal && (
                        <div className={`landing-banner-wrapper`}> 
                            <div>
                                {
                                    isEmptyDecedent && (isPagePrivate || userNotFound) && (
                                        <div style={{background: `url(${defaultBannerImage})
                                            no-repeat`,
                                            backgroundPosition: 'center',
                                            backgroundSize: 'cover',
                                            height:'350px'
                                        }}></div>
                                    )
                                }
                                {
                                    (!isEmptyDecedent) ? (
                                        <img src={bannerUrl ? bannerUrl : defaultBannerImage} alt='bannerImage' 
                                        className={`${bannerUrl ? 'bannerImage' : 'deafultBannerImage' }`}/>
                                    )
                                    :(isEmptyDecedent && !isPagePrivate && !userNotFound && (
                                        <div className="displayLoader">
                                            <img src={SpinnerImage} alt='spinnerGif'/>
                                        </div>
                                    ))
                                }
                               
                             </div>
                        
                            {session.isLoggedIn && opiId && !isPagePrivate && isFamilyAccess && (
                                <>
                                     <button className='banner-info static-text toolTipButton'>
                                        <TooltipAndInfo
                                            id='banner-tooltip-icon'
                                            color='#000000'
                                            size="25"
                                            clsName=""
                                            marginValue="0"
                                            showTooltipFn={showHideToolTip}
                                            showToolTip={showBannerTooltip}
                                            tooltipText='Recommended size to upload the banner image is 1500 x 350 Pixels'
                                        />
                                    </button>
                                    <OptionsMenuContainer
                                                originalBannerUrl={originalBannerUrl}
                                                decedent={decedent}
                                                setIdEdit={setIdEdit}
                                                setIsModal={setIsModal}
                                                setUploadNewImage={setUploadNewImage}
                                                setFileName={setFileName}
                                                setMimeType={setMimeType}
                                                setOriginalBannerImage={setOriginalBannerImage}
                                                setAvatarImage={setAvatarImage}
                                                setImageHeight={setImageHeight}
                                                >
                                    </OptionsMenuContainer>
                                </>
                            )}
                            </div>
                    )}
                    
                        </div>
                    {isModal && (
                        <div className="avatar-container shadow bg-white rounded">
                            <div
                            >
                                <AvatarEditor
                                    position={position}
                                    ref={avatarRef}
                                    image={isEdit ? originalBannerUrl : avatarImage}
                                    crossOrigin="anonymous"
                                    height={350}
                                    width={deviceWidth <= 1500 ? 1480 : deviceWidth-20}
                                    scale={zoom}
                                    rotate={0}
                                    border={ imageHeigth < 500 ? [5,5] : [10,100]}
                                    color={[255, 255, 255, 0.6]}
                                    onPositionChange={handlePositionChange}
                                    className='banner-avatar-editor' 
                                />
                            </div>
                            <div className="cancelSaveOptionsContainer">
                                <div className='slidecontainer d-flex justify-content-between align-items-center'>
                                    <span className='symbol-size' onClick={() => setZoom(prev => prev <= 0.1 ? prev : prev - 0.1)}>-</span>
                                    <input
                                        type='range'
                                        min={0.1}
                                        max={2}
                                        step={0.01}
                                        value={zoom}
                                        className='slider'
                                        onChange={(e) =>
                                            setZoom(parseFloat(e.target.value))
                                        }
                                    />
                                    <span className='symbol-size' onClick={() => setZoom(prev => prev >= 2 ? prev : prev + 0.1)}>+</span>
                                </div>
                                <div className='avatar-buttons-wrapper d-flex justify-content-center mt-2 mb-4'>
                                    <span>
                                        <button
                                            className='btn-theme  mr-3'
                                            onClick={modalClose}
                                        >
                                            CANCEL
                                        </button>
                                    </span>
                                    <span>
                                        <button
                                            className='btn-theme'
                                            onClick={avatarSave}
                                        >
                                            SAVE
                                        </button>
                                    </span>
                                </div>
                            </div>
                        </div>
                    )}
             
        </div>
    );
};

export default React.memo(BannerContentStatic);
