import React, {
    useEffect,
    useCallback,
    useState,
    useRef,
    useMemo,
} from "react";
import _ from "lodash";
import useAsync from "react-use-async-hook";
import * as ObituaryAPI from "../../../../api/obituary";
import { Link } from "react-router-dom";
import "draft-js/dist/Draft.css";
import addNotification from "../../../utils/notification/notification";
import { useSetLoading } from "../../../utils/hooks/loader";
import TooltipAndInfo from "../../../TooltipAndInfo";
import { copyListener } from "../../../utils/functions";
import RedirectToTributeStore from "../redirectToTributeStore";

const LifeOfSection = ({ data, opi, name }) => {
    const currentYear = new Date().getFullYear();
    const { loading: updateMedia, execute: executeMedia } = useAsync({
        autoExecute: false,
        task: useCallback(async (formData, type) => {
            return {
                res: await ObituaryAPI.uploadMedia(opi, formData, type),
            };
        }, []),
        dataLoader: useCallback(({ res }) => {
            addNotification({
                type: "success",
                title: "Success",
                message: "Data updated successfully",
            });
        }, []),
        onError: useCallback(error => {
            addNotification({
                type: "danger",
                title: "Error",
                message: _.get(
                    error,
                    "response.data.message",
                    "Oops! Something went wrong"
                ),
            });
        }, []),
    });

    const showLoader = useMemo(() => updateMedia, [updateMedia]);
    useSetLoading(showLoader);

    let description = _.get(data, "obituary");
    // description = _.replace(description, "<p>", "");
    // description = _.replace(description, "</p>", "");

    if (!description) {
        description = "Obituary information is not available.";
    }

    const shortDescription = description;
    const [isOpen, setIsOpen] = useState(false);
    const obituaryContent = useRef(null);
    const [showReadMoreButton, setShowReadMoreButton] = useState(false);

    const toggle = () => setIsOpen(!isOpen);
    const [showObituaryTooltip, setShowObituaryTooltip] = useState(false);
    const [showObituaryMobileTooltip, setMobileShowObituaryTooltip] = useState(false);
    const showHideToolTip = () => {
        setShowObituaryTooltip(!showObituaryTooltip);
    };

    const mobileShowHideToolTip = () => {
       setMobileShowObituaryTooltip(!showObituaryMobileTooltip)
    };

    useEffect(() => {
      setTimeout(() => {
        if (obituaryContent?.current) {
          if (obituaryContent.current.clientHeight > 275) {
            setShowReadMoreButton(true);
          } else {
            setShowReadMoreButton(false);
          }
        }
      }, 1);
    }, [description]);

    let topSection = () => {
        let childElem = (
            <div
                id='obituary-content-wrapper'
                ref={obituaryContent}
                style={{
                    textAlign: "left",
                    fontSize: "18pt",
                    fontFamily:'Georgia',
                    lineHeight: "27px",
                    color: "#212121",
                    wordBreak: "break-word",
                }}
                dangerouslySetInnerHTML={{
                    __html: isOpen ? description : shortDescription,
                }}
            />
        );

        let clsName = isOpen
            ? "profile-description-wrapper"
            : "profile-description-wrapper maxHeight275";

        return (
          <div>
            <div className={clsName}>{childElem}</div>
            {_.get(data, "obituary") && (
              <div className="copyRightStaticTextWrapper">
                <a
                  href="https://cypresslawn.com/cypress-lawn-cemetery-association/"
                  target="_blank"
                  rel="noreferrer"
                  className="copyRightText"
                >
                  {`© ${currentYear} Cypress Lawn Cemetery Association, All Rights Reserved.`}
                </a>
              </div>
            )}
          </div>
        );
    };

    useEffect(() => {
        document.addEventListener("copy", copyListener);
        return () => {
          document.removeEventListener("copy", copyListener);
        };
    }, []);

    const [deviceWidth, setDeviceWidth] = useState();

    useEffect(() => {
        const handleResize = () => {
            setDeviceWidth(window.innerWidth);
        };
        window.addEventListener("resize", handleResize);
        setDeviceWidth(window.innerWidth);
    }, []);
    
    return (
        <>
            {name && (
                <div>
                    <div className='obituary-header-family desktop-header obituary-section'>
                        <h2>The Obituary for {name} </h2>
                        <div className="d-flex">
                           {/* <div className="tribute-store-desktop-wrapper">
                                <RedirectToTributeStore opi={opi}/>
                            </div> */}
                            <TooltipAndInfo
                                id='obituary-tooltip-icon'
                                clsName='toopltip-space-top'
                                showTooltipFn={showHideToolTip}
                                showToolTip={showObituaryTooltip}
                                tooltipText='To edit any further information about your loved one please visit the family portal or contact your arranger.'
                            />
                        </div>
                    </div>
                    {
                    deviceWidth <= 567 &&
                    <div className='mobile-events-header'>
                         <div className="d-flex justify-content-between">
                            <h2>The Obituary for {name} </h2>   
                            <TooltipAndInfo
                                id='obituary-tooltip-icon-mobile'
                                clsName='toopltip-space-top'
                                showTooltipFn={mobileShowHideToolTip}
                                showToolTip={showObituaryMobileTooltip}
                                tooltipText='To edit any further information about your loved one please visit the family portal or contact your arranger.'
                            />
                        </div>
                        {/* <RedirectToTributeStore opi={opi}/>   */}
                    </div>
                }
                    <div
                        style={{
                            display: "flex",
                        }}
                    ></div>
                </div>
            )}
            <div>
                <div className='story-wrapper'>
                    {topSection()}
                    {showReadMoreButton && (
                        <div style={{ marginTop: 35 }}>
                            <div
                                className='description'
                                style={{ textAlign: "right" }}
                            >
                                <Link
                                    color='btn-theme theme-green'
                                    onClick={toggle}
                                    className='read-less-more'
                                >
                                    {isOpen ? "Read Less" : "Read More"}
                                </Link>
                            </div>
                        </div>
                    )}
                </div>

                {!!_.get(data, "audioFileUrl") && (
                    <div className='audio-wrapper d-flex justify-content-between'>
                        <div className='audio-file'>
                            <audio controls>
                                <source
                                    src={_.get(data, "audioFileUrl")}
                                ></source>
                            </audio>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default React.memo(LifeOfSection);
