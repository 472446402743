import React, { useCallback, useMemo } from "react";
import _ from "lodash";
import addNotification from "../../utils/notification/notification";
import { useSetLoading } from "../../utils/hooks/loader";
import useAsync from "react-use-async-hook";
import { getTributeLink } from "../../../api/trubutestoreLink";

const RedirectToTributeStore = ({ opi }) => {
  const {
    data: tributeStoreLink,
    loading: getTributeLinkLoader,
    execute: executeGetTributeLink,
  } = useAsync({
    autoExecute: false,
    task: getTributeLink,
    dataLoader: useCallback((res) => {
      window.open(_.get(res, "data.data"), "_blank");
      return _.get(res, "data");
    }, []),
    onError: useCallback((error) => {
      addNotification({
        type: "danger",
        title: "Error",
        message: _.get(
          error,
          "response.data.message",
          "Can't redirect to tribute store, Please try again!"
        ),
      });
    }, []),
  });

  const getRedirectionLink = (action) => {
    executeGetTributeLink(opi,action);
  };
  const showLoader = useMemo(
    () => getTributeLinkLoader,
    [getTributeLinkLoader]
  );
  useSetLoading(showLoader);

  return (
    <div className="btnWrapper">
      <button
        className="tribute-store-redirection-button mobileViewButton sendFlowerBtn"
        onClick={() => {
          getRedirectionLink('flower-click');
        }}
      >
        Send Flowers
      </button>
      <button
        className="tribute-store-redirection-button mobileViewButton plantTreeBtn"
        onClick={() => {
          getRedirectionLink('tree-click');
        }}
      >
        Plant a tree
      </button>
    </div>
  );
};

export default RedirectToTributeStore;
