import { apiFetch } from '../configs/apiConfig'
import { API_URL } from "../constants/appConstants"

export const getServiceData = (decedentId) => {
    return apiFetch({
        url: `${API_URL}/services/${decedentId}`,
        method: "GET"
    })
}

export const showServiceInterest = (serviceId, data) => {
    return apiFetch({
        url: `${API_URL}/services/${serviceId}/interest`,
        method: "POST",
        data
    })
}
