import React, { useCallback, useEffect, useMemo } from "react";
import { withRouter } from "react-router-dom";
import _ from "lodash";
import useAsync from "react-use-async-hook";

import * as AuthAPI from "../../api/auth";
import { useSetData } from "../utils/hooks/data";

import BannerContentStatic from "../utils/bannerContentStatic/bannerContentStatic";
import BtnBack from "../utils/btnBack/BtnBack";
import ScrollToTop from "../utils/hooks/scrollToTop";
import AcceptPolicies from "../acceptPolicies";
import LogoFooter from "../common/logoFooter/LogoFooter";


const PublicAppLayout = props => {
    const {
        showAcceptPolicy,
        isPublic,
        setLoader,
        session,
        isPagePrivate,
        isLoading = false,
        noMargin,
        showLogofooter,
        isFamilyAccess,
        userNotFound,
    } = props;

    useSetData(props);


    const opi =
        _.get(props, "match.params.opi") ||
        _.get(props, "match.params.onePortalId") ||
        "";

    return (
        <div className='main-content-wrapper'>
            <ScrollToTop />
            <div
                className='navigationCenter-content-wrapper'
                style={{ paddingBottom: noMargin ? 0 : "24px" }}
            >
                <div className='container d-block d-md-none'>
                    <BtnBack />
                </div>
                <BannerContentStatic
                    decedent={props.decedent}
                    userNotFound = {userNotFound}
                    opi={opi}
                    session={session}
                    isPagePrivate={isPagePrivate}
                    isLoading={isLoading}
                    isFamilyAccess={isFamilyAccess}
                />

                {props.children}
            </div>
            {showAcceptPolicy && isPublic && (
                <AcceptPolicies
                    policyStatus={!showAcceptPolicy}
                    isPublic={isPublic}
                    handleDecline={props.handleDecline}
                    setLoader={setLoader}
                />
            )}
            {showLogofooter && <LogoFooter isLoading={isLoading} />}
        </div>
    );
};

export default withRouter(PublicAppLayout);
