export const fontFamily = [
    "arial",
    "comicsans",
    "couriernew",
    "georgia",
    "helvetica",
    // "dancing-script",
    "lucida",
    "playball",
    "cinzel",
    "roboto",
    "robotoslab",
    "lato",
    "martel",
];

export const fontSize = [
    "fs12",
    "fs13",
    "small",
    "fs15",
    "smmedium",
    "fs17",
    "medium",
    "fs19",
    "fs20",
    "lgmedium",
    "fs22",
    "fs23",
    "large",
    "fs25",
    "fs26",
    "fs27",
    "fs28",
    "huge",
];

export const fontSizeMetric = {
    fs12: 12,
    fs13: 13,
    small: 14,
    fs15: 15,
    smmedium: 16,
    fs17: 17,
    medium: 18,
    fs19: 19,
    fs20: 20,
    lgmedium: 21,
    fs22: 22,
    fs23: 23,
    large: 24,
    fs25: 25,
    fs26: 26,
    fs27: 27,
    fs28: 28,
    huge: 32,
};

export const fontAlignment = ["left", "center", "right", "justify"];
