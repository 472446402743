import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

export default class AlertMessage extends Component {
    render() {
        const messageState = this.props.messageState;
        return (
            <Modal
                isOpen={this.props.toggleAlertState}
                toggle={this.props.toggleAlertModal}
                className="AlertMessage-wrapper"
                backdrop="static"
            >
                <form className="trust-form">
                    <ModalHeader>
                        <i
                            className="modal-close fas fa-times"
                            onClick={this.props.toggleAlertModal}
                        />
                        <span
                            className={
                                'modal-headername' +
                                (messageState
                                    ? ' theme-primary'
                                    : ' theme-error')
                            }
                        >
                            {this.props.title || 'Message'}
                            <i
                                className={
                                    'ml-2 fas' +
                                    (messageState
                                        ? ' fa-check-circle theme-primary'
                                        : ' fa-exclamation-circle theme-error')
                                }
                            />
                        </span>
                    </ModalHeader>
                    <ModalBody>
                        {!this.props.successMsg
                            ? 'Do You want to close this Alert, then click on the'
                            : this.props.successMsg}
                    </ModalBody>
                    <ModalFooter>
                        {this.props.showCancelButton ? (
                            <button
                                onClick={this.props.toggleAlertModal}
                                type="button"
                                className="btn-theme btn-cancel"
                            >
                                Cancel
                            </button>
                        ) : (
                            ''
                        )}
                        <button
                            onClick={() =>
                                !this.props.deleteState
                                    ? this.props.toggleAlertModal()
                                    : this.props.toggleOk()
                            }
                            type="button"
                            className="btn-theme"
                        >
                            {this.props.okButtonText || 'OK'}
                        </button>
                    </ModalFooter>
                </form>
            </Modal>
        );
    }
}
