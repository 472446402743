import { apiFetch } from '../configs/apiConfig';
import { API_URL } from '../constants/appConstants';

const getNewProgramCardTemplates = (offset = 1, limit = 5) => {
  return apiFetch({
    url: `${API_URL}/program-card/templates?limit=${limit}&offset=${offset}`,
    method: 'GET',
  });
};

const getNewProgramCard = (opi) => {
  return apiFetch({
    url: `${API_URL}/program-card/${opi}`,
    method: 'GET',
  });
};

const createNewProgramCard = (opi, data) => {
  return apiFetch({
    url: `${API_URL}/program-card/${opi}`,
    method: 'POST',
    data,
  });
};

const uploadNewProgramCard = (opi, data, onUploadProgress) => {
  return apiFetch({
    url: `${API_URL}/program-card/${opi}/upload`,
    method: 'PUT',
    data,
    onUploadProgress,
  });
};

const submitNewProgramCard = (opi) => {
  return apiFetch({
    url: `${API_URL}/program-card/${opi}/submit`,
    method: 'POST',
  });
};

export {
  getNewProgramCardTemplates,
  getNewProgramCard,
  createNewProgramCard,
  uploadNewProgramCard,
  submitNewProgramCard,
};
