import React, { useRef } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { copyListenerForEditor } from "../../utils/functions";
import { getPlainText } from "../../utils/functions";
const TinyMceEditor = ({
    changeEditorState,
    descriptionData,
    placeholder,
    height,
    width,
    name,
    setFieldValue,
    postEditorData,
    sizeLimit

}) => {
    const [data, setData] = React.useState(descriptionData);
    const [len, setLen] = React.useState(0);
    const handleUpdate = (e) => {
        const plainText = getPlainText(e || '');
        const val = e || '';
        const length = plainText?.length;
        setData(val);
        setLen(length);
        name === "shareMemory" && setFieldValue('memory', val)
        name !== "shareMemory" && changeEditorState(val)
    };
    const saveData = e => {
        if (postEditorData) {
            const val = e.target.getContent();
            handleUpdate(val);
            postEditorData();
        }
    };
    // const handleBeforeAddUndo = (evt, editor) => {
    //     const length = editor.getContent({ format: "text" }).length;
    //     // note that this is the opposite test as in handleUpdate
    //     // because we are determining when to deny adding an undo level
    //     if (length > sizeLimit) {
    //         evt.preventDefault();
    //         evt.stopPropagation();
    //         return false;
    //     }
    // };
    return (
        <>
            <div className='d-none d-sm-block'>
                <Editor
                    initialValue={descriptionData}
                    apiKey= {process.env.REACT_APP_TINY_MCE_API_KEY}
                    onEditorChange={(e) => handleUpdate(e)}
                    onBlur={e => saveData(e)}
                    init={{
                        height: height,
                        width: width,
                        force_br_newlines : true,
                        force_p_newlines : false,
                        forced_root_block : '',
                        placeholder: placeholder,
                        plugins: [
                            'advlist autolink lists link image charmap print preview anchor',
                            'searchreplace visualblocks code fullscreen',
                            'insertdatetime media table paste code help wordcount',
                            'nonbreaking'
                        ],
                        toolbar:
                            'undo redo | bold italic | alignleft aligncenter alignright | code',
                        branding: false,
                        mobile: {
                            menubar: true,
                        },
                        nonbreaking_force_tab: true,
                        content_style:
                            "body {font-size:18pt; font-family:Georgia}",
                        setup: (editor) => {
                            editor.on('init', () => {
                                editor.on('Copy', (e) => {
                                  copyListenerForEditor(e)
                                })
                            });
                        },
                        
                    }}
                />
            </div>
            <div className='d-block d-sm-none'>
                <Editor
                    initialValue={descriptionData}
                    apiKey= {process.env.REACT_APP_TINY_MCE_API_KEY}
                    onEditorChange={(e) => handleUpdate(e)}
                    onBlur={(e) => saveData(e)}
                    init={{
                        height: height,
                        width: width,
                        placeholder: placeholder,
                        branding: false,
                        force_br_newlines : true,
                        force_p_newlines : false,
                        forced_root_block : '',
                        mobile: {
                            menubar: false,
                            plugins: [
                                "advlist autolink lists link image charmap print preview anchor",
                                "searchreplace visualblocks code fullscreen",
                                "insertdatetime media table paste code help wordcount",
                                "nonbreaking",
                            ],
                            toolbar:
                                "undo redo | bold italic | fontsizeselect | fontselect | alignleft aligncenter alignright | code",
                        },
                        nonbreaking_force_tab: true,
                        content_style:
                            "body {font-size:18pt; font-family:Georgia}",
                        setup: (editor) => {
                            editor.on('init', () => {
                                editor.on('Copy', (e) => {
                                    copyListenerForEditor(e)
                                });
                            });
                        },
                        
                    }}
                />
            </div>
        </>
    );
};
export default TinyMceEditor;









