import { Col, Row } from 'reactstrap';

const ServiceCard = ({ item, onSelect }) => {
  return (
    <div className="service-card">
      <div className="service-card-header">{item?.name}</div>
      <div className="service-card-body">
        <Row sm={1} md={2}>
          <Col>
            <img
              className="service-card-img"
              src={
                item?.previewFrontImageUrl ||
                'https://deploy-preview-2356--reactstrap.netlify.app/assets/318x180.svg'
              }
              alt={`${item?.name} front page`}
            />
          </Col>
          <Col>
            <img
              className="service-card-img"
              src={
                item?.previewBackImageUrl ||
                'https://deploy-preview-2356--reactstrap.netlify.app/assets/318x180.svg'
              }
              alt={`${item?.name} back page`}
            />
          </Col>
        </Row>
        <button
          onClick={() => onSelect(item)}
          className="btn-theme theme-green-dark btn-customise"
        >
          Select
        </button>
      </div>
    </div>
  );
};

export default ServiceCard;
