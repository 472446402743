import React from "react";
import { Form } from "reactstrap";
import { Formik } from "formik";
import * as yup from "yup";

const ChangePassword = ({ executeUpdatePassword }) => {
    return (
        <Formik
            initialValues={{
                password: "",
                newPassword: "",
                confirmPassword: "",
            }}
            enableReinitialize={true}
            validationSchema={yup.object().shape({
                password: yup.string().required("Old Password is required"),
                newPassword: yup
                    .string()
                    .min(6, "Password has to be longer than 6 characters!")
                    .max(20, "Password has to be shorter than 20 characters!")
                    .required("New Password is required"),
                confirmPassword: yup
                    .string()
                    .required("Confirm Password is required")
                    .test(
                        "passwords-match",
                        "Passwords must match",
                        function (value) {
                            return this.parent.newPassword === value;
                        }
                    ),
            })}
            onSubmit={(values, formikBag) => {
                executeUpdatePassword(
                    {
                        password: values.password,
                        newPassword: values.newPassword,
                    },
                    formikBag
                );
            }}
        >
            {({
                values,
                errors,
                touched,
                handleSubmit,
                setFieldValue,
                setSubmitting,
                handleBlur,
                isSubmitting,
            }) => {
                const handleChange = e => {
                    const { name, value } = e.target;
                    setFieldValue(name, value);
                    setSubmitting(false);
                };
                return (
                    <Form
                        className='changepassword-form mt-4'
                        onSubmit={handleSubmit}
                    >
                        <div className='cardbox-wrapper'>
                            <div className='cardbox-heading'>
                                <h4 className='cardbox-heading-name text-capitalize text-black'>
                                    Change Password
                                </h4>
                            </div>
                            <div className='inputcard-wrapper cardbox-body'>
                                <div className='inputcard-body custom-inputcard-body'>
                                    <div className='inputcard-group d-flex align-items-center justify-content-start'>
                                        <div className='choose-reason inputfield d-flex align-items-center justify-content-between'>
                                            <div className='inputcard-withlabel d-flex align-items-start justify-content-center flex-column'>
                                                <label className='inputcard-label'>
                                                    Old Password
                                                </label>
                                                <input
                                                    className='custominputfield'
                                                    type='password'
                                                    name='password'
                                                    placeholder='**********'
                                                    onChange={handleChange}
                                                    value={values.password}
                                                    onBlur={handleBlur}
                                                />
                                                {errors.password &&
                                                touched.password ? (
                                                    <span
                                                        className='error-input-msg'
                                                        style={{ top: 120 }}
                                                    >
                                                        {errors.password}
                                                    </span>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className='choose-reason inputfield d-flex align-items-center justify-content-between'>
                                            <div className='inputcard-withlabel d-flex align-items-start justify-content-center flex-column'>
                                                <label className='inputcard-label'>
                                                    New Password
                                                </label>
                                                <input
                                                    className='custominputfield'
                                                    type='password'
                                                    name='newPassword'
                                                    placeholder='**********'
                                                    onChange={handleChange}
                                                    value={values.newPassword}
                                                    onBlur={handleBlur}
                                                />
                                                {errors.newPassword &&
                                                touched.newPassword ? (
                                                    <span
                                                        className='error-input-msg'
                                                        style={{ top: 120 }}
                                                    >
                                                        {errors.newPassword}
                                                    </span>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className='choose-reason inputfield d-flex align-items-center justify-content-between'>
                                            <div className='inputcard-withlabel d-flex align-items-start justify-content-center flex-column'>
                                                <label className='inputcard-label'>
                                                    Confirm Password
                                                </label>
                                                <input
                                                    className='custominputfield'
                                                    type='password'
                                                    name='confirmPassword'
                                                    placeholder='**********'
                                                    onChange={handleChange}
                                                    value={
                                                        values.confirmPassword
                                                    }
                                                    onBlur={handleBlur}
                                                />
                                                {errors.confirmPassword &&
                                                touched.confirmPassword ? (
                                                    <span
                                                        className='error-input-msg'
                                                        style={{ top: 120 }}
                                                    >
                                                        {errors.confirmPassword}
                                                    </span>
                                                ) : null}
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className='inputcard-footer'
                                        style={{
                                            justifyContent: "space-between",
                                            marginTop: 20,
                                        }}
                                    >
                                        <span>
                                            New password must be between 6 to 20
                                            characters.
                                        </span>
                                        <button
                                            type='submit'
                                            disabled={isSubmitting}
                                            className='btn-theme btn-search-wrapper text-uppercase'
                                        >
                                            SAVE
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default ChangePassword;
