import React, { useState, useEffect } from "react";
import _ from "lodash";
import { Collapse } from "reactstrap";

const DisplayDonationUrl = ({ decedentData }) => {
  const [urlsList, setUrlsList] = useState([]);
  const [isOpen, setIsOpen] = useState(true);
  const toggleCollapseButton = () => {
    setIsOpen(!isOpen);
  };
  useEffect(() => {
    if (_.get(decedentData, "donationDetails")?.length >= 1) {
      setUrlsList(_.get(decedentData, "donationDetails"));
    }
  }, [decedentData]);

  return (
    <div className="dashboard-card-customUrlInfo">
      <div className="previewUrlWrapper">
        <div
          className="d-flex justify-content-between"
          onClick={toggleCollapseButton}
        >
          <p
            className="previewHeading"
            style={{ paddingBottom: isOpen ? "0px" : "10px" }}
          >
            Donate / Charity
          </p>
          <i
            class={`fas ${
              isOpen ? "fa-chevron-up" : "fa-chevron-down"
            } collapseIcon donationCollapse`}
          ></i>
        </div>
        <Collapse isOpen={isOpen}>
          <div className="previewDescription">
            <p className="description">
              {_.get(
                decedentData?.donationDetails?.[0],
                "donationUrlDescription"
              )}
            </p>
            {urlsList?.map((item, i) => {
              return (
                <a
                  href={_.get(item, "donationUrlSrc")}
                  target="_blank"
                  rel="noreferrer"
                  className="urlPreview"
                >
                  {_.get(item, "donationUrlName")}
                </a>
              );
            })}
          </div>
        </Collapse>
      </div>
    </div>
  );
};

export default DisplayDonationUrl;
