import React from "react";
import { Form } from "reactstrap";
import { Formik } from "formik";
import _ from "lodash";
import * as yup from "yup";
import SelectComponent from "../../../utils/SelectComponent/SelectComponent";

const PersonalDetails = ({ userInfo, executeSaveUserDetail }) => {
    const options = [
        {
            value: "Mr",
            label: "Mr",
        },
        {
            value: "Mrs",
            label: "Mrs",
        },
        {
            value: "Ms",
            label: "Ms",
        },
    ];

    const getInitalOption = val => {
        const optionVal = options.find(option => {
            return option.value === val;
        });

        return optionVal || "";
    };

    return (
        <Formik
            initialValues={{
                firstName: _.get(userInfo, "firstName", ""),
                lastName: _.get(userInfo, "lastName", ""),
                middleName: _.get(userInfo, "middleName", ""),
                title: getInitalOption(_.get(userInfo, "title", "")),
            }}
            enableReinitialize={true}
            validationSchema={yup.object().shape({
                firstName: yup.string().required("Firstname is required"),
                lastName: yup.string().required("Lastname is required"),
            })}
            onSubmit={(values, formikBag) => {
                executeSaveUserDetail(
                    {
                        firstName: values.firstName,
                        lastName: values.lastName,
                        middleName: values.middleName,
                        title: values.title.value,
                    },
                    formikBag
                );
            }}
        >
            {({
                values,
                errors,
                touched,
                handleSubmit,
                setFieldValue,
                setSubmitting,
                handleBlur,
                isSubmitting,
            }) => {
                const handleChange = e => {
                    const { name, value } = e.target;
                    setFieldValue(name, value);
                    setSubmitting(false);
                };

                return (
                    <Form
                        className='personaldetails-form'
                        onSubmit={handleSubmit}
                    >
                        <div className='cardbox-wrapper'>
                            <div className='cardbox-heading'>
                                <h4 className='cardbox-heading-name text-capitalize text-black'>
                                    Personal Details
                                </h4>
                            </div>
                            <div className='inputcard-wrapper cardbox-body pb-5'>
                                <div className='inputcard-body custom-inputcard-body'>
                                    <div className='inputcard-group d-flex align-items-center justify-content-start'>
                                        <div className='choose-reason inputfield d-flex align-items-center justify-content-between'>
                                            <div className='inputcard-withlabel d-flex align-items-start justify-content-center flex-column '>
                                                <label className='inputcard-label'>
                                                    Prefix
                                                </label>
                                                <SelectComponent
                                                    placeholder='Select Title'
                                                    selectedOption={_.get(
                                                        values,
                                                        "title"
                                                    )}
                                                    defaultValue={_.get(
                                                        values,
                                                        "title"
                                                    )}
                                                    options={options}
                                                    handleChange={e => {
                                                        setFieldValue(
                                                            "title",
                                                            e
                                                        );
                                                        setSubmitting(false);
                                                    }}
                                                    onBlur={handleBlur}
                                                    name='title'
                                                />
                                            </div>
                                        </div>
                                        <div className='choose-reason inputfield d-flex align-items-center justify-content-between'>
                                            <div className='inputcard-withlabel d-flex align-items-start justify-content-center flex-column'>
                                                <label className='inputcard-label'>
                                                    First Name
                                                    <small className='ml-2 input-mandatory'>
                                                        *
                                                    </small>
                                                </label>
                                                <input
                                                    id='firstName'
                                                    name='firstName'
                                                    className='custominputfield'
                                                    type='text'
                                                    placeholder='Enter First Name'
                                                    onChange={handleChange}
                                                    value={values.firstName}
                                                    onBlur={handleBlur}
                                                />
                                                {errors.firstName &&
                                                touched.firstName ? (
                                                    <span className='error-input-msg mt-md-3'>
                                                        {errors.firstName}
                                                    </span>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className='choose-reason inputfield d-flex align-items-center justify-content-between'>
                                            <div className='inputcard-withlabel d-flex align-items-start justify-content-center flex-column'>
                                                <label className='inputcard-label'>
                                                    Middle Name
                                                </label>
                                                <input
                                                    id='middleName'
                                                    className='custominputfield'
                                                    type='text'
                                                    placeholder='Enter Middle Name'
                                                    onChange={handleChange}
                                                    value={values.middleName}
                                                    onBlur={handleBlur}
                                                    name='middleName'
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='inputcard-group d-flex align-items-center justify-content-start'>
                                        <div className='choose-reason inputfield d-flex align-items-center justify-content-between'>
                                            <div className='inputcard-withlabel d-flex align-items-start justify-content-center flex-column'>
                                                <label className='inputcard-label'>
                                                    Last Name
                                                    <small className='ml-2 input-mandatory'>
                                                        *
                                                    </small>
                                                </label>
                                                <input
                                                    id='lastName'
                                                    className='custominputfield'
                                                    type='text'
                                                    placeholder='Enter Last Name'
                                                    onChange={handleChange}
                                                    value={values.lastName}
                                                    onBlur={handleBlur}
                                                    name='lastName'
                                                />
                                                {errors.lastName &&
                                                touched.lastName ? (
                                                    <span className='error-input-msg mt-md-3'>
                                                        {errors.lastName}
                                                    </span>
                                                ) : null}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='inputcard-footer'>
                                    <button
                                        type='submit'
                                        disabled={isSubmitting}
                                        className='btn-theme btn-search-wrapper text-uppercase'
                                    >
                                        SAVE
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default PersonalDetails;
