import React from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'

import TemplateCarousel from './templateCarousel'

const PreviewModal = ({ isOpen, toggle, pages, template, url }) => {
    return (
        <Modal isOpen={isOpen} toggle={toggle} className="modal-template-slide">
            <ModalHeader toggle={toggle}></ModalHeader>
            <ModalBody>
                <TemplateCarousel pages={pages} template={template} url={url} />
            </ModalBody>
        </Modal>
    )
}

export default PreviewModal