import React from "react";
import SSOLogo from "../../../assets/images/SSO-Logo.jpg";
import CypressNewLogo from "../../../assets/images/Cypress-Lawn Modern-Logo.png";
import MillerLogo from "../../../assets/images/MillerDutra-Logo.jpg";
import CrosbyLogo from "../../../assets/images/Crosby-Gray-Logo.jpg";

const LogoFooter = ({ isLoading = false }) => {
    const currentYear = new Date().getFullYear();
    return (
        !isLoading && (
            <footer className='footer' role='contentinfo'>
                <div className='upper-footer'>
                    {/*<h3 className='upper-footer__text'>
                        Cypress Lawn Family of Funeral Homes
        </h3>*/}

                    <div className='upper-footer__logos'>
                        <div className='upper-footer__logos-container'>
                            <a
                                href='https://www.cypresslawn.com/'
                                target='_blank'
                                rel='noreferrer'
                            >
                                <img
                                    className='upper-footer__logo'
                                    src={CypressNewLogo}
                                    alt=''
                                />
                            </a>

                            {/*<p className='upper-footer__logo-phone-number'>
                                <i className='fa fa-phone-alt'></i>
                                <a href='tel:(650) 755-0580'>(650) 755-0580</a>
    </p>*/}
                        </div>
                        <div className='upper-footer__logos-container'>
                            <a
                                href='https://www.cypresslawn.com/sneider-sullivan-oconnells-funeral-home-and-cremation-service/'
                                target='_blank'
                                rel='noreferrer'
                            >
                                <img
                                    className='upper-footer__logo'
                                    src={SSOLogo}
                                    alt=''
                                />
                            </a>

                            {/*  <p className='upper-footer__logo-phone-number'>
                                <i className='fa fa-phone-alt'></i>
                                <a href='tel:(650) 343-1804'>(650) 343-1804</a>
                            </p>*/}
                        </div>
                        <div className='upper-footer__logos-container'>
                            <a
                                href='https://www.cypresslawn.com/crosby-n-gray-co-funeral-home-and-cremation-service/'
                                target='_blank'
                                rel='noreferrer'
                            >
                                <img
                                    className='upper-footer__logo'
                                    src={CrosbyLogo}
                                    alt=''
                                />
                            </a>

                            {/* <p className='upper-footer__logo-phone-number'>
                                <i className='fa fa-phone-alt'></i>
                                <a href='tel:(650) 342-6617'>(650) 342-6617</a>
                            </p>*/}
                        </div>
                        <div className='upper-footer__logos-container'>
                            <a
                                href='https://www.cypresslawn.com/miller-dutra-coastside-chapel/'
                                target='_blank'
                                rel='noreferrer'
                            >
                                <img
                                    className='upper-footer__logo'
                                    src={MillerLogo}
                                    alt=''
                                />
                            </a>

                            {/*  <p className='upper-footer__logo-phone-number'>
                                <i className='fa fa-phone-alt'></i>
                                <a href='tel:(650) 726-4474'>(650) 726-4474</a>
                            </p>*/}
                        </div>
                    </div>
                    <div className="copyRightWrapper">
                        <a href='https://cypresslawn.com/cypress-lawn-cemetery-association/' target="_blank" rel="noreferrer" className="copyRightLink">
                            {`© ${currentYear} Cypress Lawn Cemetery Association, All Rights Reserved.`}
                        </a>
                    </div>
                </div>
            </footer>
        )
    );
};

export default React.memo(LogoFooter);
