import React, { useState, useEffect, useCallback, useMemo } from "react";
import _ from "lodash";
import moment from "moment";
import { Col, Row, TabContent, TabPane } from "reactstrap";
import * as htmlToImage from "html-to-image";
import useAsync from "react-use-async-hook";

import { useSetLoading } from "../../utils/hooks/loader";
import addNotification from "../../utils/notification/notification";
import * as PrayerCardAPI from "../../../api/prayerCard";

import CustomizeTemplate from "./customizeTemplate";
import PreviewTemplate from "./previewTemplate";
import { simplifyTemplate } from "../../utils/Template/templateUtils";
import PreviewModal from "./previewModal";
import PreviewCardModal from "./previewCardModal";
import ImageGrid from "./imageGrid";
import printStyle from "./print-css";
import printCustomStyle from "./print-css-custom";

import useGAEventTracker from "../../utils/hooks/GAEventsTracker";
import checkMark from "../../../assets/images/download-transparent-check-mark-gif-11562855141yrviuuu1dd-removebg-preview.png";
import { formatDefaultNameAndDates } from "../../../utils/helper";
import {
    prayerCardFuneralLocations,
} from "../../utils/variables";
import SelectPoem from "./selectPoem";
import SelectFooter from './selectLocation'
import Loader from "../../utils/loader/loader";
import {
    checkIsHTML,
} from "./templateString";

const CustomizeCard = ({ decedent, ...props }) => {
    const [activeTab, setActiveTab] = useState(2);
    const [pages, setPage] = useState([]);
    const [isPrayerCardEditable, setPrayerCardEditable] = useState(false);
    const [allContent, setAllContent] = useState('')
    const [locationCity, setLocationCity] = useState("");
    const [selectedLocation, setSelectedLocation] = useState('');
    const [isCancel, setIsCancel] = useState(false)
    const [previousContent,setPreviousContent] = useState('')

    const {
        template,
        isLocked,
        saveTemplate,
        onePortalId,
        role,
        selectTemplate,
        loadingStatus,
        executeGetPrayerCard,
    } = props;

    useEffect(() => {
        if (template && decedent) {
            let pages = simplifyTemplate(template);
            let updatedPages = [...pages];

            const decedentInfo = _.get(decedent, "decedent", {});
            const { defaultNameWithStyle, defaultDatesWithStyle, defaultHeading } =
                formatDefaultNameAndDates(decedentInfo);

            const textFields = updatedPages[1].textFields;
            const updatedTextFields = textFields.map(textField => {
                const { priority = "", type = "", text = "" } = textField;
                if(priority == "1" && type == "heading1") {
                    if(text === 'In Loving Memory of')
                    {
                        textField.text = defaultHeading;
                    }
                }
                if (priority == "2" && type == "textarea") {
                    if (!text) {
                        textField.text = defaultNameWithStyle;
                    }
                    return textField;
                }
                if (priority == "3" && type == "heading2") {
                    if (!text) {
                        textField.text = defaultDatesWithStyle;
                    }
                    return textField;
                }
                return textField;
            });

            const newUpdatedPage = { ...updatedPages[1] };
            newUpdatedPage.textFields = { ...updatedTextFields };
            updatedPages[1] = newUpdatedPage;
            setPage(updatedPages);
        }
    }, [template, decedent]);

    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    /**
     * Show Preview
     */
    const [slidemodal, setSlideModal] = useState(false);
    const slideToggle = () => setSlideModal(!slidemodal);

    const [previewCardModal, setPreviewCardModal] = useState(false);
    const togglePreviewCard = async () => {
        if (!previewCardModal) {
            await handlePreparePrintOrPreview();
        }
        setLoading(false);
        setPreviewCardModal(!previewCardModal);
    };

    const [isLoading, setLoading] = useState(false);
    const [pageLoader, setPageLoader] = useState(false)

    /**
     * Submit Template
     */
    const { loading: submitTemplateLoader, execute: executeSubmitTemplate } =
        useAsync({
            autoExecute: false,
            task: useCallback((onePortalId, payload) => {
                return PrayerCardAPI.submitPrayerCard(onePortalId, payload);
            }, []),
            dataLoader: useCallback(res => {
                addNotification({
                    type: "success",
                    title: "Success",
                    message:
                        "Prayer Card Submitted Successfully To The Arranger",
                });
                return _.get(res, "data.data", []);
            }, []),
            onError: useCallback(error => {
                addNotification({
                    type: "danger",
                    title: "Error",
                    message: _.get(
                        error,
                        "response.data.message",
                        "Oops! Something went wrong"
                    ),
                });
            }, []),
        });

     /**
     * Get Poem List
     */
      const {
        data: poems,
        loading: getPoemLoader,
        execute: executeGetPoem,
    } = useAsync({
        autoExecute: false,
        task: PrayerCardAPI.getPoems,
        dataLoader: useCallback(res => {
            return _.get(res, "data.data", []);
        }, []),
    });

    useEffect(() => {
        executeGetPoem();
    }, []);

    const showLoader = useMemo(
        () => isLoading || submitTemplateLoader || getPoemLoader,
        [isLoading, submitTemplateLoader, getPoemLoader]
    );
    useSetLoading(showLoader);

    const GAEventsTracker = useGAEventTracker("PrayerCard Event");

    const handleTemplateSubmit = async () => {
        setLoading(true);
        let formData = new FormData();
        const activeIndex = activeTab;
        let i = 0;
        for await (let page of pages) {
            setActiveTab(i + 1); // stopping switching of pages while submit
            const element = document.getElementById(`template-div-${i}`);
            let dataUrl = await htmlToImage.toBlob(element, {
                height: i === 1 && 775
            });

            let file = new File([dataUrl], `template_${i}.png`, {
                type: "image/png",
            });
            formData.append("files", file);
            i++;
        }
        executeSubmitTemplate(onePortalId, formData);
        setActiveTab(activeIndex);
        setLoading(false);

        GAEventsTracker("submit");
    };

    /**
     * Print Template
     */
    const [pageURL, setPageURL] = useState([]);
    const [previewImageUrl, setPreviewImageUrl] = useState("");
    //const [newTemplate, setNewTemplate] = useState(template);
    const [positions, setPositions] = useState(template?.coordinates);

    const handleSavePositions = () => {
        let positionsTemplate = { ...template, coordinates: positions }
        saveTemplate(onePortalId, positionsTemplate)
        setPreviousContent(_.get(positionsTemplate, 'page.finalContent'))
        
    }

    const handlePreviewImage = async () => {
        setPageLoader(true)
        const element = document.getElementById(`template-div-1`);
        let imageUrl = ""
        let dataUrl = await htmlToImage?.toJpeg(element, {
            backgroundColor: "white",
            pixelRatio: 1,
        });
        imageUrl = dataUrl
        setPageLoader(false)
        setPreviewImageUrl(imageUrl)
    }

    const handlePreparePrint = async (isCustom) => {
        await handlePreparePrintOrPreview(isCustom,true);
        handlePrint(isCustom);
    };

    const handlePreparePrintOrPreview = async (isCustom,isPrint=false) => {
        setLoading(true);
        const activeIndex = activeTab;
        let i = 0;
        let url = [];
        for await (let page of pages) {
            setActiveTab(i + 1);
            let dataUrl = '';
            if( (i===0 && (isCustom || !isPrint)) || i === 1) {
                const element = document.getElementById(`template-div-${i}`);
                dataUrl = await htmlToImage.toJpeg(element, {
                backgroundColor: "white",
                pixelRatio: 1,
                height: i === 1 && 775
            });
            }
            // let url = pageURL;
            url[i] = dataUrl;
            i++;
        }
        setPageURL(url);
        setActiveTab(activeIndex);
    };

    const handlePrint = async (isCustom) => {
        const content = document.getElementById("print-div");
        const iframeWindow =
            document.getElementById("ifmcontentstoprint").contentWindow;
        iframeWindow.document.open();
        iframeWindow.document.write(`${content.innerHTML}`);
        iframeWindow.document.close();

        const style = document.createElement("style");
        if (isCustom) {
            style.innerHTML = _.get(printCustomStyle, "css");
        } else {
        style.innerHTML = _.get(printStyle, "css");
        }
        iframeWindow.document.head.appendChild(style);
        setLoading(false);
    };

    const [isError, setErrorMsg] = useState(false);
    const [selectedPoem, setSelectedPoem] = useState(
        _.get(template, "page.poem")
    );

    const handleSaveTemplate = payload => {
        const payloadTemplate = {
            ...template,
            templateId: template.templateId,
            page: {
                ...template.page,
                textFields: payload.textFields,
                poem: payload.poem,
                poemTitle: payload?.poem?.title,
                poemContent: payload?.poem?.content,
                finalContent: payload?.finalContent,
            },
        };
        selectTemplate(payloadTemplate)
    };
    const getHtmlFromString = (oldString) => {
        var parser = new DOMParser();
        var doc = parser.parseFromString(oldString, "text/html");
        return doc;
    }

    const checkChildNodesHadContentOrNot = ((childNodes) => {
        const lengthOfNodes = childNodes?.length;
        let count = 0
        childNodes?.forEach((ele, i) => {
            if ((ele.innerText === '' || ele.innerText === "" || ele.innerText === undefined) && ele.nodeName !== 'BR') {
                count = count + 1
            }
        })
        return count === lengthOfNodes;
    })

    const handleSelect = (value, key, style) => {
      let { page } = template;
      let { textFields, poem } = page;
      let updatedHtmlString; 
      const tagList = ['BR', '#text']  
        if (key === "footer") {
          const doc = getHtmlFromString(allContent);
          let footerElements = doc?.querySelectorAll(".footerLocation");
          let cityElements = doc?.querySelectorAll(".cityName");
          if (footerElements?.length) {
            let isFooterSet = false;
            footerElements?.forEach((element, elementIndex) => {
              const childNodesOfElement = element?.childNodes;
              //check each child node of p tag with footerLocation class
              //if it has child as br tag then don't assign location to that tag
              if (childNodesOfElement?.length > 0) {
                childNodesOfElement?.forEach((childNode, i) => {
                  if (
                    !(childNodesOfElement[i]?.nodeName === "BR") &&
                    !_.includes(
                      childNodesOfElement[i].classList,
                      "ql-custom-newLine"
                    )
                  ) {
                      if (!isFooterSet) 
                      {
                          if (childNodesOfElement[i].innerHTML) childNodesOfElement[i].innerHTML = _.get(value, "value");
                          else if (childNodesOfElement[i]?.nodeType === Node.TEXT_NODE) childNodesOfElement[i].textContent = _.get(value, "value");

                      }
                      else {
                          if (childNodesOfElement[i].innerHTML) childNodesOfElement[i].innerHTML = ''
                          else if (childNodesOfElement[i]?.nodeType === Node.TEXT_NODE) childNodesOfElement[i].textContent = '';
                      }
                      isFooterSet = true;
                  }
                });
              }
              //their is only one p tag with footerLocation and that had some text or br as a child then assign 
              //location to that p tag 
              if (
                childNodesOfElement?.length === 1 &&
                (childNodesOfElement[0]?.nodeName === "BR" ||
                  childNodesOfElement[0]?.nodeName === "#text") && !isFooterSet
              ) {
                const newEle = `<p class=\"footerLocation ql-align-center ql-size-large ql-font-playball\">${value?.value}</p>`;
                if (!isFooterSet) footerElements[elementIndex].outerHTML = newEle;
                else footerElements[elementIndex].remove()
                isFooterSet = true;
              }
              //If only one p tag with 0 child's then assign new element with style and data to that p tag
              if (childNodesOfElement?.length === 0 && !isFooterSet) {
                const newEle = `<p class=\"footerLocation ql-align-center ql-size-large ql-font-playball\">${value?.value}</p>`;
                footerElements[elementIndex].outerHTML = newEle;
                isFooterSet = true;
              }
            });
          }
          setSelectedLocation(_.get(value, "value"));
          let cityName = prayerCardFuneralLocations[_.get(value, "value")];
          if (cityElements?.length) {
            let isCityAdded = false;
            cityElements?.forEach((element, elementIndex) => {
              const childNodesOfElement = element?.childNodes;
              if (childNodesOfElement?.length > 0 ) {
                childNodesOfElement?.forEach((childNode, i) => {
                  if (
                    !(childNodesOfElement[i]?.nodeName === "BR") &&
                    !_.includes(
                      childNodesOfElement[i].classList,
                      "ql-custom-newLine"
                    )
                  ) {
                    //Check If that child node had any other child's Like <em> <u>
                      if (!isCityAdded)
                      {
                          if (childNodesOfElement[i].innerHTML) childNodesOfElement[i].innerHTML = cityName;
                          else if (childNodesOfElement[i]?.nodeType === Node.TEXT_NODE) childNodesOfElement[i].textContent = cityName;

                      }
                      else {
                        if (childNodesOfElement[i].innerHTML) childNodesOfElement[i].innerHTML = ''
                        else if (childNodesOfElement[i]?.nodeType === Node.TEXT_NODE) childNodesOfElement[i].textContent = '';
                    }
                    isCityAdded = true;
                  }
                });
              }
              if (
                childNodesOfElement?.length === 1 &&
                (childNodesOfElement[0]?.nodeName === "BR" ||
                  childNodesOfElement[0]?.nodeName === "#text") && !isCityAdded
              ) {
                const newEle = `<p class=\"cityName ql-align-center ql-size-large ql-font-playball\">${cityName}</p>`;
                if (!isCityAdded) cityElements[elementIndex].outerHTML = newEle;
                else cityElements[elementIndex].remove()
                isCityAdded = true;
              }
              if (childNodesOfElement?.length === 0 && !isCityAdded) {
                const newEle = `<p class=\"cityName ql-align-center ql-size-large ql-font-playball\">${cityName}</p>`;
                cityElements[elementIndex].outerHTML = newEle;
                isCityAdded = true;
              }
            });
          }
          let newStr = "";
          for (let i = 0; i < doc?.body?.childNodes?.length; i++) {
            newStr += doc?.body?.childNodes[i]?.outerHTML;
          }
          updatedHtmlString = newStr;
          setAllContent(updatedHtmlString);
        } else if (key === "poem") {
          const doc = getHtmlFromString(allContent);
          var contentElements = doc.querySelectorAll(".content");
          var listOfContentElements = doc.querySelectorAll(".content");
          var listOfTitleElements = doc.querySelectorAll(".poemTitle");
          var titleElements = doc.querySelectorAll(".poemTitle");
          //Thi if block for if already poem content is in html format for old data
          if (
            listOfContentElements?.length &&
            checkIsHTML(_.get(template, "page.poemContent"))
          ) {
              listOfContentElements?.forEach((ele, i) => {
              //Assign new element with poem content and style to first p tag
              if (i === 0) {
                const newEle = `<p class="\content ql-align-center\"><span class="\ql-size-fs26 ql-font-playball\">${value?.content}</span></p>`;
                if(ele.outerHTML) ele.outerHTML = newEle
                else ele.innerHTML = newEle;
              } else {
                //we should not delete if last 2 p tags had br to maintain space between poem content and footer location
                //this is to remove all other p tags expect first one while selecting new poem
                if (
                  !(
                    (i === listOfContentElements?.length - 2 ||
                      i === listOfContentElements?.length - 1) &&
                    ele.childNodes[0]?.nodeName === "BR"
                  )
                ) {
                  if (ele.outerHTML) ele.outerHTML = "";
                  else ele.outerHTML = "";
                }
                 
              }
            });
          }
          //If block for normal flow where poemContent saved in text format
          if (
            contentElements?.length &&
            !checkIsHTML(_.get(template, "page.poemContent"))
          ) {
            let isContentSet = false;
            contentElements?.forEach((element, elementIndex) => {
                const childNodesOfElement = element?.childNodes;
                //Assign poem content to p tag which has child node not an br or text and which had some data
                if (childNodesOfElement?.length > 0) {
                    childNodesOfElement?.forEach((childNode,i) => {
                        if (
                            !(
                                childNodesOfElement[i]?.nodeName === "BR" ||
                                childNodesOfElement[i]?.nodeName === "#text"
                            )
                        ) {
                          if (!isContentSet) childNodesOfElement[i].innerHTML = value?.content;
                          else childNodesOfElement[i].innerHTML = ''
                          isContentSet = true;
                      }
                    })
              }
              //If we had only one p tag and that had child node br or text then assign poem content to data p tag
              if (
                childNodesOfElement?.length === 1 &&
                (childNodesOfElement[0]?.nodeName === "BR" ||
                  childNodesOfElement[0]?.nodeName === "#text")
              ) {
                  const newEle = `<p class="\content ql-align-center\"><span class="\ql-size-fs26 ql-font-playball\">${value?.content}</span></p>`;
                  if (!isContentSet)
                    contentElements[elementIndex].outerHTML = newEle;
                  //We need to keep last two p tags if they had  br to maintain the space between poem content and footer location
                  else {
                    if (
                      childNodesOfElement[0]?.nodeName === "BR" &&
                      (elementIndex === contentElements?.length - 1 ||
                        elementIndex === contentElements?.length - 2)
                    ) {
                      contentElements[elementIndex].innerHTML = "<br>";
                    }
                  else {
                     contentElements[elementIndex].remove()
                   }
                  }
                  isContentSet = true;
              }
              //P tag with no child's
                if (childNodesOfElement?.length === 0) {                  const newEle = `<p class="\content ql-align-center\"><span class="\ql-size-fs26 ql-font-playball\">${value?.content}</span></p>`;
                  if (!isContentSet) contentElements[elementIndex].outerHTML = newEle;
                  else contentElements[elementIndex].outerHTML =''
                 isContentSet = true;
              }
            });
          }
          if (
            listOfTitleElements?.length &&
            checkIsHTML(_.get(template, "page.poemTitle"))
          ) {
              listOfTitleElements?.forEach((ele, i) => {
                if (i === 0) {
                  const newEle = `<p class="\poemTitle ql-align-center\"><span class="\ql-size-fs26 ql-font-playball\">${value?.title}</span></p>`;
                  if (ele.outerHTML) ele.outerHTML = newEle
                  ele.innerHTML = newEle
                } else {
                  //We should keep last p tag with br to maintain space between poem title and content
                  if (
                    !(
                      i === listOfTitleElements?.length - 1 &&
                      ele.childNodes[0]?.nodeName === "BR"
                    )
                  ) {
                    if (ele.outerHTML) ele.outerHTML = "";
                    ele.innerHTML = "";
                  }
                }
            });
          }
          if (
            titleElements?.length &&
            !checkIsHTML(_.get(template, "page.poemTitle"))
          ) {
            let isTitleAdded = false;
            titleElements?.forEach((element, elementIndex) => {
              const childNodesOfElement = element?.childNodes;
              if (childNodesOfElement?.length > 0) {
                childNodesOfElement?.forEach((childNode, i) => {
                  if (
                    !(
                      childNodesOfElement[i]?.nodeName === "BR" ||
                      childNodesOfElement[i]?.nodeName === "#text"
                    )
                  ) {
                    //Check If that child node had any other child's Like <em> <u>
                    if (!isTitleAdded) childNodesOfElement[i].innerHTML = value?.title;
                    else childNodesOfElement[i].innerHTML = ''
                    isTitleAdded = true;
                  }
                });
              }
              if (
                childNodesOfElement?.length === 1 &&
                (childNodesOfElement[0]?.nodeName === "BR" ||
                  childNodesOfElement[0]?.nodeName === "#text")
              ) {
                const newEle = `<p class="\poemTitle ql-align-center\"><span class="\ql-size-fs26 ql-font-playball\">${value?.title}</span></p>`;
                if (!isTitleAdded)
                titleElements[elementIndex].outerHTML = newEle;
                //If we had last p tag with br then we need to keep that p tag to maintain space between title and poem
                else {
                  if (
                    childNodesOfElement[0]?.nodeName === "BR" &&
                    elementIndex === titleElements?.length - 1
                  ) {
                    titleElements[elementIndex].innerHTML = "<br>";
                  } else {
                    titleElements[elementIndex].innerHTML = "";
                  }
                }    
                isTitleAdded = true;
              }
              if (
                childNodesOfElement?.length === 0 
              ) {
                const newEle = `<p class="\poemTitle ql-align-center\"><span class="\ql-size-fs26 ql-font-playball\">${value?.title}</span></p>`;
                if (!isTitleAdded) titleElements[elementIndex].outerHTML = newEle;
                else titleElements[elementIndex].outerHTML = ''
                isTitleAdded = true;
              }
            });
          }
          let newStr = "";
          for (let i = 0; i < doc?.body?.childNodes?.length; i++) {
              const myNode = doc?.body?.childNodes[i]
              //update new string if poem title or poem content had some text or br 
              if (!((_.includes(myNode.classList, 'content') || _.includes(myNode.classList, 'poemTitle')) && checkChildNodesHadContentOrNot(myNode?.childNodes))) {
                newStr += doc?.body?.childNodes[i]?.outerHTML;
              }
          }
          updatedHtmlString = newStr;
          setAllContent(updatedHtmlString);
          poem = {
            ...poem,
            title: _.get(value, "title"),
            content: _.get(value, "content"),
          };
        }
      handleSaveTemplate({ textFields, poem, finalContent: updatedHtmlString });
    };

    return (
        <div className='revamp-prayer-card'>
            {pageLoader ? <Loader showLoader={pageLoader}/> : ""}
            <div className='prayer-card-creation-steps'>
                <li>
                    <span style={{ fontWeight: "600" }}>STEP 1. </span> Choose a
                    prayer card from one of our available selections or upload
                    your own photo
                    <button
                        className='action-btn-link'
                        disabled={isLocked}
                        onClick={() => {
                            selectTemplate(null);
                        }}
                    >
                        Change Prayer Card
                    </button>
                    <span style={{ marginLeft: 20 }}>
                        <img
                            src={checkMark}
                            alt='Check'
                            style={{ height: 20 }}
                        />
                    </span>
                </li>
                <li>
                    <span style={{ fontWeight: "600" }}>STEP 2. </span> Please
                    select or enter a poem for your prayer card
                    {_.get(decedent, "prayerStatus") && (
                        <img
                            src={checkMark}
                            alt='Check'
                            style={{ height: 20 }}
                        />
                    )}
                </li>

                <div className='buttons-container d-flex justify-content-end w-100'>
                    {/*  <button
                className='btn-theme theme-green-dark'
                disabled={isLocked}
                onClick={() => {
                    selectTemplate(null);
                }}
            >
                View Template
            </button> */}
                    {role === "admin" && (
                        <button
                            className='btn-theme theme-green-dark ml-4'
                            onClick={togglePreviewCard}
                        >
                            Preview Card
                        </button>
                    )}
                    {role !== "admin" && (
                        <button
                            className='btn-theme theme-green-dark ml-4'
                            onClick={slideToggle}
                        >
                            Preview Card
                        </button>
                    )}
                    <button
                        onClick={() => handleTemplateSubmit()}
                        disabled={isLocked || isError || isPrayerCardEditable}
                        className='btn-theme theme-green-dark ml-4'
                    >
                        Submit to Arranger
                    </button>
                    {role === "admin" && (
                        <button
                            onClick={() => handlePreparePrint(_.get(template, "isCustom"))}
                            disabled={ isLocked || isError || isPrayerCardEditable }
                            className='btn-theme theme-green-dark ml-4'
                        >
                            Print
                        </button>
                    )}
                </div>
            </div>
            {isLocked && <p className="locked-text">This section is now locked. Please contact your arranger for changes.</p>}
            <Row className='prayer-card-template-wrapper'>
                <Col
                    md={3}
                    className='prayer-card-menu-wrapper'
                    style={{
                        pointerEvents: isPrayerCardEditable ? "none" : "all",
                        cursor: isPrayerCardEditable
                            ? "not-allowed"
                            : "pointer",
                    }}
                >
                    <PreviewTemplate
                        template={template}
                        isCustom={_.get(template, "isCustom")}
                        role={role}
                        pages={pages}
                        activeTab={activeTab}
                        toggle={toggle}
                        mode='nav'
                        url={previewImageUrl}
                    />
                </Col>
                <Col md={9}>
                    {activeTab == 2 && (
                        <div className='cardbox-heading'>
                            <div className="d-flex">
                                {!isPrayerCardEditable && (
                                    <div className='cardbox-heading-button'>
                                        <button
                                            className={`link-customise btn-theme theme-green-outlined ${isLocked ? "edit-disable-btn" : ""}`}
                                            onClick={() => {
                                                setPrayerCardEditable(true);
                                                setIsCancel(false)
                                               
                                            }}
                                            disabled={isLocked}
                                        >
                                            EDIT CARD
                                        </button>
                                    </div>
                                )}

                                {isPrayerCardEditable && (
                                    <div className='cardbox-heading-button'>
                                        <a
                                            className='link-customise btn-theme theme-cancel'
                                            onClick={() => {
                                                setPrayerCardEditable(false);
                                                setIsCancel(true)
                                                executeGetPrayerCard(onePortalId)
                                            }}
                                        >
                                            CANCEL
                                        </a>
                                    </div>
                                )}

                                {isPrayerCardEditable && (
                                    <div className='cardbox-heading-button'>
                                        <button
                                            className='link-customise btn-theme theme-green-outlined'
                                            onClick={() => {
                                                setPrayerCardEditable(false);
                                                handleSavePositions()
                                                setTimeout(function(){
                                                    handlePreviewImage(activeTab)
                                                }, 1000); 
                                            }}
                                            disabled={isError}
                                        >
                                            SAVE CARD
                                        </button>
                                    </div>
                                )}
                            </div>
                            <div className="d-flex" style={{paddingLeft:'80px'}}>
                                {
                                    isPrayerCardEditable && <>
                                        <SelectPoem
                                            handleSelect={handleSelect}
                                            poems={poems}
                                            setSelectedPoem={setSelectedPoem}
                                        />
                                        <SelectFooter
                                            handleSelect={handleSelect}
                                            setLocationCity={setLocationCity}
                                            
                                        />
                                        
                                    </>
                                }
                            </div>
                        </div>
                    )}

                    <div className='prayer-card-editor-wrapper'>
                        <TabContent
                            activeTab={activeTab}
                            style={{
                                pointerEvents: !isPrayerCardEditable
                                    ? "none"
                                    : "all",
                                cursor: !isPrayerCardEditable
                                    ? "not-allowed"
                                    : "pointer",
                            }}
                        >
                            {pages.map((page, index) => (
                                <TabPane
                                    tabId={index + 1}
                                    id={`template-div-${index}`}
                                >
                                    <CustomizeTemplate
                                        index={index}
                                        isPrayerCardEditable={
                                            isPrayerCardEditable
                                        }
                                        template={template}
                                        page={page}
                                        isLocked={isLocked}
                                        isError={isError}
                                        isCustom={_.get(template, "isCustom")}
                                        role={role}
                                        onePortalId={onePortalId}
                                        loadingStatus={loadingStatus}
                                        setError={setErrorMsg}
                                        decedent={decedent}
                                        executeSetTemplate={payload =>
                                            saveTemplate(onePortalId, payload)
                                        }
                                        handleNewTemplate={newTemplate => 
                                            selectTemplate(newTemplate)
                                        }
                                        handlePreviewImage={handlePreviewImage}
                                        setPositions={setPositions}
                                        positions={positions}
                                        poems={poems}
                                        allContent={allContent}
                                        setAllContent={setAllContent}
                                        selectedPoem={selectedPoem}
                                        setSelectedPoem={setSelectedPoem}
                                        locationCity={locationCity}
                                        setLocationCity={setLocationCity}
                                        selectedLocation={selectedLocation}
                                        isCancel={isCancel}
                                        previousContent={previousContent}
                                        setPreviousContent={setPreviousContent}
                                    />
                                </TabPane>
                            ))}
                        </TabContent>
                    </div>
                </Col>
                <Col xs={12} className='prayer-card-footer'>
                    <Row>
                        <Col xs={6}>
                            <div className='prayer-card-info'>
                                <span className='title'>Last edited by:</span>
                                <span className='value'>
                                    {_.get(template, "updatedBy", "")}
                                </span>
                            </div>
                            <div className='prayer-card-info'>
                                <span className='title'>Last edited at: </span>
                                <span className='title'>
                                    {_.get(template, "updatedAt")
                                        ? moment(template.updatedAt).format(
                                              "MMMM Do YYYY, h:mm a"
                                          )
                                        : ""}
                                </span>
                            </div>
                        </Col>
                        <Col xs={6}>
                            <div className='prayer-card-action'>
                                {pageURL.length ? (
                                    <div id='print-div'>
                                        <div id='print'>
                                            <ImageGrid
                                                type='back'
                                                url={pageURL[1]}
                                            />
                                            {
                                                _.get(template, "isCustom") && <div className='page-break' />
                                            }
                                            {_.get(template, "isCustom") && (
                                                <div className='template-img-container'>
                                                    <div className='extra' />
                                                    <div className='image'>
                                                        <img
                                                            src={pageURL[0]}
                                                            alt='Img'
                                                        />
                                                    </div>
                                                </div>
                                            ) 
                                            }
                                        </div>
                                    </div>
                                ) : (
                                    ""
                                )}
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <iframe
                id='ifmcontentstoprint'
                title='Prayer template'
                style={{
                    height: "0px",
                    width: "0px",
                    position: "absolute",
                    padding: "0 !important",
                    margin: "0 !important",
                }}
                onLoad={() => {
                    const iframeWindow =
                        window.frames.ifmcontentstoprint.contentWindow;
                    if (iframeWindow.window.location.href !== "about:blank") {
                        iframeWindow.focus();
                        iframeWindow.print();
                    }
                }}
            />
            <PreviewModal
                template={template}
                pages={pages}
                isOpen={slidemodal}
                toggle={slideToggle}
                url={previewImageUrl}
            />
            <PreviewCardModal
                template={template}
                pages={pages}
                isOpen={previewCardModal}
                toggle={togglePreviewCard}
                pageURL={pageURL}
            />
        </div>
    );
};

export default CustomizeCard;
