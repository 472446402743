import _ from "lodash";
import qs from "query-string";
import { getFullPageTitles } from "./variables";
import { saveAs } from "file-saver";

export const formatOptions = (options, name, value) => {
    options = _.map(options, option => {
        return {
            label: option[name] || option.name,
            value: option[value] || option.name,
            ...option
        };
    });
    return options;
};
export const buildPanoramicViewURL = query =>
  qs.stringifyUrl({
    url: process.env.REACT_APP_PANORAMIC_VIEW_BASE_URL,
    query,
  });
  
export const getFileName = fileUrl => {
  const srcUrl = fileUrl
  const fileNameArray = srcUrl?.split('-');
  const originalNameArray = fileNameArray ? fileNameArray.slice(1).join("") : '';
  const removeExtension = originalNameArray ? originalNameArray.split('.') : '';
  const extension = removeExtension ? removeExtension?.pop() : 'jpeg';
  const finalName = removeExtension ? removeExtension.join("").trim() : ''
  return {finalName,extension}
}

export const getPageTitle = (url) => {
  let name;
  const pageTitleArray = url?.split("/");
  if (
    (pageTitleArray[1] === "app" && pageTitleArray[2] === "memories") ||
    (pageTitleArray[1] === "admin" && pageTitleArray[2] === "memories-list")
  ) {
    name = "obituary";
  } else name = pageTitleArray?.pop();
  const fullPageTitle = _.get(
    _.find(getFullPageTitles, { value: name }),
    "label"
  );
  return fullPageTitle || "CypressLawn - Family Arrangement Assistant";
};

export const copyListener = (event) => {
  //Get the selected text and append the extra info
  
  const currentYear = new Date().getFullYear();
  var selection, selectedNode, html;
  if (window.getSelection) {
    selection = window.getSelection();
    if (selection?.rangeCount) {
      selectedNode = selection.getRangeAt(0).startContainer.parentNode;
      var container = document.createElement("div");
      container.appendChild(selection.getRangeAt(0).cloneContents());
      html = container.innerHTML;
    }
  } else {
    console.debug("The text [selection] not found.");
    return;
  }

  var range = selection.getRangeAt(0);

  if (!html) html = "" + selection;

  html += `<br/><br/><a
  href="https://cypresslawn.com/cypress-lawn-cemetery-association/"
  target="_blank"
  rel="noreferrer"
  className="copyRightText"
>
  © ${currentYear} Cypress Lawn Cemetery Association, All Rights Reserved.
</a>`;
  var newdiv = document.createElement("div");

  // Insert the container, fill it with the extended text, and define the new selection.
  selectedNode.appendChild(newdiv); // *For the Microsoft Edge browser so that the page wouldn't scroll to the bottom.
  newdiv.innerHTML = html;
  selection.selectAllChildren(newdiv);

  window.setTimeout(function () {
    selectedNode.removeChild(newdiv);
    window.getSelection().removeAllRanges();
    window.getSelection().addRange(range); // Restore original selection.
  }, 5); // Timeout is reduced to 10 msc for Microsoft Edge's sake so that it does not blink very noticeably.
};

export const copyListenerForEditor = (event) => {
  const currentYear = new Date().getFullYear();
  let html = "",
    text = "";

  html = event?.clipboardData?.getData("text/html");
  text = event?.clipboardData?.getData("text/plain");

  const pageLink = `<a
    href="https://cypresslawn.com/cypress-lawn-cemetery-association/"
    target="_blank"
    rel="noreferrer"
    className="copyRightText"
>
    © ${currentYear} Cypress Lawn Cemetery Association, All Rights Reserved.
</a>`;
  event.clipboardData.setData(
    "text/plain",
    `${text}\n\n©${currentYear} Cypress Lawn Cemetery Association, All Rights Reserved`
  );
  event.clipboardData.setData("text/html", `${html}<br></br>${pageLink}`);
  event.preventDefault();
};
export const handleFileDownLoad = async(src) => {
    const extension = src?.split(".")?.pop();
    if (extension === "docx") {
      const link = document.createElement("a");
      link.href = src;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else if (extension === "pdf") {
      const { finalName, extension } = getFileName(src);
      let response = await fetch(src);
      let blob = await response?.blob();
      const blobUrl = URL.createObjectURL(blob);
      saveAs(blobUrl, `${finalName ? finalName : "DocumentFile"}.${extension}`);
    }
}

export const getPlainText = (html) => {
  let doc = new DOMParser().parseFromString(html, "text/html");
  let plainText = doc?.body?.textContent || "";
  plainText = plainText.replace(/\n/g, ''); // Remove newline characters
  return plainText;
};