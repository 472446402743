import React, { useState, useCallback, useMemo, useEffect } from "react";
import {
    Switch,
    Route,
    useRouteMatch,
    useLocation,
    Redirect,
    useParams,
} from "react-router-dom";
import ReactNotification from "react-notifications-component";
import useAsync from "react-use-async-hook";
import _ from "lodash";

import * as AuthAPI from "../../../api/auth";
import { useSetLoading } from "../../utils/hooks/loader";
import MemoryDashboard from "../../../containers/memoryDashboardOld";
import ShareMemory from "./shareMemory";
import Cookies from "universal-cookie";

const MemoriesOld = () => {
    const match = useRouteMatch();
    const [redirectUrl, setRedirectUrl] = useState(null);
    const location = useLocation();

   

    useEffect(() => {
            let pathArr = match.path.split("/");
            if (
                pathArr.includes("memories") &&
                pathArr.includes("app") &&
                !roleVal
            ) {
                setRedirectUrl(
                    _.join(
                        _.remove(
                            _.split(location.pathname, "/"),
                            n => n !== "" && n !== "app"
                        ),
                        "/"
                    )
                );
            }
            if (roleVal) {
                if (
                    roleVal === "admin" &&
                    !location.pathname.includes("/admin/memories-list")
                ) {
                    setRedirectUrl("/admin");
                }
                if (pathArr.includes("memories") && !pathArr.includes("app")) {
                    setRedirectUrl(`/app${location.pathname}`);
                }
            }
    }, []);

    const cookies = new Cookies();
    const roleVal = sessionStorage.getItem("role") || localStorage.getItem("role") || cookies.get("role");


    return redirectUrl ? (
        <Redirect to={redirectUrl} />
    ) : (
        <div>
            <ReactNotification />
            <Switch>
                <Route
                    path={`${match.url}/:opi`}
                    exact
                    component={MemoryDashboard}
                />
                <Route
                    path={`${match.path}/:onePortalId/share`}
                    exact
                    component={ShareMemory}
                />
            </Switch>
        </div>
    );
};
export default MemoriesOld;
