import React, { Component, useState } from "react";
import _, { divide } from "lodash";
import { Form } from "reactstrap";
import * as yup from "yup";
import { Formik } from "formik";
import { API_URL } from "../../../../constants/appConstants";
import Select from "react-select/creatable";

const ServiceDetail = props => {
    const {
        details,
        decedentId,
        executeSendInterest,
        executeGetServices,
        onePortalId
    } = props;

    const [dropdownValue, setDropdownValue] = useState(null);
    const [selectedValue, setSelectedValue] = useState("");
    const [isDisabled, setIsDisabled] = useState(true);
    const serviceOptions = [
        {
            label: "I'm Interested",
            value: true,
        },
        {
            label: "I'm Not Interested",
            value: false,
        },
    ];

    return (
        <div className="service-wrapper wrapper-visitation">
            <div className="cardbox-wrapper">
                <div className="cardbox-heading">
                    <h4 className="cardbox-heading-name text-capitalize service-heading">
                        {_.get(details, "name")}
                    </h4>
                </div>
                <div className="cardbox-body cardbox-body-text">
                    <div className="service-content-wrapper">
                        <div className="service-image-wrapper">
                            <img
                                src={`${API_URL}${_.get(details, "imageUrl")}`}
                                alt="visitation Service"
                                className="service-image"
                            />
                        </div>
                        <Formik
                            initialValues={{
                                note:
                                    "" || _.get(details, "note")
                                        ? _.get(details, "note")
                                        : "",
                                selectedService:
                                    "" ||
                                    _.get(details, "isInterested") === true
                                        ? {
                                              label: "I'm Interested",
                                              value: true,
                                          }
                                        : _.get(details, "isInterested") ===
                                          false
                                        ? {
                                              label: "I'm Not Interested",
                                              value: false,
                                          }
                                        : "",
                            }}
                            enableReinitialize={true}
                            validationSchema={yup.object().shape({
                                note: yup.string().max(1000, "Note is too long")
                            })}
                            onSubmit={async (values, formikBag) => {
                                const noteValue = values.selectedService.value === true ? values.note : "";
                                await executeSendInterest(details.id, {
                                    decedentId,
                                    note: noteValue,
                                    isInterested: values.selectedService.value
                                });
                                formikBag.setSubmitting(false);
                                executeGetServices(onePortalId);
                                setIsDisabled(true)
                            }}
                        >
                            {({
                                values,
                                errors,
                                touched,
                                handleSubmit,
                                handleChange,
                                handleBlur,
                                isSubmitting,
                                setFieldValue
                            }) => {
                                return (
                                    <Form
                                        className="changepassword-form mt-4"
                                        onSubmit={handleSubmit}
                                    >
                                        <div className="service-notes-wrapper">
                                            <p>
                                                {" "}
                                                {_.get(
                                                    details,
                                                    "description"
                                                )}{" "}
                                            </p>
                                            <div className='service-notes-container'>
                                                <div className='dropdown-container'>
                                                    <h1 className='service-dropdown-heading'>
                                                        Are you interested in this service?
                                                    </h1>
                                                    <div className='select-wrapper'>
                                                        <Select
                                                            isClearable={false}
                                                            placeholder='Choose your preference'
                                                            options={serviceOptions}
                                                            name='selectedService'
                                                            value={values.selectedService}
                                                            onChange={(e) => {
                                                                setFieldValue("selectedService", e);
                                                                if (e.value === true) {
                                                                    setDropdownValue(true);
                                                                } else {
                                                                    setDropdownValue(false);
                                                                    handleSubmit()
                                                                }
                                                                setSelectedValue(e.value);
                                                            }}
                                                            onFocus={e => {
                                                                e.preventDefault();
                                                                setIsDisabled(false);
                                                            }}
                                                            className='select-dropdown'
                                                            isSearchable={false}
                                                        />
                                                    </div>
                                                </div>
                                                {(details.isInterested === true ||
                                                    dropdownValue === true) && (
                                                    <div className="service-notes-content">
                                                        <label>Notes for your arranger (optional)</label>
                                                        <textarea
                                                            placeholder="Add details"
                                                            type="text"
                                                            name="note"
                                                            onChange={handleChange}
                                                            onFocus={e => {
                                                                e.preventDefault();
                                                                setIsDisabled(false);
                                                            }}
                                                            value={values.note}
                                                        ></textarea>
                                                        {errors.note && touched.note ? (
                                                            <span className="error-input-msg">
                                                                {errors.note}
                                                            </span>
                                                        ) : null}
                                                    </div>
                                                )}
                                            </div>
                                            {(details.isInterested === true || dropdownValue) && (
                                                <div className="d-flex justify-content-center mt-4">
                                                    <button 
                                                        type="submit" 
                                                        className={`btn-theme text-uppercase ${isDisabled && 'disable-button'}`} 
                                                        disabled={isDisabled}
                                                    >
                                                        Submit to Arranger
                                                    </button>
                                                </div>
                                            )}
                                        </div>
                                    </Form>
                                );
                            }}
                        </Formik>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ServiceDetail;
