import React, { Component } from 'react';
import { FormGroup, Label, Input } from 'reactstrap';

export default class Btncheckbox extends Component {
    render() {
        return (
            <FormGroup className="custom-check">
                <Label className="checkbox-inline d-flex align-items-center justify-content-start">
                    <Input
                        className="custominputfield"
                        type="checkbox"
                        checked={this.props.value}
                        disabled={this.props.disabled}
                        onChange={e => this.props.handleChange(e.target.checked)}
                        id={this.props.id}
                    />
                    <span>
                        <i className="fas fa-check" />
                    </span>
                </Label>
            </FormGroup>
        );
    }
}
