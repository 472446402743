import React, { useState, useRef,useEffect } from 'react'
import _ from "lodash";
import {
    CarouselControl,
    Carousel,
    CarouselItem,
    CarouselIndicators,
} from 'reactstrap';
import AlertMessageForMemory from "../../../../utils/AlertMessage/AlertMessageForMemory";
import defaultImage from "../../../../../assets/images/warning.svg";
import { Link } from "react-router-dom";
import deleteIcon from '../../../../../assets/images/deleteIcon.svg'
import classicDownloadIcon from '../../../../../assets/images/classicFileDownload.svg'
import RotateImage from './rotateImageInModalItself';
import VideoThumbnail from "../../memoriesListing/memories-files/videoThumbnail";
import rotateIcon from '../../../../../assets/images/rotateIcon.svg'
import ListDocuments from '../../galleryListing/gallery-files/listDocumnets';
import { saveAs } from 'file-saver';

const renderVideo = (video, key, isThumbnail, memoryId, setVideoTitle) => {
    return (
        <VideoThumbnail
            video={video}
            isThumbnail={isThumbnail}
            flag={key}
            memoryId={memoryId}
            setVideoTitleData={setVideoTitle}
        />
    );
};

const DeleteButton = ({ status, imageDeletion, memoryId, file, nextButton, index, fileLength, setRotateImg, setDisplaySaveButton, disableIcon }) => {
    const [deleteImg, setDeleteImg] = useState(false);
    return (
        <div className='DeleteImageButton'>
            {status && file?.fileType !== 'Application' && (
                <div className='d-flex justify-content-end align-items-end mt-1 delete-image'>
                    <button disabled={(file?.fileType === 'Image') ? disableIcon && disableIcon?.isDisable : false} className={`${(disableIcon?.isDisable && file?.fileType === 'Image') ? 'disableIconButton' : 'iconsButton'}`}
                        onClick={() => {
                            setDeleteImg(true)
                            setRotateImg(false)
                            setDisplaySaveButton(false)
                        }}>
                        <img src={deleteIcon} alt="deleteIcon"
                            className="deleteIcon"
                            id="deleteIcon"
                        ></img>
                    </button>
                </div>
            )}
            {deleteImg && (
                <AlertMessageForMemory
                    toggleAlertModal={() => {
                        setDeleteImg(false);
                    }}
                    toggleOk={() => {
                         imageDeletion(memoryId, file.id);
                         setDeleteImg(false);
                         {index === fileLength-1 && nextButton()}
                    }}
                    toggleAlertState
                    title='Are you sure you want to delete this Image?'
                    successMsg='Once deleted it cannot be revoked'
                    showCancelButton={true}
                    okButtonText='Delete'
                    deleteState={true}
                />
            )}
        </div>
    )
}

const EditButton = ({ status, memoryId, file, nextButton, index, fileLength, opi, refreshList,
    isRotated, setRotateImg, rotation, setRotation, setDisplaySaveButton, disableIcon }) => {

    return (
        <div className='DeleteImageButton'>
            {status && (
                <div className='d-flex justify-content-end align-items-end mt-1 delete-image'>
                    <button onClick={() => {
                        setTimeout(() => {
                            setDisplaySaveButton(true);
                        }, 1000 * 1);
                        setRotateImg(true)
                        if (rotation === 360) {
                            setRotation(0 + 90)
                        }
                        else {
                            setRotation(rotation + 90);
                        }
                    }}
                        disabled={disableIcon && disableIcon?.isDisable}
                        className={`${disableIcon && disableIcon?.isDisable ? 'disableIconButton' : 'iconsButton'}`}
                    >
                        <img src={rotateIcon}
                            className='rotateIconMobile'
                            alt='rotateImage'
                            id="rotate-icon"
                        />
                    </button>
                </div>
            )}
        </div>
    )
}

const DownloadButton = ({ file, status, setRotateImg, setDisplaySaveButton, disableIcon }) => {
    return (
        <div>
            {status && (
                <div className='DeleteImageButton'>
                    <button disabled={disableIcon && disableIcon?.isDisable} className={`${disableIcon && disableIcon?.isDisable ? 'disableIconButton' : 'iconsButton'}`}
                        onClick={async() => {
                            setRotateImg(false)
                            setDisplaySaveButton(false)
                            const fileNameArray = file?.originalUrl ? file?.originalUrl.split('-') : file?.src.split('-');
                            const originalNameArray = fileNameArray ? fileNameArray.slice(1).join(""): '';
                            const removeExtension = originalNameArray ? originalNameArray.split('.') : '';
                            const extension = removeExtension ? removeExtension?.pop() : 'jpeg';
                            const finalName = removeExtension ? removeExtension.join("").trim() : '';
                            let response = await fetch(file?.src);
                            let blob = await response?.blob();
                            const blobUrl = URL.createObjectURL(blob);
                            saveAs(blobUrl,`${finalName ? finalName : 'MemoryImage'}.${extension}`)
                        }}>
                        <img src={classicDownloadIcon} alt='downloadIcon'
                            className='downloadIcon'
                            id="download-icon"
                        />
                    </button>
                </div>
            )}
        </div>
    )
}

const Media = ({ file, index, memoryId, isRotated, setRotateImg, rotation, isDisabled, activeIndex, setDisableIcon, session, opi, refreshList,isFamilyAccess }) => {

    const [isLoaded, setIsLoaded] = useState(false)
    let img = new Image();
    img.onload = () => {
        setIsLoaded(true)
    }

    useEffect(() => {
        if (activeIndex === index) {
            setDisableIcon({ index: activeIndex, isDisable: !isLoaded });
        }
    }, [activeIndex, isLoaded])

    useEffect(() => {
        img.src = file?.src;
    }, [])


    const imageRef = useRef(null);
    const fileContainerRef = useRef(null);
    return (
        <>
            <Link
                to={
                    {
                        pathname: `/gallery/file/${file.id}`,
                        state: { fileId: file.id },
                    }

                }
                onClick={() => {
                    localStorage.setItem(
                        [file.id],
                        JSON.stringify([file, index, memoryId])
                    );
                }}
                target={"_blank"}
                rel='noopener noreferrer'
            >
                <div className={`${_.lowerCase(file.fileType)}-file-div-poc`}>
                    {file.fileType === "Image" && (

                        <img
                            className='image-file-poc'
                            src={file.src}
                            onError={() => {
                                imageRef.current.src = defaultImage;
                                fileContainerRef.current.className = `error-div ${_.lowerCase(
                                    file.fileType
                                )}-file-div contain-img`;
                            }}
                            ref={imageRef}
                            alt='obituary-photos'
                            style={{ objectFit: "contain" }}
                        />

                    )}
                    {file.fileType === "Video" &&
                        renderVideo(file, index, false, memoryId)}
                    {file.fileType === "Audio" && (
                        <audio controls className='audio-file'>
                            <source src={file.src} />
                        </audio>
                    )}
                </div>
            </Link>
        </>
    )
}
export default function MobileView({ files, memoryId, status, imageDeletion, opi, refreshList, session, isFamilyAccess, documentFiles }) {
    const [activeIndex, setActiveIndex] = useState(0);
    const [animating, setAnimating] = useState(false);
    const [isRotated, setRotateImg] = useState(false);
    const [rotation, setRotation] = useState(0);
    const [displaySaveButton, setDisplaySaveButton] = useState(false);
    const [disableIcon, setDisableIcon] = useState({ index: 0, isDisable: true })
    const [updatedFiles,setUpdatedFiles] = useState([])
    const itemLength = updatedFiles?.length - 1

    //Separate normal media files and pdf's and docx 
    useEffect(() => {

      if (files?.length) {
        const newArr = [];
        files?.map((file, i) => {
          //check file exist in documentsFiles or not
          if (file?.fileType !== "Application") {
            newArr.push(file);
          }
        });
        setUpdatedFiles([...newArr]);
      }
    }, [files]);

    // Previous button for Carousel
    const previousButton = () => {
        setDisplaySaveButton(false)
        setRotateImg(false)
        if (animating) return;
        const nextIndex = activeIndex === 0 ?
            itemLength : activeIndex - 1;
        setActiveIndex(nextIndex);
    }

    // Next button for Carousel
    const nextButton = () => {
        setDisplaySaveButton(false)
        setRotateImg(false)
        if (animating) return;
        const nextIndex = activeIndex === itemLength ?
            0 : activeIndex + 1;
        setActiveIndex(nextIndex);
    }

    // Carousel Item Data
    
   const data = updatedFiles?.sort((a, b) => (a.id > b.id ? 1 : -1));

    const carouselItemData = data?.map((file, index) => {
        return (
            <CarouselItem
                key={file.src}
                onExited={() => setAnimating(false)}
                onExiting={() => setAnimating(true)}
            >
                <div className='Item'>
                    {!isRotated ? <Media
                        file={file}
                        index={index}
                        activeIndex={activeIndex}
                        memoryId={memoryId}
                        setDisableIcon={setDisableIcon}
                        disableIcon={{ index: index, isDisable: true }}
                        session={session}
                        opi={opi}
                        refreshList={refreshList}
                        isFamilyAccess={isFamilyAccess}
                    ></Media> : (
                        <div className='rotateImageModal'>
                            <div className='croppedImageEditor mobile-rotateImageEditor'>
                                {
                                    (index === activeIndex) && (
                                        <RotateImage
                                            memoryId={memoryId}
                                            file={file}
                                            activeIndex={activeIndex}
                                            isRotated={isRotated}
                                            setRotateImg={setRotateImg}
                                            opi={opi}
                                            refreshList={refreshList}
                                            displaySaveButton={displaySaveButton}
                                            setDisplaySaveButton={setDisplaySaveButton}
                                            rotation={rotation}
                                            setRotation={setRotation}
                                            webView={false}
                                            disableIcon={disableIcon}
                                            setDisableIcon={setDisableIcon}
                                        />
                                    )
                                }
                            </div>
                        </div>
                    )}
                    <div className={isRotated ? 'iconsContainer-rotation' : 'main-iconsContainer'} >
                        <div className={isRotated ? 'editorEnabled-Index' : 'Index'}>{`${index + 1}/${updatedFiles?.length}`}</div>
                        {status && (
                            <div className={isRotated ? 'rotateDeleteButtons' : 'iconsContainer'}>
                                <DeleteButton
                                    status={status}
                                    imageDeletion={imageDeletion}
                                    memoryId={memoryId}
                                    file={file}
                                    nextButton={nextButton}
                                    index={index}
                                    fileLength={updatedFiles?.length}
                                    setRotateImg={setRotateImg}
                                    setDisplaySaveButton={setDisplaySaveButton}
                                >
                                </DeleteButton>
                                {file.fileType === 'Image' && (
                                    <>
                                        <EditButton
                                            status={status}
                                            memoryId={memoryId}
                                            file={file}
                                            nextButton={nextButton}
                                            index={index}
                                            fileLength={updatedFiles?.length}
                                            opi={opi}
                                            refreshList={refreshList}
                                            isRotated={isRotated}
                                            setRotateImg={setRotateImg}
                                            rotation={rotation}
                                            setRotation={setRotation}
                                            setDisplaySaveButton={setDisplaySaveButton}
                                            disableIcon={disableIcon}
                                            setDisableIcon={setDisableIcon}
                                        >
                                        </EditButton>
                                        <DownloadButton
                                            file={file}
                                            status={status}
                                            setRotateImg={setRotateImg}
                                            setDisplaySaveButton={setDisplaySaveButton}
                                        >
                                        </DownloadButton>
                                    </>
                                )}
                            </div>)
                        }
                    </div>
                </div>
            </CarouselItem>
        );
    });
    return (
      <>
        {updatedFiles?.length > 1 ? (
          <Carousel
            previous={previousButton}
            next={nextButton}
            activeIndex={activeIndex}
            pause="hover"
            interval={1000 * 60}
          >
            <CarouselIndicators
              items={updatedFiles}
              activeIndex={activeIndex}
              onClickHandler={(newIndex) => {
                if (animating) return;
                setActiveIndex(newIndex);
              }}
            />
            {carouselItemData}
            <CarouselControl
              directionText="Prev"
              direction="prev"
              onClickHandler={previousButton}
            />
            <CarouselControl
              directionText="Next"
              direction="next"
              onClickHandler={nextButton}
            />
          </Carousel>
        ) : (
          <>
            {updatedFiles?.length === 1 && (
              <div className="Item">
                {!isRotated ? (
                  <Media
                    file={updatedFiles?.[0]}
                    index={0}
                    memoryId={memoryId}
                    disableIcon={disableIcon}
                    setDisableIcon={setDisableIcon}
                    activeIndex={0}
                    session={session}
                    opi={opi}
                    refreshList={refreshList}
                    isFamilyAccess={isFamilyAccess}
                  ></Media>
                ) : (
                  <div className="rotateImageModal">
                    <div className="croppedImageEditor mobile-rotateImageEditor">
                      <RotateImage
                        memoryId={memoryId}
                        file={updatedFiles?.[0]}
                        isRotated={isRotated}
                        setRotateImg={setRotateImg}
                        opi={opi}
                        refreshList={refreshList}
                        displaySaveButton={displaySaveButton}
                        setDisplaySaveButton={setDisplaySaveButton}
                        rotation={rotation}
                        setRotation={setRotation}
                        webView={false}
                        disableIcon={disableIcon}
                        setDisableIcon={setDisableIcon}
                        activeIndex={0}
                      />
                    </div>
                  </div>
                )}
                <div
                  className={
                    isRotated
                      ? "mobile-iocnsContainer"
                      : "single-iconsContainer"
                  }
                >
                  <DeleteButton
                    status={status}
                    memoryId={memoryId}
                    imageDeletion={imageDeletion}
                    file={updatedFiles?.[0]}
                    setRotateImg={setRotateImg}
                    setDisplaySaveButton={setDisplaySaveButton}
                  ></DeleteButton>
                  {updatedFiles?.[0].fileType === "Image" && (
                    <div className="d-flex">
                      <EditButton
                        status={status}
                        memoryId={memoryId}
                        file={updatedFiles?.[0]}
                        nextButton={nextButton}
                        index={0}
                        fileLength={updatedFiles?.length}
                        opi={opi}
                        refreshList={refreshList}
                        isRotated={isRotated}
                        setRotateImg={setRotateImg}
                        rotation={rotation}
                        setRotation={setRotation}
                        setDisplaySaveButton={setDisplaySaveButton}
                      ></EditButton>
                      <DownloadButton
                        file={updatedFiles?.[0]}
                        status={status}
                        setRotateImg={setRotateImg}
                        setDisplaySaveButton={setDisplaySaveButton}
                      ></DownloadButton>
                    </div>
                  )}
                </div>
              </div>
            )}
          </>
        )}
        {documentFiles?.length >= 1 && (
          <div className="viewDocumentsMobileWrapper">
            <ListDocuments
              otherMedia={documentFiles}
              session={session}
              opi={opi}
              refreshList={refreshList}
              isFamilyAccess={isFamilyAccess}
              memoryId={memoryId}   
            />
          </div>
        )}
      </>
    );
}

