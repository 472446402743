import { apiFetch } from '../configs/apiConfig'
import { API_URL } from "../constants/appConstants"

export const getData = () => {
    return apiFetch({
        url: `${API_URL}/data`,
        method: "GET"
    })
}

export const getDecedent = decedentId => {
    return apiFetch({
        url: `${API_URL}/decedent/${decedentId}/info`,
        method: "GET"
    })
}

export const savePrimaryDetails = (decedentId, data) => {
    return apiFetch({
        url: `${API_URL}/decedent/${decedentId}/primary-info`,
        method: "POST",
        data
    })
}

export const saveResidentDetails = (decedentId, data) => {
    return apiFetch({
        url: `${API_URL}/decedent/${decedentId}/resident-info`,
        method: "POST",
        data
    })
}

export const saveEducationDetails = (decedentId, data) => {
    return apiFetch({
        url: `${API_URL}/decedent/${decedentId}/education-info`,
        method: "POST",
        data
    })
}

export const saveVeteranDetails = (decedentId, data) => {
    return apiFetch({
        url: `${API_URL}/decedent/${decedentId}/veteran-info`,
        method: "POST",
        data
    })
}

export const saveParentDetails = (decedentId, data) => {
    return apiFetch({
        url: `${API_URL}/decedent/${decedentId}/parent-info`,
        method: "POST",
        data
    })
}

export const saveEthnicityDetails = (decedentId, data) => {
    return apiFetch({
        url: `${API_URL}/decedent/${decedentId}/ethnicity-info`,
        method: "POST",
        data
    })
}

export const saveCertifierDetails = (decedentId, data) => {
    return apiFetch({
        url: `${API_URL}/decedent/${decedentId}/certifier-info`,
        method: "POST",
        data
    })
}

export const saveNotifierDetails = (decedentId, data) => {
    return apiFetch({
        url: `${API_URL}/decedent/${decedentId}/notifier-info`,
        method: "POST",
        data
    })
}

export const saveNOKDetails = (decedentId, data) => {
    return apiFetch({
        url: `${API_URL}/decedent/${decedentId}/nok-info`,
        method: "POST",
        data
    })
}

export const submitToOPI = decedentId => {
    return apiFetch({
        url: `${API_URL}/decedent/${decedentId}/info`,
        method: "POST"
    })
}
