import React, { useCallback, useMemo, useState, useEffect, useRef } from "react";
import _ from "lodash";
import moment from "moment";
import useAsync from "react-use-async-hook";
import { memoryTypes } from "../../../utils/variables";
import NewObituaryFile from "../memoriesListing/memories-files/newObituarySection.js";
import ApproveMemoriesFilter from "./approveMemories-filter";
import RejectionModal from "./rejectionModal";
import * as UserAPI from "../../../../api/memories";
import { apiErrorHandler } from "../../../../utils/handlers";
import { useSetLoading } from "../../../utils/hooks/loader";
import addNotification from "../../../utils/notification/notification";
import AlertMessageForMemory from "../../../utils/AlertMessage/AlertMessageForMemory.js";
import Dropzone from "react-dropzone";
import TinyMceEditor from "../../../common/tinyMceEditor/TinyMceEditor";
import AddYouTubeOrVimeoLink from '../../../admin/memories/addYoutubeVimeoLink.js'
import elliseCricle from '../../../../assets/images/ellipse-circle.svg'
import {
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
  } from 'reactstrap'
import { useLocation } from "react-router-dom";
import { getPlainText } from "../../../utils/functions.js";

const UploadImage = ({ memoryId, opi, handleAddImg,deviceWidth,refreshList,approveOrRejectMemory}) => {
    const [isDisabled, setDisabled] = useState(false);
    const dropdownRef = useRef();
    const [currentMemory,setCurrentMemory] = useState(null)
    const [dropdownOpen,setDropDownOpen] = useState(false)
    const [deleteMemory, setDeleteMemory] = useState(false);
    const [addUrl,setAddUrl] = useState(false)
    const dropzoneRef = useRef();
    const MAX_FILE_SIZE = 500 * 1024 * 1024;
    const acceptedFormats = ['image/jpeg',
    'image/png',
    'image/gif',
    'audio/mpeg', 'audio/wav', 'audio/wave', 'video/mp4', 'video/*', 'audio/ogg','application/ogg', 
    'video/mpeg', 'video/ogg', 'video/quicktime', 'video/mp4', 'video/x-flv', 
    'video/x-matroska',  'video/x-ms-asf', 'video/x-msvideo', '.flv', '*/flv', '.ogv', '*/ogv','application/pdf']

    useEffect(()=>{
        dropdownRef.current = dropdownOpen
        if(!dropdownOpen){
            setCurrentMemory(null)
        }
    },[dropdownOpen])

    const clicking = (e) =>{
        let list = [...document.getElementsByClassName('circle-img'),
        ...document.getElementsByClassName('menuItem'),
        ...document.getElementsByClassName('uploadmedia')
    ]
        let event = e.target
            if(list.includes(event)){
                return
            }else{
                setDropDownOpen(false)
                setCurrentMemory(null)
                return
            }

    }
    useEffect(() => {
        document.addEventListener('click', clicking,true);
        return () => {
        document.removeEventListener('click', clicking, true);
        };
    },[]);

    const onDrop = async (acceptedFiles, fileRejections, memoryid) => {
        if(fileRejections && fileRejections.length > 0) {
            const rejectedFile = fileRejections[0].file;
            if(rejectedFile.size > MAX_FILE_SIZE){
                addNotification({
                    type: "danger",
                    title: "Error",
                    message: `File too large, Max file size is 500 MB.`,
                });
                return;
            }
            else if (!acceptedFormats.includes(rejectedFile.type)) {
                addNotification({
                    type: "danger",
                    title: "Error",
                    message: `Invalid File Format. Please check the file type and upload again.`,
                });
                return 
            }
        }
        if (acceptedFiles && acceptedFiles[0]) {
            const formData = new FormData();
            _.map(acceptedFiles, file => {
                const min = 1;
                const max = 100;
                const randomNum = Math.floor(Math.random() * (max - min + 1)) + min;
                const fileName = file?.name;
                const formatFileName = fileName ? fileName.split('.') : '';
                const originalExtension = formatFileName ? formatFileName.pop() : 'jpeg'
                const originalFileName = formatFileName ? formatFileName.join("").trim() : '';
                const newFileName = `${originalFileName ? originalFileName : 'MediaImage'}_${randomNum}.${originalExtension}`
                const newFile = new File([file], newFileName, { type: file.type });
                formData.append("file", newFile);
            });
            performUploadAPI(formData, acceptedFiles, memoryid);
        }
    };
    /* UPLOAD IMAGE API*/
    let {
        data: uploadData,
        loading: uploadAPILoading,
        execute: performUploadAPI,
    } = useAsync({
        autoExecute: false,
        initialData: useMemo(() => null, []),
        task: useCallback(async (requestObj, files, memoryid) => {
            if (requestObj && files && files.length) {
                return {
                    response: await UserAPI.uploadAPI(requestObj),
                    files,
                    memoryid,
                };
            }
        }, []),
        dataLoader: useCallback(({ response, files, memoryid }) => {
            if (response && files && files.length) {
                return {
                    URL: _.get(response, "data.URL"),
                    files,
                    memoryid
                };
            }
        }, []),
        onError: useCallback((error) => {
            addNotification({
                type: "danger",
                title: "Error",
                message: _.get(
                    error,
                    "response.data.message",
                    "Oops! Something went wrong"
                ),
            });
            setDropDownOpen(true)
        }, []),
    });

    useEffect(() => {
        if (uploadData) {
            setDisabled(true)
            const { URL, files, memoryid } = uploadData;
            const addedFiles = {
                files: [
                    ..._.map(files, (file, i) => ({
                        fileType: _.capitalize(file.type.split("/")[0]),
                        fileUrl: URL[i],
                        originalFileUrl : URL[i],
                    })),
                ],
            };
            executeAddImgs(addedFiles, memoryid);
        }
    }, [uploadData]);

    /*ADD IMAGE API */
    const {
        data: AddImgData,
        loading: AddImgAPILoading,
        execute: executeAddImgs,
    } = useAsync({
        autoExecute: false,
        initialData: useMemo(() => null, []),
        task: useCallback(
            (requestObj, memoryid) => UserAPI.addImgAPI(memoryid, opi, requestObj),
            []
        ),
        dataLoader: useCallback(response => {
            addNotification({
                type: "success",
                title: "Success",
                message: "Media Uploaded Successfully",
            });
            return _.get(response, "data");
        },[]),
        onError: useCallback((error) => {
            addNotification({
                type: "danger",
                title: "Error",
                message: _.get(
                    error,
                    "response.data.message",
                    "Oops! Something went wrong"
                ),
            });
            setDropDownOpen(false)
        }, []),
    });

    const getMemories = async() => {
        await handleAddImg();
        setDropDownOpen(false)
    }
    useEffect(() => {
        if (AddImgData) {
            setDisabled(false);
            getMemories();
        }
    }, [AddImgData]);

    const handleButtonClick = () => {
        dropzoneRef.current.open();
      }

    const showLoading = useMemo(() => uploadAPILoading, AddImgAPILoading
        [(uploadAPILoading, AddImgAPILoading)]
    );
    useSetLoading(showLoading);

    return (
        <div>
            <div className="ellipse-container" ref={dropdownRef}>
                <UncontrolledDropdown isOpen={ dropdownOpen && memoryId === currentMemory }>
                    <DropdownToggle  className="ToggleButton">
                        {deviceWidth < 567 ? 
                            (<i class="fas fa-ellipsis-v fa-lg circle-img" 
                                style={{color:'black'}}
                                onClick={()=>{
                               if(currentMemory !== memoryId && currentMemory){
                                setCurrentMemory(memoryId)
                                }else{
                                    setCurrentMemory(memoryId)
                                    setDropDownOpen(!dropdownOpen)
                                }
                                }}
                                ></i>)
                                : (
                                    <img src={elliseCricle}
                                            className='circle-img'
                                            onClick={()=>{
                                            if(currentMemory !== memoryId && currentMemory){
                                                setCurrentMemory(memoryId)
                                                }else{
                                                    setCurrentMemory(memoryId)
                                                    setDropDownOpen(!dropdownOpen)
                                                }
                                
                                                }}
                                            alt='ellipsIcon'
                                    ></img>
                                )
                            }
                                                        
                            </DropdownToggle>
                                <DropdownMenu right >
                                    <DropdownItem onClick={()=>{
                                        setDeleteMemory(memoryId)
                                        }}
                                        className='menuItem'
                                        >
                                        {`Delete Memory`}
                                    </DropdownItem>
                                    <DropdownItem  className="menuItem" onClick={handleButtonClick}>
                                    <Dropzone
                                        ref={dropzoneRef}
                                        onDrop={ (acceptedFiles,fileRejections) => onDrop(acceptedFiles,fileRejections, memoryId)}
                                        accept='image/jpeg,
                                        image/png,
                                        image/gif,
                                        audio/mpeg, audio/wav, audio/wave, video/mp4, video/*, audio/ogg, application/ogg, video/mpeg, video/ogg, video/quicktime, video/mp4, video/x-flv,  video/x-matroska,  video/x-ms-asf, video/x-msvideo, .flv, */flv, .ogv, */ogv,application/pdf'
                                        minSize={0}
                                        maxSize={MAX_FILE_SIZE}
                                        multiple={false}
                                        disabled={isDisabled}
                                        noClick={true}
                                    >
                                        {({ getRootProps, getInputProps }) => (
                                            <div {...getRootProps()} className='uploadmedia'>
                                                <input {...getInputProps()} className='uploadmedia'/>
                                                    Add Image/Media
                                            </div>
                                        )}
                                    </Dropzone>
                                    </DropdownItem>
                                    <DropdownItem onClick={()=>{
                                        setAddUrl(memoryId)
                                        }}
                                        className='menuItem'
                                        >
                                        Add Video Link
                                    </DropdownItem>
                                </DropdownMenu>
                        </UncontrolledDropdown>
                </div>
                {deleteMemory === memoryId && (
                    <AlertMessageForMemory
                        toggleAlertModal={() => {
                            setDeleteMemory(false);
                        }}
                        toggleOk={() => {
                            approveOrRejectMemory(
                                "Deleted", memoryId
                            );
                            setDeleteMemory(false);
                        }}
                        toggleAlertState
                        title='Are you sure you want to delete this Memory?'
                        successMsg='Once deleted it cannot be revoked'
                        showCancelButton={true}
                        okButtonText='Delete'
                        deleteState={true}
                    />
                )}
                { addUrl === memoryId && (
                    <AddYouTubeOrVimeoLink 
                            memoryId={memoryId} 
                            refreshList={refreshList}
                            opi={opi} 
                            addUrl={addUrl}
                            setAddUrl={setAddUrl}
                            adminView={false}
                    ></AddYouTubeOrVimeoLink>
                ) }
        </div>
    );
};

const ApproveMemories = ({ opi, session, isFamilyAccess }) => {
    const [isRejected, setIsRejected] = useState(false);
    const [memoryType, setMemoryType] = useState([]);
    const [isTitleEdit, setTitleEdit] = useState({id: null, isEdit: false});
    const [titleData, setTitleData] = useState("");
    const [isTitleChanged, setTitleChanged] = useState(false);
    const [isDescrChanged, setDescrChanged] = useState(false);
    const [descriptionData, setDescriptionData] = useState("");
    const [tempData, setTempData] = useState(descriptionData);
    const [isDescriptionEdit, setDescriptionEdit] = useState({id: null, isEdit: false});
    const inputRef = useRef();
    const [pendingMemories, setPendingMemories] = useState();
    const [deviceWidth,setDeviceWidth] = useState();
    const location = useLocation()
    const MAX_LENGTH_LIFE_OF_DESCRIPTION = 5000;
    const MAX_LENGTH_LIFE_OF_TITLE = 255;
    
    useEffect(() => {
        const handleResize = () => {
            setDeviceWidth(window.innerWidth);
        };
        window.addEventListener("resize", handleResize);
        setDeviceWidth(window.innerWidth);
    }, []);


    const {
        data: approveMemoriesList,
        loading: memoriesLoading,
        execute: refreshList,
    } = useAsync({
        task: useCallback((opi, memoryType) => {
            return UserAPI.getApproveMemories(opi, memoryType);
        }, []),
        dataLoader: useCallback(res => {
            let result = _.get(res, "data.memories", []);
            result = _.isEmpty(result) ? [] : result;
            setPendingMemories(result)
            return result;
        }, []),
        initialData: useMemo(() => null, []),
        onError: useCallback(error => {
            if (
                document.getElementsByClassName("notification-danger").length ==
                0
            ) {
                apiErrorHandler(error);
            }
        }, []),
        autoExecute: false,
    });
    const {
        data: approveOrRejectMemoryData,
        loading: aproveOrRejectLoading,
        execute: approveOrRejectMemoryApi,
    } = useAsync({
        task: useCallback((opi, memoryId, status, reason) => {
            return UserAPI.aceeptOrrejectMemory(opi, memoryId, status, reason);
        }, []),
        dataLoader: useCallback(res => {
            let result = _.get(res, "data.memories", []);
            result = _.isEmpty(result) ? [] : result;
            return result;
        }, []),
        initialData: useMemo(() => null, []),
        onError: useCallback(error => {
            if (
                document.getElementsByClassName("notification-danger").length ==
                0
            ) {
                apiErrorHandler(error);
            }
        }, []),
        autoExecute: false,
    });
    useEffect(() => {
        if (approveOrRejectMemoryData) {
            refreshList(opi);
        }
    }, [approveOrRejectMemoryData, opi, refreshList]);

    useEffect(() => {
        const search =
            location.search ||
            location.pathname.slice(location.pathname.indexOf("?"));
        let queryparamUrl = new URLSearchParams(search).get("memory");
         
        if(queryparamUrl && approveMemoriesList){
            queryparamUrl = Number(queryparamUrl)
            const filteredList = approveMemoriesList.filter(memory => queryparamUrl === memory.id)
            setPendingMemories(filteredList)
        } else if(!queryparamUrl && approveMemoriesList){
            setPendingMemories(approveMemoriesList)
        }
    }, [location, approveMemoriesList]);

    const approveOrRejectMemory = (status, memoryId) => {
        approveOrRejectMemoryApi(opi, memoryId, status);
        if (status === "Deleted") {
            addNotification({
                type: "success",
                title: "Success",
                message: "Memory Deleted Successfully",
            });
        } else {
            addNotification({
                type: "success",
                title: "Success",
                message: "Memory Approved Successfully",
            });
        }
    };

    const handleRejection = (reason, memoryId) => {
        approveOrRejectMemoryApi(opi, isRejected, "Rejected", reason);
        setIsRejected(false);
        addNotification({
            type: "success",
            title: "Success",
            message: "Memory Rejected Successfully",
        });
    };

    const handleFilters = filters => {
        let filterArray = filters.map(filter => {
            if (filter === 1) {
                return "Pending";
            } else {
                return "Rejected";
            }
        });
        setMemoryType(filterArray);
        refreshList(opi, filterArray);
    };

    /*Tilte Edit API */
    const {
        data: TitleEditRes,
        loading: editTitleLoader,
        execute: executeEditTitleAPI,
    } = useAsync({
        autoExecute: false,
        task: UserAPI.titleEditApi,
        dataLoader: useCallback((res) => {
            addNotification({
                type: "success",
                title: "Success",
                message: "Title Edited Succesfully",
            });
            return res;
        }, []),
        onError: useCallback((error) => {
            addNotification({
                type: "danger",
                title: "Error",
                message: _.get(
                    error,
                    "response.data.message",
                    "Oops! Something went wrong"
                ),
            });
        }, []),
    });

    useEffect(() => {
        if (TitleEditRes) {
            refreshList(opi);
        }
    }, [TitleEditRes]);

    /*Description Edit API */
    const {
        data: DescrEditRes,
        loading: editDescrLoader,
        execute: executeEditDescrAPI,
    } = useAsync({
        autoExecute: false,
        task: UserAPI.descriptionEditApi,
        dataLoader: useCallback((res) => {
            addNotification({
                type: "success",
                title: "Success",
                message: "Memory Edited Succesfully",
            });
            return res;
        }, []),
        onError: useCallback((error) => {
            addNotification({
                type: "danger",
                title: "Error",
                message: _.get(
                    error,
                    "response.data.message",
                    "Oops! Something went wrong"
                ),
            });
        }, []),
    });

    useEffect(() => {
        if (DescrEditRes) {
            refreshList(opi);
        }
    }, [DescrEditRes]);

    /*Delete Image API */
    const {
        data: deleteImgInfo,
        execute: executeDeleteImg,
        loading: deleteImgLoader,
    } = useAsync({
        autoExecute: false,
        task: UserAPI.deleteImgAPI,
        dataLoader: useCallback((res) => {
            addNotification({
                type: "success",
                title: "Success",
                message: "Media Deleted Successfully",
            });
            return res;
        }, []),
        onError: useCallback((error) => {
            addNotification({
                type: "danger",
                title: "Error",
                message: _.get(
                    error,
                    "response.data.message",
                    "Oops! Something went wrong"
                ),
            });
        }, []),
    });

    useEffect(() => {
        if (deleteImgInfo) {
            refreshList(opi);
        }
    }, [deleteImgInfo]);

    const showLoading = useMemo(
        () =>
            memoriesLoading ||
            aproveOrRejectLoading ||
            editTitleLoader ||
            editDescrLoader ||
            deleteImgLoader[memoriesLoading ||
                    aproveOrRejectLoading ||
                    editTitleLoader ||
                    editDescrLoader ||
                    deleteImgLoader
            ]
    );
    useSetLoading(showLoading);

    const saveTitleData = (memoryId) => {
      if (titleData.length === 0 && memoryId === isTitleEdit?.id) {
        addNotification({
          type: "danger",
          title: "Error",
          message: "Title should not be empty",
        });
      } else if (
        titleData?.length > MAX_LENGTH_LIFE_OF_TITLE &&
        memoryId === isTitleEdit?.id
      ) {
        addNotification({
          type: "danger",
          title: "Error",
          message: "Memory Title should be less than 255 characters",
        });
      } else if (
        isTitleChanged &&
        titleData?.length !== 0 &&
        titleData?.length <= MAX_LENGTH_LIFE_OF_TITLE &&
        isTitleEdit?.id
      ) {
        executeEditTitleAPI(titleData, memoryId, opi);
        setTitleEdit({ id: null, isEdit: false });
      }
    };

    const saveDescrData = (memoryId) => {
      if (getPlainText(descriptionData)?.length === 0 && memoryId === isDescriptionEdit?.id) {
        addNotification({
          type: "danger",
          title: "Error",
          message: "Memory Description should not be empty",
        });
      } else if (
        getPlainText(descriptionData)?.length > MAX_LENGTH_LIFE_OF_DESCRIPTION &&
        memoryId === isDescriptionEdit?.id
      ) {
        addNotification({
          type: "danger",
          title: "Error",
          message: "Memory Description should be less than 5000 characters",
        });
      } else if (
        isDescrChanged &&
        descriptionData?.length !== 0 &&
        getPlainText(descriptionData)?.length <= MAX_LENGTH_LIFE_OF_DESCRIPTION &&
        memoryId === isDescriptionEdit?.id
      ) {
        executeEditDescrAPI(descriptionData, memoryId, opi);
        setDescriptionEdit({ id: null, isEdit: false });
      }
    };

    useEffect(() => {
        if (_.get(isTitleEdit, 'isEdit')) inputRef.current.focus();
    }, [isTitleEdit]);

    const formatPhoneNumber = value => {
        if (!value) {
          return '';
        }
        let val = value.replace(/\D/g, '');
        let newVal = '';
      
        // format - (XXX) XXX-XXXX
        if (val.length > 0) {
          newVal = `(`;
        }
        if (val.length > 3) {
          newVal += `${val.substr(0, 3)}) `;
          val = val.substr(3);
        }
        if (val.length > 3 && val.length < 7) {
          newVal += `${val.substr(0, 3)}-`;
          val = val.substr(3);
        }
        if (val.length > 6) {
          newVal += `${val.substr(0, 3)}-`;
          val = val.substr(3);
        }
      
        newVal += val;
        return newVal;
    };
   
    return (
        <>
            <div className='memory-listing-family'>
                <div className='memory-filter'>
                    <ApproveMemoriesFilter
                        filterSelected={memoryType}
                        setFilters={handleFilters}
                    />
                </div>
                <div className='memory-list-wrapper'>
                    {pendingMemories && pendingMemories.length > 0 ? (
                        <div>
                            {pendingMemories.map((memory, key) => {
                                const type = _.get(
                                    _.find(memoryTypes, {
                                        value: memory.memoryType,
                                    }),
                                    "label"
                                );
                                const { memoryByPerson = "" } = memory;

                                const handleChangeTitleData = e => {
                                    setTitleData(e.target.value);
                                    setTitleChanged(true);
                                };

                                const handleChangeDescriptionData = val => {
                                    setDescriptionData(val);
                                    setDescrChanged(true);
                                };
                                return (
                                    <div className='memory-card'>
                                        <div className='memory-card-header approve-memory-header d-flex justify-content-between'>
                                            <p className='memory-title'>
                                                <span>
                                                    {moment(
                                                        memory.dateOfMemory
                                                    ).format("MM/DD/YYYY")}
                                                </span>
                                                <span
                                                    style={{
                                                        margin: "0 10px",
                                                    }}
                                                >
                                                    |
                                                </span>
                                                {(_.get(isTitleEdit, 'id') === memory.id && _.get(isTitleEdit, 'isEdit')) ? (
                                                    <span className='header-text'>
                                                        <input
                                                            value={titleData}
                                                            ref={inputRef}
                                                            className='edit-header-title'
                                                            onChange={e => handleChangeTitleData(e)}
                                                            onBlur={() => saveTitleData(memory.id)}
                                                            id='title'
                                                            placeholder=''
                                                        />
                                                    </span>
                                                ) : (
                                                    <span>
                                                        {memory.title}
                                                    </span>
                                                )}
                                                <button
                                                    className='btn-edit-icon'
                                                            onClick={() => {
                                                                setTitleEdit((prevState) => ({
                                                                    isEdit: memory?.id === prevState.id ? !prevState.isEdit : true,
                                                                    id: memory?.id
                                                                }));
                                                                setTitleData(memory.title);
                                                            }}
                                                        >
                                                        <span className='btn-inner--icon'>
                                                                <i className='far fa-edit'></i>
                                                        </span>
                                                </button>
                                            </p>
                                            <UploadImage
                                                    opi={opi}
                                                    memoryId={memory?.id}
                                                    handleAddImg={() => refreshList(opi)}
                                                    deviceWidth={deviceWidth}
                                                    refreshList={refreshList}
                                                    approveOrRejectMemory={approveOrRejectMemory}

                                                />
                                        </div>
                                        <div className='memory-content'>
                                            <div className='memory-content-text'>
                                                {(_.get(isDescriptionEdit, 'id') === memory.id && _.get(isDescriptionEdit, 'isEdit'))? (
                                                    <TinyMceEditor
                                                    width={"auto"}
                                                    height={350}
                                                    changeEditorState={(val) => handleChangeDescriptionData(val)}
                                                    descriptionData={tempData}
                                                    placeholder={"Enter your memory.."}
                                                    postEditorData={()=> saveDescrData(memory.id)}
                                                    sizeLimit={5000}
                                                 />
                                                ) : (
                                                    <span style={{fontFamily:'Georgia',fontSize:'18pt'}}>
                                                        <span
                                                            dangerouslySetInnerHTML={{
                                                                __html: _.get(
                                                                    memory,
                                                                    "memory",
                                                                    ""
                                                                ),
                                                            }}
                                                        ></span>
                                                    </span>
                                                )}
                                                <span style={{fontFamily:'Georgia',fontSize:'18pt'}}>
                                                    <button
                                                        className='btn-edit-icon ml-2'
                                                            onClick={() => {
                                                            setDescriptionEdit((prevState) => ({
                                                                isEdit: memory?.id === prevState.id ? !prevState.isEdit : true,
                                                                id: memory?.id
                                                            }));
                                                            setDescriptionData(memory.memory)
                                                            setTempData(memory.memory)
                                                            }}
                                                        >
                                                            <span className='btn-inner--icon'>
                                                                <i className='far fa-edit'></i>
                                                            </span>
                                                    </button>
                                                </span>
                                            </div>
                                            {memory.files.length > 0 && (
                                                <NewObituaryFile
                                                    files={memory.files}
                                                    opi = {opi}
                                                    memoryId={memory.id}
                                                    status='preapproval'
                                                    refreshList = {refreshList}
                                                    imageDeletion={(memoryId, fileId) =>
                                                        executeDeleteImg(memoryId, fileId, opi)
                                                    }
                                                    session={session}
                                                    isFamilyAccess={isFamilyAccess}
                                                />
                                            )}
                                        </div>
                                       
                                        <div className='memory-card-footer'>
                                            <div
                                                className='memory-footer'
                                                style={{
                                                    justifyContent:
                                                        memoryByPerson
                                                            ? "space-between"
                                                            : "end",
                                                }}
                                            >
                                            <div className="memoryByPerson-contaier">
                                                <div>
                                                    {memoryByPerson && (
                                                        <div  className='details'>
                                                            <span className='header-label'>
                                                                Posted by:
                                                            </span>
                                                            <span className='header-text'>
                                                                {_.startCase(memory?.memoryByPerson)} {" "}
                                                                {"on "} {moment(
                                                                            memory?.createdAt
                                                                            ).format("MM/DD/YYYY")}
                                                            </span>
                                                        </div>
                                                    )}
                                                    <div className="details">
                                                        <span className='header-label'>EmailID:</span>
                                                        <span className='header-text'>
                                                            {memory?.personEmail || "NA"}
                                                        </span>
                                                    </div>
                                                    <div className="details">
                                                        <span className='header-label'>Phone Number:</span>
                                                        <span className='header-text'>
                                                            {formatPhoneNumber(memory?.personPhone) || "NA"}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                                {type && (
                                                    <span className='memory-postedBy'>{`Memory: ${type}`}</span>
                                                )}
                                            </div>
                                            <hr className='hr-line' />
                                            <div className='button-container'>
                                                <button
                                                    className='footer-button reject-button'
                                                    onClick={() =>
                                                        setIsRejected(memory.id)
                                                    }
                                                    disabled={
                                                       memory.memoryStatus === 'Rejected' 
                                                       ? true 
                                                       : false 
                                                    }
                                                >
                                                    Reject
                                                </button>
                                                <button
                                                    className='footer-button approve-button'
                                                    onClick={() =>
                                                        approveOrRejectMemory(
                                                            "Accepted",
                                                            memory.id
                                                        )
                                                    }
                                                >
                                                    Approve
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    ) : (
                        <div
                            style={{
                                textAlign: "center",
                            }}
                        >
                            There are no pending requests
                        </div>
                    )}
                </div>
            </div>
            {isRejected && (
                <RejectionModal
                    setIsRejected={setIsRejected}
                    handleRejection={handleRejection}
                />
            )}
        </>
    );
};

export default ApproveMemories;
