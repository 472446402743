import React, { useState, useEffect, useRef } from "react";
import _ from "lodash";

import FilterOption from "./filterOptions";
import { DropdownIcon } from "../../../../assets/icons/DropdownIcon";

const MemoriesFilters = ({
    setFilters,
    filtersSelected = [],
    isMenu = false,
    openModal,
}) => {
    const [isAllSelected, setIsAllSelected] = useState(true);
    const [selectedFilters, setSelectedFilters] = useState([]);
    const [isFilterExpanded, setFilterExpanded] = useState(false);

    const useOutsideAlerter = ref => {
        useEffect(() => {
            /**
             * Alert if clicked on outside of element
             */
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setFilterExpanded(false);
                }
            }

            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    };

    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);

    const filters = [
        {
            value: 1,
            label: "Family Memories",
        },
        {
            value: 2,
            label: "Relationship Memories",
        },
        {
            value: 3,
            label: "Education Memories",
        },
        {
            value: 4,
            label: "Career Memories",
        },
        {
            value: 5,
            label: "Military Memories",
        },
        {
            value: 6,
            label: "Important Dates",
        },
        {
            value: 7,
            label: "Hobby Memories",
        },
        {
            value: 8,
            label: "Remembrances",
        },
    ];

    const getSelectedFilterName = () => {
        if (
            !selectedFilters ||
            selectedFilters.length === 0 ||
            selectedFilters.length === filters.length
        ) {
            return "All Memories";
        }

        const selectedFilter = selectedFilters[0];
        const selecteFilterVal = filters.find(filter => {
            return filter.value === selectedFilter;
        });
        return selecteFilterVal.label;
    };

    const handleFiltersChange = (value, isAllSelected) => {
        if (selectedFilters.includes(value)) {
            const updatedFilters = _.filter(
                selectedFilters,
                filter => filter !== value
            );
            setSelectedFilters(updatedFilters);
            setIsAllSelected(false);
        } else {
            const updatedFilters = selectedFilters.concat(value);
            setSelectedFilters(updatedFilters);
            setIsAllSelected(false);
        }
    };

    const handleIsAllSelected = checkAllSelected => {
        if (checkAllSelected) {
            setSelectedFilters(filters.map(filter => filter.value));
            setIsAllSelected(true);
        } else if (!checkAllSelected) {
            setSelectedFilters([]);
            setIsAllSelected(false);
        }
    };

    useEffect(() => {
        if (selectedFilters.length === filters.length) {
            setIsAllSelected(true);
        } else {
            setIsAllSelected(false);
        }
        setFilters(selectedFilters);
    }, [selectedFilters]);

    useEffect(() => {
        if (filtersSelected.length === filters.length) {
            setIsAllSelected(true);
        } else {
            setIsAllSelected(false);
        }
    }, [filtersSelected]);

    // useEffect(() => {
    //     if (filtersSelected.length === filters.length) {
    //         setIsAllSelected(true);
    //     } else {
    //         setIsAllSelected(false);
    //     }
    //     setSelectedFilters(filtersSelected);
    // }, []);

    return (
        <div className='filters-wrapper' ref={wrapperRef}>
            {(isMenu && openModal) || !isMenu ? (
                <>
                    <div
                        className='selected-filter'
                        onClick={() => {
                            setFilterExpanded(!isFilterExpanded);
                        }}
                    >
                        <span>{getSelectedFilterName()}</span>
                        <span>
                            <DropdownIcon />
                        </span>
                    </div>
                    {isFilterExpanded && (
                        <FilterOption
                            handleIsAllSelected={handleIsAllSelected}
                            isAllSelected={isAllSelected}
                            filters={filters}
                            handleFiltersChange={handleFiltersChange}
                            selectedFilters={filtersSelected}
                            setFilterExpanded={setFilterExpanded}
                        />
                    )}
                </>
            ) : (
                ""
            )}
        </div>
    );
};

export default React.memo(MemoriesFilters);
