import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { firstTimeSaveText } from "../../features/certificateDetails/constants/constants";

const CertificateDetailsPopup = ({ isOpen, toggle, onClose }) => {
    return (
        <Modal
            isOpen={isOpen}
            toggle={toggle}
            className='modal-program-template-slide'
        >
            <ModalHeader toggle={toggle}>
                <i className='modal-close fas fa-times' onClick={onClose} />
            </ModalHeader>
            <ModalBody>
                <div>{firstTimeSaveText}</div>
            </ModalBody>
        </Modal>
    );
};

export default CertificateDetailsPopup;
