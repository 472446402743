import React, { useEffect, useCallback, useMemo, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import _ from "lodash";
import useAsync from "react-use-async-hook";
import moment from "moment";

import AppLayout from "../../appLayout";
import BreadCrumb from "../../utils/breadCrumb/breadCrumb";

import * as DecedentAPI from "../../../api/dashboard";
import { useSetLoading } from "../../utils/hooks/loader";
import TooltipAndInfo from "../../TooltipAndInfo";
import HistorySection from "./historySection";
import addNotification from "../../utils/notification/notification";

const Dashboard = () => {
    const [loader, setLoader] = useState(false);
    const [showHomeTooltip, setShowHomeTooltip] = useState(false);
    const showHideToolTip = () => {
        setShowHomeTooltip(!showHomeTooltip);
    };
    const location = useLocation();

    useEffect(() => {
        if(location.state?.decedentError){
            addNotification({
                type: "danger",
                title: "Error",
                message: location.state.decedentError
            });
        }   
    }, []);

    /**
     * Get Decedent List
     */
    const {
        data: decedents,
        loading: getDecedentLoader,
        execute: executeGetDecedent,
    } = useAsync({
        autoExecute: false,
        task: DecedentAPI.getDecedentList,
        dataLoader: useCallback(res => {
            return _.get(res, "data.decedent");
        }, []),
    });

    useEffect(() => {
        executeGetDecedent();
    }, []);

    const showLoader = useMemo(
        () => getDecedentLoader || loader,
        [getDecedentLoader, loader]
    );
    useSetLoading(showLoader);

    const getPersonName = person => {
        let name = [];
        if (_.get(person, "firstName")) {
            name.push(_.get(person, "firstName"));
        }
        if (_.get(person, "middleName")) {
            name.push(_.get(person, "middleName"));
        }
        if (_.get(person, "lastName")) {
            name.push(_.get(person, "lastName"));
        }
        return name.join(" ") || "";
    };

    return (
        <AppLayout setLoader={setLoader}>
            <div className='container'>
                <BreadCrumb
                    links={[
                        {
                            url: "/app/dashboard",
                            description: "Home",
                            heading: "Dashboard",
                            isSpan: true,
                        },
                    ]}
                    showBack={true}
                />
                <div className='navigationCenter-body-wrapper'>
                    <div className='home-wrapper'>
                        <div className='dashboard-header-family'>
                            <div className='dashboard-header'>
                                <h1 className='home-heading'>
                                    My Arrangements
                                </h1>
                                <TooltipAndInfo
                                    id='arrangement-details-tooltip-icon'
                                    color="#000000"
                                    showTooltipFn={showHideToolTip}
                                    showToolTip={showHomeTooltip}
                                    tooltipText='Click on the "BEGIN ARRANGEMENTS" to begin entering your loved ones information'
                                />
                            </div>
                        </div>
                        <div className='dashboard-wrapper'>
                            {decedents && decedents.activeCases &&
                                decedents.activeCases.map(person => {
                                    let { decedent } = person;
                                    let personName =
                                        (_.get(decedent, "prefix")
                                            ? _.get(decedent, "prefix") + ". "
                                            : "") + getPersonName(decedent);

                                    return (
                                        <div
                                            className='dashboard-card'
                                            key={decedent.id}
                                        >
                                            <Link to={`/app/${decedent.onePortalId}/arrangements`}>
                                                <div className='dashboard-card-content card-content'>
                                                    <div className='dashboard-card-decedentinfo card-bottom'>
                                                        <h5 className='dashboard-card-name card-heading'>
                                                            {personName}
                                                        </h5>
                                                        <div className='dashboard-card-keyvalue card-keyvalue'>
                                                            <span className='dashboard-card-key'>
                                                                Date of Birth
                                                            </span>
                                                            <span className='dashboard-card-value'>
                                                                {_.get(
                                                                    decedent,
                                                                    "dateOfBirth"
                                                                )
                                                                    ? moment(
                                                                          _.get(
                                                                              decedent,
                                                                              "dateOfBirth"
                                                                          )
                                                                      ).format(
                                                                          "MM-DD-YYYY"
                                                                      )
                                                                    : "-"}
                                                            </span>
                                                        </div>
                                                        <div className='dashboard-card-keyvalue card-keyvalue'>
                                                            <span className='dashboard-card-key'>
                                                                Date of Death
                                                            </span>
                                                            <span className='dashboard-card-value'>
                                                                {_.get(
                                                                    decedent,
                                                                    "dateOfDeath"
                                                                )
                                                                    ? moment(
                                                                          _.get(
                                                                              decedent,
                                                                              "dateOfDeath"
                                                                          )
                                                                      ).format(
                                                                          "MM-DD-YYYY"
                                                                      )
                                                                    : "-"}
                                                            </span>
                                                        </div>
                                                        {/* <div className="dashboard-card-keyvalue">
                                        <span className="dashboard-card-key">Arrangement Scheduled:</span>
                                        <span className="dashboard-card-value">09-07-19</span>
                                    </div> */}
                                                    </div>
                                                    <div className='dashboard-card-viewinfo'>
                                                        <button className='arrangements-button'>
                                                            BEGIN ARRANGEMENTS
                                                        </button>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    );
                                })}
                        </div>
                    </div>
                    { decedents && decedents.closedCases && decedents.closedCases.length ? <HistorySection historyDetails={decedents ? decedents.closedCases : ''}/> : null}
                </div>
            </div>
        </AppLayout>
    );
};

export default Dashboard;
