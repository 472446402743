import React, { useState } from "react";
import { Link } from "react-router-dom";
import CypressLogo from "../../../assets/images/clLogo.png";
import { Navbar, Nav, NavItem } from "reactstrap";

import PrivacyPolicy from "../../acceptPolicies/privacyPolicy";

const NavigationBottom = () => {
  const [viewPrivacyPolicy, setOpenPolicy] = useState(false);
  const currentYear = new Date().getFullYear()

  return (
    <div className="navbar-bottom">
      <Navbar>
        <div className="branding-content">
          <Link to="#" className="navbar-brand">
            <img src={CypressLogo} className="cypress-logo" alt="cypress lawn" />
          </Link>
          <p>
            <a href='https://cypresslawn.com/cypress-lawn-cemetery-association/' target="_blank" rel="noreferrer">
              {`© ${currentYear} Cypress Lawn Cemetery Association, All Rights Reserved`}
            </a>
          </p>
        </div>
        <Nav
          className="social-media-icon d-flex align-items-center justify-content-center justify-content-lg-start  flex-row"
          navbar
        >
          <NavItem>
            <Link className="navbar-link facebook-link d-flex align-items-center justify-content-center" to="#">
              <i className="fab fa-facebook-f"></i>
            </Link>
          </NavItem>
          <NavItem>
            <Link className="navbar-link yelp-link d-flex align-items-center justify-content-center" to="#">
              <i className="fab fa-yelp"></i>
            </Link>
          </NavItem>
        </Nav>
        <Nav
          className="terms-information d-flex align-items-center align-items-lg-center justify-content-center flex-lg-row"
          navbar
        >
          {/* <NavItem>
                            <Link className="navbar-link" to="#">Terms & Conditions</Link>
                        </NavItem> */}
          <NavItem>
            <Link to="#" className="navbar-link" onClick={() => setOpenPolicy(true)}>
              Privacy Policy
            </Link>
          </NavItem>
        </Nav>
        <PrivacyPolicy isView={viewPrivacyPolicy} toggle={() => setOpenPolicy(!viewPrivacyPolicy)} />
      </Navbar>
    </div>
  );
};

export default NavigationBottom;
