import React from "react";
import _ from "lodash";
import moment from "moment";
import MemoriesFiles from "./memories-files/memories-files";
import { Link } from "react-router-dom";
import LifeOfSection from "./lifOfSection";
import { fullName } from "../../../../utils/global";
import { memoryTypes } from "../../../utils/variables";
import Loader from "../../../utils/loader/loader";
import Career from "../../../../../src/assets/images/career.svg";
import Family from "../../../../../src/assets/images/family.svg";
import Education from "../../../../../src/assets/images/education.svg";
import Relationship from "../../../../../src/assets/images/relationship.svg";
import Military from "../../../../../src/assets/images/military.svg";
import Hobby from "../../../../../src/assets/images/hobby.svg";
import ImportantDates from "../../../../../src/assets/images/important-dates.svg";
import Rememberances from "../../../../../src/assets/images/rememberances.svg";

const MemoriesListing = ({
    opi,
    decedent,
    obituary,
    memoriesList,
    isLoggedIn,
    getObituary,
    obituaryIsLocked,
}) => {
    const name = fullName(decedent) || fullName(obituary);

    const getImage = type => {
        switch (type) {
            case "Career":
                return Career;
            case "Family":
                return Family;
            case "Education":
                return Education;
            case "Relationship":
                return Relationship;
            case "Military":
                return Military;
            case "Hobby":
                return Hobby;
            case "ImportantDates":
            case "Important Dates":
                return ImportantDates;
            case "Rememberances":
                return Rememberances;
            default:
                return "";
        }
    };

    return (
        <>
            {name && <h2>The Life of {name} </h2>}
            {obituary && (
                <LifeOfSection
                    isLoggedIn={isLoggedIn}
                    opi={opi}
                    data={obituary}
                    getObituary={getObituary}
                    obituaryIsLocked={obituaryIsLocked}
                ></LifeOfSection>
            )}
            {obituary !== null && (
                <div className='memory-list-wrapper'>
                    {memoriesList && memoriesList.length > 0 ? (
                        <div>
                            {memoriesList.map((memory, key) => {
                                const type = _.get(
                                    _.find(memoryTypes, {
                                        value: memory.memoryType,
                                    }),
                                    "label"
                                );
                                const { memoryByPerson = "" } = memory;
                                return (
                                    <div className='memory-card'>
                                        <div className='memory-card-header'>
                                            <p className='memory-title'>
                                                {memory.title}
                                            </p>
                                            <div className='memory-icon'>
                                                {type ? (
                                                    <>
                                                        <img
                                                            src={getImage(type)}
                                                            alt='InstagramIcon'
                                                            className='icon-wrapper'
                                                        />
                                                        <div class='title'>
                                                            {type}
                                                        </div>
                                                    </>
                                                ) : (
                                                    ""
                                                )}
                                            </div>
                                            <span className='memory-date'>
                                                {moment(
                                                    memory.dateOfMemory
                                                ).format("MM/DD/YYYY")}
                                            </span>
                                        </div>
                                        {memory.files.length > 0 && (
                                            <div className='memory-content'>
                                                <MemoriesFiles
                                                    files={memory.files}
                                                    memoryId={memory.id}
                                                />
                                            </div>
                                        )}
                                        <div className='memory-card-footer'>
                                            <span>
                                                {_.get(memory, "memory", "")}
                                            </span>
                                            {memoryByPerson && (
                                                <span className='memory-postedBy'>
                                                    Posted by{" "}
                                                    {memory.memoryByPerson} on{" "}
                                                    {moment(
                                                        memory.createdAt
                                                    ).format("MM/DD/YYYY")}
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    ) : (
                        <div style={{ textAlign: "center" }}>
                            There are no memories available
                        </div>
                    )}
                </div>
            )}
        </>
    );
};

MemoriesListing.propTypes = {};

export default MemoriesListing;
