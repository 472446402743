import React, { useEffect, useCallback, useMemo, useState } from "react";
import axios from "axios";
import _ from "lodash";
import useAsync from "react-use-async-hook";

import { useSetLoading } from "../../../../utils/hooks/loader";

const fetchFromYoutube = url => {
    return axios({
        url: `https://www.youtube.com/oembed?url=${url}&format=json`,
        method: "GET",
    });
};

const fetchFromVimeo = url => {
    return axios({
        url: `https://vimeo.com/api/oembed.json?url=${url}`,
        method: "GET",
    });
};

const VideoThumbnail = ({
    video,
    isThumbnail,
    flag,
    memoryId,
    setVideoTitleData,
}) => {
    const [isYoutube, setIsYoutube] = useState(false);
    const [isVimeo, setIsVimeo] = useState(false);
    const [link, setLink] = useState("");
    const [videoType, setVideoType] = useState("");
    const [embeddVideo, setEmbeddVideo] = useState("");

    useEffect(() => {
        if (video) {
            let fileSrc = video.src;
            let extension, file;
            if (video.fileType === "Video") {
                extension = fileSrc.split(/[#?]/)[0].split(".").pop().trim();

                if (extension === "flv") {
                    setVideoType("video/x-flv");
                } else if (extension === "mov") {
                    setVideoType("");
                } else {
                    setVideoType("video/" + extension);
                }
            }
            fileSrc = _.replace(fileSrc, " ", "");
            fileSrc = _.split(fileSrc, " ")[0];
            const fileIsYoutube = fileSrc.search("youtube");
            const fileIsVimeo = fileSrc.search("vimeo");
            if (
                fileIsYoutube > 0 &&
                video.fileType === "Video" &&
                !isThumbnail
            ) {
                setLink(fileSrc);
                setIsYoutube(true);
            } else if (fileIsVimeo > 0 && video.fileType === "Video") {
                setLink(fileSrc);
                setIsVimeo(true);
            } else {
                setLink(video);
            }
        }
    }, [video]);

    const [thumbnailUrl, setThumbnailUrl] = useState(null);
    const [videoTitle, setVideoTitle] = useState("");

    const fetchVideoData = url => {
        let urlObject = new URL(url);

        if (urlObject.host === "www.youtube.com") {
            executeGetVideoMeta(url, "youtube");
        } else if (urlObject.host === "vimeo.com") {
            executeGetVideoMeta(url, "vimeo");
        } else {
        }
    };

    const fetchThumbanilFromUploadedVideo = () => {
        var _VIDEO = document.querySelector(`#video-file-${flag}-${memoryId}`),
            _CANVAS = document.querySelector(
                `#canvas-file-${flag}-${memoryId}`
            ),
            _CTX = _CANVAS.getContext("2d");

        _VIDEO.addEventListener("loadedmetadata", function () {
            // Set canvas dimensions same as video dimensions
            _CANVAS.width = _VIDEO.videoWidth;
            _CANVAS.height = _VIDEO.videoHeight;
        });
    };

    useEffect(() => {
        if (link && (isYoutube || isVimeo)) {
            const linkUrl = link && link.src ? link.src : link;
            fetchVideoData(linkUrl);
        } else {
            setThumbnailUrl("");
            setVideoTitle("");
            if (setVideoTitleData) {
                setVideoTitleData("");
            }
        }
    }, [link]);

    const {
        data: videoData,
        loading: getVideoMetaLoading,
        execute: executeGetVideoMeta,
    } = useAsync({
        autoExecute: false,
        task: useCallback((url, host) => {
            if (host === "youtube") {
                return fetchFromYoutube(url);
            } else {
                return fetchFromVimeo(url);
            }
        }, []),
        dataLoader: useCallback(response => {
            return _.get(response, "data");
        }, []),
        onError: useCallback(error => {
            if (error.response && _.get(error, "status") === 401) {
                let newLink = video.src.replace("watch?v=", "embed/");
                setEmbeddVideo(newLink);
            }
        }, []),
    });

    useEffect(() => {
        if (videoData) {
            setThumbnailUrl(_.get(videoData, "thumbnail_url"));
            setVideoTitle(_.get(videoData, "title"));
            if (setVideoTitleData) {
                setVideoTitleData(_.get(videoData, "title"));
            }
            if (
                (!isThumbnail &&
                    _.get(videoData, "provider_name") === "Vimeo") ||
                _.get(videoData, "provider_name") === "YouTube"
            ) {
                const embeddedCode = _.get(videoData, "html");
                const videoDiv = document.getElementById(
                    `video-file-${flag}-${memoryId}`
                );
                if (videoDiv) {
                    videoDiv.innerHTML = embeddedCode;
                }
            }
        }
    }, [videoData]);

    const showLoading = useMemo(
        () => getVideoMetaLoading,
        [getVideoMetaLoading]
    );
    useSetLoading(showLoading);

    return isYoutube || isVimeo ? (
        <div id={`video-file-${flag}-${memoryId}`} className='video-file' />
    ) : (
        <video
            preload='auto'
            id={`video-file-${flag}-${memoryId}`}
            controls
            className='video-file'
        >
            <source src={link.src} type={videoType} />
        </video>
    );
};

export default VideoThumbnail;
