import addNotification from "../components/utils/notification/notification";

const isValidFileFormat = (src) => {
  let valid;
  if (src.indexOf("data:image") > -1) {
    valid = true;
  } else {
    valid = false;
  }
  return valid;
};

export const getValidFileUrl = (files) => {
  var file = Array.isArray(files) ? files[0] : files;
  var reader = new FileReader();
  let src = "";
  return new Promise((resolve, reject) => {
    if (file.size < 50000000) {
      reader.onloadend = function () {
        src = reader.result;
        if (isValidFileFormat(src)) {
          resolve(src);
        } else {
          reject("Upload Valid File format(images/*)");
        }
      };

      if (file) {
        reader.readAsDataURL(file);
      } else {
        src = "";
        reject("No Files");
      }
    } else {
      reject("Upload an image with size less than 5MB");
    }
  });
};

export const getValidFileUrlForImg = (files) => {
  var file = Array.isArray(files) ? files[0] : files;
  var reader = new FileReader();
  let src = "";
  return new Promise((resolve, reject) => {
    if (fileSizeInMB(file?.size) < 20) {
      reader.onloadend = function () {
        src = reader.result;
        if (isValidFileFormat(src)) {
          resolve(src);
        } else {
          reject("Upload Valid File format(images/*)");
        }
      };

      if (file) {
        reader.readAsDataURL(file);
      } else {
        src = "";
        reject("No Files");
      }
    } else {
      reject("Upload an image with size less than 20MB");
      addNotification({
        type: "danger",
        title: "Error",
        message: "Upload an image with size less than 20MB",
    });
    }
  });
};

export const fullName = (personalDetails) => {
  const nameObj = [personalDetails?.firstName, personalDetails?.middleName, personalDetails?.lastName];

  return nameObj.filter((ele) => ele).join(" ");
};

export const fileSizeInMB = (bytes) => (bytes / (1024 * 1024)).toFixed(2);
