import _ from "lodash";
import React, { useEffect, useState, forwardRef} from "react";
import ReactQuill, { Quill } from "react-quill";
import "quill-paste-smart";
import { getString } from "./templateString";
import { fontFamily, fontSize, fontSizeMetric } from "./constants";
import addNotification from "../../utils/notification/notification";

import "react-quill/dist/quill.snow.css";

// const formats = ["font", "size", "bold", "italic", "underline", "align", "color"];

const Size = Quill.import("formats/size");
Size.whitelist = fontSize;
Quill.register(Size, true);

const Font = Quill.import("formats/font");
Font.whitelist = fontFamily;
Quill.register(Font, true);

var Parchment = Quill.import("parchment");
let CustomClass = new Parchment.Attributor.Class("custom", "ql-custom", {
  scope: Parchment.Scope.INLINE,
});

Quill.register(CustomClass, true);

const CustomToolbar = ({ index,style={}, allowedSize = [], type }) => {
    const { size, font } = style;
    return (
        <div id={`toolbar-${index}`} onMouseDown={e => e.preventDefault()}>
            <select className='ql-font'>
                <option
                    value='arial'
                    selected={font === "arial" ? true : false}
                >
                    Arial
                </option>
                <option
                    value='comicsans'
                    selected={font === "comicsans" ? true : false}
                >
                    Comic Sans
                </option>
                <option
                    value='couriernew'
                    selected={font === "couriernew" ? true : false}
                >
                    Courier New
                </option>
                <option
                    value='georgia'
                    selected={font === "georgia" ? true : false}
                >
                    Georgia
                </option>
                <option
                    value='helvetica'
                    selected={font === "helvetica" ? true : false}
                >
                    Helvetica
                </option>
                {/* <option value="dancing-script" selected={font === 'dancing-script' ? true: false}>Dancing Script</option> */}
                <option
                    value='lucida'
                    selected={font === "lucida" ? true : false}
                >
                    Lucida
                </option>
                <option
                    value='playball'
                    selected={font === "playball" ? true : false}
                >
                    Playball
                </option>
                <option
                    value='cinzel'
                    selected={font === "cinzel" ? true : false}
                >
                    Cinzel
                </option>
                <option
                    value='roboto'
                    selected={font === "roboto" ? true : false}
                >
                    Roboto
                </option>
                <option
                    value='robotoslab'
                    selected={font === "robotoslab" ? true : false}
                >
                    Roboto Slab
                </option>
                <option value='lato' selected={font === "lato" ? true : false}>
                    Lato
                </option>
                <option
                    value='martel'
                    selected={font === "martel" ? true : false}
                >
                    Martel
                </option>
            </select>
            <select className='ql-size'>
                {!type || Boolean(type && allowedSize[0]) ? (
                    <option
                        value='fs12'
                        selected={size === "fs12" ? true : false}
                    >
                        12
                    </option>
                ) : (
                    ""
                )}
                {!type || Boolean(type && allowedSize[1]) ? (
                    <option
                        value='fs13'
                        selected={size === "fs13" ? true : false}
                    >
                        13
                    </option>
                ) : (
                    ""
                )}
                {!type || Boolean(type && allowedSize[2]) ? (
                    <option
                        value='small'
                        selected={size === "small" ? true : false}
                    >
                        14
                    </option>
                ) : (
                    ""
                )}
                {!type || Boolean(type && allowedSize[3]) ? (
                    <option
                        value='fs15'
                        selected={size === "fs15" ? true : false}
                    >
                        15
                    </option>
                ) : (
                    ""
                )}
                {!type || Boolean(type && allowedSize[4]) ? (
                    <option
                        value='smmedium'
                        selected={size === "smmedium" ? true : false}
                    >
                        16
                    </option>
                ) : (
                    ""
                )}
                {!type || Boolean(type && allowedSize[5]) ? (
                    <option
                        value='fs17'
                        selected={size === "fs17" ? true : false}
                    >
                        17
                    </option>
                ) : (
                    ""
                )}
                {!type || Boolean(type && allowedSize[6]) ? (
                    <option
                        value='medium'
                        selected={size === "medium" ? true : false}
                    >
                        18
                    </option>
                ) : (
                    ""
                )}
                {!type || Boolean(type && allowedSize[7]) ? (
                    <option
                        value='fs19'
                        selected={size === "fs19" ? true : false}
                    >
                        19
                    </option>
                ) : (
                    ""
                )}
                {!type || Boolean(type && allowedSize[8]) ? (
                    <option
                        value='fs20'
                        selected={size === "fs20" ? true : false}
                    >
                        20
                    </option>
                ) : (
                    ""
                )}
                {!type || Boolean(type && allowedSize[9]) ? (
                    <option
                        value='lgmedium'
                        selected={size === "lgmedium" ? true : false}
                    >
                        21
                    </option>
                ) : (
                    ""
                )}
                {!type || Boolean(type && allowedSize[10]) ? (
                    <option
                        value='fs22'
                        selected={size === "fs22" ? true : false}
                    >
                        22
                    </option>
                ) : (
                    ""
                )}
                {!type || Boolean(type && allowedSize[11]) ? (
                    <option
                        value='fs23'
                        selected={size === "fs23" ? true : false}
                    >
                        23
                    </option>
                ) : (
                    ""
                )}
                {!type || Boolean(type && allowedSize[12]) ? (
                    <option
                        value='large'
                        selected={size === "large" ? true : false}
                    >
                        24
                    </option>
                ) : (
                    ""
                )}
                {!type || Boolean(type && allowedSize[13]) ? (
                    <option
                        value='fs25'
                        selected={size === "fs25" ? true : false}
                    >
                        25
                    </option>
                ) : (
                    ""
                )}
                {!type || Boolean(type && allowedSize[14]) ? (
                    <option
                        value='fs26'
                        selected={size === "fs26" ? true : false}
                    >
                        26
                    </option>
                ) : (
                    ""
                )}
                {!type || Boolean(type && allowedSize[15]) ? (
                    <option
                        value='fs27'
                        selected={size === "fs27" ? true : false}
                    >
                        27
                    </option>
                ) : (
                    ""
                )}
                {!type || Boolean(type && allowedSize[16]) ? (
                    <option
                        value='fs28'
                        selected={size === "fs28" ? true : false}
                    >
                        28
                    </option>
                ) : (
                    ""
                )}
                {!type || Boolean(type && allowedSize[17]) ? (
                    <option
                        value='huge'
                        selected={size === "huge" ? true : false}
                    >
                        32
                    </option>
                ) : (
                    ""
                )}
            </select>
            <button className='ql-bold' />
            <button className='ql-italic' />
            <button className='ql-underline' />
            <select className='ql-align'>
                <option value=''>left</option>
                <option value='center' selected>
                    center
                </option>
                <option value='right'>right</option>
                <option value='justify'>justify</option>
            </select>
        </div>
    );
};

const Editor = forwardRef(({
    value,
    setEdit,
    style={},
    index,
    handleChange,
    html,
    type,
    setErrorLog,
    textEdiorRef,
    selectedLocation,
    selectedPoem,
    inputRef,
    eventName,
    setEventName,
}) => {
    const [textHTML, setTextHTML] = useState("");
    const [isActive, setActive] = useState(false);

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }, [inputRef.current]);

    useEffect(() => {
        setActive(true);
        return () => {
            setActive(false);
        };
    }, []);

    useEffect(() => {
        if (!_.isEmpty(style) && value) {
            setTextHTML(getString(style, value));
        } else if (value) {
            setTextHTML(`<p><span>${value}</span></p>`);
        } else {
            setTextHTML(`<p></p>`);
        }
    }, []);

    const setEditorValue = html => {
        const ele = document.getElementById(`editor-${index}`);
        const ele_children = ele.childNodes[0].childNodes;
        for (let i of ele_children) {
            if (_.includes(i.className, "ql-editor")) {
                i.innerHTML = html;
                i.classList.remove("ql-blank");
            }
        }
    };

    const replaceLast = function (str, what, replacement) {
        var pcs = str.split(what);
        var lastPc = pcs.pop();
        return pcs.join(what) + replacement + lastPc;
    };

    const checkIfTextLengthIsValid = (scrollableHeight, parentHeight = 720 ) => {
        if (scrollableHeight / parentHeight > 1.2) {
            return false;
        }

        return true;
    };

    const measureInputLength = (
        text,
        updatedStyle,
        scrollableHeight = 0,
        parentHeight
    ) => {
        let { line } = style;
        const currentStyle = {
            font: _.get(updatedStyle, "font") || _.get(style, "font"),
            size: _.get(updatedStyle, "size") || _.get(style, "size"),
            style: _.get(updatedStyle, "style") || _.get(style, "style"),
        };

        text = text.split(/\r?\n/);
        let count = text.length;
        if (line < count) {
            return false;
        } else {
            for (let t of text) {
                let fontStyle = `${
                    _.get(currentStyle, "font") === "cinzel"
                        ? "small-caps"
                        : "normal"
                } ${fontSizeMetric[_.get(currentStyle, "size")]}px ${_.get(
                    currentStyle,
                    "font"
                )}`;
                let width = displayTextWidth(
                    `${
                        _.get(currentStyle, "font") === "cinzel"
                            ? _.toUpper(t)
                            : t
                    }`,
                    fontStyle
                );
                if (width >= 400) {
                    count += parseInt(width / 400);
                }
            }

            if (scrollableHeight > 200 || parentHeight > 200) {
                const isOverflow = checkIfTextLengthIsValid(
                    scrollableHeight,
                    parentHeight
                );
                if (isOverflow === false) {
                    return false;
                }
            }

            if (line < count) {
                return false;
            } else {
                return true;
            }
        }
    };

    const createAllowedSizeList = _size => {
        let list = [];
        for (let i = 0; i < fontSize.length; i++) {
            list.push(fontSize[i]);
        }
        return list;
    };

    const [allowedSize, setAllowedSize] = useState(
        createAllowedSizeList(_.get(style, "size"))
    );
    const [errorMsg, setError] = useState("");

    useEffect(() => {
        setErrorLog(Boolean(errorMsg));
        if (errorMsg !== '') {
            addNotification({
                type: "danger",
                title: "Error",
                message: "Max text limit reached try reducing the characters",
            });
            const ele = document.querySelector('.ql-editor');
            ele.style.border = "2px solid red"
        }
        else {
            const ele = document.querySelector('.ql-editor');
            ele.style.border = "1px solid #80bdff"
        }
    }, [errorMsg]);

    let { size, line, font, align, bold } = style;

    const [showToastMsg, setToastMsg] = useState(false);
    const [showErrorNotification, setErrorNotification] = useState(false);


    const htmlDecode = function (input) {
        const e = document.createElement("textarea");
        e.innerHTML = input;
        return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
    };

    const handlePaste = (content, pastedText, delta) => {
        pastedText = pastedText
            .replaceAll('"', "")
            .replaceAll("\n", `</p><p class="ql-align-${align}">`);
        const val = delta.ops.find(element => {
            const { attributes = {} } = element;
            return Boolean(attributes.font);
        });

        let pastedDeltaText = "";
        const pastedDeltaTextData = delta.ops.find(element => {
            const { attributes = null, insert = "" } = element;
            if (insert && !attributes) {
                return insert;
            }
        });
        if (pastedDeltaTextData) {
            pastedDeltaText = pastedDeltaTextData.insert;
        }

        const currentFont = _.get(val, "attributes.font", "");
        const customFont = currentFont || font;
        const innerElem = bold ? "strong" : "span";
        let newContent = htmlDecode(content);
        if (
            content.includes(
                `</${innerElem}>${pastedText}<${innerElem} class="ql-font-${font} ql-size-${size}">`
            )
        ) {
            newContent = content.replaceAll(
                `</${innerElem}>${pastedText}<${innerElem} class="ql-font-${font} ql-size-${size}">`,
                pastedText
            );
        } else if (content.includes(`</${innerElem}>${pastedText}`)) {
            newContent = content.replaceAll(
                `</${innerElem}>${pastedText}`,
                `${pastedText}</${innerElem}>`
            );
        } else if (content.includes(`</${innerElem}> ${pastedText}`)) {
            newContent = content.replaceAll(
                `</${innerElem}> ${pastedText}`,
                `${pastedText}</${innerElem}>`
            );
        } else if (content.includes(`<p> ${pastedText}`)) {
            newContent = content.replaceAll(
                `<p> ${pastedText}`,
                `<p class="ql-align-${align}"> <${innerElem} class="ql-font-${customFont} ql-size-${size}">${pastedText}</${innerElem}>`
            );
        } else if (content.includes(`<p>${pastedText}`)) {
            newContent = content.replaceAll(
                `<p>${pastedText}`,
                `<p class="ql-align-${align}"><${innerElem} class="ql-font-${customFont} ql-size-${size}">${pastedText}</${innerElem}>`
            );
        } else if (
            content.includes(`<p class="ql-align-${align}">${pastedText}`)
        ) {
            newContent = content.replaceAll(
                `<p class="ql-align-${align}">${pastedText}`,
                `<p class="ql-align-${align}"><${innerElem} class="ql-font-${customFont} ql-size-${size}">${pastedText}</${innerElem}>`
            );
        } else if (
            content.includes(`<p class="ql-align-${align}"> ${pastedText}`)
        ) {
            newContent = content.replaceAll(
                `<p class="ql-align-${align}"> ${pastedText}`,
                `<p class="ql-align-${align}"><${innerElem} class="ql-font-${customFont} ql-size-${size}"> ${pastedText}</${innerElem}>`
            );
        } else if (
            content.includes(
                `</${innerElem}>${pastedDeltaText}<${innerElem} class="ql-font-${font} ql-size-${size}">`
            )
        ) {
            newContent = content.replaceAll(
                `</${innerElem}>${pastedDeltaText}<${innerElem} class="ql-font-${font} ql-size-${size}">`,
                pastedDeltaText
            );
        } else if (content.includes(`</${innerElem}>${pastedDeltaText}`)) {
            newContent = content.replaceAll(
                `</${innerElem}>${pastedDeltaText}`,
                `${pastedDeltaText}</${innerElem}>`
            );
        } else if (content.includes(`</${innerElem}> ${pastedDeltaText}`)) {
            newContent = content.replaceAll(
                `</${innerElem}> ${pastedDeltaText}`,
                `${pastedDeltaText}</${innerElem}>`
            );
        } else if (content.includes(`<p> ${pastedDeltaText}`)) {
            newContent = content.replaceAll(
                `<p> ${pastedDeltaText}`,
                `<p class="ql-align-${align}"> <${innerElem} class="ql-font-${customFont} ql-size-${size}">${pastedDeltaText}</${innerElem}>`
            );
        } else if (content.includes(`<p>${pastedDeltaText}`)) {
            newContent = content.replaceAll(
                `<p>${pastedDeltaText}`,
                `<p class="ql-align-${align}"><${innerElem} class="ql-font-${customFont} ql-size-${size}">${pastedDeltaText}</${innerElem}>`
            );
        } else if (
            content.includes(`<p class="ql-align-${align}">${pastedDeltaText}`)
        ) {
            newContent = content.replaceAll(
                `<p class="ql-align-${align}">${pastedDeltaText}`,
                `<p class="ql-align-${align}"><${innerElem} class="ql-font-${customFont} ql-size-${size}">${pastedDeltaText}</${innerElem}>`
            );
        } else if (
            content.includes(`<p class="ql-align-${align}"> ${pastedDeltaText}`)
        ) {
            newContent = content.replaceAll(
                `<p class="ql-align-${align}"> ${pastedDeltaText}`,
                `<p class="ql-align-${align}"><${innerElem} class="ql-font-${customFont} ql-size-${size}"> ${pastedDeltaText}</${innerElem}>`
            );
        } else {
            newContent = newContent.replaceAll(
                "<p>",
                `<p class="ql-align-${align}">`
            );
            if (newContent.includes(pastedText)) {
                const updatedText = pastedText.replaceAll(
                    `</p><p class="ql-align-${align}">`,
                    "\n"
                );
                newContent = newContent.replaceAll(
                    pastedText,
                    `<${innerElem} class="ql-align-center">${updatedText}</${innerElem}>`
                );
            }
        }

        return newContent;
    };

    const getHtmlFromString = (oldString) => {
        var parser = new DOMParser();
        var doc = parser.parseFromString(oldString, "text/html");
        return doc;
    }

    const handleKeyDown = async event => {

        if ((event.metaKey || event.ctrlKey)) {
            setEventName('undo')
        }
        const ele = document.getElementById(`editor-${index}`);
        const mainClass = ele?.childNodes[0]?.childNodes[0]
        const mainOuterHtml = mainClass.innerHTML
        const mainDoc = getHtmlFromString(mainOuterHtml)
        if (event.key === 'Enter') {
            event.preventDefault()
            event.stopPropagation();
            const quill = inputRef?.current.getEditor();
            const range = quill.getSelection();
            //On enter new p tag is creating by default which taking class name and format from previous tag
            //to separate new p tag we are adding new class with name newLine
            if (range?.index) {
             quill.format("custom", "newLine");
            }
        }
        let { editor } = inputRef.current;
        let text = editor.getText();
        /** */

        const elem = event.target;

        let parentHeight = 0;
        let scrollHeight = 0;
        if (elem) {
            scrollHeight = elem?.scrollHeight;

            if (scrollHeight > 738) {  
                const msg = "LIMIT_OVER"
                setError(msg)
                setErrorLog(true)
            }
        }
        const ele_children = ele.childNodes[0].childNodes;
        for (let i of ele_children) {
            if (
                _.includes(i.className, "ql-editor") &&
                event.key === "Backspace" &&
                text.length === 1
            ) {
                i.classList.remove("ql-blank");
                i.innerHTML = `<p class="ql-align-${align}"><${
                    bold ? "strong" : "span"
                } class="ql-font-${font} ql-size-${size}"><br ></${
                    bold ? "strong" : "span"
                }></p>`;
                break;
            }
        }

        // mainClass?.childNodes?.forEach((childNode, i) => {
        //     //On backspace of p tag with footeLocation previous p tag is deleting 
        //     //but on editor its not updating to handle that here i am updating
        //     //same for cityName
        // })

        /** */
        /** Text Validation */
        let check = false;
        if (event.key !== "Backspace" && errorMsg) {   
            event.preventDefault();
        } else {
            check = true;
        }
        if (
            event.key.toLowerCase() === "v" &&
            (event.metaKey || event.ctrlKey)
        ) {
            const pastedText = await navigator.clipboard.readText();
            let validate = measureInputLength(
                text.slice(0, -1) + pastedText,
                null,
                scrollHeight
            );
            for (let i of ele_children) {
                if (_.includes(i.className, "ql-editor")) {
                    i.innerHTML = handlePaste(
                        i.innerHTML,
                        pastedText,
                        editor.getContents()
                    );
                }
            }

            if (!validate & (event.key !== "Backspace")) {
                setError("PASTE_LIMIT_OVER");
                setErrorLog(true);
                event.preventDefault();
            } else if (check && validate) {

                setError("");
            }
        }
        
        // else if (
        //     (event.which >= 32 && event.which <= 120) ||
        //     [
        //         0, 8, 13, 186, 187, 188, 189, 190, 191, 192, 219, 220, 221, 222,
        //     ].includes(event.which)
        // ) {
        //     let newText = text;
        //     if (event.which == 13) {
        //         newText = newText.replace(/[^\x20-\x7E]/g, "") + "\n";
        //     } else if (event.which != 8) {
        //         newText = newText.slice(0, -1).concat(event.key);
        //     } else {
        //         newText = newText.slice(0, -1);
        //     }
        //     let validate = false;
        //     if (check || !(event.metaKey || event.ctrlKey)) {
        //         validate = measureInputLength(newText, null, scrollHeight);
        //     } else validate = true;
        //     if (check && validate) {
        //         setError("");
        //     } else if (!check && !validate) {
        //         setError("LIMIT_REACH");
        //         setErrorLog(true);
        //         setErrorNotification(true);
        //         event.preventDefault();
        //     }
        // } else {
        //     event.preventDefault();
        // }
        
    };

    const handleFocus = event => {
        let { editor } = inputRef.current;
        let text = editor.getText();
        /** */
        const ele = document.getElementById(`editor-${index}`);
        const ele_children = ele.childNodes[0].childNodes;
        for (let i of ele_children) {
            if (_.includes(i.className, "ql-editor") && text.length === 1) {
                i.classList.remove("ql-blank");
                i.innerHTML = `<p class="ql-align-${align}"><${
                    bold ? "strong" : "span"
                } class="ql-font-${font} ql-size-${size}"><br ></${
                    bold ? "strong" : "span"
                }></p>`;
                break;
            }
        }
    };

    useEffect(() => {
      if (inputRef.current) {
        inputRef.current.getEditor().root.innerHTML = html;
      }
    }, [selectedPoem, selectedLocation]);
    
    return (
        <div
            className='text-editor'
            onBlur={() => {
                if (!errorMsg) {
                    inputRef.current.blur();
                }
            }}
            ref={textEdiorRef}
        >
            <div>
                <CustomToolbar
                    index={index}
                    style={style}
                    allowedSize={allowedSize}
                    type={type === "content" || ""}
                />
                <ReactQuill
                    id={`editor-${index}`}
                    defaultValue={html || textHTML}
                    onFocus={handleFocus}
                    onKeyDown={handleKeyDown}
                    onChange={(content, delta, source, editor) => {
                        let text = editor.getText();
                        let currentStyleData = delta.ops.find(element => {
                            const { attributes = null, insert = "" } = element;
                            return (
                                !insert &&
                                attributes &&
                                (attributes.size ||
                                    attributes.font ||
                                    attributes.style)
                            );
                        });

                        let styleAttributes = null;
                        if (currentStyleData) {
                            const {
                                size = null,
                                font = null,
                                style = null,
                            } = currentStyleData.attributes;
                            styleAttributes = { size, font, style };
                        }

                        const elm = document.getElementById(`editor-${index}`);
                        var clsElem =
                            elm.getElementsByClassName("ql-align-center");
                        const scrollHt =
                            clsElem && clsElem[0] ? clsElem[0].scrollHeight : 0;
                     
                        // let validate = measureInputLength(
                        //     text.slice(0, -1),
                        //     styleAttributes,
                        //     scrollHt
                        // );
                        let validate = true
                        if (elm?.scrollHeight > 738) {
                            validate = false
                        }
                        if (!validate && source !== "api") {
                            setError("LIMIT_REACH");
                            setErrorLog(true);
                            setErrorNotification(true);
                        }
                        if (validate) {
                            setError("");
                            setErrorLog(false);
                        }
                        if (source === "api" && content !== html) {
                            let updatedHtml = html.replace("\n", "");
                            updatedHtml = replaceLast(
                                updatedHtml,
                                "\n",
                                ""
                            ).replaceAll("  ", "");
                            setEditorValue(updatedHtml);
                            handleChange(updatedHtml);
                        } else if (content !== html) {           
                            handleChange(content);
                        }
                    }}
                    modules={{
                        toolbar: {
                            container: `#toolbar-${index}`,
                        },
                        history: {
                            delay: 1000,
                            maxStack: 100,
                            userOnly: true
                          },
                        clipboard: {
                            matchVisual: false,
                        },
                    }}
                    className={`ql-height-${fontSizeMetric[size]}-${line}`}
                    ref={inputRef}
                />
                {errorMsg && (
                    <>
                        <span className="limit-error">
                            Max text limit reached try reducing the characters
                        </span>
                       
                    </>
                )}
            </div>
        </div>
    );
});

function displayTextWidth(text, font) {
    let canvas =
        displayTextWidth.canvas ||
        (displayTextWidth.canvas = document.createElement("canvas"));
    let context = canvas.getContext("2d");
    context.font = font;
    let metrics = context.measureText(text);
    return metrics.width;
}

export default Editor;
