import axios from "axios";
import { getAccessToken, getMfaAccessToken } from "../utils";
import { API_URL } from '../constants/appConstants'
import store from "../store/store";
import _ from 'lodash';
import Cookies from "universal-cookie";

const instance = axios.create({
  baseURL: API_URL
});

instance.interceptors.request.use(
 async function (config) {
    const cookies = new Cookies();
    const isAzureMFAEnabled = process.env.REACT_APP_IS_AZURE_MFA_ENABLED;
    const role = cookies.get('role')
    const token =
      (isAzureMFAEnabled && role === 'cl-arranger')
        ? await getMfaAccessToken()
        : getAccessToken();
    if(token) {
    config.headers = {
      ...config.headers,
      'Strict-Transport-Security': 'max-age=65540; includeSubDomains',
      Authorization: `Bearer ${token}`,
    }
  } else {
    config.headers = {
      ...config.headers,
      'Strict-Transport-Security': 'max-age=65540; includeSubDomains',
    }
  }
    return config
  }
)

instance.interceptors.response.use(
  function(response) {
    return response;
  },
  function(error) {
    if( _.get(error, 'response.data.message') === "Unauthorized access Forbidden"){
      store.dispatch({
        type: 'UNAUTHORIZED_WARNING',
      })
    }
    else if (_.get(error, 'response.status') === 401) {
      store.dispatch({
        type: 'SESSION_EXPIRY_WARNING',
      })
    }
    return Promise.reject(error);
  }
);

export default instance;