import React, { useEffect, useCallback, useMemo, useState } from "react";
import { Link, useParams, useLocation, Redirect } from "react-router-dom";
import _ from "lodash";
import useAsync from "react-use-async-hook";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { FormControlLabel, Switch } from "@material-ui/core";
import AppLayout from "../../../appLayout";
import Obituary from "../obituary";
import { average } from "geolocation-utils";
import {
    getMemoriesList,
    decedentAPI,
    privacyModeAPI,
} from "../../../../api/memories";
import { getObituary } from "../../../../api/obituary";
import { getLocationData } from "../../../../api/location";
import { useSetLoading } from "../../../utils/hooks/loader";
import { apiErrorHandler } from "../../../../utils/handlers";
import { buildPanoramicViewURL } from "../../../utils/functions";

import FileIcon from "../../../../../src/assets/images/file-text.svg";
import MapIcon from "../../../../../src/assets/images/map.svg";
import MapPinIcon from "../../../../../src/assets/images/map-pin.svg";
import NavigationIcon from "../../../../../src/assets/images/navigation.svg";
import InstagramIcon from "../../../../../src/assets/images/instagram.svg";
import ProfilePic from "../../../../../src/assets/images/profile-pic.svg";
import MemoriesListing from "../memoriesListing/memoriesListing";
import MemoriesFilters from "../memoriesListing/memories-filters";
import { data } from "azure-maps-control";
import { getAccessToken } from "../../../../utils";
import addNotification from "../../../utils/notification/notification";

let currentLat, currentLong;
function showPosition(position) {
    currentLat = position.coords.latitude;
    currentLong = position.coords.longitude;
}

const Dashboard = ({ session }) => {
    const { opi } = useParams();
    const { pathname } = useLocation();
    const [loader, setLoader] = useState(false);

    const [memoryType, setMemoryType] = useState([]);
    const [openObituary, setOpenObituary] = useState(false);
    const [redirectToFAA, setBackToFAA] = useState(false);
    const [userData, setUserData] = useState({});
    const [userNotFound, setUserNotFound] = useState(false);
    const [privatePage, setPrivatePage] = useState(false);

    const openPanoramicView = () => {
        const datas = cemeteryLocation.geoJson;
        const bounds = data.BoundingBox.fromData(datas);
        const query = {
            lat: bounds?.[1],
            long: bounds?.[0],
            first: decedentData.firstName,
            last: decedentData.lastName,
            location: cemeteryLocation.propertyName,
        };
        window.open(buildPanoramicViewURL(query), "_blank");
    };

    const getAvgLatLan = () => {
        let avgLatLan = {},
            geoData;
        const locations = _.get(
            cemeteryLocation,
            "geoJson.geometry.coordinates",
            ""
        );
        if (locations) {
            if (
                _.get(cemeteryLocation, "geoJson.geometry.type") ===
                "MultiPolygon"
            ) {
                geoData = _.flattenDepth(locations, 2);
            } else {
                geoData = _.flatten(locations);
            }
            var objs = geoData.map(function (x) {
                return {
                    lat: x[1],
                    lng: x[0],
                };
            });
            avgLatLan = average(objs);
        }
        return avgLatLan;
    };

    const getDirectionsMap = async () => {
        const avgLatLan = getAvgLatLan();
        const query = `https://www.google.com/maps/dir/?api=1&amp&origin=${currentLat},${currentLong}&destination=${avgLatLan.lat},${avgLatLan.lng}&travelmode=DRIVING`;
        window.open(query, "_blank");
    };

    const getBurialLocation = () => {
        const avgLatLan = getAvgLatLan();
        const query = `https://maps.google.com/?q=${avgLatLan.lat},${avgLatLan.lng}&amp;t=k`;
        window.open(query, "_blank");
    };

    /**
     * Get Memory Listing
     */
    const {
        data: memoriesList,
        loading: memoriesLoading,
        execute: refreshList,
    } = useAsync({
        task: useCallback((opi, memoryType) => {
            return getMemoriesList(opi, memoryType);
        }, []),
        dataLoader: useCallback(res => {
            let result = _.get(res, "data", []);
            result = _.isEmpty(result) ? [] : result;
            return result;
        }, []),
        initialData: useMemo(() => null, []),
        onError: useCallback(error => {
            if (
                document.getElementsByClassName("notification-danger").length ==
                0
            ) {
                apiErrorHandler(error);
            }
        }, []),
        autoExecute: false,
    });

    /**
     * Get Decedent using API
     */
    const {
        data: decedentData,
        loading: decedentAPILoading,
        execute: executeDecedentAPI,
    } = useAsync({
        autoExecute: false,
        initialData: useMemo(() => null, []),
        task: decedentAPI,
        dataLoader: useCallback(response => {
            return _.get(response, "data.decedent", {});
        }, []),
        onError: useCallback(error => {
            if (
                error.response &&
                error.response.status === 403 &&
                !session.isLoggedIn
            ) {
                setPrivatePage(true);
            } else {
                setUserNotFound(true);
            }

            if (
                document.getElementsByClassName("notification-danger").length ==
                0
            ) {
                apiErrorHandler(error);
            }
        }, []),
    });

    /**
     * Fetch Obituary details
     */
    const {
        data: obituary,
        loading: getObituaryLoader,
        execute: executeObituary,
    } = useAsync({
        autoExecute: false,
        task: getObituary,
        dataLoader: useCallback(res => {
            return _.get(res, "data.obituary");
        }, []),
        onError: useCallback(error => {
            if (
                document.getElementsByClassName("notification-danger").length ==
                0
            ) {
                apiErrorHandler(error);
            }
        }, []),
    });

    /**
     * Fetch Cemetery Location details
     */
    const { data: cemeteryLocation, execute: executeCemeteryLocation } =
        useAsync({
            autoExecute: false,
            task: getLocationData,
            dataLoader: useCallback(res => {
                return _.get(res, "data.cemeteryLocation");
            }, []),
        });

    /**
     * Get initial data
     */
    useEffect(() => {
        if (opi) {
            refreshList(opi);
            executeDecedentAPI(opi);
            executeObituary(opi);
            executeCemeteryLocation(opi);
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(showPosition);
            }
        }
    }, []);

    const showLoading = useMemo(
        () =>
            memoriesLoading ||
            decedentAPILoading ||
            getObituaryLoader ||
            loader,
        [memoriesLoading, decedentAPILoading, getObituaryLoader, loader]
    );
    useSetLoading(showLoading);

    const handleFilters = filters => {
        setMemoryType(filters);
        refreshList(opi, filters);
    };

    const handleBackToFAA = () => {
        if (session.isLoggedIn) {
            setBackToFAA(true);
        }
    };
    useEffect(() => {
        if (_.get(decedentData, "firstName") === "") {
            setUserData({
                dateOfBirth: _.get(obituary, "dateOfBirth", ""),
                dateOfDeath: _.get(obituary, "dateOfDeath", ""),
                firstName: _.get(obituary, "firstName", ""),
                lastName: _.get(obituary, "lastName", ""),
                middleName: _.get(obituary, "middleName", ""),
                prefix: _.get(obituary, "prefix", ""),
            });
        } else {
            setUserData({ ...decedentData });
        }
    }, [decedentData, obituary]);

    const [filterModal, setFilterModal] = useState(false);
    const [actionModal, setActionModal] = useState(false);

    const isPagePrivate =
        privatePage &&
        !_.get(decedentData, "firstName") &&
        !_.get(obituary, "firstName", "");

    const isDecedentInvalid =
        userNotFound &&
        !_.get(decedentData, "firstName") &&
        !_.get(obituary, "firstName", "");

    return redirectToFAA ? (
        <Redirect to='/app' />
    ) : (
        <AppLayout
            decedent={userData || null}
            isPublic='true'
            setLoader={setLoader}
        >
            <div className='memory-container container'>
                <div className='navigationCenter-body-wrapper'>
                    <div className='memories-wrapper'>
                        {isPagePrivate && (
                            <div className='memories-not-found-content-wrapper'>
                                <h2>Uh, Oh!</h2>
                                <h3>
                                    The page that you have requested is private.
                                    You don't have the permission to view this.
                                </h3>
                            </div>
                        )}
                        {!isPagePrivate && isDecedentInvalid && (
                            <div className='memories-not-found-content-wrapper'>
                                <h2>Uh, Oh!</h2>
                                <h3>
                                    The page that you have requested doesn't
                                    exist.
                                </h3>
                            </div>
                        )}
                        {!isPagePrivate && !isDecedentInvalid && (
                            <>
                                <div className='memories-left-content-wrapper'>
                                    <Actions
                                        isLoggedIn={session.isLoggedIn}
                                        obituary={obituary}
                                        setOpenObituary={() => {
                                            setOpenObituary(true);
                                            executeObituary(opi);
                                        }}
                                        decedentData={decedentData}
                                        cemeteryLocation={cemeteryLocation}
                                        handleBackToFAA={handleBackToFAA}
                                        pathname={pathname}
                                        getBurialLocation={getBurialLocation}
                                        openPanoramicView={openPanoramicView}
                                        getDirectionsMap={getDirectionsMap}
                                    />
                                </div>
                                {openObituary && (
                                    <Obituary
                                        isOpen={openObituary}
                                        decedent={decedentData}
                                        onClose={() => setOpenObituary(false)}
                                        data={obituary}
                                        opi={opi}
                                        getObituary={executeObituary}
                                        isLoggedIn={session.isLoggedIn}
                                        obituaryIsLocked={
                                            decedentData?.obituaryIsLocked
                                        }
                                    />
                                )}
                                <div className='memories-main-content-wrapper'>
                                    <div className='mobile-data-wrapper'>
                                        <div className='row'>
                                            <div className='col-6 text-center'>
                                                <Link
                                                    onClick={() =>
                                                        setFilterModal(true)
                                                    }
                                                    className='filter-options'
                                                >
                                                    Filters
                                                </Link>
                                            </div>
                                            <div className='col-6 text-center'>
                                                <Link
                                                    onClick={() =>
                                                        setActionModal(true)
                                                    }
                                                    className='filter-options'
                                                >
                                                    More
                                                </Link>
                                            </div>
                                        </div>
                                        {setActionModal ? (
                                            <MenuModal
                                                isOpen={actionModal}
                                                menuClass='menu-modal'
                                                toggle={() =>
                                                    setActionModal(false)
                                                }
                                                header='Actions'
                                            >
                                                <Actions
                                                    isLoggedIn={
                                                        session.isLoggedIn
                                                    }
                                                    obituary={obituary}
                                                    decedentData={decedentData}
                                                    setOpenObituary={() => {
                                                        setOpenObituary(true);
                                                        executeObituary(opi);
                                                    }}
                                                    cemeteryLocation={
                                                        cemeteryLocation
                                                    }
                                                    handleBackToFAA={
                                                        handleBackToFAA
                                                    }
                                                    pathname={pathname}
                                                    getBurialLocation={
                                                        getBurialLocation
                                                    }
                                                    openPanoramicView={
                                                        openPanoramicView
                                                    }
                                                    getDirectionsMap={
                                                        getDirectionsMap
                                                    }
                                                />
                                            </MenuModal>
                                        ) : (
                                            ""
                                        )}
                                        <MenuFilter
                                            filterModal={filterModal}
                                            setFilterModal={setFilterModal}
                                            memoryType={memoryType}
                                            handleFilters={handleFilters}
                                        />
                                    </div>
                                    <MemoriesListing
                                        opi={opi}
                                        decedent={decedentData}
                                        obituary={obituary}
                                        isLoggedIn={session.isLoggedIn}
                                        memoriesList={memoriesList || []}
                                        getObituary={executeObituary}
                                        obituaryIsLocked={
                                            decedentData?.obituaryIsLocked
                                        }
                                    />
                                </div>

                                <div className='memories-right-content-wrapper'>
                                    <h4>Next Upcoming Events</h4>
                                    <p>No upcoming events</p>
                                    <Link
                                        to='#'
                                        className='events-details-wrapper'
                                    >
                                        Additional details/events
                                    </Link>
                                    <MemoriesFilters
                                        setFilters={handleFilters}
                                        filtersSelected={memoryType}
                                    />
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </AppLayout>
    );
};

const MenuFilter = ({
    filterModal,
    setFilterModal,
    memoryType,
    handleFilters,
}) => {
    return (
        <MenuModal
            isOpen={filterModal}
            menuClass='menu-modal'
            toggle={() => setFilterModal(false)}
            header='Filters'
        >
            <MemoriesFilters
                setFilters={handleFilters}
                filtersSelected={memoryType}
                isMenu={true}
                openModal={filterModal}
            />
        </MenuModal>
    );
};

const MenuModal = ({ children, isOpen, toggle, header, menuClass }) => {
    return (
        <Modal isOpen={isOpen} toggle={toggle} className={menuClass}>
            <ModalHeader>
                <i className='modal-close fas fa-times' onClick={toggle} />
                <span className='modal-headername'>{header}</span>
            </ModalHeader>
            <ModalBody>
                <div className='modal-content'>{children}</div>
            </ModalBody>
        </Modal>
    );
};

const Actions = ({
    isLoggedIn,
    obituary,
    setOpenObituary,
    cemeteryLocation,
    handleBackToFAA,
    pathname,
    getBurialLocation,
    openPanoramicView,
    getDirectionsMap,
    decedentData,
}) => {
    const { opi } = useParams();
    const isPrivate = _.get(decedentData, "isPrivate", false);
    const [state, setState] = React.useState(isPrivate);

    useEffect(() => {
        setState(isPrivate);
    }, [isPrivate]);

    const handleChange = async event => {
        setState(event.target.checked);
        try {
            const data = { isPrivate: event.target.checked };
            const url = privacyModeAPI(opi);
            fetch(url, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${getAccessToken()}`,
                },
                body: JSON.stringify(data),
            })
                .then(response => response.json())
                .then(data => {
                    const status = event.target.checked ? "On" : "Off";
                    addNotification({
                        type: "success",
                        title: "Success",
                        message: `Privacy turned ${status}.`,
                    });
                })
                .catch(error => {
                    if (
                        document.getElementsByClassName("notification-danger")
                            .length == 0
                    ) {
                        apiErrorHandler(error);
                    }
                });
        } catch (error) {
            if (
                document.getElementsByClassName("notification-danger").length ==
                0
            ) {
                apiErrorHandler(error);
            }
        }
    };

    return (
        <ul>
            {isLoggedIn && (
                <li className='options-wrapper'>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={state}
                                onChange={handleChange}
                                value={state}
                                inputProps={{
                                    "aria-label": "secondary checkbox",
                                }}
                            />
                        }
                        label={"Set obituary to private"}
                        labelPlacement='start'
                    />
                </li>
            )}
            <li>
                {isLoggedIn || obituary?.obituary ? (
                    <li>
                        <Link
                            onClick={setOpenObituary}
                            className='options-wrapper'
                        >
                            <img
                                src={FileIcon}
                                alt='FileIcon'
                                className='icon-wrapper'
                            />
                            Obituary
                        </Link>
                    </li>
                ) : null}

                {cemeteryLocation &&
                    cemeteryLocation.geoJson &&
                    !_.isEmpty(cemeteryLocation.geoJson) && (
                        <div>
                            <li>
                                <Link
                                    onClick={getBurialLocation}
                                    target='_blank'
                                    className='options-wrapper'
                                >
                                    <img
                                        src={NavigationIcon}
                                        alt='NavigationIcon'
                                        className='icon-wrapper'
                                    />
                                    Burial Location
                                </Link>
                            </li>
                            <li>
                                <Link
                                    onClick={getDirectionsMap}
                                    className='options-wrapper'
                                >
                                    <img
                                        src={MapIcon}
                                        alt='MapIcon'
                                        className='icon-wrapper'
                                    />
                                    Get Directions
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to='/'
                                    onClick={openPanoramicView}
                                    target='_blank'
                                    className='options-wrapper long-text-wrapper'
                                >
                                    <img
                                        src={MapPinIcon}
                                        alt='MapPinIcon'
                                        className='icon-wrapper'
                                    />
                                    <div>Ground-Level Cemetery 360 View</div>
                                </Link>
                            </li>
                        </div>
                    )}
            </li>
            <li>
                <Link to={`${pathname}/share`} className='options-wrapper'>
                    <img
                        src={InstagramIcon}
                        alt='InstagramIcon'
                        className='icon-wrapper'
                    />
                    Share a Memory
                </Link>
            </li>
            <li className='empty-li' />
            {isLoggedIn && (
                <li>
                    <Link
                        onClick={handleBackToFAA}
                        className='view-info-button btn-theme theme-orange text-uppercase bck-family-btn'
                    >
                        Back to Family portal
                    </Link>
                </li>
            )}
        </ul>
    );
};

export default Dashboard;
