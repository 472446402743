import React from "react";
import { connect } from "react-redux";
import { Redirect, useRouteMatch,  useLocation } from "react-router-dom";

import SessionExpiry from "../components/sessionExpiryAlert";
import Loader from "../components/utils/loader/loader";
import "react-notifications-component/dist/theme.css";
import _ from "lodash";
import Cookies from 'universal-cookie';
import { msalInstance } from '../index.js';
import { doLogout, getAccessToken, handleAuthLogout } from "../utils";
import UnauthorizedAlert from "../components/unauthorizedAlertMsg";

const _AuthHOC = (WrappedComponent, reverseMode = false) => {
    const RenderedComponent = (props) => {
        return (
            <React.Fragment>
                <Loader showLoader={props.showLoader} />
                <div className="content-renderer">
                    <WrappedComponent {...props} />
                </div>
                {props.loggedIn && (
                    <UnauthorizedAlert open={props.isUnauthorized} msalInstance={msalInstance} />
                )}
                {props.loggedIn && (
                    <SessionExpiry open={props.sessionExpired} msalInstance={msalInstance} />
                )}
            </React.Fragment>
        );
    };

    const WrapperComponent = (props) => {
        const {
            isLoggedIn,
            role,
            isNewUser,
            decedentId,
            decedentError,
            ...otherProps
        } = props;
        const { path } = useRouteMatch();
        const { pathname, search } = useLocation();
        const searchParameter = search ? search.slice(search.indexOf("?")) : pathname && pathname.includes("?") ? pathname.slice(pathname.indexOf("?")) : ''
        const urlFromOnePortal = new URLSearchParams(searchParameter).get("onePortal");
        const pathWithQueryParams = `/${search}`
        const adminPath = `/admin${search}`
        const appPath = `/app${search}`
        const profilePath = `/profile${search}`
        const regex = /CS-\d+-\d+/;
        const isAzureMFAEnabled = process.env.REACT_APP_IS_AZURE_MFA_ENABLED;
        
        // const callhandleAuthLogout = () => {
        //   const logoutRedirectUrl =
        //     process.env.REACT_APP_HOST !== "https://temp.clcafamily.com"
        //       ? "/obituaries"
        //       : "/";
        //   // Without clicking on sign out, if forceful logout occurs,
        //   // and if it is a cl-arranger login with MFA enabled, then we need to remove account details as well.
        //   if (msalInstance?.getAllAccounts().length >= 1 && isAzureMFAEnabled) {
        //     handleAuthLogout(
        //       msalInstance,
        //       msalInstance.getAllAccounts(),
        //       logoutRedirectUrl,
        //       true
        //     );
        //   }
        // };

        if (pathname !== '/' && pathname !== '/?onePortal=true' && pathname !== '/administrator?onePortal=true') {
            const match = pathname?.match(regex);
            //Store OPI at the time of cl-arranger login only to push the path to history after session expired
            if (match && search === '?onePortal=true') {
                const opi = match?.[0]
                localStorage.setItem('opi', opi)
            }
        }
        if (isLoggedIn) {
            let redirectPath
            if (decedentId) {
                redirectPath = `/app/${decedentId}/arrangements${search}`
            } else {
                redirectPath = `/app${search}`
            }
            if ((role === "arranger" || role === "admin") && !isNewUser && urlFromOnePortal === 'true') {
                doLogout()
            }
            if (
                role === "arranger" &&
                ["/admin", "/administrator", "/cl-arranger"].includes(path) &&
                !isNewUser
            ) {
                doLogout();
                localStorage.setItem('pathFromOp',`${pathname}${search}`)
                return (
                    (path === "/admin" || path === "/administrator") && (
                        <Redirect
                            to={{
                                pathname: `/administrator${search}`,
                                state: { referrer: `${pathname}${search}` },
                            }}
                        />
                    )
                );
            }
            if (role === "admin" && ["/app", "/cl-arranger"].includes(path) && !isNewUser) {
                doLogout()
            }
            if (role === "cl-arranger" && ["/app"].includes(path) && !isNewUser) {
                doLogout()
            }
            if (role === "cl-arranger" && ["/admin"].includes(path) && !urlFromOnePortal && !isNewUser) {
                doLogout()
            }
            return reverseMode && role === "arranger" && isNewUser ? (
                <Redirect to={profilePath}/>
            ) : reverseMode && role === "arranger" && !isNewUser ? (
                <Redirect
                    to={{
                        pathname: redirectPath,
                        state: { decedentError: decedentError },
                    }}
                />
            ) : (role === "arranger" || role==="admin") && !isNewUser && urlFromOnePortal === 'true' ? (
              <>
                {localStorage.setItem("pathFromOp", `${pathname}${search}`)}
                <Redirect
                  to={{
                    pathname: `/${search}`,
                    state: { referrer: `${pathname}${search}` },
                  }}
                />
              </>
            ) : reverseMode && role === "admin" ? (
                <Redirect to={adminPath} />
            ) : role === "arranger" && path === "/admin" ? (
                <Redirect to={appPath} />
            ) : role === "admin" && path === "/app" ? (
                <Redirect to={adminPath}/>
            ) : (
                <RenderedComponent {...otherProps} loggedIn={true} />
            );
        } else {
            return reverseMode ? (
                <RenderedComponent {...otherProps} loggedIn={false} />
            ) : (props.forceLogout ? 
                    <Redirect to={pathWithQueryParams}/>
                    : ( 
                        
                        <Redirect
                        to={{
                            pathname: path === '/admin' ? `/administrator${search}` : `/${search}`,
                            state: { referrer: `${pathname}${search}` || `/app${search}` }
                        }}/>
                ));
        
        }
    };

    const cookies = new Cookies();
    return connect((state) => ({
        role: _.get(
            state,
            "authentication.session.role",
            localStorage.getItem("role") || cookies.get("role")
        ),
        isNewUser: _.get(state, "authentication.session.isNewUser"),
        isLoggedIn: _.get(
            state,
            "authentication.session.isLoggedIn",
            Boolean(getAccessToken())
        ),
        showLoader: _.get(state, "loader.showLoader", false),
        sessionExpired: _.get(state, "authentication.sessionExpired"),
        forceLogout: _.get(state, "authentication.forceLogout"),
        decedentId: _.get(state, "authentication.session.decedentId"),
        decedentError: _.get(state, "authentication.session.decedentError"),
        isUnauthorized: _.get(state, "authentication.isUnauthorized")
    }))(WrapperComponent);
};

export default _AuthHOC;
