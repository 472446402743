import store from "../store/store";
import Cookies from 'universal-cookie';
import { msalInstance } from '../index';

export const getMfaAccessToken = async () => {
    try {
      const accounts = await msalInstance.getAllAccounts();
      const accessTokenRequest = {
        scopes: ['user.read'],
        account: accounts[0],
        forceRefresh: true
      };
   
      const accessTokenResponse = await msalInstance.acquireTokenSilent(
        accessTokenRequest
      );
      const accessToken = accessTokenResponse.idToken;
      return accessToken;
    } catch {
      handleAuthLogout();
      return null;
    }
};

export function getAccessToken() {
    var token = "";
    const cookies = new Cookies();

    // if (sessionStorage.getItem("token"))
    //     token = sessionStorage.getItem("token");
    if (localStorage.getItem("token"))
        token = localStorage.getItem("token");
    else if (cookies.get('token'))
        token = cookies.get('token');

    return token;
}

export function doLogout() {
    const isAzureMFAEnabled = process.env.REACT_APP_IS_AZURE_MFA_ENABLED;
    
    const cookies = new Cookies();
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("role");
    localStorage.clear();
    if(isAzureMFAEnabled?.toLowerCase() === "true") sessionStorage.clear()
    
    cookies.remove('token', { path: '/' });
    cookies.remove('role', { path: '/' });
    store.dispatch({
        type: "LOGOUT"
    });
}

export function getPolicyAcceptance() {
    var haveAcceptPolicy = "";

    if (sessionStorage.getItem("haveAcceptPolicy"))
        haveAcceptPolicy = sessionStorage.getItem("haveAcceptPolicy");

    return haveAcceptPolicy;
}

export const handleAuthLogout = (msalInstance, accounts, redirectURL, fromFamilyWebsite = false) => {
  if (fromFamilyWebsite) {
    setTimeout(() => {
      const logoutHint = accounts[0]?.idTokenClaims.login_hint;
      msalInstance?.logoutRedirect({
        postLogoutRedirectUri: redirectURL ? redirectURL : "/obituaries",
        logoutHint,
      });
    }, [10]);
  } else {
    const logoutHint = accounts[0]?.idTokenClaims.login_hint;
    msalInstance?.logoutRedirect({
      postLogoutRedirectUri: redirectURL ? redirectURL : "/obituaries",
      logoutHint,
    });
  }
};
