import React from "react";
import { Label, Input } from "reactstrap";

const MemoryFilterOptions = ({
    filters,
    isAllSelected,
    selectedFilters,
    handleFiltersChange,
}) => {
    return (
        <ul className="filter-options">
            {filters.map((filter) => {
                return (
                    <li>
                        <div className="checkbox-wrapper">
                            <Label className="checkbox-inline d-flex align-items-center justify-content-start">
                                <Input
                                    type="checkbox"
                                    name="filterValues"
                                    onChange={() => {
                                        handleFiltersChange(filter.value);
                                    }}
                                    checked={
                                        isAllSelected ||
                                        selectedFilters.includes(filter.value)
                                    }
                                />
                                {filter.label}
                            </Label>
                        </div>
                    </li>
                );
            })}
        </ul>
    );
};

export default MemoryFilterOptions;
