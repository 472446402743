import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Link } from "react-router-dom";
import CypressLogo from "../../../assets/images/clLogo.png";
import {
    Collapse,
    Navbar,
    Nav,
    NavItem,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
} from "reactstrap";
import _ from "lodash";
import useGAEventTracker from "../../utils/hooks/GAEventsTracker";
import useAsync from "react-use-async-hook";
import * as DecedentAPI from "../../../api/dashboard";
import { useSetLoading } from "../../utils/hooks/loader";
import Cookies from "universal-cookie";

const NavigationTop = ({ userInfo, decedentId, location }) => {

    const [isOpen, setOpen] = useState(false);
    const cookies = new Cookies();
    const Oprole = sessionStorage.getItem("role") || localStorage.getItem("role") || cookies.get("role");

    const toggle = () => {
        setOpen(!isOpen);
    };

    const {
        data: decedentsData,
        loading: getDecedentLoader,
        execute: executeGetDecedent,
    } = useAsync({
        autoExecute: false,
        task: DecedentAPI.getDecedentList,
        dataLoader: useCallback(res => {
            return _.get(res, "data.decedent");
        }, []),
    });

    useEffect(() => {
        if(Oprole !== "admin") {
            executeGetDecedent();
        }
    }, [Oprole]);

    const showLoader = useMemo(
        () => getDecedentLoader,
        [getDecedentLoader]
    );
    useSetLoading(showLoader);

    let initialDecedent;
    if (decedentsData && decedentsData.activeCases && decedentsData.activeCases.length) {
        let activeCase = decedentsData.activeCases;
        initialDecedent = _.get(activeCase, "[0].decedent.onePortalId");
    } else if (decedentsData && decedentsData.closedCases &&decedentsData.closedCases.length) {
        let closedCase = decedentsData.closedCases;
        initialDecedent = _.get(closedCase, "[0].decedent.onePortalId");
    }

    let id = decedentId ? decedentId : initialDecedent

    return (
        <div className='navbar-top'>
            <Navbar expand='md'>
                <Link to='#' className='navbar-brand'>
                    <img
                        src={CypressLogo}
                        className='cypress-logo'
                        alt='cypress lawn'
                    />
                </Link>
                <span
                    className='navigation-toggler d-block d-md-none'
                    onClick={toggle}
                >
                    <i className='fas fa-bars'></i>
                </span>
                <Collapse isOpen={isOpen} navbar>
                    {Oprole && (
                        <Nav className='ml-auto' navbar>
                            {Oprole !== "admin" ? (
                                <div>
                                    <NavItem style={{ float: "left" }} className={`${Oprole === "cl-arranger" && "disable-cursor"}`}>
                                        <Link
                                            className={`${Oprole === "cl-arranger" && "disable-link"}
                                                ${
                                                    location.pathname === "/app"
                                                        ? "navbar-link active"
                                                        : "navbar-link navbar-color"
                                                }`}
                                            to='/app'
                                        >
                                            Home
                                        </Link>
                                    </NavItem>
                                    <NavItem style={{ float: "left" }} className={`${(Oprole === "cl-arranger" || id === undefined ) && "disable-cursor"}`}>
                                        <Link
                                            className={`${(Oprole === "cl-arranger" || id === undefined) && "disable-link"}
                                                ${
                                                    decedentId
                                                        ? "navbar-link active"
                                                        : "navbar-link navbar-color"
                                                }`}
                                            to={
                                                decedentId
                                                    ? `/app/${decedentId}/arrangements`
                                                    : `/app/${initialDecedent}/arrangements`
                                            }
                                        >
                                            My Arrangements
                                        </Link>
                                    </NavItem>
                                </div>
                            ) : (
                                <div style={{ width: "auto" }}>
                                    <NavItem style={{ float: "left" }}>
                                        <Link
                                            className={
                                                location.pathname ===
                                                "/admin/memories"
                                                    ? "navbar-link active"
                                                    : "navbar-link"
                                            }
                                            to={
                                                Oprole
                                                    ? "/admin/memories"
                                                    : "#"
                                            }
                                        >
                                            Memories
                                        </Link>
                                    </NavItem>
                                    <NavItem style={{float: "left"}}>
                                        <Link
                                            className={
                                                // location.pathname === '/admin/decedents'
                                                location.pathname ===
                                                "/admin/decedents"
                                                    ? "navbar-link active"
                                                    : "navbar-link"
                                            }
                                            to={
                                                Oprole
                                                    ? "/admin/decedents"
                                                    : "#"
                                            }
                                        >
                                            Arrangements Summary
                                        </Link>
                                    </NavItem>
                                    <NavItem style={{float: "left"}}>
                                        <Link
                                            className={
                                                // location.pathname === '/admin/decedents'
                                                location.pathname ===
                                                "/admin/loginReport"
                                                    ? "navbar-link active"
                                                    : "navbar-link"
                                            }
                                            to={
                                                Oprole
                                                    ? "/admin/loginReport"
                                                    : "#"
                                            }
                                        >
                                           Login Report
                                        </Link>
                                    </NavItem>
                                    <NavItem style={{float: "left"}}>
                                        <Link
                                            className={
                                                location.pathname === 
                                                "/admin/userReport"
                                                ? "navbar-link active"
                                                : "navbar-link"
                                            }
                                            to={
                                                Oprole
                                                ? "/admin/userReport"
                                                : "#"
                                            }
                                        >
                                            User Report
                                        </Link>
                                    </NavItem>
                                </div>
                            )}

                            <UncontrolledDropdown nav inNavbar>
                                <DropdownToggle className='navbar-link' nav>
                                    <span className='nav-nameimage'>
                                        {_.get(userInfo, "firstName", "") &&
                                            _.head(
                                                _.get(userInfo, "firstName")
                                            )}
                                        {_.get(userInfo, "lastName", "") &&
                                            _.head(_.get(userInfo, "lastName"))}
                                    </span>
                                    <span className='nav-name'>
                                        {_.get(userInfo, "firstName", "") +
                                            " " +
                                           (userInfo?.lastName !== null ? _.get(userInfo, "lastName", "") : "")}
                                    </span>
                                    <i className='fas fa-chevron-down'></i>
                                </DropdownToggle>
                                <DropdownMenu right>
                                    <div className={`${Oprole === "cl-arranger" && "disable-cursor"} dropdown-link`}>
                                        <Link
                                            to={Oprole === 'admin' ? '/admin/userProfile' :'/app/userProfile'}
                                            className={`${Oprole === "cl-arranger" && "disable-link"} dropdown-link-color`}
                                        >
                                            Profile
                                        </Link>
                                    </div>
                                    <Link
                                        to='/'
                                        className='dropdown-link sign-out'
                                        onClick={()=>{
                                            localStorage.setItem('signOut','yes')
                                        }}
                                    >
                                        Sign Out
                                    </Link>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </Nav>
                    )}
                </Collapse>
            </Navbar>
        </div>
    );
};

export default NavigationTop;
