import React, { useState, useEffect, useCallback, useMemo } from "react";
import _ from "lodash";
import useAsync from "react-use-async-hook";
import TooltipAndInfo from "../../../TooltipAndInfo";
import { useSetLoading } from "../../../utils/hooks/loader";
import addNotification from "../../../utils/notification/notification";
import * as CustomizeFamilyWebsite from "../../../../api/customizeFamilyWebsite";
import DeleteIcon from "../../../../assets/images/deleteGrey.svg";
const moment = require("moment");

const DisplayUsersMessages = ({ opi }) => {
  const [displayMessageTooltip, setDisplayMessageTooltip] = useState(false);
  const [listOfMessages, setListOfMessages] = useState([]);
  const [showReadForEachMessage, setShowReadMore] = useState([]);

  const showDisplayMessageTooltip = () => {
    setDisplayMessageTooltip(!displayMessageTooltip);
  };

  /* Get List Of Messages */

  const {
    data: messages,
    loading: getMessagesLoader,
    execute: executeGetMessages,
  } = useAsync({
    autoExecute: false,
    initialData: useMemo(() => null, []),
    task: useCallback((opi) => {
      return CustomizeFamilyWebsite.getListOfMessages(opi);
    }, []),
    dataLoader: useCallback((res) => {
      return _.get(res, "data", []);
    }, []),
    onError: useCallback((error) => {
      addNotification({
        type: "danger",
        title: "Error",
        message: _.get(
          error,
          "response.data.message",
          "Oops! Something went wrong"
        ),
      });
    }, []),
  });

  /* Delete Message */

  const {
    data: deleteMessage,
    loading: deleteMessagesLoader,
    execute: executeDeleteMessage,
  } = useAsync({
    autoExecute: false,
    initialData: useMemo(() => null, []),
    task: useCallback((id, opi) => {
      return CustomizeFamilyWebsite.deleteMessage(id, opi);
    }, []),
    dataLoader: useCallback((res) => {
      addNotification({
        type: "success",
        title: "Success",
        message: "Message Deleted Successfully",
      });
      return _.get(res, "data", []);
    }, []),
    onError: useCallback((error) => {
      addNotification({
        type: "danger",
        title: "Error",
        message: _.get(
          error,
          "response.data.message",
          "Oops! Something went wrong"
        ),
      });
    }, []),
  });

  useEffect(() => {
    executeGetMessages(opi);
  }, []);

  useEffect(() => {
    if (_.get(deleteMessage, "success")) {
      executeGetMessages(opi);
    }
  }, [deleteMessage]);

  useEffect(() => {
    if (_.get(messages, "data")) {
      setListOfMessages(_.get(messages, "data"));
    }
  }, [messages]);

  useEffect(() => {
    const arr = [];
    if (listOfMessages?.length >= 1) {
      listOfMessages?.map((message, i) => {
        if (document.getElementById(`message-${i}`)?.clientHeight >= 48) {
          arr.push({ index: i, value: true, isOpen: false });
        } else {
          arr.push({ index: i, value: false, isOpen: false });
        }
      });
    }
    setShowReadMore([...arr]);
  }, [listOfMessages]);

  const handleReadMore = (i) => {
    const updatedMessages = showReadForEachMessage.map((message) =>
      message.index === i
        ? {
            ...message,
            isOpen: !showReadForEachMessage[i]?.["isOpen"],
          }
        : message
    );
    setShowReadMore(updatedMessages);
  };

  const handleDelete = (id) => {
    executeDeleteMessage({ id: id }, opi);
  };
  const showLoader = useMemo(
    () => getMessagesLoader || deleteMessagesLoader,
    [getMessagesLoader, deleteMessagesLoader]
  );
  useSetLoading(showLoader);

  return (
    <div className="listOfMessagesWrapper">
      <div className="display-name-heading">
        <div className="display-name">
          <div className="customIndex">4</div>
          <div>Messages</div>
        </div>
        <TooltipAndInfo
          id="displayMessages"
          showTooltipFn={showDisplayMessageTooltip}
          showToolTip={displayMessageTooltip}
          size={25}
          tooltipText="Here You Can See Messages Send By The Users"
          color="#000000"
        />
      </div>
      <div className="messagesWrapper">
        {listOfMessages?.length === 0 && (
          <div className="d-flex align-items-center justify-content-center mb-2">
            <span>No Messages Are Available</span>
          </div>
        )}
        {listOfMessages?.length >= 1 &&
          listOfMessages?.map((item, i) => {
            return (
              <div className="messageEmailDetails">
                <div className="senderDetails">
                  <span className="fromEmailText">
                    <span className="fromText">
                      {"From:"}
                      {"\u00a0\u00a0"}
                    </span>{" "}
                    {item?.email}
                  </span>
                  <div className="timeContainer">
                    <spa className="showDate">
                      {moment(item?.updatedAt).format("hh:mm A")},{" "}
                      {moment(item?.updatedAt).format("MM-DD-YYYY")}
                    </spa>
                    <img
                      src={DeleteIcon}
                      alt="deleteIcon"
                      className="deleteIcon"
                      onClick={() => {
                        handleDelete(item?.id);
                      }}
                    />
                  </div>
                </div>
                <div className={`messageDetails`} id={`message-${i}`}>
                  <div
                    className={`${
                      !showReadForEachMessage?.[i]?.["isOpen"]
                        ? "applyMaxHeight"
                        : "messageContent"
                    }`}
                  >
                    {!showReadForEachMessage?.[i]?.["isOpen"] &&
                    item?.message?.length > 180 ? (
                      <>
                        <span className="messageText">{_.slice(item?.message, 0, 180)}</span>
                        {showReadForEachMessage?.[i]?.["value"] &&
                          !showReadForEachMessage?.[i]?.["isOpen"] && (
                            <button
                              style={{ marginLeft: "5px" }}
                              className="showReadMoreButton"
                              onClick={() => {
                                handleReadMore(i);
                              }}
                            >
                              Read more
                            </button>
                          )}
                      </>
                    ) : (
                      <span className="messageText">{item?.message}</span>
                    )}
                  </div>
                  {showReadForEachMessage?.[i]?.["value"] &&
                    showReadForEachMessage?.[i]?.["isOpen"] && (
                      <div className="showReadMoreWrapper">
                        <button
                          className="showReadMoreButton"
                          onClick={() => {
                            handleReadMore(i);
                          }}
                        >
                          Read less
                        </button>
                      </div>
                    )}
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default DisplayUsersMessages;
