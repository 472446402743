import { store } from 'react-notifications-component';
import _ from 'lodash'

export const apiErrorHandler = (errorRes) => {  
    let error;
      if (
        _.get(errorRes, 'response.data.error.errors') &&
        _.get(errorRes, 'response.data.error.errors').length &&
        typeof _.get(errorRes, 'response.data') === 'object'
      ) {
        error = _.get(errorRes, 'response.data.error.errors[0].message', '');
      } else if (typeof _.get(errorRes, 'response.data') === 'object') {
        error =
          _.get(errorRes, 'response.data.error.message', null) ||
          _.get(errorRes, 'response.data.message', null) ||
          _.get(errorRes, 'response.data.messages', null) ||
          _.get(errorRes, 'response.data.error', null) ||
          _.get(errorRes, 'message');
      } else {
        error = _.get(errorRes, 'response.statusText');
      }
      store.addNotification({
        animationIn: ['animated', 'fadeIn'],
        container: 'top-right',
        animationOut: ['animated', 'fadeOut'],
        dismiss: {
        showIcon: true,
        duration: 5000
        },
        title: 'Error',
        type: 'danger',
        dismiss: {
          duration: 3000
        },
        message: error
      });
  };