import React, { useRef } from "react";
import { Row, Col, Form } from "reactstrap";
import { Formik } from "formik";
import _ from "lodash";
import addNotification from "../../../utils/notification/notification";

import {
  validateButtonName,
  validateDonationUrl,
  validateDesc,
} from "./validateForm";

const DonationForm = ({
  formDataList,
  setFormDataList,
  activeTab,
  setActiveTab,
  errorObjectList,
  setErrorObjectList,
  executeDonationDetails,
  checkIsError,
  onePortalId,
  listOfUrls,
  setListOfUrls,
  setDonationUrlChangesSaved,
  executeDeleteDonationDetails,
  setIsDisabled,
  setIsDeleteDisables,
  isDeleteDisabled,
  isDisable,
  deletedDataObjects,
  setDeletedDataObjects,
  enableAddUrl,
  setEnableAddurl,
}) => {
  const formRef = useRef(null);

  const checkIsDataExists = () => {
    const result = _.some(
      formDataList?.[0],
      (value) => !_.isUndefined(value) && !_.isEmpty(value)
    );
    return result;
  };

  const handleAddUrl = () => {
    if (_.some(errorObjectList, (obj) => _.some(obj, (value) => !!value))) {
      return false;
    }
    if (listOfUrls?.length >= 5) {
      return addNotification({
        type: "warning",
        title: "Warning",
        message: "You can add maximum 5 URL's",
      });
    }
    const newUrls = [...listOfUrls, `DonationUrl ${listOfUrls?.length + 1}`];
    const updatedFormDataList = [...formDataList];
    updatedFormDataList[listOfUrls?.length] = {
      donationUrlDescription:
        updatedFormDataList[activeTab]?.donationUrlDescription,
    };
    setActiveTab(newUrls?.length - 1);
    setListOfUrls([...newUrls]);
    setFormDataList([...updatedFormDataList]);
  };

  const customValidation = (name, value) => {
    let errorMsg;
    if (name === "donationUrlDescription") errorMsg = validateDesc(value);
    else if (name === "donationUrlSrc") errorMsg = validateDonationUrl(value);
    else if (name === "donationUrlName") errorMsg = validateButtonName(value);
    const errorList = [...errorObjectList];
    errorList[activeTab] = { ...errorList[activeTab], [name]: errorMsg };
    setErrorObjectList(errorList);
  };

  const handleSubmitForm = async (values) => {
    const result = checkIsError();
    if (!result) {
      await executeDonationDetails(
        formDataList,
        deletedDataObjects,
        onePortalId
      );
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{
        donationUrlDescription:
          formDataList[activeTab]?.donationUrlDescription || "",
        donationUrlSrc: formDataList[activeTab]?.donationUrlSrc || "",
        donationUrlName: formDataList[activeTab]?.donationUrlName || "",
      }}
      validateOnBlur={false}
      onSubmit={(values) => {
        handleSubmitForm(values);
      }}
      innerRef={formRef}
    >
      {({
        values,
        errors,
        touched,
        handleSubmit,
        setFieldValue,
        setTouched,
        setSubmitting,
        isValidating,
      }) => {
        const handleChange = (e) => {
          const { name, value } = e.target;
          setFieldValue(name, value);
          customValidation(name, value);
          const updatedFormDataList = [...formDataList];
          if (name === "donationUrlDescription") {
            listOfUrls?.map((item, index) => {
              updatedFormDataList[index] = {
                ...updatedFormDataList[index],
                [name]: value,
                isDataChanged: true,
              };
            });
          } else {
            updatedFormDataList[activeTab] = {
              ...updatedFormDataList[activeTab],
              [name]: value,
              isDataChanged: true,
            };
          }
          setFormDataList(updatedFormDataList);
          setIsDisabled(false);
          setDonationUrlChangesSaved(false);
          setIsDeleteDisables(true);
          setSubmitting(false);
        };
        return (
          <Form id={activeTab} onSubmit={handleSubmit}>
            <Row>
              <Col md={6} lg={6} xl={6}>
                <div className="inputcard-wrapper">
                  <div className="inputcard-body custom-inputcard-body">
                    {activeTab === 0 && (
                      <div className="inputcard-group descriptionGroup">
                        <div className="choose-reason inputfield d-flex align-items-center justify-content-between">
                          <div className="inputcard-withlabel d-flex align-items-start justify-content-center flex-column">
                            <label className="inputcard-label">
                              Description
                            </label>
                            <textarea
                              className="custominputfield descriptionField"
                              type="text"
                              id="donationUrlDescription"
                              placeholder="Enter Description Here"
                              name="donationUrlDescription"
                              value={values.donationUrlDescription}
                              onChange={handleChange}
                            />
                            {_.find(
                              errorObjectList,
                              (obj) => obj?.donationUrlDescription
                            )?.donationUrlDescription && (
                              <span className="error-memory-input-msg">
                                {
                                  _.find(
                                    errorObjectList,
                                    (obj) => obj?.donationUrlDescription
                                  )?.donationUrlDescription
                                }
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="inputcard-group">
                      <div className="choose-reason inputfield d-flex align-items-center justify-content-between">
                        <div className="inputcard-withlabel d-flex align-items-start justify-content-center flex-column">
                          <label className="inputcard-label">
                            URL For Donations
                            <small className="ml-2 input-mandatory">*</small>
                          </label>
                          <input
                            className="custominputfield"
                            placeholder="https://cypresslawn.com/shop/"
                            name="donationUrlSrc"
                            value={values.donationUrlSrc}
                            onChange={handleChange}
                            id="donationUrlSrc"
                          />

                          {errorObjectList[activeTab]?.donationUrlSrc && (
                            <span className="error-memory-input-msg">
                              {errorObjectList[activeTab]?.donationUrlSrc}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="inputcard-group">
                      <div className="choose-reason inputfield d-flex align-items-center justify-content-between">
                        <div className="inputcard-withlabel d-flex align-items-start justify-content-center flex-column">
                          <label className="inputcard-label">
                            Add Text to Button
                            <small className="ml-2 input-mandatory">*</small>
                          </label>
                          <input
                            className="custominputfield"
                            type="text"
                            placeholder="Ex: Donate Organs"
                            name="donationUrlName"
                            value={values.donationUrlName}
                            onChange={handleChange}
                            id="donationUrlName"
                          />

                          {errorObjectList[activeTab]?.donationUrlName && (
                            <span className="error-memory-input-msg">
                              {errorObjectList[activeTab]?.donationUrlName}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              {formDataList?.length >= 1 && checkIsDataExists() && (
                <Col className="previewUrlWrapper" md={6} lg={6}>
                  <p className="previewHeading">Preview</p>

                  <div className="previewDescription">
                    <p className="description">
                      {formDataList[0]?.donationUrlDescription}
                    </p>
                    {formDataList?.map((item, index) => {
                      return (
                        <>
                          {item?.donationUrlName && (
                            <a
                              href={item?.donationUrlSrc}
                              target="_blank"
                              rel="noreferrer"
                              className={`urlPreview ${
                                errors?.donationUrlSrc ? "disabledPreview" : ""
                              }`}
                              onClick={(e) => {
                                if (errorObjectList[index]?.donationUrlSrc) {
                                  e.preventDefault();
                                }
                              }}
                            >
                              {item?.donationUrlName}
                            </a>
                          )}
                        </>
                      );
                    })}
                  </div>
                </Col>
              )}
            </Row>
            <hr className="lineTag"></hr>
            <div className="d-flex justify-content-between">
              <div className="d-flex align-items-start">
                <button
                  className={`save-button addNewUrl ${
                    !enableAddUrl && "disableAddUrl"
                  }`}
                  type="button"
                  onClick={handleAddUrl}
                  disabled={!enableAddUrl}
                >
                  Add New URL
                </button>
              </div>
              <div className="d-flex justify-content-end align-items-end">
                <button
                  type="button"
                  className="delete-button"
                  disabled={isDeleteDisabled}
                  onClick={() => {
                    setFieldValue("donationUrlDescription", "");
                    setFieldValue("donationUrlSrc", "");
                    setFieldValue("donationUrlName", "");
                    setFormDataList([
                      {
                        donationUrlDescription: "",
                        donationUrlSrc: "",
                        donationUrlName: "",
                      },
                    ]);
                    setListOfUrls(["DonationUrl 0"]);
                    setErrorObjectList([]);
                    setDeletedDataObjects([]);
                    executeDeleteDonationDetails(onePortalId);
                  }}
                >
                  Delete
                </button>
                <button
                  type="submit"
                  className="save-button"
                  disabled={isDisable}
                >
                  Save
                </button>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default DonationForm;
