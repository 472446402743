import React, {
    useCallback,
    useEffect,
    useMemo,
    useState,
    useRef,
} from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import useAsync from "react-use-async-hook";
import _ from "lodash";
import moment from "moment";
import { useSetLoading } from "../../utils/hooks/loader";
import * as Dashboard from "../../../api/dashboard";
import AppLayout from "../../appLayout";
import BreadCrumb from "../../utils/breadCrumb/breadCrumb";
import { privacyModeAPI, decedentAPI } from "../../../api/memories";
import { getAccessToken } from "../../../utils";
import addNotification from "../../utils/notification/notification";
import TooltipAndInfo from "../../TooltipAndInfo";
import Cookies from "universal-cookie";

const Arrangements = ({ role }) => {
    const { onePortalId } = useParams();
    const { pathname, search } = useLocation();
    const searchParameter = search ? search.slice(search.indexOf("?")) : pathname && pathname.includes("?") ? pathname.slice(pathname.indexOf("?")) : ''
    const [isArranger, setIsArranger] = useState(true);
    const [module, setModule] = useState("app");
    const [loader, setLoader] = useState(false);

    const [biographyTooltipOpen, setBiographyTooltipOpen] = useState(false);
    const [prayerCardTooltipOpen, setPrayerCardTooltipOpen] = useState(false);
    const [programCardTooltipOpen, setProgramCardTooltipOpen] = useState(false);
    const [obituaryTooltipOpen, setObituaryTooltipOpen] = useState(false);

    const cookies = new Cookies();
    const Oprole =
        sessionStorage.getItem("role") || cookies.get("role") || localStorage.getItem("role");

    const showBiographyToolTip = () => {
        setBiographyTooltipOpen(!biographyTooltipOpen);
    };

    const getTooltipText = isLocked => {
        if (isLocked) {
            return "This section has been locked by your arranger. To edit or add any further information about your loved one please contact your arranger directly.";
        }
        return "This section is unlocked by your arranger. You can edit or add any further information about your loved one.";
    };

    const showPrayerCardToolTip = () => {
        setPrayerCardTooltipOpen(!prayerCardTooltipOpen);
    };

    const showProgramCardToolTip = () => {
        setProgramCardTooltipOpen(!programCardTooltipOpen);
    };

    const showObituaryToolTip = () => {
        setObituaryTooltipOpen(!obituaryTooltipOpen);
    };

    const handlePrivacyChange = async event => {
        setPrivacyState(event.target.checked);
        try {
            const data = { isPrivate: event.target.checked };
            const url = privacyModeAPI(_.get(decedent, "decedent.opi"));
            fetch(url, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${getAccessToken()}`,
                },
                body: JSON.stringify(data),
            })
                .then(response => response.json())
                .then(data => {
                    const status = event.target.checked ? "On" : "Off";
                    addNotification({
                        type: "success",
                        title: "Success",
                        message: `Privacy turned ${status}.`,
                    });
                })
                .catch(error => {
                    if (
                        document.getElementsByClassName("notification-danger")
                            .length == 0
                    ) {
                        addNotification({
                            type: "danger",
                            title: "Error",
                            message: _.get(
                                error,
                                "response.data.message",
                                "Oops! Something went wrong"
                            ),
                        });
                    }
                });
        } catch (error) {
            if (
                document.getElementsByClassName("notification-danger").length ==
                0
            ) {
                addNotification({
                    type: "danger",
                    title: "Error",
                    message: _.get(
                        error,
                        "response.data.message",
                        "Oops! Something went wrong"
                    ),
                });
            }
        }
    };

    useEffect(() => {
        if (role === "admin") {
            setIsArranger(false);
            setModule(role);
        } else if (role === "arranger") {
            setIsArranger(true);
            setModule("app");
        }
    }, [role]);

    /**
     * Get Decedent Detail
     */
    let opi = useRef("");
    const {
        data: decedent,
        loading: getDecedentLoader,
        execute: executeGetDecedent,
    } = useAsync({
        autoExecute: false,
        task: Dashboard.getArrangementSummary,
        dataLoader: useCallback(res => {
            const z = _.get(res, "data");
            opi.current = _.get(z, "decedent.opi", {});
            return _.get(res, "data");
        }, []),
        onError: useCallback(error => {
            addNotification({
                type: "danger",
                title: "Error",
                message: _.get(
                    error,
                    "response.data.message",
                    "Oops! Something went wrong"
                )
            });
        }, [])
    });

    /**
     * Get Decedent using API
     */
    const { data: decedentData, execute: executeDecedentAPI } = useAsync({
        autoExecute: false,
        initialData: useMemo(() => null, []),
        task: decedentAPI,
        dataLoader: useCallback(response => {
            return _.get(response, "data.decedent", {});
        }, []),
        onError: useCallback(error => {
            if (
                document.getElementsByClassName("notification-danger").length ==
                0
            ) {
                addNotification({
                    type: "danger",
                    title: "Error",
                    message: _.get(
                        error,
                        "response.data.message",
                        "Oops! Something went wrong"
                    ),
                });
            }
        }, []),
    });

    const isPrivate = _.get(decedentData, "isPrivate", false);
    const [privacyState, setPrivacyState] = React.useState(isPrivate);
    useEffect(() => {
        setPrivacyState(isPrivate);
    }, [isPrivate]);

    useEffect(() => {
        if (onePortalId) {
            executeGetDecedent(onePortalId);
        }
    }, [onePortalId]);

    useEffect(() => {
        if (role == "admin" && opi.current) {
            executeDecedentAPI(opi.current);
        }
    }, [opi.current]);

    const showLoader = useMemo(
        () => getDecedentLoader || loader,
        [getDecedentLoader, loader]
    );
    useSetLoading(showLoader);

    const urlPathName = isArranger
        ? `/app/memories/${onePortalId}`
        :`/admin/memories-list/${onePortalId}`;

    const lfirstname = _.camelCase(_.get(decedent,"decedent.firstName"));
    const llastname = _.camelCase(_.get(decedent, "decedent.lastName"));
    const fullname = llastname ?`${lfirstname}-${llastname}-obituary` : `${lfirstname}-obituary`
    const familyWebsiteUrlPathName = `/name/${fullname}/${_.get(
        decedent,
        "decedent.opi"
    )}`;
    return (
        <AppLayout
            decedent={_.get(decedent, "decedent") || null}
            haveAcceptPolicy={
                isArranger ? _.get(decedent, "haveAcceptPolicy", false) : true
            }
            getDecedent={executeGetDecedent}
            setLoader={setLoader}
        >
            <div className='container'>
                {isArranger ? (
                    <BreadCrumb
                        links={[
                            {
                                url: `/app`,
                                description: "Home",
                                heading: "Dashboard",
                            },
                            {
                                url: `/app/${onePortalId}/arrangements`,
                                description: "Arrangements Summary",
                                heading: "Arrangements Summary",
                                isSpan: true,
                            },
                        ]}
                        showBack={true}
                    />
                ) : (
                    <BreadCrumb
                        links={[
                            {
                                url: `/admin/decedents`,
                                description: "Home",
                                heading: "Dashboard",
                            },
                            {
                                url: `/admin/${onePortalId}/arrangements`,
                                description: "Arrangements Summary",
                                heading: "Arrangements Summary",
                                isSpan: true,
                                search: searchParameter
                            },
                        ]}
                        showBack={true}
                    />
                )}
                <div className='navigationCenter-body-wrapper'>
                    <div className='arrangements-wrapper'>
                        {isArranger && (
                            <h1 className='steps-heading'>STEP 1</h1>
                        )}
                        <div className='d-flex flex-column flex-md-row justify-content-start justify-content-md-between align-items-start'>
                            {isArranger && (
                                <div className='cardbox-wrapper card-whatToExpect cardbox-small'>
                                    <div className='cardbox-heading'>
                                        <h4 className='cardbox-heading-name text-black card-heading-size'>
                                            What to Expect?
                                        </h4>
                                    </div>
                                    <div className='cardbox-body cardbox-body-text d-flex align-items-start justify-content-start flex-column'>
                                        <p className='m-0 cardbox-text'>
                                            In this section you will find helpful information to prepare 
                                            you for your Arrangement meeting.
                                        </p>
                                        <Link
                                            to={{
                                                pathname: `/app/${onePortalId}/whatToExpect`,
                                                state: { onePortalId },
                                            }}
                                            className='view-info-button btn-theme text-uppercase'
                                        >
                                            View Details
                                        </Link>
                                    </div>
                                </div>
                            )}
                            {isArranger && (
                                <div className='cardbox-wrapper card-whatToBring cardbox-small'>
                                    <div className='cardbox-heading'>
                                        <h4 className='cardbox-heading-name text-black card-heading-size'>
                                            What to Bring?
                                        </h4>
                                    </div>
                                    <div className='cardbox-body cardbox-body-text d-flex align-items-start justify-content-start flex-column'>
                                        <p className='m-0 cardbox-text'>
                                            Items you will want to bring when you meet with your arranger.
                                        </p>
                                        <Link
                                            to={{
                                                pathname: `/app/${onePortalId}/whatToBring`,
                                                state: { onePortalId },
                                            }}
                                            className='view-info-button btn-theme text-uppercase'
                                        >
                                            View Details
                                        </Link>
                                    </div>
                                </div>
                            )}
                        </div>

                        <>
                            {isArranger && (
                                <h1 className='steps-heading'>STEP 2</h1>
                            )}
                            <div className='cardbox-wrapper card-whatToBring'>
                                <div className='cardbox-heading'>
                                    <h4 className='cardbox-heading-name text-black card-heading-size'>
                                        Biography and Death Certificate information
                                    </h4>
                                    <h5 className='cardbox-heading-right-text card-heading-size'>
                                        Status:{" "}
                                        {_.get(decedent, "isFreeze") ? (
                                            <span className='red-color'>
                                                Locked
                                            </span>
                                        ) : (
                                            <span className='green-color'>
                                                Unlocked
                                            </span>
                                        )}{" "}
                                        <TooltipAndInfo
                                            id='biography-tooltip-icon'
                                            showTooltipFn={showBiographyToolTip}
                                            showToolTip={biographyTooltipOpen}
                                            tooltipText={getTooltipText(
                                                _.get(decedent, "isFreeze")
                                            )}
                                            color='#000000'
                                        />
                                    </h5>
                                </div>
                                <div className='cardbox-body cardbox-body-text d-flex align-items-start justify-content-start flex-column'>
                                    <p className='m-0 cardbox-text'>
                                        Use this section to gather the family history and information
                                        you will need for your arrangement. From the information you
                                        provide we will initiate the original death certificate.
                                    </p>
                                    <Link
                                        to={{
                                            pathname: `/${module}/${onePortalId}/certificateDetails`,
                                            state: { onePortalId },
                                            search:searchParameter
                                        }}
                                        className='view-info-button btn-theme text-uppercase'
                                    >
                                        {_.get(decedent, "isFreeze")
                                            ? "View"
                                            : "Add/Edit"}
                                    </Link>
                                </div>
                            </div>
                        </>

                        {isArranger && (
                            <>
                                <h1 className='steps-heading'>STEP 3</h1>
                                <div className='cardbox-wrapper card-whatToBring'>
                                    <div className='cardbox-heading'>
                                        <h4 className='cardbox-heading-name text-black card-heading-size'>
                                            Available Services
                                        </h4>
                                    </div>
                                    <div className='cardbox-body cardbox-body-text d-flex align-items-start justify-content-start flex-column'>
                                        <p className='m-0 cardbox-text'>
                                            Here you will find some of the services we offer.
                                        </p>
                                        <Link
                                            to={{
                                                pathname: `/app/${onePortalId}/servicesDetails`,
                                                state: { onePortalId },
                                            }}
                                            className='view-info-button btn-theme text-uppercase'
                                        >
                                            Select Services
                                        </Link>
                                    </div>
                                </div>
                            </>
                        )}
                        {isArranger && (
                            <h1 className='steps-heading'>STEP 4</h1>
                        )}
                        <div className='cardbox-wrapper card-whatToBring'>
                            <div className='cardbox-heading'>
                                <h4 className='cardbox-heading-name text-black card-heading-size'>
                                    Create Prayer Card 
                                </h4>
                                <h5 className='cardbox-heading-right-text card-heading-size'>
                                    Status:{" "}
                                    {_.get(decedent, "prayerStatus") ? (
                                        <span className='red-color'>
                                            Locked
                                        </span>
                                    ) : (
                                        <span className='green-color'> 
                                            Unlocked
                                        </span>
                                    )}{" "}
                                    <TooltipAndInfo
                                        id='prayer-card-tooltip-icon'
                                        showTooltipFn={showPrayerCardToolTip}
                                        showToolTip={prayerCardTooltipOpen}
                                        tooltipText={getTooltipText(
                                            _.get(decedent, "prayerStatus")
                                        )}
                                        color='#000000'
                                    />
                                </h5>
                            </div>
                            <div className='cardbox-body cardbox-body-text d-flex align-items-start justify-content-start flex-column'>
                                <p className='m-0 cardbox-text'>
                                    Create prayer cards for your loved one and
                                    submit them to your arranger.
                                </p>
                                <Link
                                    to={{
                                        pathname: `/${module}/${onePortalId}/prayerCreation`,
                                        state: {
                                            onePortalId: { onePortalId }
                                        },
                                        search:searchParameter
                                    }}
                                    className='view-info-button btn-theme text-uppercase'
                                >
                                    {_.get(decedent, "prayerStatus")
                                        ? "View"
                                        : "Add/Edit"}
                                </Link>
                                {!isArranger &&
                                    _.get(decedent, "prayerCard.updatedBy") && (
                                        <div className='timestamp-wrapper'>
                                            <p>
                                                <i>
                                                    last edited by{" "}
                                                    {_.get(
                                                        decedent,
                                                        "prayerCard.updatedBy"
                                                    )}{" "}
                                                    at{" "}
                                                    {_.get(
                                                        decedent,
                                                        "prayerCard.updatedAt"
                                                    )
                                                        ? moment(
                                                              decedent
                                                                  .prayerCard
                                                                  .updatedAt
                                                          ).format(
                                                              "MMMM Do YYYY, h:mm:ss a"
                                                          )
                                                        : ""}
                                                </i>
                                            </p>
                                        </div>
                                    )}
                            </div>
                        </div>
                         {isArranger && (
                            <h1 className='steps-heading'>STEP 5</h1>
                        )}
                        <div className='cardbox-wrapper card-whatToBring'>
                            <div className='cardbox-heading'>
                                <h4 className='cardbox-heading-name text-black card-heading-size'>
                                    Create Service Program <label className="BetaButton">Beta Version</label>
                                </h4>
                                <h5 className='cardbox-heading-right-text card-heading-size'>
                                    Status:{" "}
                                    {_.get(decedent, "programStatus") ? (
                                        <span className='red-color'>
                                            Locked
                                        </span>
                                    ) : (
                                        <span className='green-color'>
                                            Unlocked
                                        </span>
                                    )}{" "}
                                    <TooltipAndInfo
                                        id='program-card-tooltip-icon'
                                        showTooltipFn={showProgramCardToolTip}
                                        showToolTip={programCardTooltipOpen}
                                        tooltipText={getTooltipText(
                                            _.get(decedent, "programStatus")
                                        )}
                                        color='#000000'
                                    />
                                </h5>
                            </div>
                            <div className='cardbox-body cardbox-body-text d-flex align-items-start justify-content-start flex-column'>
                                <p className='m-0 cardbox-text'>
                                    Create service program for your loved one and
                                    submit them to your arranger.
                                </p>
                                <Link
                                    to={{
                                        pathname: `/${module}/${onePortalId}/programCreation`,
                                        state: {
                                            onePortalId: { onePortalId }
                                        },
                                        search:searchParameter
                                    }}
                                    className='view-info-button btn-theme text-uppercase'
                                >
                                    {_.get(decedent, "programStatus")
                                        ? "View"
                                        : "Add/Edit"}
                                </Link>
                                {!isArranger &&
                                    _.get(
                                        decedent,
                                        "programCard.updatedBy"
                                    ) && (
                                        <div className='timestamp-wrapper'>
                                            <p>
                                                <i>
                                                    last edited by{" "}
                                                    {_.get(
                                                        decedent,
                                                        "programCard.updatedBy"
                                                    )}{" "}
                                                    at{" "}
                                                    {_.get(
                                                        decedent,
                                                        "programCard.updatedAt"
                                                    )
                                                        ? moment(
                                                              decedent
                                                                  .programCard
                                                                  .updatedAt
                                                          ).format(
                                                              "MMMM Do YYYY, h:mm:ss a"
                                                          )
                                                        : ""}
                                                </i>
                                            </p>
                                        </div>
                                    )}
                            </div>
                        </div> 
                        {isArranger && (
                            <h1 className='steps-heading'>STEP 6</h1>
                        )}
                        <div className='cardbox-wrapper card-whatToBring'>
                            <div className='cardbox-heading'>
                                <h4 className='cardbox-heading-name text-black card-heading-size'>
                                    Obituary
                                </h4>
                                <h5 className='cardbox-heading-right-text card-heading-size'>
                                    Status:{" "}
                                    {_.get(decedent, "obituaryIsLocked") ? (
                                        <span className='red-color'>
                                            Locked
                                        </span>
                                    ) : (
                                        <span className='green-color'>
                                            Unlocked
                                        </span>
                                    )}{" "}
                                    <TooltipAndInfo
                                        id='obituary-tooltip-icon'
                                        showTooltipFn={showObituaryToolTip}
                                        showToolTip={obituaryTooltipOpen}
                                        tooltipText={getTooltipText(
                                            _.get(decedent, "obituaryIsLocked")
                                        )}
                                        color='#000000'
                                    />
                                </h5>
                            </div>
                            <div className='cardbox-body cardbox-body-text d-flex align-items-start justify-content-start flex-column'>
                                <>
                                    <p className='m-0 cardbox-text'>
                                        Add the obituary details about your
                                        loved one and submit them to your
                                        arranger.
                                    </p>
                                    {_.get(decedent, "decedent.opi") && (
                                        <Link
                                            to={{
                                                pathname: urlPathName,
                                                state: {
                                                    onePortalId: { onePortalId },
                                                },
                                                search:searchParameter
                                            }}
                                            className='view-info-button btn-theme text-uppercase'
                                        >
                                            {_.get(decedent, "obituaryIsLocked")
                                                ? "View"
                                                : "Add/Edit"}
                                        </Link>
                                    )}
                                </>
                            </div>
                        </div>
                        {isArranger && (
                            <h1 className='steps-heading'>STEP 7</h1>
                        )}
                         <div className='cardbox-wrapper card-whatToBring'>
                            <div className='cardbox-heading'>
                                <h4 className='cardbox-heading-name text-black card-heading-size'>
                                   Customize Family Website
                                </h4>
                            </div>
                            <div className='cardbox-body cardbox-body-text d-flex align-items-start justify-content-start flex-column'>
                                <>
                                    <p className='m-0 cardbox-text'>
                                       Change between legal & preferred name, add donation / charity options,
                                       social media links & view messages sent by friends & family.
                                    </p>
                                    {_.get(decedent, "decedent.opi") && (
                                        <Link
                                        to={{
                                           pathname: `/${module}/${onePortalId}/customizeFamilyWebsite`,
                                            state: {
                                                onePortalId: { onePortalId },
                                            },
                                            search:searchParameter
                                        }}
                                        className='view-info-button btn-theme text-uppercase'
                                    >
                                            Add/Edit
                                        </Link>
                                    )}
                                </>
                            </div>
                        </div>
                        {isArranger && (
                            <h1 className='steps-heading'>STEP 8</h1>
                        )}
                        <div className='cardbox-wrapper card-whatToBring'>
                            <div className='cardbox-heading'>
                                <h4 className='cardbox-heading-name text-black card-heading-size'>
                                    Share Memories
                                </h4>
                            </div>
                            <div className='cardbox-body cardbox-body-text d-flex align-items-start justify-content-start flex-column'>
                                <>
                                    <p className='m-0 cardbox-text'>
                                        Add memories about your loved one and
                                        share the family website link with
                                        others.
                                    </p>
                                    {_.get(decedent, "decedent.opi") && (
                                        <Link
                                            to={{
                                                pathname:
                                                    familyWebsiteUrlPathName,
                                                state: {
                                                    onePortalId: _.get(
                                                        decedent,
                                                        "decedent.opi"
                                                    ),
                                                },
                                            }}
                                            className='view-info-button btn-theme text-uppercase'
                                            target='_blank'
                                            rel='noopener noreferrer'
                                        >
                                            Go TO FAMILY WEBSITE
                                        </Link>
                                    )}
                                </>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </AppLayout>
    );
};

export default Arrangements;