import Dropzone from 'react-dropzone';
import addNotification from '../../utils/notification/notification';

const DropzoneFileSelect = ({ maxSize, onDropAccepted }) => {
  const handleDropRejected = (files) => {
    const errorType = files[0].errors[0].code;
    let errorObject = null;

    switch (errorType) {
      case 'file-invalid-type':
        errorObject = {
          title: 'Error',
          message: `Only .docx files are allowed`,
          type: 'danger',
        };
        break;

      default:
        errorObject = {
          title: 'Error',
          message: `Max file size allowed is ${maxSize} Mb`,
          type: 'danger',
        };
    }

    addNotification(errorObject);
  };

  return (
    <Dropzone
      multiple={false}
      noKeyboard
      maxSize={maxSize * 1024 * 1024}
      accept={
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
      }
      onDropAccepted={onDropAccepted}
      onDropRejected={handleDropRejected}
      onError={() => {
        addNotification({
          title: 'Error',
          message: 'Something went wrong. Please try again later',
          type: 'danger',
        });
      }}
    >
      {({ getRootProps, getInputProps, isDragReject }) => {
        return (
          <div>
            <div
              {...getRootProps({
                className: `dropzone ${
                  isDragReject ? 'dropzone-file-reject' : ''
                }`,
              })}
            >
              <input {...getInputProps()} />
              <div>
                <div className="dropzone-file-selector">
                  {!isDragReject && (
                    <>
                      <div className="dropzone-icon">
                        <i className="fas fa-upload fa-2x" />
                      </div>
                      <p className="dropzone-text">
                        <span className="dropzone-text-heighlight">
                          Click to upload
                        </span>{' '}
                        or drag and drop
                      </p>
                    </>
                  )}
                  {isDragReject && (
                    <p className="dropzone-text dropzone-text-reject">
                      Only .docx files are allowed
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        );
      }}
    </Dropzone>
  );
};

export default DropzoneFileSelect;
