import _ from "lodash";
import React from "react";
import { Nav, NavItem, NavLink } from "reactstrap";

const Tabs = ({ urls, activeTab, onTabClick, onTabClose, errorObjectList }) => {
  const checkColor = (index) => {
    if (
      activeTab === index &&
      (!errorObjectList?.[index] ||
        _.isEmpty(errorObjectList?.[index]) ||
        !_.some(errorObjectList?.[index], (value) => !!value))
    ) {
      return "green";
    } else if (
      (activeTab !== index && _.isEmpty(errorObjectList?.[index])) ||
      !_.some(errorObjectList?.[index], (value) => !!value)
    ) {
      return "black";
    } else if (
      !_.isEmpty(errorObjectList?.[index]) &&
      _.some(errorObjectList?.[index], (value) => !!value)
    ) {
      return "red";
    }
  };
  return (
    <>
      {urls?.length > 1 && (
        <Nav tabs>
          {urls.map((url, index) => (
            <NavItem
              key={index}
              style={{
                borderBottom:
                  activeTab === index ? "5px solid #cc8b12" : "none",
              }}
            >
              <NavLink
                onClick={() => onTabClick(index)}
                style={{
                  color: checkColor(index),
                }}
              >
                {`Dontaion URL ${index + 1}`}
              </NavLink>{" "}
              {activeTab === index && (
                <i
                  className="modal-close fas fa-times"
                  onClick={() => onTabClose(index)}
                  style={{ color: checkColor(index) }}
                />
              )}
            </NavItem>
          ))}
        </Nav>
      )}
    </>
  );
};

export default Tabs;
