import React, { useRef } from "react";

const InputTag = ({ tags = [], handleChange, regex, handleError, error = null }) => {
    const tagRef = useRef();

    const removeTag = i => {
        const newTags = [...tags];
        newTags.splice(i, 1);
        handleChange(newTags);
    };

    const validateOnChange = e => {
        if (!e.target.value.trim()) {
            checkError('');
        }
    }

    const validateOnBlur = e => {
        const val = e.target.value;
        if (tags.find(tag => tag.toLowerCase() === val.trim().toLowerCase())) {
            checkError("Duplicate value");
        } else if (val.trim() && !val.match(regex)) {
            checkError("Invalid URL format");
        } else if (val.trim()) {
            checkError('');
            handleChange([...tags, val.trim()]);
            tagRef.current.value = null;
        } else {
            checkError('');
        }
    }

    const checkError = (msg) => {
        if (error !== `URL doesn't exists`) {
            handleError(msg);
        }
    }

    const inputKeyDown = e => {
        const val = e.target.value;
        if (e.key === " " && val) {
            if (tags.find(tag => tag.toLowerCase() === val.trim().toLowerCase())) {
                checkError("Duplicate value");
            } else if (!val.match(regex)) {
                checkError("Invalid URL format");
            } else {
                checkError('');
                handleChange([...tags, val.trim()]);
                tagRef.current.value = null;
            }
        } else if (e.key === "Backspace" && !val) {
            removeTag(tags.length - 1);
            checkError('');
        }
    };

    return (
        <div className="input-tag">
            <ul className="input-tag__tags">
                {tags.map((tag, i) => (
                    <li key={tag}>
                        {tag}
                        <button
                            type="button"
                            onClick={() => {
                                removeTag(i);
                            }}
                        >
                            +
                        </button>
                    </li>
                ))}
                <li className="input-tag__tags__input">
                    <input
                        type="text"
                        onKeyDown={inputKeyDown}
                        ref={tagRef}
                        onChange={validateOnChange}
                        onBlur={validateOnBlur}
                        placeholder={"Enter YouTube or Vimeo or Tribute link"}
                    />
                </li>
            </ul>
        </div>
    );
};

export default InputTag;
