const initialState = {
    showLoader: true,
    data: ''
};

export default (state = initialState, action) => {
    switch (action.type) {
        case `SET_LOADER`:
            return { ...state, showLoader: action.payload };
        case `SET_DATA`:
            return { ...state, data: action.payload };
        default:
            return state;
    }
};
