import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import useAsync from "react-use-async-hook";
import _ from "lodash";
import { Prompt, useHistory } from "react-router-dom";
import TooltipAndInfo from "../../../TooltipAndInfo";
import { useSetLoading } from "../../../utils/hooks/loader";
import addNotification from "../../../utils/notification/notification";
import * as CustomizeFamilyWebsite from "../../../../api/customizeFamilyWebsite";
import ConfirmationDialog from "../../../utils/confirmationDailouge";
import Tabs from "./tabs";
import DonationForm from "./donationForm";
var timeout;

const CustomizeDonationLink = ({
  decedent,
  onePortalId,
  executeGetDecedent,
}) => {
  const history = useHistory();
  const [customizeLinkToolTip, setCustomizeLinkToolTip] = useState(false);
  const [errorObjectList, setErrorObjectList] = useState([]);
  const [isDisable, setIsDisabled] = useState(true);
  const [isDeleteDisabled, setIsDeleteDisables] = useState(true);
  const [isDonationUrlChangesSaved, setDonationUrlChangesSaved] =
    useState(true);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [isDonationError, setIsDonationError] = useState(false);
  const [isNavigate, setIsNavigate] = useState(false);
  const [targetLocation, setTargetLocation] = useState(null);
  const [listOfUrls, setListOfUrls] = useState(["DonationUrl 1"]);
  const [activeTab, setActiveTab] = useState(0);
  const [deletedDataObjects, setDeletedDataObjects] = useState([]);
  const [enableAddUrl,setEnableAddurl] = useState(true)
  const [formDataList, setFormDataList] = useState([
    { donationUrlDescription: "", donationUrlSrc: "", donationUrlName: "" },
  ]);
  const showCustomizeLinkToolTip = () => {
    setCustomizeLinkToolTip(!customizeLinkToolTip);
  };

  useEffect(() => {
    if (_.get(decedent, "donationDetails")?.length >= 1) {
      setFormDataList(_.get(decedent, "donationDetails"));
      let newList = [];
      _.get(decedent, "donationDetails")?.map((item, i) => {
        newList.push(`DonationUrl ${i}`);
      });
      setListOfUrls(newList);
      setIsDeleteDisables(false);
    }
  }, [decedent]);

  const {
    data: donationData,
    loading: donationDetailsLoader,
    execute: executeDonationDetails,
  } = useAsync({
    autoExecute: false,
    task: useCallback(async (values, deletedDataValues, opi) => {
      const finalData = filterFun(values, deletedDataValues);
      if (finalData?.length) {
        setEnableAddurl(false)
        return await CustomizeFamilyWebsite.updateDonationDetails(
          { data: finalData },
          opi
        );
      }
    }, []),
    dataLoader: useCallback((res) => {
      return _.get(res, "data");
    }, []),
    onError: useCallback((error) => {
      addNotification({
        type: "danger",
        title: "Error",
        message: _.get(
          error,
          "response.data.message",
          "Oops! Something went wrong"
        ),
      });
      setDonationUrlChangesSaved(false);
    }, []),
  });

  const fetchDecedentData = async() => {
    await executeGetDecedent(onePortalId);
    setEnableAddurl(true)
    setIsDisabled(true);
    setIsDeleteDisables(false);
    setDonationUrlChangesSaved(true);
    setIsDonationError(false);
    setDeletedDataObjects([]);
  }

  useEffect(() => {
    if (_.get(donationData, "success")) {
      addNotification({
        title: "Success",
        type: "success",
        message: "Donation Details Updated Successfully",
      });
      fetchDecedentData()
    }
  }, [donationData]);

  const filterFun = (values, deletedDataValues) => {
    const finalData = [];
    values?.map((item) => {
      if (item?.isDataChanged) {
        finalData.push(item);
      }
    });
    const newArray = finalData?.concat(deletedDataValues);
    return newArray;
  };

  const handleBeforeUnload = async (event) => {
    if (!checkIsError()) {
      await executeDonationDetails(
        formDataList,
        deletedDataObjects,
        onePortalId
      );
    }
  };

  function warning() {
    timeout = setTimeout(function () {
      setTimeout(() => {
        handleBeforeUnload();
      }, [1000]);
    }, 1000);
    return "";
  }

  function noTimeout() {
    clearTimeout(timeout);
  }

  if (!isDonationUrlChangesSaved) {
    window.onbeforeunload = warning;
    window.unload = noTimeout;
  } else {
    window.onbeforeunload = null;
  }

  const handleNavigation = (location) => {
    if (!isDonationUrlChangesSaved && !showConfirmationDialog) {
      checkIsError();
      setIsNavigate(true);
      setTargetLocation(location);
      setShowConfirmationDialog(true);
      return false;
    }
    return true;
  };

  const handleSaveChanges = async () => {
    await executeDonationDetails(formDataList, deletedDataObjects, onePortalId);
    setShowConfirmationDialog(false);
    if (isNavigate) {
      history.push(targetLocation.pathname);
      setIsNavigate(false);
    }
  };

  const handleDiscardlNavigation = () => {
    setDonationUrlChangesSaved(true);
    setShowConfirmationDialog(false);
    if (isNavigate) {
      setIsNavigate(false);
      history.push(targetLocation.pathname);
    }
  };

  const handleCancelNavigagtion = () => {
    setDonationUrlChangesSaved(false);
    setShowConfirmationDialog(false);
    setIsNavigate(false);
  };

  const {
    data: deletedData,
    loading: deleteDonationDetailsLoader,
    execute: executeDeleteDonationDetails,
  } = useAsync({
    autoExecute: false,
    task: useCallback(async (opi) => {
      await CustomizeFamilyWebsite.deleteDonationDetails(opi);
    }, []),
    dataLoader: useCallback((res) => {
      addNotification({
        title: "Success",
        type: "success",
        message: "Donation Details Deleted Successfully",
      });
      setIsDeleteDisables(true);
      return _.get(res, "data");
    }, []),
    onError: useCallback((error) => {
      addNotification({
        type: "danger",
        title: "Error",
        message: _.get(
          error,
          "response.data.message",
          "Oops! Something went wrong"
        ),
      });
    }, []),
  });

  const onTabClick = (index) => {
    setActiveTab(index);
  };

  const onTabClose = (index) => {
    setIsDisabled(false);
    const newUrls = [...listOfUrls];
    newUrls.splice(index, 1);
    setListOfUrls(newUrls);
    if (formDataList[index]?.id) {
      const deletedDataList = [...deletedDataObjects];
      const newObj = { ...formDataList[index], isDeleted: true };
      deletedDataList.push(newObj);
      setDeletedDataObjects(deletedDataList);
      setDonationUrlChangesSaved(false);
    }
    const dataList = [...formDataList];
    dataList.splice(index, 1);
    setFormDataList(dataList);
    const errorsList = [...errorObjectList];
    errorsList.splice(index, 1);
    setErrorObjectList(errorsList);
    if (index === 0) setActiveTab(0)
    else setActiveTab(index - 1);
  };

  const checkIsError = () => {
    let result = false;
    const errorsList = [...errorObjectList];
    formDataList?.map((item, i) => {
      const errorObj = { ...errorObjectList[i] };
      if (!item?.donationUrlSrc || item?.donationUrlSrc === "") {
        errorObj["donationUrlSrc"] = "Url is required";
        result = true;
      }
      if (!item?.donationUrlName || item?.donationUrlName === "") {
        result = true;
        errorObj["donationUrlName"] = "ButtonName is required";
      }
      if (!_.isEmpty(errorObj)) {
        errorsList[i] = errorObj;
        setErrorObjectList(errorsList);
      }
    });
    result = _.some(errorsList, (obj) => {
      if (
        obj &&
        (obj?.donationUrlDescription ||
          obj?.donationUrlSrc ||
          obj?.donationUrlName)
      ) {
        return true;
      }
    });
    setIsDonationError(result);
    return result;
  };

  const showLoading = useMemo(
    () => deleteDonationDetailsLoader || donationDetailsLoader,
    [deleteDonationDetailsLoader, donationDetailsLoader]
  );
  useSetLoading(showLoading);

  return (
    <div className="cutomizeLinkWrapper">
      <Prompt when={!isDonationUrlChangesSaved} message={handleNavigation} />
      {showConfirmationDialog && !isDonationUrlChangesSaved && (
        <ConfirmationDialog
          isOpen={showConfirmationDialog}
          onSave={handleSaveChanges}
          onDisCard={handleDiscardlNavigation}
          onCancel={handleCancelNavigagtion}
          textToDisplay={"You have unsaved  Custom Donation Url Changes"}
          isError={isDonationError}
        />
      )}
      <div className="display-name-heading">
        <div className="display-name">
          <div className="customIndex">2</div>
          <div>Donation / Charity Options</div>
        </div>
        <TooltipAndInfo
          id="customizeLinkToolTip"
          showTooltipFn={showCustomizeLinkToolTip}
          showToolTip={customizeLinkToolTip}
          size={25}
          tooltipText="Customize your donation link to be displayed on the Family Website"
          color="#000000"
        />
      </div>
      <Tabs
        urls={listOfUrls}
        activeTab={activeTab}
        onTabClick={onTabClick}
        onTabClose={onTabClose}
        errorObjectList={errorObjectList}
      />
      <DonationForm
        formDataList={formDataList}
        setFormDataList={setFormDataList}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        errorObjectList={errorObjectList}
        setErrorObjectList={setErrorObjectList}
        executeDonationDetails={executeDonationDetails}
        checkIsError={checkIsError}
        onePortalId={onePortalId}
        listOfUrls={listOfUrls}
        setListOfUrls={setListOfUrls}
        setDonationUrlChangesSaved={setDonationUrlChangesSaved}
        executeDeleteDonationDetails={executeDeleteDonationDetails}
        setIsDisabled={setIsDisabled}
        setIsDeleteDisables={setIsDeleteDisables}
        setDeletedDataObjects={setDeletedDataObjects}
        isDeleteDisabled={isDeleteDisabled}
        isDisable={isDisable}
        deletedDataObjects={deletedDataObjects}
        enableAddUrl={enableAddUrl}
        setEnableAddurl={setEnableAddurl}
      />
    </div>
  );
};

export default CustomizeDonationLink;
