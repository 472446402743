import React, { useEffect, useCallback, useMemo, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import useAsync from "react-use-async-hook";
import MapPinIcon from "../../../src/assets/images/map-pin.svg";
import NavigationIcon from "../../../src/assets/images/navigation.svg";
import grave from "../../../src/assets/images/grave.png";
import orangeGrave from '../../../src/assets/images/orangeGrave.png'
import orangeArrowRight from '../../../src/assets/images/orangeArrowRight.png'
import blueArrowRight from '../../../src/assets/images/BlueArrowRight.png'
import MapIcon from "../../../src/assets/images/map.svg";
import TwitterIcon from "../../../src/assets/images/twitter.svg";
import FaceBookIcon from "../../assets/images/facebook.svg";
import MailIcon from "../../assets/images/mail.svg";
import LinkIcon from "../../assets/images/copyLink.svg";
import { getLocationData } from "../../api/location";
import { useSetLoading } from "../utils/hooks/loader";
import { buildPanoramicViewURL } from "../utils/functions";
import { average } from "geolocation-utils";
import { data } from "azure-maps-control";
import _ from "lodash";
import moment from "moment";
import TooltipAndInfoForImage from "../TooltipAndInfo/toolTipandInfoForImage";
import ContactFamilyMember from "./contactFamilyMember";

import addNotification from "../utils/notification/notification";
import { FormControlLabel, Switch } from "@material-ui/core";
import { doLogout, getAccessToken, handleAuthLogout } from "../../utils";
import { privacyModeAPI } from "../../api/memories";
import { apiErrorHandler } from "../../utils/handlers";
import LoginForm from "./login/LoginForm";
import ProfilePictureSection from "../features/familyWebsite/memoriesListing/profilePictureSection";
import ForgotPassword from "./ForgotPassword/ForgotPassword";
import { LOCATE_GRAVESITE_URL } from "../../../src/constants/appConstants";
import { getLocationByKey, getLocationUrls } from "../utils/variables";
import DisplaySocialMediaProfiles from "./displaySocialMediaProfiles";
import SendFlowers from "./sendFlowers";
import SubscribeDecedent from "./subscribeDecedent";
import RedirectToFaaButton from "./redirectToFaaButton";
import DisplayDonationUrl from './displayDonationUrl.js'
import Cookies from 'universal-cookie';

let currentLat, currentLong;
function showPosition(position) {
    currentLat = position.coords.latitude;
    currentLong = position.coords.longitude;
}

const Sidebar = ({
    opi,
    decedentData,
    obituary,
    session,
    loggedIn,
    refreshSession,
    setActiveTab,
    isLoading = false,
    isFamilyAccess,
    setFamilyAccess,
    executeObituary,
    executeDecedentAPI,
    executeWebsiteAccess,
    msalInstance,

}) => {
    const text = "Copy website link";
    const [copyText, setCopyText] = useState(text);
    const [showTwitterTooltip, setShowTwitterTooltip] = useState(false);
    const [showFacebookTooltip, setShowFacebookTooltip] = useState(false);
    const [showMailTooltip, setShowMailTooltip] = useState(false);
    const [showLinkTooltip, setShowLinkTooltip] = useState(false);
    const [isLoginPopup, setLoginPopup] = useState(false);
    const [isForgotPassword, setForgotPassword] = useState(false);
    const [socialMediaLinksData,setSocialMediaLinksData] = useState(null)

    const isPrivate = _.get(decedentData, "isPrivate", false);
    const [state, setState] = useState(isPrivate);

    useEffect(() => {
        setState(isPrivate);
    }, [isPrivate]);

    useEffect(() => {
        if(decedentData?.socialMediaLinks) {
            setSocialMediaLinksData(_.get(decedentData?.socialMediaLinks,'socialMediaLinks'))
        }
    },[decedentData])

    const handleChange = async event => {
        setState(event.target.checked);
        try {
            const data = { isPrivate: event.target.checked };
            const url = privacyModeAPI(opi);
            fetch(url, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${getAccessToken()}`,
                },
                body: JSON.stringify(data),
            })
                .then(response => response.json())
                .then(data => {
                    const status = event.target.checked ? "On" : "Off";
                    addNotification({
                        type: "success",
                        title: "Success",
                        message: `Privacy turned ${status}.`,
                    });
                })
                .catch(error => {
                    if (
                        document.getElementsByClassName("notification-danger")
                            .length == 0
                    ) {
                        apiErrorHandler(error);
                    }
                });
        } catch (error) {
            if (
                document.getElementsByClassName("notification-danger").length ==
                0
            ) {
                apiErrorHandler(error);
            }
        }
    };

    const showHideToolTip = () => {
        setShowTwitterTooltip(!showTwitterTooltip);
    };
    const showHideFacebookToolTip = () => {
        setShowFacebookTooltip(!showFacebookTooltip);
    };
    const showHideMailToolTip = () => {
        setShowMailTooltip(!showMailTooltip);
    };
    const showHideLinkToolTip = () => {
        setCopyText(text);
        setShowLinkTooltip(!showLinkTooltip);
    };
    /**
     * Fetch Cemetery Location details
     */
    const {
        data: cemeteryLocation,
        loading: locationAPILoading,
        execute: executeCemeteryLocation,
    } = useAsync({
        autoExecute: false,
        task: getLocationData,
        dataLoader: useCallback(res => {
            return _.get(res, "data.cemeteryLocation");
        }, []),
    });

    /**
     * Get initial data
     */
    useEffect(() => {
        if (opi) {
            executeCemeteryLocation(opi);
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(showPosition);
            }
        }
    }, []);
    const openPanoramicView = () => {
        const datas = cemeteryLocation.geoJson;
        const bounds = data.BoundingBox.fromData(datas);
        const query = {
            lat: bounds?.[1],
            long: bounds?.[0],
            first: decedentData.firstName,
            last: decedentData.lastName,
            location: cemeteryLocation.propertyName,
        };
        window.open(buildPanoramicViewURL(query), "_blank");
    };
    const getAvgLatLan = () => {
        let avgLatLan = {},
            geoData;
        const locations = _.get(
            cemeteryLocation,
            "geoJson.geometry.coordinates",
            ""
        );
        if (locations) {
            if (
                _.get(cemeteryLocation, "geoJson.geometry.type") ===
                "MultiPolygon"
            ) {
                geoData = _.flattenDepth(locations, 2);
            } else {
                geoData = _.flatten(locations);
            }
            var objs = geoData.map(function (x) {
                return {
                    lat: x[1],
                    lng: x[0],
                };
            });
            avgLatLan = average(objs);
        }
        return avgLatLan;
    };

    const getDirectionsMap = async () => {
        const avgLatLan = getAvgLatLan();
        const query = `https://www.google.com/maps/dir/?api=1&amp&origin=${currentLat},${currentLong}&destination=${avgLatLan.lat},${avgLatLan.lng}&travelmode=DRIVING`;
        window.open(query, "_blank");
    };

    const handleLocateAGraveSite = () => {
        const query = `${LOCATE_GRAVESITE_URL}/map-tour`;
        window.open(query, "_blank");
    };

    const getBurialLocation = () => {
        const avgLatLan = getAvgLatLan();
        const query = `https://maps.google.com/?q=${avgLatLan.lat},${avgLatLan.lng}&amp;t=k`;
        window.open(query, "_blank");
    };
    const getPersonName = person => {
        let name = [];
        if (_.get(person, "firstName") && _.get(person, "firstName").trim()) {
            name.push(_.get(person, "firstName").trim());
        }
        if (_.get(person, "middleName") && _.get(person, "middleName").trim()) {
            name.push(_.get(person, "middleName").trim());
        }
        if (_.get(person, "lastName") && _.get(person, "lastName").trim()) {
            name.push(_.get(person, "lastName").trim());
        }
        return name.join(" ") || "";
    };
    const calculateAge = (dateOfBirth, dateOfDeath) => {
        if (dateOfBirth || dateOfDeath) {
            const dob = moment(dateOfBirth) || null;
            const dod = moment(dateOfDeath) || null;
            if (dateOfBirth && dateOfDeath) {
                let age = `${dod.diff(dob, "years")}`;
                if (age === "1") {
                    age = age + " Year";
                } else {
                    age = age + " Years";
                }
                return age;
            } else if (dateOfBirth) {
                let age = `${moment().diff(dob, "years")}`;
                if (age === "1") {
                    age = age + " Year";
                } else {
                    age = age + " Years";
                }
                if (age < 0) {
                    return "-";
                } else return age;
            } else if (dateOfDeath) {
                return "-";
            }
        }
        return "-";
    };
    const url = window.location.href;
    const decedentName = getPersonName(decedentData);
    const subject = `${decedentName}'s Memorial Page`;
    const faceBookLink = `https://www.facebook.com/sharer/sharer.php?u=${url}`;
    const mailLink = `mailto:?subject=${subject}&body=${subject} has been shared with you to Share Memories,Send Flowers and more ! ${url}`;
    const twitterLink = `https://twitter.com/intent/tweet?text=Visit ${subject}&url=${url}`;
    const showLoading = useMemo(() => locationAPILoading[locationAPILoading]);
    useSetLoading(showLoading);

    const locCode = decedentData && decedentData.FuneralLocationCode;
    const isAzureMFAEnabled = process.env.REACT_APP_IS_AZURE_MFA_ENABLED;

    const handleLogout = () => {
        const cookies = new Cookies();
        const role = cookies.get('role')
        const logoutRedirectUrl = process.env.REACT_APP_HOST !== 'https://temp.clcafamily.com' ? `/obituaries${location?.pathname}` : `${location?.pathname}`
        const accountDetails = msalInstance?.getAllAccounts();
        if(role === 'cl-arranger' && isAzureMFAEnabled)
        handleAuthLogout(msalInstance, accountDetails, logoutRedirectUrl, true)
        doLogout();
        addNotification({
            type: "success",
            title: "Success",
            message: "User Logged Out Successfully",
        });
    };

    const location = useLocation();
    const callAPIS = async () => {
      if (!session.isLoggedIn) {
        const search =
          location.search ||
          location.pathname.slice(location.pathname.indexOf("?"));
        const queryparamUrl = new URLSearchParams(search).get("memory");
        if (queryparamUrl) {
          setLoginPopup(true);
        }
        setActiveTab("obituary");
      }
    };

    useEffect(() => {
      callAPIS();
    }, [session?.isLoggedIn]);

    const getFormattedDobDod = () => {
      let dateStr = "";
      if (decedentData?.dateOfBirth) {
        dateStr = moment(decedentData.dateOfBirth).format("LL");
      }
      if (decedentData?.dateOfDeath) {
        dateStr =
          dateStr + " - " + moment(decedentData?.dateOfDeath).format("LL");
      }
      if (decedentData?.dateOfBirth && !decedentData?.dateOfDeath) {
        dateStr = dateStr + " - ";
      }
      return dateStr;
    };


    return (
        <div className='dashboards-card'>
            <div className='dashboard-card-content'>
                <div className='profile-picture-wrapper'>
                    {/* <img
            src={
              obituary && obituary.pictureUrl
                ? obituary.pictureUrl
                : defaultImage
            }
            alt="ProfilePic"
            className="profile-pic-wrapper"
          /> */}
                    <ProfilePictureSection
                        isLoggedIn={session.isLoggedIn}
                        opi={opi}
                        imageClassName={"upload-button"}
                        data={obituary}
                        isLoading={isLoading}
                        isFamilyAccess={isFamilyAccess}
                        getObituary={executeObituary}
                    />
                </div>
                <div className='dashboard-card-decedentinfo'>
                    <div className='dashboard-card-keyvalue nameValue'>
                        <span className='dashboard-card-value displayNameSpan'>
                            {obituary?.displayName}
                        </span>
                    </div>
                    {decedentData && (decedentData?.dateOfBirth || decedentData?.dateOfDeath) &&
                        <div className='dashboard-card-keyvalue birthDeathDetails'>
                            <span className='dashboard-card-value dobSpan'>
                                {getFormattedDobDod()}
                            </span>
                        </div>
                    }
                </div>
                {!_.isEmpty(socialMediaLinksData) && (
                <div className="dashboard-card-socialMediaProfileInfo">
                   <DisplaySocialMediaProfiles socialMediaLinksData={socialMediaLinksData} />
                </div>)}
                <div className='dashboard-card-viewinfo'>
                    <div className='dashboard-card-key-value '>
                        <span className='heading'>Locations</span>
                    </div>
                    <div className='dashboard-card-key-value'>
                        <a href={getLocationUrls(locCode)} target='_blank' rel='noreferrer'>
                            {" "}
                            {getLocationByKey(locCode)}
                        </a>
                    </div>
                    <>
                        {cemeteryLocation &&
                            cemeteryLocation.geoJson &&
                            !_.isEmpty(cemeteryLocation.geoJson) && (
                                <>
                                    <div className='dashboard-card-key-value'>
                                        <Link
                                            onClick={getBurialLocation}
                                            className='options-wrapper'
                                        >
                                            <img
                                                src={NavigationIcon}
                                                alt='NavigationIcon'
                                                className='icon-wrapper'
                                            />
                                            Burial Location
                                        </Link>
                                    </div>
                                    <div className='dashboard-card-key-value'>
                                        <Link
                                            onClick={openPanoramicView}
                                            className='options-wrapper'
                                        >
                                            <img
                                                src={MapPinIcon}
                                                alt='MapPinIcon'
                                                className='icon-wrapper'
                                            />
                                            Cemetery 360 View
                                        </Link>
                                    </div>
                                    <div className='dashboard-card-key-value'>
                                        <Link
                                            onClick={getDirectionsMap}
                                            className='options-wrapper'
                                        >
                                            <img
                                                src={MapIcon}
                                                alt='MapIcon'
                                                className='icon-wrapper'
                                            />
                                            Get Directions
                                        </Link>
                                    </div>
                                </>
                            )}

                        <div className='dashboard-card-key-value'>
                            <Link
                                onClick={handleLocateAGraveSite}
                                className='options-wrapper locateGrave'
                            >
                                <img
                                    src={orangeGrave}
                                    alt='GraveIcon'
                                    className='icon-wrapper'
                                />
                                Locate a Gravesite
                                <img src={orangeArrowRight} alt='orangeArrowRight' className="rightArrow" />
                            </Link>
                        </div>
                    </>
                </div>
                {
                    decedentData?.donationDetails?.length >= 1 && (
                        <DisplayDonationUrl decedentData={decedentData} />
                    )
                }
                <div className="dashboard-card-sendFlowers">
                    <SendFlowers opi={ opi} />
                </div>
                <div className="dashboard-card-subscribeDecedent">
                    <SubscribeDecedent decedentData={decedentData} opi={opi}/>
                </div>
                {_.get(decedentData, "isDecedentHaveFamilyArranger") &&
                    (_.get(decedentData, "hideContactFamily")
                        ? _.get(session, "isLoggedIn") && isFamilyAccess
                        : true) && (
                        <div className="dashboard-card-contactFamilyMember">
                        <ContactFamilyMember
                            decedentData={decedentData}
                            opi={opi}
                            session={session}
                            isFamilyAccess={isFamilyAccess}
                        />
                        </div>
                    )
                }
                <div className='dashboard-card-shareInfo'>
                    <div className='dashboard-card-key-value'>
                        <span className='heading shareText'>Share Website</span>
                        <div className='dashboard-card-share-value'>
                        <TooltipAndInfoForImage
                            icon={TwitterIcon}
                            showTooltipFn={showHideToolTip}
                            showToolTip={showTwitterTooltip}
                            tooltipText='Share to Twitter'
                            id='twitter-image'
                            link={twitterLink}
                        />
                        <TooltipAndInfoForImage
                            icon={FaceBookIcon}
                            showTooltipFn={showHideFacebookToolTip}
                            showToolTip={showFacebookTooltip}
                            tooltipText='Share to Facebook'
                            id='facebook-image'
                            link={faceBookLink}
                        />
                        <TooltipAndInfoForImage
                            icon={MailIcon}
                            showTooltipFn={showHideMailToolTip}
                            showToolTip={showMailTooltip}
                            tooltipText='Share to Mail'
                            id='mail-image'
                            link={mailLink}
                        />
                        <TooltipAndInfoForImage
                            icon={LinkIcon}
                            showTooltipFn={showHideLinkToolTip}
                            showToolTip={showLinkTooltip}
                            tooltipText={copyText}
                            onClick={() => {
                                navigator.clipboard.writeText(url);
                                setCopyText("Website link copied");
                            }}
                            id='link-image'
                        />
                    </div>
                    </div>

                    {session.isLoggedIn && isFamilyAccess && (
                        <div className='dashboard-formcontrol-label'>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={state}
                                        onChange={handleChange}
                                        value={state}
                                        inputProps={{
                                            "aria-label": "secondary checkbox",
                                        }}
                                    />
                                }
                                label={"Make website private"}
                                labelPlacement='start'
                                style={{ margin: 0 }}
                            />
                        </div>
                    )}
                    <RedirectToFaaButton
                        session={session}
                        isFamilyAccess={isFamilyAccess}
                        opi={opi}
                    />
                    <button
                        className={`dashboard-login-button ${
                            session.isLoggedIn && isFamilyAccess
                                ? "logout-button"
                                : "login-button"
                        }`}
                        onClick={() =>
                            session.isLoggedIn && isFamilyAccess
                                ? handleLogout()
                                : setLoginPopup(true)
                        }
                    >
                        {session.isLoggedIn && isFamilyAccess ? "Log out" : "Login"} <img src={blueArrowRight} alt="blueArrowRight" className="blueArrow"/>
                    </button>
                    {!isForgotPassword && isLoginPopup && (
                        <LoginForm
                            setLoginPopup={setLoginPopup}
                            loggedIn={loggedIn}
                            refreshSession={refreshSession}
                            opi={opi}
                            decedentData={decedentData}
                            isForgotPassword={isForgotPassword}
                            setForgotPassword={setForgotPassword}
                            isFamilyAccess={isFamilyAccess}
                            setFamilyAccess={setFamilyAccess}
                        />
                    )}
                    {isForgotPassword && (
                        <ForgotPassword setForgotPassword={setForgotPassword} setLoginPopup={setLoginPopup} />
                    )}
                </div>
            </div>
        </div>
    );
};
export default Sidebar;
