import React, { useState } from "react";
import { Formik } from "formik";
import _ from "lodash";
import * as yup from "yup";
import { Form, Tooltip } from "reactstrap";
import { formatOptions } from "../../../../utils/functions";
import SearchSelectComponent from "../../../../utils/SearchSelelctComponent/SearchSelelctComponent";
import { certificateFreezeText } from "../../constants/constants";

const FatherDetails = ({
    states,
    countries,
    decedent,
    handleSubmitDetail,
    isDisable,
}) => {
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const showToolTip = disabled => {
        if (isDisable) {
            setTooltipOpen(disabled);
        }
    };
    const filterState = (options, country) => {
        options = _.filter(options, option => {
            return option.country.name === country;
        });
        return options;
    };

    const handleSearch = (searchString, options) => {
        if (searchString) {
            return _.filter(options, opt =>
                _.includes(
                    _.toLower(_.get(opt, "label")),
                    _.toLower(searchString)
                )
            );
        }
        return options;
    };

    return (
        <Formik
            initialValues={{
                birthCountry:
                    _.get(decedent, "birthCountry") || ""
                        ? {
                              label: decedent.birthCountry,
                              value: decedent.birthCountry,
                              ..._.find(countries, {
                                  name: decedent.birthCountry,
                              }),
                          }
                        : "",
                birthState: _.get(decedent, "birthState")
                    ? {
                          label: decedent.birthState,
                          value: decedent.birthState,
                          ..._.find(states, {
                              name: decedent.birthState,
                          }),
                      }
                    : "",
                firstName: _.get(decedent, "firstName") || "",
                lastName: _.get(decedent, "lastName") || "",
                middleName: _.get(decedent, "middleName") || "",
                relation: _.get(decedent, "relation") || "",
                states: formatOptions(states),
                countries: formatOptions(countries),
            }}
            validationSchema={yup.object().shape({
                firstName: yup.string().required("First Name is required"),
            })}
            enableReinitialize={true}
            onSubmit={(values, formikBag) => {
                handleSubmitDetail(values, formikBag);
            }}
        >
            {({
                values,
                handleSubmit,
                handleChange,
                handleBlur,
                isSubmitting,
                setFieldValue,
                errors,
                touched,
            }) => {
                return (
                    <Form id='fatherForm' onSubmit={handleSubmit}>
                        <div className='inputcard-body custom-inputcard-body'>
                            <div className='inputcard-group d-flex align-items-center justify-content-start'>
                                <div className='choose-reason inputfield d-flex align-items-center justify-content-between'>
                                    <div className='inputcard-withlabel d-flex align-items-start justify-content-center flex-column '>
                                        <label className='inputcard-label'>
                                            First Name
                                            <small className='ml-2 input-mandatory'>
                                                *
                                            </small>
                                        </label>
                                        <input
                                            id='fatherFirstName'
                                            className='custominputfield'
                                            type='text'
                                            placeholder='Enter First Name'
                                            value={values.firstName}
                                            name='firstName'
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            disabled={isDisable}
                                        />
                                        {errors.firstName &&
                                        touched.firstName ? (
                                            <span className='error-input-msg certificate-error-msg'>
                                                {errors.firstName}
                                            </span>
                                        ) : null}
                                    </div>
                                </div>
                                <div className='choose-reason inputfield d-flex align-items-center justify-content-between'>
                                    <div className='inputcard-withlabel d-flex align-items-start justify-content-center flex-column '>
                                        <label className='inputcard-label'>
                                            Middle Name
                                        </label>
                                        <input
                                            id='fatherMiddleName'
                                            className='custominputfield'
                                            type='text'
                                            placeholder='Enter Middle Name'
                                            value={values.middleName}
                                            name='middleName'
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            disabled={isDisable}
                                        />
                                    </div>
                                </div>
                                <div className='choose-reason inputfield d-flex align-items-center justify-content-between'>
                                    <div className='inputcard-withlabel d-flex align-items-start justify-content-center flex-column '>
                                        <label className='inputcard-label'>
                                            Last Name
                                        </label>
                                        <input
                                            id='fatherLastName'
                                            className='custominputfield'
                                            type='text'
                                            placeholder='Enter Last Name'
                                            value={values.lastName}
                                            name='lastName'
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            disabled={isDisable}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='inputcard-group d-flex align-items-center justify-content-start'>
                                <div className='choose-reason inputfield d-flex align-items-center justify-content-between'>
                                    <div className='inputcard-withlabel d-flex align-items-start justify-content-center flex-column '>
                                        <label className='inputcard-label'>
                                            Birth Country
                                        </label>
                                        <SearchSelectComponent
                                            placeholder='Select Birth Country'
                                            isClearable
                                            className='display-clear-indicator'
                                            options={values.countries}
                                            defaultValue={values.birthCountry}
                                            selectedOption={values.birthCountry}
                                            handleInputChange={e => {
                                                setFieldValue(
                                                    "countries",
                                                    handleSearch(
                                                        e,
                                                        formatOptions(countries)
                                                    )
                                                );
                                            }}
                                            name='birthCountry'
                                            handleChange={e => {
                                                setFieldValue(
                                                    "birthCountry",
                                                    e
                                                );
                                                if (!e) {
                                                    setFieldValue(
                                                        "birthState",
                                                        ""
                                                    );
                                                } else if (
                                                    _.get(
                                                        values.birthState,
                                                        "country.name"
                                                    ) !== e.value
                                                ) {
                                                    setFieldValue(
                                                        "birthState",
                                                        ""
                                                    );
                                                }
                                                setFieldValue(
                                                    "states",
                                                    formatOptions(
                                                        e
                                                            ? filterState(
                                                                  states,
                                                                  e.value
                                                              )
                                                            : states
                                                    )
                                                );
                                            }}
                                            disabled={isDisable}
                                        />
                                    </div>
                                </div>
                                <div className='choose-reason inputfield d-flex align-items-center justify-content-between'>
                                    <div className='inputcard-withlabel d-flex align-items-start justify-content-center flex-column '>
                                        <label className='inputcard-label'>
                                            Birth State
                                        </label>
                                        <SearchSelectComponent
                                            placeholder='Select Birth State'
                                            options={values.states}
                                            isClearable
                                            className='display-clear-indicator'
                                            defaultValue={values.birthState}
                                            selectedOption={values.birthState}
                                            name='birthState'
                                            handleInputChange={e => {
                                                setFieldValue(
                                                    "states",
                                                    handleSearch(
                                                        e,
                                                        formatOptions(states)
                                                    )
                                                );
                                            }}
                                            handleChange={e => {
                                                setFieldValue("birthState", e);
                                                if (e)
                                                    setFieldValue(
                                                        "birthCountry",
                                                        {
                                                            label: e.country
                                                                .name,
                                                            value: e.country
                                                                .name,
                                                            ...e.country,
                                                        }
                                                    );
                                            }}
                                            disabled={isDisable}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='inputcard-footer'>
                            <div
                                id='fatherDetails-save'
                                onPointerEnter={() => showToolTip(true)}
                                onPointerLeave={() => showToolTip(false)}
                            >
                                <button
                                    type='submit'
                                    disabled={isDisable || isSubmitting}
                                    className='btn-theme btn-search-wrapper text-uppercase'
                                >
                                    SAVE
                                </button>
                            </div>
                            <Tooltip
                                placement='top-end'
                                isOpen={tooltipOpen}
                                target='fatherDetails-save'
                                className='tooltip-template'
                            >
                                {certificateFreezeText}
                            </Tooltip>
                        </div>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default FatherDetails;
