import React from "react"
import ReactGA from "react-ga4"

const useGAEventTracker = (category = 'Event Category') => {
  const trackEvent = (action = 'action', label = 'label') => {
    ReactGA.event({ category, action, label }, ['faaAnalyticsTracker']);
  }
  return trackEvent
}

export default useGAEventTracker