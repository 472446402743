import React from "react";
import { Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";

const Disclaimer = ({ isOpen, toggle, onClose, handleAccept }) => {
    return (
        <Modal isOpen={isOpen} toggle={toggle} className="policy-wrapper">
            <ModalHeader>
                <i className="modal-close fas fa-times" onClick={() => onClose()} />
                <span className="modal-headername">
                    {" "}
                    Copyright Disclaimer <i className={"ml-2 fas"} />
                </span>
            </ModalHeader>
            <ModalBody>
                <div className="modal-content">
                    <span>
                        The Funeral Home policy is to NOT reproduce any
                        copyrighted or other proprietary materials without
                        verification from the User that they have the lawful
                        right and/or authority to use. By using our Funeral
                        Arrangement Assistant, you agree and certify to the
                        Funeral Home that you either own all copyright or
                        proprietary rights to the materials you are uploading OR
                        that you have the authority or right to use these
                        materials. If there are any infringement claims for the
                        use of the materials or IF the Funeral Home is asked by
                        a Regulatory Agency to remove any or all of your
                        content, you agree to indemnify, defend, and hold
                        harmless the Funeral Home including all of its
                        directors, officers, employees, assigns,
                        representatives, agents, related entities or parties
                        from all liabilities, fines, claims, lawsuits, losses,
                        judgements, and/or other causes of action. The Funeral
                        Home reserves the right to refuse to use any materials
                        that it may believe to be protected material. The
                        Funeral Home respects the intellectual property of
                        others, and if you believe that your work has been used
                        in a way that constitutes copyright infringement, please
                        contact the Funeral Home immediately.
                    </span>
                </div>
            </ModalBody>
            <ModalFooter>
                <button
                    type="button"
                    className="btn-theme btn-cancel"
                    onClick={() => onClose()}
                >
                    Decline
                </button>
                <button
                    type="button"
                    className="btn-theme"
                    onClick={() => handleAccept()}
                >
                    Accept
                </button>
            </ModalFooter>
        </Modal>
    );
};

export default Disclaimer;
