import _ from "lodash";

export const simplifyTemplate = template => {
    let pages = [];
    if (_.get(template, "type") === "prayer") {
        pages.push({
            type: "image",
            imageURL: _.get(template, "imageURL")
        });
        pages.push({
            type: "page",
            ..._.get(template, "page")
        });
    } else {
        if (_.get(template, "mainPage")) {
            pages.push({
                type: "page",
                ..._.get(template, "mainPage")
            });
        }
        if (_.get(template, "leftPage")) {
            pages.push({
                type: "page",
                ..._.get(template, "leftPage")
            });
        }
        if (_.get(template, "rightPage")) {
            pages.push({
                type: "page",
                ..._.get(template, "rightPage")
            });
        }
        if (_.get(template, "backPage")) {
            let textFields = _.get(template, "backPage.textFields");
            let groupFields = [];

            _.map(_.get(template, "backPage.textFields"), txt => {
                if (_.startsWith(_.get(txt, "type"), "group")) {
                    groupFields.push(txt);
                }
            });
            textFields = _.filter(
                textFields,
                txt => !_.startsWith(_.get(txt, "type"), "group")
            );
            groupFields = _.groupBy(groupFields, "priority");
            _.mapKeys(groupFields, (value, key) => {
                let left, right;
                _.map(value, v => {
                    let i = v.type.substr(v.type.length - 1);
                    i = i % 2
                    if (parseInt(i) === 1) {
                        left = {
                            isEdit: false,
                            ...v
                        };
                    } else {
                        right = {
                            isEdit: false,
                            ...v
                        };
                    }
                });
                textFields.push({
                    type: "group",
                    priority:
                        _.get(left, "priority") || _.get(right, "priority"),
                    left,
                    right
                });
            });
            pages.push({
                type: "page",
                ..._.get(template, "backPage"),
                textFieldGrouped: textFields
            });
        }
    }
    return pages;
};

export const simplifyTextFields = (textFields, group) => {
    let textFieldToUpdate = group || textFields
    let inputs = _.flatten(
        _.map(textFieldToUpdate, txt => {
            if (txt.type === "poem") {
                return [
                    {
                        isEdit: false,
                        type: "poem",
                        subType: "title",
                        ...txt
                    },
                    {
                        isEdit: false,
                        type: "poem",
                        subType: "content",
                        ...txt
                    }
                ];
            } else if (group) {
                return {
                    ...txt,
                    priority: parseInt(_.get(txt, "priority"))
                };
            } else
                return {
                    isEdit: false,
                    ...txt,
                    priority: parseInt(_.get(txt, "priority"))
                };
        })
    );
    return inputs;
};
