import React, { useState, useEffect, useRef } from "react";
import _ from "lodash";
import { Formik, Form } from "formik";
import { Tooltip } from "reactstrap";
import parse from "html-react-parser";

import EditTemplateImage from "./editTemplateImage";
import { simplifyTextFields } from "../../utils/Template/templateUtils";
import Editor from "./editor";
import Template from "./template";
import {
    getString,
    getCustomHeight,
    getCustomSize,
    checkIsHTML,
} from "./templateString";
import {
    getLocationByCode,
    prayerCardFuneralLocations,
} from "../../utils/variables";

import { ReactComponent as Edit } from "../../../assets/images/edit.svg";
import { ReactComponent as SelectIcon } from "../../../assets/images/select.svg";
import CustomImageGrid from "./CustomImageGrid";

const CustomizeTemplate = ({
    page,
    template,
    executeSetTemplate,
    index,
    isLocked,
    isCustom,
    isError,
    role,
    onePortalId,
    setError,
    loadingStatus,
    decedent,
    isPrayerCardEditable,
    handlePreviewImage,
    handleNewTemplate,
    poems,
    allContent,
    setAllContent,
    selectedPoem,
    setSelectedPoem,
    locationCity,
    selectedLocation,
    setLocationCity,
    isCancel,
    previousContent,
    setPreviousContent,
}) => {
    const [inputs, setInputs] = useState([]);
    const locationCode = _.get(decedent, "decedent.locationCode");
    const locationName = getLocationByCode(locationCode);
    const inputRef = useRef(null)
    const [eventName,setEventName] = useState(null)

    const nodeRef = useRef(null);
    const formikRef = useRef(null)
    const textEdiorRef = useRef();

    useEffect(() => {
        const updatedTemplate = { ...template };
        let { page } = updatedTemplate;
        let { textFields } = page;
        let footer = _.find(textFields, { type: "footer" });
        let footerIdx = _.findIndex(textFields, { type: "footer" });
        let style = Template.prayer[_.get(footer, "name")]
        let { size, font, align } = style
        let cityName;
        footer.text = footer.text ? footer.text : locationName;
        let footerDetails = footer.text
        if (!checkIsHTML(footerDetails)) {
          let initialLoc = footer.text;
          cityName = prayerCardFuneralLocations[footer.text];
          footer.text = `<p class=\"footerLocation ql-align-${align} 
            ql-size-${size} 
            ql-font-${font}\">${initialLoc}</p><p class="\cityName ql-align-${align} ql-size-${size} ql-font-${font}\">${cityName}</p>`;
          textFields[footerIdx] = footer;
        } else {
          const htmlString = footerDetails;
          let updatedHtmlString = "";
          // Parse the HTML string
          const parser = new DOMParser();
          const doc = parser.parseFromString(htmlString, "text/html");
          const checkIsFooterLocation = doc.querySelector('.footerLocation')
          const checkIsCityName = doc.querySelector('.cityName');
          // Find all P Tags
          const pElements = doc?.querySelectorAll("p");
          // Loop through each p element
          if (!(checkIsFooterLocation && checkIsCityName)) {
            pElements?.forEach((pElement, index) => {
                if (
                  !pElement?.classList?.contains("footerLocation") && pElement.innerText !== ''
                ) {
                    if(!(pElements?.length > 1 && index === pElements.length - 1 ))
                     pElement?.classList?.add("footerLocation");
                }
                if (index === pElements.length - 1 && !pElement?.classList?.contains("cityName")) {
                  pElement?.classList?.add("cityName");
                }
              }
          //   }
            );
            for (let i = 0; i < doc?.body?.childNodes?.length; i++) {
              updatedHtmlString += doc?.body?.childNodes[i]?.outerHTML;
          }
          }
          if (updatedHtmlString) footer.text = updatedHtmlString;
          textFields[footerIdx] = footer;
        }
        handleNewTemplate(updatedTemplate)
        setLocationCity(cityName);
    }, [decedent]);


    useEffect(() => {
        setTimeout(function(){
            handlePreviewImage()
        }, 500); 
    }, []);

    useEffect(() => {
        handleNewTemplate(template)
        if (_.get(template, "page.textFields")) {
            let inputs = simplifyTextFields(template.page.textFields);
            inputs = _.orderBy(inputs, ["priority"], ["asc"]);
            setInputs(inputs);
        }
    }, []);

    const [openPoemSelect, setPoemSelect] = useState(false);
    const [openFooterSelect, setFooterSelect] = useState(false);

    const handleSaveTemplate = payload => {
        const payloadTemplate = {
            ...template,
            templateId: template.templateId,
            page: {
                ...template.page,
                textFields: payload.textFields,
                poem: payload.poem,
                finalContent: payload?.finalContent,
            },
        };
        handleNewTemplate(payloadTemplate);
    };

    const getHtmlFromString = (oldString) => {
        var parser = new DOMParser();
        var doc = parser.parseFromString(oldString, "text/html");
        return doc;
    }
  
       //Function to count the br tags with content class and footerLocation class
       const getCountOfClass = (className, doc) => {
         let count = 0;
         for (let i = 0; i < doc?.body?.childNodes?.length; i++) {
           const currentNode = doc?.body?.childNodes[i];
           const classList = currentNode?.classList;
           if (_.includes(classList, className)) {
             count = count + 1;
           }
         }
         if (count > 1) return true;
         return false;
       };

       const addClassToTag = (currentNode, doc) => {
         const pervNode = currentNode?.previousSibling;
         const nextNode = currentNode?.nextSibling;
         const classList = pervNode?.classList;
         if (_.includes(classList, "poemTitle")) {
           // Check class name, only if it is br
           // If there is only one br tag after poemTitle with content class then we should not replace that
           // with poemTitle class
           if (
             currentNode?.childNodes[0]?.nodeName === "BR" &&
             !_.includes(currentNode?.classList, "poemTitle") &&
             getCountOfClass("content", doc)
           ) {
             currentNode?.classList?.remove("content");
             currentNode?.classList?.add("poemTitle");
           }
         }
         if (
           _.includes(classList, "content") &&
           !_.includes(nextNode?.classList, "cityName")
         ) {
           //Check class name only if it is br
           if (
             currentNode?.childNodes[0]?.nodeName === "BR" &&
             !_.includes(currentNode?.classList, "content") &&
             getCountOfClass("footerLocation", doc)
           ) {
             currentNode?.classList?.remove("footerLocation");
             currentNode?.classList?.add("content");
           }
         }
         return currentNode;
       };
  
  
     const handleInputChange = (e, input, i) => {
        let { page } = template;
        let { textFields, poem } = page;
        const newDoc = getHtmlFromString(e)
        const findContentEle = newDoc.querySelector(".content");
        const findTitleEle = newDoc.querySelector(".poemTitle");
        const findFooterLocation = newDoc?.querySelector('.footerLocation')
        const findCityName = newDoc?.querySelector('.cityName')
        const oldDoc = getHtmlFromString(allContent);
        const tagList = ['BR', '#text']  
        //Using this flag for checking user deleting entire poem or footer location
        let flag = false;
        //when user tries to delete entire poem delete only  text not html Tag which had class name content
        let updatedHtmlString = oldDoc.documentElement.outerHTML;
        //Get Current Where We editing data 
        if (!findContentEle) {
          flag = true;
          // const contentElement = oldDoc.querySelector(".content");
          const newEle = document.createElement("p");
          newEle.classList.add("ql-align-center");
          newEle.classList.add("content");
          newEle.innerHTML = "<br>";
          const pElements = newDoc.querySelectorAll("p");
          const parentNode = pElements[0]?.parentElement || `<p></p>`;
          if (findFooterLocation) {
            parentNode?.insertBefore(newEle, findFooterLocation);
          } else if (!findFooterLocation && findCityName) {
            parentNode?.insertBefore(newEle, findCityName);
          } else {
            parentNode.appendChild(newEle);
          }
        }
        if (!findTitleEle) {
          flag = true;
          const newEle = document.createElement("p");
          newEle.classList.add("ql-align-center");
          newEle.classList.add("poemTitle");
          newEle.innerHTML = "<br>";
          const pElements = newDoc.querySelectorAll("p");
          const parentNode = pElements[0]?.parentElement || `<p></p>`;
          if (findContentEle) {
            parentNode?.insertBefore(newEle, findContentEle);
          } else {
            parentNode.appendChild(newEle);
          }
        }
        //when user tries to delete entire footer location delete only text not html tag which had class name footerLocation
        if (!findFooterLocation) {
          flag = true;
          const newEle = document.createElement("p");
          newEle.classList.add("ql-align-center");
          newEle.classList.add("footerLocation");
          newEle.innerHTML = "<br>";
          const pElements = newDoc.querySelectorAll("p");
          const parentNode = pElements[0]?.parentElement || `<p></p>`;
          if (findContentEle) {
            findContentEle.insertAdjacentElement("afterend", newEle);
          } else {
            parentNode.appendChild(newEle);
          }
        }
        //when user tries to delete entire city location delete only text not html tag which had class name cityName
        if (!findCityName) {
          flag = true;
          const newEle = document.createElement("p");
          newEle.classList.add("ql-align-center");
          newEle.classList.add("cityName");
          newEle.innerHTML = "<br>";
          const pElements = newDoc.querySelectorAll("p");
          const parentNode = pElements[0]?.parentElement || `<p></p>`;
          if (findFooterLocation) {
            findFooterLocation.insertAdjacentElement("afterend", newEle);
          }
        }
        if (!flag) {
          // setAllContent(e)
          // handleSaveTemplate({ textFields, poem, finalContent:  e });
          let newStr = "";
          for (let i = 0; i < newDoc?.body?.childNodes?.length; i++) {
            const currentNode = addClassToTag(
              newDoc?.body?.childNodes[i],
              newDoc
            );
            newStr += currentNode?.outerHTML;
          }
          updatedHtmlString = newStr;
          setAllContent(updatedHtmlString);
          handleSaveTemplate({
            textFields,
            poem,
            finalContent: updatedHtmlString,
          });
          if (
            inputRef.current &&
            inputRef.current.getEditor().root.innerHTML !== updatedHtmlString
          ) {
            inputRef.current.getEditor().root.innerHTML = updatedHtmlString;
          }
        } else if (flag) {
          let newStr = "";
          for (let i = 0; i < oldDoc?.body?.childNodes?.length; i++) {
            newStr += oldDoc?.body?.childNodes[i]?.outerHTML;
          }
          updatedHtmlString = newStr;
          setAllContent(updatedHtmlString);
          handleSaveTemplate({
            textFields,
            poem,
            finalContent: updatedHtmlString,
          });
          if (
            inputRef.current &&
            inputRef.current.getEditor().root.innerHTML !== updatedHtmlString
          ) {
            inputRef.current.getEditor().root.innerHTML = updatedHtmlString;
          }
        }
        setEventName(null)
    };

    const [triggerUpdate, setTriggerUpdate] = useState(false);

    const fetchAllFieldsHtmlContent = () => {
      let allFieldsHtmlContent = "";
        if (_.get(page, "finalContent")) {
        setAllContent(_.get(page, "finalContent"));
        setPreviousContent(_.get(page, "finalContent"))
       } else {
        inputs?.map((inputField, i) => {
          let value = _.get(inputField, "text");
          let style = _.get(inputField, "name")
            ? Template.prayer[_.get(inputField, "name")]
            : {};
          if (
            _.get(inputField, "type") &&
            _.get(inputField, "subType") === "title"
          ) {
            value = _.get(page, "poem.title");
            style =
              Template.prayer[_.get(inputField, "name")][
                _.get(inputField, "subType")
                ];
             //If already poem title in html format we have to add poemTitle class to that html string
              if (checkIsHTML(value)) {
                const htmlString = value;
                // Parse the HTML string
                const parser = new DOMParser();
                const doc = parser.parseFromString(htmlString, "text/html");
                //find All p tags
                const pElements = doc.querySelectorAll("p");
                const parentNode = pElements[0]?.parentElement;
                const brTagToInsertBefore = document.createElement('p')
                const brTagToInsertAfter = document.createElement('p')
                brTagToInsertBefore.classList.add('ql-align-center')
                brTagToInsertAfter.classList.add('ql-align-center')
                brTagToInsertAfter.classList.add('ql-size-small')
                brTagToInsertAfter.classList.add('poemTitle')
                brTagToInsertBefore.innerHTML = '<br>'
                brTagToInsertAfter.innerHTML= '<br>'
                let isClassAdded = false;
                //Adding poemTitle class to P tag if does not already have that class name
                if (
                  pElements?.length === 1 &&
                  !_.includes(pElements[0]?.classList, "poemTitle")
                ) {
                  pElements[0]?.classList?.add("poemTitle");
                  isClassAdded = true;
                  //We need to insert BR tag before  poem title to maintain space between title and date
                  parentNode?.insertBefore(brTagToInsertBefore, pElements[0]);
                  //We need to insert BR tag  after poem title to maintain space between
                  //poem title and content
                  parentNode?.insertBefore(
                    brTagToInsertAfter,
                    pElements[0]?.nextSibling
                  );
                }
                if (pElements?.length > 1) {
                  pElements?.forEach((pElement, i) => {
                    if (!pElement?.classList?.contains("poemTitle")) {
                      //Adding poemTitle class to each p tag
                      pElement?.classList?.add("poemTitle");
                      // If we already had p ele with br tag then remove that title class to that p ele
                      //To keep this p ele with br while selecting poem  title
                      if (
                        i === 0 &&
                        pElements[0]?.childNodes?.[0]?.nodeName === "BR"
                      ) {
                        pElements[0]?.classList.remove("poemTitle");
                      }
                      //We need to insert BR tag before and after poem title to maintain space between
                      //poem title and date
                      if (
                        i === 0 &&
                        pElements[0]?.childNodes?.[0]?.nodeName !== "BR"
                      ) {
                        parentNode?.insertBefore(
                          brTagToInsertBefore,
                          pElements[0]
                        );
                      }
                      //We need to insert BR tag  after poem title to maintain space between
                      //poem title and content
                      if (
                        i === pElements.length - 1 &&
                        pElements[i]?.childNodes?.[i]?.nodeName !== "BR"
                      ) {
                        parentNode?.insertBefore(
                          brTagToInsertAfter,
                          pElements[i]?.nextSibling
                        );
                      }
                      isClassAdded = true;
                    }
                  });
                }
                //Getting updated html string
                if (isClassAdded) {
                  let newContent = "";
                  for (let i = 0; i < doc?.body?.childNodes.length; i++) {
                    newContent += doc?.body?.childNodes[i]?.outerHTML;
                  }
                  if (newContent) value = newContent;
                }
              }
          }
          if (
            _.get(inputField, "type") &&
            _.get(inputField, "subType") === "content"
          ) {
            value = _.get(page, "poem.content");
            style =
              Template.prayer[_.get(inputField, "name")][
                _.get(inputField, "subType")
                ];
                //If already poem content in html format we have to add content class to that html string
                if (checkIsHTML(value)) {
                  const htmlString = value;
                  // Parse the HTML string
                  const parser = new DOMParser();
                  const doc = parser.parseFromString(htmlString, "text/html");
                  //find parent all p tags
                  const pElements = doc.querySelectorAll("p");
                  let isContentClassAdded = false;
                  const parentNode = pElements[0]?.parentElement;
                  const brTagToInsertAfter = document.createElement('p')
                  brTagToInsertAfter.classList.add('ql-align-center')
                  brTagToInsertAfter.classList.add('content')
                  brTagToInsertAfter.innerHTML = '<br>'
                  const brTagToInsertAfterSecond = document.createElement('p')
                  brTagToInsertAfterSecond.classList.add('ql-align-center')
                  brTagToInsertAfterSecond.classList.add('content') 
                  brTagToInsertAfterSecond.innerHTML= '<br>'
                  //Adding content class to p tag if it already don't have that class
                  if (pElements?.length === 1 && !(_.includes(pElements[0]?.classList, 'content'))) {
                      pElements[0]?.classList?.add("content");
                      //We need to add 2 p tags with br to maintain space between content and footer location
                      parentNode.insertBefore(brTagToInsertAfter, pElements[0].nextSibling)
                      parentNode.insertBefore(brTagToInsertAfterSecond, pElements[0].nextSibling)
                      isContentClassAdded = true;
                  }
                    if (pElements?.length > 1) {
                      const lengthOfPEle = pElements?.length; 
                      pElements?.forEach((pElement, i) => {
                      if (!((pElement?.classList?.contains("content")))) {
                        //Adding content class to each p tag
                        pElement?.classList.add("content");
                        //If last 2 p tags does not contain br then add 2 p tags with br to maintain space between poem content and footer location
                          if (
                            i === lengthOfPEle - 1 &&
                            pElements[lengthOfPEle - 2]?.childNodes?.[0]
                              ?.nodeName !== "BR" &&
                            pElements[lengthOfPEle - 1]?.childNodes?.[0]
                              ?.nodeName !== "BR"
                          ) {
                            parentNode.insertBefore(
                              brTagToInsertAfter,
                              pElements[i].nextSibling
                            );
                            parentNode.insertBefore(
                              brTagToInsertAfterSecond,
                              pElements[i].nextSibling
                            );
                          }
                          //If already last p element had br tag then add one p element with br at the end of poem content to maintain space between content and footer location
                          if (
                            i === lengthOfPEle - 1 &&
                            pElements[lengthOfPEle - 1]?.childNodes?.[0]
                              ?.nodeName === "BR"
                          ) {
                            parentNode.insertBefore(
                              brTagToInsertAfter,
                              pElements[i].nextSibling
                            );
                          }
                        isContentClassAdded = true;
                      }
                    });
                   
                  }
                  //Getting updated html string
                  if (isContentClassAdded) {
                    let newContent = "";
                    for (let i = 0; i < doc?.body?.childNodes.length; i++) {
                       newContent += doc?.body?.childNodes[i]?.outerHTML;
                    }
                    if (newContent) value = newContent;
                  }
                }
          }
          let htmlContent;
          let type = _.get(inputField, "type");
          let text = value;
          if (type === "poem") {
            htmlContent = text;
          } else if (type === "footer") {
            let html = _.get(template, "page.textFields");
            let footerIdx = _.findIndex(html, { type: "footer" });
            if (html && html.length) {
              htmlContent = html[footerIdx] ? html[footerIdx].text : text;
              text = htmlContent;
            }
          } else {
            let html = _.get(template, "page.textFields");
            if (html && html.length) {
              htmlContent = html[i] ? html[i].text : text;
            }
          }
          if (!checkIsHTML(text)) {
            let subType = type === "poem" ? _.get(inputField, "subType") : type;
            style = {
              ...style,
              size: getCustomSize(style, text, subType),
            };
            htmlContent = getString(style, text, subType);
          }

          if (checkIsHTML(text)) {
            style = getCustomHeight(text, style);
          }
          allFieldsHtmlContent = allFieldsHtmlContent + htmlContent;
          setPreviousContent(allFieldsHtmlContent)
          setAllContent(allFieldsHtmlContent);
        });
      }
    };
  
    useEffect(() => {
        fetchAllFieldsHtmlContent();
    }, [inputs])

    useEffect(() => {
        if (isCancel) {
            setAllContent(previousContent)
        }
    }, [isCancel])

    return (
        <>
            {_.get(page, "type") === "image" && isCustom && index === 0 && (
                <CustomImageGrid url={template.imageURL} />
            )}
            {_.get(page, "type") === "image" && !isCustom && (
                <EditTemplateImage
                    index={index}
                    role={role}
                    isCustom={isCustom}
                    source={template.imageURL}
                />
            )}
            {_.get(page, "type") !== "image" && (
                <>
                    {/* <AutoSave
                template={newTemplate}
                isSave={loadingStatus}
                handleUpdate={handleUpdate}
                trigger={triggerUpdate}
                setTrigger={setTriggerUpdate}
            /> */}
                    <div className='template-screen-edit'>
                        <Formik
                            initialValues={{
                                editField: {isEdit: true},
                                text: allContent,
                                key: 0,
                                poem: selectedPoem,
                            }}
                            innerRef={formikRef}
                            enableReinitialize
                        >
                            {({ values, handleChange, setFieldValue }) => {
                                const handleInputEdit = (val) => {
                                    if (!isLocked) {
                                        let { editField } = values;
                                        editField.isEdit = val;
                                        setFieldValue(
                                            "editField",
                                            editField
                                        );
                                    }

                                    if (isError) {
                                        let { editField } = values;
                                        editField.isEdit = !val;
                                        setFieldValue(
                                            "editField",
                                            editField
                                        );
                                    }
                                };

                                const getEditCustomText = values => {
                                    const { editField = {} } = values;
                                    return "Edit";
                                };
                                return (
                                        <Form
                                            className='prayercard-draggable'
                                            style={{ zIndex: 10 }}
                                            id={0}
                                        >
                                            {(_.get(
                                                values,
                                                "editField.isEdit"
                                            ) && isPrayerCardEditable) ? (
                                                <Editor
                                                    value={values.text}
                                                    index={values.key}
                                                    setEdit={() => {
                                                        handleInputEdit(false);
                                                        setTriggerUpdate(true);
                                                    }}
                                                    setErrorLog={setError}
                                                    handleChange={val => {
                                                        handleInputChange(
                                                            val,
                                                            values.editField,
                                                            values.key
                                                        );
                                                        if (
                                                            _.get(
                                                                values,
                                                                "editField.subType"
                                                            ) === "content"
                                                        ) {
                                                            setSelectedPoem({
                                                                    ...selectedPoem,
                                                                    content:val,
                                                                });
                                                        } else if (
                                                            _.get(
                                                                values,
                                                                "editField.subType"
                                                            ) === "title"
                                                        ) {
                                                            setSelectedPoem({
                                                                    ...selectedPoem,
                                                                    title: val,
                                                                });
                                                        }
                                                        setFieldValue(
                                                            "text",
                                                            val
                                                        );
                                                    }}
                                                    
                                                    html={allContent}
                                                    type={_.get(
                                                        values,
                                                        "editField.subType"
                                                    )}
                                                    textEdiorRef={textEdiorRef}
                                                    selectedPoem={selectedPoem}
                                                    selectedLocation={selectedLocation}
                                                    eventName={eventName}
                                                    setEventName={setEventName}
                                                    inputRef={inputRef}
                                                />
                                            ) : (
                                                <div
                                                    className={`template-string-wrapper  ${
                                                        isPrayerCardEditable
                                                            ? "editable-border"
                                                            : ""
                                                    }`}
                                                >
                                                    <figure className='hover-figure'ref={nodeRef} id={0}>
                                                        <div
                                                            className='template-string'
                                                            id='string'
                                                        >
                                                            {parse(allContent)}
                                                        </div>
                                                        {_.get(
                                                            values,
                                                            "editField.type"
                                                        ) === "poem" &&
                                                        _.get(
                                                            values,
                                                            "editField.subType"
                                                        ) !== "content" ? (
                                                            <EditOrSelectOption
                                                                handleAction={
                                                                    handleInputEdit
                                                                }
                                                                index={
                                                                    values.key
                                                                }
                                                                isSelect={true}
                                                                isEdit={true}
                                                                displayText='Select Poem'
                                                                showDropDownIcon
                                                                handleSelect={() => {
                                                                    setPoemSelect(
                                                                        true
                                                                    );
                                                                }}
                                                                isLocked={
                                                                    isLocked
                                                                }
                                                            />
                                                        ) : _.get(
                                                            values,
                                                            "editField.type"
                                                        ) === "footer" ? (
                                                            <>
                                                                
                                                                <EditOrSelectOption
                                                                    handleAction={
                                                                        handleInputEdit
                                                                    }
                                                                    index={
                                                                        values.key
                                                                    }
                                                                    isEdit={true}
                                                                    isSelect={
                                                                        true
                                                                    }
                                                                    displayText='Select Location'
                                                                    handleSelect={() => {
                                                                        setFooterSelect(
                                                                            true
                                                                        );
                                                                    }}
                                                                    isLocked={
                                                                        isLocked
                                                                    }
                                                                />
                                                            </>
                                                        ) : (
                                                            <EditOrSelectOption
                                                                handleAction={
                                                                    handleInputEdit
                                                                }
                                                                index={
                                                                    values.key
                                                                }
                                                                isEdit={true}
                                                                isLocked={
                                                                    isLocked
                                                                }
                                                                editCustomText={getEditCustomText(
                                                                    values
                                                                )}
                                                            />
                                                        )}
                                                    </figure>
                                                </div>
                                            )}
                                        </Form>
                                );
                            }}
                        </Formik>
                    </div>
                </>
            )}
        </>
    );
};

const EditOrSelectOption = ({
    handleAction,
    index,
    isSelect,
    handleSelect,
    isEdit,
    displayText,
    isLocked,
    showDropDownIcon,
    editCustomText,
}) => {
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const [tooltipSelectOpen, setTooltipSelectOpen] = useState(false);
    const toggle = () => setTooltipOpen(!tooltipOpen);
    const toggleSelect = () => setTooltipSelectOpen(!tooltipSelectOpen);
    const getEditText = () => {
        if (editCustomText) {
            return editCustomText;
        }
        return "Edit";
    };

    return (
        <figcaption style={{ alignItems: showDropDownIcon ? "end" : "center" }}>
            <div className='action-wrapper'>
                {isEdit ? (
                    <>
                        <Tooltip
                            placement='top-end'
                            isOpen={tooltipOpen}
                            target={`edit-${index}`}
                            toggle={toggle}
                        >
                            {!isLocked === true
                                ? getEditText()
                                : "This section is now locked. Please contact your arranger for changes."}
                        </Tooltip>

                        <Edit
                            id={`edit-${index}`}
                            onClick={() => {
                                if (!isLocked) handleAction(true);
                            }}
                            className='prayer-edit-icon'
                        />
                    </>
                ) : (
                    ""
                )}
            </div>
        </figcaption>
    );
};

export default CustomizeTemplate;
