import React, { useCallback, useMemo, useState } from "react";
import { Formik } from "formik";
import _ from "lodash";
import * as yup from "yup";
import { Form, Tooltip } from "reactstrap";
import useAsync from "react-use-async-hook";

import * as DecedentAPI from "../../../../api/decedent";
import { useSetLoading } from "../../../utils/hooks/loader";
import addNotification from "../../../utils/notification/notification";

import { genders, prefixes } from "../../../utils/variables";
import { formatOptions } from "../../../utils/functions";
import DatepickerComponent from "../../../utils/datePicker/datePicker";
import SelectComponent from "../../../utils/SelectComponent/SelectComponent";
import SearchSelectComponent from "../../../utils/SearchSelelctComponent/SearchSelelctComponent";
import MaskedPhoneNumber from "../../../utils/MaskedPhoneNumber";
import { getPhoneNumberCountryCode } from "../getCountryCode";
import { getSecondaryPhoneCountryCode } from "../getCountryCode";
import {
    certificateFreezeText,
    firstTimeSaveText,
} from "../constants/constants";

const isInteger = s => {
    if (s === "-") return true;
    var isInteger_re = /^\s*(\+|-)?\d+\s*$/;
    return String(s).search(isInteger_re) != -1;
};

const PrimaryDetails = ({
    decedent,
    maritalStatus,
    states,
    countries,
    refreshDecedent,
    isDisable,
    decedentId,
    showCertificateSavePopup,
    enableSubmitButton,
}) => {
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const isFieldAlreadySaved = _.get(decedent, "isFirstSaved");
    const [isFirstSaved, setIsFirstSaved] = useState(isFieldAlreadySaved);
    const {phoneNumber,countryCode} = decedent?.phoneNumber ? getPhoneNumberCountryCode(decedent.phoneNumber) : {phoneNumber:'',countryCode:''} ;
    const {secondaryPhoneNumber,secondaryCountryCode} = decedent?.secondaryPhoneNumber ?
    getSecondaryPhoneCountryCode(decedent.secondaryPhoneNumber) : {secondaryPhoneNumber:'',secondaryCountryCode:''};

    if (
        typeof isFirstSaved === "undefined" &&
        isFirstSaved !== isFieldAlreadySaved
    ) {
        setIsFirstSaved(isFieldAlreadySaved);
    }
    const showToolTip = disabled => {
        if (isDisable) {
            setTooltipOpen(disabled);
        }
    };
    /**
     * Save Primary Details
     */
    const { loading: updateLoader, execute: executeUpdate } = useAsync({
        autoExecute: false,
        task: useCallback(
            async (
                values,
                decedentId,
                formikBag,
                isFirstSaved,
                showCertificateSavePopup
            ) => {
                let payload = {
                    prefix: values.prefix ? values.prefix.value : "",
                    firstName: values.firstName,
                    middleName: values.middleName,
                    lastName: values.lastName,
                    maidenName: values.maidenName,
                    suffix: values.suffix,
                    aka: values.aka,
                    preferredFirstName: values.preferredFirstName,
                    preferredMiddleName: values.preferredMiddleName,
                    preferredLastName: values.preferredLastName,
                    birthState: values.birthState
                        ? values.birthState.label
                        : "",
                    birthCountry: values.birthCountry
                        ? values.birthCountry.label
                        : "",
                    gender: values.gender ? values.gender.label : "",
                    maritalStatus: values.maritalStatus
                        ? values.maritalStatus.label
                        : "",
                    email: values.email,
                    phoneNumber:`${values.countryCode + values.phoneNumber}`,
                    secondaryPhoneNumber:`${values.secondaryCountryCode + values.secondaryPhoneNumber}`,
                    notes: values.notes,
                };
                if (values.dateOfBirth) {
                    payload["dateOfBirth"] = values.dateOfBirth;
                }
                if (values.ssn) {
                    payload["ssn"] = values.ssn;
                }
                return {
                    res: await DecedentAPI.savePrimaryDetails(
                        decedentId,
                        payload
                    ),
                    formikBag,
                    isFirstSaved,
                    showCertificateSavePopup,
                };
            },
            []
        ),
        dataLoader: useCallback(
            ({ res, formikBag, isFirstSaved, showCertificateSavePopup }) => {
                formikBag.setSubmitting(false);
                refreshDecedent();
                enableSubmitButton();
                if (isFirstSaved) {
                    addNotification({
                        type: "success",
                        title: "Success",
                        message: firstTimeSaveText,
                    });
                } else {
                    showCertificateSavePopup();
                    setIsFirstSaved(true);
                }
                addNotification({
                    type: "success",
                    title: "Success",
                    message: `Primary Details Updated!`,
                });
            },
            []
        ),
        onError: useCallback(error => {
            addNotification({
                type: "danger",
                title: "Error",
                message: _.get(
                    error,
                    "response.data.message",
                    "Oops! Something went wrong"
                ),
            });
        }, []),
    });

    const showLoader = useMemo(() => updateLoader, [updateLoader]);
    useSetLoading(showLoader);

    const handleSSNKeyPress = event => {
        let character = String.fromCharCode(event.which);
        if (!isInteger(character)) {
            return false;
        }
    };

    const handleSSNKeyUp = (event, setFieldValue) => {
        let ssnValue = event.target.value;
        var val = ssnValue.replace(/\D/g, "");
        var newVal = "";
        if (val.length > 4) {
            ssnValue = val;
        }
        if (val.length > 3 && val.length < 6) {
            newVal += val.substr(0, 3) + "-";
            val = val.substr(3);
        }
        if (val.length > 5) {
            newVal += val.substr(0, 3) + "-";
            newVal += val.substr(3, 2) + "-";
            val = val.substr(5);
        }
        newVal += val;
        ssnValue = newVal;
        setFieldValue("ssn", ssnValue);
    };

    const nameRegex = /^[a-zA-Z-'.\s\u00C0-\u017F]*$/;
    const akaRegex = /^[a-zA-Z-'".\s\u00C0-\u017F()]*$/

    const filterState = (options, country) => {
        options = _.filter(options, option => {
            return option.country.name === country;
        });
        return options;
    };

    const handleSearch = (searchString, options) => {
        if (searchString) {
            return _.filter(options, opt =>
                _.includes(
                    _.toLower(_.get(opt, "label")),
                    _.toLower(searchString)
                )
            );
        }
        return options;
    };

    return (
        <div className='primarydetails-wrapper'>
            <div className='cardbox-wrapper'>
                <div className='cardbox-heading'>
                    <h4 className='cardbox-heading-name text-capitalize text-black'>
                        <span className='item-numbering'>1</span>
                        Death Certificate Information about your loved one
                    </h4>
                </div>
                <Formik
                    initialValues={{
                        prefix: _.get(decedent, "prefix")
                            ? {
                                  value: _.get(decedent, "prefix"),
                                  label: _.get(decedent, "prefix"),
                              }
                            : "",
                        firstName: _.get(decedent, "firstName", ""),
                        middleName: _.get(decedent, "middleName") || "",
                        lastName: _.get(decedent, "lastName") || "",
                        suffix: _.get(decedent, "suffix") || "",
                        maidenName: _.get(decedent, "maidenName") || "",
                        aka: _.get(decedent, "aka") || "",
                        preferredFirstName: _.get(decedent, "preferredFirstName", ""),
                        preferredMiddleName: _.get(decedent, "preferredMiddleName", ""),
                        preferredLastName: _.get(decedent, "preferredLastName", ""),
                        dateOfBirth: _.get(decedent, "dateOfBirth")
                            ? new Date(decedent.dateOfBirth)
                            : "",
                        birthState: _.get(
                            decedent,
                            "locationOfBirth.birthState"
                        )
                            ? {
                                  label: _.get(
                                      decedent,
                                      "locationOfBirth.birthState"
                                  ),
                                  value: _.get(
                                      decedent,
                                      "locationOfBirth.birthState"
                                  ),
                                  name: _.get(
                                      decedent,
                                      "locationOfBirth.birthState"
                                  ),
                                  country: {
                                      name: _.get(
                                          decedent,
                                          "locationOfBirth.birthCountry"
                                      ),
                                  },
                              }
                            : "",
                        birthCountry: _.get(
                            decedent,
                            "locationOfBirth.birthCountry"
                        )
                            ? {
                                  label: _.get(
                                      decedent,
                                      "locationOfBirth.birthCountry"
                                  ),
                                  value: _.get(
                                      decedent,
                                      "locationOfBirth.birthCountry"
                                  ),
                                  name: _.get(
                                      decedent,
                                      "locationOfBirth.birthCountry"
                                  ),
                              }
                            : "",
                        gender: _.get(decedent, "gender")
                            ? _.find(genders, {
                                  label: _.get(decedent, "gender"),
                              })
                            : "",
                        maritalStatus: _.get(decedent, "maritalStatus")
                            ? _.find(formatOptions(maritalStatus), {
                                  value: _.get(decedent, "maritalStatus"),
                              })
                            : "",
                        ssn: "",
                        email: _.get(decedent, "email") || "",
                        phoneNumber: phoneNumber,
                        countryCode: countryCode,
                        secondaryPhoneNumber: secondaryPhoneNumber,
                        secondaryCountryCode: secondaryCountryCode,
                        notes: "",
                        states: formatOptions(states),
                        countries: formatOptions(countries),
                        previousSSN: _.get(decedent, "ssn") || "",
                    }}
                    enableReinitialize={true}
                    validationSchema={yup.object().shape({
                        firstName: yup
                            .string()
                            .matches(nameRegex, "Invalid First Name")
                            .required("First Name is required")
                            .max(50, "First Name is too long"),
                        middleName: yup
                            .string()
                            .matches(nameRegex, "Invalid Middle Name")
                            .notRequired()
                            .max(50, "Middle Name is too long"),
                        lastName: yup
                            .string()
                            .matches(nameRegex, "Invalid Last Name")
                            .notRequired()
                            .max(50, "Last Name is too long"),
                        suffix: yup
                            .string()
                            .matches(nameRegex, "Invalid Suffix")
                            .notRequired()
                            .max(4, "Suffix is too long"),
                        maidenName: yup
                            .string()
                            .matches(nameRegex, "Invalid Maiden Name")
                            .notRequired()
                            .max(50, "Maiden Name is too long"),
                        aka: yup
                            .string()
                            .matches(akaRegex, "Invalid AKA")
                            .notRequired()
                            .max(50, "AKA is too long"),
                        preferredFirstName: yup
                            .string()
                            .matches(akaRegex, "Invalid Preferred First Name")
                            .notRequired()
                            .max(50, "Preferred First Name is too long")
                            .nullable(),
                        preferredMiddleName: yup
                            .string()
                            .matches(akaRegex, "Invalid Preferred Middle Name")
                            .notRequired()
                            .max(50, "Preferred Middle Name is too long")
                            .nullable(),
                        preferredLastName: yup
                            .string()
                            .matches(akaRegex, "Invalid Preferred Last Name")
                            .notRequired()
                            .max(50, "Preferred Last Name is too long")
                            .nullable(),
                        email: yup.string().email("Invalid Email").nullable(),
                        phoneNumber: yup
                            .string()
                            .trim()
                            .notRequired()
                            .matches(
                                /^[0-9]+$/,
                                "Please enter valid Phone Number"
                            )
                            .test(
                                "length checker",
                                "Please enter valid Phone Number",
                                val => {
                                    if (val) {
                                        return val.length === 10;
                                    } else {
                                        return !val ? true : false;
                                    }
                                }
                            ),
                        secondaryPhoneNumber: yup
                            .string()
                            .trim()
                            .notRequired()
                            .matches(
                                /^[0-9]+$/,
                                "Please enter valid Phone Number"
                            )
                            .test(
                                "length checker",
                                "Please enter valid Phone Number",
                                val => {
                                    if (val) {
                                        return val.length === 10;
                                    } else {
                                        return !val ? true : false;
                                    }
                                }
                            ),
                        ssn: yup
                            .string()
                            .matches(
                                /^(?!000|666)[0-7][0-9]{2}-(?!00)[0-9]{2}-(?!0000)[0-9]{4}$/,
                                "Invalid SSN"
                            )
                            .notRequired(),
                            
                    })}
                    onSubmit={(values, formikBag) => {
                        executeUpdate(
                            values,
                            decedentId,
                            formikBag,
                            isFirstSaved,
                            showCertificateSavePopup
                        );
                    }}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleSubmit,
                        handleBlur,
                        isSubmitting,
                        setFieldValue,
                        setSubmitting,
                    }) => {
                        const handleChange = e => {
                            const { name, value } = e.target;
                            setFieldValue(name, value);
                            setSubmitting(false);
                        };
                        return (
                            <Form id='primaryForm' onSubmit={handleSubmit}>
                                <div className='inputcard-wrapper cardbox-body'>
                                    <div className='inputcard-body custom-inputcard-body'>
                                        <div className='inputcard-group d-flex align-items-center justify-content-start'>
                                            <div className='choose-reason inputfield d-flex align-items-center justify-content-between'>
                                                <div className='inputcard-withlabel d-flex align-items-start justify-content-center flex-column '>
                                                    <label className='inputcard-label'>
                                                        Title
                                                    </label>
                                                    <SelectComponent
                                                        placeholder='Select title'
                                                        options={prefixes}
                                                        defaultValue={
                                                            values.prefix
                                                        }
                                                        selectedOption={
                                                            values.prefix
                                                        }
                                                        name='prefix'
                                                        handleChange={e => {
                                                            setFieldValue(
                                                                "prefix",
                                                                e
                                                            );
                                                            setSubmitting(
                                                                false
                                                            );
                                                        }}
                                                        disabled={isDisable}
                                                    />
                                                </div>
                                            </div>
                                            <div className='choose-reason inputfield d-flex align-items-center justify-content-between'>
                                                <div className='inputcard-withlabel d-flex align-items-start justify-content-center flex-column'>
                                                    <label className='inputcard-label'>
                                                        First Name
                                                        <small className='ml-2 input-mandatory'>
                                                            *
                                                        </small>
                                                    </label>
                                                    <input
                                                        id='firstName'
                                                        className='custominputfield'
                                                        type='text'
                                                        placeholder='Enter first name'
                                                        value={values.firstName}
                                                        name='firstName'
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        disabled={isDisable}
                                                    />
                                                    {errors.firstName &&
                                                    touched.firstName ? (
                                                        <span className='error-input-msg certificate-error-msg'>
                                                            {errors.firstName}
                                                        </span>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className='choose-reason inputfield d-flex align-items-center justify-content-between'>
                                                <div className='inputcard-withlabel d-flex align-items-start justify-content-center flex-column'>
                                                    <label className='inputcard-label'>
                                                        Middle Name
                                                    </label>
                                                    <input
                                                        id='middleName'
                                                        className='custominputfield'
                                                        type='text'
                                                        placeholder='Enter middle name'
                                                        value={
                                                            values.middleName
                                                        }
                                                        name='middleName'
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        disabled={isDisable}
                                                    />
                                                    {errors.middleName &&
                                                    touched.middleName ? (
                                                        <span className='error-input-msg certificate-error-msg'>
                                                            {errors.middleName}
                                                        </span>
                                                    ) : null}
                                                </div>
                                            </div>
                                        </div>
                                        <div className='inputcard-group d-flex align-items-center justify-content-start'>
                                            <div className='choose-reason inputfield d-flex align-items-center justify-content-between'>
                                                <div className='inputcard-withlabel d-flex align-items-start justify-content-center flex-column'>
                                                    <label className='inputcard-label'>
                                                        Last Name
                                                    </label>
                                                    <input
                                                        id='lastName'
                                                        className='custominputfield'
                                                        type='text'
                                                        placeholder='Enter last name'
                                                        value={values.lastName}
                                                        name='lastName'
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        disabled={isDisable}
                                                    />
                                                    {errors.lastName &&
                                                    touched.lastName ? (
                                                        <span className='error-input-msg certificate-error-msg'>
                                                            {errors.lastName}
                                                        </span>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className='choose-reason inputfield d-flex align-items-center justify-content-between'>
                                                <div className='inputcard-withlabel d-flex align-items-start justify-content-center flex-column'>
                                                    <label className='inputcard-label'>
                                                        Suffix
                                                    </label>
                                                    <input
                                                        id='suffix'
                                                        className='custominputfield'
                                                        type='text'
                                                        placeholder='Enter Suffix'
                                                        value={values.suffix}
                                                        name='suffix'
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        disabled={isDisable}
                                                    />
                                                    {errors.suffix &&
                                                    touched.suffix ? (
                                                        <span className='error-input-msg certificate-error-msg'>
                                                            {errors.suffix}
                                                        </span>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className='choose-reason inputfield d-flex align-items-center justify-content-between'>
                                                <div className='inputcard-withlabel d-flex align-items-start justify-content-center flex-column'>
                                                    <label className='inputcard-label'>
                                                        Also Known as (A.K.A)
                                                    </label>
                                                    <input
                                                        id='akaName'
                                                        className='custominputfield'
                                                        type='text'
                                                        placeholder='As Known As'
                                                        value={values.aka}
                                                        name='aka'
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        disabled={isDisable}
                                                    />
                                                    {errors.aka &&
                                                    touched.aka ? (
                                                        <span className='error-input-msg certificate-error-msg'>
                                                            {errors.aka}
                                                        </span>
                                                    ) : null}
                                                </div>
                                            </div>
                                        </div>
                                        <div className='inputcard-group d-flex align-items-center justify-content-start'>
                                            <div className='choose-reason inputfield d-flex align-items-center justify-content-between'>
                                                <div className='inputcard-withlabel d-flex align-items-start justify-content-center flex-column'>
                                                    <label className='inputcard-label'>
                                                        Preferred First Name
                                                    </label>
                                                    <input
                                                        id='preferredFirstName'
                                                        className='custominputfield'
                                                        type='text'
                                                        placeholder='Enter Preferred First Name'
                                                        value={values.preferredFirstName}
                                                        name='preferredFirstName'
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        disabled={isDisable}
                                                    />
                                                    {errors.preferredFirstName &&
                                                    touched.preferredFirstName ? (
                                                        <span className='error-input-msg certificate-error-msg'>
                                                            {errors.preferredFirstName}
                                                        </span>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className='choose-reason inputfield d-flex align-items-center justify-content-between'>
                                                <div className='inputcard-withlabel d-flex align-items-start justify-content-center flex-column'>
                                                    <label className='inputcard-label'>
                                                        Preferred Middle Name
                                                    </label>
                                                    <input
                                                        id='preferredMiddleName'
                                                        className='custominputfield'
                                                        type='text'
                                                        placeholder='Enter preferred Middle Name'
                                                        value={values.preferredMiddleName}
                                                        name='preferredMiddleName'
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        disabled={isDisable}
                                                    />
                                                    {errors.preferredMiddleName &&
                                                    touched.preferredMiddleName ? (
                                                        <span className='error-input-msg certificate-error-msg'>
                                                            {errors.preferredMiddleName}
                                                        </span>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className='choose-reason inputfield d-flex align-items-center justify-content-between'>
                                                <div className='inputcard-withlabel d-flex align-items-start justify-content-center flex-column'>
                                                    <label className='inputcard-label'>
                                                        Preferred Last Name
                                                    </label>
                                                    <input
                                                        id='preferredLastName'
                                                        className='custominputfield'
                                                        type='text'
                                                        placeholder='Enter preferred Last Name'
                                                        value={values.preferredLastName}
                                                        name='preferredLastName'
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        disabled={isDisable}
                                                    />
                                                    {errors.preferredLastName &&
                                                    touched.preferredLastName ? (
                                                        <span className='error-input-msg certificate-error-msg'>
                                                            {errors.preferredLastName}
                                                        </span>
                                                    ) : null}
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div className='inputcard-group d-flex align-items-center justify-content-start'>
                                            <div className='choose-reason inputfield d-flex align-items-center justify-content-between'>
                                                <div className='inputcard-withlabel d-flex align-items-start justify-content-center flex-column'>
                                                    <label className='inputcard-label'>
                                                        Date of Birth
                                                    </label>
                                                    <div className='daterange-container w-100'>
                                                        <DatepickerComponent
                                                            placeholderText='Select Date of Birth'
                                                            selectedDate={
                                                                values.dateOfBirth
                                                            }
                                                            onChange={e => {
                                                                setFieldValue(
                                                                    "dateOfBirth",
                                                                    e
                                                                );
                                                                setSubmitting(
                                                                    false
                                                                );
                                                            }}
                                                            disabled={isDisable}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='choose-reason inputfield d-flex align-items-center justify-content-between'>
                                                <div className='inputcard-withlabel d-flex align-items-start justify-content-center flex-column'>
                                                    <label className='inputcard-label'>
                                                        Birth State
                                                    </label>
                                                    <SearchSelectComponent
                                                        placeholder='Enter/Select Birth State'
                                                        isClearable
                                                        className='display-clear-indicator'
                                                        options={values.states}
                                                        handleInputChange={e => {
                                                            setFieldValue(
                                                                "states",
                                                                handleSearch(
                                                                    e,
                                                                    formatOptions(
                                                                        states
                                                                    )
                                                                )
                                                            );
                                                        }}
                                                        selectedOption={
                                                            values.birthState
                                                        }
                                                        name='birthState'
                                                        handleChange={e => {
                                                            setFieldValue(
                                                                "birthState",
                                                                e
                                                            );
                                                            if (e)
                                                                setFieldValue(
                                                                    "birthCountry",
                                                                    {
                                                                        label: e
                                                                            .country
                                                                            .name,
                                                                        value: e
                                                                            .country
                                                                            .name,
                                                                        ...e.country,
                                                                    }
                                                                );
                                                            setSubmitting(
                                                                false
                                                            );
                                                        }}
                                                        disabled={isDisable}
                                                    />
                                                </div>
                                            </div>
                                            <div className='choose-reason inputfield d-flex align-items-center justify-content-between'>
                                                <div className='inputcard-withlabel d-flex align-items-start justify-content-center flex-column'>
                                                    <label className='inputcard-label'>
                                                        Birth Country
                                                    </label>
                                                    <SearchSelectComponent
                                                        isClearable
                                                        className='display-clear-indicator'
                                                        placeholder='Enter/Select Birth Country'
                                                        options={
                                                            values.countries
                                                        }
                                                        handleInputChange={e => {
                                                            setFieldValue(
                                                                "countries",
                                                                handleSearch(
                                                                    e,
                                                                    formatOptions(
                                                                        countries
                                                                    )
                                                                )
                                                            );
                                                        }}
                                                        defaultValue={
                                                            values.birthCountry
                                                        }
                                                        selectedOption={
                                                            values.birthCountry
                                                        }
                                                        name='birthCountry'
                                                        handleChange={e => {
                                                            setFieldValue(
                                                                "birthCountry",
                                                                e
                                                            );
                                                            if (!e) {
                                                                setFieldValue(
                                                                    "birthState",
                                                                    ""
                                                                );
                                                            } else if (
                                                                _.get(
                                                                    values.birthState,
                                                                    "country.name"
                                                                ) !== e.value
                                                            ) {
                                                                setFieldValue(
                                                                    "birthState",
                                                                    ""
                                                                );
                                                            }
                                                            setFieldValue(
                                                                "states",
                                                                formatOptions(
                                                                    e
                                                                        ? filterState(
                                                                              states,
                                                                              e.value
                                                                          )
                                                                        : states
                                                                )
                                                            );
                                                            setSubmitting(
                                                                false
                                                            );
                                                        }}
                                                        disabled={isDisable}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='inputcard-group d-flex align-items-center justify-content-start'>
                                            <div className='choose-reason inputfield d-flex align-items-center justify-content-between'>
                                                <div className='inputcard-withlabel d-flex align-items-start justify-content-center flex-column'>
                                                    <label className='inputcard-label'>
                                                        Gender
                                                    </label>
                                                    <SelectComponent
                                                        placeholder='Select Gender'
                                                        options={genders}
                                                        defaultValue={
                                                            values.gender
                                                        }
                                                        selectedOption={
                                                            values.gender
                                                        }
                                                        name='gender'
                                                        handleChange={e => {
                                                            setFieldValue(
                                                                "gender",
                                                                e
                                                            );
                                                            setFieldValue(
                                                                "maidenName",
                                                                ""
                                                            );
                                                            setSubmitting(
                                                                false
                                                            );
                                                        }}
                                                        disabled={isDisable}
                                                    />
                                                </div>
                                            </div>
                                            <div className='choose-reason inputfield d-flex align-items-center justify-content-between'>
                                                <div className='inputcard-withlabel d-flex align-items-start justify-content-center flex-column'>
                                                    <label className='inputcard-label'>
                                                        Marital Status
                                                    </label>
                                                    <SelectComponent
                                                        placeholder='Select Marital Status'
                                                        options={formatOptions(
                                                            maritalStatus
                                                        )}
                                                        defaultValue={
                                                            values.maritalStatus
                                                        }
                                                        selectedOption={
                                                            values.maritalStatus
                                                        }
                                                        name='maritalStatus'
                                                        handleChange={e => {
                                                            setFieldValue(
                                                                "maritalStatus",
                                                                e
                                                            );
                                                            setSubmitting(
                                                                false
                                                            );
                                                        }}
                                                        disabled={isDisable}
                                                    />
                                                </div>
                                            </div>
                                            <div className='choose-reason inputfield d-flex align-items-center justify-content-between'>
                                                <div className='inputcard-withlabel d-flex align-items-start justify-content-center flex-column'>
                                                    <label className='inputcard-label'>
                                                        SSN Number
                                                    </label>
                                                    <input
                                                        id='ssn'
                                                        className='custominputfield'
                                                        type='text'
                                                        placeholder={
                                                            values.previousSSN
                                                                ? `XXX-XX-${values.previousSSN}`
                                                                : "Enter SSN XXX-XX-XXXX"
                                                        }
                                                        value={values.ssn}
                                                        name='ssn'
                                                        onChange={e => {
                                                            setFieldValue(
                                                                "ssn",
                                                                e.target.value
                                                            );
                                                            setSubmitting(
                                                                false
                                                            );
                                                        }}
                                                        onKeyPress={
                                                            handleSSNKeyPress
                                                        }
                                                        onKeyUp={e => {
                                                            handleSSNKeyUp(
                                                                e,
                                                                setFieldValue
                                                            );
                                                            setSubmitting(
                                                                false
                                                            );
                                                        }}
                                                        maxLength='11'
                                                        disabled={isDisable}
                                                    />
                                                    {errors.ssn &&
                                                    touched.ssn ? (
                                                        <span className='error-input-msg certificate-error-msg'>
                                                            {errors.ssn}
                                                        </span>
                                                    ) : null}
                                                </div>
                                            </div>
                                        </div>
                                        <div className='inputcard-group d-flex align-items-center justify-content-start'>
                                            <div className='choose-reason inputfield d-flex align-items-center justify-content-between'>
                                                <div className='inputcard-withlabel d-flex align-items-start justify-content-center flex-column'>
                                                    <label className='inputcard-label'>
                                                        Email ID
                                                    </label>
                                                    <input
                                                        id='emailID'
                                                        className='custominputfield'
                                                        type='email'
                                                        placeholder='Enter Email'
                                                        value={values.email}
                                                        name='email'
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        disabled={isDisable}
                                                    />
                                                    {errors.email &&
                                                    touched.email ? (
                                                        <span className='error-input-msg certificate-error-msg'>
                                                            {errors.email}
                                                        </span>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className='choose-reason inputfield d-flex align-items-center justify-content-between'>
                                                <div className='inputcard-withlabel d-flex align-items-start justify-content-center flex-column'>
                                                    <label className='inputcard-label'>
                                                        Primary Contact Number
                                                    </label>
                                                    {/* <input
                                                        id="primaryContact"
                                                        className="custominputfield"
                                                        type="text"
                                                        placeholder="Enter Phonenumber"
                                                        value={
                                                            values.phoneNumber
                                                        }
                                                        name="phoneNumber"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        disabled={isDisable}
                                                        maxLength={10}
                                                    /> */}
                                                    {values.countryCode  ?
                                                        (<div style={{display:'flex'}}>
                                                            <div className='choose-reason country-code-input inputfield d-flex align-items-center justify-content-between'>
                                                                <div className='inputcard-withlabel d-flex align-items-start justify-content-center flex-column'>
                                                                    <input
                                                                        className="countryCode"
                                                                        value={values.countryCode}
                                                                        type="text"
                                                                        name="countryCode"
                                                                        disabled
                                                                    >
                                                                    </input>
                                                                </div>
                                                            </div>
                                                            <MaskedPhoneNumber
                                                                className='custominputfield'
                                                                type='text'
                                                                name='phoneNumber'
                                                                label='Phone Number'
                                                                formInput
                                                                placeholder='Enter Phonenumber'
                                                                value={
                                                                     values.phoneNumber
                                                                    }
                                                                onBlur={handleBlur}
                                                                onChange={value => {
                                                                    setFieldValue(
                                                                         "phoneNumber",
                                                                           value
                                                                        );
                                                                    }}
                                                                disabled={isDisable}
                                                            />
                                                            {errors.phoneNumber &&
                                                                touched.phoneNumber ? (
                                                                <span className='error-input-msg certificate-error-msg'>
                                                                    {errors.phoneNumber}
                                                                </span>
                                                                ) : null}
                                                        </div>) 
                                                    :(
                                                        <>
                                                            <MaskedPhoneNumber
                                                                className='custominputfield'
                                                                type='text'
                                                                name='phoneNumber'
                                                                label='Phone Number'
                                                                formInput
                                                                placeholder='Enter Phonenumber'
                                                                value={
                                                                        values.phoneNumber
                                                                    }
                                                                onBlur={handleBlur}
                                                                        onChange={value => {
                                                                            setFieldValue(
                                                                            "phoneNumber",
                                                                                value
                                                                                );
                                                                            }}
                                                                disabled={isDisable}
                                                                />
                                                                {errors.phoneNumber &&
                                                                    touched.phoneNumber ? (
                                                                        <span className='error-input-msg certificate-error-msg'>
                                                                            {errors.phoneNumber}
                                                                        </span>
                                                                        ) : null}
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                            <div className='choose-reason inputfield d-flex align-items-center justify-content-between'>
                                                <div className='inputcard-withlabel d-flex align-items-start justify-content-center flex-column'>
                                                    <label className='inputcard-label'>
                                                        Secondary Contact Number
                                                    </label>
                                                    {/* <input
                                                        id="secondaryContact"
                                                        className="custominputfield"
                                                        type="text"
                                                        placeholder="Enter Secondary Phonenumber"
                                                        value={
                                                            values.secondaryPhoneNumber
                                                        }
                                                        name="secondaryPhoneNumber"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        disabled={isDisable}
                                                        maxLength={10}
                                                    /> */}
                                                    {values.secondaryCountryCode  ?
                                                    (
                                                        <div style={{display : 'flex'}}>
                                                               <div className='choose-reason  country-code-input inputfield d-flex align-items-center justify-content-between'>
                                                                   <div className='inputcard-withlabel d-flex align-items-start justify-content-center flex-column'>
                                                                       <input
                                                                           className="countryCode"
                                                                           value={values.secondaryCountryCode}
                                                                           type="text"
                                                                           name="secondaryCountryCode"
                                                                           disabled
                                                                       >
                                                                       </input>
                                                                   </div>
                                                               </div>
                                                           <MaskedPhoneNumber
                                                               className='custominputfield'
                                                               type='text'
                                                               name='phoneNumber'
                                                               label='Phone Number'
                                                               formInput
                                                               placeholder='Enter Secondary Phonenumber'
                                                               value={
                                                                   values.secondaryPhoneNumber
                                                               }
                                                               onBlur={handleBlur}
                                                               onChange={value => {
                                                                   setFieldValue(
                                                                       "secondaryPhoneNumber",
                                                                       value
                                                                   );
                                                               }}
                                                               disabled={isDisable}
                                                           />
                                                           {errors.secondaryPhoneNumber &&
                                                           touched.secondaryPhoneNumber ? (
                                                               <span className='error-input-msg certificate-error-msg'>
                                                                   {
                                                                       errors.secondaryPhoneNumber
                                                                   }
                                                               </span>
                                                           ) : null}
                                                       </div>)
                                                   :
                                                   (<>
                                                        <MaskedPhoneNumber
                                                            className='custominputfield'
                                                            type='text'
                                                            name='phoneNumber'
                                                            label='Phone Number'
                                                            formInput
                                                            placeholder='Enter Secondary Phonenumber'
                                                            value={
                                                                values.secondaryPhoneNumber
                                                            }
                                                            onBlur={handleBlur}
                                                            onChange={value => {
                                                                setFieldValue(
                                                                    "secondaryPhoneNumber",
                                                                    value
                                                                );
                                                            }}
                                                            disabled={isDisable}
                                                        />
                                                        {errors.secondaryPhoneNumber &&
                                                        touched.secondaryPhoneNumber ? (
                                                            <span className='error-input-msg certificate-error-msg'>
                                                                {
                                                                    errors.secondaryPhoneNumber
                                                                }
                                                            </span>
                                                        ) : null}
                                                    </>
                                                   )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className='inputcard-group d-flex align-items-center justify-content-start'>
                                            <div className='choose-reason inputfield d-flex align-items-center justify-content-between'>
                                                <div className='inputcard-withlabel d-flex align-items-start justify-content-center flex-column'>
                                                    <label className='inputcard-label'>
                                                        Maiden Name
                                                    </label>
                                                    <input
                                                        id='maidenName'
                                                        className='custominputfield'
                                                        type='text'
                                                        placeholder='Enter maiden name'
                                                        value={
                                                            values.maidenName
                                                        }
                                                        name='maidenName'
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        disabled={
                                                            isDisable ||
                                                            _.get(
                                                                values,
                                                                "gender.label"
                                                            ) !== "Female"
                                                        }
                                                    />
                                                    {errors.maidenName &&
                                                    touched.maidenName ? (
                                                        <span className='error-input-msg certificate-error-msg'>
                                                            {errors.maidenName}
                                                        </span>
                                                    ) : null}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='inputcard-footer'>
                                        <div
                                            id='primaryForm-save'
                                            onPointerEnter={() =>
                                                showToolTip(true)
                                            }
                                            onPointerLeave={() =>
                                                showToolTip(false)
                                            }
                                        >
                                            <button
                                                type='submit'
                                                disabled={
                                                    isDisable || isSubmitting
                                                }
                                                className='btn-theme btn-search-wrapper text-uppercase'
                                            >
                                                SAVE
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <Tooltip
                                    placement='top-end'
                                    isOpen={tooltipOpen}
                                    target='primaryForm-save'
                                    className='tooltip-template'
                                >
                                    {certificateFreezeText}
                                </Tooltip>
                            </Form>
                        );
                    }}
                </Formik>
            </div>
        </div>
    );
};

export default PrimaryDetails;
