import React from "react";
import Select from "react-select";

class SelectComponent extends React.Component {
    render() {
        return (
            <div className="select-wrapper">
                <Select
                    placeholder={this.props.placeholder}
                    value={this.props.selectedOption}
                    defaultValue={this.props.selectedOption}
                    onChange={e => {
                        if (this.props.handleChange) {
                            this.props.name
                                ? this.props.handleChange(e, this.props.name)
                                : this.props.handleChange(e);
                        }
                    }}
                    onBlur={this.props.onBlur}
                    isDisabled={this.props.disabled}
                    options={this.props.options}
                    isSearchable={false}
                    isClearable={this.props.isClearable || false}
                    classNamePrefix={
                        this.props.className
                            ? this.props.className
                            : "select-dropdown"
                    }
                    id={this.props.id}
                />
            </div>
        );
    }
}
export default SelectComponent;
