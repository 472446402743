module.exports = {
    css: `
        html {
          margin: 0 !important;
          padding: 0 !important;
        }
  
        body {
          margin: 0 !important;
          padding: 0 !important;
        }
        @media print {
          html, body {
              border: 0.1px solid white;
              height: 99%;
              page-break-after: avoid;
              page-break-before: avoid;
          }
          @page {
            size:landscape letter;
          }
      }
  
        #print {
          width: 100%;
          height: 100%
          display: block !important;
          margin: 0 !important;
          padding: 0 !important;
        }
        
        #print .cards {
          display: flex !important;
          flex-wrap: wrap;
          width: 100% !important;
          max-width: 100% !important;
          height: fit-content !important;
          margin: 0 !important;
          padding: 0 !important;
        }
        
        .extra {
          width: 9% !important;
          height: 100% !important;
        }
        
        #print .cards .items {
          display: flex !important;
          flex-wrap: wrap;
          width: 90.91% !important;
          height: 100% !important;
        }
        
        #print .cards .card-item-container {
          width: 25% !important;
          padding: 0px;
          height: 50% !important;
          display: flex !important;
          align-items: center !important;
          justify-content: center !important;
          margin-top : 18px !important; 
          margin-bottom: 18px !important;
        }
        
        #print .cards .card-item-container img {
          max-width: 100% !important;
          height: auto !important;
        }
        
        .back img {
          transform: rotate(180deg) !important;
        }
        
        #print .cards .card-item-container canvas {
          margin: 0 auto !important;
        }
        
        #print .cards .card-item-container .annotationLayer {
          display: none !important;
        }
        
        .template-img-container {
          display: flex !important;
        }
        
        #print .image {
          width: 91% !important;
        }
        
        #print .image img {
          max-width: 100% !important;
          height: 100% !important;
          min-height: 100% !important;
          width: 100% !important;
        }
        
        #print .page-break {
          display: block;
          page-break-before: always;
        }
        
        * {
          box-sizing: border-box !important;
        }
        
        @media print {
          @page {
            margin: 0 !important;
          }
        }
        
        .print-page {
          display: flex !important;
          width: 100% !important;
        }
        
        .print-image {
          width: 50% !important;
          display: flex !important;
          align-items: center !important;
          justify-content: center !important;
        }
        
        .print-image img {
          max-width: 100% !important;
          height: auto !important;
        }`
  };
  