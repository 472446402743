import React, { useCallback, useMemo, useState } from "react";
import _ from "lodash";
import * as yup from "yup";
import { Formik, Field } from "formik";
import { Form, Tooltip } from "reactstrap";
import useAsync from "react-use-async-hook";

import * as DecedentAPI from "../../../../api/decedent";
import { useSetLoading } from "../../../utils/hooks/loader";
import addNotification from "../../../utils/notification/notification";
import { relations } from "../../../utils/variables";
import { formatOptions } from "../../../utils/functions";
import CommonAddress from "../../../utils/commonAddress/commonAddress";
import SelectComponent from "../../../utils/SelectComponent/SelectComponent";
import MaskedPhoneNumber from "../../../utils/MaskedPhoneNumber";
import { getPhoneNumberCountryCode } from "../getCountryCode";
import { getSecondaryPhoneCountryCode } from "../getCountryCode";
import {
    certificateFreezeText,
    firstTimeSaveText,
} from "../constants/constants";

const NotifierDetails = ({
    decedent,
    locationData,
    isDisable,
    decedentId,
    refreshDecedent,
    showCertificateSavePopup,
    enableSubmitButton,
}) => {
    /**
     * Save Notifier Details
     */
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const showToolTip = disabled => {
        if (isDisable) {
            setTooltipOpen(disabled);
        }
    };
    const isFieldAlreadySaved = _.get(decedent, "isFirstSaved");
    const [isFirstSaved, setIsFirstSaved] = useState(isFieldAlreadySaved);
    const {phoneNumber,countryCode} = decedent?.phoneNumber ? getPhoneNumberCountryCode(decedent.phoneNumber) :  {phoneNumber:'',countryCode:''};
    const {secondaryPhoneNumber,secondaryCountryCode} = decedent?.secondaryPhoneNumber ?
    getSecondaryPhoneCountryCode(decedent.secondaryPhoneNumber) : {secondaryPhoneNumber:'',secondaryCountryCode:''};
    if (
        typeof isFirstSaved === "undefined" &&
        isFirstSaved !== isFieldAlreadySaved
    ) {
        setIsFirstSaved(isFieldAlreadySaved);
    }
    const { loading: updateLoader, execute: executeUpdate } = useAsync({
        autoExecute: false,
        task: useCallback(
            async (
                values,
                decedentId,
                formikBag,
                isFirstSaved,
                showCertificateSavePopup
            ) => {
                let payload = {
                    prefix: values.prefix ? values.prefix.value : "",
                    firstName: values.firstName,
                    middleName: values.middleName,
                    lastName: values.lastName,
                    email: values.email,
                    phoneNumber:`${values.countryCode + values.phoneNumber}`,
                    secondaryPhoneNumber:`${values.secondaryCountryCode + values.secondaryPhoneNumber}`,
                    relation: values.relation ? values.relation.label : "",
                    address: {
                        line1: values.line1,
                        line2: values.line2,
                        country: !values.country ? "" : values.country.value,
                        state: !values.state ? "" : values.state.value,
                        county: !values.county ? "" : values.county.value,
                        city: !values.city ? "" : values.city.value,
                        zipcode: !values.zipcode ? "" : values.zipcode.value,
                    },
                };
                return {
                    res: await DecedentAPI.saveNotifierDetails(
                        decedentId,
                        payload
                    ),
                    formikBag,
                    isFirstSaved,
                    showCertificateSavePopup,
                };
            },
            []
        ),
        dataLoader: useCallback(
            ({ res, formikBag, isFirstSaved, showCertificateSavePopup }) => {
                formikBag.setSubmitting(false);
                refreshDecedent();
                enableSubmitButton();
                if (isFirstSaved) {
                    addNotification({
                        type: "success",
                        title: "Success",
                        message: firstTimeSaveText,
                    });
                } else {
                    showCertificateSavePopup();
                    setIsFirstSaved(true);
                }
                addNotification({
                    type: "success",
                    title: "Success",
                    message: "Notifier Details Updated!",
                });
            },
            []
        ),
        onError: useCallback(error => {
            addNotification({
                type: "danger",
                title: "Error",
                message: _.get(
                    error,
                    "response.data.message",
                    "Oops! Something went wrong"
                ),
            });
        }, []),
    });

    const showLoader = useMemo(() => updateLoader, [updateLoader]);
    useSetLoading(showLoader);

    const phoneRegExp = /^[0-9]+$/;

    return (
        <div className='notifierdetails-wrapper'>
            <div className='cardbox-wrapper'>
                <div className='cardbox-heading'>
                    <h4 className='cardbox-heading-name text-capitalize text-black'>
                        <span className='item-numbering'>7</span>
                        Notifier Details
                    </h4>
                </div>
                <Formik
                    initialValues={{
                        prefix: _.get(decedent, "prefix")
                            ? {
                                  value: _.get(decedent, "prefix"),
                                  label: _.get(decedent, "prefix"),
                              }
                            : "",
                        firstName: _.get(decedent, "firstName") || "",
                        middleName: _.get(decedent, "middleName") || "",
                        lastName: _.get(decedent, "lastName") || "",
                        phoneNumber: phoneNumber,
                        countryCode: countryCode,
                        secondaryPhoneNumber: secondaryPhoneNumber,
                        secondaryCountryCode: secondaryCountryCode,
                        relation: _.get(decedent, "relationship")
                            ? {
                                  value: decedent.relationship,
                                  label: decedent.relationship,
                                  ..._.find(relations, {
                                      name: decedent.relationship,
                                  }),
                              }
                            : "",
                        email: _.get(decedent, "email") || "",
                        line1: _.get(decedent, "address.line1") || "",
                        line2: _.get(decedent, "address.line2") || "",
                        city:
                            _.get(decedent, "address.city") &&
                            _.get(locationData, "cities")
                                ? _.find(
                                      formatOptions(
                                          locationData.cities,
                                          "name",
                                          "code"
                                      ),
                                      {
                                          value: _.get(
                                              decedent,
                                              "address.city"
                                          ),
                                      }
                                  ) || {
                                      label: _.get(decedent, "address.city"),
                                      name: _.get(decedent, "address.city"),
                                      value: _.get(decedent, "address.city"),
                                      state: {
                                          name: _.get(
                                              decedent,
                                              "address.state"
                                          ),
                                      },
                                  }
                                : "",
                        country:
                            _.get(decedent, "address.country") &&
                            _.get(locationData, "countries")
                                ? _.find(
                                      formatOptions(locationData.countries),
                                      {
                                          value: _.get(
                                              decedent,
                                              "address.country"
                                          ),
                                      }
                                  )
                                : "",
                        county:
                            _.get(decedent, "address.county") &&
                            _.get(locationData, "counties")
                                ? _.find(
                                      formatOptions(
                                          locationData.counties,
                                          "description",
                                          "description"
                                      ),
                                      {
                                          value: _.get(
                                              decedent,
                                              "address.county"
                                          ),
                                      }
                                  )
                                : "",
                        state:
                            _.get(decedent, "address.state") &&
                            _.get(locationData, "states")
                                ? _.find(formatOptions(locationData.states), {
                                      value: _.get(decedent, "address.state"),
                                  })
                                : "",
                        zipcode:
                            _.get(decedent, "address.zipcode") &&
                            _.get(locationData, "zipcodes")
                                ? _.find(
                                      formatOptions(
                                          locationData.zipcodes,
                                          "code",
                                          "code"
                                      ),
                                      {
                                          value: _.get(
                                              decedent,
                                              "address.zipcode"
                                          ),
                                      }
                                  )
                                : "",
                    }}
                    enableReinitialize={true}
                    validationSchema={yup.object().shape({
                        firstName: yup
                            .string()
                            .required("First Name is required"),
                        email: yup.string().email("Invalid Email").nullable(),
                        phoneNumber: yup
                            .string()
                            .trim()
                            .notRequired()
                            .matches(
                                phoneRegExp,
                                "Please enter valid Phone Number"
                            )
                            .nullable()
                            .test(
                                "length checker",
                                "Please enter valid Phone Number",
                                val => {
                                    if (val) {
                                        return val.length === 10;
                                    } else {
                                        return !val ? true : false;
                                    }
                                }
                            ),
                        secondaryPhoneNumber: yup
                            .string()
                            .trim()
                            .notRequired()
                            .matches(
                                phoneRegExp,
                                "Please enter valid Phone Number"
                            )
                            .nullable()
                            .test(
                                "length checker",
                                "Please enter valid Phone Number",
                                val => {
                                    if (val) {
                                        return val.length === 10;
                                    } else {
                                        return !val ? true : false;
                                    }
                                }
                            ),
                    })}
                    onSubmit={(values, formikBag) => {
                        executeUpdate(
                            values,
                            decedentId,
                            formikBag,
                            isFirstSaved,
                            showCertificateSavePopup
                        );
                    }}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleSubmit,
                        handleChange,
                        handleBlur,
                        isSubmitting,
                        setFieldValue,
                    }) => {
                        return (
                            <Form id='notifierForm' onSubmit={handleSubmit}>
                                <div className='inputcard-wrapper cardbox-body'>
                                    <div className='inputcard-body custom-inputcard-body'>
                                        <div className='inputcard-group d-flex align-items-center justify-content-start'>
                                            <div className='choose-reason inputfield d-flex align-items-center justify-content-between'>
                                                <div className='inputcard-withlabel d-flex align-items-start justify-content-center flex-column '>
                                                    <label className='inputcard-label'>
                                                        Prefix
                                                    </label>
                                                    <SelectComponent
                                                        placeholder='Select title'
                                                        options={[
                                                            {
                                                                value: "Mr",
                                                                label: "Mr",
                                                            },
                                                            {
                                                                value: "Mrs",
                                                                label: "Mrs",
                                                            },
                                                            {
                                                                value: "Ms",
                                                                label: "Ms",
                                                            },
                                                        ]}
                                                        defaultValue={
                                                            values.prefix
                                                        }
                                                        selectedOption={
                                                            values.prefix
                                                        }
                                                        name='prefix'
                                                        handleChange={e => {
                                                            setFieldValue(
                                                                "prefix",
                                                                e
                                                            );
                                                        }}
                                                        disabled={
                                                            isDisable ||
                                                            !_.get(
                                                                decedent,
                                                                "isNotifierVerified"
                                                            )
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div className='choose-reason inputfield d-flex align-items-center justify-content-between'>
                                                <div className='inputcard-withlabel d-flex align-items-start justify-content-center flex-column'>
                                                    <label className='inputcard-label'>
                                                        First Name
                                                        <small className='ml-2 input-mandatory'>
                                                            *
                                                        </small>
                                                    </label>
                                                    <Field
                                                        id='firstName'
                                                        className='custominputfield'
                                                        type='text'
                                                        placeholder='Enter first name'
                                                        defaultValue={
                                                            values.firstName
                                                        }
                                                        name='firstName'
                                                        disabled={
                                                            isDisable ||
                                                            !_.get(
                                                                decedent,
                                                                "isNotifierVerified"
                                                            )
                                                        }
                                                    />
                                                    {errors.firstName &&
                                                    touched.firstName ? (
                                                        <span className='error-input-msg certificate-error-msg'>
                                                            {errors.firstName}
                                                        </span>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className='choose-reason inputfield d-flex align-items-center justify-content-between'>
                                                <div className='inputcard-withlabel d-flex align-items-start justify-content-center flex-column'>
                                                    <label className='inputcard-label'>
                                                        Middle Name
                                                    </label>
                                                    <input
                                                        id='middleName'
                                                        className='custominputfield'
                                                        type='text'
                                                        placeholder='Enter middle name'
                                                        defaultValue={
                                                            values.middleName
                                                        }
                                                        name='middleName'
                                                        disabled={
                                                            isDisable ||
                                                            !_.get(
                                                                decedent,
                                                                "isNotifierVerified"
                                                            )
                                                        }
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='inputcard-group d-flex align-items-center justify-content-start'>
                                            <div className='choose-reason inputfield d-flex align-items-center justify-content-between'>
                                                <div className='inputcard-withlabel d-flex align-items-start justify-content-center flex-column'>
                                                    <label className='inputcard-label'>
                                                        Last Name
                                                    </label>
                                                    <input
                                                        id='lastName'
                                                        className='custominputfield'
                                                        type='text'
                                                        placeholder='Enter last name'
                                                        defaultValue={
                                                            values.lastName
                                                        }
                                                        name='lastName'
                                                        disabled={
                                                            isDisable ||
                                                            !_.get(
                                                                decedent,
                                                                "isNotifierVerified"
                                                            )
                                                        }
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className='choose-reason inputfield d-flex align-items-center justify-content-between'>
                                                <div className='inputcard-withlabel d-flex align-items-start justify-content-center flex-column'>
                                                    <label className='inputcard-label'>
                                                        Primary Contact Number
                                                    </label>
                                                    {/* <Field
                                                        id="phoneNumber"
                                                        className="custominputfield"
                                                        type="text"
                                                        placeholder="Enter Phonenumber"
                                                        defaultValue={
                                                            values.phoneNumber
                                                        }
                                                        name="phoneNumber"
                                                        maxLength={10}
                                                        disabled={isDisable || !_.get(decedent, 'isNotifierVerified')}
                                                    /> */}
                                                    {values.countryCode  ? 
                                                    (
                                                        <div style={{display:'flex'}}>
                                                            <div className='choose-reason country-code-input inputfield d-flex align-items-center justify-content-between'>
                                                                <div className='inputcard-withlabel d-flex align-items-start justify-content-center flex-column'>
                                                                    <input
                                                                        className="countryCode"
                                                                        value={values.countryCode}
                                                                        type="text"
                                                                        name="countryCode"
                                                                        disabled
                                                                    >
                                                                    </input>
                                                                </div>
                                                            </div>
                                                            <MaskedPhoneNumber
                                                                className='custominputfield'
                                                                type='text'
                                                                name='phoneNumber'
                                                                label='Phone Number'
                                                                formInput
                                                                placeholder='Enter Phonenumber'
                                                                value={
                                                                    values.phoneNumber
                                                                }
                                                                onChange={value => {
                                                                    setFieldValue(
                                                                        "phoneNumber",
                                                                        value
                                                                    );
                                                                }}
                                                                disabled={
                                                                    isDisable ||
                                                                    !_.get(
                                                                        decedent,
                                                                        "isNotifierVerified"
                                                                    )
                                                                }
                                                            />
                                                            {errors.phoneNumber &&
                                                            touched.phoneNumber ? (
                                                                <span className='error-input-msg certificate-error-msg'>
                                                                    {errors.phoneNumber}
                                                                </span>
                                                            ) : null} 
                                                        </div>)
                                                    : 
                                                    ( <>
                                                        <MaskedPhoneNumber
                                                            className='custominputfield'
                                                            type='text'
                                                            name='phoneNumber'
                                                            label='Phone Number'
                                                            formInput
                                                            placeholder='Enter Phonenumber'
                                                            value={
                                                                values.phoneNumber
                                                            }
                                                            onChange={value => {
                                                                setFieldValue(
                                                                    "phoneNumber",
                                                                    value
                                                                );
                                                            }}
                                                            disabled={
                                                                isDisable ||
                                                                !_.get(
                                                                    decedent,
                                                                    "isNotifierVerified"
                                                                )
                                                            }
                                                        />
                                                        {errors.phoneNumber &&
                                                            touched.phoneNumber ? (
                                                                <span className='error-input-msg certificate-error-msg'>
                                                                    {errors.phoneNumber}
                                                                </span>
                                                            ) : null}
                                                     </>
                                                    )
                                                }
                                                </div>
                                            </div>
                                            <div className='choose-reason inputfield d-flex align-items-center justify-content-between'>
                                                <div className='inputcard-withlabel d-flex align-items-start justify-content-center flex-column'>
                                                    <label className='inputcard-label'>
                                                        Secondary Contact Number
                                                    </label>
                                                    {/* <Field
                                                        id="faxNumber"
                                                        className="custominputfield"
                                                        type="text"
                                                        placeholder="Enter Secondary Phonenumber"
                                                        defaultValue={
                                                            values.secondaryPhoneNumber
                                                        }
                                                        name="secondaryPhoneNumber"
                                                        maxLength={10}
                                                        disabled={isDisable || !_.get(decedent, 'isNotifierVerified')}
                                                    /> */}
                                                    {values.secondaryCountryCode  ?
                                                      (
                                                        <div style={{display:'flex'}}>
                                                            <div className='choose-reason country-code-input inputfield d-flex align-items-center justify-content-between'>
                                                                <div className='inputcard-withlabel d-flex align-items-start justify-content-center flex-column'>
                                                                    <input
                                                                        className="countryCode"
                                                                        value={values.secondaryCountryCode}
                                                                        type="text"
                                                                        name="secondaryCountryCode"
                                                                        disabled
                                                                    >
                                                                    </input>
                                                                </div>
                                                            </div>
                                                            <MaskedPhoneNumber
                                                                className='custominputfield'
                                                                type='text'
                                                                name='secondaryPhoneNumber'
                                                                label='Phone Number'
                                                                formInput
                                                                placeholder='Enter Secondary Phonenumber'
                                                                value={
                                                                    values.secondaryPhoneNumber
                                                                }
                                                                onChange={value => {
                                                                    setFieldValue(
                                                                        "secondaryPhoneNumber",
                                                                        value
                                                                    );
                                                                }}
                                                                disabled={
                                                                    isDisable ||
                                                                    !_.get(
                                                                        decedent,
                                                                        "isNotifierVerified"
                                                                    )
                                                                }
                                                            />
                                                            {errors.secondaryPhoneNumber &&
                                                            touched.secondaryPhoneNumber ? (
                                                                <span className='error-input-msg certificate-error-msg'>
                                                                    {
                                                                        errors.secondaryPhoneNumber
                                                                    }
                                                                </span>
                                                            ) : null}
                                                       </div>
                                                      )
                                                      : (<>
                                                            <MaskedPhoneNumber
                                                                className='custominputfield'
                                                                type='text'
                                                                name='secondaryPhoneNumber'
                                                                label='Phone Number'
                                                                formInput
                                                                placeholder='Enter Secondary Phonenumber'
                                                                value={
                                                                    values.secondaryPhoneNumber
                                                                }
                                                                onChange={value => {
                                                                    setFieldValue(
                                                                        "secondaryPhoneNumber",
                                                                        value
                                                                    );
                                                                }}
                                                                disabled={
                                                                    isDisable ||
                                                                    !_.get(
                                                                        decedent,
                                                                        "isNotifierVerified"
                                                                    )
                                                                }
                                                            />
                                                            {errors.secondaryPhoneNumber &&
                                                            touched.secondaryPhoneNumber ? (
                                                                <span className='error-input-msg certificate-error-msg'>
                                                                    {
                                                                        errors.secondaryPhoneNumber
                                                                    }
                                                                </span>
                                                            ) : null}
                                                        </>
                                                      )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className='inputcard-group d-flex align-items-center justify-content-start'>
                                            <div className='choose-reason w-sm-50 inputfield d-flex align-items-center justify-content-between'>
                                                <div className='inputcard-withlabel d-flex align-items-start justify-content-center flex-column '>
                                                    <label className='inputcard-label'>
                                                        Relationship
                                                    </label>
                                                    <SelectComponent
                                                        placeholder='Select Relationship'
                                                        options={formatOptions(
                                                            relations
                                                        )}
                                                        defaultValue={
                                                            values.relation
                                                        }
                                                        selectedOption={
                                                            values.relation
                                                        }
                                                        name='relation'
                                                        handleChange={e => {
                                                            setFieldValue(
                                                                "relation",
                                                                e
                                                            );
                                                        }}
                                                        disabled={
                                                            isDisable ||
                                                            !_.get(
                                                                decedent,
                                                                "isNotifierVerified"
                                                            )
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='subdetails-wrapper mt-3'>
                                            <h5 className='text-capitalize'>
                                                Address Details
                                            </h5>
                                            <CommonAddress
                                                setFieldValue={setFieldValue}
                                                values={values}
                                                data={locationData || {}}
                                                isDisabled={
                                                    isDisable ||
                                                    !_.get(
                                                        decedent,
                                                        "isNotifierVerified"
                                                    )
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className='inputcard-footer'>
                                        <div
                                            id='notifierDetails-save'
                                            onPointerEnter={() =>
                                                showToolTip(true)
                                            }
                                            onPointerLeave={() =>
                                                showToolTip(false)
                                            }
                                        >
                                            <button
                                                type='submit'
                                                disabled={
                                                    isDisable ||
                                                    isSubmitting ||
                                                    !_.get(
                                                        decedent,
                                                        "isNotifierVerified"
                                                    )
                                                }
                                                className='btn-theme btn-search-wrapper text-uppercase'
                                            >
                                                SAVE
                                            </button>
                                        </div>

                                        <Tooltip
                                            placement='top-end'
                                            isOpen={tooltipOpen}
                                            target='notifierDetails-save'
                                            className='tooltip-template'
                                        >
                                            {certificateFreezeText}
                                        </Tooltip>
                                    </div>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            </div>
        </div>
    );
};

export default NotifierDetails;
