import React, { useEffect, useCallback, useMemo, useState } from "react";
import axios from "axios";
import _ from "lodash";
import useAsync from "react-use-async-hook";

import { useSetLoading } from "../../../../utils/hooks/loader";

const fetchFromYoutube = url => {
    return axios({
        url: `https://www.youtube.com/oembed?url=${url}&format=json`,
        method: "GET",
    });
};

const fetchFromVimeo = url => {
    return axios({
        url: `https://vimeo.com/api/oembed.json?url=${url}`,
        method: "GET",
    });
};

const VideoThumbnail = ({
    video,
    isThumbnail,
    flag,
    memoryId,
    setVideoTitleData,
}) => {
    const [isYoutube, setIsYoutube] = useState(false);
    const [isVimeo, setIsVimeo] = useState(false);
    const [link, setLink] = useState("");
    const [videoType, setVideoType] = useState("");
    const [embeddVideo, setEmbeddVideo] = useState("");
    const [isTribute, setIsTribute] = useState(false)
    const [youtubeError, setYoutubeError] = useState(false);
    const cdnURL = process.env.REACT_APP_CDN_URL
    const blobURL = process.env.REACT_APP_BLOB_URL

    useEffect(() => {
        if (video) {
            let fileSrc = video.src;
            let extension, file;
            if (video.fileType === "Video") {
                extension = fileSrc.split(/[#?]/)[0].split(".").pop().trim();

                if (extension === "flv") {
                    setVideoType("video/x-flv");
                } else if (extension === "mov") {
                    setVideoType("");
                } else {
                    setVideoType("video/" + extension);
                }
            }
            fileSrc = _.replace(fileSrc, " ", "");
            fileSrc = _.split(fileSrc, " ")[0];
            const fileIsYoutube = fileSrc.search("youtube");
            const fileIsYoutube1 = fileSrc.search("youtu.be"); //for youtu.be videos
            const fileIsVimeo = fileSrc.search("vimeo");
            if (
                fileIsYoutube > 0 &&
                video.fileType === "Video" &&
                !isThumbnail
            ) {
                setLink(fileSrc);
                setIsYoutube(true);
                setIsTribute(false)
            } else if (fileIsVimeo > 0 && video.fileType === "Video") {
                setLink(fileSrc);
                setIsVimeo(true);
                setIsTribute(false)
            } else if(fileIsYoutube1 > 0 && video.fileType === "Video") {
                setLink(fileSrc);
                setIsYoutube(true);
                setIsTribute(false)
            } else {
                setLink(video);
                setIsYoutube(false)
                setIsVimeo(false)
            }
        }
    }, [video]);

    const [thumbnailUrl, setThumbnailUrl] = useState(null);
    const [videoTitle, setVideoTitle] = useState("");

    const fetchVideoData = url => {
        let urlObject = new URL(url);

        if (
            urlObject.host === "www.youtube.com" ||
            urlObject.host === "www.youtu.be" ||
            urlObject.host === "youtu.be" ||
            urlObject.host === "youtube.com"
        ) {
            executeGetVideoMeta(url, "youtube");
        } else if (urlObject.host === "vimeo.com") {
            executeGetVideoMeta(url, "vimeo");
        } else {
        }
    };

    const fetchThumbanilFromUploadedVideo = () => {
        var _VIDEO = document.querySelector(`#video-file-${flag}-${memoryId}`),
            _CANVAS = document.querySelector(
                `#canvas-file-${flag}-${memoryId}`
            ),
            _CTX = _CANVAS.getContext("2d");

        _VIDEO.addEventListener("loadedmetadata", function () {
            // Set canvas dimensions same as video dimensions
            _CANVAS.width = _VIDEO.videoWidth;
            _CANVAS.height = _VIDEO.videoHeight;
        });
    };

    useEffect(() => {
        if (link && (isYoutube || isVimeo)) {
            const linkUrl = link && link.src ? link.src : link;
            fetchVideoData(linkUrl);
        } else if(link && typeof(link) === "object" && link.src.includes("tribute")){
            setIsTribute(true)
            if (setVideoTitleData) {
                setVideoTitleData("");
            }
        } else {
            setThumbnailUrl("");
            setVideoTitle("");
            if (setVideoTitleData) {
                setVideoTitleData("");
            }
        }
    }, [link]);

    const {
        data: videoData,
        loading: getVideoMetaLoading,
        execute: executeGetVideoMeta,
        error: errorData
    } = useAsync({
        autoExecute: false,
        task: useCallback((url, host) => {
            if (host === "youtube") {
                return fetchFromYoutube(url);
            } else {
                return fetchFromVimeo(url);
            }
        }, []),
        dataLoader: useCallback(response => {
            setYoutubeError(false);
            return _.get(response, "data");
        }, []),
        onError: useCallback(error => {
            return _.get(error, "response")
        }, []),
    });

    useEffect(() => {
        if (videoData) {
            setThumbnailUrl(_.get(videoData, "thumbnail_url"));
            setVideoTitle(_.get(videoData, "title"));
            if (setVideoTitleData) {
                setVideoTitleData(_.get(videoData, "title"));
            }
            if (
                (!isThumbnail &&
                    _.get(videoData, "provider_name") === "Vimeo") ||
                _.get(videoData, "provider_name") === "YouTube"
            ) {
                const embeddedCode = _.get(videoData, "html");
                const videoDiv = document.getElementById(
                    `video-file-${flag}-${memoryId}`
                );
                if (videoDiv) {
                    videoDiv.innerHTML = embeddedCode;
                }
            }
        }
    }, [videoData]);

    useEffect(() => {
        if(errorData) {
            if (errorData.response && _.get(errorData.response, "status") === 401) {
                setYoutubeError(errorData?.response?.data)
                let newLink = video.src.replace("watch?v=", "embed/");
                newLink = newLink && newLink.split("&")[0]
                if(video.src.includes("youtu.be")) {
                    let splitUrl = video.src.split("youtu.be")
                    newLink = splitUrl[0] + "youtube.com/embed" + splitUrl[1]
                }
                setEmbeddVideo(newLink);
                if (setVideoTitleData) {
                    setVideoTitleData("");
                    setIsYoutube(false)
                }
            }
        }
    }, [errorData])

    const showLoading = useMemo(
        () => getVideoMetaLoading,
        [getVideoMetaLoading]
    );
    useSetLoading(showLoading);
    if(!isYoutube && !isVimeo && !youtubeError && !isTribute){
        const source = link.src
        if (source) {
        link.src = source.replace(blobURL, cdnURL)
        }
    }

    return (
        <>
            {youtubeError && (!isYoutube || !isVimeo) && !isTribute && (
                <div
                    className="video-file"
                    id={`video-file-${flag}-${memoryId}`}
                >
                    <iframe
                        src={embeddVideo}
                        frameborder='0'
                        allow='autoplay; encrypted-media'
                        allowfullscreen='allowfullscreen'
                        title={`video-${memoryId}`}
                        id={`video-${memoryId}`}
                    />
                </div>
            )}
            {(isYoutube || isVimeo) && !youtubeError && (
                <div
                    id={`video-file-${flag}-${memoryId}`}
                    className='video-file'
                />
            )}
            {(!isYoutube || !isVimeo) && isTribute && (
                <div
                    className='video-file'
                    id={`video-file-${flag}-${memoryId}`}
                >
                    <iframe
                        src={link.src}
                        frameborder='0'
                        allow='autoplay; encrypted-media'
                        allowfullscreen='allowfullscreen'
                        title={`video-${memoryId}`}
                        id={`video-${memoryId}`}
                        className='video-file'
                    />
                </div>
            )}
            {!isYoutube && !isVimeo && !youtubeError && !isTribute && (
                <video
                    preload='auto'
                    id={`video-file-${flag}-${memoryId}`}
                    controls
                    className='video-file mp4Video'
                    src = {link.src}
                    type ={videoType}
                >
                    {/* <source src={link.src} type={'video/mp4'} /> */}
                </video>
            )}
        </>
    );
};

export default VideoThumbnail;
