import React from "react";
import Select from "react-select";
import { formatOptions } from "../../utils/functions";

const SelectPoem = ({ handleSelect, setSelectedPoem, poems }) => {
  return (
    <div className="select-wrapper" style={{paddingTop:'20px'}}>
      <div className="choose-reason inputfield d-flex align-items-center justify-content-between">
        <div
          className="inputcard-withlabel d-flex align-items-start justify-content-center flex-column"
          style={{ minWidth: "250px" }}
        >
          <Select
            className="select-input"
            styles={{
              menu: (base) => ({
                ...base,
                position: "absolute",
              }),
              container: (base) => ({
                ...base,
                width: 200,
              }),
            }}
            name="color"
            placeholder="Select Poem"
            options={formatOptions(poems)}
            onChange={(val) => {
              handleSelect(val, "poem");
              setSelectedPoem(val);
            }}
          
          />
        </div>
      </div>
    </div>
  );
};

export default SelectPoem;
