import { getAccessToken } from "../../utils";
import Cookies from 'universal-cookie';

const cookies = new Cookies();
const initialState = {
    session: {
        isLoggedIn: Boolean(getAccessToken()),
        role: localStorage.getItem("role") || cookies.get("role"),
        isNewUser: false,
        decedentId: null,
        decedentError: null
    },
    sessionExpired: false,
    isUnauthorized: false,
    isLoading: true,
    forceLogout: false
};

export default (state = initialState, action) => {
    switch (action.type) {
        case "LOGOUT":
            return {
                ...state,
                session: {
                    ...state.session,
                    isLoggedIn: false,
                    role: ''
                },
                sessionExpired: false,
                isUnauthorized: false,
                forceLogout: true
            };
        case "LOGIN":
            return {
                ...state,
                session: {
                    ...state.session,
                    isLoggedIn: true,
                    role: action.payload.role,
                    isNewUser: action.payload.isNewUser,
                    decedentId: action.payload.decedentId,
                    decedentError: action.payload.decedentError
                }
            };
        case "SESSION_EXPIRY_WARNING":
            return {
                ...state,
                sessionExpired: true
            };
        case "UNAUTHORIZED_WARNING":
            return {
                ...state,
                isUnauthorized: true
            };
        case "REFRESH_SESSION":
            return {
                ...state,
                sessionExpired: false,
                isUnauthorized: false
            };
        default:
            return state;
    }
};
