import React from "react";
import { connect } from "react-redux";
import "react-notifications-component/dist/theme.css";
import _ from "lodash";
import Loader from "../components/utils/loader/loader";

const _PublicHOC = (WrappedComponent) => {
    const RenderedComponent = props => {
        return (
            <React.Fragment>
                <Loader showLoader={props.showLoader} />
                <div className="content-renderer">
                    <WrappedComponent {...props} />
                </div>
            </React.Fragment>
        );
    };

    return connect(state => ({
        showLoader: _.get(state, "loader.showLoader", false),
    }))(RenderedComponent);
};

export default _PublicHOC;
