import { useEffect } from "react";
import store from "../../../store/store";

export const useSetLoading = (value) => {
    useEffect(() => {
        store.dispatch({
            type: `SET_LOADER`,
            payload: value
        });
    }, [value]);
};
