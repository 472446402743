import React from "react";
import { InfoIcon } from "../../assets/icons/InfoIcon";
import { Tooltip } from "reactstrap";

const TooltipAndInfo = props => {
    const {
        id = "",
        showTooltipFn,
        key = "",
        showToolTip = false,
        tooltipText = "Some text here",
        clsName = "space-tooltip-icon",
        toolTipClsName = "",
    } = props;

    return (
        <React.Fragment>
            <span
                className={`${clsName}`}
                id={id}
                key={key}
            >
                <InfoIcon color={props.color} size={props.size} marginValue={props.marginValue}/>
            </span>
            <Tooltip
                placement='top-end'
                isOpen={showToolTip}
                target={id}
                toggle={showTooltipFn}
                className={`tooltip-template ${toolTipClsName}`}
            >
                {tooltipText}
                <br/>
                {props.tooltipExtraText}
            </Tooltip>
        </React.Fragment>
    );
};

export default TooltipAndInfo;
