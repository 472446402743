import React, {
    useEffect,
    useCallback,
    useState,
    useRef,
    useMemo,
} from "react";
import _ from "lodash";
import useAsync from "react-use-async-hook";
import * as ObituaryAPI from "../../../../api/obituary";
import { Link } from "react-router-dom";
import { Collapse } from "reactstrap";
import {
    Editor,
    EditorState,
    RichUtils,
    ContentState,
    convertToRaw,
} from "draft-js";
import "draft-js/dist/Draft.css";
import { convertToHTML, convertFromHTML } from "draft-convert";
import addNotification from "../../../utils/notification/notification";
import { getValidFileUrl, fullName } from "../../../../utils/global";
import { useSetLoading } from "../../../utils/hooks/loader";
import defaultImage from "../../../../assets/images/238_DefaultDeceasedPortrait.jpg";
import UPic from "../../../../assets/images/u.png";
import BoldPic from "../../../../assets/images/bold.png";
import ItalicPic from "../../../../assets/images/italic.png";

import { Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import AvatarEditor from "react-avatar-editor";

const MAX_LENGTH_LIFE_OF_DESCRIPTION = 10000;
const MAX_LENGTH_HEADING = 254;

const LifeOfSection = ({
    data,
    opi,
    isLoggedIn,
    getObituary,
    obituaryIsLocked,
}) => {
    const fileRef = useRef(null);
    const audioRef = useRef(null);
    const inputRef = useRef(null);
    const avatarRef = useRef(null);
    const [focus, setFocus] = useState(false);
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [descriptionData, setDescriptionData] = useState(editorState);
    const [editorError, setEditorError] = useState(false);
    const [editorErrorMessage, setEditorErrorMessage] = useState("");
    const { loading: updateLoader, execute: executeEditor } = useAsync({
        autoExecute: false,
        task: useCallback(async (values, section) => {
            let payload;
            if (section === "title") {
                payload = {
                    title: values,
                };
            } else {
                payload = {
                    description: values,
                };
            }
            return {
                res: await ObituaryAPI.updateObituary(
                    opi,
                    payload,
                    section,
                    true
                ),
            };
        }, []),
        dataLoader: useCallback(({ res }) => {
            addNotification({
                type: "success",
                title: "Success",
                message: "Data updated successfully",
            });
        }, []),
        onError: useCallback(error => {
            addNotification({
                type: "danger",
                title: "Error",
                message: _.get(
                    error,
                    "response.data.message",
                    "Oops! Something went wrong"
                ),
            });
        }, []),
    });

    const { loading: updateMedia, execute: executeMedia } = useAsync({
        autoExecute: false,
        task: useCallback(async (formData, type) => {
            return {
                res: await ObituaryAPI.uploadMedia(opi, formData, type),
            };
        }, []),
        dataLoader: useCallback(({ res }) => {
            addNotification({
                type: "success",
                title: "Success",
                message: "Data updated successfully",
            });
        }, []),
        onError: useCallback(error => {
            addNotification({
                type: "danger",
                title: "Error",
                message: _.get(
                    error,
                    "response.data.message",
                    "Oops! Something went wrong"
                ),
            });
        }, []),
    });

    const showLoader = useMemo(() => updateMedia, [updateMedia]);
    useSetLoading(showLoader);

    const initial = () => {
        if (data.lifeOf) {
            if (_.get(data.lifeOf, "description")) {
                setEditorState(
                    EditorState.createWithContent(
                        convertFromHTML(_.get(data.lifeOf, "description"))
                    )
                );
                setDescriptionData(editorState);
            } else {
                setFocus(true);
            }
            // if (_.get(data.lifeOf, "title")) {
            //     setTitleState(
            //         EditorState.createWithContent(
            //             convertFromHTML(_.get(data.lifeOf, "title"))
            //         )
            //     );
            //     setTitleData(titleState)
            // } else {
            //     setheadingFocus(true);
            // }
        } else {
            setFocus(true);
            // setheadingFocus(true);
        }
    };

    useEffect(() => {
        if (data) initial();
    }, [data]);

    useEffect(() => {
        if (inputRef.current && focus) {
            inputRef.current.focus();
        }
    }, [inputRef.current, focus]);

    const formatText = (e, section, type) => {
        e.preventDefault();
        if (section === "body") {
            let nextState = RichUtils.toggleInlineStyle(editorState, type);
            if (
                convertToRaw(nextState.getCurrentContent()).blocks[0]["text"]
                    .length > MAX_LENGTH_LIFE_OF_DESCRIPTION
            ) {
                setEditorError(true);
                setEditorErrorMessage(
                    "You have exceeded max limit for obituary"
                );
            } else {
                setEditorState(nextState);
            }
        }
        // else {
        //     let nextState = RichUtils.toggleInlineStyle(titleState, type);
        //     if ((convertToRaw(nextState.getCurrentContent()).blocks[0]['text']).length> MAX_LENGTH_HEADING) {
        //         setHeadingError(true);
        //         setHeadingErrorMessage('You have exceeded max limit for life of title');
        //     }
        //     else {
        //         setTitleState(nextState);
        //     }
        // }
    };
    const postEditorData = async e => {
        if (
            convertToHTML(editorState.getCurrentContent()) !==
            convertToHTML(descriptionData.getCurrentContent())
        ) {
            await executeEditor(
                convertToHTML(editorState.getCurrentContent()),
                "obituary"
            );
            getObituary(opi);
            setEditorError(false);
        }
        setFocus(false);
    };

    const changeEditorState = (event, editorState) => {
        const contentState = event.getCurrentContent();
        if (
            convertToRaw(contentState).blocks[0]["text"].length <=
            MAX_LENGTH_LIFE_OF_DESCRIPTION
        ) {
            setEditorError(false);
            setEditorState(event);
        }
    };

    const changeBeforeInputLifeOfDescription = (event, editorState) => {
        const totalLength =
            convertToRaw(editorState.getCurrentContent()).blocks[0]["text"]
                .length + event.length;
        if (totalLength > MAX_LENGTH_LIFE_OF_DESCRIPTION) {
            setEditorError(true);
            setEditorErrorMessage(
                "You have exceeded max limit for life of description"
            );
        } else {
            setEditorError(false);
        }
        return totalLength > MAX_LENGTH_LIFE_OF_DESCRIPTION;
    };

    const uploadClick = type => {
        type === "image" ? fileRef.current.click() : audioRef.current.click();
    };

    const [isModel, setIsModel] = useState(false);
    const [avatarImage, setAvatarImage] = useState(_.get(data, "pictureUrl"));
    const [zoom, setZoom] = useState(1);
    const modelClose = () => setIsModel(false);
    const [fileName, setFileName] = useState("fileName.jpg");
    const [mimeType, setMimeType] = useState("image/jpeg");
    const avatarSave = async () => {
        if (avatarRef.current) {
            // const canvas = avatarRef.getImage()
            const canvasScaled = avatarRef.current.getImageScaledToCanvas();
            const croppedImg = canvasScaled.toDataURL();
            const blob = await (await fetch(croppedImg)).blob();
            const file = new File([blob], fileName, {
                type: mimeType,
                lastModified: new Date(),
            });
            var formData = new FormData();
            formData.append("file", file);
            await executeMedia(formData, "image");
            getObituary(opi);
            setIsModel(false);
        }
    };

    const handleImagePreview = async e => {
        const file = e.target.files[0];
        setFileName(file.name);
        setMimeType(file.type);
        e.target.value = null;
        let fileURL = await getValidFileUrl(file);
        setAvatarImage(fileURL);
        setIsModel(true);
    };

    const handleAudio = async e => {
        const file = e.target.files[0];
        var formData = new FormData();
        formData.append("file", file);
        await executeMedia(formData, "audio");
        window.location.reload();
    };

    const name = fullName(data);

    let description = _.get(data.lifeOf, "description");
    // description = _.replace(description, "<p>", "");
    // description = _.replace(description, "</p>", "");

    const shortDescription =
        description.length > 400
            ? description.substring(0, 400) + ". . ."
            : description;

    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);
    const [isTopSection, setIsTopSection] = useState(true);
    const [isEditorSection, setIsEditorSection] = useState(false);

    const activateEditor = () => {
        setIsTopSection(false);
        setIsEditorSection(true);
        setFocus(true);
        setIsOpen(true);
    };

    let topSection = (
        <div>
            <div className='mask'>
                <div
                    className={`${
                        !isLoggedIn && obituaryIsLocked ? "disable-onhover" : ""
                    } profile-picture-wrapper`}
                >
                    <img
                        src={_.get(data, "pictureUrl") || defaultImage}
                        alt='ProfilePic'
                        className='profile-pic-wrapper'
                    />
                    {isLoggedIn && !obituaryIsLocked && (
                        <Link
                            onClick={e => uploadClick("image")}
                            className='link-customise btn-theme theme-green'
                        >
                            Edit
                        </Link>
                    )}
                    <input
                        id='template-image'
                        type='file'
                        ref={fileRef}
                        accept='.png,.jpg,.jpeg'
                        onChange={handleImagePreview}
                        hidden
                    />
                </div>
            </div>
            <div className='profile-description-wrapper'>
                {shortDescription ? (
                    <span
                        style={{ textAlign: "left",
                        padding: "0",
                        margin: "0 0 15px",
                        fontSize: "18pt",
                        fontFamily:"Georgia",
                        lineHeight: "27px" }}
                        dangerouslySetInnerHTML={{
                            __html: isOpen ? description : shortDescription,
                        }}
                        onClick={
                            isLoggedIn && !obituaryIsLocked
                                ? activateEditor
                                : ""
                        }
                    />
                ) : isLoggedIn && !obituaryIsLocked ? (
                    <input
                        className='profile-description-input'
                        placeholder={`Say somethings about ${name}...`}
                        onClick={activateEditor}
                    />
                ) : (
                    ""
                )}
            </div>
        </div>
    );

    let editorSection = (
        <div className='lifeof-wrapper'>
            {focus && isLoggedIn ? (
                <div className='options'>
                    <a
                        className={`${
                            editorState.getCurrentInlineStyle().has("BOLD")
                                ? "selection"
                                : ""
                        }`}
                        onMouseDown={e => formatText(e, "body", "BOLD")}
                    >
                        <img
                            src={BoldPic}
                            alt='BoldPic'
                            className='BoldPic-wrapper'
                        />
                    </a>
                    <a
                        className={`${
                            editorState.getCurrentInlineStyle().has("ITALIC")
                                ? "selection"
                                : ""
                        }`}
                        onMouseDown={e => formatText(e, "body", "ITALIC")}
                    >
                        <img
                            src={ItalicPic}
                            alt='ItalicPic'
                            className='ItalicPic-wrapper'
                        />
                    </a>
                    <a
                        className={`${
                            editorState.getCurrentInlineStyle().has("UNDERLINE")
                                ? "selection"
                                : ""
                        }`}
                        onMouseDown={e => formatText(e, "body", "UNDERLINE")}
                    >
                        <img src={UPic} alt='UPic' className='UPic-wrapper' />
                    </a>
                </div>
            ) : (
                ""
            )}
            <div
                className={`container-root ${
                    focus && isLoggedIn
                        ? editorError
                            ? "errorFocus"
                            : "hasFocus"
                        : "noFocus"
                }`}
            >
                {isLoggedIn && !obituaryIsLocked ? (
                    <Editor
                        ref={inputRef}
                        onFocus={() => {
                            setFocus(true);
                        }}
                        onBlur={e => {
                            postEditorData(e);
                            setIsEditorSection(false);
                            setIsTopSection(true);
                        }}
                        editorState={editorState}
                        onChange={event =>
                            changeEditorState(event, editorState)
                        }
                        handleBeforeInput={event =>
                            changeBeforeInputLifeOfDescription(
                                event,
                                editorState
                            )
                        }
                        placeholder={`Some great things about ${name}...`}
                        preserveSelectionOnBlur={true}
                    ></Editor>
                ) : (
                    <span
                        style={{ textAlign: "left", whiteSpace: "pre-wrap" }}
                        dangerouslySetInnerHTML={{
                            __html: _.get(data, "description"),
                        }}
                    />
                )}
            </div>
        </div>
    );

    return (
        <div>
            <div className='story-wrapper'>
                {isTopSection ? topSection : ""}
                <Collapse isOpen={isOpen}>
                    <div>
                        {isEditorSection && isLoggedIn ? editorSection : ""}
                        {editorError ? (
                            <div
                                style={{ marginTop: 10 }}
                                className='errorMessage'
                            >
                                {editorErrorMessage}
                            </div>
                        ) : (
                            ""
                        )}
                    </div>
                </Collapse>
                {description.length > 400 && (
                    <div>
                        <hr />
                        <div className='description'>
                            <Link
                                color='btn-theme theme-green'
                                onClick={toggle}
                            >
                                {isOpen ? "Read Less" : "Read More"}
                            </Link>
                        </div>
                    </div>
                )}
                <Modal
                    isOpen={isModel}
                    onClose={() => setIsModel(false)}
                    className='obituary-modal-wrapper'
                    style={{ width: "55%" }}
                >
                    <ModalHeader>
                        <i
                            className='close-icon-hg modal-close fas fa-times'
                            onClick={modelClose}
                        />
                    </ModalHeader>
                    <ModalBody align='center'>
                        <div className='story-wrapper' align='center'>
                            <AvatarEditor
                                ref={avatarRef}
                                image={avatarImage}
                                width={250}
                                height={250}
                                border={10}
                                color={[255, 255, 255, 0.6]} // RGBA
                                scale={zoom}
                                rotate={0}
                            />
                            <div className='slidecontainer'>
                                <input
                                    type='range'
                                    min={1}
                                    max={3}
                                    step={0.1}
                                    value={zoom}
                                    className='slider'
                                    onChange={e => {
                                        setZoom(parseFloat(e.target.value));
                                    }}
                                ></input>
                            </div>
                            <div className='avatar-buttons-wrapper'>
                                <span
                                    style={{ display: "block", float: "left" }}
                                >
                                    <button
                                        className='btn-theme theme-green'
                                        onClick={modelClose}
                                    >
                                        CANCEL
                                    </button>
                                </span>
                                <span
                                    style={{ display: "block", float: "right" }}
                                >
                                    <button
                                        className='btn-theme theme-green'
                                        onClick={avatarSave}
                                    >
                                        SAVE
                                    </button>
                                </span>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>
            </div>

            {!(
                !isLoggedIn &&
                obituaryIsLocked &&
                !_.get(data, "audioFileUrl")
            ) && (
                <div className='audio-wrapper d-flex justify-content-between'>
                    <div className='audio-file'>
                        <audio controls>
                            <source src={_.get(data, "audioFileUrl")}></source>
                        </audio>
                    </div>
                    {isLoggedIn && !obituaryIsLocked ? (
                        <Link
                            onClick={e => uploadClick("audio")}
                            className='btn-edit btn-theme'
                        >
                            Edit
                        </Link>
                    ) : (
                        ""
                    )}
                </div>
            )}
            <input
                id='template-image'
                type='file'
                ref={audioRef}
                accept='.mp3, .wav'
                onChange={handleAudio}
                hidden
            />
        </div>
    );
};

export default LifeOfSection;
