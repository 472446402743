export const validateDesc = (value) => {
  let error;
  if (value?.length > 250)
    error = "Description should be less than 251 characters";
  return error;
};

export const validateDonationUrl = (value) => {
  let error;
  if (!value) {
    error = "Url is required";
  } else if (value?.length > 1024)
    error = "Url should be less then 1025 characters";
  else if (!/^https?:\/\//.test(value)) error = "Invalid Url";
  return error;
};

export const validateButtonName = (value) => {
  let error;
  if (!value) {
    error = "ButtonName is required";
  } else if (value?.length > 40)
    error = "ButtonName should be less than 41 characters";
  return error;
};
