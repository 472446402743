export const VALID_FILE_TYPES = [
    "audio/mpeg",
    "audio/wav",
    "audio/wave",
    "video/mp4",
    "video/*",
    "audio/ogg",
    "application/ogg",
    "image/jpeg",
    "image/png",
    "image/gif",
    "video/mpeg",
    "video/ogg",
    "video/quicktime",
    "audio/mp3",
    "video/x-flv",
    "video/x-matroska",
    "video/x-ms-asf",
    "video/x-msvideo",
    ".flv",
    "*/flv",
    ".ogv",
    "*/ogv",
];
