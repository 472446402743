import React from "react";
import _ from "lodash";
import LifeOfSection from "./lifOfSection";
import { fullName } from "../../../../utils/global";
const MemoriesListing = ({
    opi,
    decedent,
    obituary,
    isLoggedIn,
    getObituary,
    obituaryIsLocked,
    loggedinUserData
}) => {
    const name = obituary?.displayName;

    return (
        <>
            {obituary && (
                <LifeOfSection
                    isLoggedIn={isLoggedIn}
                    opi={opi}
                    data={obituary}
                    getObituary={getObituary}
                    obituaryIsLocked={obituaryIsLocked}
                    name={name}
                    loggedinUserData={loggedinUserData}
                ></LifeOfSection>
            )}
        </>
    );
};

MemoriesListing.propTypes = {};

export default MemoriesListing;
