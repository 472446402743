module.exports = {
    prayer: {
        text1: {
            align: 'center',
            font: 'playball',
            size: 'huge',
            bold: false,
            italic: false,
            underline: false,
            line: 1,
        },
        text2: {
            align: 'center',
            font: 'cinzel',
            size: 'large',
            bold: true,
            italic: false,
            underline: false,
            line: 2
        },
        text3: {
            align: 'center',
            font: 'cinzel',
            size: 'large',
            bold: true,
            italic: false,
            underline: false,
            line: 2
        },
        text4: {
            title: {
                align: 'center',
                font: 'playball',
                size: 'fs26',
                bold: false,
                italic: false,
                underline: false,
                line: 2
            },
            content: {
                align: 'center',
                font: 'playball',
                size: 'fs26',
                bold: false,
                italic: false,
                underline: false,
                line: 25
            }
        },
        text5: {
            align: 'center',
            font: 'playball',
            size: 'large',
            bold: false,
            italic: false,
            underline: false,
            line: 3
        }
    }
}