import { Col, Row } from 'reactstrap';
import OfficePreview from '../../../common/officePreview/officePreview';
import ServiceCardActionPanel from '../../../common/serviceCard/serviceCardActionPanel/serviceCardActionPanel';

const NewProgramCard = ({ item, fileUrl, onUpload, uploadProgress,executeSubmitProgramCard }) => {
  return (
    <Row className="new-program-card">
      <Col sm={8}>
        <OfficePreview src={fileUrl} />
      </Col>
      <Col sm={4}>
        <ServiceCardActionPanel
          item={item}
          fileUrl={fileUrl}
          onUpload={onUpload}
          uploadProgress={uploadProgress}
          executeSubmitProgramCard={executeSubmitProgramCard}
        />
      </Col>
    </Row>
  );
};

export default NewProgramCard;
