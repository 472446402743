import React, { useState, useEffect } from "react";
// import { Document, Page, pdfjs } from 'react-pdf';
import { Document, Page } from "react-pdf/dist/entry.webpack";
import PreviousButton from "../../../../../assets/images/prev.png";
import NextButton from "../../../../../assets/images/next.png";

const PdfPreview = ({ width = "100%", height = "600px", src }) => {
  const [numPages, setNumPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [deviceWidth, setDeviceWidth] = useState();

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      // Since we're observing the viewport, there will only be one entry
      const { width } = entries[0]?.contentRect;
      setDeviceWidth(width);
    });
    // Start observing the viewport
    resizeObserver.observe(document?.documentElement);
    // Cleanup function to disconnect the observer when the component unmounts
    return () => {
      resizeObserver?.disconnect();
    };
  }, []);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }
  const onNextPage = () => {
    //For mobile view we are displaying single page per row
    //For web view we are displaying 2 pages per row
    if (deviceWidth <= 567) {
      setCurrentPage(currentPage + 1);
    } else if (deviceWidth > 567 && currentPage + 2 <= numPages) {
      setCurrentPage(currentPage + 2);
    }
  };

  const onPreviousPage = () => {
    if (deviceWidth <= 567) {
      setCurrentPage(currentPage - 1);
    } else if (deviceWidth > 567 && currentPage - 2 >= 1) {
      setCurrentPage(currentPage - 2);
    }
  };

  const hasNext = (index) => {
    if (deviceWidth <= 567) {
      if (currentPage + 1 <= numPages) {
        return true;
      }
    } else if (deviceWidth > 567) {
      if (currentPage + 2 <= numPages) {
        return true;
      }
    }
  };

  const getWidth = () => {
    if (deviceWidth < 300) return 200;
    else if (deviceWidth >= 300 && deviceWidth <= 567) return 300;
    else if (deviceWidth > 567 && deviceWidth <= 820) return 260;
    else if (deviceWidth > 820 && deviceWidth <= 1000) return 350;
    else if (deviceWidth > 1000 && deviceWidth <= 1300) return 370;
    else if (deviceWidth > 1300 && deviceWidth < 1440) return 420;
    else if (deviceWidth >= 1440) return 490;
  };

  return (
    <div className="displayPdfAsImagesContainer">
      <Document file={src} onLoadSuccess={onDocumentLoadSuccess}>
        <div
          key={`page_pair_${currentPage}`}
          className="pageContainer d-flex justify-content-center"
        >
          {deviceWidth <= 567 && (
            <Page pageNumber={currentPage} width={getWidth()} />
          )}
          {deviceWidth > 567 && (
            <>
              <Page pageNumber={currentPage} width={getWidth()} />
              {currentPage + 1 <= numPages && (
                <Page pageNumber={currentPage + 1} width={getWidth()} />
              )}
            </>
          )}
        </div>
      </Document>

      <div>
        {currentPage !== 1 && (
          <img
            onClick={onPreviousPage}
            src={PreviousButton}
            alt="previousIcon"
            class="previous-button arrowButton"
          />
        )}
        {hasNext() && (
          <img
            className="next-button arrowButton"
            src={NextButton}
            alt="next icon"
            onClick={onNextPage}
          />
        )}
      </div>
    </div>
  );
};

export default PdfPreview;
