import React, { useState, useEffect, useCallback, useMemo } from "react";
import _ from "lodash";
import * as yup from "yup";
import { Formik, Field } from "formik";
import { Form, Tooltip } from "reactstrap";
import useAsync from "react-use-async-hook";

import * as DecedentAPI from "../../../../api/decedent";
import { useSetLoading } from "../../../utils/hooks/loader";
import addNotification from "../../../utils/notification/notification";
import { relations } from "../../../utils/variables";
import { formatOptions } from "../../../utils/functions";
import CommonAddress from "../../../utils/commonAddress/commonAddress";
import SelectComponent from "../../../utils/SelectComponent/SelectComponent";
import MaskedPhoneNumber from "../../../utils/MaskedPhoneNumber";
import { getPhoneNumberCountryCode } from "../getCountryCode";
import { getSecondaryPhoneCountryCode } from "../getCountryCode";
import {
    certificateFreezeText,
    firstTimeSaveText,
} from "../constants/constants";
import TooltipAndInfo from "../../../TooltipAndInfo";

const AddNOKForm = ({
    decedent,
    locationData,
    isDisable,
    executeUpdate,
    decedentId,
    showCertificateSavePopup,
}) => {
    const phoneRegExp = /^[0-9]+$/;
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const showToolTip = disabled => {
        if (isDisable) {
            setTooltipOpen(disabled);
        }
    };
    const isFieldAlreadySaved = _.get(decedent, "isFirstSaved");
    const [isFirstSaved, setIsFirstSaved] = useState(isFieldAlreadySaved);
    const {phoneNumber,countryCode} =  decedent?.phoneNumber ? getPhoneNumberCountryCode(decedent.phoneNumber) : {phoneNumber:'',countryCode:''} ;
    const {secondaryPhoneNumber,secondaryCountryCode} = decedent?.secondaryPhoneNumber ?
    getSecondaryPhoneCountryCode(decedent.secondaryPhoneNumber) : {secondaryPhoneNumber:'',secondaryCountryCode:''};
    if (
        typeof isFirstSaved === "undefined" &&
        isFirstSaved !== isFieldAlreadySaved
    ) {
        setIsFirstSaved(isFieldAlreadySaved);
    }

    return (
        <Formik
            initialValues={{
                id: _.get(decedent, "id"),
                prefix: _.get(decedent, "prefix")
                    ? {
                          value: _.get(decedent, "prefix"),
                          label: _.get(decedent, "prefix"),
                      }
                    : "",
                firstName: _.get(decedent, "firstName"),
                middleName: _.get(decedent, "middleName"),
                lastName: _.get(decedent, "lastName"),
                phoneNumber: phoneNumber,
                countryCode: countryCode,
                secondaryPhoneNumber: secondaryPhoneNumber,
                secondaryCountryCode: secondaryCountryCode,
                isNotifier: _.get(decedent, "isNotifier"),
                relation: _.get(decedent, "relationship")
                    ? {
                          value: decedent.relationship,
                          label: decedent.relationship,
                          ..._.find(relations, {
                              name: decedent.relationship,
                          }),
                      }
                    : "",
                email: _.get(decedent, "email"),
                line1: _.get(decedent, "address.line1"),
                line2: _.get(decedent, "address.line2"),
                city:
                    _.get(decedent, "address.city") &&
                    _.get(locationData, "cities")
                        ? _.find(
                              formatOptions(
                                  locationData.cities,
                                  "name",
                                  "code"
                              ),
                              {
                                  value: _.get(decedent, "address.city"),
                              }
                          ) || {
                              label: _.get(decedent, "address.city"),
                              name: _.get(decedent, "address.city"),
                              value: _.get(decedent, "address.city"),
                              state: {
                                  name: _.get(decedent, "address.state"),
                              },
                          }
                        : "",
                country:
                    _.get(decedent, "address.country") &&
                    _.get(locationData, "countries")
                        ? _.find(formatOptions(locationData.countries), {
                              value: _.get(decedent, "address.country"),
                          })
                        : "",
                county:
                    _.get(decedent, "address.county") &&
                    _.get(locationData, "counties")
                        ? _.find(
                              formatOptions(
                                  locationData.counties,
                                  "description",
                                  "description"
                              ),
                              {
                                  value: _.get(decedent, "address.county"),
                              }
                          )
                        : "",
                state:
                    _.get(decedent, "address.state") &&
                    _.get(locationData, "states")
                        ? _.find(formatOptions(locationData.states), {
                              value: _.get(decedent, "address.state"),
                          })
                        : "",
                zipcode:
                    _.get(decedent, "address.zipcode") &&
                    _.get(locationData, "zipcodes")
                        ? _.find(
                              formatOptions(
                                  locationData.zipcodes,
                                  "code",
                                  "code"
                              ),
                              {
                                  value: _.get(decedent, "address.zipcode"),
                              }
                          )
                        : "",
            }}
            enableReinitialize={true}
            validationSchema={yup.object({
                firstName: yup.string().required("First Name is required"),
                email: yup.string().email("Invalid Email").nullable(),
                phoneNumber: yup
                    .string()
                    .trim()
                    .notRequired()
                    .matches(phoneRegExp, "Please enter valid Phone Number")
                    .nullable()
                    .test(
                        "length checker",
                        "Please enter valid Phone Number",
                        val => {
                            if (val) {
                                return val.length === 10;
                            } else {
                                return !val ? true : false;
                            }
                        }
                    ),
                secondaryPhoneNumber: yup
                    .string()
                    .trim()
                    .notRequired()
                    .matches(phoneRegExp, "Please enter valid Phone Number")
                    .nullable()
                    .test(
                        "length checker",
                        "Please enter valid Phone Number",
                        val => {
                            if (val) {
                                return val.length === 10;
                            } else {
                                return !val ? true : false;
                            }
                        }
                    ),
            })}
            onSubmit={(values, formikBag) => {
                executeUpdate(
                    values,
                    decedentId,
                    formikBag,
                    isFirstSaved,
                    setIsFirstSaved,
                    showCertificateSavePopup
                );
            }}
        >
            {({
                values,
                errors,
                touched,
                handleSubmit,
                isSubmitting,
                setFieldValue,
                handleChange,
            }) => {
                return (
                    <Form id='nokForm' onSubmit={handleSubmit}>
                        <div className='inputcard-body custom-inputcard-body'>
                            <div className='inputcard-group d-flex align-items-center justify-content-start'>
                                <div className='choose-reason inputfield d-flex align-items-center justify-content-between'>
                                    <div className='inputcard-withlabel d-flex align-items-start justify-content-center flex-column '>
                                        <label className='inputcard-label'>
                                            Prefix
                                        </label>
                                        <SelectComponent
                                            placeholder='Select title'
                                            options={[
                                                { value: "Mr", label: "Mr" },
                                                { value: "Mrs", label: "Mrs" },
                                                { value: "Ms", label: "Ms" },
                                            ]}
                                            defaultValue={values.prefix}
                                            selectedOption={values.prefix}
                                            name='prefix'
                                            handleChange={e => {
                                                setFieldValue("prefix", e);
                                            }}
                                            disabled={isDisable}
                                        />
                                    </div>
                                </div>
                                <div className='choose-reason inputfield d-flex align-items-center justify-content-between'>
                                    <div className='inputcard-withlabel d-flex align-items-start justify-content-center flex-column'>
                                        <label className='inputcard-label'>
                                            First Name
                                            <small className='ml-2 input-mandatory'>
                                                *
                                            </small>
                                        </label>
                                        <Field
                                            id='firstName'
                                            className='custominputfield'
                                            type='text'
                                            placeholder='Enter first name'
                                            defaultValue={values.firstName}
                                            name='firstName'
                                            disabled={isDisable}
                                        />
                                        {errors.firstName &&
                                        touched.firstName ? (
                                            <span className='error-input-msg certificate-error-msg'>
                                                {errors.firstName}
                                            </span>
                                        ) : null}
                                    </div>
                                </div>
                                <div className='choose-reason inputfield d-flex align-items-center justify-content-between'>
                                    <div className='inputcard-withlabel d-flex align-items-start justify-content-center flex-column'>
                                        <label className='inputcard-label'>
                                            Middle Name
                                        </label>
                                        <Field
                                            id='middleName'
                                            className='custominputfield'
                                            type='text'
                                            placeholder='Enter middle name'
                                            defaultValue={values.middleName}
                                            name='middleName'
                                            disabled={isDisable}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='inputcard-group d-flex align-items-center justify-content-start'>
                                <div className='choose-reason inputfield d-flex align-items-center justify-content-between'>
                                    <div className='inputcard-withlabel d-flex align-items-start justify-content-center flex-column'>
                                        <label className='inputcard-label'>
                                            Last Name
                                        </label>
                                        <Field
                                            id='lastName'
                                            className='custominputfield'
                                            type='text'
                                            placeholder='Enter last name'
                                            defaultValue={values.lastName}
                                            name='lastName'
                                            disabled={isDisable}
                                        />
                                    </div>
                                </div>
                                <div className='choose-reason inputfield d-flex align-items-center justify-content-between'>
                                    <div className='inputcard-withlabel d-flex align-items-start justify-content-center flex-column'>
                                        <label className='inputcard-label'>
                                            Primary Contact Number
                                        </label>
                                        {/* <Field
                                            id="phoneNumber"
                                            className="custominputfield"
                                            type="text"
                                            placeholder="Enter Phonenumber"
                                            defaultValue={values.phoneNumber}
                                            name="phoneNumber"
                                            disabled={isDisable}
                                            maxLength={10}
                                        /> */}
                                        {values.countryCode  ?
                                            (
                                                <div style={{display:'flex'}}>
                                                    <div className='choose-reason country-code-input inputfield d-flex align-items-center justify-content-between'>
                                                        <div className='inputcard-withlabel d-flex align-items-start justify-content-center flex-column'>
                                                            <input
                                                                className="countryCode"
                                                                value={values.countryCode}
                                                                type="text"
                                                                name="countryCode"
                                                                disabled
                                                            >
                                                            </input>
                                                        </div>
                                                     </div>
                                                    <MaskedPhoneNumber
                                                        className='custominputfield'
                                                        type='text'
                                                        name='phoneNumber'
                                                        label='Phone Number'
                                                        formInput
                                                        placeholder='Enter Phonenumber'
                                                        value={values.phoneNumber}
                                                        onChange={value => {
                                                            setFieldValue(
                                                                "phoneNumber",
                                                                value
                                                            );
                                                        }}
                                                        disabled={isDisable}
                                                    />
                                                    {errors.phoneNumber &&
                                                    touched.phoneNumber ? (
                                                        <span className='error-input-msg certificate-error-msg'>
                                                            {errors.phoneNumber}
                                                        </span>
                                                    ) : null}
                                                </div>
                                            ) : 
                                            ( <>
                                                <MaskedPhoneNumber
                                                    className='custominputfield'
                                                    type='text'
                                                    name='phoneNumber'
                                                    label='Phone Number'
                                                    formInput
                                                    placeholder='Enter Phonenumber'
                                                    value={values.phoneNumber}
                                                    onChange={value => {
                                                        setFieldValue(
                                                            "phoneNumber",
                                                            value
                                                            );
                                                            }}
                                                    disabled={isDisable}
                                                    />
                                                    {errors.phoneNumber &&
                                                        touched.phoneNumber ? (
                                                            <span className='error-input-msg certificate-error-msg'>
                                                                {errors.phoneNumber}
                                                            </span>
                                                        ) : null}
                                                    </>
                                            )}
                                    </div>
                                </div>
                                <div className='choose-reason inputfield d-flex align-items-center justify-content-between'>
                                    <div className='inputcard-withlabel d-flex align-items-start justify-content-center flex-column'>
                                        <label className='inputcard-label'>
                                            Secondary Contact Number
                                        </label>
                                        {/* <Field
                                            id="faxNumber"
                                            className="custominputfield"
                                            type="text"
                                            placeholder="Enter Secondary Phonenumber"
                                            defaultValue={
                                                values.secondaryPhoneNumber
                                            }
                                            name="secondaryPhoneNumber"
                                            disabled={isDisable}
                                            maxLength={10}
                                        /> */}
                                       {values.secondaryCountryCode  ?
                                            (
                                                <div style={{display:'flex'}}>
                                                    <div className='choose-reason country-code-input inputfield d-flex align-items-center justify-content-between'>
                                                        <div className='inputcard-withlabel d-flex align-items-start justify-content-center flex-column'>
                                                            <input
                                                                className="countryCode"
                                                                value={values.secondaryCountryCode}
                                                                type="text"
                                                                name="secondaryCountryCode"
                                                                disabled
                                                            >
                                                            </input>
                                                        </div>
                                                     </div>
                                                    <MaskedPhoneNumber
                                                        className='custominputfield'
                                                        type='text'
                                                        name='phoneNumner'
                                                        label='Phone Number'
                                                        formInput
                                                        placeholder='Enter Secondary Phonenumber'
                                                        value={values.secondaryPhoneNumber}
                                                        onChange={value => {
                                                            setFieldValue(
                                                                "secondaryPhoneNumber",
                                                                value
                                                            );
                                                        }}
                                                        disabled={isDisable}
                                                    />
                                                    {errors.secondaryPhoneNumber &&
                                                    touched.secondaryPhoneNumber ? (
                                                        <span className='error-input-msg certificate-error-msg'>
                                                            {errors.secondaryPhoneNumber}
                                                        </span>
                                                    ) : null} 
                                                </div>
                                            ):
                                            (
                                                <>
                                                    <MaskedPhoneNumber
                                                        className='custominputfield'
                                                        type='text'
                                                        name='phoneNumber'
                                                        label='Phone Number'
                                                        formInput
                                                        placeholder='Enter Secondary Phonenumber'
                                                        value={values.secondaryPhoneNumber}
                                                        onChange={value => {
                                                            setFieldValue(
                                                                "secondaryPhoneNumber",
                                                                value
                                                                );
                                                            }}
                                                            disabled={isDisable}
                                                            />
                                                        {errors.secondaryPhoneNumber &&
                                                            touched.secondaryPhoneNumber ? (
                                                                <span className='error-input-msg certificate-error-msg'>
                                                                    {errors.secondaryPhoneNumber}
                                                                </span>
                                                        ) : null}
                                                </>
                                            )}
                                    </div>
                                </div>
                            </div>
                            <div className='inputcard-group d-flex align-items-center justify-content-start mb-5'>
                                <div className='choose-reason inputfield d-flex align-items-center justify-content-between'>
                                    <div className='inputcard-withlabel d-flex align-items-start justify-content-center flex-column '>
                                        <label className='inputcard-label'>
                                            Relationship
                                        </label>
                                        <SelectComponent
                                            placeholder='Select Relationship'
                                            options={formatOptions(relations)}
                                            defaultValue={values.relation}
                                            selectedOption={values.relation}
                                            name='relation'
                                            handleChange={e => {
                                                setFieldValue("relation", e);
                                            }}
                                            disabled={isDisable}
                                        />
                                    </div>
                                </div>
                                <div className='choose-reason inputfield d-flex align-items-center justify-content-between'>
                                    <div className='inputcard-withlabel d-flex align-items-start justify-content-center flex-column '>
                                        <label className='inputcard-label'>
                                            Email ID
                                        </label>
                                        <Field
                                            id='emailNok'
                                            className='custominputfield'
                                            type='email'
                                            placeholder='Enter Email'
                                            defaultValue={values.email}
                                            name='email'
                                            disabled={isDisable}
                                        />
                                        {errors.email && touched.email ? (
                                            <span className='error-input-msg certificate-error-msg'>
                                                {errors.email}
                                            </span>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                            <CommonAddress
                                setFieldValue={setFieldValue}
                                values={{
                                    line1: values.line1,
                                    line2: values.line2,
                                    city: values.city,
                                    county: values.county,
                                    state: values.state,
                                    zipcode: values.zipcode,
                                    country: values.country,
                                }}
                                data={locationData || {}}
                                isDisabled={isDisable}
                            />
                        </div>
                        <div className='inputcard-footer'>
                            <div
                                id='nokDetails-save'
                                onPointerEnter={() => showToolTip(true)}
                                onPointerLeave={() => showToolTip(false)}
                            >
                                <button
                                    type='submit'
                                    disabled={isDisable || isSubmitting}
                                    className='btn-theme btn-search-wrapper text-uppercase'
                                >
                                    SAVE
                                </button>
                            </div>
                            <Tooltip
                                placement='top-end'
                                isOpen={tooltipOpen}
                                target='nokDetails-save'
                                className='tooltip-template'
                            >
                                {certificateFreezeText}
                            </Tooltip>
                        </div>
                    </Form>
                );
            }}
        </Formik>
    );
};

const NOKDetails = ({
    decedent,
    locationData,
    isDisable,
    decedentId,
    refreshDecedent,
    showCertificateSavePopup,
    enableSubmitButton,
}) => {
    const [nokCount, setNokCount] = useState(1);
    const [isTooltipOpen, setIsTooltipOpen] = useState(false);

    useEffect(() => {
        if (decedent && decedent.length) {
            setNokCount(decedent.length);
        }
    }, [decedent]);

    /**
     * Save NOK Details
     */
    const { loading: updateLoader, execute: executeUpdate } = useAsync({
        autoExecute: false,
        task: useCallback(
            async (
                values,
                decedentId,
                formikBag,
                isFirstSaved,
                setIsFirstSaved,
                showCertificateSavePopup
            ) => {
                let payload = {
                    id: values.id,
                    prefix: values.prefix ? values.prefix.value : "",
                    firstName: values.firstName,
                    middleName: values.middleName,
                    lastName: values.lastName,
                    email: values.email,
                    phoneNumber:`${values.countryCode + values.phoneNumber}`,
                    secondaryPhoneNumber:`${values.secondaryCountryCode + values.secondaryPhoneNumber}`,
                    relation: values.relation ? values.relation.label : "",
                    isNotifier: values.isNotifier,
                    address: {
                        line1: values.line1,
                        line2: values.line2,
                        country: !values.country ? "" : values.country.value,
                        state: !values.state ? "" : values.state.value,
                        county: !values.county ? "" : values.county.value,
                        city: !values.city ? "" : values.city.value,
                        zipcode: !values.zipcode ? "" : values.zipcode.value,
                    },
                };
                return {
                    res: await DecedentAPI.saveNOKDetails(decedentId, payload),
                    formikBag,
                    isFirstSaved,
                    setIsFirstSaved,
                    showCertificateSavePopup,
                };
            },
            []
        ),
        dataLoader: useCallback(
            ({
                res,
                formikBag,
                isFirstSaved,
                setIsFirstSaved,
                showCertificateSavePopup,
            }) => {
                formikBag.setSubmitting(false);
                refreshDecedent();
                enableSubmitButton();
                if (isFirstSaved) {
                    addNotification({
                        type: "success",
                        title: "Success",
                        message: firstTimeSaveText,
                    });
                } else {
                    showCertificateSavePopup();
                    setIsFirstSaved(true);
                }

                addNotification({
                    type: "success",
                    title: "Success",
                    message: "Next of Kin Details Updated!",
                });
            },
            []
        ),
        onError: useCallback(error => {
            addNotification({
                type: "danger",
                title: "Error",
                message: _.get(
                    error,
                    "response.data.message",
                    "Oops! Something went wrong"
                ),
            });
        }, []),
    });

    const showLoader = useMemo(() => updateLoader, [updateLoader]);
    useSetLoading(showLoader);

    const showHideToolTipFn = () => {
        setIsTooltipOpen(!isTooltipOpen);
    };

    return (
        <div className='nokdetails-wrapper'>
            <div className='cardbox-wrapper'>
                <div className='cardbox-heading'>
                    <h4 className='cardbox-heading-name text-black'>
                        <span className='item-numbering'>8</span>
                        Next of Kin Details
                    </h4>
                    <div style={{ display: "flex" }}>
                        <button
                            type='button'
                            disabled={isDisable}
                            onClick={() => setNokCount(nokCount + 1)}
                            className='btn-add-nok btn-theme theme-link'
                        >
                            ADD NEXT OF KIN
                        </button>

                        <TooltipAndInfo
                            id='nok-tooltip-id'
                            showTooltipFn={showHideToolTipFn}
                            showToolTip={isTooltipOpen}
                            tooltipText='Add any other contact that may participate in
                        your arrangement process.'
                            clsName='space-tooltip-icon-top'
                        />
                    </div>
                </div>
                <div className='inputcard-wrapper cardbox-body'>
                    {_.map(
                        Array.from({ length: nokCount }, (v, i) => i),
                        element => (
                            <div className='subdetails-wrapper mt-3'>
                                <div className='subdetails-content'>
                                    <h5 className='text-capitalize'>
                                        NOK #{element + 1}
                                    </h5>
                                    {_.get(decedent[element], "isNotifier") && (
                                        <h6 className='subdetail-hl'>
                                            [Same as Notifier]
                                        </h6>
                                    )}
                                </div>
                                <AddNOKForm
                                    decedent={decedent[element] || {}}
                                    locationData={locationData}
                                    isDisable={isDisable}
                                    executeUpdate={executeUpdate}
                                    decedentId={decedentId}
                                    showCertificateSavePopup={
                                        showCertificateSavePopup
                                    }
                                />
                            </div>
                        )
                    )}
                </div>
            </div>
        </div>
    );
};

export default NOKDetails;
