import React, { useState } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
const ConfirmationDialog = ({
  isOpen,
  onCancel,
  onSave,
  onDisCard,
  textToDisplay,
  isError,
}) => {
  const [submitDisable, setSubmitDisable] = useState(false);
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onCancel}
      toggle={!isOpen}
      className="AlertMessage-wrapper"
      backdrop="static"
    >
      <ModalHeader>
        <span className={"modal-memory-headername" + " theme-error"}>
          <i className={"mr-2 fas" + " fa-check-circle theme-error"} />
          {"Un Saved Changes"}
        </span>
        <i className="modal-close fas fa-times" onClick={() => onCancel()} />
      </ModalHeader>
      <ModalBody>{textToDisplay}</ModalBody>
      <ModalFooter>
        <button
          onClick={() => {
            onSave();
            setSubmitDisable(true);
          }}
          className="btn-theme"
          disabled={isError || submitDisable}
        >
          Save
        </button>
        <button onClick={onDisCard} className="btn-theme btn-cancel">
          Discard
        </button>
      </ModalFooter>
    </Modal>
  );
};

export default ConfirmationDialog;
