import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import useAsync from "react-use-async-hook";
import _ from "lodash";

import { useSetLoading } from "../../utils/hooks/loader";
import * as Dashboard from "../../../api/dashboard";
import BreadCrumb from "../../utils/breadCrumb/breadCrumb";
import AppLayout from "../../appLayout";
import SelectingDisplayName from "./displayName";
import CustomizeDonationLink from "./cutomizeDonateLink";
import AddSocialMediaLinks from "./addSocialMediaLinks";
import DisplayCountOfSubscribers from "./displayCountOfSubscribers";
import DisplayUsersMessages from "./displayUsersMessages";
import { apiErrorHandler } from "../../../utils/handlers";
import { getObituary } from "../../../api/dashboard";
import { Row, Col } from "reactstrap";

const CustomizeFamilyWebsite = ({ role }) => {
  const { onePortalId } = useParams();
  const [isArranger, setIsArranger] = useState(true);
  const [loader, setLoader] = useState(false);
  const { pathname, search } = useLocation();
  const searchParameter = search
    ? search.slice(search.indexOf("?"))
    : pathname && pathname.includes("?")
    ? pathname.slice(pathname.indexOf("?"))
    : "";

  useEffect(() => {
    if (role === "admin") setIsArranger(false);
    else if (role === "arranger") setIsArranger(true);
  }, [role]);
  /**
   * Get Decedent Detail
   */
  const {
    data: decedent,
    loading: getDecedentLoader,
    execute: executeGetDecedent,
  } = useAsync({
    autoExecute: false,
    task: Dashboard.getArrangementSummary,
    dataLoader: useCallback((res) => {
      return _.get(res, "data");
    }, []),
  });

  useEffect(() => {
    if (onePortalId) {
      executeGetDecedent(onePortalId);
      executeObituary(onePortalId);
    }
  }, [onePortalId]);

  /**
   * Fetch Obituary details
   */
  const {
    data: obituary,
    loading: getObituaryLoader,
    execute: executeObituary,
  } = useAsync({
    autoExecute: false,
    task: getObituary,
    dataLoader: useCallback((res) => {
      return _.get(res, "data.obituary");
    }, []),
    onError: useCallback((error) => {
      if (document.getElementsByClassName("notification-danger").length == 0) {
        apiErrorHandler(error);
      }
    }, []),
  });

  const showLoader = useMemo(
    () => getDecedentLoader || getObituaryLoader || loader,
    [getDecedentLoader, getObituaryLoader, loader]
  );
  useSetLoading(showLoader);
  
  return (
    <AppLayout
      decedent={_.get(decedent, "decedent") || null}
      haveAcceptPolicy={
        isArranger ? _.get(decedent, "haveAcceptPolicy", false) : true
      }
      getDecedent={executeGetDecedent}
      setLoader={setLoader}
    >
      <div className="container">
        {isArranger ? (
          <BreadCrumb
            links={[
              {
                url: `/app`,
                description: "Home",
                heading: "Dashboard",
              },
              {
                url: `/app/${onePortalId}/arrangements`,
                description: "Arrangements Summary",
                heading: "Arrangements Summary",
              },
              {
                url: `/app/${onePortalId}/customizeFamilyWebsite`,
                description: "Customize Family Website",
                heading: "Customize Family Website",
                isSpan: true,
              },
            ]}
            showBack={true}
          />
        ) : (
          <BreadCrumb
            links={[
              {
                url: `/admin/decedents`,
                description: "Home",
                heading: "Dashboard",
              },
              {
                url: `/admin/${onePortalId}/arrangements`,
                description: "Arrangements Summary",
                heading: "Arrangements Summary",
                search: searchParameter,
              },
              {
                url: `/admin/${onePortalId}/customizeFamilyWebsite`,
                description: "Customize Family Website",
                heading: "Customize Family Website",
                isSpan: true,
              },
            ]}
            showBack={true}
          />
        )}
        <div className="navigationCenter-body-wrapper">
          <div className="customizeFamilyWebsiteWrapper">
            <Row style={{ marginBottom: "30px" }}>
              <Col className="cardbox-wrapper">
                <SelectingDisplayName
                  obituary={obituary}
                  opi={onePortalId}
                  executeObituary={() => executeObituary(onePortalId)}
                />
              </Col>
            </Row>
            <Row style={{ marginBottom: "30px" }}>
              <Col className="cardbox-wrapper">
                <CustomizeDonationLink
                  decedent={decedent}
                  onePortalId={onePortalId}
                  executeGetDecedent={executeGetDecedent}
                />
              </Col>
            </Row>
            <Row style={{ marginBottom: "30px" }}>
              <Col className="cardbox-wrapper">
                <AddSocialMediaLinks
                  decedent={decedent}
                  onePortalId={onePortalId}
                />
              </Col>
            </Row>
            <Row style={{ marginBottom: "30px" }}>
              <Col className="cardbox-wrapper">
                <DisplayUsersMessages
                  decedent={decedent}
                  opi={onePortalId}
                />
              </Col>
            </Row>
            <Row style={{ marginBottom: "30px" }}>
              <Col className="cardbox-wrapper">
                <DisplayCountOfSubscribers
                  decedent={decedent}
                  onePortalId={onePortalId}
                />
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </AppLayout>
  );
};

export default CustomizeFamilyWebsite;
