/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { Input } from 'reactstrap';

const isInteger = s => {
  if (s === '-') return true;
  return String(s).search(/^\s*(\+|-)?\d+\s*$/) !== -1;
};

const handlePhoneNumberKeyPress = event => {
    const character = String.fromCharCode(event.which);
    if (!isInteger(character)) {
      return false;
    }
};

const formatPhoneNumber = value => {
    if (!value) {
      return '';
    }
    let val = value.replace(/\D/g, '');
    let newVal = '';
  
    // format - (XXX) XXX-XXXX
    if (val.length > 0) {
      newVal = `(`;
    }
    if (val.length > 3) {
      newVal += `${val.substr(0, 3)}) `;
      val = val.substr(3);
    }
    if (val.length > 3 && val.length < 7) {
      newVal += `${val.substr(0, 3)}-`;
      val = val.substr(3);
    }
    if (val.length > 6) {
      newVal += `${val.substr(0, 3)}-`;
      val = val.substr(3);
    }
  
    newVal += val;
    return newVal;
};

const numericPhoneNumber = phoneNumberValue => {
    if (!phoneNumberValue) return '';
  
    const phoneNumberArr = phoneNumberValue.match(/[0-9]/gi);
    if (phoneNumberArr) {
      return phoneNumberArr.join('');
    }
    return '';
};

const MaskedPhoneNumber = ({ name, onChange, value, ...rest }) => {
  const phoneNumber = formatPhoneNumber(value);

  return (
    <Input
      value={phoneNumber}
      name={name}
      maxLength="14"
      placeholder="(XXX) XXX-XXXX"
      onKeyPress={handlePhoneNumberKeyPress}
      onChange={e => {
        onChange(numericPhoneNumber(e.target.value));
      }}
      {...rest}
    />
  );
};

MaskedPhoneNumber.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func
};

MaskedPhoneNumber.defaultProps = {
  name: '',
  value: '',
  onChange: () => {}
};

export default MaskedPhoneNumber;
