import React from 'react';

const generateGridArray = () => {
  return new Array(8).fill(1);
};

export default function ImageGrid({ type, url }) {
  return (
    <div className="cards">
      <div className="extra" />
      <div className={`items cover ${type}`}>
        {generateGridArray().map(() => (
          <div className="card-item-container">
            <img src={url} alt="Img" />
          </div>
        ))}
      </div>
    </div>
  );
}