import React, { useCallback, useMemo, useState } from "react";
import { Formik } from "formik";
import _ from "lodash";
import { Form, Tooltip } from "reactstrap";
import useAsync from "react-use-async-hook";

import * as DecedentAPI from "../../../../api/decedent";
import { useSetLoading } from "../../../utils/hooks/loader";
import { formatOptions } from "../../../utils/functions";
import addNotification from "../../../utils/notification/notification";
import CommonAddress from "../../../../components/utils/commonAddress/commonAddress";
import {
    certificateFreezeText,
    firstTimeSaveText,
} from "../constants/constants";

const ResidentialDetails = ({
    decedent,
    isDisable,
    decedentId,
    refreshDecedent,
    locationData,
    showCertificateSavePopup,
    enableSubmitButton,
}) => {
    /**
     * Save Residential Details
     */
    const isFieldAlreadySaved = _.get(decedent, "isFirstSaved");
    const [isFirstSaved, setIsFirstSaved] = useState(isFieldAlreadySaved);
    if (
        typeof isFirstSaved === "undefined" &&
        isFirstSaved !== isFieldAlreadySaved
    ) {
        setIsFirstSaved(isFieldAlreadySaved);
    }
    const { loading: updateLoader, execute: executeUpdate } = useAsync({
        autoExecute: false,
        task: useCallback(
            async (
                values,
                decedentId,
                formikBag,
                isFirstSaved,
                showCertificateSavePopup
            ) => {
                let payload = {
                    line1: values.line1,
                    line2: values.line2,
                    country: !values.country ? "" : values.country.value,
                    state: !values.state ? "" : values.state.value,
                    county: !values.county ? "" : values.county.value,
                    city: !values.city ? "" : values.city.value,
                    zipcode: !values.zipcode ? "" : values.zipcode.value,
                    noOfYearsStayed: values.noOfYearsStayed,
                };
                return {
                    res: await DecedentAPI.saveResidentDetails(
                        decedentId,
                        payload
                    ),
                    formikBag,
                    isFirstSaved,
                    showCertificateSavePopup,
                };
            },
            []
        ),
        dataLoader: useCallback(
            ({ res, formikBag, isFirstSaved, showCertificateSavePopup }) => {
                formikBag.setSubmitting(false);
                refreshDecedent();
                enableSubmitButton();
                if (isFirstSaved) {
                    addNotification({
                        type: "success",
                        title: "Success",
                        message: firstTimeSaveText,
                    });
                } else {
                    showCertificateSavePopup();
                    setIsFirstSaved(true);
                }

                addNotification({
                    type: "success",
                    title: "Success",
                    message: "Residential Details Updated!",
                });
            },
            []
        ),
        onError: useCallback(error => {
            addNotification({
                type: "danger",
                title: "Error",
                message: _.get(
                    error,
                    "response.data.message",
                    "Oops! Something went wrong"
                ),
            });
        }, []),
    });
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const showLoader = useMemo(() => updateLoader, [updateLoader]);
    useSetLoading(showLoader);
    const showToolTip = disabled => {
        if (isDisable) {
            setTooltipOpen(disabled);
        }
    };

    return (
        <div className='residentaildetails-wrapper'>
            <div className='cardbox-wrapper'>
                <div className='cardbox-heading'>
                    <h4 className='cardbox-heading-name text-capitalize text-black'>
                        <span className='item-numbering'>2</span>
                        Residential Details
                    </h4>
                </div>
                <Formik
                    initialValues={{
                        noOfYearsStayed:
                            parseInt(_.get(decedent, "noOfYearsStayed")) || "",
                        line1: _.get(decedent, "address.line1"),
                        line2: _.get(decedent, "address.line2"),
                        city:
                            _.get(decedent, "address.city") &&
                            _.get(locationData, "cities")
                                ? _.find(
                                      formatOptions(
                                          locationData.cities,
                                          "name",
                                          "code"
                                      ),
                                      {
                                          value: _.get(
                                              decedent,
                                              "address.city"
                                          ),
                                          state: {
                                              name: _.get(
                                                  decedent,
                                                  "address.state"
                                              ),
                                          },
                                      }
                                  ) || {
                                      label: _.get(decedent, "address.city"),
                                      name: _.get(decedent, "address.city"),
                                      value: _.get(decedent, "address.city"),
                                      state: {
                                          name: _.get(
                                              decedent,
                                              "address.state"
                                          ),
                                      },
                                  }
                                : "",
                        country:
                            _.get(decedent, "address.country") &&
                            _.get(locationData, "countries")
                                ? _.find(
                                      formatOptions(locationData.countries),
                                      {
                                          value: _.get(
                                              decedent,
                                              "address.country"
                                          ),
                                      }
                                  )
                                : "",
                        county:
                            _.get(decedent, "address.county") &&
                            _.get(locationData, "counties")
                                ? _.find(
                                      formatOptions(
                                          locationData.counties,
                                          "description",
                                          "description"
                                      ),
                                      {
                                          value: _.get(
                                              decedent,
                                              "address.county"
                                          ),
                                          state: {
                                              name: _.get(
                                                  decedent,
                                                  "address.state"
                                              ),
                                          },
                                      }
                                  )
                                : "",
                        state:
                            _.get(decedent, "address.state") &&
                            _.get(locationData, "states")
                                ? _.find(formatOptions(locationData.states), {
                                      value: _.get(decedent, "address.state"),
                                  })
                                : "",
                        zipcode:
                            _.get(decedent, "address.zipcode") &&
                            _.get(locationData, "zipcodes")
                                ? _.find(
                                      formatOptions(
                                          locationData.zipcodes,
                                          "code",
                                          "code"
                                      ),
                                      {
                                          value: _.get(
                                              decedent,
                                              "address.zipcode"
                                          ),
                                      }
                                  )
                                : "",
                    }}
                    enableReinitialize={true}
                    onSubmit={(values, formikBag) => {
                        executeUpdate(
                            values,
                            decedentId,
                            formikBag,
                            isFirstSaved,
                            showCertificateSavePopup
                        );
                    }}
                >
                    {({
                        values,
                        handleSubmit,
                        setSubmitting,
                        handleBlur,
                        isSubmitting,
                        setFieldValue,
                        setFieldError,
                    }) => {
                        const handleChange = e => {
                            const { name, value } = e.target;
                            setFieldValue(name, value);
                            setSubmitting(false);
                        };
                        return (
                            <Form id='residentForm' onSubmit={handleSubmit}>
                                <div className='inputcard-wrapper cardbox-body'>
                                    <div className='inputcard-body custom-inputcard-body'>
                                        <CommonAddress
                                            setFieldValue={setFieldValue}
                                            values={values}
                                            data={locationData || {}}
                                            isDisabled={isDisable}
                                        />
                                        <div className='inputcard-group d-flex align-items-center justify-content-start'>
                                            <div className='choose-reason-address choose-reason inputfield d-flex align-items-center justify-content-between'>
                                                <div className='inputcard-withlabel d-flex align-items-start justify-content-center flex-column'>
                                                    <label className='inputcard-label'>
                                                        Number of Years stayed
                                                        in the County
                                                    </label>
                                                    <input
                                                        className='custominputfield'
                                                        placeholder='Enter No of Years Stayed'
                                                        type='number'
                                                        value={
                                                            values.noOfYearsStayed
                                                        }
                                                        name='noOfYearsStayed'
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        disabled={isDisable}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='inputcard-footer'>
                                        <div
                                            id='residentForm-save'
                                            onPointerEnter={() =>
                                                showToolTip(true)
                                            }
                                            onPointerLeave={() =>
                                                showToolTip(false)
                                            }
                                        >
                                            <button
                                                type='submit'
                                                disabled={
                                                    isDisable || isSubmitting
                                                }
                                                className='btn-theme btn-search-wrapper text-uppercase'
                                            >
                                                SAVE
                                            </button>
                                        </div>
                                        <Tooltip
                                            placement='top-end'
                                            isOpen={tooltipOpen}
                                            target='residentForm-save'
                                            className='tooltip-template'
                                        >
                                            {certificateFreezeText}
                                        </Tooltip>
                                    </div>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            </div>
        </div>
    );
};

export default ResidentialDetails;
