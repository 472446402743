import React, {
    useEffect,
    useCallback,
    useState,
    useRef,
    useMemo,
} from "react";
import _ from "lodash";
import useAsync from "react-use-async-hook";
import { Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import {
    Editor,
    EditorState,
    RichUtils,
    ContentState,
    convertToRaw,
} from "draft-js";
import "draft-js/dist/Draft.css";

import * as ObituaryAPI from "../../../../api/obituary";
import { useSetLoading } from "../../../utils/hooks/loader";
import addNotification from "../../../utils/notification/notification";
import { fullName } from "../../../../utils/global";

import "./obituary.scss";
import defaultImage from "../../../../assets/images/238_DefaultDeceasedPortrait.jpg";
import BoldPic from "../../../../assets/images/bold.png";
import ItalicPic from "../../../../assets/images/italic.png";
import UPic from "../../../../assets/images/u.png";
import { convertToHTML, convertFromHTML } from "draft-convert";
import TinyMceEditor from "../../../../components/common/tinyMceEditor/TinyMceEditor";

const MAX_LENGTH = 20000;
const MAX_LENGTH_HEADING = 254;

const Obituary = ({
    isOpen,
    onClose,
    data,
    opi,
    getObituary,
    isLoggedIn,
    obituaryIsLocked,
}) => {
    const inputDesRef = useRef();
    const [focus, setFocus] = useState(false);
    const [type, setType] = useState();
    const [headingFocus, setheadingFocus] = useState(false);
    const [editorState, setEditorState] = useState();
    const [headingState, setHeadingState] = useState(EditorState.createEmpty());
    const [titleData, setTitleData] = useState(headingState);
    const [descriptionData, setDescriptionData] = useState(editorState);
    const [initialDescriptionData, setInitialDescriptionData] =
        useState(editorState);
    const [headingError, setHeadingError] = useState(false);
    const [headingErrorMessage, setHeadingErrorMessage] = useState("");
    const [editorError, setEditorError] = useState(false);
    const [editorErrorMessage, setEditorErrorMessage] = useState("");

    /**
     * Update Obituary
     */
    const { loading: updateLoader, execute: executeEditor } = useAsync({
        autoExecute: false,
        task: useCallback(async (values, section) => {
            let payload;
            if (section === "title") {
                payload = {
                    title: values,
                };
            } else {
                payload = {
                    obituary: values,
                };
            }
            return {
                res: await ObituaryAPI.updateObituary(
                    opi,
                    payload,
                    section,
                    false
                ),
            };
        }, []),
        dataLoader: useCallback(({ res }) => {
            addNotification({
                type: "success",
                title: "Success",
                message: "Data updated successfully",
            });
        }, []),
        onError: useCallback(error => {
            addNotification({
                type: "danger",
                title: "Error",
                message: _.get(
                    error,
                    "response.data.message",
                    "Oops! Something went wrong"
                ),
            });
        }, []),
    });

    const showLoader = useMemo(() => updateLoader, [updateLoader]);
    useSetLoading(showLoader);

    const initial = () => {
        if (data) {
            if (_.get(data, "obituary")) {
                setDescriptionData(_.get(data, "obituary"));
                setInitialDescriptionData(_.get(data, "obituary"));
            } else {
                setFocus(true);
            }
            if (_.get(data, "title")) {
                setHeadingState(
                    EditorState.createWithContent(
                        convertFromHTML(_.get(data, "title"))
                    )
                );
                setTitleData(headingState);
            } else {
                setheadingFocus(true);
            }
        } else {
            setFocus(true);
            setheadingFocus(true);
        }
    };

    useEffect(() => {
        if (data) initial();
    }, [data]);
    const formatText = (e, section, type) => {
        e.preventDefault();
        if (section === "body") {
            let nextState = RichUtils.toggleInlineStyle(editorState, type);
            setType(type);
            if (e.target.value.length > MAX_LENGTH) {
                setEditorError(true);
                setEditorErrorMessage(
                    "You have exceeded max limit for obituary"
                );
            } else {
                setEditorState(nextState);
            }
        } else {
            let nextState = RichUtils.toggleInlineStyle(headingState, type);
            setType(type);
            if (
                convertToRaw(nextState.getCurrentContent()).blocks[0]["text"]
                    .length > MAX_LENGTH_HEADING
            ) {
                setHeadingError(true);
                setHeadingErrorMessage(
                    "You have exceeded max limit for life of title"
                );
            } else {
                setHeadingState(nextState);
            }
        }
    };

    const postEditorData = async (e, val) => {
        setEditorError(false);
        const obituaryText = val ? val : descriptionData;
        if (!obituaryText) {
            setEditorError(true);
            setEditorErrorMessage("Obituary is required");
        } else if (obituaryText.length > MAX_LENGTH) {
            return false;
        } else if (_.get(data, "obituary") !== obituaryText) {
            await executeEditor(obituaryText, "obituary");
            getObituary(opi);
            setEditorError(false);
            setFocus(false);
        }
    };

    const postHeadingData = async e => {
        if (
            convertToHTML(headingState.getCurrentContent()) !==
            convertToHTML(titleData.getCurrentContent())
        ) {
            await executeEditor(
                convertToHTML(headingState.getCurrentContent()),
                "title"
            );
            getObituary(opi);
            setHeadingError(false);
        }
        setheadingFocus(false);
    };

    const changeEditorState = value => {
        setFocus(true);
        if (!value) {
            setEditorError(true);
            setEditorErrorMessage("Obituary is required");
            setDescriptionData(value);
        } else if (value.length <= MAX_LENGTH) {
            setEditorError(false);
            setDescriptionData(value);
        } else {
            setEditorError(true);
            setEditorErrorMessage("You have exceeded max limit for obituary");
        }
    };

    const changeHeadingState = (event, headingState) => {
        const contentState = event.getCurrentContent();
        if (
            convertToRaw(contentState).blocks[0]["text"].length <=
            MAX_LENGTH_HEADING
        ) {
            setHeadingError(false);
            setHeadingState(event);
        }
    };

    const changeBeforeInputHeading = (event, headingState) => {
        const totalLength =
            convertToRaw(headingState.getCurrentContent()).blocks[0]["text"]
                .length + event.length;
        if (totalLength > MAX_LENGTH_HEADING) {
            setHeadingError(true);
            setHeadingErrorMessage(
                "You have exceeded max limit for life of title"
            );
        } else {
            setHeadingError(false);
        }
        return totalLength > MAX_LENGTH_HEADING; //if true then it wont go to changeHeadingState method
    };

    const name = fullName(data);

    return (
        <Modal isOpen={isOpen} className='obituary-modal-wrapper'>
            <ModalHeader>
                <i
                    className='close-icon-hg modal-close fas fa-times'
                    onClick={() => {
                        onClose();
                        getObituary(opi);
                    }}
                />
            </ModalHeader>
            <ModalBody align='center'>
                <div className='story-wrapper' align='center'>
                    <div className='profile-wrapper'>
                        <div className='profile-picture-wrapper'>
                            <img
                                src={_.get(data, "pictureUrl") || defaultImage}
                                alt='ProfilePic'
                                className='profile-pic-wrapper'
                            />
                        </div>
                        <div
                            className='profile-thought-wrapper'
                            style={{ display: "flex" }}
                        >
                            <div style={{ width: "calc(100% - 25px)" }}>
                                <div
                                    className={`container-root profile-quote ${
                                        headingFocus && isLoggedIn
                                            ? headingError
                                                ? "errorFocus"
                                                : "hasFocus"
                                            : "noFocus"
                                    }`}
                                >
                                    {isLoggedIn ? (
                                        <Editor
                                            placeholder={`Say something about ${name}...`}
                                            onFocus={() =>
                                                setheadingFocus(true)
                                            }
                                            onBlur={e => postHeadingData(e)}
                                            editorState={headingState}
                                            onChange={event =>
                                                changeHeadingState(
                                                    event,
                                                    headingState
                                                )
                                            }
                                            handleBeforeInput={event =>
                                                changeBeforeInputHeading(
                                                    event,
                                                    headingState
                                                )
                                            }
                                        ></Editor>
                                    ) : (
                                        <div
                                            style={{
                                                textAlign: "left",
                                                whiteSpace: "pre-wrap",
                                            }}
                                            dangerouslySetInnerHTML={{
                                                __html: _.get(data, "title"),
                                            }}
                                        />
                                    )}
                                </div>
                                {headingError ? (
                                    <div
                                        style={{ textAlign: "left" }}
                                        className='errorMessage'
                                    >
                                        {headingErrorMessage}
                                    </div>
                                ) : (
                                    ""
                                )}
                            </div>
                            {headingFocus && isLoggedIn ? (
                                <div className='options'>
                                    <a
                                        className={`${
                                            headingState
                                                .getCurrentInlineStyle()
                                                .has("BOLD")
                                                ? "selection"
                                                : ""
                                        }`}
                                        onMouseDown={e =>
                                            formatText(e, "header", "BOLD")
                                        }
                                    >
                                        {" "}
                                        <img
                                            src={BoldPic}
                                            alt='BoldPic'
                                            className='BoldPic-wrapper'
                                        />
                                    </a>
                                    <a
                                        className={`${
                                            headingState
                                                .getCurrentInlineStyle()
                                                .has("ITALIC")
                                                ? "selection"
                                                : ""
                                        }`}
                                        onMouseDown={e =>
                                            formatText(e, "header", "ITALIC")
                                        }
                                    >
                                        <img
                                            src={ItalicPic}
                                            alt='ItalicPic'
                                            className='ItalicPic-wrapper'
                                        />
                                    </a>
                                    <a
                                        className={`${
                                            headingState
                                                .getCurrentInlineStyle()
                                                .has("UNDERLINE")
                                                ? "selection"
                                                : ""
                                        }`}
                                        onMouseDown={e =>
                                            formatText(e, "header", "UNDERLINE")
                                        }
                                    >
                                        <img
                                            src={UPic}
                                            alt='UPic'
                                            className='UPic-wrapper'
                                        />
                                    </a>
                                </div>
                            ) : (
                                ""
                            )}
                        </div>
                    </div>
                </div>
                <hr />
                <div className='modal-content' align='center'>
                    {editorError ? (
                        <div
                            style={{ textAlign: "left", margin: 5 }}
                            className='errorMessage'
                        >
                            {editorErrorMessage}
                        </div>
                    ) : (
                        ""
                    )}

                    {isLoggedIn && !obituaryIsLocked ? (
                        <TinyMceEditor
                            ref={inputDesRef}
                            changeEditorState={changeEditorState}
                            descriptionData={initialDescriptionData}
                            className={`container-root obituary-quote ${
                                focus && isLoggedIn
                                    ? editorError
                                        ? "errorFocus"
                                        : "hasFocus"
                                    : "noFocus"
                            }`}
                            id='description'
                            postEditorData={postEditorData}
                            placeholder={`Obituary of ${name}...`}
                        />
                    ) : (
                        <div className='container-root obituary-quote'>
                            <div
                                style={{
                                    textAlign: "left",
                                    whiteSpace: "pre-wrap",
                                    fontFamily:'Georgia',
                                    fontSize:'18pt'
                                }}
                                dangerouslySetInnerHTML={{
                                    __html: _.get(data, "obituary"),
                                }}
                            />
                        </div>
                    )}
                </div>
            </ModalBody>
        </Modal>
    );
};

export default Obituary;
