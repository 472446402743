import React, { useCallback, useMemo, useState } from "react";
import { Formik } from "formik";
import _ from "lodash";
import { Form, Tooltip } from "reactstrap";
import useAsync from "react-use-async-hook";

import * as DecedentAPI from "../../../../api/decedent";
import { useSetLoading } from "../../../utils/hooks/loader";
import addNotification from "../../../utils/notification/notification";
import { formatOptions } from "../../../utils/functions";
import {
    certificateFreezeText,
    firstTimeSaveText,
} from "../constants/constants";
import SelectComponent from "../../../utils/SelectComponent/SelectComponent";

const EducationOccupationDetails = ({
    decedent,
    qualifications,
    decedentId,
    isDisable,
    refreshDecedent,
    showCertificateSavePopup,
    enableSubmitButton,
}) => {
    /**
     * Save Education & Occupation Details
     */
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const isFieldAlreadySaved = _.get(decedent, "isFirstSaved");
    const [isFirstSaved, setIsFirstSaved] = useState(isFieldAlreadySaved);
    if (
        typeof isFirstSaved === "undefined" &&
        isFirstSaved !== isFieldAlreadySaved
    ) {
        setIsFirstSaved(isFieldAlreadySaved);
    }

    const showToolTip = disabled => {
        if (isDisable) {
            setTooltipOpen(disabled);
        }
    };
    const { loading: updateLoader, execute: executeUpdate } = useAsync({
        autoExecute: false,
        task: useCallback(
            async (
                values,
                decedentId,
                formikBag,
                isFirstSaved,
                showCertificateSavePopup
            ) => {
                let payload = {
                    occupation: values.occupation,
                    industry: values.industry,
                    yearsOfOccupation: values.yearsOfOccupation,
                    qualification: values.qualification
                        ? {
                              name: values.qualification.value,
                              id: values.qualification.id,
                          }
                        : "",
                };
                return {
                    res: await DecedentAPI.saveEducationDetails(
                        decedentId,
                        payload
                    ),
                    formikBag,
                    isFirstSaved,
                    showCertificateSavePopup,
                };
            },
            []
        ),
        dataLoader: useCallback(
            ({ res, formikBag, isFirstSaved, showCertificateSavePopup }) => {
                formikBag.setSubmitting(false);
                refreshDecedent();
                enableSubmitButton();
                if (isFirstSaved) {
                    addNotification({
                        type: "success",
                        title: "Success",
                        message: firstTimeSaveText,
                    });
                } else {
                    showCertificateSavePopup();
                    setIsFirstSaved(true);
                }
                addNotification({
                    type: "success",
                    title: "Success",
                    message: "Education & Occupational Details Updated!",
                });
            },
            []
        ),
        onError: useCallback(error => {
            addNotification({
                type: "danger",
                title: "Error",
                message: _.get(
                    error,
                    "response.data.message",
                    "Oops! Something went wrong"
                ),
            });
        }, []),
    });

    const showLoader = useMemo(() => updateLoader, [updateLoader]);
    useSetLoading(showLoader);

    return (
        <div className='educationoccupationdetails-wrapper'>
            <div className='cardbox-wrapper'>
                <div className='cardbox-heading'>
                    <h4 className='cardbox-heading-name text-capitalize text-black'>
                        <span className='item-numbering'>4</span>
                        Education and Occupation Details
                    </h4>
                </div>
                <Formik
                    initialValues={{
                        qualificationData: formatOptions(qualifications),
                        qualification: _.get(decedent, "qualification")
                            ? {
                                  label: decedent.qualification,
                                  value: decedent.qualification,
                                  ..._.find(qualifications, {
                                      name: decedent.qualification,
                                  }),
                              }
                            : "",
                        yearsOfOccupation: parseInt(
                            _.get(decedent, "yearsOfOccupation")
                        ),
                        occupation: _.get(decedent, "occupation"),
                        industry: _.get(decedent, "industry"),
                    }}
                    enableReinitialize={true}
                    onSubmit={(values, formikBag) => {
                        executeUpdate(
                            values,
                            decedentId,
                            formikBag,
                            isFirstSaved,
                            showCertificateSavePopup
                        );
                    }}
                >
                    {({
                        values,
                        handleSubmit,
                        setSubmitting,
                        handleBlur,
                        isSubmitting,
                        setFieldValue,
                    }) => {
                        const handleChange = e => {
                            const { name, value } = e.target;
                            setFieldValue(name, value);
                            setSubmitting(false);
                        };
                        return (
                            <Form id='educationForm' onSubmit={handleSubmit}>
                                <div className='inputcard-wrapper cardbox-body'>
                                    <div className='inputcard-body custom-inputcard-body'>
                                        <div className='inputcard-group d-flex align-items-center justify-content-start'>
                                            <div className='choose-reason inputfield d-flex align-items-center justify-content-between'>
                                                <div className='inputcard-withlabel d-flex align-items-start justify-content-center flex-column '>
                                                    <label className='inputcard-label'>
                                                        Education Qualification
                                                    </label>
                                                    <SelectComponent
                                                        placeholder='Select Qualification'
                                                        options={
                                                            values.qualificationData
                                                        }
                                                        defaultValue={
                                                            values.qualification
                                                        }
                                                        selectedOption={
                                                            values.qualification
                                                        }
                                                        name='qualification'
                                                        handleChange={e => {
                                                            setFieldValue(
                                                                "qualification",
                                                                e
                                                            );
                                                            setSubmitting(
                                                                false
                                                            );
                                                        }}
                                                        disabled={isDisable}
                                                    />
                                                </div>
                                            </div>
                                            <div className='choose-reason inputfield d-flex align-items-center justify-content-between'>
                                                <div className='inputcard-withlabel d-flex align-items-start justify-content-center flex-column '>
                                                    <label className='inputcard-label'>
                                                        Occupation
                                                    </label>
                                                    <input
                                                        id='occupation'
                                                        className='custominputfield'
                                                        type='text'
                                                        placeholder='Enter Occupation'
                                                        value={
                                                            values.occupation
                                                        }
                                                        name='occupation'
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        disabled={isDisable}
                                                    />
                                                </div>
                                            </div>
                                            <div className='choose-reason inputfield d-flex align-items-center justify-content-between'>
                                                <div className='inputcard-withlabel d-flex align-items-start justify-content-center flex-column '>
                                                    <label className='inputcard-label'>
                                                        Years in occupation
                                                    </label>
                                                    <input
                                                        id='yearsOccupation'
                                                        className='custominputfield'
                                                        type='number'
                                                        placeholder='Enter Years of Occupation'
                                                        value={
                                                            values.yearsOfOccupation
                                                        }
                                                        name='yearsOfOccupation'
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        disabled={isDisable}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='inputcard-group d-flex align-items-center justify-content-start'>
                                            <div className='choose-reason inputfield d-flex align-items-center justify-content-between'>
                                                <div className='inputcard-withlabel d-flex align-items-start justify-content-center flex-column '>
                                                    <label className='inputcard-label'>
                                                        Industry
                                                    </label>
                                                    <input
                                                        id='industryDetails'
                                                        className='custominputfield'
                                                        type='text'
                                                        placeholder='Enter Industry'
                                                        value={values.industry}
                                                        name='industry'
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        disabled={isDisable}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='inputcard-footer'>
                                        <div
                                            id='educationForm-save'
                                            onPointerEnter={() =>
                                                showToolTip(true)
                                            }
                                            onPointerLeave={() =>
                                                showToolTip(false)
                                            }
                                        >
                                            <button
                                                type='submit'
                                                disabled={
                                                    isDisable || isSubmitting
                                                }
                                                className='btn-theme btn-search-wrapper text-uppercase'
                                            >
                                                SAVE
                                            </button>
                                        </div>
                                        <Tooltip
                                            placement='top-end'
                                            isOpen={tooltipOpen}
                                            target='educationForm-save'
                                            className='tooltip-template'
                                        >
                                            {certificateFreezeText}
                                        </Tooltip>
                                    </div>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            </div>
        </div>
    );
};

export default EducationOccupationDetails;
