import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from "react";
import { Formik } from "formik";
import useAsync from "react-use-async-hook";
import { useSetLoading } from "../utils/hooks/loader";
import { Form,Collapse } from "reactstrap";
import * as yup from "yup";
import addNotification from "../utils/notification/notification";
import * as CustomizeFamilyWebsite from "../../api/customizeFamilyWebsite";
import _ from "lodash";
import Reaptcha from "reaptcha";
import { FormControlLabel, Switch } from "@material-ui/core";
var momentTz = require("moment-timezone");

const ContactFamilyMember = ({
  decedentData,
  opi,
  session,
  isFamilyAccess,
}) => {
  const timeZone = momentTz.tz.guess();
  const [isDisable, setIsDisabled] = useState(true);
  const [formData, setFormData] = useState(null);
  const [errorObject, setErrorObject] = useState(null);
  const [useCaptcha, setUseCaptcha] = useState(false);
  const [isVerfied, setIsVerified] = useState(false);
  const [isOpen,setIsOpen] = useState(false)
  const [isHideContactFamily, setHideContactFamily] = useState({
    hideContactFamily: false,
  });
  const captchaRef = useRef();
  const formRef = useRef()
  const siteKey = process.env?.REACT_APP_SITE_KEY || '6LfRxvYpAAAAAOwb6Se3R1iapOHsHn1Bonx7ytmb'
  useEffect(() => {
    if (!_.isEmpty(decedentData)) {
      setHideContactFamily({
        hideContactFamily: _.get(decedentData, "hideContactFamily"),
      });
    }
  }, [decedentData]);

  useEffect(() => {
    if (useCaptcha) {
      document
        .getElementById("recaptcha")
        .addEventListener("touchstart", { passive: true });
    }
  }, []);

  const verify = async () => {
    captchaRef.current.getResponse().then(async (res) => {
      setIsVerified(true);
      setErrorObject({ 'captcha' : ''})
    });
    setUseCaptcha(false);
    captchaRef?.current?.reset();
  };

  const {
    data: saveMessageData,
    loading: subscriberLoader,
    execute: executeAddMessage,
  } = useAsync({
    autoExecute: false,
    initialData: useMemo(() => null, []),
    task: useCallback((values, opi, timeZone) => {
      return CustomizeFamilyWebsite.saveMessage(values, opi, timeZone);
    }, []),
    dataLoader: useCallback((res) => {
      addNotification({
        type: "success",
        title: "Success",
        message: "Your Message has been sent to the Family Member.",
      });
      setIsVerified(false);
      return _.get(res, "data", []);
    }, []),
    onError: useCallback((error) => {
      addNotification({
        type: "danger",
        title: "Error",
        message: _.get(
          error,
          "response.data.message",
          "Oops! Something went wrong"
        ),
      });
    }, []),
  });

  const {
    data: messagesCount,
    loading: getCountLoader,
    execute: executeGetCountOfMesagesSend,
  } = useAsync({
    autoExecute: false,
    initialData: useMemo(() => null, []),
    task: useCallback((opi,timeZone) => {
      return CustomizeFamilyWebsite.getCount(opi,timeZone);
    }, []),
    dataLoader: useCallback((res) => {
      return _.get(res, "data", []);
    }, []),
    onError: useCallback((error) => {
      addNotification({
        type: "danger",
        title: "Error",
        message: _.get(
          error,
          "response.data.message",
          "Oops! Something went wrong"
        ),
      });
    }, []),
  });

  const {
    data: hideContactFamilyMemberData,
    loading: hideContactFamilyMemberLoader,
    execute: executeHideContactFamily,
  } = useAsync({
    autoExecute: false,
    initialData: useMemo(() => null, []),
    task: useCallback((opi,obj) => {
      return CustomizeFamilyWebsite.hideContactFamily(opi,obj);
    }, []),
    dataLoader: useCallback((res) => {
     return res;
    }, []),
    onError: useCallback((error) => {
      addNotification({
        type: "danger",
        title: "Error",
        message: _.get(
          error,
          "response.data.message",
          "Oops! Something went wrong"
        ),
      });
      setIsVerified(false);
    }, []),
  });

  // useEffect(() => {
  //   if (messagesCount) {
  //     if (_.get(messagesCount, "count") > 3) {
  //       addNotification({
  //         type: "danger",
  //         title: "Error",
  //         message: "Enter Captcha To Send Message",
  //       });
  //       setUseCaptcha(true);
  //     } else {
  //       executeAddMessage(formData, opi, timeZone);
  //     }
  //   }
  // }, [messagesCount]);


  const handleSubmitForm = async (values) => {
    setFormData({ ...values });
    if (isVerfied) {
      await executeAddMessage(formData, opi, timeZone)
      formRef?.current?.resetForm();
      setIsDisabled(true);
    }
  };

  const handleHideContactFamily = (event) => {
    let obj = {
      [event.target.name]: event.target.checked,
    };
    setHideContactFamily(obj);
    executeHideContactFamily(opi,obj);
  };

  useEffect(() => {
    if (_.get(hideContactFamilyMemberData,'data.success')) {
      const status = _.get(isHideContactFamily, "hideContactFamily")
        ? "Hided"
        : "Un Hided";
      addNotification({
        type: "success",
        title: "Success",
        message: `Contact Family Member ${status} Successfully`,
      });
    }
  }, [hideContactFamilyMemberData]);

  const toggleCollapseButton = () => {
    setIsOpen(!isOpen);
  };

  const showLoader = useMemo(
    () => subscriberLoader || getCountLoader || hideContactFamilyMemberLoader,
    [subscriberLoader, getCountLoader, hideContactFamilyMemberLoader]
  );
  useSetLoading(showLoader);

  return (
    <div>
      <div className="d-flex justify-content-between">
        <p className="heading" onClick={toggleCollapseButton}>Contact Family</p>
        {session.isLoggedIn && isFamilyAccess && (
          <div className="hideConatctFamily">
            <FormControlLabel
              control={
                <Switch
                  checked={_.get(isHideContactFamily, "hideContactFamily")}
                  name={"hideContactFamily"}
                  value={_.get(isHideContactFamily, "hideContactFamily")}
                  onChange={handleHideContactFamily}
                  inputProps={{
                    "aria-label": "secondary checkbox",
                  }}
                />
              }
              label={"Hide"}
              labelPlacement="start"
              style={{
                margin: 0,
              }}
            />
          </div>
        )}
        <i
          class={`fas ${
            isOpen ? "fa-chevron-up" : "fa-chevron-down"
          } collapseIcon`}
          onClick={toggleCollapseButton}
        ></i>
      </div>
      <Collapse isOpen={isOpen}>
        <Formik
          innerRef={formRef}
          enableReinitialize
          initialValues={{
            email: "",
            message: "",
          }}
          validateOnBlur={false}
          validateOnChange={true}
          validationSchema={yup.object().shape({
            email: yup
              .string()
              .email("Invalid Email")
              .required("Email Required"),
            message: yup
              .string()
              .max(500, "Messages should less then 501 characters")
              .required("Message is required"),
          })}
          onSubmit={(values) => {
            handleSubmitForm(values);
          }}
        >
          {({
            values,
            errors,
            handleSubmit,
            setFieldValue,
            touched,
            setTouched,
            setSubmitting,
          }) => {
            const handleChange = (e) => {
              setIsDisabled(false);
              if(!isVerfied) setUseCaptcha(true)
              const { name, value } = e.target;
              setFieldValue(name, value);
              setTouched({ ...touched, [name]: true });
              setFormData({ ...values, [name]: value });
              setSubmitting(false);
            };
            return (
              <Form id="subscribeDecedentForm" onSubmit={handleSubmit}>
                <div className="inputcard-wrapper">
                  <div className="inputcard-body custom-inputcard-body">
                    <div className="inputcard-group">
                      <div className="choose-reason inputfield d-flex align-items-center justify-content-between">
                        <div className="inputcard-withlabel d-flex align-items-start justify-content-center flex-column">
                          <input
                            className="custominputfield email"
                            placeholder="Please Add Email"
                            name="email"
                            value={values.email}
                            onChange={handleChange}
                            id="email"
                          />

                          {errors.email && touched.email && (
                            <span className="error-memory-input-msg">
                              {errors.email}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                    <div
                      className="inputcard-group"
                      style={{
                        marginTop:
                          errors?.email && touched?.email ? "10px" : "0px",
                      }}
                    >
                      <div className="choose-reason inputfield d-flex align-items-center justify-content-between">
                        <div className="inputcard-withlabel d-flex align-items-start justify-content-center flex-column">
                          <textarea
                            className="custominputfield message"
                            type="text"
                            id="message"
                            placeholder="Please Add Message"
                            name="message"
                            value={values.message}
                            onChange={handleChange}
                          />

                          {errors.message && touched.message && (
                            <span className="error-memory-input-msg">
                              {errors.message}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {useCaptcha && (
                  <>
                    <Reaptcha
                      id="recaptcha"
                      sitekey={siteKey}
                      ref={captchaRef}
                      onVerify={verify}
                      onExpire={() => {
                        captchaRef.current.reset();
                      }}
                    />
                    <div className='error-captcha-div'>
                      <span className="error-captcha-msg">
                        {errorObject?.['captcha']}
                      </span>
                    </div>
                  </>
                )}
                <button
                  type="submit"
                  className={`send-message save-button ${
                    isDisable ? "disabledSendMessage" : ""
                  }`}
                  disabled={isDisable}
                  onClick={()=>{
                    if(!isVerfied) {
                      setErrorObject({'captcha' : 'verify captcha'})
                    }
                  }}
                  style={{ marginTop: !useCaptcha ? "20px" : "0px" }}
                >
                  Send Message
                </button>
              </Form>
            );
          }}
        </Formik>
      </Collapse>
    </div>
  );
};

export default ContactFamilyMember;
