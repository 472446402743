import _ from 'lodash';
import moment from 'moment';

export const formatDefaultNameAndDates = (decedentInfo) => {
  const firstName = _.get(decedentInfo, 'firstName', '');
  const lastName = _.get(decedentInfo, 'lastName', '');
  const dateOfBirth = _.get(decedentInfo, 'dateOfBirth', '');
  const dateOfDeath = _.get(decedentInfo, 'dateOfDeath', '');
  const defaultFirstName = firstName || 'FirstName';
  const defaultLastName = lastName || 'LastName';
  const defaultName = `${defaultFirstName} ${defaultLastName}`;
  const defaultHeading = `<p class=\"ql-align-center ql-size-fs28\"><span class=\"ql-size-huge ql-font-playball\">${'In Loving Memory of'}</span></p>`;
  const defaultNameWithStyle = `<p class=\"ql-align-center\"><strong class=\"ql-font-cinzel ql-size-fs28\">${defaultName}</strong></p>`;

  const defaultDateOfBirth = dateOfBirth
    ? formatDateOfBirthOrDeath(dateOfBirth)
    : 'Month Day, Year';
  const defaultDateOfDeath = dateOfDeath
    ? formatDateOfBirthOrDeath(dateOfDeath)
    : 'Month Day, Year';
  const defaultDates = `${defaultDateOfBirth} - ${defaultDateOfDeath}`;
  const defaultDatesWithStyle = `<p class=\"ql-align-center\"><strong class=\"ql-font-cinzel ql-size-medium\">${defaultDates}</strong></p>`;

  return { defaultNameWithStyle, defaultDatesWithStyle, defaultHeading };
};

export const formatDateOfBirthOrDeath = (inputDate) => {
  const formattedDate = moment(inputDate).format('MMM DD, YYYY');
  return formattedDate;
};

export const formatDefaultNameAndDatesForProgramCard = (
  decedentInfo,
  addSunriseText = false,
  addCityState = false
) => {
  const firstName = _.get(decedentInfo, 'firstName', '');
  const lastName = _.get(decedentInfo, 'lastName', '');
  const dateOfBirth = _.get(decedentInfo, 'dateOfBirth', '');
  const dateOfDeath = _.get(decedentInfo, 'dateOfDeath', '');
  const defaultFirstName = firstName || 'FirstName';
  const defaultLastName = lastName || 'LastName';
  const defaultNameWithStyle = `${defaultFirstName} ${defaultLastName}`;

  const defaultDateOfBirth = dateOfBirth
    ? formatDateOfBirthOrDeath(dateOfBirth)
    : 'Month Day, Year';
  const defaultDateOfDeath = dateOfDeath
    ? formatDateOfBirthOrDeath(dateOfDeath)
    : 'Month Day, Year';

  const dateOfBirthToShow = addSunriseText
    ? `Sunrise: ${defaultDateOfBirth}`
    : addCityState
    ? `${defaultDateOfBirth}\nCity, State`
    : defaultDateOfBirth;
  const dateOfDeathToShow = addSunriseText
    ? `Sunset: ${defaultDateOfDeath}`
    : addCityState
    ? `${defaultDateOfDeath}\nCity, State`
    : defaultDateOfDeath;

  return { defaultNameWithStyle, dateOfBirthToShow, dateOfDeathToShow };
};

export const toMb = (bytes) => {
  return (bytes / 1024 / 1024).toFixed(2);
};
