import React, {
    useEffect,
    useCallback,
    useMemo,
    useState,
} from "react";
import { Link, useParams, useLocation, Redirect } from "react-router-dom";
import _ from "lodash";
import useAsync from "react-use-async-hook";
import { FormControlLabel, Switch } from "@material-ui/core";
import AppLayout from "../../../appLayout";
import {
    decedentAPI,
    privacyModeAPI,
} from "../../../../api/memories";
import { getObituary } from "../../../../api/obituary";
import { useSetLoading } from "../../../utils/hooks/loader";
import { apiErrorHandler } from "../../../../utils/handlers";
import MemoriesListing from "../memoriesListing/memoriesListing";
import { getAccessToken } from "../../../../utils";
import addNotification from "../../../utils/notification/notification";
import ProfilePictureSection from "../memoriesListing/profilePictureSection";
import BreadCrumb from "../../../utils/breadCrumb/breadCrumb";
import TooltipAndInfo from "../../../TooltipAndInfo";
import Cookies from "universal-cookie";
import * as AuthAPI from "../../../../api/auth";

let currentLat, currentLong;
function showPosition(position) {
    currentLat = position.coords.latitude;
    currentLong = position.coords.longitude;
}

const Dashboard = ({ session }) => {
    const { opi } = useParams();
    const { pathname, search } = useLocation();
    const [loader, setLoader] = useState(false);
    const [isArranger, setIsArranger] = useState(true);
    const [openObituary, setOpenObituary] = useState(false);
    const [redirectToFAA, setBackToFAA] = useState(false);
    const [userData, setUserData] = useState({});
    const [userNotFound, setUserNotFound] = useState(false);
    const [privatePage, setPrivatePage] = useState(false);
    const [isFaaAccess, setFaaAccess] = useState(false);
    const cookies = new Cookies();
    const role =
        cookies.get("role") ||
        localStorage.getItem("role") ||
        sessionStorage.getItem("role");

    const searchParameter = search ? search.slice(search.indexOf("?")) : pathname && pathname.includes("?") ? pathname.slice(pathname.indexOf("?")) : '';

    useEffect(() => {
        if (role === "admin" || role === "cl-arranger") setIsArranger(false);
        else if (role === "arranger") setIsArranger(true)
    }, [role]);

    const {
        data: websiteAccessResponse,
        loading: getwebsiteAccessLoader,
        execute: executeWebsiteAccess,
    } = useAsync({
        autoExecute: false,
        task: AuthAPI.familyWebsiteAccess,
        dataLoader: useCallback(res => {
            setFaaAccess(true)
            //console.log(res,"response");
            return _.get(res, "data");
        }, []),
        onError: useCallback(error => {
            setFaaAccess(false)
            if (
                document.getElementsByClassName("notification-danger").length ==
                0
            ) {
                apiErrorHandler(error);
            }
        }, []),
    });

    useEffect(() => {
        if(opi){
            executeWebsiteAccess(opi)
        }
    },[opi])

    /**
     * Get Memory Listing
     */
    // const {
    //     data: memoriesList,
    //     loading: memoriesLoading,
    //     execute: refreshList,
    // } = useAsync({
    //     task: useCallback((opi, memoryType) => {
    //         return getMemoriesList(opi, memoryType);
    //     }, []),
    //     dataLoader: useCallback(res => {
    //         let result = _.get(res, "data", []);
    //         result = _.isEmpty(result) ? [] : result;
    //         return result;
    //     }, []),
    //     initialData: useMemo(() => null, []),
    //     onError: useCallback(error => {
    //         if (
    //             document.getElementsByClassName("notification-danger").length ==
    //             0
    //         ) {
    //             apiErrorHandler(error);
    //         }
    //     }, []),
    //     autoExecute: false,
    // });

    /**
     * Get Decedent using API
     */
    const {
        data: decedentData,
        loading: decedentAPILoading,
        execute: executeDecedentAPI,
    } = useAsync({
        autoExecute: false,
        initialData: useMemo(() => null, []),
        task: decedentAPI,
        dataLoader: useCallback(response => {
            return _.get(response, "data.decedent", {});
        }, []),
        onError: useCallback(error => {
            if (
                error.response &&
                error.response.status === 403 &&
                !session.isLoggedIn
            ) {
                setPrivatePage(true);
            } else {
                setUserNotFound(true);
            }

            if (
                document.getElementsByClassName("notification-danger").length ==
                0
            ) {
                apiErrorHandler(error);
            }
        }, []),
    });

    /**
     * Fetch Obituary details
     */
    const {
        data: obituary,
        loading: getObituaryLoader,
        execute: executeObituary,
    } = useAsync({
        autoExecute: false,
        task: getObituary,
        dataLoader: useCallback(res => {
            return _.get(res, "data.obituary");
        }, []),
        onError: useCallback(error => {
            if (
                document.getElementsByClassName("notification-danger").length ==
                0
            ) {
                apiErrorHandler(error);
            }
        }, []),
    });

    /**
     * Get initial data
     */
    useEffect(() => {
        if (isFaaAccess) {
            //refreshList(opi);
            executeDecedentAPI(opi);
            executeObituary(opi);
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(showPosition);
            }
        }
    }, [isFaaAccess, opi]);

    const showLoading = useMemo(
        () =>
            decedentAPILoading ||
            getObituaryLoader ||
            loader,
        [decedentAPILoading, getObituaryLoader, loader]
    );
    useSetLoading(showLoading);

    useEffect(() => {
        if (_.get(decedentData, "firstName") === "") {
            setUserData({
                dateOfBirth: _.get(obituary, "dateOfBirth", ""),
                dateOfDeath: _.get(obituary, "dateOfDeath", ""),
                firstName: _.get(obituary, "firstName", ""),
                lastName: _.get(obituary, "lastName", ""),
                middleName: _.get(obituary, "middleName", ""),
                prefix: _.get(obituary, "prefix", ""),
            });
        } else {
            setUserData({ ...decedentData });
        }
    }, [decedentData, obituary]);

    const isPagePrivate =
        privatePage &&
        !_.get(decedentData, "firstName") &&
        !_.get(obituary, "firstName", "");

    const isDecedentInvalid =
        userNotFound &&
        !_.get(decedentData, "firstName") &&
        !_.get(obituary, "firstName", "");

        const [loggedinUserData, setLoggedinUserData] = useState()
        const updateLoggedinData = (userData)=>{
        setLoggedinUserData(userData)
        }

    return redirectToFAA ? (
        <Redirect to='/app' />
    ) : (
        <AppLayout
            decedent={userData || null}
            isPublic='true'
            setLoader={setLoader}
            updateLoggedinData={updateLoggedinData}
        >
            <div className='memory-container container' style={{maxWidth:'1445px'}}>
                {isArranger ? (
                    <BreadCrumb
                        links={[
                            {
                                url: `/app`,
                                description: "Home",
                                heading: "Dashboard",
                            },
                            {
                                url: `/app/${opi}/arrangements`,
                                description: "Arrangements Summary",
                                heading: "Arrangements Summary",
                            },
                            {
                                url: `/app/memories/${opi}`,
                                description: "Obituary",
                                heading: "Obituary",
                                isSpan: true,
                            },
                        ]}
                        showBack={true}
                        customStyling
                    />
                ) : (
                    <BreadCrumb
                        links={[
                            {
                                url: `/admin/decedents`,
                                description: "Home",
                                heading: "Dashboard",
                            },
                            {
                                url: `/admin/${opi}/arrangements`,
                                description: "Arrangements Summary",
                                heading: "Arrangements Summary",
                                search: searchParameter
                            },
                            {
                                url: `/admin/memories/${opi}`,
                                description: "Obituary",
                                heading: "Obituary",
                                isSpan: true,
                            },
                        ]}
                        showBack={true}
                        customStyling
                    />
                )}
                <div className='navigationCenter-body-wrapper'>
                    <div className='memories-wrapper'>
                        {isPagePrivate && (
                            <div className='memories-not-found-content-wrapper'>
                                <h2>Uh, Oh!</h2>
                                <h3>
                                    The page that you have requested is private.
                                    You don't have the permission to view this.
                                </h3>
                            </div>
                        )}
                        {!isPagePrivate && isDecedentInvalid && (
                            <div className='memories-not-found-content-wrapper'>
                                <h2>Uh, Oh!</h2>
                                <h3>
                                    The page that you have requested doesn't
                                    exist.
                                </h3>
                            </div>
                        )}
                        {!isPagePrivate && !isDecedentInvalid && (
                            <>
                                <div className='memories-left-content-wrapper'>
                                    <Actions
                                        isLoggedIn={session.isLoggedIn}
                                        obituary={obituary}
                                        getObituary={executeObituary}
                                        setOpenObituary={() => {
                                            setOpenObituary(true);
                                            executeObituary(opi);
                                        }}
                                        decedentData={decedentData}
                                        pathname={pathname}
                                    />
                                </div>
                                <div className='memories-main-content-wrapper'>
                                    <MemoriesListing
                                        opi={opi}
                                        decedent={decedentData}
                                        obituary={obituary}
                                        isLoggedIn={session.isLoggedIn}
                                        //memoriesList={memoriesList || []}
                                        getObituary={executeObituary}
                                        obituaryIsLocked={
                                            decedentData?.obituaryIsLocked
                                        }
                                        loggedinUserData={loggedinUserData}
                                    />
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </AppLayout>
    );
};

const Actions = ({ isLoggedIn, obituary, setOpenObituary, decedentData,getObituary }) => {
    const { opi } = useParams();
    const isPrivate = _.get(decedentData, "isPrivate", false);
    const [state, setState] = React.useState(isPrivate);

    const [privacyTooltipOpen, setPrivacyTooltipOpen] = useState(false);

    const showPrivacyToolTip = () => {
        setPrivacyTooltipOpen(!privacyTooltipOpen);
    };

    useEffect(() => {
        setState(isPrivate);
    }, [isPrivate]);

    const handleChange = async event => {
        setState(event.target.checked);
        try {
            const data = { isPrivate: event.target.checked };
            const url = privacyModeAPI(opi);
            fetch(url, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${getAccessToken()}`,
                },
                body: JSON.stringify(data),
            })
                .then(response => response.json())
                .then(data => {
                    const status = event.target.checked ? "On" : "Off";
                    addNotification({
                        type: "success",
                        title: "Success",
                        message: `Privacy turned ${status}.`,
                    });
                })
                .catch(error => {
                    if (
                        document.getElementsByClassName("notification-danger")
                            .length == 0
                    ) {
                        apiErrorHandler(error);
                    }
                });
        } catch (error) {
            if (
                document.getElementsByClassName("notification-danger").length ==
                0
            ) {
                apiErrorHandler(error);
            }
        }
    };

    const renderLoggedIn = () => {
        return (
            isLoggedIn && (
                <li className='options-wrapper switch-icon'>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={state}
                                onChange={handleChange}
                                value={state}
                                inputProps={{
                                    "aria-label": "secondary checkbox",
                                }}
                            />
                        }
                        label={"Set Website Private"}
                        labelPlacement='start'
                        style={{ margin: 0 }}
                    />

                    <TooltipAndInfo
                        id='biography-tooltip-icon'
                        showTooltipFn={showPrivacyToolTip}
                        showToolTip={privacyTooltipOpen}
                        clsName='toopltip-space-top7px'
                        tooltipText={
                            "In the event you would like to keep this private and not post to our public website, please set this to Private."
                        }
                    />
                </li>
            )
        );
    };

    return (
        <ul>
            {renderLoggedIn()}
            {obituary && (
                <li
                    style={{
                        borderTop:"1px solid #c1c2c3"
                    }}
                    className='obituary-upload-image'
                >
                    <ProfilePictureSection
                        isLoggedIn={isLoggedIn}
                        opi={opi}
                        data={obituary}
                        getObituary={getObituary}
                        obituaryIsLocked={false}
                    />
                </li>
            )}
        </ul>
    );
};

export default Dashboard;
