import { Col, Modal, ModalBody } from "reactstrap";
import _ from "lodash";
import React, { useState, useRef, useEffect } from "react";
import VideoThumbnail from "../../memoriesListing/memories-files/videoThumbnail";
import Gallery from "react-photo-gallery";
import defaultImage from "../../../../../assets/images/warning.svg";
import Closebutton from "../../../../../assets/images/cancel-button.png";
import AlertMessageForMemory from "../../../../utils/AlertMessage/AlertMessageForMemory";
import PreviousButton from "../../../../../assets/images/prev.png";
import NextButton from "../../../../../assets/images/next.png";
import MobileView from "./mobile-view";
import downloadIcon from "../../../../../assets/images/filledFileDownload.svg";
import SpinnerImage from "../../../../../assets/gifs/spinning-loading.gif";
import rotateIcon from "../../../../../assets/images/rotateIcon.svg";
import deleteIcon from "../../../../../assets/images/deleteIcon.svg";
import RotateImage from "./rotateImageInModalItself";
import ListDocuments from "../../galleryListing/gallery-files/listDocumnets";
import { Tooltip } from "reactstrap";
import { Link } from "react-router-dom";
import { saveAs } from "file-saver";

const Media = ({ file, index, text, openModal, memoryId, deviceWidth }) => {
  const imageRef = useRef(null);
  const fileContainerRef = useRef(null);

  return (
    <div
      onClick={(e) => {
        openModal();
      }}
      key={index}
      ref={fileContainerRef}
      className={`${_.lowerCase(
        file.fileType
      )}-file-div-poc gallery-image-div-color-poc`}
    >
      {file.fileType === "Image" && (
        <>
          <img
            className="image-file-poc"
            src={file.src}
            onError={() => {
              imageRef.current.src = defaultImage;
              fileContainerRef.current.className = `error-div ${_.lowerCase(
                file.fileType
              )}-file-div contain-img`;
            }}
            ref={imageRef}
            style={{ objectFit: "contain" }}
          />
        </>
      )}
      {file.fileType === "Video" && renderVideo(file, index, false, memoryId)}
      {file.fileType === "Audio" && (
        <audio controls className="audio-file" key={file?.id}>
          <source src={file.src} />
        </audio>
      )}
      {/* {text && <h2>{text}</h2>} */}
    </div>
  );
};

const renderVideo = (video, key, isThumbnail, memoryId, setVideoTitle) => {
  return (
    <VideoThumbnail
      video={video}
      isThumbnail={isThumbnail}
      flag={key}
      memoryId={memoryId}
      setVideoTitleData={setVideoTitle}
    />
  );
};

const toolTipInfo = (
  id = "",
  showTooltipFn,
  showToolTip = false,
  tooltipText = "Some text here"
) => {
  return (
    <Tooltip target={id} isOpen={showToolTip} toggle={showTooltipFn}>
      {tooltipText}
    </Tooltip>
  );
};

const FilesModal = ({
  closeModal,
  findNext,
  findPrev,
  hasNext,
  hasPrev,
  file,
  status,
  imageDeletion,
  currentIndex,
  fileCount,
  memoryId,
  setCurrentFile,
  isRotated,
  setRotateImg,
  opi,
  refreshList,
  displaySaveButton,
  setDisplaySaveButton,
}) => {
  const imageRef = useRef(null);
  const fileContainerRef = useRef(null);
  const [deleteImg, setDeleteImg] = useState(false);
  const [deleteFiletooltipOpen, setDeleteFileTooltipOpen] = useState(false);
  const [rotateImagetooltipOpen, setRotateImageTooltipOpen] = useState(false);
  const [downloadImagetooltipOpen, setDownloadImageTooltipOpen] =
    useState(false);
  const [rotation, setRotation] = useState();
  const [disableIcon, setDisableIcon] = useState(true);

  useEffect(() => {
    setDisableIcon(true);
  }, [file]);

  useEffect(() => {
    const rotValue =
      file?.rotationAngle === 360 ? 0 + 90 : file?.rotationAngle + 90;
    setRotation(rotValue);
  }, [isRotated]);

  const toggleDeleteToolTipOpen = () => {
    setDeleteFileTooltipOpen(!deleteFiletooltipOpen);
  };

  const toggleRotateToolTipOpen = () => {
    setRotateImageTooltipOpen(!rotateImagetooltipOpen);
  };

  const toggleDownloadToolTipOpen = () => {
    setDownloadImageTooltipOpen(!downloadImagetooltipOpen);
  };

  useEffect(() => {
    if (isRotated) {
      setRotateImageTooltipOpen(false);
    }
  }, [isRotated]);

  const [videoTitleData, setVideoTitleData] = useState("");
  const result = [];
  let classname;
  for (let i = 0; i < fileCount; i++) {
    if (i === currentIndex) {
      classname = "active";
    } else {
      classname = "dot";
    }
    result.push(<span className={classname}></span>);
  }

  return (
    <Modal
      isOpen={!!file}
      toggle={closeModal}
      size="lg"
      className="images-preview-modal gallery-modal"
    >
      <ModalBody>
        <Col className="hi">
          {file && file.fileType === "Image" && (
            <>
              <div
                id={file ? `${_.lowerCase(file.fileType)}-file-div` : ""}
                ref={fileContainerRef}
                className={file ? `${_.lowerCase(file.fileType)}-file-div` : ""}
                key={file?.id}
              >
                {hasPrev && (
                  <img
                    src={PreviousButton}
                    className="previous-button"
                    onClick={() => {
                      findPrev();
                      setRotation(0);
                    }}
                    alt="previous"
                  ></img>
                )}
                {!isRotated ? (
                  <img
                    className="image-file"
                    alt="gallery"
                    src={file.src}
                    onLoad={() => {
                      setDisableIcon(false);
                    }}
                    onError={() => {
                      imageRef.current.src = defaultImage;
                      fileContainerRef.current.className = `error-div ${_.lowerCase(
                        file.fileType
                      )}-file-div`;
                    }}
                    key={file?.id}
                    ref={imageRef}
                  />
                ) : (
                  <div className="image-file rotateImageModal">
                    <div className="croppedImageEditor rotationEditor-Website">
                      <RotateImage
                        memoryId={memoryId}
                        file={file}
                        isRotated={isRotated}
                        setRotateImg={setRotateImg}
                        opi={opi}
                        refreshList={refreshList}
                        displaySaveButton={displaySaveButton}
                        setDisplaySaveButton={setDisplaySaveButton}
                        rotation={rotation}
                        disableIcon={disableIcon}
                        setDisableIcon={setDisableIcon}
                        setRotation={setRotation}
                        webView={true}
                      />
                    </div>
                  </div>
                )}
                <img
                  src={Closebutton}
                  className="close-button"
                  onClick={closeModal}
                  alt="close-button"
                />
                {hasNext && (
                  <img
                    src={NextButton}
                    className="next-button"
                    onClick={() => {
                      setRotation(0);
                      findNext();
                    }}
                    alt="next"
                  ></img>
                )}
              </div>
            </>
          )}
          {file && file.fileType === "Video" && (
            <>
              <div className="gallery-videos" key={file?.id}>
                {hasPrev && (
                  <img
                    src={PreviousButton}
                    className="previous-button"
                    onClick={findPrev}
                    alt="previous"
                  ></img>
                )}
                <img
                  src={Closebutton}
                  className="close-button"
                  onClick={closeModal}
                  alt="close-button"
                />
                {renderVideo(file, "main", false, file.id, setVideoTitleData)}
                {hasNext && (
                  <img
                    src={NextButton}
                    className="next-button"
                    onClick={findNext}
                    alt="next"
                  ></img>
                )}
              </div>
            </>
          )}
          {file && file.fileType === "Audio" && (
            <div
              className={`gallery-audio-file-div ${
                file ? `${_.lowerCase(file.fileType)}-file-div` : ""
              }
                                `}
              key={file?.id}
            >
              <div className="gallery-audios">
                <audio controls className="audio-file" key={file?.id}>
                  <source src={file.src} />
                </audio>
                {hasPrev && (
                  <img
                    src={PreviousButton}
                    className="previous-button"
                    onClick={findPrev}
                    alt="previous"
                  ></img>
                )}
                <img
                  src={Closebutton}
                  className="close-button"
                  onClick={closeModal}
                  alt="close-button"
                />
                {hasNext && (
                  <img
                    src={NextButton}
                    className="next-button"
                    onClick={findNext}
                    alt="next"
                  ></img>
                )}
              </div>
            </div>
          )}
          {file && file.fileType === "Video" && (
            <div style={{ textAlign: "center" }}>{videoTitleData}</div>
          )}
        </Col>
        <div className="IconsContainer">
          <div className="Index">{`${currentIndex + 1}/${fileCount}`}</div>
          {status && (
            <div className="ListOfIcons">
              <>
                <button
                  disabled={file?.fileType === "Image" ? disableIcon : false}
                  className={`${
                    disableIcon && file?.fileType === "Image"
                      ? "disable-button"
                      : "common-props"
                  }`}
                  onClick={() => {
                    setDeleteImg(true);
                  }}
                >
                  <img
                    src={deleteIcon}
                    className="delete-button"
                    id={`deleteIcon`}
                    alt="delete"
                  />
                </button>
                {toolTipInfo(
                  "deleteIcon",
                  toggleDeleteToolTipOpen,
                  deleteFiletooltipOpen,
                  `Delete ${file?.fileType}`
                )}
              </>
              {file && file.fileType === "Image" && (
                <>
                  <>
                    <button
                      onClick={() => {
                        setCurrentFile(file);
                        setRotateImg(true);
                        if (rotation === 360) {
                          setRotation(0 + 90);
                        } else if (!isRotated && file?.rotationAngle) {
                          setRotation(rotation);
                        } else setRotation(rotation + 90);

                        setTimeout(() => {
                          setDisplaySaveButton(true);
                        }, 1000 * 1);
                      }}
                      disabled={disableIcon}
                      className={`${
                        disableIcon ? "disable-button" : "common-props"
                      }`}
                    >
                      <img
                        src={rotateIcon}
                        className="rotate-button"
                        id={`rotate-icon`}
                        alt="rotate"
                      />
                    </button>
                    {toolTipInfo(
                      `rotate-icon`,
                      toggleRotateToolTipOpen,
                      rotateImagetooltipOpen,
                      `Rotate Image`
                    )}
                  </>
                  <>
                    <button
                      onClick={ async () => {
                        setRotateImg(false);
                        setDisplaySaveButton(false);
                        const fileNameArray = file?.originalUrl
                          ? file?.originalUrl.split("-")
                          : file?.src.split("-");
                        const originalNameArray = fileNameArray
                          ? fileNameArray.slice(1).join("")
                          : "";
                        const removeExtension = originalNameArray
                          ? originalNameArray.split(".")
                          : "";
                        const extension = removeExtension
                          ? removeExtension?.pop()
                          : "jpeg";
                        const finalName = removeExtension
                          ? removeExtension.join("").trim()
                          : "";
                        let response = await fetch(file?.src);
                        let blob = await response?.blob();
                        const blobUrl = URL.createObjectURL(blob);
                        saveAs(
                          blobUrl,
                          `${
                            finalName ? finalName : "MemoryImage"
                          }.${extension}`
                        );
                      }}
                      disabled={disableIcon}
                      className={`${
                        disableIcon ? "disable-button" : "common-props"
                      }`}
                    >
                      <img
                        src={downloadIcon}
                        className="download-button"
                        alt="download"
                        id={`download-icon`}
                      />
                    </button>
                    {toolTipInfo(
                      `download-icon`,
                      toggleDownloadToolTipOpen,
                      downloadImagetooltipOpen,
                      `Download Image`
                    )}
                  </>
                </>
              )}
            </div>
          )}
        </div>
        {deleteImg && (
          <AlertMessageForMemory
            toggleAlertModal={() => {
              setDeleteImg(false);
            }}
            toggleOk={() => {
              imageDeletion(memoryId, file?.id);
              setDeleteImg(false);
            }}
            toggleAlertState
            title={`Are you sure you want to delete this ${file?.fileType}?`}
            successMsg="Once deleted it cannot be revoked"
            showCancelButton={true}
            okButtonText="Delete"
            deleteState={true}
          />
        )}
      </ModalBody>
    </Modal>
  );
};

const NewObituary = ({
  files,
  opi,
  userData,
  obituary,
  memoriesList,
  memoryId,
  imageDeletion,
  refreshList,
  status,
  section,
  session,
  isFamilyAccess
}) => {
  files = files.sort((a, b) => (a.id > b.id ? 1 : -1));
  const [currentIndex, setCurrentIndex] = useState(null);
  const [currentFile, setCurrentFile] = useState();
  const [deviceWidth, setDeviceWidth] = useState();
  const [photos, setPhotos] = useState([]);
  const [media, setMedia] = useState([]);
  const [documentFiles,setDocumentFiles] = useState([]);
  const [imagesArray, setImagesArray] = useState([]);
  const [displaySaveButton, setDisplaySaveButton] = useState(false);
  const [isRotated, setRotateImg] = useState(false);
  const [presentIndex, setPresentIndex] = useState(null);
  const [filesArray, setFilesArray] = useState([]);
  const [finalFilesArray,setFinalFilesArray] = useState([]);
  const [isPhotosLoaded, setIsPhotosLoaded] = useState(false);

  let imageFiles = [];
  let VideoAndAudioFiles = [];
  let documentsData = [];
  useEffect(() => {
    for (const file of files) {
      if (file.fileType === "Image") {
        imageFiles.push(file);
      } else if (file.fileType === "Video" || file.fileType === "Audio") {
        VideoAndAudioFiles.push(file);
      }
      else if (file.fileType === 'Application') {
        documentsData.push(file)
      } 
    }
    setImagesArray(imageFiles);
    setMedia(VideoAndAudioFiles);
    setDocumentFiles(documentsData);
  }, [files]);

  useEffect(() => {
    if (photos.length) {
      setIsPhotosLoaded(true);
    }
  }, [photos]);

  const getImagesDimensions = async () => {
    const imagesData = [];

    for (const file of imagesArray.slice(0, 2)) {
      const img = new Image();
      img.src = file.src;
      await new Promise((resolve) => {
        img.onload = () => {
          imagesData.push({
            originalUrl: file?.originalUrl,
            rotationAngle: file?.rotationAngle,
            src: file.src,
            width: img.width || 1,
            height: img.height || 1,
            fileType: file.fileType,
            id: file?.id,
          });

          resolve();
        };
      });
    }
    setPhotos(imagesData);
  };

  useEffect(() => {
    getImagesDimensions();
  }, [imagesArray]);

  useEffect(() => {
    if (photos?.length === 1 && media?.length >= 0) {
      setFilesArray(files);
    } else if (photos.length > 1) {
      if (imagesArray?.length === 2 && media?.length >= 0) {
        setFilesArray(photos.concat(media));
      } else if (imagesArray.length >= 2 && media.length === 0) {
        setFilesArray(imagesArray);
      } else if (imagesArray.length > 2 && media.length > 0) {
        const photosFileIds = [photos[0].id, photos[1].id];
        let otherMedia = [];
        files.map((file, i) => {
          if (!_.includes(photosFileIds, file.id)) {
            otherMedia.push(file);
          }
        });
        setFilesArray(photos.concat(otherMedia));
      }
    } else if (photos.length === 0 && media.length >= 1) {
      setFilesArray(files);
    }
  }, [files, photos, media]);

  useEffect(()=>{
    if(filesArray?.length) {
      const excludeDocuments = filesArray?.filter((file) => file?.fileType !== 'Application');
      setFinalFilesArray(excludeDocuments)
    }
  },[filesArray])

  useEffect(() => {
    const handleResize = () => {
      setDeviceWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    setDeviceWidth(window.innerWidth);
  }, []);

  const renderFiles = (files) => {
    files = files.sort((a, b) => (a.id > b.id ? 1 : -1));
    let className = "";
    if (files?.length === 1) {
      className =
        files[0].fileType === "Image" ? "single-grid-image" : "single-grid";
    } else if (files?.length >= 2) {
      className = "dual-grid";
    }
    return (
      <div className={`${className}`}>
        {files?.map((file, i) => {
          return i < 1
            ? file && (
                <div
                  className={
                    `${className}` !== "single-grid"
                      ? `grid-layout grid-item`
                      : `grid-item`
                  }
                >
                  <Media
                    file={file}
                    index={i}
                    openModal={() => openModal(i)}
                    memoryId={memoryId}
                  />
                </div>
              )
            : i === 1 && files.length === 2
            ? file && (
                <div className="grid-layout grid-item">
                  <Media
                    file={file}
                    index={i}
                    openModal={() => openModal(i)}
                    memoryId={memoryId}
                  />
                </div>
              )
            : i === 1 &&
              file && (
                <div className="grid-layout grid-item-last">
                  <div className="grid-show-more">
                    <Media
                      file={files[i]}
                      index={i}
                      openModal={() => openModal(i)}
                      memoryId={memoryId}
                    />
                  </div>
                  {file && (
                    <div className="mask-layer">
                      <span
                        className={
                          files.length - 2 < 9 ? "single-digit" : "double-digit"
                        }
                      >
                        + {files.length - 2}
                      </span>
                    </div>
                  )}
                </div>
              );
        })}
      </div>
    );
  };

  const CustomGalleryItem = ({ index, photo, margin }) => {
    const isSecondImage = index === 1 ? true : false;

    return (
      <Link
        to={
          deviceWidth <= 567
            ? {
                pathname: `/gallery/file/${photo?.id}`,
                state: { fileId: photo?.id },
              }
            : "#"
        }
        onClick={() => {
          setCurrentFile(photo);
          localStorage.setItem(
            [photo.id],
            JSON.stringify([photo, index, memoryId])
          );
        }}
        target={deviceWidth <= 567 && "_blank"}
        rel="noopener noreferrer"
      >
        <div
          style={{
            position: "relative",
            margin: `${margin}px`,
          }}
          className="obituaryImages"
          onClick={() => {
            setCurrentFile(photo);
            openModal(index);
          }}
        >
          <img
            key={index}
            width={photo.width}
            height={photo.height}
            src={photo?.src}
            alt={"memoryImage"}
            style={{ maxWidth: "100%", maxHeight: "100%" }}
          />

          {isSecondImage && files?.length - (documentFiles?.length) > 2 && (
            <div className="maskLayerObituary">
              <span
                className={
                  (files?.length - documentFiles?.length) - 2 < 9 ? "single-digit" : "double-digit"
                }
              >
                + {(files.length - documentFiles?.length) - 2}
              </span>
            </div>
          )}
        </div>
      </Link>
    );
  };
  const openModal = (index) => {
    setPresentIndex(index);
    setCurrentIndex(index);
  };

  const closeModal = (e) => {
    if (e != undefined) {
      e.preventDefault();
    }
    setRotateImg(false);
    setDisplaySaveButton(false);
    setCurrentIndex(null);
  };

  const findPrev = (e) => {
    setRotateImg(false);
    setDisplaySaveButton(false);
    setPresentIndex(presentIndex - 1);
    setCurrentIndex(currentIndex - 1);
  };

  const findNext = (e) => {
    setRotateImg(false);
    setDisplaySaveButton(false);
    setPresentIndex(presentIndex + 1);
    setCurrentIndex(currentIndex + 1);
  };
 
  const documentsAllowScrol = {
    maxHeight : '350px',
    overflowY: 'auto',
  }

  return (
    <div className="imagesGallery">
      {deviceWidth < 567 ? (
        <div className="memory-content-poc">
          <div className="obituaryImages-mobileView">
            <MobileView
              files={files}
              documentFiles={documentFiles}
              memoryId={memoryId}
              status={status}
              imageDeletion={imageDeletion}
              opi={opi}
              refreshList={refreshList}
              session={session}
              isFamilyAccess={isFamilyAccess}
            ></MobileView>
          </div>
        </div>
      ) : (
        <div>
          {imagesArray.length === 1 && media.length >= 0 && (
            <div className="memory-content-poc" key={memoryId}>
              <div className="media-container-poc">
                {renderFiles(photos.concat(media))}
              </div>
            </div>
          )}
          {imagesArray.length === 0 && media.length >= 1 && (
            <div className="memory-content-poc" key={memoryId}>
              <div className="media-container-poc">{renderFiles(media)}</div>
            </div>
          )}
          {!isPhotosLoaded && imagesArray.length >= 2 ? (
            <div className="displayLoader">
              <img src={SpinnerImage} alt="spinnerGif" />
            </div>
          ) : (
            <>
              {photos.length > 1 && (
                <Gallery
                  photos={photos.slice(0, 2)}
                  direction="row"
                  renderImage={CustomGalleryItem}
                  key={memoryId}
                />
              )}
            </>
          )}
          {
            documentFiles?.length >= 1 && (
            <div className="displayDocuments"  style={documentFiles?.length > 7 ? documentsAllowScrol : {}} >
              <ListDocuments 
                otherMedia={documentFiles}
                session={session} 
                opi={opi}
                refreshList={refreshList}
                isFamilyAccess={isFamilyAccess}
                memoryId={memoryId}
                callFrom={"obituary"}
                />
            </div>)
          }
          <div className="media-container-poc">
            <FilesModal
              closeModal={closeModal}
              findPrev={findPrev}
              findNext={findNext}
              hasPrev={currentIndex > 0}
              hasNext={currentIndex + 1 < finalFilesArray.length}
              file={finalFilesArray[currentIndex]}
              currentIndex={currentIndex}
              fileCount={finalFilesArray.length}
              status={status}
              imageDeletion={imageDeletion}
              memoryId={memoryId}
              isRotated={isRotated}
              setRotateImg={setRotateImg}
              setCurrentFile={setCurrentFile}
              opi={opi}
              refreshList={refreshList}
              displaySaveButton={displaySaveButton}
              setDisplaySaveButton={setDisplaySaveButton}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default NewObituary;
