import React, { useState, useEffect, useCallback, useMemo, } from "react";
import useAsync from "react-use-async-hook";
import _ from "lodash";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { Formik } from "formik";
import { Form } from "reactstrap";
import InputTag from "../../utils/InputTag";
import addNotification from "../../utils/notification/notification";
import VideoThumbnail from '../../features/familyWebsite/shareMemory/videoThumbnail'
import Closebutton from '../../../assets/images/cancel-button.png'
import * as adminApi from '../../../api/admin'
import * as UserAPI from "../../../api/memories";

import { useSetLoading } from "../../utils/hooks/loader";


const VideoPreview = ({ videoData, setFieldValue, setVideoLinkError }) => {
    const [errorObj, setError] = useState([]);

    useEffect(() => {
        let flag = false;
        _.map(errorObj, obj => {
            if (_.get(obj, "error")) {
                flag = true;
                setVideoLinkError(_.get(obj, "error"));
            }
        });
        if (!flag) {
            setVideoLinkError("");
        }
    }, [JSON.stringify(errorObj)]);

    useEffect(() => {
        let obj = _.map(videoData, (v, i) => ({
            link: v,
            error: _.get(errorObj[i], "error", ""),
        }));
        setError(obj);
    }, [videoData]);



    return videoData.map((videoUrl, key) => {
        return (
            <div className='inputcard-group' align='center'>
                <VideoThumbnail
                    link={videoUrl}
                    handleClose={() => {
                        const filterredVideos = _.filter(
                            videoData,
                            ele => ele !== videoUrl
                        );
                        setFieldValue("videoLink", filterredVideos);
                    }}
                    key={key}
                    setVideoError={setVideoLinkError}
                    setFieldError={msg => {
                        let err = errorObj || [];
                        err[key] = {
                            link: videoUrl,
                            error: msg,
                        };
                        setError(err);
                    }}
                />
            </div>

        );
    });
};


const AddYouTubeOrVimeoLink = ({ memoryId, addUrl,
    setAddUrl, adminView, handleMemoryEdit, opi, refreshList }) => {
    const [videoLinkError, setVideoLinkError] = useState("");
    const [showVideoPreview, setShowVideoPreview] = useState(false);

    const submitVideoUrls = (values) => {
        let filesToSave = [];
        if (values.videoLink) {
            let videos = values.videoLink;
            videos.forEach(video => {
                filesToSave = _.concat(filesToSave, [
                    {
                        fileUrl: video,
                        fileType: "Video",
                    },
                ]);
            });
            if (adminView) {
                executeAddImages(memoryId, { files: filesToSave })
            }
            else {
                executeAddImages(memoryId, opi, { files: filesToSave })
            }

        }
    }

    const closeModal = () => {
        setAddUrl(false)
    }

    //AddImageApi For Admin
    const {
        data: AddImageData,
        loading: UrlsUploadedLoaded,
        execute: executeAddImages

    } = useAsync({
        autoExecute: false,
        initialData: useMemo(() => null, []),
        task: adminView ? adminApi.addImageAPI : UserAPI.addImgAPI,
        dataLoader: useCallback(response => {
            if (response) {
                addNotification({
                    type: "success",
                    title: "Success",
                    message: "Video Uploaded Successfully",
                });
                return response
            }
        }, []),
        onError: useCallback(error => {
            addNotification({
                type: "danger",
                title: "Error",
                message: _.get(
                    error,
                    "response.data.message",
                    "Oops! Something went wrong"
                ),
            });
            closeModal()
        }, []),
    });

    const getMemoriesList = async () => {
        if (adminView) {
            await handleMemoryEdit();
        }
        else {
            await refreshList(opi);
        }
        closeModal()
    }
    useEffect(() => {
        if (AddImageData) {
            getMemoriesList()

        }
    }, [AddImageData])

    const showLoading = useMemo(() => UrlsUploadedLoaded
    [(UrlsUploadedLoaded)]
    );
    useSetLoading(showLoading);

    return (
        <Modal isOpen={addUrl} className="addvideoUrl-modal" toggle={closeModal}>
            <ModalHeader>
                <img
                    src={Closebutton}
                    className='custom-close-button'
                    onClick={() => {
                        closeModal()
                    }}
                    alt='close-button'
                />
            </ModalHeader>
            <ModalBody>
                <div className="cardboxs-wrapper AddVideoUrl-container">
                    <Formik
                        enableReinitialize
                        initialValues={{ videoLink: '' }}
                        onSubmit={values => {
                            if (videoLinkError) {
                                addNotification({
                                    type: "danger",
                                    title: "Error",
                                    message: "Resolve all validation error",
                                });
                            } else {
                                submitVideoUrls(values)
                            }

                        }}
                    >
                        {({

                            values,
                            handleChange,

                            setFieldValue,
                            handleSubmit: onSubmit,
                            errors,
                            touched,
                        }) => {
                            return (
                                <Form
                                    id='primaryForm'
                                    onSubmit={onSubmit}
                                >
                                    <div className='memory-gallery' style={{ padding: '0px' }}>
                                        <section
                                            align='center'
                                            className='video-upload'
                                        >
                                            <label className='label-input'>
                                                Already have video on
                                                YouTube or Vimeo or Tribute? You
                                                can include them in your
                                                memory here.
                                            </label>
                                            <div className='inputcard-group d-flex align-items-center justify-content-start'>
                                                <div className='full-width choose-reason inputfield'>
                                                    <div className='inputtag-field inputcard-withlabel d-flex align-items-center justify-content-center flex-row addVideoLink'>
                                                        <InputTag
                                                            tags={
                                                                values.videoLink ||
                                                                []
                                                            }
                                                            handleError={e =>
                                                                setVideoLinkError(
                                                                    e
                                                                )
                                                            }
                                                            error={
                                                                videoLinkError
                                                            }
                                                            regex={
                                                                /https:\/\/(?:www.)?(vimeo\.com\/(.*)|(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$|tributeslides\.com\/videos\/embedded_video\/(.*)|tributeslides\.com\/tributes\/show\/(.*))/
                                                            }
                                                            // regex={
                                                            //     /https:\/\/(?:www.)?(vimeo\.com\/(.*)|youtu(be\.com|\.be)\/watch\?v=(.*?)|tributeslides\.com\/(.*))/
                                                            // }
                                                            // regex={
                                                            //     /https:\/\/(?:www.)?(?:(vimeo).com\/(.*)|(youtube).com\/watch\?v=(.*?))/
                                                            // }
                                                            handleChange={value => {
                                                                if (
                                                                    !value.length
                                                                ) {
                                                                    setVideoLinkError(
                                                                        ""
                                                                    );
                                                                }
                                                                setFieldValue(
                                                                    "videoLink",
                                                                    value
                                                                );
                                                                setShowVideoPreview(
                                                                    true
                                                                );
                                                            }}
                                                        />
                                                        <button
                                                            type='button'
                                                            className='add-video-button addVideo-mobile-button'
                                                            onClick={e => {
                                                                e.preventDefault();
                                                            }}
                                                        >
                                                            Add Video
                                                        </button>

                                                        {videoLinkError && (
                                                            <span className='error-msg'>
                                                                {
                                                                    videoLinkError
                                                                }
                                                            </span>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                            {showVideoPreview && values.videoLink.length
                                                ? (
                                                  <div className="videoPreviewContainer">
                                                        <VideoPreview
                                                            videoData={
                                                                values.videoLink
                                                            }
                                                            setFieldValue={
                                                                setFieldValue
                                                            }
                                                            setVideoLinkError={
                                                                setVideoLinkError
                                                            }

                                                        />
                                                </div>
                                                ) : (
                                                    ""
                                                )}
                                            <div
                                                className='inputcard-group'
                                                align='center'
                                            >
                                                <button type="submit" className="save-button">Submit</button>
                                            </div>
                                        </section>
                                    </div>
                                </Form>
                            )
                        }}
                    </Formik>
                </div>
            </ModalBody>
        </Modal>
    )

}

export default AddYouTubeOrVimeoLink;