import {
    Modal,
    ModalBody,
    Form,
    FormGroup,
    Label,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
} from "reactstrap";
import React, { useCallback, useState, useEffect } from "react";
import { Formik } from "formik";
import * as yup from "yup";
import { useLocation, Redirect } from "react-router-dom";
import * as AuthAPI from "../../../api/auth";
import _ from "lodash";
import useAsync from "react-use-async-hook";
import addNotification from "../../utils/notification/notification";
import { useHistory } from "react-router";
import Cookies from "universal-cookie";


const LoginForm = ({
    setLoginPopup,
    loggedIn,
    refreshSession,
    opi,
    decedentData,
    isForgotPassword,
    setForgotPassword,
    isFamilyAccess,
    setFamilyAccess
}) => {
    const [showLoginPassword, setShowLoginPassword] = useState(false);
    const [isRemember, setRemember] = useState(false);
    const history = useHistory();
    const location = useLocation();
    const lfirstName = _.camelCase(decedentData?.firstName);
    const llastName = _.camelCase(decedentData?.lastName);
    const decedentName = llastName ? `${lfirstName}-${llastName}-obituary` : `${lfirstName}-obituary`;
    const search = location.search

    const {
        data: websiteAccessResponse,
        loading: getwebsiteAccessLoader,
        execute: executeWebsiteAccess,
    } = useAsync({
        autoExecute: false,
        task: AuthAPI.familyWebsiteAccess,
        dataLoader: useCallback(res => {
            if(res.data.hasAccess === true){
                setFamilyAccess(true)
                addNotification({
                    type: "success",
                    title: "Success",
                    message: "User Logged In Successfully",
                });
            }
            return _.get(res, "data");
        }, []),
        onError: useCallback(error => {
            setFamilyAccess(false)
            addNotification({
                type: "danger",
                title: "Error",
                message: "Unauthorized. Please enter valid credentials",
            });
        }, []),
    });

    const {
        data: loginDetails,
        execute: executeLogIn,
        loading: logInLoader,
        // error: signInError
    } = useAsync({
        autoExecute: false,
        task: useCallback(async (payload, formikBag) => {
            return {
                res: await AuthAPI.doSignIn({
                    payload,
                    role: "arranger",
                }),
                formikBag,
            };
        }, []),
        dataLoader: useCallback(({ res, formikBag }) => {
            const dataObj = _.get(res, "data.data");
            if (dataObj) {
                if (isRemember) {
                    localStorage.setItem("token", _.get(dataObj, "token"));
                    localStorage.setItem("role", _.get(dataObj, "role"));
                } else {
                    sessionStorage.setItem("token", _.get(dataObj, "token"));
                    sessionStorage.setItem("role", _.get(dataObj, "role"));
                }
                if (_.get(dataObj, "role") === "arranger") {
                    <Redirect to={`/name/${decedentName}/${opi}`} />;
                    //window.location.reload(false);
                }
                const cookies = new Cookies();
                cookies.set("token", _.get(dataObj, "token"), { path: "/" });
                cookies.set("role", _.get(dataObj, "role"), { path: "/" });
                const pathState = { ...location, search: search, state: location.state || "" };
                const {
                    state: { referrer },
                } = pathState;
                history.push({
                    pathname: `/name/${decedentName}/${opi}`,
                    search: search
                })
                loggedIn(_.get(dataObj, "role"), _.get(dataObj, "isNewUser"));
            }
            return _.get(res, "data.data");
        }, []),
        onError: useCallback((error) => {
            if (_.get(error, "response.status") === 400) {
                addNotification({
                    type: "danger",
                    title: "Error",
                    message: _.get(
                        error,
                        "response.data.message",
                        'Oops! something went wrong..',
                    ),
                });
                refreshSession();
            }
            else {
                addNotification({
                    type: "danger",
                    title: "Error",
                    message: _.get(
                        error,
                        "response.data.message",
                    ),
                });
                refreshSession();
            }
        }, []),
    });

    useEffect(() => {
        refreshSession();
    }, []);

    // const showLoader = useMemo(() => logInLoader, [logInLoader]);
    // useSetLoading(showLoader);

    useEffect(() => {
        if (loginDetails) {
            if (isRemember) {
                localStorage.setItem("token", _.get(loginDetails, "token"));
                localStorage.setItem("role", _.get(loginDetails, "role"));
            } else {
                sessionStorage.setItem("token", _.get(loginDetails, "token"));
                sessionStorage.setItem("role", _.get(loginDetails, "role"));
            }
            if (_.get(loginDetails, "role") === "arranger") {
                <Redirect to={`/name/${decedentName}/${opi}`} />;
            }
            const cookies = new Cookies();
            cookies.set("token", _.get(loginDetails, "token"), { path: "/" });
            cookies.set("role", _.get(loginDetails, "role"), { path: "/" });
            const pathState = { ...location, state: location.state || "" };
            const {
                state: { referrer },
            } = pathState;
            history.push({
                pathname: `/name/${decedentName}/${opi}`,
                search: search
            })
            loggedIn(
                _.get(loginDetails, "role"),
                _.get(loginDetails, "isNewUser")
            );
        }
    }, [loginDetails]);

    return (
        <Modal isOpen contentClassName="login-modal">
            <ModalBody className="modal-body">
                <i
                    className="fas fa-times close-modal"
                    onClick={() => setLoginPopup(false)}
                ></i>
                <Formik
                    initialValues={{
                        email: "",
                        password: "",
                    }}
                    validationSchema={yup.object({
                        email: yup
                            .string()
                            .email("Invalid Email")
                            .required("Email-Id is required"),
                        password: yup.string().required("Password is required"),
                    })}
                    onSubmit={(values, formikBag) => {
                        executeLogIn(values, formikBag);
                        setLoginPopup(false);
                    }}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleSubmit,
                        handleBlur,
                        handleChange,
                        isSubmitting,
                        setSubmitting,
                        setFieldValue,
                    }) => {
                        const onChange = (e) => {
                            const { name, value } = e.target;
                            setFieldValue(name, value);
                            setSubmitting(false);
                        };

                        return (
                            <Form
                                onSubmit={handleSubmit}
                                className="login-form"
                            >
                                <h1 className="login-heading">
                                    Login to your Family Website
                                </h1>
                                <h1 className="login-text">
                                    Enter your Family Portal credentials
                                </h1>

                                <FormGroup>
                                    <Label for="email" className="form-label">
                                        Email ID
                                    </Label>
                                    <Input
                                        id="email"
                                        name="email"
                                        type="text"
                                        placeholder="Enter email address"
                                        value={values.email}
                                        onBlur={handleBlur}
                                        onChange={onChange}
                                        className="form-input"
                                    />
                                    {errors.email && touched.email ? (
                                        <span className="error-msg">
                                            {errors.email}
                                        </span>
                                    ) : null}
                                </FormGroup>
                                <FormGroup>
                                    <Label
                                        for="password"
                                        className="d-flex justify-content-between align-items-center form-label"
                                    >
                                        Password
                                        <button
                                            className="forgot-password"
                                            onClick={() => {
                                                setForgotPassword(true);
                                            }}
                                        >
                                            Forgot Password?
                                        </button>
                                    </Label>
                                    <InputGroup>
                                        <Input
                                            id="password"
                                            name="password"
                                            placeholder="Enter Password"
                                            type={
                                                showLoginPassword
                                                    ? "text"
                                                    : "password"
                                            }
                                            value={values.password}
                                            onBlur={handleBlur}
                                            onChange={onChange}
                                            className="form-input"
                                        />
                                        <InputGroupAddon addonType="append">
                                            <InputGroupText className="icon-text">
                                                {!showLoginPassword ? (
                                                    <i
                                                        className="fas fa-eye"
                                                        onClick={() =>
                                                            setShowLoginPassword(
                                                                true
                                                            )
                                                        }
                                                    ></i>
                                                ) : (
                                                    <i
                                                        className="fas fa-eye-slash"
                                                        onClick={() =>
                                                            setShowLoginPassword(
                                                                false
                                                            )
                                                        }
                                                    ></i>
                                                )}
                                            </InputGroupText>
                                        </InputGroupAddon>
                                    </InputGroup>
                                    {errors.password && touched.password ? (
                                        <span className="error-msg">
                                            {errors.password}
                                        </span>
                                    ) : null}
                                </FormGroup>
                                <FormGroup className="checkbox-label">
                                    <Input
                                        type="checkbox"
                                        name="rememberMe"
                                        onChange={() =>
                                            setRemember(!isRemember)
                                        }
                                    />
                                    <Label>Remember Credentials</Label>
                                </FormGroup>
                                <button type="submit" className="signin-button">
                                    Sign In
                                </button>
                            </Form>
                        );
                    }}
                </Formik>
            </ModalBody>
        </Modal>
    );
};

export default LoginForm;
