import React, { useEffect, useState, useCallback, useMemo } from "react";
import addNotification from "../../../utils/notification/notification";
import useAsync from "react-use-async-hook";
import { Form } from "reactstrap";
import { Formik } from "formik";
import UploadMedia from "../uploadMedia";
import { shareMediaAPI } from "../../../../api/memories";
import * as yup from "yup";
import _ from "lodash";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { useSetLoading } from "../../../utils/hooks/loader";
import { fullName } from "../../../../utils/global";
import TinyMceEditor from "../../../common/tinyMceEditor/TinyMceEditor";
import { getPlainText } from "../../../utils/functions";

const UploadOnlyMediaModal = ({ opi, decedentData, refreshList }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [videoLinkError, setVideoLinkError] = useState("");
  const [mediaToUpload, setMedia] = useState([]);
  const [progress, setProgress] = useState(0);
  const [finalDataObj, steFinalDataObj] = useState(null);

  const name = fullName(decedentData);

  const onClose = () => {
    setIsOpen(false);
    setMedia([]);
  };
  const requestObject = (values, files) => {
    let filesToSave = [];
    filesToSave = files.map(({ file, ...rest }) => rest);
    if (values.videoLink) {
      let videos = values.videoLink;
      videos.forEach((video) => {
        filesToSave = _.concat(filesToSave, [
          {
            fileUrl: video,
            fileType: "Video",
          },
        ]);
      });
    }
    return {
      title: values.title,
      memory: values.memory,
      memoryType: 9,
      files: filesToSave,
    };
  };

  /**
   * Share memory API
   */
  const {
    data: shareMediaData,
    loading: shareMediaAPILoading,
    execute: performShareMediaAPI,
  } = useAsync({
    autoExecute: false,
    initialData: useMemo(() => null, []),
    task: useCallback((opi, requestObj) => shareMediaAPI(opi, requestObj), []),
    dataLoader: useCallback((response) => {
      addNotification({
        type: "success",
        title: "Success",
        message: "Media Shared Successfully.",
      });
      setIsOpen(false);
      return _.get(response, "data");
    }, []),
    onError: useCallback((error) => {
      addNotification({
        type: "danger",
        title: "Error",
        message: _.get(
          error,
          "response.data.message",
          "Oops! Something went wrong"
        ),
      });
    }, []),
  });

  useEffect(() => {
    if (_.get(shareMediaData, "id")) {
      refreshList(opi);
    }
  }, [shareMediaData]);

  const handleShareMedia = async (requestObj) => {
    await performShareMediaAPI(opi, requestObj);
    setMedia([]);
  };

  const showLoader = useMemo(
    () => shareMediaAPILoading,
    [shareMediaAPILoading]
  );
  useSetLoading(showLoader);

  return (
    <div className="uploadMediaWrapper">
      <button
        className="share-memory-button"
        onClick={() => {
          setIsOpen(true);
        }}
      >
        Upload Media
      </button>
      <Modal isOpen={isOpen} className="uploadMediaModal">
        <ModalHeader>
          {"Share A Memory "}
          <i className="modal-close fas fa-times" onClick={() => onClose()} />
        </ModalHeader>
        <ModalBody>
          <Formik
            enableReinitialize
            initialValues={{
              videoLink: [],
              title: "",
              memory: "",
            }}
            validationSchema={yup.object().shape({
              videoLink: yup.string("Enter valid data"),
              title: yup
                .string()
                .trim()
                .max(254, "Memory title should be less than 255 characters"),
              memory: yup
                .string()
                .trim()
                .test(
                  'max-plain-text-length',
                  'Memory should be less than 5000 characters',
                  function (value) {
                    const plainText = getPlainText(value || '');
                    return plainText.length <= 5000;
                  }
                )
            })}
            onSubmit={(values) => {
              const obj = requestObject(values, mediaToUpload);
              steFinalDataObj(obj);
              if (videoLinkError) {
                addNotification({
                  type: "danger",
                  title: "Error",
                  message: "Resolve all validation error",
                });
              } else {
                handleShareMedia(obj);
              }
            }}
          >
            {(formik) => {
              const {
                values,
                handleChange,
                setFieldValue,
                setSubmitting,
                handleSubmit,
                isSubmitting,
                errors,
                touched,
              } = formik;
              return (
                <Form id="primaryForm" onSubmit={handleSubmit}>
                  <div className="cardboxs-wrapper inputcard-wrapper">
                    <div className="inputcard-body custom-inputcard-body">
                      <div
                        className="inputcard-group d-flex align-items-center"
                        style={{ paddingTop: "0px" }}
                      >
                        <div className="choose-reason inputfield d-flex align-items-center justify-content-between share-memory-title">
                          <div className="inputcard-withlabel d-flex justify-content-center flex-column your-memory-wrapper">
                            <label className="inputcard-label">
                              Memory title
                            </label>
                            <input
                              className="custominputfieldforMemory"
                              type="text"
                              placeholder="Enter the title of memory"
                              name="title"
                              onChange={handleChange}
                            />

                            {errors.title && touched.title && (
                              <span className="error-memory-input-msg">
                                {errors.title}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="choose-reason inputfield tinymce-container">
                      <label className="inputcard-label memory-label-text">
                        Your Memory of {name}
                      </label>
                      <TinyMceEditor
                        height={350}
                        width={"auto"}
                        placeholder={"Enter your memory.."}
                        name={"shareMemory"}
                        setFieldValue={setFieldValue}
                        sizeLimit={5000}
                      />
                      {errors.memory &&
                        (touched.memory || values.memory.length > 5000) && (
                          <span className="error-memory-input-msg">
                            {errors.memory}
                          </span>
                        )}
                    </div>
                    <div id="preview-img"></div>
                    <UploadMedia
                      values={values}
                      setFieldValue={setFieldValue}
                      mediaToUpload={mediaToUpload}
                      setMedia={setMedia}
                      progress={progress}
                      setProgress={setProgress}
                      videoLinkError={videoLinkError}
                      setVideoLinkError={setVideoLinkError}
                      acceptAllMedia={true}
                    />
                    <div className="shareMedia">
                      <button
                        type="submit"
                        disabled={
                          (progress > 0 && progress < 100) ||
                          (_.isEmpty(mediaToUpload) &&
                            values?.title === "" &&
                            values?.memory === "" &&
                            values?.videoLink?.length === 0)
                        }
                        className="share-button add-video-button"
                      >
                        Share
                      </button>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default UploadOnlyMediaModal;
