import React, {
    useEffect,
    useCallback,
    useMemo,
    useState,
    useTransition,
    Suspense,
    lazy,
} from "react";
import { useParams, useLocation, Redirect } from "react-router-dom";
import _ from "lodash";
import moment from "moment";
import useAsync from "react-use-async-hook";
import PublicAppLayout from "../../../publicAppLayout";
import { HelmetProvider, Helmet } from 'react-helmet-async';
import { doLogout, handleAuthLogout } from "../../../../utils";
import {
    getMemoriesList,
    decedentAPI,
    hideMemoryAPI,
} from "../../../../api/memories";
import * as AuthAPI from "../../../../api/auth";
import * as UserAPI from "../../../../api/memories";
import { getObituary } from "../../../../api/obituary";
import { useSetLoading } from "../../../utils/hooks/loader";
import { apiErrorHandler } from "../../../../utils/handlers";
import MemoriesListing from "../memoriesListing/memoriesListing";
import Sidebar from "../../../website/leftPanel.js";
import { memoryTypes } from "../../../utils/variables";
import NewObituary from "../memoriesListing/memories-files/newMemoryFiles";
//  import GalleryFiles from "../galleryListing/gallery-files/gallery-files";
import MemoriesFilters from "../memoriesListing/memories-filters";
// import ShareMemory from "../shareMemory/index";
import UpcomingEvents from "../upcomingEvents";
import { getPolicyAcceptance } from "../../../../utils";
import { FormControlLabel, Switch } from "@material-ui/core";
import ApproveMemories from "../approveMemories";
import ReactGA from "react-ga4";
import addNotification from "../../../utils/notification/notification";
import UploadOnlyMediaModal from "./uploadOnlyMediaModal.js";
import EXIF from 'exif-js';
import AlertMessageForMemory from "../../../utils/AlertMessage/AlertMessageForMemory";
import { msalInstance } from '../../../../index.js'
import Cookies from "universal-cookie";
import RedirectToTributeStore from "../redirectToTributeStore.js";
const ShareMemory = lazy(() => import('../shareMemory/index'));
// const GalleryFiles = lazy(() => import('../galleryListing/gallery-files/gallery-files'));
const NewGallery = lazy(() => import('../galleryListing/gallery-files/new-gallery-files'));
const NewObituaryFile = lazy(()=> import ('../memoriesListing/memories-files/newObituarySection.js'));

let currentLat, currentLong;

function showPosition(position) {
    currentLat = position.coords.latitude;
    currentLong = position.coords.longitude;
}

ReactGA.initialize([
  {
    trackingId: process.env.REACT_APP_Google_Analytics_Id,
    gaOptions: { name: "faaAnalyticsTracker", send_page_view: false },
    testMode:
      process.env.REACT_APP_HOST !== "https://cypresslawn.com/obituaries" ? true : false,
  },
]);

const Dashboard = ({ session, loggedIn, refreshSession, sessionExpired }) => {

    const { opi } = useParams();
    const [loader, setLoader] = useState(false);
    const [redirectToFAA, setBackToFAA] = useState(false);
    const [userData, setUserData] = useState({});
    const [userNotFound, setUserNotFound] = useState(false);
    const [privatePage, setPrivatePage] = useState(false);
    const [memoryType, setMemoryType] = useState([]);
    const [acceptPolicy, setAcceptPolicies] = useState(false);
    const [isApproved, setIsApproved] = useState(false);
    const [currentMemory, setMemoryId] = useState(null)
    const [loadPdf, setLoadPdf] = useState(false)
    const [isPending, startTransition] = useTransition();
    const [isPendingShreMemory, startTransitionShareMemory] = useTransition();
    const [isPendingApproveMemory, startTransitionApproveMemory] = useTransition();
    const [loadApproveMemories,setLoadApproveMemories] = useState(false);
    const [loadShareMemory,setLoadShareMemory] = useState(false);
    const [obituaryData,setObituaryData] = useState();
    const [obituaryText,setObituaryText] = useState('')
    const [photos, setPhotos] = useState([]);
    const [allMemoriesImageFiles, setAllMemoriesImageFiles] = useState([]);
    const [allMemoriesPhotos, setAllMemoriesPhotos] = useState([]);
    const [otherMediaFiles,setOtherMediaFiles] = useState([]);
    const [imagesArray, setImagesArray] = useState([]);
    const [media, setMedia] = useState([]);
    const [galleryAllMedia, setGalleryAllMedia] = useState([])
    const [deviceWidth,setDeviceWidth] = useState();
    const location = useLocation();
    const isAzureMFAEnabled = process.env.REACT_APP_IS_AZURE_MFA_ENABLED;
    const cookies = new Cookies();
  
    useEffect(() => {
      if (session?.isLoggedIn && sessionExpired) {
        const role = cookies.get("role");
        const logoutRedirectUrl =
          process.env.REACT_APP_HOST !== "https://temp.clcafamily.com"
            ? `/obituaries${location?.pathname}`
            : `${location?.pathname}`;
        if (
          isAzureMFAEnabled &&
          msalInstance?.getAllAccounts()?.length >= 1
        )
          handleAuthLogout(
            msalInstance,
            msalInstance?.getAllAccounts(),
            logoutRedirectUrl,
            true
          );
        doLogout();
        window.location.reload();
      }
    }, [sessionExpired]);

    const handlePolicies = () => {
        let haveAcceptPolicy = getPolicyAcceptance();
        if (!haveAcceptPolicy) {
            setAcceptPolicies(true);
        }
    };

    const handleFilters = filters => {
        setMemoryType(filters);
        refreshList(opi, filters);
    };
    const handleCancelShareMemory = () => {
        setAcceptPolicies(false);
        setShareMemory(false);
    };
    const [shareMemory, setShareMemory] = useState(false);
    const [activeTab, setActiveTab] = useState("obituary");
    const [hideMemory, sethideMemory] = useState({});
    const [isFamilyAccess, setFamilyAccess] = useState(false);

    const {
        data: websiteAccessResponse,
        loading: getwebsiteAccessLoader,
        execute: executeWebsiteAccess,
    } = useAsync({
        autoExecute: false,
        task: AuthAPI.familyWebsiteAccess,
        dataLoader: useCallback(res => {
            //console.log(res,"response");
            return _.get(res, "data");
        }, []),
        onError: useCallback(error => {
            setFamilyAccess(false)
            if (
                document.getElementsByClassName("notification-danger").length ==
                0
            ) {
                apiErrorHandler(error);
            }
        }, []),
    });

    useEffect(() => {
        if(opi){
            executeWebsiteAccess(opi)
        }
    },[session?.isLoggedIn])
    /**
     * Get Memory Listing
     */
    const {
        data: memoriesList,
        loading: memoriesLoading,
        execute: refreshList,
    } = useAsync({
        task: useCallback((opi, memoryType) => {
            return getMemoriesList(opi, memoryType);
        }, []),
        dataLoader: useCallback(res => {
            let result = _.get(res, "data", []);
            result = _.isEmpty(result) ? [] : result;
            return result;
        }, []),
        initialData: useMemo(() => null, []),
        onError: useCallback(error => {
            if (
                document.getElementsByClassName("notification-danger").length ==
                0
            ) {
                apiErrorHandler(error);
            }
        }, []),
        autoExecute: false,
    });

    let galleryFiles;
    let pdfImages = [];

    useEffect(() => {
      if (memoriesList) {
        galleryFiles = memoriesList ? memoriesList.flatMap((x) => x.files) : [];
        const documnetFiles = []
        memoriesList.map((memory, i) => {
          const imageFiles = []
          memory?.files?.map((file, i) => {
            if(file?.fileType === 'Image') {
              imageFiles.push(file)
            }
            else if (file?.fileType === 'Application') {
              const newFile = {...file, memoryId: memory?.id}
              documnetFiles.push(newFile)
            }
          })
          if (imageFiles.length >= 1) {
            pdfImages.push({ memoryId: memory.id, imageFiles: imageFiles });
          }
        });
        setOtherMediaFiles(documnetFiles)
        setAllMemoriesImageFiles(pdfImages);
        setGalleryAllMedia(galleryFiles);
      }
    }, [memoriesList]);

    const callGetImageDimentions = async (memory) => {
      const eachMemoryPhotos = await getImagesDimensions(
        memory.imageFiles
      );
      return eachMemoryPhotos;
    };

    const getEachMemoryPhotos = async (filesArray) => {
      let arr = [];
      filesArray.forEach((memory) => {
        if (memory?.imageFiles?.length >= 1) {
          arr.push(memory);
        }
      });
      arr = await Promise.all(
        arr.map(async (memory) => {
          const eachMemoryPhotos = await callGetImageDimentions(memory);
          return {
            memoryId: memory.memoryId,
            photos: eachMemoryPhotos,
          };
        })
      );
      setAllMemoriesPhotos(arr);
    };


    useEffect(() => {
      if (allMemoriesImageFiles.length >= 1) {
        getEachMemoryPhotos(allMemoriesImageFiles);
      }
    }, [allMemoriesImageFiles]);

    let imageFiles = [];
    let VideoAndAudioFiles = [];

    useEffect(() => {
      if(galleryAllMedia?.length > 0) {
        for (const file of galleryAllMedia) {
          if (file.fileType === "Image") {
            imageFiles.push(file);
          } else if (file.fileType === "Video" || file.fileType === "Audio") {
            VideoAndAudioFiles.push(file);
          }
        }
        setImagesArray(imageFiles);
        setMedia(VideoAndAudioFiles);
      }
    }, [galleryAllMedia]);

    const callGetImageDimentionsForGallery = async (imageFiles) => {
      const imagesData = [];
      for (const file of imageFiles) {
        const img = new Image();
        img.src = file.src;
        const imageData = await new Promise((resolve) => {
          img.onload = () => {
            resolve({
              src: file.src,
              id: file.id,
              width: img.width || 1,
              height: img.height || 1,
              fileType: file.fileType,
            });
          };
        });
        imagesData.push(imageData);
      }
      setPhotos(imagesData);
      return imagesData;
    };

    const rotateImage = (imageSrc, rotation) => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.setAttribute("crossOrigin", "anonymous");
        img.src = imageSrc;
        img.onload = function () {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");

          if (rotation === 90 || rotation === 270 || rotation === -90) {
            canvas.width = img.height;
            canvas.height = img.width;
          } else {
            canvas.width = img.width;
            canvas.height = img.height;
          }

          switch (rotation) {
            case 90:
              ctx.rotate((rotation * Math.PI) / 180);
              ctx.drawImage(img, 0, -canvas.width);
              break;
            case 180:
              ctx.rotate((rotation * Math.PI) / 180);
              ctx.drawImage(img, -canvas.width, -canvas.height);
              break;
            case 270:
              ctx.rotate((rotation * Math.PI) / 180);
              ctx.drawImage(img, -canvas.height, 0);
              break;
            default:
              ctx.drawImage(img, 0, 0);
              break;
          }

          return resolve(canvas.toDataURL("image/jpeg"));
        };
        img.onerror = function () {
          reject(new Error("Failed to load image"));
        };
      });
    };

    const getImagesDimensions = async (imageFiles) => {
      const orientationPromises = imageFiles.map((file) => {
        const img = new Image();
        img.src = file.src;
        return new Promise((resolve) => {
          img.onload = () => {
            EXIF.getData(img, () => {
              const imageOrientation = EXIF.getTag(img, "Orientation") || 1;
              resolve({
                fileType: file.fileType,
                src: file.src,
                id: file.id,
                orientation: imageOrientation,
              });
            });
          };
        });
      });

      const orientations = await Promise.all(orientationPromises);
      const imagesData = await Promise.all(
        orientations.map(async (data) => {
          let img = new Image();
          img.src = data.src;
          const orientationValues = [3, 5, 6, 7, 8];
          if (_.includes(orientationValues, data?.orientation)) {
            const rotatedCanvas = await rotateImage(img.src, 0);
            img.src = rotatedCanvas;
          }
          return new Promise((resolve) => {
            img.onload = () => {
              resolve({
                src: img.src,
                id: data.id,
                fileType: data.fileType,
                width: img.width || 1,
                height: img.height || 1,
              });
            };
          });
        })
      );
      return imagesData;
    };

    useEffect(() => {
      callGetImageDimentionsForGallery(imagesArray);
    }, [imagesArray]);

    //galleryFiles = galleryFiles.filter(file => !file.src.includes("tribute"))
    /**
     * Get Decedent using API
     */
    const {
        data: decedentData,
        loading: decedentAPILoading,
        execute: executeDecedentAPI,
    } = useAsync({
        autoExecute: false,
        initialData: useMemo(() => null, []),
        task: decedentAPI,
        dataLoader: useCallback(response => {
            return _.get(response, "data.decedent", {});
        }, []),
        onError: useCallback(error => {
            const errorObject = _.get(error,'response');
            if (
                error.response &&
                error.response.status === 403 &&
                !session.isLoggedIn
            ) {
                setPrivatePage(true);
            } 
            else if (errorObject && (_.get(errorObject.data,'message') !== 'Session Expired' && _.get(errorObject,'status') === 401))
            {
                setUserNotFound(true);
            }
            else if (errorObject && (_.get(errorObject.data,'message') === 'Decedent does not exist' && _.get(errorObject,'status') === 404) ) {
                setUserNotFound(true)
            }
            if (
                document.getElementsByClassName("notification-danger").length ==
                0
            ) {
                apiErrorHandler(error);
            }
        }, [session.isLoggedIn]),
    });

    /**
     * Fetch Obituary details
     */
    const {
        data: obituary,
        loading: getObituaryLoader,
        execute: executeObituary,
    } = useAsync({
        autoExecute: false,
        initialData: useMemo(() => null, []),
        task: getObituary,
        dataLoader: useCallback(res => {
            setObituaryData(_.get(res, "data.obituary",{}))
            return _.get(res, "data.obituary",{});
        }, []),
        onError: useCallback(error => {
            setObituaryData(null)
            if (
                document.getElementsByClassName("notification-danger").length ==
                0
            ) {
                apiErrorHandler(error);
            }
        }, []),
    });
    /**
     * API to hide Memory
     */
    const {
        data: hideMemoryDetail,
        loading: hideMemoryLoader,
        execute: executeHideMemory,
    } = useAsync({
        autoExecute: false,
        task: useCallback(hideMemory => {
            return hideMemoryAPI(opi, hideMemory);
        }, []),
        dataLoader: useCallback(res => {
            return _.get(res, "data");
        }, []),
        onError: useCallback(error => {
            if (
                document.getElementsByClassName("notification-danger").length ==
                0
            ) {
                apiErrorHandler(error);
            }
        }, []),
    });

    useEffect(()=>{
        if(websiteAccessResponse){
            setFamilyAccess(websiteAccessResponse.hasAccess)
        }
    },[websiteAccessResponse])

    /**
     * case if having query params
     */

    useEffect(() => {
        const search =
            location.search ||
            location.pathname.slice(location.pathname.indexOf("?"));
        const queryparamUrl = new URLSearchParams(search).get("memory");
        if(queryparamUrl && session.isLoggedIn && isFamilyAccess){
            setActiveTab("approvememories")
            setLoadApproveMemories(true)
        }
    }, [isFamilyAccess, location, session]);

    /**
     * Get initial data
     */
    useEffect(() => {
        if (opi && activeTab === "obituary") {
            //refreshList(opi);
            executeDecedentAPI(opi);
            executeObituary(opi);
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(showPosition);
            }
        }
    }, [activeTab, session?.isLoggedIn]);

    useEffect(() => {
      if (opi && activeTab !== "obituary") {
        executeDecedentAPI(opi);
        executeObituary(opi);
      }
    }, [session?.isLoggedIn]);

    useEffect(() => {
        if(activeTab === "gallery") {
            refreshList(opi)
        }
    }, [activeTab])

    useEffect(() => {
        if (hideMemoryDetail) {
            refreshList(opi);
        }
    }, [hideMemoryDetail]);

    /* Un Approve Memory */
    const {
        data: unApproveMemoryData,
        loading: unApproveMemoryLoading,
        execute: unApproveMemoryApi,
    } = useAsync({
        task: useCallback((opi, memoryId, status) => {
            
            return UserAPI.aceeptOrrejectMemory(opi, memoryId, status);
        }, []),
        dataLoader: useCallback((res) => {
            addNotification({
                type: "success",
                title: "Success",
                message: "Memory Un Approved Successfully",
            });
            return res;
        }, []),
        onError: useCallback(error => {
            if (
                document.getElementsByClassName("notification-danger").length ==
                0
            ) {
                apiErrorHandler(error);
            }
        }, []),
        autoExecute: false,
    });

    useEffect(() => {
        if (unApproveMemoryData) {
            refreshList(opi);
        }
    }, [unApproveMemoryData, opi, refreshList]);
    const showLoading = useMemo(
        () =>
            memoriesLoading ||
            decedentAPILoading ||
            getObituaryLoader ||
            hideMemoryLoader ||
            unApproveMemoryLoading ||
            loader,
        [
            memoriesLoading,
            decedentAPILoading,
            getObituaryLoader,
            hideMemoryLoader,
            unApproveMemoryLoading,
            loader,
        ]
    );
    useSetLoading(showLoading);
    useEffect(() => {
        if (!(_.isEmpty(decedentData) && session?.isLoggedIn)) {
            const selectedName = _.get(obituary, "displayNameObject") && Object.keys(_.get(obituary, "displayNameObject"));
            if (_.get(decedentData, "firstName") === "") {
                setUserData({
                    dateOfBirth: _.get(obituary, "dateOfBirth", ""),
                    dateOfDeath: _.get(obituary, "dateOfDeath", ""),
                    firstName: _.get(obituary, "firstName", ""),
                    lastName: _.get(obituary, "lastName", ""),
                    middleName: _.get(obituary, "middleName", ""),
                    prefix: _.get(obituary, "prefix", ""),
                });
            } else {
                setUserData({ ...decedentData });
            }
            const parser = new DOMParser();
            if (_.get(obituary, 'obituary')) {
                const htmlDoc = parser.parseFromString(_.get(obituary, 'obituary'), 'text/html');
                const plainText = htmlDoc.body.textContent;
                setObituaryText(plainText.substring(0, 160));
            }
            else {
                setObituaryText("Obituary information is not available")
            }
        }
    }, [decedentData, obituary]);

    const isPagePrivate =
        privatePage &&
        !_.get(decedentData, "firstName") &&
        !_.get(obituaryData, "firstName", "");


    const isDecedentInvalid =
        userNotFound &&
        !_.get(decedentData, "firstName") &&
        !_.get(obituary, "firstName", "");

    useEffect(() => {
      if (decedentData) {
        ReactGA.send({
          hitType: "pageview",
          page: window.location.pathname + window.location.search,
          title: _.get(decedentData, 'pageTitle'),
        });
      }
    }, [decedentData]);
    
    useEffect(() => {
      const handleResize = () => {
        setDeviceWidth(window.innerWidth);
      };
      window.addEventListener("resize", handleResize);
      setDeviceWidth(window.innerWidth);
    }, []);
  
    const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
    };

    return redirectToFAA ? (
      <Redirect to='/app' />
    ) : (
      <HelmetProvider>
        <div>
          <Helmet>
            <title>{_.get(decedentData, 'pageTitle')}</title>
            <meta name="description" content={obituaryText} />
          </Helmet>
          <PublicAppLayout
            decedent={userData || null}
            isPublic='true'
            showAcceptPolicy={acceptPolicy}
            setLoader={setLoader}
            session={session}
            isFamilyAccess={isFamilyAccess}
            handleDecline={handleCancelShareMemory}
            isPagePrivate={isPagePrivate}
            isLoading={showLoading}
            userNotFound={userNotFound}
            noMargin
            showLogofooter
          >
            <div
              className='memory-container container'
              style={{ maxWidth: '1445px' }}
            >
              <div className='navigationCenter-body-wrapper'>
                <div className='memories-wrapper-family'>
                  {isPagePrivate && (
                    <div className='memories-not-found-content-wrapper'>
                      <h2>Uh, Oh!</h2>
                      <h3>
                        The page that you have requested is private. You don't
                        have the permission to view this.
                      </h3>
                    </div>
                  )}
                  {!isPagePrivate && isDecedentInvalid && (
                    <div className="memories-not-found-content-wrapper">
                      <h2>Uh, Oh!</h2>
                      <h3>The page that you have requested doesn't exist.</h3>
                    </div>
                  )}
                  {!isPagePrivate && !isDecedentInvalid && (
                    <>
                      <div className='memories-left-content-wrapper-family'>
                        <Sidebar
                          opi={opi}
                          decedentData={decedentData}
                          obituary={obituary}
                          session={session}
                          loggedIn={loggedIn}
                          refreshSession={refreshSession}
                          setActiveTab={setActiveTab}
                          isLoading={showLoading}
                          isFamilyAccess={isFamilyAccess}
                          setFamilyAccess={setFamilyAccess}
                          executeDecedentAPI={executeDecedentAPI}
                          executeWebsiteAccess={executeWebsiteAccess}
                          executeObituary={executeObituary}
                          msalInstance={msalInstance}
                        />
                      </div>
                      <div className="memories-main-content-wrapper-family">
                        {shareMemory && loadShareMemory ? (
                          <Suspense
                            fallback={<div>ShareMemory Is Loading....</div>}
                          >
                            <ShareMemory
                              onePortalId={opi}
                              shareMemory={shareMemory}
                              handlePolicies={handlePolicies}
                              setShareMemory={() => {
                                setShareMemory(!shareMemory);
                                setLoader(false);
                              }}
                              decedentData={decedentData}
                            />
                          </Suspense>
                        ) : (
                          <>
                              <div
                                className={`memories-tab  memories-mobile-tab ${
                                  session.isLoggedIn
                                    ? "memories-tab-responsive-login"
                                    : "memories-tab-responsive"
                                }`}
                              >
                                <h1 className="d-block d-sm-none memories-menu-heading">
                                  Menu
                                </h1>
                                <div className="family-website-nav-tabs">
                                  <button
                                    className={`nav-tab-button  ${
                                      activeTab === "obituary" ? "active" : ""
                                    }`}
                                    onClick={() => setActiveTab("obituary")}
                                  >
                                    Obituary
                                  </button>
                                  <button
                                    className={`nav-tab-button  ${
                                      activeTab === "events" ? "active" : ""
                                    }`}
                                    onClick={() => setActiveTab("events")}
                                  >
                                    Events/Services
                                  </button>
                                  <button
                                    className={`nav-tab-button  ${
                                      activeTab === "gallery" ? "active" : ""
                                    }`}
                                    onClick={() => {
                                      startTransition(() => {
                                        setActiveTab("gallery");
                                        setLoadPdf(true);
                                      });
                                    }}
                                  >
                                    Gallery
                                  </button>
                                  {session.isLoggedIn && isFamilyAccess && (
                                    <button
                                      className={`nav-tab-button  ${
                                        activeTab === "approvememories"
                                          ? "active"
                                          : ""
                                      }`}
                                      onClick={() =>
                                        startTransitionApproveMemory(() => {
                                          setActiveTab("approvememories");
                                          setLoadApproveMemories(true);
                                        })
                                      }
                                    >
                                      Approve Memories
                                    </button>
                                  )}
                                  {session?.isLoggedIn && isFamilyAccess && (
                                    <div className="uploadOnlyMedia d-block d-sm-none">
                                      <UploadOnlyMediaModal opi={opi} decedentData={decedentData} refreshList={refreshList} />
                                    </div>
                                  )}
                                  {!(session?.isLoggedIn && isFamilyAccess)&&<div className="d-block d-sm-none navBarTributeStoreButtons"><RedirectToTributeStore opi={opi} /></div>}
                                  {!shareMemory ? (
                                    <button
                                      className="d-block d-sm-none share-memory-button"
                                      onClick={() => {
                                        startTransitionShareMemory(() => {
                                          setLoadShareMemory(true);
                                          setShareMemory(true);
                                        });
                                      }}
                                    >
                                      Share a Memory
                                    </button>
                                  ) : (
                                    ""
                                  )}
                                </div>
                                <div className="d-none d-sm-flex uploadMedia-shareMemoryWrapper">
                                  {!(session?.isLoggedIn && isFamilyAccess) && <RedirectToTributeStore opi={opi} />}
                                  {session?.isLoggedIn && isFamilyAccess && (
                                    <div className="uploadOnlyMedia d-none d-sm-flex">
                                      <UploadOnlyMediaModal opi={opi} decedentData={decedentData} refreshList={refreshList} />
                                    </div>
                                  )}
                                  {!shareMemory ? (
                                    <div className="d-none d-sm-flex share-memory-button-wrapper">
                                      <button
                                        className="share-memory-button"
                                        onClick={() => {
                                          startTransitionShareMemory(() => {
                                            setLoadShareMemory(true);
                                            setShareMemory(true);
                                          });
                                        }}
                                      >
                                        Share a Memory
                                      </button>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                              <div className="approve-memories-container">
                                {activeTab === "approvememories" &&
                                  loadApproveMemories && (
                                    <Suspense
                                      fallback={
                                        <div>
                                          Approve Memories Are Loading...
                                        </div>
                                      }
                                    >
                                      <ApproveMemories opi={opi} session={session} isFamilyAccess={isFamilyAccess} />
                                    </Suspense>
                                  )}
                              </div>
                              <div className="obituary-wrapper-family">
                                {activeTab === "obituary" && (
                                  <MemoriesListing
                                    opi={opi}
                                    decedent={decedentData}
                                    obituary={obituary}
                                    isLoggedIn={session.isLoggedIn}
                                    memoriesList={memoriesList || []}
                                    getObituary={executeObituary}
                                    obituaryIsLocked={
                                      decedentData?.obituaryIsLocked
                                    }
                                  />
                                )}
                                {activeTab === "events" && (
                                  <UpcomingEvents
                                    opi={opi}
                                    decedent={decedentData}
                                    obituary={obituary}
                                    isLoggedIn={session.isLoggedIn}
                                    isFamilyAccess={isFamilyAccess}
                                  />
                                )}
                              </div>
                              {activeTab === "obituary" && (
                                <div className="memory-listing-family">
                                  <div className="memory-filter">
                                    <MemoriesFilters
                                      setFilters={handleFilters}
                                      filtersSelected={memoryType}
                                    />
                                  </div>

                                  {obituary !== null && (
                                    <div className="memory-list-wrapper">
                                      {memoriesList &&
                                      memoriesList.length > 0 ? (
                                        <div>
                                          {memoriesList.map((memory, key) => {
                                            const type = _.get(
                                              _.find(memoryTypes, {
                                                value: memory.memoryType,
                                              }),
                                              "label"
                                            );
                                            const { memoryByPerson = "" } =
                                              memory;
                                            const handleChange = (event) => {
                                              let obj = {
                                                [event.target.name]:
                                                  event.target.checked,
                                              };
                                              sethideMemory(obj);
                                              executeHideMemory(obj);
                                              event.target.checked &&
                                                addNotification({
                                                  type: "success",
                                                  title: "Success",
                                                  message:
                                                    "Memory Hidden Successfully",
                                                });
                                            };

                                            return (
                                              <div className="memory-card">
                                                <div className="memory-card-header">
                                                  <p className="memory-title">
                                                    <span>
                                                      {moment(
                                                        memory.dateOfMemory
                                                      ).format("MM/DD/YYYY")}
                                                    </span>
                                                    <span
                                                      style={{
                                                        margin: "0 10px",
                                                      }}
                                                    >
                                                      { memory?.title && '|'}
                                                    </span>
                                                    {memory.title}
                                                  </p>
                                                  {session.isLoggedIn &&
                                                    isFamilyAccess && (
                                                      <div>
                                                        <FormControlLabel
                                                          control={
                                                            <Switch
                                                              checked={
                                                                hideMemory[
                                                                  memory.id
                                                                ] ||
                                                                memory.isPrivate
                                                              }
                                                              name={memory.id.toString()}
                                                              value={
                                                                hideMemory &&
                                                                hideMemory[
                                                                  memory.id
                                                                ]
                                                                  ? hideMemory[
                                                                      memory.id
                                                                    ]
                                                                  : memory.isPrivate
                                                              }
                                                              onChange={
                                                                handleChange
                                                              }
                                                              inputProps={{
                                                                "aria-label":
                                                                  "secondary checkbox",
                                                              }}
                                                            />
                                                          }
                                                          label={"Hide Memory"}
                                                          labelPlacement="start"
                                                          style={{
                                                            margin: 0,
                                                          }}
                                                        />
                                                      </div>
                                                    )}
                                                </div>
                                                <div className="memory-content">
                                                  <div
                                                    className="memory-content-text"
                                                    style={{
                                                      fontFamily: "Georgia",
                                                      fontSize: "18pt",
                                                    }}
                                                  >
                                                    <span
                                                      dangerouslySetInnerHTML={{
                                                        __html: _.get(
                                                          memory,
                                                          "memory",
                                                          ""
                                                        ),
                                                      }}
                                                    ></span>
                                                  </div>
                                                  {memory.files.length > 0 && (
                                                    <NewObituaryFile
                                                      files={memory.files}
                                                      memoryId={memory.id}
                                                      session={session}
                                                      opi={opi}
                                                      refreshList={refreshList}
                                                      isFamilyAccess={isFamilyAccess}
                                                    />
                                                  )}
                                                </div>
                                                <div className="memory-card-footer">
                                                  <div
                                                    className="memory-footer"
                                                    style={{
                                                      justifyContent:
                                                        memoryByPerson
                                                          ? "space-between"
                                                          : "end",
                                                    }}
                                                  >
                                                    {memoryByPerson && (
                                                      <span className="memory-postedBy">
                                                        Posted by{" "}
                                                        {`${_.startCase(
                                                          _.get(
                                                            memory,
                                                            "memoryByPersonFirstName"
                                                          )
                                                        )} ${_.upperCase(
                                                          _.head(
                                                            _.get(
                                                              memory,
                                                              "memoryByPersonLastName"
                                                            )
                                                          )
                                                        )}`}{" "}
                                                        on{" "}
                                                        {moment(
                                                          memory.createdAt
                                                        ).format("MM/DD/YYYY")}
                                                      </span>
                                                    )}
                                                    {type && (
                                                      <span className="memory-postedBy">{`Memory: ${type}`}</span>
                                                    )}
                                                  </div>
                                                  {session.isLoggedIn &&
                                                    isFamilyAccess && (
                                                      <div>
                                                        <hr className="hr-line" />
                                                        <div className="button-container">
                                                          <button
                                                            className="unApprove-button reject-button"
                                                            onClick={() => {
                                                              setIsApproved(
                                                                true
                                                              );
                                                              setMemoryId(
                                                                memory.id
                                                              );
                                                            }}
                                                          >
                                                            Un Approve
                                                          </button>
                                                        </div>
                                                      </div>
                                                    )}
                                                </div>
                                              </div>
                                            );
                                          })}
                                          {isApproved && (
                                            <AlertMessageForMemory
                                              toggleAlertModal={() => {
                                                setIsApproved(false);
                                              }}
                                              toggleOk={() => {
                                                setIsApproved(false);
                                                unApproveMemoryApi(
                                                  opi,
                                                  currentMemory,
                                                  "Pending"
                                                );
                                              }}
                                              toggleAlertState
                                              title="Are you sure you want to Un-Approve this Memory?"
                                              successMsg="This memory will be un-published and move to 
                                                                                the Approve Memories section"
                                              showCancelButton={true}
                                              okButtonText="Ok"
                                              deleteState={true}
                                            ></AlertMessageForMemory>
                                          )}
                                        </div>
                                      ) : (
                                        <div
                                          style={{
                                            textAlign: "center",
                                          }}
                                        >
                                          There are no memories available
                                        </div>
                                      )}
                                    </div>
                                  )}
                                </div>
                              )}
                              {activeTab === "obituary" &&  (deviceWidth <= 576) && (
                                <div className="memories-tab justify-end" style={{zIndex:'0'}}>
                                  {!shareMemory ? (
                                    <button
                                      className="share-memory-button"
                                      onClick={() => {
                                        startTransitionShareMemory(() => {
                                          setLoadShareMemory(true);
                                          setShareMemory(true);
                                        });
                                      }}
                                    >
                                      Share a Memory
                                    </button>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              )}
                              {isPending && <div>Loading....</div>}
                              {activeTab === "gallery" && loadPdf && (
                                <div className="memory-listing-family">
                                  {!shareMemory && obituary !== null && (
                                    <div className="memory-list-wrapper">
                                      <div>
                                        <div className="memory-card gallery-card">
                                          <div className="memory-content">
                                            <Suspense>
                                              <NewGallery
                                                imagesArray={imagesArray}
                                                files={galleryAllMedia}
                                                photos={photos}
                                                allMemoriesPhotos={
                                                  allMemoriesPhotos
                                                }
                                                allMemoriesImageFiles={
                                                  allMemoriesImageFiles
                                                }
                                                otherMedia={otherMediaFiles}
                                                media={media}
                                                opi={opi}
                                                memoriesList={memoriesList}
                                                obituary={obituary}
                                                userData={userData}
                                                section="gallery"
                                                session={session}
                                                refreshList={refreshList}
                                                isFamilyAccess={isFamilyAccess}
                                                
                                              />
                                            </Suspense>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              )}
                          </>
                        )}
                      </div>
                    </>
                  )}
                </div>
                <div className="scrollDiv d-flex justify-content-end">
                  <button
                    onClick={() => {
                      scrollToTop();
                    }}
                    className="scroolButton btn-floating btn-lg"
                  >
                    <i class="fas fa-arrow-up scroolUp"></i> Scroll To Top
                  </button>
                </div>
              </div>
            </div>
          </PublicAppLayout>
        </div>
      </HelmetProvider>
    );
};

export default React.memo(Dashboard);
