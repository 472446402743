import React, { useState, useEffect, useCallback, useMemo } from "react";
import useAsync from "react-use-async-hook";
import _ from "lodash";
import Cropper from 'react-easy-crop'
import { uploadAPI } from "../../../../../api/memories"
import * as rotateImageApi from '../../../../../api/memories'
import * as adminRotateImageApi from '../../../../../api/admin'
import addNotification from "../../../../utils/notification/notification";
import Loader from '../../../../utils/loader/loader'

const RotateImage = ({
    setRotateImg,
    isRotated,
    memoryId,
    file,
    opi,
    refreshList,
    webView,
    adminView,
    handleMemoryEdit,
    displaySaveButton,
    setDisplaySaveButton,
    rotation,
    setRotation,
    disableIcon,
    setDisableIcon,
    activeIndex,
}) => {
    const [imageSrc, setImageSrc] = useState(
        webView ?
            (file?.originalUrl ? file?.originalUrl : file?.src)
            : file?.src
    );
    const [cropSize, setCropSize] = useState({ height: 0, width: 0 })
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
    const [croppedImage, setCroppedImage] = useState(null)
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [aspectRatio,setAspectRatio] = useState(1)
    const [isLoaded,setIsLoaded] = useState(false)
    const [clsName,setClsName] = useState('landscapeImage')
    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels)
    }, [])

    useEffect(()=>{
        if(isRotated) {
        if (webView) {
            setDisableIcon(true)
        }
        else {
            setDisableIcon({ index: activeIndex, isDisable: true })
        }
    }
    },[isRotated])

    const [showUploadImageLoader,setUploadImageShowLoader] = useState(false)
    const [showRotateImageLoader,setRotateImageLoader] = useState(false)

    const onMediaLoad = (media) => {
        if (webView) {
            setDisableIcon(false)
        }
        else {
            setDisableIcon({index:activeIndex,isDisable:false})
        }
    }

    const closeModal = () => {
        setDisplaySaveButton(false)
        setRotateImg(false)
        setDisableIcon(false)
        if (webView) {
            setDisableIcon(false)
        }
        else {
            setDisableIcon({ index: activeIndex, isDisable: false })
            setRotation(0);
        }
    }

    /* UPLOAD IMAGE API*/
    let {
        data: uploadData,
        loading: uploadAPILoading,
        execute: performUploadAPI,
    } = useAsync({
        autoExecute: false,
        initialData: useMemo(() => null, []),
        task: useCallback(async (files, memoryid) => {
            if (files) {
                return {
                    response: await uploadAPI(files),
                    memoryid,
                };
            }
        }, []),
        dataLoader: useCallback(({ response, memoryid }) => {
            if (response) {
                return {
                    URL: _.get(response, "data.URL"),
                    memoryid,
                };
            }
        }, []),
        onError: useCallback(error => {
            addNotification({
                type: "danger",
                title: "Error",
                message: _.get(
                    error,
                    "response.data.message",
                    "Oops! Something went wrong"
                ),
            });
            closeModal()
        }, []),
    });

    /* ROTATE IMAGE API  */

    const {
        data: RotateImageData,
        loading: RoateImageAPILoading,
        execute: executeAddImages

    } = useAsync({
        autoExecute: false,
        task: adminView ? adminRotateImageApi.rotateImageAPI : rotateImageApi.rotateImgAPI,
        dataLoader: useCallback(response => {
            if (response) {
                addNotification({
                    type: "success",
                    title: "Success",
                    message: "Image Rotated Successfully",
                });
                return response
            }
        }, []),
        onError: useCallback(error => {
            addNotification({
                type: "danger",
                title: "Error",
                message: _.get(
                    error,
                    "response.data.message",
                    "Oops! Something went wrong"
                ),
            });
            // setIsDisabled(false)
            closeModal()
        }, []),
    });
    ;

    useEffect(() => {
        if (uploadData) {
            const { URL } = uploadData
            const data = {
                fileType: file.fileType,
                fileUrl: URL[0],
                fileId: file.id,
                rotationAngle: rotation,
                memoryId: memoryId
            }
            if (adminView) {
                executeAddImages(memoryId, data)
            }
            else executeAddImages(memoryId, opi, data)
        }

    }, [uploadData])

    const getUnApprovedMemories = async () => {
        await refreshList(opi);
       closeModal()
    }

    const getMemoriesList = async () => {
        await handleMemoryEdit()
        closeModal();
    }
    useEffect(() => {
        if (RotateImageData) {
            if (adminView) {
                getMemoriesList();

            }
            else getUnApprovedMemories();
        }
    }, [RotateImageData])


    useEffect(()=>{
        if(uploadAPILoading) {
            setUploadImageShowLoader(true)
        }
        else setUploadImageShowLoader(false)
    },[uploadAPILoading])

    useEffect(()=>{
        if(RoateImageAPILoading) {
          setRotateImageLoader(true)
        }
        else setRotateImageLoader(false)
    },[RoateImageAPILoading])

  
    useEffect(()=>{
        const img = new Image();
        if (webView) {
            img.src = file?.originalUrl ? file?.originalUrl : file?.src;
            img.addEventListener('load', function () {
                if ((rotation === 90 || rotation === 270)) {
                    setClsName('portraitImage')
                }
                else if ((rotation === 0 || rotation === 180 || rotation === 360)) {
                    setClsName('landscapeImage')
                }
            });
        }
    },[rotation])
    
    const getCroppedImg = async (imageSrc,
        rotation
    ) => {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.setAttribute('crossOrigin', 'anonymous')
            img.src = imageSrc;
            img.onload = function () {
                const canvas = document.createElement("canvas");
                const ctx = canvas.getContext("2d");

                // set proper canvas dimensions before transform & export
                if (rotation === 90 || rotation === 270) {
                    canvas.width = img.height;
                    canvas.height = img.width;
                } else {
                    canvas.width = img.width;
                    canvas.height = img.height;
                }

                // transform context before drawing image
                switch (rotation) {
                    case 90:
                        ctx.rotate((rotation * Math.PI) / 180);
                        ctx.drawImage(img, 0, -canvas.width);
                        break;
                    case 180:
                        ctx.rotate((rotation * Math.PI) / 180);
                        ctx.drawImage(img, -canvas.width, -canvas.height);
                        break;
                    case 270:
                        ctx.rotate((rotation * Math.PI) / 180);
                        ctx.drawImage(img, -canvas.height, 0);
                        break;
                    default:
                        ctx.drawImage(img, 0, 0);
                        break;
                }

                // export base64
                // canvas.toBlob((blob) => {
                //     resolve(URL.createObjectURL(blob));
                // }, "image/jpeg");
                resolve(canvas.toDataURL("image/jpeg"));
            };
            img.onerror = function () {
                reject(new Error("Failed to load image"));
            };
        });
    }

    const handleSave = async (file) => {
        const fileNameArray =  file?.originalUrl? file?.originalUrl.split('-') : file?.src.split('-');
        const originalNameArray = fileNameArray ? fileNameArray.slice(1).join("") : '';
        const removeExtension = originalNameArray ? originalNameArray.split('.') : '';
        const extension = removeExtension ? removeExtension.pop() : 'jpeg'
        const finalName = removeExtension ? removeExtension.join("").trim() : '';
        const min = 1;
        const max = 100;
        const randomNum = Math.floor(Math.random() * (max - min + 1)) + min;
        const croppedImg = await getCroppedImg(imageSrc, rotation)
        const blob = await (await fetch(croppedImg)).blob();
        const result = new File([blob], `${finalName ? finalName : 'rotateImage'}_${file?.id}_${randomNum}.${extension}`, {
            type: file.fileType,
            lastModified: new Date(),
        });
        var formData = new FormData();
        formData.append("file", result);
        await performUploadAPI(formData, memoryId)
    }

    return (
        <>
            {showUploadImageLoader ? <Loader showLoader={showUploadImageLoader} /> : ""}
            {showRotateImageLoader ? <Loader showLoader={showRotateImageLoader} /> : ""}
            <div className={webView ? clsName : ''}>
                <Cropper
                    image={imageSrc}
                    crop={crop}
                    rotation={rotation}
                    // aspect={1}
                    onCropChange={setCrop}
                    onCropComplete={onCropComplete}
                    onRotationChange={setRotation}
                    onMediaLoaded={onMediaLoad}
                    cropSize={cropSize}
                    showCroppedArea={false}
                    disableTouch={true}
                    objectFit={'contain'}
                    style={{ boxShadow: 'none !important' }}
                />
                {
                    displaySaveButton && (
                        <div className="rotateImageModal">
                            <div className={webView ? 'showSaveButton' : 'mobile-showSaveButton'}>
                            </div>
                            <div className='footer-container d-flex align-items-center avatar-buttons-wrapper modal-footer-buttons' style={{ justifyContent: 'space-evenly' }}>
                                <button onClick={() => {
                                    if (!webView) {
                                        setRotation(0)
                                    }
                                    setRotateImg(false)
                                    setDisplaySaveButton(false)
                                    if (webView) {
                                        setDisableIcon(false)
                                    }
                                    else {
                                        setDisableIcon({ index: activeIndex, isDisable: false })
                                    }
                                }} className={`${'not-disabled'} footer-btn`}
                                >CANCEL</button>

                                <button
                                    className={`${(webView ? disableIcon : (disableIcon && disableIcon?.isDisable)) ? 'disable-button' : 'not-disabled'} footer-btn`}
                                    onClick={() => {
                                        setUploadImageShowLoader(true);
                                        // setIsDisabled(true)
                                        if (webView) {
                                            setDisableIcon(true)
                                        }
                                        else {
                                            setDisableIcon({ index: activeIndex, isDisable: true })
                                        }
                                        handleSave(file)
                                    }}
                                    disabled={webView ? disableIcon : (disableIcon && disableIcon?.isDisable)}
                                >
                                    SAVE
                                </button>
                            </div>
                        </div>
                    )
                }
            </div>
        </>
    )
};

export default RotateImage;