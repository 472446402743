import _, { defaults, size } from 'lodash' 
import { fontFamily, fontSize, fontSizeMetric } from './constants'
import Template from './template'

let isHTMLReg = /<[^<]+>/
export const checkIsHTML = (str) => {
    return isHTMLReg.test(str)
}

const updateStringSize = (style, value) => {
    let { font, size, line } = style
    let fontStyle = `${font === 'cinzel' ? 'small-caps' : 'normal'} ${fontSizeMetric[size]}px ${font}`
    let width = displayTextWidth(
        `${_.get(style, 'font') === 'cinzel' ? _.toUpper(value) : value}`, 
        fontStyle
    )
    if (line < Math.ceil(width/400)) {
        let findIdx = fontSize.indexOf(size)
        return fontSize[findIdx - 1]
    }
    return size
}

const validateWidth = (value, style) => {
    let { font, size } = style
    let width = displayTextWidth(value, font)
    let line = estimateLineOfText(size)
    return (line < Math.ceil(width/400))
}

const calculateSize = (style, value) => {
    let { font, size, line } = style
    let fontStyle = `${font === 'cinzel' ? 'small-caps' : 'normal'} ${fontSizeMetric[size]}px ${font}`

    let validate = validateWidth(value, {
        font: fontStyle,
        line,
        size
    })
    if (!validate) {
        return size
    } else {
        let findIdx = fontSize.indexOf(size)
        size = fontSize[findIdx - 1]
        return calculateSize({
            ...style,
            line,
            size
        }, value)
    }
}

export const getCustomSize = (style, value, key) => {
    let { size } = style
    if (key === 'content' && !checkIsHTML(value)) {
        size = calculateSize(style, value)
    } else if (key && !checkIsHTML(value)) {
        size = updateStringSize(style, value)
    }
    return size;
}

export const getString = (style, value, type) => {
    let { bold = false, italic = false, underline = false } = style;
    let html = value;
    
    let { size, font, align } = style

    if (underline) {
        html = `<u>${html}</u>`
    }
    if (italic) {
        html = `<em>${html}</em>`
    }
    if (bold) {
        html = `<strong>${html}</strong>`
    }

    html = `${type === 'title' ? '<p class=\"ql-align-center"\><br></p>' : ''}<p class=\"ql-align-${align} '  ' ${type === 'title' ? 'poemTitle' : type} '  ' \"><span class=\"ql-size-${size} ql-font-${font}\">${html}</span></p><p class=\"ql-align-center ${type === 'title' ? "ql-size-small poemTitle" : "content"} "\><br></p>${type === 'content' ? '<p class=\"ql-align-center content"\><br></p>' : ''}`
    return html
}

export const estimateLineOfText = (defaultSize) => {
    let template = Template.prayer['text4']['content']
    let { line, size } = template
    
    let defaultLineIdx = fontSize.indexOf(defaultSize)
    let lineIdx = fontSize.indexOf(size)
    return line + (lineIdx - defaultLineIdx);
}

export const getCustomHeight = (text, style) => {
    let customStyle = {
        ...style
    }
    for (let f of fontSize) {
        if (_.includes(text, `ql-size-${f}`) && f !== _.get(style, "size")) {
            customStyle = {
                ...customStyle,
                size: f
            }
        }
    }
    for (let f of fontFamily) {
        if (_.includes(text, `ql-font-${f}`) && f !== _.get(style, "font")) {
            customStyle = {
                ...customStyle,
                font: f
            }
        }
    }
    return customStyle
}

function displayTextWidth(text, font) {
    let canvas = displayTextWidth.canvas || (displayTextWidth.canvas = document.createElement("canvas"));
    let context = canvas.getContext("2d");
    context.font = font;
    let metrics = context.measureText(text);
    return metrics.width;
}
