import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

export default class BtnBack extends Component {
    constructor(props) {
        super(props);
        this.goToPrevPage = this.goToPrevPage.bind(this);
    }

    goToPrevPage() {
        this.props.history.goBack();
    }

    render() {
        return (
            <div className="back-btn-section">
                <button onClick={this.goToPrevPage} className="back-btn">
                    <i className="fas fa-arrow-left" />
                    <span>Back</span>
                </button>
            </div>
        );
    }
}
// export default withRouter(BtnBack);
