import React, { useState, useRef } from "react";
import _ from "lodash";
import { Modal, Row, Col, ModalBody, ModalHeader } from "reactstrap";
import VideoThumbnail from "./videoThumbnail";

import defaultImage from "../../../../../assets/images/warning.svg";

const Media = ({ file, index, text, openModal, memoryId }) => {
    const imageRef = useRef(null);
    const fileContainerRef = useRef(null);

    return (
        <div
            onClick={(e) => {
                openModal();
            }}
            key={index}
            ref={fileContainerRef}
            className={`${_.lowerCase(file.fileType)}-file-div`}
        >
            {file.fileType === "Image" && (
                <img
                    className="image-file"
                    src={file.src}
                    onError={() => {
                        imageRef.current.src = defaultImage;
                        fileContainerRef.current.className = `error-div ${_.lowerCase(
                            file.fileType
                        )}-file-div`;
                    }}
                    ref={imageRef}
                />
            )}
            {file.fileType === "Video" &&
                renderVideo(file, index, false, memoryId)}
            {file.fileType === "Audio" && (
                <audio controls className="audio-file">
                    <source src={file.src} />
                </audio>
            )}
            {text && <h2>{text}</h2>}
        </div>
    );
};

const renderVideo = (video, key, isThumbnail, memoryId, setVideoTitle) => {
    return (
        <VideoThumbnail
            video={video}
            isThumbnail={isThumbnail}
            flag={key}
            memoryId={memoryId}
            setVideoTitleData={setVideoTitle}
        />
    );
};

const MemoriesFiles = ({ files, memoryId }) => {
    const [currentIndex, setCurrentIndex] = useState(null);

    const renderFiles = (files) => {
            files  = files.sort((a, b) => (a.id > b.id) ? 1 : -1)
        if (files.length === 1) {
            return (
                <Row className="single-container">
                    <Col xs={12} className="large-view">
                        <Media
                            file={files[0]}
                            index={0}
                            openModal={() => openModal(0)}
                            memoryId={memoryId}
                        />
                    </Col>
                </Row>
            );
        } else if (files.length === 2) {
            return (
                <Row className="dual-container">
                    <Col xs={12} md={6} className="view">
                        <Media
                            file={files[0]}
                            index={0}
                            openModal={() => openModal(0)}
                            memoryId={memoryId}
                        />
                    </Col>
                    <Col xs={12} md={6} className="view view-mobile">
                        {files[1] && (
                            <Media
                                file={files[1]}
                                index={1}
                                openModal={() => openModal(1)}
                                memoryId={memoryId}
                            />
                        )}
                    </Col>
                </Row>
            );
        } else if (files.length > 1 && files.length < 4) {
            return (
                <Row className="three-container">
                    <Col xs={6} md={8} className="large-view">
                        <Media
                            file={files[0]}
                            index={0}
                            openModal={() => openModal(0)}
                            memoryId={memoryId}
                        />
                    </Col>
                    <Col xs={6} md={4} className="small-view">
                        {files[1] && (
                            <div className="small-media-div">
                                <Media
                                    file={files[1]}
                                    index={1}
                                    openModal={() => openModal(1)}
                                    memoryId={memoryId}
                                />
                            </div>
                        )}
                        {files[2] && (
                            <div className="small-media-div">
                                <Media
                                    file={files[2]}
                                    index={2}
                                    openModal={() => openModal(2)}
                                    memoryId={memoryId}
                                />
                            </div>
                        )}
                    </Col>
                </Row>
            );
        } else {
            return (
                <Row className="multi-container">
                    <Col xs={6} md={8} className="large-view">
                        <Media
                            file={files[0]}
                            index={0}
                            openModal={() => openModal(0)}
                            memoryId={memoryId}
                        />
                    </Col>
                    <Col xs={6} md={4} className="small-view">
                        {files[1] && (
                            <div className="small-media-div">
                                <Media
                                    file={files[1]}
                                    index={1}
                                    openModal={() => openModal(1)}
                                    memoryId={memoryId}
                                />
                            </div>
                        )}
                        {files[2] && (
                            <div className="small-media-div">
                                <Media
                                    file={files[2]}
                                    index={2}
                                    openModal={() => openModal(2)}
                                    memoryId={memoryId}
                                />
                            </div>
                        )}
                        {files.length === 4 ? (
                            <div className="small-media-div">
                                <Media
                                    file={files[3]}
                                    index={3}
                                    openModal={() => openModal(3)}
                                    memoryId={memoryId}
                                />
                            </div>
                        ) : (
                            <div className="show-more-div">
                                <div className="media-div small-media-div">
                                    <Media
                                        file={files[3]}
                                        index={3}
                                        openModal={() => openModal(3)}
                                    />
                                </div>
                                <div className="mask-layer">
                                    <span>+ {files.length - 3} more</span>
                                </div>
                            </div>
                        )}
                    </Col>
                </Row>
            );
        }
    };

    const openModal = (index) => {
        setCurrentIndex(index);
    };

    const closeModal = (e) => {
        if (e != undefined) {
            e.preventDefault();
        }
        setCurrentIndex(null);
    };

    const findPrev = (e) => {
        setCurrentIndex(currentIndex - 1);
    };

    const findNext = (e) => {
        setCurrentIndex(currentIndex + 1);
    };

    return (
        <>
            <div className="media-container">{renderFiles(files)}</div>
            <FilesModal
                closeModal={closeModal}
                findPrev={findPrev}
                findNext={findNext}
                hasPrev={currentIndex > 0}
                hasNext={currentIndex + 1 < files.length}
                file={files[currentIndex]}
                currentIndex={currentIndex}
                fileCount={files.length}
                memoryId={memoryId}
            />
        </>
    );
};

const FilesModal = ({
    closeModal,
    findNext,
    findPrev,
    hasNext,
    hasPrev,
    file,
    currentIndex,
    fileCount,
    memoryId,
}) => {
    const imageRef = useRef(null);
    const fileContainerRef = useRef(null);

    const [videoTitleData, setVideoTitleData] = useState("");
    const result = [];
    let classname;
    for (let i = 0; i < fileCount; i++) {
        if (i === currentIndex) {
            classname = "active";
        } else {
            classname = "dot";
        }
        result.push(<span className={classname}></span>);
    }

    return (
        <Modal
            isOpen={!!file}
            toggle={closeModal}
            size="lg"
            className="images-preview-modal"
        >
            <ModalHeader>
                <i className="modal-close fas fa-times" onClick={closeModal} />
            </ModalHeader>
            <ModalBody>
                <Col>
                    {hasPrev && (
                        <button className="modal-prev" onClick={findPrev}>
                            &lsaquo;
                        </button>
                    )}
                </Col>
                <Col>
                    {file && file.fileType === "Image" && (
                        <div
                            id={
                                file
                                    ? `${_.lowerCase(file.fileType)}-file-div`
                                    : ""
                            }
                            ref={fileContainerRef}
                        >
                            <img
                                ref={imageRef}
                                onError={() => {
                                    imageRef.current.src = defaultImage;
                                    fileContainerRef.current.className = `error-div ${_.lowerCase(
                                        file.fileType
                                    )}-file-div`;
                                }}
                                className="image-file"
                                src={file.src}
                            />
                        </div>
                    )}
                    {file &&
                        file.fileType === 'Video' &&
                        renderVideo(file, 'main', false, memoryId, setVideoTitleData)}
                    {file && file.fileType === 'Audio' && (
                        <div
                            className={
                                file
                                    ? `${_.lowerCase(file.fileType)}-file-div`
                                    : ''
                            }
                        >
                            <audio controls className="audio-file">
                                <source src={file.src} />
                            </audio>
                        </div>
                    )}
                </Col>
                <Col>
                    {hasNext && (
                        <button className="modal-next" onClick={findNext}>
                            &rsaquo;
                        </button>
                    )}
                </Col>
                {file && file.fileType === "Video" && (
                    <div style={{ textAlign: "center" }}>{videoTitleData}</div>
                )}
                <div style={{ textAlign: "center" }}>{result}</div>
            </ModalBody>
        </Modal>
    );
};

export default MemoriesFiles;
