import React from "react";
import { Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";

const PrivacyPolicy = ({ isOpen, toggle, onClose, handleAccept, isView }) => {
    return (
        <Modal isOpen={isView || isOpen} className="policy-wrapper">
            <ModalHeader>
                <i
                    className="modal-close fas fa-times"
                    onClick={() => (isView ? toggle() : onClose())}
                />
                <span className="modal-headername">
                    {" "}
                    Privacy Policy <i className={"ml-2 fas"} />
                </span>
            </ModalHeader>
            <ModalBody>
                <div className="modal-content">
                    <div className="content-section">
                        <span className="heading">
                            {" "}
                            What information do we collect?{" "}
                        </span>
                        <br />
                        <span className="content">
                            We collect information from you when you register to
                            use the Funeral Arrangement Assistant. The
                            information we collect about the Decedent will
                            remain confidential unless you authorize it’s use
                            for public viewing such as in the case of the
                            obituary, prayer card, and Service program.
                        </span>
                    </div>
                    <div className="content-section">
                        <span className="heading">
                            {" "}
                            How do we use your information?{" "}
                        </span>
                        <br />
                        <span className="content">
                            We use the information we collect from you about the
                            decedent, the decedent’s wishes, or you and your
                            Family’s wishes to facilitate in the arrangement
                            process. Specific personal information about the
                            decedent is used in the application of the death
                            certificate and filed through California EDRS
                            (Electronic Death Registration System) with the
                            appropriate county. Other information is used to
                            understand both the Family and decedent’s wishes and
                            interest in the type of service and merchandise
                            selection. Other information is used to prepare the
                            obituary, prepare payer cards, and prepare the
                            Service program. The use of all information provided
                            by you in the Funeral Arrangement Assistant is
                            designed to allow us to deliver the best service
                            possible and exceed your expectations.
                        </span>
                    </div>
                    <div className="content-section">
                        <span className="heading">
                            {" "}
                            How do we protect information?{" "}
                        </span>
                        <br />
                        <span className="content">
                            We implement a variety of security measures to
                            maintain the safety of yours and the Decedent’s
                            personal information. All personal information is
                            contained behind secured networks and is only
                            accessible by a limited number of persons who have
                            special access rights to such systems and are
                            required to keep the information confidential. When
                            you place personal information into our care, we
                            offer the use of a secure server.
                        </span>
                    </div>
                    <div className="content-section">
                        <span className="heading"> Do we use “cookies”? </span>
                        <br />
                        <span className="content">
                            Yes. Cookies are small files that a site or its
                            service provider transfers to your computer’s hard
                            drive through your Web browser (if you allow) that
                            enables the site’s or service provider’s systems to
                            recognize your browser and capture and remember
                            certain information. You can choose to have your
                            computer warn you each time a cookie is being sent,
                            or you can choose to turn off all cookies. You do
                            this through your browser (like Netscape Navigator
                            or Internet Explorer) settings. Each browser is a
                            little different, so look at your browser Help menu
                            to learn the correct way to modify your cookies. If
                            you turn cookies off, you may not have access to
                            many features that make your Funeral Arrangement
                            Assistant experience more efficient.
                        </span>
                    </div>
                    <div className="content-section">
                        <span className="heading">
                            {" "}
                            Do we disclose the information we collect to outside
                            parties?{" "}
                        </span>
                        <br />
                        <span className="content">
                            We <b>DO NOT share ANY information</b> with outside
                            parties. The term “outside parties” does not include
                            our business.
                        </span>
                    </div>
                    <div className="content-section">
                        <span className="heading">
                            {" "}
                            How can you opt-out, remove or modify information
                            you have provided to us?{" "}
                        </span>
                        <br />
                        <span className="content">
                            Once the death certificate has been registered and
                            the service has taken place, you can no longer
                            modify any information entered into the Funeral
                            Arrangement Assistant. If information that was
                            entered by you is subsequently discovered by you to
                            be in error, please contact the Funeral Home
                            immediately.
                        </span>
                    </div>
                    <div className="content-section">
                        <span className="heading"> Third-Party Links </span>
                        <br />
                        <span className="content">
                            <i>
                                There are <b>NO Third-Party links</b> in the
                                Funeral Arrangement Assistant.
                            </i>{" "}
                            The Funeral Arrangement Assistant is linked only to
                            the Funeral Home internal software application in
                            order to complete the arrangement. The internal
                            software application of the Funeral Home does
                            transmit all of the Decedent’s death registration
                            and certificate information electronically to the
                            EDRS.
                        </span>
                    </div>
                    <div className="content-section">
                        <span className="heading"> Changes to our policy </span>
                        <br />
                        <span className="content">
                            If we decide to change our privacy policy, we will
                            post those changes on the Funeral Home’s website.
                            Policy changes will apply only to information
                            collected after the date of the change. This policy
                            was last modified on November 1, 2020.
                        </span>
                    </div>
                    <div className="content-section">
                        <span className="heading">
                            {" "}
                            Questions and feedback{" "}
                        </span>
                        <br />
                        <span className="content">
                            We welcome your questions, comments, and concerns
                            about privacy. Please send us any and all feedback
                            pertaining to privacy, or any other issue.
                        </span>
                    </div>
                    <div className="content-section">
                        <span className="heading"> Online Policy Only </span>
                        <br />
                        <span className="content">
                            This online privacy policy applies only to
                            information collected through the Funeral
                            Arrangement Assistant.
                        </span>
                    </div>
                    <div className="content-section">
                        <span className="heading"> Your consent </span>
                        <br />
                        <span className="content">
                            By using the Funeral Arrangement Assistant, you
                            consent to our privacy policy.
                        </span>
                    </div>
                </div>
            </ModalBody>

            <ModalFooter>
                {!isView ? (
                    <>
                        <button
                            type="button"
                            className="btn-theme btn-cancel"
                            onClick={() => onClose()}
                        >
                            Decline
                        </button>
                        <button
                            type="button"
                            className="btn-theme"
                            onClick={() => handleAccept()}
                        >
                            Accept
                        </button>
                    </>
                ) : (
                    ""
                )}
            </ModalFooter>
        </Modal>
    );
};

export default PrivacyPolicy;
