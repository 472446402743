import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import useAsync from "react-use-async-hook";
import _ from "lodash";

import { useSetLoading } from "../../utils/hooks/loader";
import * as Dashboard from "../../../api/dashboard";
import AppLayout from "../../appLayout";
import BreadCrumb from "../../utils/breadCrumb/breadCrumb";
import WhatToBringBanner from "../../../assets/images/whatToBring.jpg";

const WhatToBring = () => {
    const { onePortalId } = useParams();
    const [loader, setLoader] = useState(false);

    /**
     * Get Decedent Detail
     */
    const {
        data: decedent,
        loading: getDecedentLoader,
        execute: executeGetDecedent
    } = useAsync({
        autoExecute: false,
        task: Dashboard.getArrangementSummary,
        dataLoader: useCallback(res => {
            return _.get(res, "data");
        }, [])
    });

    useEffect(() => {
        if (onePortalId) {
            executeGetDecedent(onePortalId);
        }
    }, [onePortalId]);

    const showLoader = useMemo(() => getDecedentLoader || loader, [getDecedentLoader, loader]);
    useSetLoading(showLoader);

    return (
        <AppLayout
            decedent={_.get(decedent, "decedent") || null}
            haveAcceptPolicy={_.get(decedent, "haveAcceptPolicy", false)}
            getDecedent={executeGetDecedent}
            setLoader={setLoader}
        >
            <div className="container">
                <BreadCrumb
                    links={[
                        {
                            url: `/app`,
                            description: "Home",
                            heading: "Dashboard"
                        },
                        {
                            url: `/app/${onePortalId}/arrangements`,
                            description: "Arrangements Summary",
                            heading: "Arrangements Summary"
                        },
                        {
                            url: "/app/whatToBring",
                            description: "What To Bring",
                            heading: "What To Bring",
                            isSpan: true
                        }
                    ]}
                    showBack={true}
                />
                <div className="navigationCenter-body-wrapper">
                    <div className="whattoexpect-wrapper">
                        <div className="whattoexpect-banner">
                            <img
                                src={WhatToBringBanner}
                                alt="What To Bring"
                                className="whattoexpect-banner-image"
                            />
                        </div>
                        <div className="whattoexpect-welcome">
                            <h4 className="whattoexpect-welcome-header">
                                Things We Will Need
                            </h4>
                            <p className="whattoexpect-welcome-note">
                                Please bring these things to the arrangement
                                appointment with you:
                            </p>
                            <p className="whattoexpect-welcome-note text-center m-0">
                                <small>
                                    (You may print this list for your
                                    convenience.)
                                </small>
                            </p>
                            <ul className="whattoexpect-welcome-list">
                                <li>
                                    Durable Power of Attorney for Health Care
                                    document, if one exists (This is especially
                                    important if you are arranging a cremation).
                                </li>
                                <li>
                                    If a Veteran, Military Discharge Papers
                                    (Form DD-214).
                                </li>
                                <li>
                                    Insurance Policies (if you intend to use
                                    insurance to pay for the arrangements).
                                </li>
                                <li>
                                    Pre-arrangement contract, if you have one.
                                </li>
                                <li>Cemetery deeds or paperwork.</li>
                                <li>
                                    Clothing, including undergarments (suggested
                                    even if you are choosing cremation).
                                    <br />
                                    (long sleeve garments with a high neckline
                                    or collar are best)
                                </li>
                                <li>A recent photograph of your loved one.</li>
                                <li>
                                    Photographs for us to create a personalized
                                    DVD presentation.
                                </li>
                                <li>
                                    Any personal mementos, such as cards,
                                    photos, childrens drawings, etc., that you
                                    may wish to accompany your loved one for
                                    burial or cremation.
                                </li>
                                <li>
                                    If we are arranging international travel to a foreign country, you
                                    should bring any passports,
                                    birth certificates, marriage certificates, or
                                    other legal documents.
                                </li>
                                <li>
                                    It may also be helpful to start a list of
                                    relatives and friends who should be
                                    notified.
                                </li>
                            </ul>
                        </div>

                        <div className="whattoexpect-welcome">
                            <h4 className="whattoexpect-welcome-header">
                                Preparing the Death Certificate
                            </h4>
                            <p className="whattoexpect-welcome-note">
                                When we are notified of a death, we promptly
                                contact the doctor or coroner to secure the
                                medical (cause of death) information for the
                                death certificate. We will complete the
                                certificate with personal information provided
                                by you. We will confirm the accuracy of this
                                information before we file the certificate in
                                the county where the person died. At that time,
                                we will secure the permit for burial, shipment,
                                or cremation, and we will order certified copies
                                of the death certificate for you. Here are some
                                of the things you may need a certified death
                                certificate for:
                            </p>
                            <p className="whattoexpect-welcome-note text-center m-0">
                                <small>
                                    (You may print this list for your
                                    convenience.)
                                </small>
                            </p>
                            <ul className="whattoexpect-welcome-list">
                                <li>
                                    Bank accounts, retirement accounts,
                                    investment accounts (Check with each
                                    institution to see how many are needed).
                                </li>
                                <li>Transfer title on automobiles.</li>
                                <li>Every life insurance policy.</li>
                                <li>Probate - check with an attorney.</li>
                                <li>
                                    Veteran’s Affairs (a copy for this purpose
                                    can be ordered at no cost).
                                </li>
                                <li>
                                    Transferring title on Real Estate (check
                                    with an attorney)
                                    <br />
                                    (Social Security will be notified at the
                                    time the death certificate is filed. You
                                    will need to contact Social Security
                                    directly to apply for a surviving spouse
                                    death benefit. You will not need a certified
                                    death certificate for Social Security; we
                                    can provide you with the “Funeral Director’s
                                    Statement of Death”).
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </AppLayout>
    );
};

export default WhatToBring;
