import React from "react";
import _ from "lodash";
import Select from "react-select";

class SearchSelectComponent extends React.Component {
    render() {
        const defaultSize = this.props.size || 10;

        return (
            <div className='select-wrapper'>
                <Select
                    noOptionsMessage={this.props.noOptionsMessage}
                    placeholder={this.props.placeholder}
                    value={
                        this.props.selectedOption
                            ? this.props.selectedOption
                            : ""
                    }
                    onInputChange={this.props.handleInputChange}
                    onFocus={this.props.onFocus}
                    getOptionLabel={this.props.getOptionLabel}
                    onChange={this.props.handleChange}
                    options={_.take(this.props.options, defaultSize)}
                    filterOption={this.props.filterOption}
                    isSearchable
                    isClearable={this.props.isClearable || false}
                    classNamePrefix={`${
                        this.props.className || ""
                    } select-dropdown`}
                    isDisabled={this.props.disabled}
                    id={this.props.id}
                />
            </div>
        );
    }
}
export default SearchSelectComponent;
