import React from "react";
import { Tooltip } from "reactstrap";

const TooltipAndInfoForImage = props => {
    const {
        id = "",
        showTooltipFn,
        showToolTip = false,
        tooltipText = "Some text here",
        clsName = "space-tooltip-share-icon",
        icon,
        onClick,
        link
    } = props;

    return (
        <React.Fragment>
            <a
                href={link}
                className={clsName}
                id={id}
                onClick={onClick}
                target='_blank' rel="noreferrer"
            >
                <img
                    src={icon}
                    alt='Icon'
                    className='share-icon-wrapper'
                >
                </img>
            </a>
            <Tooltip
                placement='bottom-start'
                isOpen={showToolTip}
                target={id}
                toggle={showTooltipFn}
                className='tooltip-image-template'
            >
                {tooltipText}
            </Tooltip>
        </React.Fragment>
    );
};

export default TooltipAndInfoForImage;
