import React, { useState, useEffect } from "react";
import { Input, Label } from "reactstrap";
import _ from "lodash";

import FilterOption from './filterOptions';

const MemoriesFilters = ({ setFilters, filtersSelected = [], isMenu = false, openModal }) => {
    const [isAllSelected, setIsAllSelected] = useState(true);
    const [selectedFilters, setSelectedFilters] = useState([]);
    const filters = [
        {
            value: 1,
            label: "Family Memories"
        },
        {
            value: 2,
            label: "Relationship Memories"
        },
        {
            value: 3,
            label: "Education Memories"
        },
        {
            value: 4,
            label: "Career Memories"
        },
        {
            value: 5,
            label: "Military Memories"
        },
        {
            value: 6,
            label: "Important Dates"
        },
        {
            value: 7,
            label: "Hobby Memories"
        },
        {
            value: 8,
            label: "Rememberances"
        }
    ];

    const handleFiltersChange = (value, isAllSelected) => {
        if (selectedFilters.includes(value)) {
            const updatedFilters = _.filter(selectedFilters, filter => filter !== value)
            setSelectedFilters(updatedFilters)
            setIsAllSelected(false)
        } else {
            const updatedFilters = selectedFilters.concat(value);
            setSelectedFilters(updatedFilters);
            setIsAllSelected(false)
        }
    }

    const handleIsAllSelected = (checkAllSelected) => {
        if (checkAllSelected) {
            setSelectedFilters(filters.map(filter => filter.value))
            setIsAllSelected(true)
        } else if (!checkAllSelected) {
            setSelectedFilters([])
            setIsAllSelected(false)
        }
    };

    useEffect(() => {
        if (selectedFilters.length === filters.length) {
            setIsAllSelected(true);
        } else {
            setIsAllSelected(false);
        }
        setFilters(selectedFilters);
    }, [selectedFilters]);

    useEffect(() => {
        if (filtersSelected.length === filters.length) {
            setIsAllSelected(true);
        } else {
            setIsAllSelected(false);
        }
    }, [filtersSelected]);

    useEffect(() => {
        if (filtersSelected.length === filters.length) {
            setIsAllSelected(true);
        } else {
            setIsAllSelected(false);
        }
        setSelectedFilters(filtersSelected);
    }, []);

    return (
        <div className="filters-wrapper">
            {!isMenu && <h6>Filters</h6>}
            {(isMenu && openModal) || !isMenu ? 
                <FilterOption 
                    handleIsAllSelected={handleIsAllSelected}
                    isAllSelected={isAllSelected}
                    filters={filters}
                    handleFiltersChange={handleFiltersChange}
                    selectedFilters={filtersSelected}
                />
            : ''}
        </div>
    );
};

export default MemoriesFilters;
