import React, { useCallback, useEffect, useMemo, useState } from "react";
import useAsync from "react-use-async-hook";
import _ from "lodash";
import TooltipAndInfo from "../../../TooltipAndInfo";
import { useSetLoading } from "../../../utils/hooks/loader";
import addNotification from "../../../utils/notification/notification";
import * as CustomizeFamilyWebsite from "../../../../api/customizeFamilyWebsite";

const DisplayCountOfSubscribers = ({ onePortalId }) => {
  const [countToolTip, setCountLinkToolTip] = useState(false);
  const [countClassName, setCountClassName] = useState("singleDigit");
  const [count, setCount] = useState(0);
  const showCountLinkToolTip = () => {
    setCountLinkToolTip(!countToolTip);
  };
  const {
    data: subscribersCount,
    loading: getCountLoader,
    execute: executeGetCountOfSubscribers,
  } = useAsync({
    autoExecute: false,
    initialData: useMemo(() => null, []),
    task: useCallback((opi) => {
      return CustomizeFamilyWebsite.getSubscribersCount(opi);
    }, []),
    dataLoader: useCallback((res) => {
      return _.get(res, "data", []);
    }, []),
    onError: useCallback((error) => {
      addNotification({
        type: "danger",
        title: "Error",
        message: _.get(
          error,
          "response.data.message",
          "Oops! Something went wrong"
        ),
      });
    }, []),
  });

  useEffect(() => {
    executeGetCountOfSubscribers(onePortalId);
  }, []);

  useEffect(() => {
    if (subscribersCount) {
      setCount(_.get(subscribersCount, "count"));
    }
  }, [subscribersCount]);

  const showLoader = useMemo(() => getCountLoader, [getCountLoader]);

  useSetLoading(showLoader);

  useEffect(() => {
    if (count?.toString().length > 2) {
      setCountClassName("threeDigit");
    }
  }, [count]);

  return (
    <div className="displaySubscribersCountWrapper">
      <div className="display-name-heading">
        <div className="display-name">
          <div className="customIndex">5</div>
          <div>Friends & Families Requested for Reminders</div>
        </div>
        <TooltipAndInfo
          id="countOfSubScribers"
          showTooltipFn={showCountLinkToolTip}
          showToolTip={countToolTip}
          size={25}
          tooltipText="Count Of Friends & Families Requested for Reminders"
          color="#000000"
        />
      </div>
      <div className="textWrapper">
        <p className="countText">Total number of subscribers : </p>
        <div className={`countValue ${countClassName}`}>{count}</div>
      </div>
    </div>
  );
};

export default DisplayCountOfSubscribers;
