import { apiFetch } from "../configs/apiConfig";
import { API_URL } from "../constants/appConstants";
import axios from "axios"
import { getAccessToken } from "../utils";

export const getPrayerCardTemplates = () => {
    return apiFetch({
        url: `${API_URL}/prayer-card/templates`,
        method: "GET"
    });
};

export const getPoems = () => {
    return apiFetch({
        url: `${API_URL}/poems`,
        method: "GET"
    });
};

export const setPrayerCard = (decedentId, data) => {
    return apiFetch({
        url: `${API_URL}/prayer-card/${decedentId}`,
        method: "POST",
        data
    });
};

export const getPrayerCard = decedentId => {
    return apiFetch({
        url: `${API_URL}/prayer-card/${decedentId}`,
        method: "GET"
    });
};

export const submitPrayerCard = (decedentId, formData) => {
    return axios({
        url: `${API_URL}/prayer-card/${decedentId}/submit`,
        method: "POST",
        data: formData,
        headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${getAccessToken()}`
        }
    });
};

export const addCustomPrayerCard = (decedentId, formData) => {
    return axios({
        url: `${API_URL}/prayer-card/${decedentId}/custom`,
        method: "POST",
        data: formData,
        headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${getAccessToken()}`
        }
    });
}