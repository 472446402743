import React from 'react';
import { Label, Input } from 'reactstrap';

const Btnradio = props => {
    return (
        <Label className="radio-inline d-flex align-items-center justify-content-start ">
            <Input
                type="radio"
                name={
                    props.radioButtonType
                        ? props.radioButtonType
                        : 'iAmRadioButton'
                }
                onBlur={props.onBlur}
                value={props.buttonValue ? props.buttonValue : props.value}
                onChange={props.handleChange}
                checked={props.checked}
                disabled={props.disabled}
                id={props.id}
            />
            <span>
                <i className="fas fa-circle" />
            </span>
            {/* Your Text Here */}
        </Label>
    );
};

export default Btnradio;
