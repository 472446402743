import {
    Modal,
    ModalBody,
    Form,
    FormGroup,
    Label,
    Input,
} from "reactstrap";
import React, { useCallback, useState, useMemo } from "react";
import { Formik } from "formik";
import * as yup from "yup";
import * as AuthAPI from "../../../api/auth";
import _ from "lodash";
import useAsync from "react-use-async-hook";
import addNotification from "../../utils/notification/notification";
import { useSetLoading } from "../../utils/hooks/loader";

const ForgotPassword = ({ setForgotPassword, setLoginPopup }) => {
    const [isSuccess, setSuccess] = useState(false);
    const { loading: forgotPasswordLoader, execute: executeForgotPassword } =
        useAsync({
            autoExecute: false,
            task: useCallback(async (payload, formikBag) => {
                return {
                    res: await AuthAPI.resetPassword(payload),
                    formikBag,
                };
            }, []),
            dataLoader: useCallback(({ res, formikBag }) => {
                formikBag.setSubmitting(false);
                addNotification({
                    type: "success",
                    title: "Success",
                    message: _.get(res, "data.message"),
                });
                setSuccess(true);
            }, []),
            onError: useCallback((error) => {
                addNotification({
                    type: "danger",
                    title: "Error",
                    message: _.get(
                        error,
                        "response.data.message",
                        "Oops! Something went wrong"
                    ),
                });
            }, []),
        });

    const showLoader = useMemo(
        () => forgotPasswordLoader,
        [forgotPasswordLoader]
    );
    useSetLoading(showLoader);

    return (
        <Modal isOpen>
            <ModalBody>
                <Formik
                    initialValues={{
                        email: "",
                    }}
                    validationSchema={yup.object().shape({
                        email: yup.string().required("E-mail Id is required"),
                    })}
                    onSubmit={(values, formikBag) => {
                        executeForgotPassword(values, formikBag);
                    }}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleSubmit,
                        handleBlur,
                        handleChange,
                        isSubmitting,
                        setSubmitting,
                        setFieldValue,
                    }) => {
                        const onChange = (e) => {
                            const { name, value } = e.target;
                            setFieldValue(name, value);
                            setSubmitting(false);
                        };

                        return (
                            <Form
                                onSubmit={handleSubmit}
                                className="reset-form"
                            >
                                <h1 className="forgot-heading">
                                    Forgot Password?
                                </h1>
                                <p className="forgot-text">
                                    No worries, we’ll send you reset
                                    instructions to your mail ID.
                                </p>
                                {!isSuccess ? (
                                    <React.Fragment>
                                        <FormGroup>
                                            <Label
                                                for="email"
                                                className="form-label"
                                            >
                                                Email ID
                                            </Label>
                                            <Input
                                                id="email"
                                                name="email"
                                                type="text"
                                                placeholder="Enter email address"
                                                value={values.email}
                                                onBlur={handleBlur}
                                                onChange={onChange}
                                                className="form-input"
                                            />
                                            {errors.email && touched.email ? (
                                                <span className="error-msg">
                                                    {errors.email}
                                                </span>
                                            ) : null}
                                        </FormGroup>
                                        <button
                                            type="submit"
                                            className="forgot-button"
                                        >
                                            Send Instructions
                                        </button>
                                    </React.Fragment>
                                ) : (
                                    <React.Fragment>
                                        <div className="auth-successalert-wrapper">
                                            <div className="successalert-card">
                                                <i className="fas fa-check-circle"></i>
                                                <span>
                                                    Check your Email to Reset
                                                    your Password
                                                </span>
                                            </div>
                                            <p className="resend-wrapper">
                                                Email not received?{" "}
                                                <button
                                                    type="submit"
                                                    disabled={isSubmitting}
                                                    className="resend-click"
                                                >
                                                    Click here
                                                </button>{" "}
                                                to resend
                                            </p>
                                        </div>
                                    </React.Fragment>
                                )}
                                <div className="back-button-tab">
                                    <button
                                        onClick={() => {
                                            setForgotPassword(false);
                                            setLoginPopup(true);
                                        }}
                                        className="arrow-left"
                                    >
                                        <i className="fas fa-arrow-left"></i>
                                    </button>
                                    <p>back to Login In</p>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            </ModalBody>
        </Modal>
    );
};

export default ForgotPassword;
