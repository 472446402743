import React, { useCallback, useMemo } from "react";
import _ from "lodash"
import { sendFlowersLink } from "../constants/urlConstants";
import { Row, Col } from "reactstrap";
import SendFlowersImage from "../../assets/images/sendFlowersImage.png";
import BlueRightArrow from "../../assets/images/BlueRightArrow.png";
import useAsync from "react-use-async-hook";
import { getTributeLink } from "../../api/trubutestoreLink";
import addNotification from "../utils/notification/notification";
import { useSetLoading } from "../utils/hooks/loader";

const SendFlowers = ({opi}) => {

  const {
    data: tributeStoreLink,
    loading: getTributeLinkLoader,
    execute: executeGetTributeLink,
} = useAsync({
    autoExecute: false,
    task: getTributeLink,
    dataLoader: useCallback(res => {
        window.open(_.get(res, "data.data"), '_blank');
        return _.get(res, "data");
    }, []),
    onError: useCallback(error => {
        addNotification({
            type: "danger",
            title: "Error",
            message: _.get(
                error,
                "response.data.message",
                "Can't redirect to tribute store, Please try again!"
            )
        });
    }, [])
});
  
  const getRedirectionLink = () => {
    executeGetTributeLink(opi);
  }
  
  const showLoader = useMemo(
    () => getTributeLinkLoader,
    [getTributeLinkLoader]
  );
  useSetLoading(showLoader);

  return (
    <Row className="sendFlowersWrapper">
      <Col lg={4} xl={4} md={4} sm={4} className="flowerWrapper">
        <img src={SendFlowersImage} alt="sendFlowers" className="flowerImage" />
      </Col>
      <Col lg={8} xl={8} md={8} sm={8} className="flowerHeadingCol">
        <Row className="sendFlowersHeading">
          <button
            className="sendFlowersText"
            onClick={() => { getRedirectionLink('flower-click') }}
          >
            Send Flowers
            <img src={BlueRightArrow} alt="rightArrow" className="rightArrow" />
          </button>
        </Row>
        <Row>
          <p className="message">Express your love by sending flowers</p>
        </Row>
      </Col>
    </Row>
  );
};

export default SendFlowers;
