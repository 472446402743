import React from 'react'
import _ from 'lodash';
import { Input, Label } from "reactstrap";

const FilterOptions = ({ 
    handleIsAllSelected, 
    isAllSelected,
    filters,
    handleFiltersChange,
    selectedFilters
}) => {
    return (
        <ul>
            <li>
                <div className="checkbox-wrapper">
                    <Label className="checkbox-inline d-flex align-items-center justify-content-start">
                        <Input
                            type="checkbox"
                            name="filterValues"
                            onChange={() => {handleIsAllSelected(!isAllSelected)}}
                            checked={isAllSelected}
                        />
                        All Memory
                    </Label>
                </div>
            </li>
            {
                filters.map(filter => {
                    return (
                        <li>
                            <div className="checkbox-wrapper">
                                <Label className="checkbox-inline d-flex align-items-center justify-content-start">
                                    <Input
                                        type="checkbox"
                                        name="filterValues"
                                        onChange={() => {
                                            handleFiltersChange(filter.value);
                                        }}
                                        checked={
                                            isAllSelected ||
                                            selectedFilters.includes(
                                                filter.value
                                            )
                                        }
                                    />
                                    {filter.label}
                                </Label>
                            </div>
                        </li>
                    );
            })}
        </ul>
    )
}

export default FilterOptions