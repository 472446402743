import React from "react";
import Instagram from "../../assets/images/instagramSelect.svg";
import Facebook from "../../assets/images/facebook.svg";
import Linkedin from "../../assets/images/LinkedIn.svg";
import Twitter from "../../assets/images/twitterNewIcon.webp";
import Youtube from "../../assets/images/youtube.svg";
import Tiktok from "../../assets/images/tiktok.svg";
import Blog from "../../assets/images/blog.svg";
import CustomUrl from "../../assets/images/internet.svg";
import _ from "lodash";

const DisplaySocialMediaProfiles = ({ socialMediaLinksData }) => {
  const socialMediaIcons = {
    Instagram,
    Facebook,
    Linkedin,
    Twitter,
    Youtube,
    Tiktok,
    CustomUrl,
    Blog,
  };
  return (
    <div className="socialMediaProfileLinksWrapper">
      <p className="relateProfileHeading">Related Profiles : </p>
      <div className="iconsWrapper">
        {Object.keys(socialMediaLinksData).map((profile, i) => {
          return (
            <a
              href={_.get(socialMediaLinksData, profile)}
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={socialMediaIcons[profile]}
                alt="socilMediaProfileLink"
                className={`socialMediaProfileImage ${profile}`}
              ></img>
            </a>
          );
        })}
      </div>
    </div>
  );
};

export default DisplaySocialMediaProfiles;
