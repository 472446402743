import React, { Suspense, lazy, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useLocation,
} from "react-router-dom";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import "../styles.global.scss";

import Protected from "../containers/authentication.HOC";
import Public from "../containers/public.HOC";
import App from "../components/features";
import AuthLogin from "../components/authentication/msal-login";

const Authentication = lazy(() => import("../components/authentication/auth"));
const Admin = lazy(() => import("../components/admin"));
const Memories = lazy(() => import("../components/features/memories"));
const MemoriesOld = lazy(() => import("../components/features/memoriesOld"));
const FamilyWebsite = lazy(() =>
  import("../components/features/familyWebsite")
);
const ImageDisplay = lazy(() =>
  import("../components/features/imageDisplay/index")
);
// import Admin from "../components/admin";
// import Memories from "../components/features/memories";
// import MemoriesOld from "../components/features/memoriesOld";
// import FamilyWebsite from "../components/features/familyWebsite";
// import ImageDisplay from "../components/features/imageDisplay/index";

// const ProtectedApp = Protected(App);
// const ProtectedAdmin = Protected(Admin);
const isAzureMFAEnabled = process.env.REACT_APP_IS_AZURE_MFA_ENABLED;

// eslint-disable-next-line import/no-anonymous-default-export
const MainRouter = () => {
  const url = window.location.href;
  const isOnePortalParam = new URLSearchParams(new URL(url).search).get("onePortal");
  return (
    <>
      {isAzureMFAEnabled?.toLowerCase() !== "true" || !isOnePortalParam  ? (
        <Router basename="/obituaries/">
          <div>
            <Suspense fallback={<div>Loading...</div>}>
              <Switch>
                <Route path="/app" component={Protected(App)} />
                <Route path="/memories" component={Public(Memories)} />
                <Route path="/memories-old" component={Public(MemoriesOld)} />
                <Route
                  path="/share-memories"
                  component={Public(FamilyWebsite)}
                />
                <Route path="/name" component={Public(FamilyWebsite)} />
                <Route path="/gallery/file" component={Public(ImageDisplay)} />
                <Route path="/admin" component={Protected(Admin)} />
                <Route path="/" component={Authentication} />
                <Route path="/administrator" component={Authentication} />
                <Route path="*" component={Authentication} />
              </Switch>
            </Suspense>
          </div>
        </Router>
      ) : (
        <>
          <UnauthenticatedTemplate>
              <AuthLogin redirectUrl={url} />
          </UnauthenticatedTemplate>
          <AuthenticatedTemplate>
            <Router basename="/obituaries/">
              <div>
                <Suspense fallback={<div>Loading...</div>}>
                  <Switch>
                    <Route path="/app" component={Protected(App)} />
                    <Route path="/memories" component={Public(Memories)} />
                    <Route
                      path="/memories-old"
                      component={Public(MemoriesOld)}
                    />
                    <Route
                      path="/share-memories"
                      component={Public(FamilyWebsite)}
                    />
                    <Route path="/name" component={Public(FamilyWebsite)} />
                    <Route
                      path="/gallery/file"
                      component={Public(ImageDisplay)}
                    />
                    <Route path="/admin" component={Protected(Admin)} />
                    <Route path="/" component={Authentication} />
                    <Route path="/administrator" component={Authentication} />
                    <Route path="*" component={Authentication} />
                  </Switch>
                </Suspense>
              </div>
            </Router>
          </AuthenticatedTemplate>
        </>
      )}
    </>
  );
};
export default MainRouter;
