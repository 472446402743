import React from 'react';

import { doLogout,handleAuthLogout } from '../../utils';
import AlertMessage from '../utils/AlertMessage/AlertMessage';
import Cookies from "universal-cookie";

function UnauthorizedAlert({open,msalInstance}) {
  return (
      <AlertMessage
          toggleAlertModal={() => {
              if (open) {
                const isAzureMFAEnabled = process.env.REACT_APP_IS_AZURE_MFA_ENABLED;
                const cookies = new Cookies()
                const role = cookies.get("role");
                  const logoutRedirectUrl =
                    process.env.REACT_APP_HOST !== "https://temp.clcafamily.com"
                      ? `/obituaries`
                      : `/`;
                  // Without clicking on sign out, if forceful logout occurs,
                  // and if it is a cl-arranger login with MFA enabled, then we need to remove account details as well.
                  if (
                    isAzureMFAEnabled &&
                    msalInstance?.getAllAccounts()?.length >= 1
                  )
                    handleAuthLogout(
                      msalInstance,
                      msalInstance?.getAllAccounts(),
                      logoutRedirectUrl,
                      true,
                    );
                doLogout();
              }
          }}
          toggleAlertState={open}
          successMsg={
              "Unauthorized Access! You don't have the access to view this page. Please login again."
          }
          showCancelButton={false}
          title={"Access Denied"}
      />
  );
}

export default UnauthorizedAlert