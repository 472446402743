import React, { useState, useCallback, useEffect, useMemo } from "react";
import _ from "lodash";
import { useLocation, useParams } from "react-router-dom";
import useAsync from "react-use-async-hook";

import { useSetLoading } from "../../utils/hooks/loader";
import addNotification from "../../utils/notification/notification";
import * as PrayerCardAPI from "../../../api/prayerCard";
import * as Dashboard from "../../../api/dashboard";

import BreadCrumb from "../../utils/breadCrumb/breadCrumb";
import TemplateList from "./templateList";
import CustomizeCard from "./customizeCard";

import checkMark from "../../../assets/images/download-transparent-check-mark-gif-11562855141yrviuuu1dd-removebg-preview.png";
import AppLayout from "../../appLayout";

const PrayerCard = ({ role, isLocked = false }) => {
    const { onePortalId } = useParams();
    const [template, selectTemplate] = useState(null);
    const [custom, setCustom] = useState(null);
    const [isArranger, setIsArranger] = useState(true);
    const [loader, setLoader] = useState(false);

    const { pathname, search } = useLocation();
    const searchParameter = search ? search.slice(search.indexOf("?")) : pathname && pathname.includes("?") ? pathname.slice(pathname.indexOf("?")) : ''

    useEffect(() => {
        if (role === "admin") setIsArranger(false);
        else if (role === "arranger") setIsArranger(true);
    }, [role]);

    /**
     * Get Decedent Detail
     */
    const {
        data: decedent,
        loading: getDecedentLoader,
        execute: executeGetDecedent,
    } = useAsync({
        autoExecute: false,
        task: Dashboard.getDecedentDetails,
        dataLoader: useCallback(res => {
            return _.get(res, "data");
        }, []),
    });

    useEffect(() => {
        if (onePortalId) {
            executeGetDecedent(onePortalId);
            executeGetPrayerCard(onePortalId);
        }
    }, [onePortalId]);

    /**
     * Get Prayer Card
     */
    const {
        data: prayerCard,
        loading: getPrayerCardLoader,
        execute: executeGetPrayerCard,
    } = useAsync({
        autoExecute: false,
        task: useCallback(onePortalId => {
            return PrayerCardAPI.getPrayerCard(onePortalId);
        }, []),
        dataLoader: useCallback(res => {
            return _.get(res, "data.data", {});
        }, []),
        onError: useCallback(error => {
            addNotification({
                type: "danger",
                title: "Error",
                message: _.get(
                    error,
                    "response.data.message",
                    "Oops! Something went wrong"
                ),
            });
        }, []),
    });

    useEffect(() => {
        if (prayerCard && Object.keys(prayerCard).length) {
            const tempCard = {
                ...prayerCard,
                type: "prayer",
                page: {
                    ...prayerCard.page,
                    poem: {
                        id:
                            _.get(prayerCard, "page.poemId") ||
                            _.get(prayerCard, "page.poem.id"),
                        title:
                            _.get(prayerCard, "page.poemTitle") ||
                            _.get(prayerCard, "page.poem.title"),
                        content:
                            _.get(prayerCard, "page.poemContent") ||
                            _.get(prayerCard, "page.poem.content"),
                    },
                },
            };
            selectTemplate(tempCard)
        }
    }, [prayerCard]);

    /**
     * Add Custom Prayer card
     */
    const {
        data: customPrayerCardResponse,
        loading: addCustomPrayerCardLoader,
        execute: executeAddCustomPrayerCard,
    } = useAsync({
        autoExecute: false,
        task: useCallback((onePortalId, formData) => {
            return PrayerCardAPI.addCustomPrayerCard(onePortalId, formData);
        }, []),
        dataLoader: useCallback(res => {
            return _.get(res, "data.data", []);
        }, []),
        onError: useCallback(error => {
            addNotification({
                type: "danger",
                title: "Error",
                message: _.get(
                    error,
                    "response.data.message",
                    "Oops! Something went wrong"
                ),
            });
        }, []),
    });

    useEffect(() => {
        if (onePortalId && customPrayerCardResponse) {
            executeGetPrayerCard(onePortalId);
        }
    }, [customPrayerCardResponse]);

    /**
     * Save Template
     */
    const {
        data: templateResponse,
        loading: setTemplateLoader,
        execute: executeSetTemplate,
    } = useAsync({
        autoExecute: false,
        task: useCallback((onePortalId, payload) => {
            return PrayerCardAPI.setPrayerCard(onePortalId, payload);
        }, []),
        dataLoader: useCallback(res => {
            return _.get(res, "data.data", []);
        }, []),
        onError: useCallback(error => {
            addNotification({
                type: "danger",
                title: "Error",
                message: _.get(
                    error,
                    "response.data.message",
                    "Oops! Something went wrong"
                ),
            });
        }, []),
    });

    useEffect(() => {
        if (templateResponse) {
            executeGetPrayerCard(onePortalId);
        }
    }, [templateResponse]);

    const showLoader = useMemo(
        () =>
            // getPrayerCardLoader ||
            getDecedentLoader ||
            addCustomPrayerCardLoader ||
            getPrayerCardLoader ||
            // setTemplateLoader ||
            loader,
        [
            // getPrayerCardLoader,
            getDecedentLoader,
            addCustomPrayerCardLoader,
            // setTemplateLoader,
            getPrayerCardLoader,
            loader,
        ]
    );
    useSetLoading(showLoader);

    return (
        <AppLayout
            decedent={_.get(decedent, "decedent") || null}
            haveAcceptPolicy={
                isArranger ? _.get(decedent, "haveAcceptPolicy", false) : true
            }
            getDecedent={executeGetDecedent}
            setLoader={setLoader}
        >
            <div className='container'>
                {isArranger ? (
                    <BreadCrumb
                        links={[
                            {
                                url: `/app`,
                                description: "Home",
                                heading: "Dashboard",
                            },
                            {
                                url: `/app/${onePortalId}/arrangements`,
                                description: "Arrangements Summary",
                                heading: "Arrangements Summary",
                            },
                            {
                                url: "/app/prayerCreation",
                                description: "Prayer Card Creation",
                                heading: "Prayer Card Creation",
                                isSpan: true,
                            },
                        ]}
                        showBack={true}
                    />
                ) : (
                    <BreadCrumb
                        links={[
                            {
                                url: `/admin/decedents`,
                                description: "Home",
                                heading: "Dashboard",
                            },
                            {
                                url: `/admin/${onePortalId}/arrangements`,
                                description: "Arrangements Summary",
                                heading: "Arrangements Summary",
                                search: searchParameter
                            },
                            {
                                url: "/admin/prayerCreation",
                                description: "Prayer Card Creation",
                                heading: "Prayer Card Creation",
                                isSpan: true,
                            },
                        ]}
                        showBack={true}
                    />
                )}

                {!template && !custom ? (
                    <div className='prayer-card-body-wrapper'>
                        <div className='prayer-card-creation-steps'>
                            <li>
                                <span style={{ fontWeight: "600" }}>
                                    STEP 1.{" "}
                                </span>{" "}
                                Choose a prayer card from one of our available
                                selections or upload your own photo
                                {template && (
                                    <a
                                        className='action-btn-link'
                                        disabled={isLocked}
                                        onClick={() => {
                                            selectTemplate(null);
                                        }}
                                    >
                                        Change Prayer Card
                                    </a>
                                )}
                            </li>
                            {template && (
                                <li>
                                    <span style={{ fontWeight: "600" }}>
                                        STEP 2.{" "}
                                    </span>{" "}
                                    Please select or enter a poem for your
                                    prayer card
                                    {_.get(decedent, "prayerStatus") && (
                                        <img
                                            src={checkMark}
                                            alt='Check'
                                            style={{ height: 20 }}
                                        />
                                    )}
                                </li>
                            )}
                        </div>
                        <TemplateList
                            selectTemplate={selectTemplate}
                            addCustomPrayerCard={formData =>
                                executeAddCustomPrayerCard(
                                    onePortalId,
                                    formData
                                )
                            }
                            saveTemplate={payload =>
                                executeSetTemplate(onePortalId, payload)
                            }
                            isLocked={_.get(decedent, "prayerStatus")}
                        />
                    </div>
                ) : (
                    <CustomizeCard
                        decedent={decedent}
                        template={template}
                        onePortalId={onePortalId}
                        selectTemplate={selectTemplate}
                        saveTemplate={executeSetTemplate}
                        role={role}
                        executeGetPrayerCard={executeGetPrayerCard}
                        loadingStatus={setTemplateLoader}
                        isLocked={_.get(decedent, "prayerStatus")}
                    />
                )}
            </div>
        </AppLayout>
    );
};

export default PrayerCard;
