import React from "react";
import ReactDOM from "react-dom";

import { Provider } from "react-redux";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";

import store from "./store/store";

import MainRouter from "./routes/route";
import * as serviceWorker from "./serviceWorker";
import { msalConfig } from "./authConfig";

export const msalInstance = new PublicClientApplication(msalConfig);
const isAzureMFAEnabled = process.env.REACT_APP_IS_AZURE_MFA_ENABLED;

const url = window.location.href;
const isOnePortalParam = new URLSearchParams(new URL(url).search).get("onePortal");

ReactDOM.render(
  <Provider store={store}>
    {isAzureMFAEnabled?.toLowerCase() === "true" && isOnePortalParam ? (
      <MsalProvider instance={msalInstance}>
        <MainRouter />
      </MsalProvider>
    ) : (
      <MainRouter />
    )}
  </Provider>,
  document.getElementById("root")
);

serviceWorker.unregister();
