import React, { useEffect, useRef, useState } from "react";
import _ from "lodash";
import { DropdownIcon } from "../../../../assets/icons/DropdownIcon";
import DropupIcon from "../../../../assets/images/DropupIcon.svg";
import MemoryFilterOptions from "./memoryFilter-options";

const ApproveMemoriesFilter = ({ setFilters, filterSelected }) => {
    const [isAllSelected, setAllSelected] = useState(true);
    const [selectedFilters, setSelectedFilters] = useState([]);
    const [isFilterExpanded, setFilterExpanded] = useState(false);

    const useOutsideAlerter = (ref) => {
        useEffect(() => {
            /**
             * Alert if clicked on outside of element
             */
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setFilterExpanded(false);
                }
            }

            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    };

    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);

    const filters = [
        { value: 1, label: "Pending for Approval" },
        { value: 2, label: "Rejected" },
    ];

    const getSelectedfilters = () => {
        if (
            !selectedFilters ||
            selectedFilters.length === 0 ||
            selectedFilters.length === filters.length
        ) {
            return "All Filters";
        }
        const selectedFilter = selectedFilters[0];
        const selectedFilterVal = filters.find((filter) => {
            return filter.value === selectedFilter;
        });
        return selectedFilterVal.label;
    };

    const handleFilterChange = (value) => {
        if (selectedFilters.includes(value)) {
            const updatedFilters = _.filter(
                selectedFilters,
                (filter) => filter !== value
            );
            setSelectedFilters(updatedFilters);
            setAllSelected(false);
        } else {
            const updatedFilters = selectedFilters.concat(value);
            setSelectedFilters(updatedFilters);
            setAllSelected(false);
        }
    };

    useEffect(() => {
        if (selectedFilters.length === filters.length) {
            setAllSelected(true);
        } else {
            setAllSelected(false);
        }
        setFilters(selectedFilters);
    }, [selectedFilters]);

    useEffect(() => {
        if (filterSelected.length === filters.length) {
            setAllSelected(true);
        } else {
            setAllSelected(false);
        }
    }, [filterSelected]);

    return (
        <div className="filters-wrapper" ref={wrapperRef}>
            <div
                className="selected-filter"
                onClick={() => setFilterExpanded(!isFilterExpanded)}
            >
                <span>{getSelectedfilters()}</span>
                {isFilterExpanded ? (
                    <img src={DropupIcon} alt="dropup" />
                ) : (
                    <span>
                        <DropdownIcon />
                    </span>
                )}
            </div>
            {isFilterExpanded && (
                <MemoryFilterOptions
                    filters={filters}
                    selectedFilters={selectedFilters}
                    isAllSelected={isAllSelected}
                    setFilterExpanded={setFilterExpanded}
                    handleFiltersChange={handleFilterChange}
                />
            )}
        </div>
    );
};

export default ApproveMemoriesFilter;
