import React, { useState, useCallback, useMemo, useEffect } from "react";
import {
    Switch,
    Route,
    useRouteMatch,
    useLocation,
    Redirect,
    useParams,
} from "react-router-dom";
import ReactNotification from "react-notifications-component";
import useAsync from "react-use-async-hook";
import _ from "lodash";
import * as AuthAPI from "../../../api/auth";
import { useSetLoading } from "../../utils/hooks/loader";
import FamilyWebsiteDashboard from "../../../containers/familyWebsiteDashboard";
import ImageFullView from "../imageDisplay/ImageFullView";

const FamilyWebsite = () => {
    const match = useRouteMatch();
    const [redirectUrl, setRedirectUrl] = useState(null);
    const location = useLocation();
   
        /**
     * Get User Details
     */
        const {
            data: userDetail,
            loading: getUserLoader,
            execute: executeGetUser,
        } = useAsync({
            autoExecute: false,
            task: AuthAPI.isUserLoggedIn,
            dataLoader: useCallback(res => {
                return _.get(res, "data.data");
            }, []),
        });
    
    useEffect(() => {
        executeGetUser();
    }, []);

    useEffect(() => {
        if(getUserLoader) {
            let pathArr = match.path.split("/");
            if (
                pathArr.includes("memories") &&
                pathArr.includes("app") &&
                !userDetail
            ) {
                setRedirectUrl(
                    _.join(
                        _.remove(
                            _.split(location.pathname, "/"),
                            n => n !== "" && n !== "app"
                        ),
                        "/"
                    )
                );
            }
            // if (userDetail) {
            //     if (
            //         _.get(userDetail, "role") === "admin" &&
            //         !location.pathname.includes("/admin/memories-list")
            //     ) {
            //         setRedirectUrl("/admin");
            //     }
            //     if (pathArr.includes("memories") && !pathArr.includes("app")) {
            //         setRedirectUrl(`/app${location.pathname}`);
            //     }
            // }
        }
    }
    , [userDetail]);

    // useSetLoading(showLoader);

    return redirectUrl ? (
        <Redirect to={redirectUrl} />
    ) : (
        <div>
            <ReactNotification />
            <Switch>
                <Route
                    path={`${match.url}/:fullname/:opi`}
                    exact
                    component={FamilyWebsiteDashboard}
                />
            </Switch>
        </div>
    );
};
export default FamilyWebsite;
