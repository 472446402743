export const textFieldStyle = {
    heading1: {
        type: "input",
        inputStyle: "playball",
        divStyle: "playball",
        length: 45,
        lineLength: 1,
        lineLimit: 45,
    },
    heading2: {
        type: "input",
        inputStyle: "cinzel-extra-bold",
        divStyle: "margin-md cinzel-extra-bold",
        length: 50,
        isOnlyUpperCase: true,
        lineLength: 1,
        lineLimit: 50,
    },
    textarea: {
        type: "textarea",
        inputStyle: "cinzel-bold",
        divStyle: "name-textarea margin-sm cinzel-bold",
        length: 90,
        isOnlyUpperCase: true,
        lineLength: 2,
        lineLimit: 45,
    },
    poem: {
        title: {
            type: "input",
            inputStyle: "playball",
            divStyle: "playball",
            length: 45,
            lineLength: 1,
            lineLimit: 45,
        },
        content: {
            type: "input",
            inputStyle: "poem-textarea playball",
            divStyle: "xxl-textarea playball",
            length: 1100,
            lineLength: 25,
            lineLimit: 50,
        },
    },
    footer: {
        type: "input",
        inputStyle: "playball",
        divStyle: "margin-top-xlg playball",
        length: 45,
        lineLength: 1,
        lineLimit: 45,
    },
    image: {
        type: "image",
        //inputStyle: "margin-xlg image-md",
        inputStyle:"image-lg",
        divStyle: "",
    },
    locationLogo: {
        type: "image",
        inputStyle: "margin-location image-mdx",
        divStyle: "",
    },
    heading3: {
        type: "input",
        inputStyle: "cinzel-bold cinzel-bold-lg",
        divStyle: "margin-xs cinzel-bold cinzel-bold-lg",
        length: 40,
        lineLength: 1,
        lineLimit: 40,
    },
    location: {
        type: "input",
        inputStyle: "cinzel-extra-bold",
        divStyle: "margin-md cinzel-extra-bold",
        length: 50,
        isOnlyUpperCase: true,
        lineLength: 1,
        lineLimit: 50,
    },
    nameTextarea: {
        type: "textarea",
        inputStyle: "playball",
        divStyle: "margin-sm name-textarea playball",
        length: 85,
        lineLength: 2,
        lineLimit: 45,
    },
    textarea2: {
        type: "textarea",
        inputStyle: "max-textarea cinzel-bold",
        divStyle: "xl-textarea cinzel-bold",
        length: 650,
        align: "left",
        lineLength: 15,
        lineLimit: 45,
    },
    textarea3: {
        //Font size and weight will be less
        type: "textarea",
        inputStyle: "cinzel-bold",
        divStyle: "four-textarea margin-lg cinzel-bold",
        length: 160,
        lineLength: 4,
        lineLimit: 40,
    },
    textarea4: {
        // Font size and weight will be same as textarea3
        type: "textarea",
        inputStyle: "max-textarea cinzel",
        divStyle: "program-textarea xxl-textarea cinzel",
        length: 1100,
        align: "left",
        lineLength: 20,
        lineLimit: 50,
    },
    heading4: {
        // Font size will be less from heading3
        type: "input",
        inputStyle: "cinzel-bold",
        divStyle: "margin-top-lg cinzel-bold",
        length: 40,
        lineLength: 1,
        lineLimit: 40,
    },
    group: {
        type: "textarea",
        inputStyle: "group-textarea cinzel",
        divStyle: "group-div-1 cinzel",
        length: 65,
        lineLength: 1,
    },
    groupTextarea1: {
        type: "textarea",
        inputStyle: "group-textarea cinzel",
        divStyle: "three-textarea group-div-1 cinzel",
        length: 70,
        groupDiv: true,
        lineLength: 3,
        lineLimit: 45,
    },
    groupTextarea2: {
        type: "textarea",
        inputStyle: "group-textarea cinzel",
        divStyle: "three-textarea group-div-2 cinzel",
        length: 70,
        groupDiv: true,
        lineLength: 3,
        lineLimit: 45,
    },
    textarea5: {
        type: "textarea",
        inputStyle: "playball",
        divStyle: "md-textarea program-template margin-top-xlg playball",
        length: 320,
        lineLength: 8,
        lineLimit: 45,
    },
    footer1: {
        type: "input",
        inputStyle: "playball",
        divStyle: "playball",
        length: 45,
        align: "right",
        lineLength: 1,
        lineLimit: 45,
    },
    image2: {
        // check from here
        type: "image",
        inputStyle: "image-lg",
        divStyle: "",
    },
    heading5: {
        // Font size will be less from heading3
        type: "input",
        inputStyle: "cinzel-bold-lg",
        divStyle: "cinzel-bold-lg",
        length: 50,
        lineLength: 1,
    },
    nameTextarea2: {
        type: "textarea",
        inputStyle: "playball-lg",
        divStyle: "name-textarea playball-lg",
        length: 100,
        lineLength: 2,
    },
    nameTextarea3: {
        type: "textarea",
        inputStyle: "cinzel-bold-xlg",
        divStyle: "name-textarea margin-xlg cinzel-bold-xlg",
        length: 90,
        lineLength: 2,
        lineLimit: 45,
    },
    textarea7: {
        type: "textarea",
        inputStyle: "cinzel-bold-lg",
        divStyle: "name-textarea cinzel-bold-lg",
        length: 70,
        lineLength: 2,
        lineLimit: 45,
    },
    textarea6: {
        type: "textarea",
        inputStyle: "cinzel-bold",
        divStyle: "lg-textarea cinzel-bold",
        length: 450,
        lineLength: 10,
        lineLimit: 45,
    },
    groupTextarea3: {
        type: "textarea",
        inputStyle: "group-textarea playball",
        divStyle: "four-textarea margin-lg group-div-1 playball",
        length: 95,
        groupDiv: true,
        lineLength: 4,
    },
    groupTextarea4: {
        type: "textarea",
        inputStyle: "group-textarea playball",
        divStyle: "four-textarea margin-lg group-div-2 playball",
        length: 95,
        groupDiv: true,
        lineLength: 4,
    },
    textarea8: {
        type: "textarea",
        inputStyle: "playball",
        divStyle: "six-textarea playball",
        length: 270,
        lineLength: 6,
        lineLimit: 45,
    },
};

export const pageName = {
    0: "mainPage",
    1: "leftPage",
    2: "rightPage",
    3: "backPage",
};

export const templateName = {
    0: "Page 1 - Front Cover",
    1: "Page 2 - Inside Left",
    2: "Page 3 - Inside Right",
    3: "Page 4 - Back Cover",
};
