import { Col, Button } from 'reactstrap';
import { useState } from 'react';
import DropzoneFileCard from '../../dropzoneFileCard/dropzoneFileCard';
import { PROGRAM_CARD_MAX_FILE_SIZE } from '../../../../constants/appConstants';
import DropzoneFileSelect from '../../dropzoneFileSelect/dropzoneFileSelect';
import InstructionsModal from '../instructionsModal';
import _ from 'lodash';

const ServiceCardActionPanel = ({
  item,
  fileUrl,
  onUpload,
  uploadProgress,
  executeSubmitProgramCard,
}) => {
  const [file, setFile] = useState(null);

  const handleAcceptedFiles = (files) => {
    const formData = new FormData();
    formData.append('file', files[0]);
    setFile(files[0]);
    onUpload(formData);
  };

  return (
    <div className="service-card-action-panel">
      {(_.get(item?.programCardTemplate, "name") || item?.name) && (
        <h4 className="theme-primary">
          {_.get(item?.programCardTemplate, "name") || item?.name}
        </h4>
      )}
      <Col className="control">
        <h6>STEP A:</h6>
        <p style={{marginBottom:'0.5rem'}}>
          Please download the card template and edit into the Microsoft Word
        </p>
        <div>
          <InstructionsModal/>
        </div>
        <a
          href={fileUrl}
          className="btn-theme theme-green-dark btn-customise btn-block"
        >
          Download to Edit Template
        </a>
      </Col>
      {!_.get(item, "isLocked") && (
        <>
          <div className="divider" />
          <Col className="control">
            <h6>STEP B:</h6>
            <p>Please upload the updated template and submit to the arranger</p>

            {!file && (
              <DropzoneFileSelect
                maxSize={PROGRAM_CARD_MAX_FILE_SIZE}
                onDropAccepted={handleAcceptedFiles}
              />
            )}
            {file && (
              <DropzoneFileCard
                file={file}
                uploadProgress={uploadProgress}
                onFileRemove={() => setFile(null)}
              />
            )}
            <Button className="btn-theme theme-green-dark btn-customise btn-block"
              onClick={()=>{
                executeSubmitProgramCard()
              }}
            >
              submit to arranger
            </Button>
          </Col>
        </>
      )}
    </div>
  );
};

export default ServiceCardActionPanel;
