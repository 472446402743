import { useEffect, useCallback, useMemo } from "react";
import _ from "lodash";
import useAsync from "react-use-async-hook";

import { useSetLoading } from "./loader";
import store from "../../../store/store";
import * as DecedentAPI from "../../../api/decedent";

export const useSetData = (props) => {
  const callData = props?.callData === undefined || !!props.callData;

  const states = store.getState();
  const { data } = states.loader;

  useEffect(() => {
    if (callData && !data) {
      executeGetData();
    }
  }, [data]);

  /**
   * Get Static data
   */
  const { data: dataResponse, loading: getDataLoader, execute: executeGetData } = useAsync({
    autoExecute: false,
    task: DecedentAPI.getData,
    dataLoader: useCallback((res) => {
      return _.get(res, "data.data");
    }, []),
  });

  useEffect(() => {
    if (dataResponse) {
      store.dispatch({
        type: `SET_DATA`,
        payload: dataResponse,
      });
    }
  }, [dataResponse]);

  // const showLoader = useMemo(() => getDataLoader, [getDataLoader]);
  // useSetLoading(showLoader);
};
