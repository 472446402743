import React, { useEffect } from 'react';
import { useMsal } from '@azure/msal-react';

export default function AuthLogin({ handleAuthToken }) {
  const { instance } = useMsal();

  const handleLogin = async () => {
    await instance.handleRedirectPromise();

    const accounts = instance.getAllAccounts();
    if (accounts.length === 0) {
      await instance.loginRedirect().catch(e => {
        console.log('loginRedirect error', e);
      });
    }
  };

  useEffect(() => {
    handleLogin();
  }, []);

  return <></>;
}
